import React, { useContext, useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { UserContext } from "../../Editor";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";
import { Field } from "../../../../store/DataSourceMetaData";

interface IProductStrengthForReimbursementProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}


const ProductStrengthForReimbursement = ({
    fieldInfo,
    datasourceName,
    section,
  }: IProductStrengthForReimbursementProp) => {
    const userContext = useContext(UserContext);

    const [field, setField] = useState<any[]>([]);
    const [productStrength, setProductStrength] = useState<any>([]);
    const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
    
    useEffect(() => {
        if (field.length == 0 && fieldInfo.length > 0) {
          setField(fieldInfo);
        }
      }, [fieldInfo]);
    
    useEffect(() => {
        setProductStrength(subPageDate["product_dosage_reimb"]);
      }, [subPageDate]);
      console.log('field: ', field);
      console.log('productStrength: ', productStrength);

    const fieldFormulation = field.filter((x) => x.name === "ReimbursementStatus_ProductFormulation");//?.map((val) => (val));
    const fieldAdministration = field.filter((x) => x.name === "ReimbursementStatus_AdministrationRoute");//?.map((val) => (val))
    const fieldStrength = field.filter((x) => x.name === "ReimbursementStatus_Strength");//?.map((val) => (val))
    const fieldReimbursed = field.filter((x) => x.name === "ReimbursementStatus_status");//?.map((val) => (val))

    const renderInfoIcon = (fieldObj: any) => {
        // let fieldIcon = field.filter((x) => x.name === fieldObj.name)
        //       ?.map((val) => (val))
        console.log(fieldObj)
        return (
            <>
                <FaInfoCircle
                    onClick={() => {
                    userContext.showAlert(
                        <>
                        <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                            {datasourceName}
                            <DoubleArrowIcon
                            style={{ fontSize: "18px", paddingBottom: "2px" }}
                            />
                            {section}
                            <DoubleArrowIcon
                            style={{
                                strokeWidth: "5",
                                fontSize: "18px",
                                paddingBottom: "2px",
                            }}
                            />
                        {fieldObj[0].label}
                        </span>
                        </>,
                        "info",
                        fieldObj[0].descriptionClient? fieldObj[0].descriptionClient : "",
                        "https://google.com",
                        true
                    );
                    }}
                    style={{
                    fontSize: "12px",
                    marginLeft: "3px",
                    cursor: "pointer",
                    }}
                />
          </>
        );
    };

    return (
        <>
            {!!productStrength && productStrength.length > 0 && productStrength.map((el: any, index: number) => (
                <>
                <br />
                <div className="col-sm-12">
                    {index == 0 && 
                        <div className="container form-group"
                            style={{ paddingLeft: "20px" }}>
                            <div className="form-group">
                                <label className="col-sm-3 control-label  no-padding-right control-label-plain-text">
                                    Product dosage: 
                                </label>
                                <div className="col-sm-12 row top-padding" style={{marginTop:"10px"}}>
                                    <div className="col-sm-3"><label className=""></label>Formulation {renderInfoIcon(fieldFormulation)}</div>
                                    <div className="col-sm-3" style={{maxWidth: "23%"}}>Route of administration {renderInfoIcon(fieldAdministration)}</div>
                                    <div className="col-sm-3"><label className=""></label>Dose(Unit) {renderInfoIcon(fieldStrength)}</div>
                                    <div className="col-sm-3" style={{maxWidth: "23%"}}>Reimbursed {renderInfoIcon(fieldReimbursed)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    <hr />
                    <div className="col-sm-12 row form-group">
                        <div className="col-sm-3 text-right" style={{ paddingTop: "10px" }}>{index + 1}.</div>
                        {field
                            .filter((x) => x.name.split('_')[1] === "ProductFormulation").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "ProductFormulation").map((val) => (
                                <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
                                    <label
                                    >{el[val.name.split('_')[1]]}</label>
                                </div>
                        ))}
                        {field
                            .filter((x) => x.name.split('_')[1] === "AdministrationRoute").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "AdministrationRoute").map((val) => (
                                <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"1em" }}>
                                    {el[val.name.split('_')[1]] != null ? String(el[val.name.split('_')[1]]).split(",-,")
                                        .map((element) => (
                                            <>
                                            <label>{element}</label>
                                            <br />
                                            </>
                                    )) : ''}
                                </div>
                        ))}
                        {field
                            .filter((x) => x.name.split('_')[1] === "Strength").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "Strength").map((val) => (
                                <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"2em" }}>
                                    <label
                                    >{el[val.name.split('_')[1]]}</label>
                                </div>
                        ))}
                        {field
                            .filter((x) => x.name.split('_')[1] === "status").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "status").map((val) => (
                                <div className="col-sm-2" style={{ paddingTop: "10px",paddingLeft:"3em" }}>
                                    <label
                                    >{el[val.name.split('_')[1].toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase())]}</label>
                                </div>
                        ))}
                    </div>
                    {field
                        .filter((x) => (x.name.split('_')[1] === "Dosing" )).length > 0 && 
                        field
                        .filter((x) => (x.name.split('_')[1] === "Dosing" )).map((val) => (
                            <>
                                <div
                                    className="col-sm-12 row form-group"
                                    style={{ marginBottom: "0rem" }}
                                    key = {val.fieldType}
                                >
                                    <label
                                        className="col-sm-4 text-right control-label "
                                        style={{ paddingRight: "18px" }}
                                        >
                                        {val.label}
                                        {true && (<FaInfoCircle
                                            onClick={() => {
                                            userContext.showAlert(
                                                <>
                                                    <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                                                        {datasourceName}
                                                        <DoubleArrowIcon
                                                        style={{ fontSize: "18px", paddingBottom: "2px" }}
                                                        />
                                                        {section}
                                                        <DoubleArrowIcon
                                                        style={{
                                                            strokeWidth: "5",
                                                            fontSize: "18px",
                                                            paddingBottom: "2px",
                                                        }}
                                                        />
                                                        {val.label}
                                                    </span>
                                                </>,
                                                "info",
                                                val.description? val.description : "",
                                                "https://google.com",
                                                true
                                            );
                                            }}
                                            style={{
                                            fontSize: "12px",
                                            marginLeft: "3px",
                                            cursor: "pointer",
                                            }}
                                        />)}
                                    </label>
                                    <div className="col-sm-8" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
                                        <label
                                            dangerouslySetInnerHTML={{ __html: el[val.name.split('_')[1]] }}
                                        ></label>
                                    </div>
                                </div>
                            </>
                    ))}
                    <div className="col-sm-12 row form-group">
                        <div className="col-sm-3 text-right"></div>
                        
                        {field
                            .filter((x) => x.name.split('_')[1] === "StartDate").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "StartDate").map((val) => (
                                <>
                                    <label
                                        className="col-sm-3 text-right control-label "
                                        style={{ paddingRight: "18px" }}
                                        >
                                        {val.label}
                                        {true && (<FaInfoCircle
                                            onClick={() => {
                                            userContext.showAlert(
                                                <>
                                                    <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                                                        {datasourceName}
                                                        <DoubleArrowIcon
                                                        style={{ fontSize: "18px", paddingBottom: "2px" }}
                                                        />
                                                        {section}
                                                        <DoubleArrowIcon
                                                        style={{
                                                            strokeWidth: "5",
                                                            fontSize: "18px",
                                                            paddingBottom: "2px",
                                                        }}
                                                        />
                                                        {val.label}
                                                    </span>
                                                </>,
                                                "info",
                                                val.description? val.description : "",
                                                "https://google.com",
                                                true
                                            );
                                            }}
                                            style={{
                                            fontSize: "12px",
                                            marginLeft: "3px",
                                            cursor: "pointer",
                                            }}
                                        />)}
                                    </label>
                                    <div className="col-sm-1" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
                                        <label
                                        >{(el[val.name.split('_')[1]] != '' && el[val.name.split('_')[1]] != null && el[val.name.split('_')[1]] != undefined) ? new Date(el[val.name.split('_')[1]]).toLocaleDateString('en-GB') : ''}</label>
                                    </div>
                                </>
                        ))}
                        {field
                            .filter((x) => x.name.split('_')[1] === "EndDate").length > 0 &&
                            field
                            .filter((x) => x.name.split('_')[1] === "EndDate").map((val) => (
                                <>
                                    <label
                                        className="col-sm-3 text-right control-label "
                                        style={{ paddingRight: "18px" }}
                                        >
                                        {val.label}
                                        {true && (<FaInfoCircle
                                            onClick={() => {
                                            userContext.showAlert(
                                                <>
                                                    <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                                                        {datasourceName}
                                                        <DoubleArrowIcon
                                                        style={{ fontSize: "18px", paddingBottom: "2px" }}
                                                        />
                                                        {section}
                                                        <DoubleArrowIcon
                                                        style={{
                                                            strokeWidth: "5",
                                                            fontSize: "18px",
                                                            paddingBottom: "2px",
                                                        }}
                                                        />
                                                        {val.label}
                                                    </span>
                                                </>,
                                                "info",
                                                val.description? val.description : "",
                                                "https://google.com",
                                                true
                                            );
                                            }}
                                            style={{
                                            fontSize: "12px",
                                            marginLeft: "3px",
                                            cursor: "pointer",
                                            }}
                                        />)}
                                    </label>
                                    <div className="col-sm-1" style={{ paddingTop: "10px",paddingLeft:"1em" }}>
                                        <label
                                        >{(el[val.name.split('_')[1]] != '' && el[val.name.split('_')[1]] != null && el[val.name.split('_')[1]] != undefined) ? new Date(el[val.name.split('_')[1]]).toLocaleDateString('en-GB') : ''}</label>
                                    </div>
                                </>
                        ))}
                    </div>
                </div>
            </>
            ))}
        </>
    );
};
export default ProductStrengthForReimbursement;