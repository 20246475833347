import React, { useState } from "react";
// import { connect } from 'react-redux';
// import { ApplicationState } from '../store';
import SearchResult from "./SearchResult/SearchResult";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SectionView from "./Forms/SectionView";
import Editor from "./Forms/Editor";
import Overview from "./Dashboard/overview";
import Dashboard from "./Dashboard/dashboard";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  saveAccountId,
  saveClientId,
  saveUserAccessData,
  setIsValid,
  saveCurrentClientName,
  saveCurrentRole,
  saveCurrentUserId,
  useFetchUserAccountIdMutation,
  useFetchUserAccessDetailsMutation,
} from "../store/UserProfileApiSlice";
import { useEffect } from "react";
import { setPathwayRule } from "../store/pathwaySlice";
import apiCall from "./service/apiCall";
import { useRouteMatch } from "react-router-dom";
import { getDataSourceName } from "../store/SearchSlice";
import './Home.css';
import DeleteCall from "./service/deleteCall";

interface IautoSave {
  ID: number
  DatasourceID : number;
  Record_ID : number;
  RecordTitle : string;
}

function Home(props: any) {
  const showSearchResult = useAppSelector(
    (state) => state.searchResult.showSearchResult
  );
  const openForm = useAppSelector((state) => state.autocompleteSearch.openForm);
  const dsID = useAppSelector(
    (state) => state.autocompleteSearch.selectedDataSourceID
  );

  let _token = localStorage.getItem("token");
  const isValid = useAppSelector((state) => state.userProfile.isValid);
  //const [isValid, setIsValid] = React.useState(false);
  const [lastStatus, setLastStatus] = React.useState("info");
  const [isAlertShow, setIsAlertShow] = React.useState(true);
  const [type_, setType_] = React.useState("");
  const [message_, setMessage_] = React.useState("");
  const [title_, setTitle_] = React.useState("Info");
  const [isAlertModal, setIsAlertModal] = React.useState(false);
  const [confirmAlertDialog, setConfirmAlertDialog] = React.useState("No");
  const [roleId, setRoleId] = React.useState<number>(1);
  const [currClientName, setCurrClientName] = React.useState<string>("");
  const [currClientId, setCurrClientId] = React.useState<number>(0);
  const [currAccountId, setCurrAccountId] = React.useState<number>(0);
  const [fetchUserAccountId] = useFetchUserAccountIdMutation();
  const [fetchUserAccessDetails] = useFetchUserAccessDetailsMutation();
  const [pathwayFixed, setPathwayFixed] = React.useState<boolean>(false);
  const routeMatch: any = useRouteMatch();
  const savedSearchId = Number(routeMatch.params.savedSearchId);
  const [autoSaveRecords, setAutoSaveRecords] = useState<IautoSave[]>([]); 

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (_token) {
      const tokenData = JSON.parse(atob(_token.split(".")[1]));
      getAccoundId(tokenData.userid, tokenData.role, tokenData.clientId);
      getUserAccessDetails(tokenData.userid);
      setRoleId(tokenData.role);
      dispatch(saveCurrentRole(tokenData.role));
      dispatch(saveCurrentUserId(tokenData.userid));
      localStorage.setItem("CurrRoleId", tokenData.role);
      localStorage.setItem("CurrUserId", tokenData.userid);
    }
  }, [_token]);

  const getAccoundId = async (
    userId: number,
    roleId: number,
    clientId: number
  ) => {
    const accountId = await fetchUserAccountId({ userId, roleId, clientId });
    setCurrClientName(accountId.data.ClientName);
    setCurrClientId(accountId.data.ClientId);
    setCurrAccountId(accountId.data.ID);
    dispatch(saveAccountId(accountId.data.ID));
    dispatch(saveClientId(clientId));
    dispatch(saveCurrentClientName(accountId.data.ClientName.toString()));
  };

  const getUserAccessDetails = async (userId: number) => {
    const accessDetails = await fetchUserAccessDetails({ userId });
    dispatch(saveUserAccessData(accessDetails.data));
  };
  //const [isValid, setIsValid] = React.useState(true)
  let HomeDiv;
  useEffect(() => {
    if (_token) {
      dispatch(setIsValid(true));
    }
    const prevUrl = localStorage.getItem("prevUrl");
    localStorage.removeItem("prevUrl");
    localStorage.removeItem("redirect");
    if (prevUrl) {
      window.location.href = prevUrl;
    }else{
          //fetch auto saved records
          getAllAutoSavedRecords();
    }
  }, []);

  const OnClickIgnore = (id:  number) => {
    // console.log(this)
    setAutoSaveRecords(prev => prev.filter((x) => x.ID != id));
    DeleteCall(`/api/autosave/${id}`);
  }

  const getAllAutoSavedRecords = async () => {
    const resp = await apiCall(`/api/autosave/all`);
    const data: IautoSave[] = resp.data ? resp.data : [];
    setAutoSaveRecords(data);
  }

  //showAutoSavedRecord
  useEffect(() => {
    if (autoSaveRecords.length > 0)
      showAutoSavedRecord();
    else
      toastr.remove();
  }, [ autoSaveRecords ])

  const showAutoSavedRecord = () => {
    let AutoSaveLinks = `
    <span>You have some unsaved record(s). Please click on desired record link to restore the data.
    <br>
    Click ignore to discard the unsaved record(s).
    <br>
    Unsaved record(s):
    <br></span>`;

    autoSaveRecords.forEach((x) => {
      AutoSaveLinks += `<div style="padding-bottom : 10px"><b>${getDataSourceName(x.DatasourceID)}: </b>  <a class="autosave-link" href='/form/${x.DatasourceID}${x.Record_ID ? `/${x.Record_ID}` : ''}' target="_blank">${x.RecordTitle}</a>
      <button class="ignore-btn" id=${`autoSave-ignore-btn_${x.ID}`} name=${`btn_${x.ID}`}>Ignore</button><br></div>`;
    });
    AutoSaveLinks += '';

    toastr.options.escapeHtml = false;
    toastr.remove();
    toastr.info(AutoSaveLinks, "<h5 style='font-weight:bold'></h5>", infoOptions);

    setTimeout(() => {
      autoSaveRecords.forEach((x) => {
        document?.getElementById(`autoSave-ignore-btn_${x.ID}`)?.addEventListener('click', () => OnClickIgnore(x.ID), { passive: true });
      });
    }, 0)

  }

  const Content = () => {
    return (<ul>
      <li>1</li>
      <li>2</li>
      <li>3</li>
    </ul>)
  }

  const warningOptions = {
    positionClass: "toast-top-center",
    hideDuration: 300,
    timeOut: 60000,
    closeButton: true,
  };

  const infoOptions = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    toastClass: "toast-info parent-toast",
    positionClass: "toast-bottom-right",
    preventDuplicates: false,
    onclick: ()=>{},
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 0,
    extendedTimeOut: 0,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    tapToDismiss: false,
  };

  const onClick = (type: string, title: string, msg: string) => {
    if (lastStatus != type) {
      toastr.remove();
    }
    setLastStatus(type);
    if (type == "warning") {
      toastr.warning(msg, title, warningOptions);
    }
    if (type == "info") {
      toastr.info(msg, title, infoOptions);
    }
  };

  const showError = (title: any, type: any, message: any) => {
    setType_(type);
    setMessage_(message);
    setTitle_(title);
  };

  //----------- props for draggable custom popup
  const popupBtnName = "Drug";
  const popupTitle = "Drug";
  const popupBtnArray = [
    "Cancel",
    "Clear All",
    "Select All",
    "Save Selections",
  ];
  const handleClose = () => {};
  const handleClearAll = () => {};
  const handleSelectAll = () => {};
  const handleSaveSelections = () => {};
  const popupBtnCallbacks = [
    handleClose,
    handleClearAll,
    handleSelectAll,
    handleSaveSelections,
  ];

  const popupBody: React.ReactNode = (
    <ul>
      <li>option1</li>
      <li>option2</li>
      <li>option3</li>
      <li>option4</li>
    </ul>
  );

  useEffect(() => {
    apiCall("/api/pathway/pathway_rule").then((res: any) => {
      dispatch(setPathwayRule(res.data));
      if (res.data === "pathway-c-1") {
        setPathwayFixed(true);
      } else if (res.data === "pathway-r-1") {
        setPathwayFixed(true);
      }
    });
  }, []);

  //-----------

  return (
    <div>
      {isValid && (
        <div>
          <Dashboard
            roleId={roleId}
            clientName={currClientName}
            clientId={currClientId}
            accountId={currAccountId}
            pathwayFixed={pathwayFixed}
          />
          {/* <NewModal /> */}

          {showSearchResult && <SearchResult></SearchResult>}

          {openForm && !showSearchResult && (
            <Editor></Editor>
          )}
        </div>
      )}
      {!isValid && <p>Login...</p>}
    </div>
  );
}
export default Home;
