import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../img/hta-logo.png";
import "./Login.css";
import Cookies from 'js-cookie';

export const rootURL = window.location.origin;
export const cal_sda = () => {
  if (Cookies.get("email")) {
    window.location.href =
      `${process.env.REACT_APP_DISCOVERY_ENDPOINT}/authorize?response_type=code&scope=openid&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=` +
      rootURL +
      "/cb";
  } else {
    window.location.href = `${rootURL}/Login`;
  }
}

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (email)
      Cookies.set("email", JSON.stringify(email));
  }, [email]);

  const submitForm = (e : any) => {
    e.preventDefault();
    Cookies.set("email", JSON.stringify(email));
    cal_sda();
  };

  return (
    <div style={{ paddingTop: "10vw" }}>
      <div className="container container-global" data-v-857fd648="">
        <div className="wrap-form" data-v-857fd648="">
          <div className="form-signin main-overlay" data-v-857fd648="">
            <div className="mb-4 text-center" data-v-857fd648="">
              <img src={logo} alt="IQVIA | HTA Accelerator" />
            </div>
            <form onSubmit={submitForm}>
              <div className="form-group form-group-cs mb-3">
                <input
                  type="text"
                  className="form-input"
                  name="email"
                  value={email}
                  placeholder="E-mail"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <button type="submit" className="form-button" style={{ borderRadius: 15 }}>
                  Get started
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
