import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";

interface IRWEHTAProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
  showEmptyFieldsFlag: boolean;
}

const RWE = ({
  fieldInfo,
  datasourceName,
  section,
  showEmptyFieldsFlag
}: IRWEHTAProp) => {

  const [field, setField] = useState<any[]>([]);
  const [rwe_data, setRweData] = useState<any>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      setField(fieldInfo);
    }
  }, [fieldInfo]);

  useEffect(() => {
    setRweData(subPageDate["rwe"]);
  }, [subPageDate]);

  // console.log('field rwe: ', field);
  // console.log('rwe_data: ', rwe_data);

  return (
    <>
      {rwe_data && rwe_data.rwe_sources && rwe_data.rwe_sources.length > 0 &&
      rwe_data.rwe_sources.map((el: any, index: number) => (
        <div className="col-sm-12">
           <br></br>
          <b>RWE source {index + 1}</b>
          <hr />
          <>
            {field.length > 0 && 
              // .filter((x) => x.name === "RWE_Datasource.RWEDatasourceID")
              field.filter((x) => x.masterFieldId).map((val) => (
                <Element
                  label={val.label}
                  fieldType={val.fieldTypeId}
                  value={String(el[val.name.split('.')[1]]).includes(",-, ") ?
                  String(el[val.name.split('.')[1]]).split(",-, ")
                  .map((element) => (
                    <>
                      <label>{element}</label>
                      <br />
                    </>
                  )) : el[val.name.split('.')[1]]
                 }
                  // value={el["RWE_Source"]}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                  showEmptyFieldsFlag={showEmptyFieldsFlag}
                />
              ))}
          </>
          {/* <>
            {field
              .filter((x) => x.name === "RWE_Datasource.TypeofDatasourceID")
              ?.map((val) => (
                <Element
                  label={val.label}
                  value={el["Type_of_source"]}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                />
              ))}
          </>
          <>
            {field
              .filter((x) => x.name === "RWE_Datasource.AreasSupportedName")
              ?.map((val) => (
                <Element
                  label={val.label}
                  value={el["Area_supported"]}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                />
              ))}
          </>
          <>
            {field
              .filter((x) => x.name === "RWE_Datasource.PayerAcceptanceID")
              ?.map((val) => (
                <Element
                  label={val.label}
                  value={el["Payer_acceptance"]}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                />
              ))}
          </>
          <>
            {field
              .filter((x) => x.name === "RWE_Datasource.RationaleName")
              ?.map((val) => (
                <Element
                  label={val.label}
                  value={String(el["Rationale"])
                    .split(",-,")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))
                }
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                />
              ))}
          </>
          <>
            {field
              .filter((x) => x.name === "RWE_Datasource.AdditionalRWEDetails")
              ?.map((val) => (
                <Element
                  label={val.label}
                  value={el["Additional_details"]}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.descriptionClient}
                />
              ))}
          </> */}
          
        </div>
      ))}
      {rwe_data && rwe_data.rwe_sources && rwe_data.rwe_sources.length == 0 && (
        <div className="col-sm-12" style={{backgroundColor: "whitesmoke", textAlign: "center"}}>
          No further RWE information was provided
        </div>
      )}
      {/* {
        <div className="col-sm-12">
        <hr />
        <b>RWE critique</b>
        </div>
      } */}
      {/*{rwe_data && rwe_data.rwe_critique && rwe_data.rwe_critique.length >0 && 
      rwe_data.rwe_critique.map((el: any, index: number) => (
        <div className="col-sm-12">
        <>
          {field
            .filter((x) => x.name === "HTARecord_RWEClinicalPositives").length > 0 &&
            field
            .filter((x) => x.name === "HTARecord_RWEClinicalPositives").map((val) => (
              <Element
                label={val.label}
                value={String(el["RWE_clinical_positives"])
                    .split(",-,")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))
                }
                datasourceName={datasourceName}
                section={section}
                description={val.descriptionClient}
              />
            ))}
        </>
        <>
          {field
            .filter((x) => x.name === "HTARecord_RWEClinicalNegatives").length > 0 &&
            field
            .filter((x) => x.name === "HTARecord_RWEClinicalNegatives").map((val) => (
              <Element
                label={val.label}
                value={String(el["RWE_clinical_negatives"])
                    .split(",-,")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))
                }
                datasourceName={datasourceName}
                section={section}
                description={val.descriptionClient}
              />
            ))}
        </>
        <>
          {field
            .filter((x) => x.name === "HTARecord_RWEEconomicPositives").length > 0 &&
            field
            .filter((x) => x.name === "HTARecord_RWEEconomicPositives").map((val) => (
              <Element
                label={val.label}
                value={String(el["RWE_economic_positives"])
                    .split(",-,")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))
                }
                datasourceName={datasourceName}
                section={section}
                description={val.descriptionClient}
              />
            ))}
        </>
        <>
          {field
            .filter((x) => x.name === "HTARecord_RWEEconomicNegatives").length > 0 &&
            field
            .filter((x) => x.name === "HTARecord_RWEEconomicNegatives").map((val) => (
              <Element
                label={val.label}
                value={String(el["RWE_economic_negatives"])
                    .split(",-,")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))
                }
                datasourceName={datasourceName}
                section={section}
                description={val.descriptionClient}
              />
            ))}
        </>
        </div>
      ))} */}
    </>
  );
};

export default RWE;
