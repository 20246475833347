import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPathway } from "../components/Charts/PathwayChart";

export interface IpathwayState {
  pathwayFilter: string[];
  level: number;
  pathwayData: IPathway;
  searchParams: IsearchParams;
  pathwayRule: string;
  pathwayDatasourceId: number;
  searchParamsState: any;
  changeFilter: number;
  search : boolean;
  columnsData :IColumns;
  maxLevel : number;
  visibleColumns : any;
}

interface IsearchParams {
  [name: string]: string;
}

interface IColumns {
  [key:string] : string[]
}
export const initialState: IpathwayState = {
  pathwayFilter: [],
  level: 0,
  pathwayData: {},
  searchParams: {},
  pathwayRule: "",
  pathwayDatasourceId: 1,
  searchParamsState: {},
  changeFilter : 0,
  search: false,
  columnsData: {},
  maxLevel : 0,
  visibleColumns: {},
};

export const pathwaySlice = createSlice({
  name: "pathway",
  initialState,
  reducers: {
    setPathwayFilter: (state, action: PayloadAction<string[]>) => {
      state.pathwayFilter = action.payload;
    },
    setLevel: (state, action: PayloadAction<number>) => {
      state.level = action.payload;
    },
    setPathwayData: (state, action: PayloadAction<IPathway>) => {
      state.pathwayData = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<IsearchParams>) => {
      state.searchParams = action.payload;
    },
    setPathwayRule: (state, action: PayloadAction<string>) => {
      state.pathwayRule = action.payload;
    },
    setPathwayDatasourceId: (state, action: PayloadAction<number>) => {
      state.pathwayDatasourceId = action.payload;
    },
    setSearchParamsState: (state, action: PayloadAction<any>) => {
      state.searchParamsState = action.payload;
    },
    setChangeFilter: (state, action: PayloadAction<number>) => {
      state.changeFilter = action.payload;
    },
    setSearch:(state, action: PayloadAction<boolean>) => {
      state.search = action.payload;
    },
    setColumnsData:(state, action: PayloadAction<IColumns>) => {
      state.columnsData = action.payload;
    },
    setMaxLevel:(state, action: PayloadAction<number>) => {
      state.maxLevel = action.payload;
    },
    setvisibleColumns:(state, action: PayloadAction<any>) =>{
      state.visibleColumns = action.payload;
    }
  },
});

export const {
  setPathwayFilter,
  setLevel,
  setPathwayData,
  setSearchParams,
  setPathwayRule,
  setPathwayDatasourceId,
  setSearchParamsState,
  setChangeFilter,
  setSearch,
  setColumnsData,
  setMaxLevel,
  setvisibleColumns,
} = pathwaySlice.actions;

export default pathwaySlice.reducer;
