import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AssignModal.css";
import CloseIcon from "@mui/icons-material/Close";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from "antd";
import { useFetchLookupItemsQuery } from "../../store/LookupApiSlice";
import dayjs, { Dayjs } from "dayjs";
import PostCall from "../../components/service/postCall";
import apiCall from "../../components/service/apiCall";
import { IselectedLookupGlobe, IselectedLookupGlobeSpecial } from "../interfaces/multiselect";
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";

interface AssignModalProp {
  open: boolean;
  handleClose: Function;
  selectedRowsId: number[];
  dataSource_id: number;
}

type UserObj = {
  label: string;
  ID: number;
};

const AssignModal = (props: AssignModalProp) => {
  const { open, handleClose, selectedRowsId, dataSource_id } = props;
  
  const [selectedRecordInfo, setSelectedRecordInfo] = useState<{
    recId: any;
    multiselectValues: IselectedLookupGlobe;
    multiselectValuesSpecial: IselectedLookupGlobeSpecial;
  }>({
    recId: null,
    multiselectValues: {},
    multiselectValuesSpecial: {},
  });
  const [selectRole, setSelectRole] = useState<{
    both: boolean;
    associate: boolean;
    reviewer: boolean;
  }>({
    both: true,
    associate: false,
    reviewer: false,
  });
  const [formData, setFormData] = useState<{
    associate: any;
    associateID: any;
    dueDate: Dayjs | any;
    reviewer: any;
    reviewerID: any;
    dueDateReview: Dayjs | any;
    comment: string;
  }>({
    associate: null,
    associateID: null,
    dueDate: null,
    reviewer: null,
    reviewerID: null,
    dueDateReview: null,
    comment: "",
  });
  const [userList, setUserList] = useState<UserObj[]>([]);

  const [fetchInfoProgress, setFetchInfoProgress] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState('1');
  const [assignDisabled, setAssignDisabled] = useState(false);
  
  // Methods
  const roleStyle = (value: boolean) => {
    if (value) return { backgroundColor: "#3bbeed", color: "#fff" };
    else return { backgroundColor: "#fff", color: "#000" };
  };

  const changeRole = (value: any) => {
    let tmp: any = selectRole;
    for (let n in tmp) {
      tmp[n] = false;
    }
    tmp[value] = true;

    setSelectRole({
      both: tmp.both,
      associate: tmp.associate,
      reviewer: tmp.reviewer,
    });
  };

  const onSubmit = async () => {
    if (formData.associateID === null && formData.reviewerID === null) {
      return false;
    }
    console.log("submit", formData, selectedRowsId, dataSource_id);
    let dueDate = formData.dueDate
      ? `${formData.dueDate.$D}/${formData.dueDate.$M + 1}/${
          formData.dueDate.$y
        }`
      : null;
    let dueDateReview = formData.dueDateReview
      ? `${formData.dueDateReview.$D}/${formData.dueDateReview.$M + 1}/${
          formData.dueDateReview.$y
        }`
      : null;

    let obj = {};
    if (formData.associateID !== null && formData.reviewerID !== null) {
      obj = {
        obj: {
          Associate: {
            id: formData.associateID,
            due_date: formData.dueDate,
          },
          Reviewer: {
            id: formData.reviewerID,
            due_date: formData.dueDateReview,
          },
        },
        Comments: formData.comment != null ? formData.comment : "",
        Ids: selectedRowsId,
        RecInfo: selectedRecordInfo,
      };
    } else if (formData.associateID === null) {
      obj = {
        obj: {
          Reviewer: {
            id: formData.reviewerID,
            due_date: formData.dueDateReview,
          },
        },
        Comments: formData.comment != null ? formData.comment : "",
        Ids: selectedRowsId,
        RecInfo: selectedRecordInfo
      };
    } else if (formData.reviewerID === null) {
      obj = {
        obj: {
          Associate: {
            id: formData.associateID,
            due_date: formData.dueDate,
          },
        },
        Comments: formData.comment != null ? formData.comment : "",
        Ids: selectedRowsId,
        RecInfo: selectedRecordInfo
      };
    }

    console.log(obj);
    PostCall(`/api/update/assign/bulk`, {
      datasource_id: dataSource_id,
      obj: obj,
      selectedTab: selectedTab,
    }).then((resp) => {
      console.log(resp);
      ToastrAlert("Record assignment done successfully", "success");
    });
    closeModal();
  };

  const onInputSelectionChange = (
    event: any,
    newInputValue: any,
    inputName: string
  ) => {
    console.log("newInputValue", newInputValue);
    if (inputName === "associate") {
      setFormData({
        ...formData,
        associate:
          newInputValue !== null && newInputValue.label
            ? newInputValue.label
            : null,
        associateID:
          newInputValue !== null && newInputValue.ID ? newInputValue.ID : null,
      });
    } else if (inputName === "reviewer") {
      setFormData({
        ...formData,
        reviewer:
          newInputValue !== null && newInputValue.label
            ? newInputValue.label
            : null,
        reviewerID:
          newInputValue !== null && newInputValue.ID ? newInputValue.ID : null,
      });
    }
  };

  const fetchUsers = useFetchLookupItemsQuery({
    lookup_id: 301,
    datasource_id: dataSource_id,
  });

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data.length > 0) {
      let arr = [];
      for (let c of fetchUsers.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setUserList(arr);
    }
  }, [fetchUsers.isLoading]);

  useEffect(() => {
    if (selectedRowsId.length > 0 && props.open) {
      setFetchInfoProgress(true);
      setAssignDisabled(true);
      apiCall(`/api/multiselectvalues/${dataSource_id}/${selectedRowsId}/search`).then((response) => {
        console.log('response ', response.data);
        setFetchInfoProgress(false);
        let recDetails: any = [];
        for (let j = 0; j < response.data.length; j++) {
          let selLookup = response.data[j]["multiselectValues"];
          let selLookupSpecial = response.data[j]["multiselectValuesSpecial"];
          let newObj: IselectedLookupGlobe = {};
          let newObjSpecial: IselectedLookupGlobeSpecial = {};
          for (let i = 0; i < selLookup.length; i++) {
            if (selLookup[i] != null) {
              if (newObj.hasOwnProperty(selLookup[i]["FieldSource"])) {
                newObj[selLookup[i]["FieldSource"]].push({
                  Id: selLookup[i]["LookupId"],
                  Value: selLookup[i]["Value"],
                });
              } else {
                newObj[selLookup[i]["FieldSource"]] = [
                  { Id: selLookup[i]["LookupId"], Value: selLookup[i]["Value"] },
                ];
              }
            }
          }
          for (let i = 0; i < selLookupSpecial.length; i++) {
            if (
              newObjSpecial.hasOwnProperty(selLookupSpecial[i]["FieldSource"])
            ) {
              newObjSpecial[selLookupSpecial[i]["FieldSource"]].push({
                Id: selLookupSpecial[i]["LookupId"],
                Value: selLookupSpecial[i]["Value"],
              });
            } else {
              newObjSpecial[selLookupSpecial[i]["FieldSource"]] = [
                {
                  Id: selLookupSpecial[i]["LookupId"],
                  Value: selLookupSpecial[i]["Value"],
                },
              ];
            }
          }
          recDetails.push({
            recId: response.data[j]["recId"],
            multiselectValues: newObj,
            multiselectValuesSpecial: newObjSpecial,
          })
        }
        setSelectedRecordInfo(recDetails);
        setAssignDisabled(false);
      });
    }
  }, [props.open]);

  const renderOptions = (props: React.HTMLAttributes<HTMLElement>,option: Partial<any>) => {
    return <li {...props} key = {option.ID}>{option.label}</li>
  }

  const createDate = (value: any) => {
    if(value == null)
      return undefined;
    let day = value.$D;
    let month = value.$M + 1;
    let year = value.$y;
    let date = `${month}-${day}-${year}`;
    return date;

  };

  const associateElement = () => {
    return (
      <div className="associate row">
        <span className="col-2">Associate</span>
        <Autocomplete
          className="col-9 autocomplete"
          disablePortal
          id="combo-box-demo"
          options={userList}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formData.associate === null ? "Select an Option" : ""}
            />
          )}
          renderOption={renderOptions}
          value={formData.associate}
          onChange={(e: any, newValue: string | null) =>
            onInputSelectionChange(e, newValue, "associate")
          }
        />
      </div>
    );
  };

  const reviewerElement = () => {
    return (
      <div className={selectRole.both ? "reviewer row mt-4" : "reviewer row"}>
        <span className="col-2">Reviewer</span>
        <Autocomplete
          className="col-9 autocomplete"
          disablePortal
          id="combo-box-demo"
          options={userList}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={formData.reviewer === null ? "Select an Option" : ""}
            />
          )}
          renderOption={renderOptions}
          value={formData.reviewer}
          onChange={(e: any, newValue: string | null) =>
            onInputSelectionChange(e, newValue, "reviewer")
          }
        />
      </div>
    );
  };

  const dueDateElement = () => {
    return (
      <div className="dueDate row mt-4">
        <span className="col-2">Due date</span>
        <div className="col-9">
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <DatePicker
              // value={formData.dueDate}
              value={
                formData.dueDate == "" || formData.dueDate == undefined
                  ? undefined
                  : dayjs(formData.dueDate)
              }
              // onChange={(d) =>
              //   setFormData({ ...formData, dueDate: d })
              // }
              // placeholder={formData.dueDate === null ? "Due date (data)" : ""}
              placeholder="From"
              onChange={(newValue:any) =>{ 
                setFormData({ ...formData, dueDate: createDate(newValue) })
              }}
              format="MM-DD-YYYY"
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          {/* </LocalizationProvider> */}
        </div>
      </div>
    );
  };

  const dueDateReviewerElement = () => {
    return (
      <div className="dueDateReview row mt-4">
        <span className="col-2">Due date (review)</span>
        <div className="col-9">
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={formData.dueDateReview}
              onChange={(newValue) =>
                setFormData({ ...formData, dueDateReview: newValue })
              }
              label={formData.dueDateReview === null ? "Due date (review)" : ""}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
          der dateAdapter={AdapterDayjs}> */}
            <DatePicker
              // value={formData.dueDateReview}
              value={
                formData.dueDateReview == "" || formData.dueDateReview == undefined
                  ? undefined
                  : dayjs(formData.dueDateReview)
              }
              onChange={(newValue) =>
                setFormData({ ...formData, dueDateReview: createDate(newValue) })
              }
              // placeholder={formData.dueDate === null ? "Due date (data)" : ""}
              placeholder="From"
              format="MM-DD-YYYY"
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
        </div> 
      </div>
    );
  };

  const commentElement = () => {
    return (
      <div className="comment row mt-4">
        <span className="col-2">Comments</span>
        <div className="col-9">
          <TextField
            id="outlined-multiline-static"
            label="Enter Description"
            multiline
            rows={4}
            value={formData.comment}
            onChange={(e) =>
              setFormData({ ...formData, comment: e.target.value })
            }
          />
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setSelectRole({
      both: true,
      associate: false,
      reviewer: false,
    });
    setFormData({
      associate: null,
      dueDate: null,
      reviewer: null,
      dueDateReview: null,
      comment: "",
      associateID: null,
      reviewerID: null,
    });
    handleClose();
  };

  return (
    <Modal show={open} onHide={() => closeModal()} centered>
      <Modal.Header>
        <Modal.Title>Assign users</Modal.Title>
        <CloseIcon className="Close-icon" onClick={() => closeModal()} />
      </Modal.Header>
      <Modal.Body>
        <div className="bodyTop">
          <ButtonGroup aria-label="Select_Role" style={{ paddingLeft: "7.5em", paddingBottom: "1.2em" }}>
            <Button
              style={roleStyle(selectRole.both)}
              onClick={() => 
                {
                  changeRole("both")
                  setSelectedTab('1')
                }
              }
            >
              Both
            </Button>
            <Button
              style={roleStyle(selectRole.associate)}
              onClick={() => 
                {
                  changeRole("associate");
                  setSelectedTab('2');
                }
              }
            >
              Associate
            </Button>
            <Button
              style={roleStyle(selectRole.reviewer)}
              onClick={() => 
                {
                  changeRole("reviewer")
                  setSelectedTab('3')
                }
              }
            >
              Reviewer
            </Button>
          </ButtonGroup>
        </div>
        <div className="bodyMiddle">
          {(selectRole.both || selectRole.associate) && associateElement()}
          {(selectRole.both || selectRole.associate) && dueDateElement()}
          {(selectRole.both || selectRole.reviewer) && reviewerElement()}
          {(selectRole.both || selectRole.reviewer) && dueDateReviewerElement()}
          {commentElement()}
        </div>
        {fetchInfoProgress && 
          <span className="iqvia-color" >Please wait, fetching information for the selected records.</span>
        }
      </Modal.Body>
      <Modal.Footer>
        <span className="spanBtn" onClick={() => closeModal()}>
          Cancel
        </span>
        <span>|</span>
        <span className={"spanBtn " + (assignDisabled ? "assign-disabled" : "")} onClick={() => onSubmit()}>
          Assign
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignModal;
