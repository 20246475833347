import React from "react";
import { useEffect, useContext, useState } from "react";
import PathwayChart from "../Charts/PathwayChart";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setChangeFilter } from "../../store/pathwaySlice";
import apiCall from "../service/apiCall";

interface pathwayProps {
  currentDataSource: number;
  currentDataSourceName: string;
  setSelectedCriteria: any;
  setPathwaySearch: any;
  pathwaySearch?: boolean;
  pathwayFixed:boolean;
  defaultColumn:any;
  setPageSize: any;
  setCurrentPage: any;
}

const pathwayList = [
  "HTAs by Therapeutic Area",
  "HTAs by Disease",
  "HTAs by ATC 4th Level Description",
  "HTAs by Brand",
  "HTAs by Country",
  "HTAs by HTA Status",
];
const d_name = ["HTA", "Clinical", "Regulatory"];

export default function Pathway(props: pathwayProps) {
  let {
    currentDataSource,
    currentDataSourceName,
    setSelectedCriteria,
    setPathwaySearch,
    pathwaySearch,
    pathwayFixed,
    defaultColumn,
    setPageSize,
    setCurrentPage
  } = props;
  const [currpathwayList, SetCurrPathwayList] = useState<string[]>(pathwayList);
  const [pathwayPending, setPathwayPending] = useState<boolean>(false);
  const [pathwayLevel, setPathwayLevel] = useState<string[]>([]);
  const pathwayDatasourceId = useAppSelector((state) => state.pathway.pathwayDatasourceId);
  const level = useAppSelector((state) => state.pathway.level);
  const pathwayRule = useAppSelector((state) => state.pathway.pathwayRule);
  const dispatch = useAppDispatch();
  const [count, setCount] = useState<number>(0);
  const [pathwayFilterLevel, setPathwayFilterLevel] = useState(7);
  
  useEffect(() => {
    if (pathwayRule != "pathway-c-1" && pathwayRule != "pathway-r-1" || count == 0) {
      if (pathwayRule !== "") {
        apiCall(`/api/pathway/config/${pathwayRule}/${currentDataSource}`).then(
          (res) => {
            SetCurrPathwayList(res.data["header"] || []);
            setPathwayLevel(res.data["level"] || []);
            setCount(1);
          }
        );
      }
    }
  }, [pathwayRule, currentDataSource]);

  const BreadCrumCall = (index:number) => {
      setPathwayFilterLevel(index);
      if (currentDataSource === 2) {
        if (index === 0) {
          dispatch(setChangeFilter(0));
        } else if (index === 1) {
          dispatch(setChangeFilter(1));
        }
      }    
  }

  return (
    <>
      <h4 className="widget-title lighter homesubheading pathway">
        <b style={{ fontSize: "15px" }}>
          Follow a pathway to{" "}
          {pathwayRule == "pathway-c-1"
            ? d_name[1]
            : pathwayRule == "pathway-r-1"
            ? d_name[2]
            : d_name[currentDataSource - 1]}{" "}
          records:
        </b>
      </h4>

      <div className="col-md-12" style={{ borderBottom: "1px solid #DDD" }}>
        <div
          id="pathway-chevron-container"
          className="navbar-default navbar-breadcrumb"
          style={{ fontSize: "0.5em", width: "100%" }}
        >
          <ol
            className="cd-breadcrumb triangle custom-icons"
            id="pathway-breadcrumb"
          >
            {currpathwayList.map((item, index) => (
              <li
                key={index}
                className={index == level ? "current" : ""}
                onClick={() => {
                  BreadCrumCall(index);
                }}
              >
                <a>{item}</a>
              </li>
            ))}
          </ol>
        </div>
        <div className="container row">
          <div className="col-md-12">
            <PathwayChart
              ds_id={currentDataSource}
              setSelectedCriteria={setSelectedCriteria}
              setPathwaySearch={setPathwaySearch}
              pathwaySearch={pathwaySearch}
              pathwayLevel={pathwayLevel}
              pathwayFixed={pathwayFixed}
              pathwayPending={pathwayPending}
              setPathwayPending={setPathwayPending}
              pathwayFilterLevel={pathwayFilterLevel}
              setPathwayFilterLevel = {setPathwayFilterLevel}
              currpathwayList={currpathwayList}
              defaultColumn={defaultColumn}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
