import React,{useEffect, useState} from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useFetchAuditLogChangeDataMutation } from './AuditLogChangeApiSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from "@mui/icons-material/Close";
import {useAppDispatch, useAppSelector } from './hooks';
import GetDatasourceName from '../components/AutocompleteModule/utility';


export default (props: ICellRendererParams) => {
  const dispatch = useAppDispatch();
  const {data: gridData} = props;
  const isLastRow = props.node.rowIndex === props.api.getDisplayedRowCount() - 1;
  const [oldChanges, setOldChanges] = useState<any>({});
  const [newChanges, setNewChanges] = useState<any>({});
  const [keys, setKeys] = useState<String[]>([]);
  const [searchText, setSearchText] = useState('');

  const dataSourceId = useAppSelector(
    (state) => state.multiselect.datasource_id
  );

  let currentDataSourceName = GetDatasourceName(dataSourceId)

  const [fetchAuditLogChangeData] =
  useFetchAuditLogChangeDataMutation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const buttonClicked = async () => {
    setShow(true)
     let {ID, Field} = gridData;

    const fieldArray = Field.split(',');
    const searchResult : any = await fetchAuditLogChangeData({datasource_id: dataSourceId, Id:ID});
    const firstRecord = searchResult.data[0];
    const secondRecord = searchResult.data[1];
    const oldChangesObj: any = {};
    const newChangesObj: any = {};
    let nameArray = [];
    fieldArray.forEach((field: string) => {
      if (currentDataSourceName == "HTARecord") {
        if (field.startsWith("HTARecord_")) {
          if(field.endsWith("Disease_ID")){
            field = field.replace("Disease_ID", "Disease")
            nameArray = field.split('_');
            field = nameArray[1] + "Names";
          } 
          else if (field.endsWith("_Drug"))
          {
              field = "RecordDrugNames";
          }
          else if (field.endsWith("_Drug_Combination"))
          {
              field = "DrugCombination";
          }
          else if (field.endsWith("_DrugComparator_Combination"))
          {
              field = "DrugComparatorCombination";
          } else {
              nameArray = field.split('_');
              field = nameArray[1] + "Names";
          }
        }
      } else {
        if (field.startsWith("${currentDataSourceName}+_ ")) {
          nameArray = field.split('_');
          field = nameArray[1] + "Names";
        }
      }
    
      if(field == "QAssociate") {
        field = field.replace("QAssociate", "AssociateName");
      } else if(field == "QReviewer") {
        field = field.replace("QReviewer", "ReviewerName");
      } else if(field.endsWith("_ID")) {
        field = field.replace("_ID", "Name")
        if(Object.keys(oldChanges).includes(field)) {
          return field;
        } else if(!(Object.keys(oldChanges).includes(field))) 
        {
          field = field.replace("_ID", "Names");
          if(Object.keys(oldChanges).includes(field)) {
            return field;
          }
        } else if(!(Object.keys(oldChanges).includes(field))) 
        {
          field = field.replace("_ID", "LIValue");
          if(Object.keys(oldChanges).includes(field)) {
            return field;
          }
        }
      } 
      else if(field.endsWith("IDs")) {
        field = field.replace("IDs", "Names")
      }
      oldChangesObj[field] = firstRecord[field];
      newChangesObj[field] = secondRecord[field]; 
    });
    setOldChanges(oldChangesObj);
    setNewChanges(newChangesObj);
  };
  useEffect(() => {
    if(Object.keys(oldChanges).length > 0){
      setKeys(Object.keys(oldChanges))
    }

  }, [oldChanges]);

  useEffect(()=>{
    if(searchText != "" && keys.length > 0 ){
      setKeys(keys.filter(x => x.toLowerCase().includes(searchText)));
    } 
    else{
      setKeys(Object.keys(oldChanges))
    }

  },[searchText])
  return (
    <span>
      {!isLastRow && <Button onClick={() => buttonClicked()}>Changes</Button>}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Change details</Modal.Title>
          <input
            style={{ width: "200px", textAlign: "left" }}
            type="text"
            id="search-fieldName-id"
            placeholder="Search field name"
            onChange={(e) => {
              setSearchText(e.target.value.toLowerCase());
            }}
          />
          <CloseIcon className="Close-icon" onClick={() => handleClose()} />
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div
              className="col-sm-4 control-label no-padding-right"
              data-toggle="tooltip"
              data-placement="right"
            >
              <h5
                className="pull-right"
                style={{ fontWeight: "bold", paddingRight: "20%" }}
              >
                Field
              </h5>
            </div>
            <div className="col-sm-4 top-padding">
              <h5 style={{ fontWeight: "bold", paddingLeft: "30%" }}>
                Old value
              </h5>
            </div>
            <div className="col-sm-4 top-padding">
              <h5 style={{ fontWeight: "bold", paddingLeft: "20%" }}>
                New value
              </h5>
            </div>
          </div>
          {keys.filter((x) => x).map((key, index) => (
            <div id="change-details-outer-div">
              <div className="modal-body no-padding-bottom">
                <div id="" className="form-group">
                  <label
                    className="col-sm-4 control-label no-padding-right"
                    data-toggle="tooltip"
                    data-placement="right"
                    style={{paddingRight:"85px",wordBreak:"break-word"}}
                  >
                    {key} &nbsp;&nbsp;:
                  </label>
                  {oldChanges[key] ? (
                    <div className="col-sm-4 top-padding">
                      <span
                        id="oldText"
                        style={{ paddingLeft:"90px",wordBreak:"break-word"}}
                        dangerouslySetInnerHTML={{ __html: oldChanges[key] }}
                      ></span>
                    </div>
                  ) : (
                    <span style={{ paddingLeft: "120px",width:"295px"}}>-</span>
                  )}
                  <div className="col-sm-4 top-padding">
                    <span
                      id="newText"
                      dangerouslySetInnerHTML={{ __html: newChanges[key] }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <span
            className="spanBtn"
            style={{ color: "skyblue" }}
            onClick={() => handleClose()}
          >
            Cancel
          </span>
        </Modal.Footer>
      </Modal>
    </span>
  );
};
