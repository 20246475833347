import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setSelectedLookupGlobe } from "../../../../store/MultiSelectSlice";
import { IselectedLookupGlobe } from "../../../interfaces/multiselect";
import apiCall from "../../../service/apiCall";
import FieldView from "../../FieldView";
import { htaExtrapolationFields } from "./Utilty";

interface IHTARecord_ExtrapolationForm {
      values: any;
      fieldChanged: (name?: string, value?: string, obj?: any) => void;
      RichtextFieldChanged: (name: string, value: EditorState) => void;
      datasourcename: string;
      datasourceId: number;
      formMode?: string
      showEmptyFieldsFlag: boolean;
}

const HTARecord_ExtrapolationForm = ({
      values,
      fieldChanged,
      RichtextFieldChanged,
      datasourcename,
      datasourceId,
      formMode,
      showEmptyFieldsFlag
}: IHTARecord_ExtrapolationForm) => {

      const [field, setField] = useState<Field[]>(htaExtrapolationFields);
      const selectedLookupGlobe = useAppSelector(
            (state) => state.multiselect.selectedLookupGlobe
      );

      const dispatch = useAppDispatch();

      // useEffect(() => {
      //       const fetchFieldData = async () => {
      //             const res = await apiCall(`/api/admin/extrapolation/field`);
      //             const _field: Field[] = res.data;
      //             setField(_field);
      //       }
      //       fetchFieldData()
      // }, [])

      useEffect(() => {
            // if(formMode != "write"){
                  if (selectedLookupGlobe.HTARecord_ExtrapolationMultiselet?.length > 0 ) {
                        if (values.HTA_Extrapolation?.length > 0 )  {
                              let _HTA_Extrapolation: any[] = values.HTA_Extrapolation;
                              const htaExtrapolationIds: number[] = _HTA_Extrapolation.map((el: any) => el.Extrapolation_ID);
                              const htaRecordExtrapolationMultiselectids: number[] =
                                    selectedLookupGlobe.HTARecord_ExtrapolationMultiselet.map((el) => el.Id);
                              let idsToBeRemoved : number[] = [];
                              let indexTobeRemoved :  number[]= [];
                              htaExtrapolationIds.forEach((id,index) => {
                                    if(!htaRecordExtrapolationMultiselectids.includes(id)){
                                          idsToBeRemoved.push(id);
                                          indexTobeRemoved.push(index);
                                    }
                              });
                              const multiselectValuesTobeAdded =
                                    selectedLookupGlobe.HTARecord_ExtrapolationMultiselet.filter((el) => !htaExtrapolationIds.includes(el.Id));
                              _HTA_Extrapolation =
                                    _HTA_Extrapolation.filter((el) => !idsToBeRemoved.includes(el.Extrapolation_ID));
                              multiselectValuesTobeAdded.forEach((el) => {
                                    _HTA_Extrapolation.push({
                                          Extrapolation_ID: el.Id,
                                          Extrapolation_Name: el.Value
                                    });                              
                              });
                              let _selectedLookupGlobe = {...selectedLookupGlobe};
                              indexTobeRemoved.forEach((i) => {
                                    delete _selectedLookupGlobe["CurveFits_ID_"+String(i)];
                                    _selectedLookupGlobe = reArrangeIndexwithKeys("CurveFits_ID_",_selectedLookupGlobe,i);
                                    delete _selectedLookupGlobe["Extrapolation_AdjustmentMethods_ID_"+String(i)];
                                    _selectedLookupGlobe = reArrangeIndexwithKeys("Extrapolation_AdjustmentMethods_ID_",_selectedLookupGlobe,i);
                              });
                              if(multiselectValuesTobeAdded.length > 0 || indexTobeRemoved.length > 0){
                                    // _selectedLookupGlobe = reArrangeIndexwithKeys("CurveFits_ID_",_selectedLookupGlobe);
                                    // _selectedLookupGlobe = reArrangeIndexwithKeys("Extrapolation_AdjustmentMethods_ID_",_selectedLookupGlobe);
                                    fieldChanged("HTA_Extrapolation", "", _HTA_Extrapolation);
                                    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
                              }
                        } else {
                              const HTARecord_ExtrapolationMultiselet =
                                    selectedLookupGlobe.HTARecord_ExtrapolationMultiselet;
                              let _HTA_Extrapolation: any = [];
                              HTARecord_ExtrapolationMultiselet.forEach((el) => {
                                    _HTA_Extrapolation.push({
                                          Extrapolation_ID: el.Id,
                                          Extrapolation_Name: el.Value
                                    });
                              });
                              fieldChanged("HTA_Extrapolation", "", _HTA_Extrapolation);
                        }
                  } else if(datasourceId == 1) {
                        fieldChanged("HTA_Extrapolation", "", []);
                        deleteExtrapolationMultiselect();
                  }
            // }

      }, [selectedLookupGlobe.HTARecord_ExtrapolationMultiselet])

      const reArrangeIndexwithKeys = (key: string,selectedLookupGlobe:IselectedLookupGlobe,index : number) => {
            let _selectedLookupGlobe = {...selectedLookupGlobe};
            for (let i = index ; i < selectedLookupGlobe.HTARecord_ExtrapolationMultiselet.length; i++){
                  const keyName = key + String(index);
                  const nextKeyName = key + String(index+1);
                  _selectedLookupGlobe[keyName] = _selectedLookupGlobe[nextKeyName];
                  delete _selectedLookupGlobe[nextKeyName];
            }
            // const keys = Object.keys(selectedLookupGlobe).filter((k) => k.includes(key));
            // keys.forEach((k) => {
            //       delete selectedLookupGlobe[k]
            // });
            return _selectedLookupGlobe;
      }

      const deleteExtrapolationMultiselect = () => {
            let _selectedLookupGlobe = {...selectedLookupGlobe};
            const keysToBeDeleted  :string[] = Object.keys(_selectedLookupGlobe).filter((key) => key.includes("CurveFits_ID_" || "Extrapolation_AdjustmentMethods_ID_" ));
            keysToBeDeleted.forEach((key) => delete _selectedLookupGlobe[key]);
            dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
      }

      const updateExtrapolation = (obj: any, index: number) => {
            const _HTA_Extrapolation = [...values.HTA_Extrapolation];
            _HTA_Extrapolation[index] = obj;
            fieldChanged("HTA_Extrapolation", "", _HTA_Extrapolation);
          };

      const validateExtrapolation = (field: Field, index: number) => {
            const _HTA_Extrapolation = values.HTA_Extrapolation[index];
            if (field.name == "ExTreatment") {
                  if (_HTA_Extrapolation.Extrapolation_Name == "Overall survival (OS)")
                        return true
                  else return false
            } else if (field.name === "Extrapolation_AdjustmentMethods_ID" || field.name === "TreatmentManufacturersBasecase_ID") {
                  if( _HTA_Extrapolation.ExTreatment)
                        return true
                  else return false
            }
            return true
      }

      const showHideFilter = (field: Field, index: number) => {
            const HTA_Extrapolation = { ...values.HTA_Extrapolation[ index ] };
            if (showEmptyFieldsFlag || formMode != "read") {
                  return true
            } else {
                  if (HTA_Extrapolation[ field.name ] ||
                        HTA_Extrapolation[ field.name ] == false ||
                        selectedLookupGlobe[ field.name + `_${index}` ]?.length > 0 ||
                        field.name == "ExtrapRWEProvided_ID")
                        return true
                  else
                        return false
            }

      }

      return (
            <div>
                  {
                        values?.HTA_Extrapolation &&
                        values.HTA_Extrapolation.map((el: any, index: number) => (
                              <>
                                    <h6>
                                          <label>{el.Extrapolation_Name}</label>
                                    </h6>
                                    <hr />
                                    {
                                          field.filter((f) => validateExtrapolation(f,index)).filter((x) => showHideFilter(x,index)).map((f) => (
                                                <FieldView
                                                      field={f}
                                                      values={values}
                                                      fieldChanged={fieldChanged}
                                                      RichtextFieldChanged={RichtextFieldChanged}
                                                      datasourcename={datasourcename}
                                                      section={"Extrapolation"}
                                                      datasourceId={datasourceId}
                                                      parentObject={values.HTA_Extrapolation[index]}
                                                      setObj={updateExtrapolation}
                                                      index={index}
                                                      vi={index}
                                                      formMode={formMode}
                                                />
                                          ))
                                    }
                              </>
                        ))
                  }
            </div>
      )
}

export default HTARecord_ExtrapolationForm;