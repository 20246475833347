import React, { useState, useEffect } from "react";
import "./InternalUserTracker.css";
import "../../app.css";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useInternalUserTrackerMutation } from "../../store/InternalUserApiSlice";

interface InternalUserProp {
  open: boolean;
  handleClose: Function;
}

const InternalUserTracker = ({ open, handleClose }: InternalUserProp) => {
  const initialFormState = {
    workType: "P",
    picName: "",
    description: "",
    startDate: "",
    endDate: "",
  };
  const intialProjectCode = {
    projectCode: "",
  };

  const initilFormErrors = {
    workType: false,
    projectCode: false,
    picName: false,
    description: false,
    startDate: false,
    endDate: false,
  };

  const [projectCode, setProjectCode] = useState(intialProjectCode);
  const [proceed, setProceed] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [formValues, setFormValues] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState(initilFormErrors);

  //const userId = sessionStorage.getItem("userid");

  const [internalUserTracker] = useInternalUserTrackerMutation();

  const saveInternalUserDetails = async () => {
    const internaluser = {
      //userId: parseInt(userId),
      workType: formValues.workType,
      projectCode: projectCode.projectCode,
      startDate: new Date(formValues.startDate),
      endDate: new Date(formValues.endDate),
      description: formValues.description,
      picName: formValues.picName,
    };
    const internaluserData = await internalUserTracker(internaluser);
  };
  const internalUsageProceed = () => {
    setProceed(true);
  };

  const formSubmit = () => {
    saveInternalUserDetails();
  };

  const internalUsageProjectCode = (e) => {
    let { name, value } = e.target;

    let projectArr = [
      "1234567",
      "1111111",
      "2222222",
      "3333333",
      "4444444",
      "5555555",
      "6666666",
      "7777777",
      "8888888",
      "9999999",
    ];
    if (projectArr.includes(value)) {
      alert("Invalid project code " + value);
      value = "";
    }
    if (value == 0) {
      value = "";
    }
    if (value) {
      value = value.replace(/\D/g, "");
    }
    setProjectCode((prev) => ({ ...prev, [name]: value }));
  };

  const internalUsageInput = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const clearInternalUser = () => {
    setFormValues(initialFormState);
    setProjectCode(intialProjectCode);
    setFormErrors(initilFormErrors);
  };

  const closeInternalUser = () => {
    handleClose();
  };
  const validate = () => {
    let isValid = true;
    if (!formValues.workType) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, workType: true }));
    }
    if (formValues.workType === "P" && projectCode.projectCode.length != 7) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, projectCode: true }));
    }
    if (formValues.workType === "P" && !formValues.picName) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, picName: true }));
    }
    if (!formValues.startDate) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, startDate: true }));
    }
    if (!formValues.endDate) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, endDate: true }));
    }
    if (!formValues.description) {
      isValid = false;
      setFormErrors((prev) => ({ ...prev, description: true }));
    }
    return isValid;
  };

  const validateAndSubmit = () => {
    const isValid = validate();
    if (isValid) {
      formSubmit();
      clearInternalUser();
    }
    return isValid;
  };

  const conformNextInternalUser = () => {
    const isValid = validateAndSubmit();
    if (isValid) {
      setShowClose(true);
    }
  };
  const conformCloseInternalUser = () => {
    const isValid = validateAndSubmit();
    if (isValid) {
      setProceed(false);
      handleClose();
    }
  };

  return (
    <Modal
      dialogClassName="internal-user-modal"
      id="modal-internal-usage"
      show={open}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      // style={{ top: "50px" }}
    >
      <Modal.Header className="modal-header-footer-bg-color">
        <Modal.Title
          style={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Internal usage tracker
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!proceed ? (
          <div
            style={{
              fontSize: "11px",
            }}
          >
            <div>
              <p className="internal-p">
                The uptake of HTA Accelerator across IQVIA has increased
                significantly and we need to track its contribution to our
                business more effectively. Therefore, we kindly request you
                adhere to the rules below and provide us with a few details so
                you continue to benefit from the content and investment into the
                platform.
              </p>
              <p>
                Please reference HTA Accelerator in BD and TL documents going
                out to external parties and send a copy to{" "}
                <a
                  href="mailto:hta-accelerator@iqvia.com"
                  className="internal-anchor-link"
                >
                  hta-accelerator@iqvia.com
                </a>
              </p>
              <p>
                In proposals, please include a 6,000 USD / 5,000 EUR one-time
                data access fee for clients who currently do not have access to
                the relevant diseases in scope of the proposed project work.
                This fee needs to be included in CRM as a product code based on
                the IQVIA sales organization that issues the invoice. Please
                find the product code based on your location and business unit
                below
              </p>
            </div>
            <table
              className="table table-striped pcode-table"
              style={{ marginRight: "10%", width: "75%", color: "#0f0f10" }}
            >
              <tbody
                style={{
                  fontSize: "11px",
                }}
              >
                <tr style={{ backgroundColor: "lightgray" }}>
                  <th>Type of Fee</th>
                  <th>Location</th>
                  <th>Consulting</th>
                  <th>RWS</th>
                </tr>
                <tr>
                  <td>Data Access</td>
                  <td>IQVIA UK</td>
                  <td>IWD_HTA</td>
                  <td>IWD_HTA_MED</td>
                </tr>
                <tr>
                  <td>Data Access</td>
                  <td>IQVIA US</td>
                  <td>US_HTA</td>
                  <td>US_HTA_MED</td>
                </tr>
                <tr>
                  <td>Data Access</td>
                  <td>IQVIA DE</td>
                  <td>DE_HTA</td>
                  <td>DE_HTA_MED</td>
                </tr>
                <tr>
                  <td>Data Access</td>
                  <td>IQVIA FR</td>
                  <td>FR_HTA</td>
                  <td>FR_HTA_MED</td>
                </tr>
                <tr>
                  <td>Data Access</td>
                  <td>IQVIA CH</td>
                  <td>CH_HTA</td>
                  <td>CH_HTA_MED</td>
                </tr>
                <tr>
                  <td>Project work</td>
                  <td>-</td>
                  <td>MC_1099</td>
                  <td>MC_1121A</td>
                </tr>
              </tbody>
            </table>
            Thank you on behalf of the entire HTA Accelerator team.
            <div
              style={{
                textAlign: "left",
                padding: "px 5px 0px 5px",
                fontWeight: "normal",
                marginTop: "7px",
                borderTop: "1px dotted gray",
                fontSize: "11px",
              }}
            >
              <p style={{ marginTop: "7px" }}>
                <strong style={{ color: "black" }}>NOTE</strong>: Dear IQVIA
                users, we are changing our terms and conditions for the use of
                HTA Accelerator on paid projects for non-subscribing clients to
                ensure that HTA Accelerator revenue targets can be achieved.
              </p>
              <p>
                I confirm that the 6,000 USD / 5,000 EUR HTA Accelerator data
                access fee for my current paid project on behalf of a
                non-subscribing client has either been assigned to the HTA
                Accelerator product code in CRM or I authorize the HTA
                Accelerator team to have the product code added to CRM now.
                Please inform your PIC accordingly.
              </p>
              <p>
                In case of questions, please contact{" "}
                <a
                  href="mailto:hta-accelerator@iqvia.com"
                  className="internal-anchor-link"
                >
                  hta-accelerator@iqvia.com
                </a>
              </p>
            </div>
          </div>
        ) : (
          <>
            <Container>
                <form style={{width:"97%"}} onSubmit={conformCloseInternalUser}>
                  <Row>

                    <div className="form-group">
                      <label
                        className="col-sm-4 col-form-label IUT-label-alignment control-label-plain-text required"
                        style={{ fontSize: "13px" }}
                      >
                        What are you working on?
                      </label>
                      <select
                        className="form-select no-border-radius"
                        name="workType"
                        value={formValues.workType}
                        required
                        onChange={internalUsageInput}
                      >
                        <option value="P">Project</option>
                        <option value="L">Proposal</option>
                        <option value="T">Thought leadership</option>
                        <option value="O">Other</option>
                      </select>
                    </div>

                  </Row>
                  <Row>
                    <div className="form-group">
                      <label
                        className={`col-sm-4 col-form-label IUT-label-alignment control-label-plain-text ${formValues.workType === "P" ? "required " : ""
                          }`}
                        style={{ fontSize: "13px" }}
                      >
                        Provide a project code
                      </label>
                      <input
                        className={`form-control error ${formErrors.projectCode && "invalid"
                          }`}
                        required={formValues.workType === "P"}
                        type="text"
                        //pattern="[0-9]*"
                        maxLength={7}
                        name="projectCode"
                        value={projectCode.projectCode}
                        onChange={internalUsageProjectCode}
                      />

                      {formErrors.projectCode &&
                        projectCode.projectCode.length == 0 && (
                          <span
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Field is required
                          </span>
                        )}
                      {formErrors.projectCode &&
                        projectCode.projectCode.length > 0 &&
                        projectCode.projectCode.length != 7 && (
                          <span
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Project code should be 7 digits
                          </span>
                        )}
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <label
                        className={`col-sm-4 col-form-label IUT-label-alignment control-label-plain-text ${formValues.workType === "P" ? "required " : ""
                          }`}
                        style={{ fontSize: "13px" }}
                      >
                        PIC name (Principal in-charge)
                      </label>
                      <input
                        className={`form-control ${formErrors.picName && "invalid"
                          }`}
                        type="text"
                        name="picName"
                        value={formValues.picName}
                        required={formValues.workType === "P"}
                        onChange={internalUsageInput}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <label
                        className="col-sm-4 col-form-label IUT-label-alignment control-label-plain-text required"
                        style={{ fontSize: "13px" }}
                      >
                        Start date
                      </label>
                      <input
                        className={`form-control datepicker date-width col-md-3 ${formErrors.startDate && "invalid"
                          }`}
                        name="startDate"
                        value={formValues.startDate}
                        type="date"
                        required
                        onChange={internalUsageInput}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <label
                        className="col-sm-4 col-form-label IUT-label-alignment control-label-plain-text required"
                        style={{ fontSize: "13px" }}
                      >
                        End date
                      </label>
                      <input
                        className={`form-control date-width datepicker col-md-3  ${formErrors.endDate && "invalid"
                          }`}
                        name="endDate"
                        required
                        value={formValues.endDate}
                        type="date"
                        onChange={internalUsageInput}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <label
                        className="col-sm-4 col-form-label IUT-label-alignment control-label-plain-text required"
                        style={{ fontSize: "13px" }}
                      >
                        Provide a brief description
                      </label>
                      <textarea
                        className={`form-control ${formErrors.description && "invalid"
                          }`}
                        name="description"
                        value={formValues.description}
                        required
                        onChange={internalUsageInput}
                      ></textarea>
                    </div>
                  </Row>
                </form>
            </Container>
            <div
              style={{
                textAlign: "left",
                padding: "px 5px 0px 5px",
                fontWeight: "normal",
                marginTop: "7px",
                borderTop: "1px dotted gray",
                fontSize: "11px",
              }}
            >
              <p style={{ marginTop: "7px" }}>
                <strong>NOTE</strong>: Dear IQVIA users, we are changing our
                terms and conditions for the use of HTA Accelerator on paid
                projects for non-subscribing clients to ensure that HTA
                Accelerator revenue targets can be achieved.
              </p>
              <p>
                I confirm that the 6,000 USD / 5,000 EUR HTA Accelerator data
                access fee for my current paid project on behalf of a
                non-subscribing client has either been assigned to the HTA
                Accelerator product code in CRM or I authorize the HTA
                Accelerator team to have the product code added to CRM now.
                Please inform your PIC accordingly.
              </p>
              <p>
                In case of questions, please contact{" "}
                <a
                  href="mailto:hta-accelerator@iqvia.com"
                  className="internal-anchor-link"
                >
                  hta-accelerator@iqvia.com
                </a>
              </p>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-header-footer-bg-color">
        {!proceed ? (
          <button
            className="internaluser-button"
            onClick={internalUsageProceed}
          >
            Proceed
          </button>
        ) : (
          <div>
            <button className="btn btn-info hidden" onClick={clearInternalUser}>
              Clear
            </button>
            <button
              type="submit"
              className="internaluser-btn"
              onClick={conformCloseInternalUser}
            >
              Confirm & close
            </button>
            <button
              type="submit"
              className="internaluser-btn"
              onClick={conformNextInternalUser}
            >
              Confirm & next
            </button>
            {showClose && (
              <button className="internaluser-btn" onClick={closeInternalUser}>
                Close
              </button>
            )}
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InternalUserTracker;
