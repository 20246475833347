import React, {useRef} from "react";
import Popover from "@mui/material/Popover";
import Draggable from "react-draggable";
import PopupContent from "./PopupContent";
import Backdrop from "@mui/material/Backdrop";
import { useEffect } from "react";

interface DraggablePopoverProps {
  anchorEl: HTMLButtonElement | null;
  title: string;
  popupBody: React.ReactNode;
  buttonsArray: String[];
  btnCallbacks: React.MouseEventHandler<HTMLButtonElement>[];
  onPopupClose: React.MouseEventHandler<HTMLButtonElement>;
  keyName? : string
}

export default function DraggablePopover(props: DraggablePopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    props.anchorEl
  );

  const nodeRef = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        {popupBtnName || 'Open Popover'}
      </Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Draggable  enableUserSelectHack={false} nodeRef={nodeRef}>
          <div ref={nodeRef}>
          <Popover
            
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={props.onPopupClose}
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "center",
            // }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
            <PopupContent
              title={props.title}
              popupBody={props.popupBody}
              buttonsArray={props.buttonsArray}
              btnCallbacks={props.btnCallbacks}
              onPopupClose={props.onPopupClose}
              keyName = {props.keyName}
            />
          </Popover>
          </div>
        </Draggable>
      </Backdrop>
    </div>
  );
}
