import React, { useState } from "react";
import style from "./Alertbox.module.css";
import css from "classnames";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsInfoCircleFill } from "react-icons/bs";
import { BiCommentError } from "react-icons/bi";
import { AiOutlineWarning } from "react-icons/ai";
import Content from "./ReadMore";
import { faMaximize, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./Alertbox.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
interface alertProp {
  // title: string,
  // msg: string,
  // type: string,
  // modalShow: boolean;
  alertState: any[];
  setModalShow: (val: any[]) => void;
}

interface propType {
  type: string;
}

function ChooseIcon(props: propType) {
  const type = props.type;
  if (type == "warning") {
    return <AiOutlineWarning style={{ fontSize: "13px" }} />;
  } else if (type == "error") {
    return <BiCommentError style={{ fontSize: "13px" }} />;
  }
  return <BsInfoCircleFill style={{ fontSize: "13px" }} />;
}

function NewAlert(props: alertProp) {
  const setNewModalShow = props.setModalShow;
  // const { title, msg, type, modalShow } = props
  const Title = props.alertState[0];
  const Type = props.alertState[1];
  const Message = props.alertState[2];
  const url = props.alertState[3];
  const ModalShow = props.alertState[4];

  const [show, setShow] = useState(true);

  const handleClose = () => {
    props.setModalShow(["", "", "", false]);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const dispatch = useAppDispatch();
  const accountType = useAppSelector((state) => state.userProfile.accountType);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {ModalShow && (
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header
            style={{ backgroundColor: "#fafafa" }}
            className={css(style[Type])}
          >
            <Modal.Title className="row">
              {/* <ChooseIcon type={Type} /> */}
              <div className="col-sm-10">{Title}
                </div>
              
              <div className="col-sm-2">
                <FontAwesomeIcon
                  style={{
                    fontSize: "19px",
                    cursor: "pointer",
                    marginLeft:"60px",
                    paddingTop:"9px"
                  }}
                  size="sm"
                  icon={faMaximize}
                  onClick={() => window.open(url, '_blank', 'noopener')}
                />
                    <FontAwesomeIcon
                  style={{ padding: "6px", fontSize: "26px", cursor: "pointer",float:"right"}}
                  onClick={handleClose}
                  icon={faXmark}
                  size="xl"
                />
              </div>
              
            </Modal.Title>
            
          </Modal.Header>
          <Modal.Body>
            {/* <Content txt={Message} /> */}
            <label
                dangerouslySetInnerHTML={{ __html: Message || "No Instructions available." }}
            ></label>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#fafafa" }}>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            <a style={{ color: "#00aae7" }} onClick={handleClose}>
              Cancel
            </a>
            <a style={{ color: "#00aae7",cursor:"pointer" }} onClick={() => openInNewTab(url)}>
              {accountType && accountType === "EClient"
                ? ""
                : "Edit instruction"}
            </a>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
export default NewAlert;
