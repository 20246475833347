import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch, useLocation, Link, useHistory } from "react-router-dom";
import {
  useFetchSectionMutation,
  useFetchFieldMutation,
} from "../../store/DataSourceMetaDataApiSlice";
import { Section, Field, Comment } from "../../store/DataSourceMetaData";
import { getCommentTableName, getIdFieldName } from "../../utils/index";
import apiCall from "../service/apiCall";
import moment from "moment";
import {
  useFetchLookupItemsMutationMutation,
  useFetchLookupItemsQuery,
} from "../../store/LookupApiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Comments from "../Forms/Comments";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IselectedLookupGlobe, IselectedLookupGlobeSpecial } from "../interfaces/multiselect";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FormFieldType } from "../Shared/Utility";
import { datasourceMap, hyperlinkList, LinkStyle, lookupIdWithParentValue } from "../Forms/FieldView";
import ComplexLookupSplit from "../Forms/Utility";
import { setSelectedDataState, setSelectedLookupGlobe, setSelectedLookupGlobeSpecial } from "../../store/MultiSelectSlice";

interface lookupItemT {
  id: string;
  label: string;
}

type ClientObj = {
  label: string;
  ID: number;
};

const CompareRecords = () => {
  const [dataSourceID, setDataSourceID] = useState<number>(1);
  const [recordID_1, setRecordID_1] = useState<number>();
  const [recordID_2, setRecordID_2] = useState<number>();
  const [srows, setSrows] = useState<Section[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentsRecordID_1, setcommentsRecordID_1] = useState<Comment[]>([]);
  const [commentsRecordID_2, setCommentsRecordID_2] = useState<Comment[]>([]);
  const [dataRecordID_1, setDataRecordID_1] = useState<any>({});
  const [dataRecordID_2, setDataRecordID_2] = useState<any>({});
  const [fieldRows, setFieldRows] = React.useState<Field[]>([]);
  const [listLookUpIDs, setListLookUpIDs] = useState([]);
  const [items, setItems] = useState<any>({});
  const [clientList, setClientList] = useState<ClientObj[]>([]);
  const myRef = useRef();
  const [selectedLookupGlobeRecord_1, setSelectedLookupRecord_1] =
    useState<IselectedLookupGlobe>({});
  const [selectedLookupGlobeRecord_2, setSelectedLookupRecord_2] =
    useState<IselectedLookupGlobe>({});
  const [url, setUrl] = useState<string>("");
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>(false);

  // Router
  const routeMatch = useRouteMatch();
  const location = useLocation();

  // Style
  const titleStyle = {
    fontSize: "15px",
    fontWeight: 700,
    color: "#00AAE7",
  };

  // Methods
  const [fetchSection, { data, error, isLoading, isSuccess }] =
    useFetchSectionMutation();
  const [fetchField, { isLoading: isLoadingFields }] = useFetchFieldMutation();
  const [fetchLookupItems] = useFetchLookupItemsMutationMutation();
  const fetchClients = useFetchLookupItemsQuery({
    lookup_id: 127,
    datasource_id: dataSourceID,
  });

  const dispatch = useAppDispatch();
  const accountType = useAppSelector((state) => state.userProfile.accountType);
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );

  useEffect(() => {
    if (fetchClients.data && fetchClients.data.length > 0) {
      let arr = [];
      for (let c of fetchClients.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setClientList(arr);
    }
  }, [fetchClients.isLoading]);

  const initialize = async () => {
    setLoading(true);
    // console.log("routeMatch", routeMatch, "location", location);
    let sourceID = routeMatch.params.dataSourceID;
    setDataSourceID(sourceID);
    setUrl(location.pathname + location.search);
    let searchSplit = location.search.split("&");
    let firstID = searchSplit[0].split("=")[1];
    let secondID = searchSplit[1].split("=")[1];
    if (!!firstID) setRecordID_1(Number(firstID));
    if (!!secondID) setRecordID_2(Number(secondID));

    if (accountType != null && accountType != "") {
      // Data
      await fetchRecordsData(sourceID, Number(firstID), Number(secondID));

      // Sections

      await fetchSections(sourceID, Number(firstID), Number(secondID));


      // Comments
      await fetchComments(sourceID, Number(firstID), Number(secondID));

      // Fields
      await fetchFields(sourceID);

      // LookupGlobe
      await fetchLookupGlobe(sourceID, Number(firstID), Number(secondID));

      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [accountType]);

  const fetchSections = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    const result : any = await fetchSection({
      dataSourceID: sourceID,
      roleId : 0
    });
    // console.log("result++", result);
    let _rows = [];
    if (result && result["data"]) {
      if (
        Number(firstID) == 0 ||
        firstID == null ||
        isNaN(Number(firstID)) ||
        Number(secondID) == 0 ||
        secondID == null ||
        isNaN(Number(secondID))
      ) {
        _rows = result.data.filter(
          (x) => x.name != "Record details" && x.name != "Search results"
        );
      } else if (accountType === "EClient") {
        _rows = result.data.filter(
          (x) => x.name != "Record details" && x.name != "Search results"
        );
        //console.log("_rows :", _rows);
      } else {
        _rows = result.data.filter((x:any) => x.name != "Search results");
      }
      // console.log("rows", _rows);
      setSrows(_rows);
    }
  };

  const fetchComments = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    let tableName = getCommentTableName(Number(sourceID));
    let idColumnName = getIdFieldName(Number(sourceID));
    // console.log("tableName", tableName, "idColumnName", idColumnName);

    let commentFirstID = await apiCall(
      `/api/fetchComments/${tableName}/${firstID}/${idColumnName}`
    );
    // console.log("commentFirstID", commentFirstID);
    if (
      commentFirstID &&
      commentFirstID.data &&
      commentFirstID.data.length > 0
    ) {
      setcommentsRecordID_1(commentFirstID.data);
    }

    let commentSecondID = await apiCall(
      `/api/fetchComments/${tableName}/${secondID}/${idColumnName}`
    );
    // console.log("commentSecondID", commentSecondID);
    if (
      commentSecondID &&
      commentSecondID.data &&
      commentSecondID.data.length > 0
    ) {
      setCommentsRecordID_2(commentSecondID.data);
    }
  };

  const fetchRecordsData = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    let recordID_1 = await apiCall(`/api/datasource/${sourceID}/${firstID}/'view'`);
    //console.log("recordID_1", recordID_1);
    if (recordID_1 && recordID_1.data && recordID_1.data.length > 0) {
      setDataRecordID_1(recordID_1.data[0]);
    }

    let recordID_2 = await apiCall(`/api/datasource/${sourceID}/${secondID}/'view'`);
    //console.log("recordID_2", recordID_2);
    if (recordID_2 && recordID_2.data && recordID_2.data.length > 0) {
      setDataRecordID_2(recordID_2.data[0]);
    }
  };

  const fetchFields = async (sourceID: number) => {
    const result : any = await fetchField({ dataSourceID: sourceID });
    //console.log("fieldRows", result);

    if (result && result.data && result.data.length > 0) {
      setFieldRows(result.data);
      let tmp: any = [];
      let tmp2: any = {};

      for (let x of result.data) {
        if (x.lookupId !== null) {
          tmp.push(x.lookupId);
          let lookup :any = await fetchLookupItems({ lookup_id: Number(x.lookupId),datasource_id : sourceID });
          if (lookup.data && lookup.data.length > 0) {
            let lookupItems: lookupItemT[] = lookup.data.map((x:any) => ({
              label: String(x.Value),
              id: String(x.ID),
            }));
            tmp2[x.name] = lookupItems;
          }
        }
      }
      setListLookUpIDs(tmp);
      setItems(tmp2);
      // if (sections && sections.length > 0) setValue(sections[0].id.toString());
    }
  };

  const scrollToComment = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (myRef && myRef.current) myRef.current?.scrollIntoView();
  };

  const navigate = useHistory();

  const drugSearch = async (
    event: any,
    item: any,
    searchName: string,
    Country_ID:number,
    disease?: any,
     
  ) => {
    event.preventDefault();
    if (searchName === "HTA") {
      let obj = {
        DrugIDs: [item],
      };
      dispatch(setSelectedDataState(item));
      dispatch(setSelectedLookupGlobe(obj));
      navigate.push("/Search/ByDrug");
    } else if (searchName === "Pricing") {
      let country = await apiCall(`/api/getCountry/${Country_ID}`);
      if (country.data && country.data.length > 0) {
        let obj = {
          DrugIDs: [item],
          CountryIDs: [{ Id: Country_ID, Value: country.data[0].Name }],
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrugPricing");
      }
    } else if (searchName === "Clinical") {
      let obj = {
        DrugIDs: [item],
        SuperSearchDiseaseIDs: [disease[0]],
      };
      dispatch(setSelectedDataState(item));
      dispatch(setSelectedLookupGlobe(obj));
      navigate.push("/Search/ByDrugClinical");
    } else if (searchName === "Regulatory") {
      let regulator = await apiCall(`/api/getRegulator/${Country_ID}`);
      if (regulator.data && regulator.data.length > 0) {
        let obj = {
          DrugIDs: [item],
          SuperSearchDiseaseIDs: [disease[0]],
          RegulatorIDs: regulator.data,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrugRegulatory");
      }
    }
  };

  const displayDrugBrandName = (row: any,position:number) => {
    return (
      <span
        style={{
          width: "100%",
          display: "block",
          fontSize: "12px",
          color: "#00aae7",
        }}
      >
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Clinical",
              position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID,
              selectedLookupGlobe["HTARecord_PrimaryDisease_ID"]
            )
          }
        >
          Clinical
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Regulatory",
              position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID,
              selectedLookupGlobe["HTARecord_PrimaryDisease_ID"]
            )
          }
        >
          Regulatory
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) => drugSearch(e, row, "HTA",position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID)}
        >
          HTA
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) => drugSearch(e, row, "Pricing",position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID)}
        >
          Pricing
        </Link>
      </span>
    );
  };

  const displayContent = (
    name: string,
    fieldTypeId: number,
    position: number,
    lookupId: number,
    sectionName: string,
    vi?: number
  ) => {
    if (sectionName !== "Disease") {
      switch (fieldTypeId) {
        case 4:
          return (
            <label>
              {!isLoading &&
                getLookupLabel(name, position, fieldTypeId, lookupId)}
            </label>
          );
        case FormFieldType.Checkbox:
          return (
            <input
              type="checkbox"
              readOnly
              value={getLookupLabel(name, position, fieldTypeId, lookupId)}
              style={{ color: "rgb(0, 170, 231)", marginTop: 2 }}
              checked={getLookupLabel(name, position, fieldTypeId, lookupId)}
            />
          );
        case FormFieldType.MultiSelect:
        case FormFieldType.DrugSelect:
        case FormFieldType.DeviceSelect:
        case FormFieldType.ClinicalDataSelect:
          let msKey = "";
          if (vi != undefined) {
            msKey = name+"_"+position + "_" + String(vi);
          } else {
            msKey = name+"_"+position;
          }
          const hasKey = selectedLookupGlobe.hasOwnProperty(msKey);
          const hasKeySpecial =
            selectedLookupGlobeSpecial.hasOwnProperty(msKey);
          return (
            <>
              {hasKey &&
                hyperlinkList.includes(name) &&
                selectedLookupGlobe[msKey].map((row, index) => {
                  return (
                    <>
                      <a
                        key={index}
                        style={{
                          color: "#00aae7",
                          border: "none",
                          background: "none",
                          padding: "0px",
                          fontSize: "12px",
                          display: "block",
                        }}
                        href={`/view/${datasourceMap[msKey]}/${row.Id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.Value}
                      </a>
                      {name === "HTARecord_Drug" && displayDrugBrandName(row,position)}
                    </>
                  );
                })}
              {hasKey &&
                !hyperlinkList.includes(name) &&
                !lookupIdWithParentValue.includes(lookupId) &&
                selectedLookupGlobe[msKey].map((row) => {
                  return (
                    <span
                      key={row.Id}
                      style={{
                        width: "100%",
                        display: "block",
                        fontSize: "12px",
                      }}
                    >
                      {row.Value}
                    </span>
                  );
                })}
              {hasKey &&
                [
                  "HTARecord_Sourceofcomparator",
                  "HTARecord_CoaInstrument",
                ].includes(name) && (
                  <>
                    <ComplexLookupSplit data={selectedLookupGlobe[msKey]} />
                  </>
                )}
            </>
          );

        case 32:
          if (name.toLowerCase().indexOf("link") >= 0) {
            return (
              <a
                style={{
                  color: "#00aae7",
                  border: "none",
                  background: "none",
                  padding: "0px",
                  fontSize: "12px",
                }}
                href="/"
              >
                Link
              </a>
            );
          } else return "";

        default:
          return (
            <label
              dangerouslySetInnerHTML={{
                __html: getLookupLabel(name, position, fieldTypeId, lookupId),
              }}
            ></label>
          );
      }
    }
  };

  const getLookupLabel = (
    propertyName: string,
    position: number,
    fieldTypeId: number,
    lookupId: number
  ) => {
    if (propertyName == null || propertyName == "") {
      return "";
    }

    let _value =
      position === 1
        ? dataRecordID_1[propertyName]
        : dataRecordID_2[propertyName];

    if (_value == null || _value == "") return "";

    if (fieldTypeId == 15) {
      if (_value == null || _value == "") return "";
      else {
        return moment(_value).format("DD/MM/yyyy");
      }
    }

    if (lookupId == null) return _value;

    let lookupItem;
    if (items[propertyName]) {
      lookupItem = items[propertyName].find((opt:any) => opt.id == _value);
    }

    let label = "";

    if (lookupItem) label = lookupItem.label;
    return label;
  };

  const fetchLookupGlobe = async (
    dataSourceID: number,
    recordId_1: number,
    recordId_2: number
  ) => {
    let newObj: IselectedLookupGlobe = {};
    let newObjSpecial: IselectedLookupGlobeSpecial = {};
    let multiselect_api_record_1 =
      dataSourceID < 250
        ? `/api/multiselectvalues/${dataSourceID}/${recordId_1}/'view'`
        : `/api/multiselectvalues/admin/${dataSourceID}/${recordId_1}`;
    let response = await apiCall(multiselect_api_record_1);
    // .then((response) => {
    // console.log(response);
    let selLookup = response.data.multiselectValues;
    let selLookupSpecial = response.data.multiselectValuesSpecial;
    for (let i = 0; i < selLookup.length; i++) {
      if (newObj.hasOwnProperty(selLookup[i]["FieldSource"])) {
        newObj[`${selLookup[i]["FieldSource"]}_1`].push({
          Id: selLookup[i]["LookupId"],
          Value: selLookup[i]["Value"],
        });
      } else {
        newObj[`${selLookup[i]["FieldSource"]}_1`] = [
          { Id: selLookup[i]["LookupId"], Value: selLookup[i]["Value"] },
        ];
      }
    }

    for (let i = 0; i < selLookupSpecial.length; i++) {
      if (
        newObjSpecial.hasOwnProperty(`${selLookupSpecial[i]["FieldSource"]}_1`)
      ) {
        newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_1`].push({
          Id: selLookupSpecial[i]["LookupId"],
          Value: selLookupSpecial[i]["Value"],
        });
      } else {
        newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_1`] = [
          {
            Id: selLookupSpecial[i]["LookupId"],
            Value: selLookupSpecial[i]["Value"],
          },
        ];
      }
    }

    let multiselect_api_record_2 =
      dataSourceID < 250
        ? `/api/multiselectvalues/${dataSourceID}/${recordId_2}/'view'`
        : `/api/multiselectvalues/admin/${dataSourceID}/${recordId_2}`;
    response = await apiCall(multiselect_api_record_2);
    // .then((response) => {
    // console.log(response);
    selLookup = response.data.multiselectValues;
    selLookupSpecial = response.data.multiselectValuesSpecial;
    // let newObj: IselectedLookupGlobe = {};
    for (let i = 0; i < selLookup.length; i++) {
      if (newObj.hasOwnProperty(selLookup[i]["FieldSource"])) {
        newObj[`${selLookup[i]["FieldSource"]}_2`].push({
          Id: selLookup[i]["LookupId"],
          Value: selLookup[i]["Value"],
        });
      } else {
        newObj[`${selLookup[i]["FieldSource"]}_2`] = [
          { Id: selLookup[i]["LookupId"], Value: selLookup[i]["Value"] },
        ];
      }
    }

    for (let i = 0; i < selLookupSpecial.length; i++) {
      if (
        newObjSpecial.hasOwnProperty(`${selLookupSpecial[i]["FieldSource"]}_2`)
      ) {
        newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_2`].push({
          Id: selLookupSpecial[i]["LookupId"],
          Value: selLookupSpecial[i]["Value"],
        });
      } else {
        newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_2`] = [
          {
            Id: selLookupSpecial[i]["LookupId"],
            Value: selLookupSpecial[i]["Value"],
          },
        ];
      }
    }

    // setSelectedLookupRecord_2({ ...selectedLookupGlobeRecord_2, ...newObj });
    // });
    dispatch(setSelectedLookupGlobe({ ...selectedLookupGlobe, ...newObj }));
    dispatch(
      setSelectedLookupGlobeSpecial({
        ...selectedLookupGlobeSpecial,
        ...newObjSpecial,
      })
    );
  };

  const diseaseTable = (value: any, record: number) => {
    let selectedLookupGlobe =
      record === 1 ? selectedLookupGlobeRecord_1 : selectedLookupGlobeRecord_2;
    let filter = fieldRows.filter((x: any) => x.sectionId === value.id);
    let tableOne = [];
    let tableTwo = [];

    for (let x of filter) {
      if (
        (x.name.includes("Primary") || x.name === "ICDCode") &&
        !x.name.includes("Autocompelete")
      ) {
        tableOne.push(x);
      } else if (
        x.name.includes("Secondary") &&
        !x.name.includes("Autocompelete")
      ) {
        tableTwo.push(x);
      }
    }

    tableOne.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });

    if (tableTwo.length > 0) {
      tableTwo.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
    }

    return (
      <div className="diseaseTable">
        <div className="tableOne">{tableDisplay(tableOne, 1, record)}</div>
        {tableTwo.length > 0 &&
          !!selectedLookupGlobe[tableTwo[0].name] &&
          !!selectedLookupGlobe[tableTwo[1].name] && (
            <>
              <div className="tableSepartor"></div>
              <div className="tableTwo">
                {tableDisplay(tableTwo, 2, record)}
              </div>
            </>
          )}
      </div>
    );
  };

  const replaceLabelOncology: any = {
    HTARecord_PrimaryTherapeuticArea: "Therapeutic area",
    HTARecord_PrimaryDisease_ID: "Disease",
    HTARecord_PrimaryDiseaseSubtype: "Cell Type / Location",
    HTARecord_PrimaryDiseaseSubtype2: "Histology",
    HTARecord_PrimaryDiseaseSubtype3: "Biomarker",
    HTARecord_PrimaryDiseaseSubtype4: "Stage",
    ICDCode: "ICD code",
  };

  const tableDisplay = (table: any, tableNum: number, record: number) => {
    let selectedLookupGlobe =
      record === 1 ? selectedLookupGlobeRecord_1 : selectedLookupGlobeRecord_2;

    return (
      <TableContainer component={Paper}>
        <Table
          sx={{ maxWidth: tableNum === 1 ? "90%" : "85%" }}
          aria-label="customized table"
        >
          <TableHead>
            <StyledTableRow>
              {table.map((item: any) => (
                <React.Fragment key={item.id}>
                  <StyledTableCellThead>
                    {selectedLookupGlobe["HTARecord_PrimaryTherapeuticArea"] &&
                    selectedLookupGlobe["HTARecord_PrimaryTherapeuticArea"][0]
                      .Value &&
                    selectedLookupGlobe["HTARecord_PrimaryTherapeuticArea"][0]
                      .Value === "Oncology"
                      ? replaceLabelOncology[item.name]
                      : item.label}
                  </StyledTableCellThead>
                </React.Fragment>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {table.map((item: any) => (
                <StyledTableCellBody key={item.id}>
                  {selectedLookupGlobe[item.name] &&
                    selectedLookupGlobe[item.name].map((x, index) => (
                      <div
                        style={
                          index !== selectedLookupGlobe[item.name].length - 1
                            ? {
                                borderBottom: "1px solid #9e9e9e",
                                paddingTop: 15,
                                paddingBottom: 15,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                              }
                            : {
                                paddingTop: 15,
                                paddingBottom: 15,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                              }
                        }
                        key={index}
                      >
                        {x.Value}
                      </div>
                    ))}
                </StyledTableCellBody>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.head}`]: {
      borderBottom: "none",
    },
  }));

  const StyledTableCellThead = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f4f4f4",
      color: theme.palette.common.black,
      borderTop: "1px solid #9e9e9e",
      borderBottom: "1px solid #9e9e9e",
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    "&:last-child": {
      position: "absolute",
      right: 10,
      borderTop: "1px solid #9e9e9e",
      borderBottom: "1px solid #9e9e9e",
      marginBottom: "1px !important",
    },
  }));

  const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
    "&:last-child": {
      position: "absolute",
      right: 10,
      borderBottom: "1px solid #9e9e9e",
      marginBottom: "1px !important",
    },
  }));

  const hideMenu = (event: any) => {
    event.preventDefault();
    setIsMenuHidden(true);
  };

  const IsFieldIsEmpty = (field: Field) => {
    if (
      field.fieldTypeId == FormFieldType.MultiSelect ||
      field.fieldTypeId == FormFieldType.DrugSelect ||
      field.fieldTypeId == FormFieldType.DeviceSelect ||
      field.fieldTypeId == FormFieldType.ClinicalDataSelect
    ) {
      console.log(`${field.name}_1`);
      if (
          !selectedLookupGlobe[`${field.name}_1`] &&
        !selectedLookupGlobe[`${field.name}_2`]
      ) {
        return false;
      }else return true
    }
    if (!dataRecordID_1[field.name] && !dataRecordID_2[field.name])
      return false;
    return true;
  };

  return !loading ? (
    <div>
      <div style={{ paddingLeft: "5%" }}>
        <span
          style={{ color: "rgb(0, 170, 231)", cursor: "pointer" }}
          onClick={() => setIsMenuHidden(false)}
        >
          Record
        </span>
      </div>
      <div className="main-container col-sm-12 row">
        <div
          className="leftactionpane col-md-2 record-view-height"
          style={
            isMenuHidden
              ? { padding: "0px", display: "none" }
              : { padding: "0px" }
          }
        >
          <ul
            className="ace-left-nav ui-sortable"
            data-spy="affix"
            data-offset-top="205"
            style={{ padding: 0 }}
          >
            <li className="ui-sortable-handle">
              <div>
                <a
                  className="pull-right"
                  href="#"
                  id="left-ace-nav-btn"
                  title="Hide this Menu"
                  onClick={(e) => {
                    e.preventDefault();
                    hideMenu(e)}}
                >
                  <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ fontSize: "25px", paddingRight: "7px" }}
                  ></i>
                </a>
              </div>
              <div
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  height: "66px",
                }}
              >
                <b>
                  <span style={{ paddingTop: "20px", display: "block" }}>
                    Record Layout
                  </span>
                </b>
              </div>
            </li>
            {srows.length > 0 &&
              srows
                .filter(
                  (s) => s.parent_section_id == null || s.parent_section_id == 0
                )
                .map((sectionNav) => (
                  <li
                    className="hover-action ui-sortable-handle ace-left-nav-activated"
                    key={sectionNav.id}
                  >
                    <a
                      href={url + "#section-" + sectionNav.id.toString()}
                      style={{ color: "rgb(0, 170, 231) !important" }}
                      className="sectionLink"
                    >
                      <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                        <span style={{ color: "rgb(0, 170, 231) !important" }}>
                          {sectionNav.name}
                        </span>
                      </div>
                    </a>
                  </li>
                ))}
            {(commentsRecordID_1.length > 0 ||
              commentsRecordID_2.length > 0) && (
              <li className="hover-action ui-sortable-handle ace-left-nav-activated">
                <a
                  href="#section-comment"
                  onClick={(e) => scrollToComment(e)}
                  className="sectionLink"
                >
                  <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                    Comments
                  </div>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div
          className={
            (isMenuHidden ? "col-md-12" : "col-md-10") +
            " rightactionpane record-view-height"
          }
        >
          <div id="div-fixed" style={{ paddingLeft: "0px" }}>
            <div className="pull-left" style={{ margin: "15px 0px" }}>
              <div
                className="row justify-content-end"
                style={{
                  borderBottom: "1px solid #ededed",
                  margin: "20px auto",
                  paddingBottom: "20px",
                }}
              >
                <div className="col-5" style={{ paddingLeft: 40 }}>
                  <Link
                    to={`/view/${dataSourceID}/${recordID_1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={titleStyle}>{dataRecordID_1.Name}</span>
                  </Link>
                </div>
                <div className="col-5">
                  <Link
                    to={`/view/${dataSourceID}/${recordID_2}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={titleStyle}>{dataRecordID_2.Name}</span>
                  </Link>
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <b className="record-operations-label">Details</b>
              </div>
              {srows.map((section) => (
                <div
                  className="panel-group"
                  id={"section-" + section.id.toString()}
                  key={section.id}
                >
                  <div className="pull-left" style={{ width: "100%" }}>
                    <h4
                      className="panel-title"
                      data-toggle="collapse"
                      data-target={
                        "[id='section-" + section.id.toString() + "-collapse']"
                      }
                    >
                      <span
                        data-toggle="tooltip"
                        title=""
                        data-original-title=""
                      >
                        {section.name}
                      </span>
                    </h4>
                  </div>
                  <div
                    id={"section-" + section.id.toString() + "-collapse"}
                    style={{ clear: "both" }}
                    className="collapse in show"
                  >
                    {fieldRows
                      .filter(
                        (item) =>
                          item.sectionId == section.id &&
                          item.masterFieldId == null && IsFieldIsEmpty(item)
                      )
                      .map((item) => (
                        <div
                          className={
                            section.id !== 49
                              ? "col-sm-12 row form-group field-type-" +
                                item.fieldTypeId.toString()
                              : ""
                          }
                          key={item.id}
                        >
                          {!item.label.includes("Assign") &&
                            section.name !== "Disease" && (
                              <>
                                <label
                                  className={
                                    "col-sm-2 text-end control-label "
                                  }
                                  title={item.description}
                                >
                                  {item.label}
                                </label>
                                <div className="col-sm-10 control-label">
                                  <div
                                    className="row"
                                    style={{
                                      color: "#333333",
                                      textDecoration: "none",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      lineHeight: "17px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-6">
                                      <span>
                                        {displayContent(
                                          item.name,
                                          item.fieldTypeId,
                                          1,
                                          item.lookupId,
                                          section.name
                                        )}
                                      </span>
                                    </div>
                                    <div className="col-sm-6">
                                      <span>
                                        {displayContent(
                                          item.name,
                                          item.fieldTypeId,
                                          2,
                                          item.lookupId,
                                          section.name
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      ))}
                    {section.name === "Disease" && (
                      <div>
                        <div>
                          <h5 style={{ marginLeft: 10 }}>
                            {" "}
                            {dataRecordID_1.Name}{" "}
                          </h5>
                          {diseaseTable(section, 1)}
                        </div>
                        <div>
                          <h5 style={{ marginLeft: 10 }}>
                            {" "}
                            {dataRecordID_2.Name}{" "}
                          </h5>
                          {diseaseTable(section, 2)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {(commentsRecordID_1.length > 0 ||
                commentsRecordID_2.length > 0) && (
                <div ref={myRef}>
                  <span>
                    {commentsRecordID_1 && commentsRecordID_1.length > 0 ? (
                      <div>
                        <Comments
                          commentList={commentsRecordID_1}
                          clientList={clientList}
                          view="compare"
                          recordTitle={dataRecordID_1.Name}
                        />
                      </div>
                    ) : (
                      <div>
                        <div> {dataRecordID_1.Name} : </div>
                        <div> No comment </div>
                      </div>
                    )}
                  </span>
                  <span>
                    {commentsRecordID_2 && commentsRecordID_2.length > 0 ? (
                      <div>
                        <Comments
                          commentList={commentsRecordID_2}
                          clientList={clientList}
                          view="compare"
                          recordTitle={dataRecordID_2.Name}
                        />
                      </div>
                    ) : (
                      <div>
                        <div> {dataRecordID_2.Name} : </div>
                        <div> No comment </div>
                      </div>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "300px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default CompareRecords;
