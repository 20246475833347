import React, { useEffect, useState } from "react";
import { DropdownItem } from "reactstrap";
import axiosInstance from "../service/axiosInstance";


const exportOptions = { 1: "Current View", 2: "All Fields" };

const AddtionalOptions = {
  3: "All fields + Summary of related datasources",
  4: "All fields including all related datasources",
};

const pricingOption = { 5: "All Fields + PricingPoints" };

interface Iprop {
  ds_id: number;
}

const DropDownForExport = ({ ds_id }: Iprop) => {
  const [options, setOptions] = useState<any>(exportOptions);

  useEffect(() => {
    let options_ = { ...options };

    if ([1, 2, 3, 4].includes(ds_id)) {
      if (ds_id === 4) {
        options_ = { ...options_, ...pricingOption };
      }
      options_ = { ...options_, ...AddtionalOptions };

      setOptions(options_);
    }
  }, [ds_id]);

  const exportOnClick = (id: number) => {
    console.log("Export agent here!", id);
    // PostCall(`/api/export/${ds_id}/${id}`).then(res => {
    //   console.log("export call made....")
    // });

    axiosInstance.get(
      process.env.REACT_APP_API_URL + "/api/search/downloadtest",
      {responseType: "blob"},
    ).then((resp) => {
      const href = URL.createObjectURL(resp.data);

      const link = document.createElement("a");

      link.href = href;

      link.setAttribute("download", "report.xlsx");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <>
      {Object.keys(options).length > 0 && Object.keys(options || {}).map((el: string) => (
        <DropdownItem  onClick={() => exportOnClick(Number(el))}>
          {options[el]}
        </DropdownItem>
      ))}
    </>
  );
};


interface IDropDownOptionsProp {
  options : string[];
  currOption : number;
};

export const DropDownOptions = ({options, currOption}:IDropDownOptionsProp) => {
  
  return (
    <>
      {options.map((el, index) => (
        <option
          key={index}
          value={index}
        >
          {el}
        </option>
      ))}
      {/* <option>Current View</option>
      <option>All Fields</option>
      <option>All fields + Summary of related datasources</option>
      <option>All fields including all related datasources</option> */}
    </>
  );
};

export default DropDownForExport;
