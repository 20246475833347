import React, { useState, useEffect, useContext } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { IselectedLookup, IselectedLookupGlobeSpecial } from "../../../interfaces/multiselect";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../../Editor";
import {
  setSelectedLookupGlobe,
} from "../../../../store/MultiSelectSlice";
import { sortArray } from "./Utilty";


interface HTARecord_CoaInstrument {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

interface IEndPoint {
  [key: string]: string;
}

interface IResult {
  [i: string]: IselectedLookup[];
}
const HTARecord_CoaInstrument = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,

}: HTARecord_CoaInstrument) => {
  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );

  const userContext = useContext(UserContext);

  useEffect(() => {
    const ep_keys: string[] = Object.keys(selectedLookupGlobe).filter((x) =>
      x.includes("COA_Endpoints.EndpointType.EndpointID")
    );

    const coa = values[field.name];

    ep_keys.forEach((x, i) => {
      const items = x.split("_");
      const coa_index = Number(items[items.length - 1]);

      const selectedEndPoints = selectedLookupGlobe[x];

      const [allResults, RealtiveDifference] = getRelatedLookups();
      selectedEndPoints.forEach((ep) => {
        if (coa && coa[coa_index]) {
          if (coa[coa_index]?.["endPoint"]) {
            const ep_names = coa[coa_index]["endPoint"].map(
              (x: any) => x["EndpointName"].trim()
            );

            const lastIndex: number = ep_names.lastIndexOf(ep.Value?.trim());
            if (lastIndex > -1) {
              coa[coa_index]["endPoint"].splice(lastIndex + 1, 0, {
                EndpointID: ep.Id,
                EndpointName: ep.Value,
                Results: allResults,
                RealtiveDifference: RealtiveDifference,
              });
            } else {
              coa[coa_index]["endPoint"].push({
                EndpointID: ep.Id,
                EndpointName: ep.Value,
                Results: allResults,
                RealtiveDifference: RealtiveDifference,
              });
            }
          } else {
            coa[coa_index]["endPoint"] = [
              {
                EndpointID: ep.Id,
                EndpointName: ep.Value,
                Results: allResults,
                RealtiveDifference: RealtiveDifference,
              },
            ];
          }
          coa[coa_index]["endPoint"].sort((a:any,b:any) => a.EndpointName.localeCompare(b.EndpointName));
        }
      });
    });

    let StateCheck = false;

    ep_keys.forEach((x) => {
      if (selectedLookupGlobe[x].length > 0) StateCheck = true;
    });

    if (coa != undefined && StateCheck) {
      fieldChanged(field.name, "", coa);
      let _selectedLookupGlobe = { ...selectedLookupGlobe };
      ep_keys.forEach((x) => {
        _selectedLookupGlobe[x] = []
      })
      dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    }
  }, [
    selectedLookupGlobe,
    selectedLookupGlobeSpecial
  ]);

  const compareDrugs = (id : string ,drugIds : string[]) => {
    if(id.includes('|')){
      drugIds = drugIds.filter((x) => x.includes('|'))
                        .map((x) => x.split('|').map((y) => Number(y)).sort().join(','));
      const ids = id.split('|').map((x) => Number(x)).sort().join(',');
      return drugIds.includes(ids);
    }else{
      return drugIds.includes(id);
    }
  }

  const NotincludesCompareDrugs = (id : string ,drugIds : string[]) => {
    if(id.includes('|')){
      drugIds = drugIds.filter((x) => x.includes('|'))
                        .map((x) => x.split('|').map((y) => Number(y)).sort().join(','));
      const ids = id.split('|').map((x) => Number(x)).sort().join(',');
      return !drugIds.includes(ids);
    }else{
      return !drugIds.includes(id);
    }
  }

  useEffect(() => {
    if (values?.COA_Endpoints?.length > 0 && Object.keys(selectedLookupGlobe).length > 0) {
      values.COA_Endpoints.forEach((el: any) => {
        const [allResults, RealtiveDifference] = getRelatedLookups();

        el?.endPoint?.forEach((ep: any) => {
          let _allResults = [...allResults] || [];
          let _RealtiveDifference = [...RealtiveDifference];

          if (ep.Results) {
            let drugsIds = _allResults.map((x: any) => x.Id);
            ep.Results = ep.Results.filter((x: any) => drugsIds.includes(x.Id));
            let resultIds: any[] = ep.Results.map((x: any) => x.Id);
            _allResults = _allResults.filter((x: any) => !resultIds.includes(x.Id));
            ep.Results = [...ep.Results, ..._allResults];
          }


          if (ep.RealtiveDifference) {
            let comparatorIds = _RealtiveDifference.map((x: any) => x.Id);
            ep.RealtiveDifference = ep.RealtiveDifference.filter((x: any) => comparatorIds.includes(x.Id));
            let RealtiveDifferenceIds: any[] = ep.RealtiveDifference.map((x: any) => x.Id);
            _RealtiveDifference = _RealtiveDifference.filter((x: any) => !RealtiveDifferenceIds.includes(x.Id));
            ep.RealtiveDifference = [...ep.RealtiveDifference, ..._RealtiveDifference];
          }
        });
      });
      fieldChanged(field.name, "", values.COA_Endpoints);
    }
  }, [
    selectedLookupGlobe.HTARecord_Drug,
    selectedLookupGlobe.HTARecord_Drug_Combination,
    selectedLookupGlobe.HTARecord_Device,
    selectedLookupGlobe.HTARecord_ProcedureIntervention,
    selectedLookupGlobe.HTARecord_DrugComparator,
    selectedLookupGlobe.HTARecord_DrugComparator_Combination,
    selectedLookupGlobe.HTARecord_DeviceComparator,
    selectedLookupGlobe.HTARecord_ProcedureInterventionComparator
  ])

  const getRelatedLookups = () => {

    let HTARecord_Drug: any = [
      ...(selectedLookupGlobeSpecial["HTARecord_Drug_Combination"] || []),
    ];
  
    if (HTARecord_Drug.length == 0)
      HTARecord_Drug =
        !!selectedLookupGlobe["HTARecord_Drug"] && selectedLookupGlobe["HTARecord_Drug"].map((x) => ({
          Id: String(x.Id),
          Value: x.Value,
        })) || [];
    else {
      let combinations = selectedLookupGlobeSpecial["HTARecord_Drug_Combination"]?.map((x) => x.Id);
      let combIds: number[] = [];
      combinations.forEach((el) => {
        let ids: number[] = el.split('|').map((x) => Number(x));
        combIds = [...combIds, ...ids];
      })
      let drugs = selectedLookupGlobe?.HTARecord_Drug?.filter((x) => !combIds.includes(x.Id)).map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];
      HTARecord_Drug = [...drugs, ...HTARecord_Drug];
    }
    let drugIds = [...HTARecord_Drug?.map((x:any) => x.Id) || []];

    let HTARecord_DrugComparator: any = [
      ...(selectedLookupGlobeSpecial[
        "HTARecord_DrugComparator_Combination"
      ] || []),
    ];
  
    // const drugIds : any[] = HTARecord_Drug.map((x:any) => x.Id) || [];
    //.filter((x) => !drugIds.includes(String(x.Id)))
    if (HTARecord_DrugComparator.length == 0){
      HTARecord_DrugComparator =
        !!selectedLookupGlobe["HTARecord_DrugComparator"] && selectedLookupGlobe["HTARecord_DrugComparator"].map((x) => ({
          Id: String(x.Id),
          Value: x.Value,
        })) || [];
      HTARecord_DrugComparator = HTARecord_DrugComparator?.filter((x:any) => !drugIds.includes(x.Id));
    }
    else{
      let combinations = selectedLookupGlobeSpecial["HTARecord_DrugComparator_Combination"]?.map((x) => x.Id);
      let combIds: number[] = [];
      combinations.forEach((el) => {
        let ids: number[] = el.split('|').map((x) => Number(x));
        combIds = [...combIds, ...ids];
      })
      let drugs = selectedLookupGlobe?.HTARecord_DrugComparator?.filter((x) => !combIds.includes(x.Id)).map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];
      drugs = drugs?.filter((x) => !drugIds.includes(x.Id));
      HTARecord_DrugComparator = [...drugs, ...HTARecord_DrugComparator];
    }

    drugIds = [...drugIds,...HTARecord_DrugComparator?.map((x:any) => x.Ids) || []];
    let HTARecord_DrugComparatorPayer : any;
    if(HTARecord_DrugComparator.length == 0){
      HTARecord_DrugComparatorPayer = !!selectedLookupGlobe["HTARecord_DrugComparatorPayer"] && selectedLookupGlobe["HTARecord_DrugComparatorPayer"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];
      HTARecord_DrugComparatorPayer = HTARecord_DrugComparatorPayer?.filter((x:any) => !drugIds.includes(x.Id));
    }else{
      let combinations = HTARecord_DrugComparator?.map((x:any) => x.Id) || [];
      let combIds: number[] = [];
      combinations.forEach((el:any) => {
        let ids: number[] = el.split('|').map((x:any) => Number(x));
        combIds = [...combIds, ...ids];
      });
      let drugs = selectedLookupGlobe?.HTARecord_DrugComparatorPayer?.filter((x) => !combIds.includes(x.Id)).map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];
      HTARecord_DrugComparatorPayer = drugs?.filter((x) => !drugIds.includes(x.Id));
    }

    drugIds = [...drugIds,...HTARecord_DrugComparatorPayer?.map((x:any) => x.Ids) || []];

    const HTARecord_Device: any =
    !!selectedLookupGlobe["HTARecord_Device"] && selectedLookupGlobe["HTARecord_Device"].map((x) => ({
      Id: String(x.Id),
      Value: x.Value,
    }))?.filter((x) => !drugIds.includes(x.Id)) || [];
    
    drugIds = [...drugIds,...HTARecord_Device?.map((x:any) => x.Ids) || []];

    const HTARecord_DeviceComparator: any =
    !!selectedLookupGlobe["HTARecord_DeviceComparator"] && selectedLookupGlobe["HTARecord_DeviceComparator"].map((x) => ({
      Id: String(x.Id),
      Value: x.Value,
    }))?.filter((x) => !drugIds.includes(x.Id)) || [];

    drugIds = [...drugIds,...HTARecord_DeviceComparator?.map((x:any) => x.Ids) || []];

    const HTARecord_ProcedureIntervention: any =
    !!selectedLookupGlobe["HTARecord_ProcedureIntervention"] && selectedLookupGlobe["HTARecord_ProcedureIntervention"].map((x) => ({
      Id: String(x.Id),
      Value: x.Value,
    }))?.filter((x) => !drugIds.includes(x.Id)) || [];
    drugIds = [...drugIds,...HTARecord_ProcedureIntervention?.map((x:any) => x.Ids) || []];

  const HTARecord_ProcedureInterventionComparator: any =
    !!selectedLookupGlobe["HTARecord_ProcedureInterventionComparator"] && selectedLookupGlobe["HTARecord_ProcedureInterventionComparator"].map(
      (x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })
    )?.filter((x) => !drugIds.includes(x.Id)) || [];

    let intervention: any = [
      ...HTARecord_ProcedureIntervention,
      ...HTARecord_Drug,
      ...HTARecord_Device,
    ];

    intervention = intervention.sort(sortArray);

    let comparators: any = [
      ...HTARecord_DrugComparator,
      ...HTARecord_DrugComparatorPayer,
      ...HTARecord_DeviceComparator,
      ...HTARecord_ProcedureInterventionComparator,
    ];

    comparators = comparators.filter((x:any) => {
      return intervention.some((item: any) => String(item.Id) !== String(x.Id) && item.Value !== x.Value)
    })

    comparators = comparators.sort(sortArray);

    let RealtiveDifference: any[] = [];
    intervention.forEach((x: any) => {
      comparators.forEach((el: any) => {
        if (String(x.Id) != String(el.Id))
          RealtiveDifference.push({
            Id: String(x.Id) + "+" + String(el.Id),
            Value: x.Value + " vs " + el.Value,
          });
      });
    });

    let allResults : {Id : string, Value : string}[] = [...intervention, ...comparators];
    return [allResults,RealtiveDifference]
    
  }

  const resultChange = (
    id: string,
    i: string,
    currentItem: number,
    coa_index: number,
   
  ) => {
    //
    //const ResultInfo : any[] = [...values[field.name][i]]
    let coa = values[field.name];
    if (!String(id).includes("_")) {
      let results: any[] = [...coa[coa_index]["endPoint"][i]["Results"]];

      for (let ri = 0; ri < results.length; ri++) {
        if (results[ri].Id == id && !results[ri]["ResultName"]) {
          results[ri] = {
            ...results[ri],
            ResultName: " ",
          };
          break;
        }
      }
      coa[coa_index]["endPoint"][i]["Results"] = [...results];
    } else {
      const n_id = id.replace("_","");
      let RealtiveDifference: any[] = [
        ...coa[coa_index]["endPoint"][i]["RealtiveDifference"],
      ];

      for (let ri = 0; ri < RealtiveDifference.length; ri++) {
        if (
          RealtiveDifference[ri].Id == n_id &&
          !RealtiveDifference[ri]["ResultName"]
        ) {
          RealtiveDifference[ri] = {
            ...RealtiveDifference[ri],
            ResultName: " ",
          };
          break;
        }
      }

      coa[coa_index]["endPoint"][i]["RealtiveDifference"] = [
        ...RealtiveDifference,
      ];
    }

    fieldChanged(field.name, "", coa);
  };

  // Adding all result from dropdown to endpoints
  const addAllResults = (i:number,coa_index:number) => {
    let coa = values[field.name];
    let results: any[] = [...coa[coa_index]["endPoint"][i]["Results"]];
    for (let ri = 0; ri < results.length; ri++) {
      if (!results[ri]["ResultName"]) {
        results[ri] = {
          ...results[ri],
          ResultName: " ",
        };        
      }
    }
    coa[coa_index]["endPoint"][i]["Results"] = [...results];
    
    let RealtiveDifference: any[] = [
      ...coa[coa_index]["endPoint"][i]["RealtiveDifference"],
    ];

    for (let ri = 0; ri < RealtiveDifference.length; ri++) {
      if (
        !RealtiveDifference[ri]["ResultName"]
      ) {
        RealtiveDifference[ri] = {
          ...RealtiveDifference[ri],
          ResultName: " ",
        };
      }
    }

    coa[coa_index]["endPoint"][i]["RealtiveDifference"] = [
      ...RealtiveDifference,
    ];
    
    fieldChanged(field.name, "", coa);
  };

  const onResultNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
   
    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["Results"][result_index].ResultName;
    }else {
      coa[coa_index]["endPoint"][ep_index]["Results"][result_index] = {
        ...coa[coa_index]["endPoint"][ep_index]["Results"][result_index],
        ResultName: name,
      };
    }

    fieldChanged(field.name,"",coa);
  };

  const onRelativeDifferenceNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index].ResultName;
    }else {
      coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index] = {
        ...coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index],
        ResultName: name,
      };
    }

    fieldChanged(field.name,"",coa);
  };

  const updateCOA = (obj: any, index: number) => {
    const coaObj = [...values[field.name]];
    coaObj[index] = obj;
    fieldChanged(field.name, "", coaObj);
  };

  const addEndpointTypes = (coa_index: number) => {
    const id: number = childRows
      .filter((f) => f.name == "COA_Endpoints.EndpointType.EndpointID")
      .map((f) => f.lookupId)[0];

    openModal(
      id,
      "COA_Endpoints.EndpointType.EndpointID_" + String(coa_index),
      "Endpoint Type"
    );
  };

  const deleteInstrument = (coa_index: number) => {
    const msg = "Are you sure you want to delete this COA instrument?";
    const check = window.confirm(msg);

    if (check) {
      let slg = { ...selectedLookupGlobe };
      for (let i = coa_index;i < values[field.name].length-1; i++){
        // slg[`COA_Endpoints.Significances_${i}`] = slg[`COA_Endpoints.Significances_${i+1}`];
        // slg[`COA_Endpoints.ImportanceID_${i}`] = slg[`COA_Endpoints.ImportanceID_${i+1}`];

        slg[`COA_Endpoints.Significances_${i+1}`] != undefined ?
        slg[`COA_Endpoints.Significances_${i}`] =
        slg[`COA_Endpoints.Significances_${i+1}`] :
        slg[`COA_Endpoints.Significances_${i}`] = [];

        slg[`COA_Endpoints.ImportanceID_${i+1}`] != undefined ?
        slg[`COA_Endpoints.ImportanceID_${i}`] =
        slg[`COA_Endpoints.ImportanceID_${i+1}`] :
        slg[`COA_Endpoints.ImportanceID_${i}`] = [];
      }
      const coaLength =  values[field.name].length-1;
      delete slg[`COA_Endpoints.Significances_${coaLength}`];
      delete slg[`COA_Endpoints.ImportanceID_${coaLength}`];
  
      dispatch(setSelectedLookupGlobe(slg));

      if (values[field.name].length == 1) fieldChanged(field.name, "", []);
      else {
        const coa_instruments: any[] = [...values[field.name]];
        fieldChanged(field.name, "", [
          ...coa_instruments.filter((item: any, xi: number) => xi != coa_index),
        ]);
      }
    }
  };

  const deleteEndPoint = (coa_index: number,id:number, ep_index: number) => {
    const coa = values[field.name];
    const endPointObj = coa[coa_index]["endPoint"].filter(
      (x: any, i: number) => i != ep_index
    );
    coa[coa_index]["endPoint"] = endPointObj;
    fieldChanged(field.name, "", coa);
  };

  return (
    <>
      {values[field.name] &&
        values[field.name].map((parent: any, index: number) => (
          <>
            <div className="float-left">
              <b> {"COA instrument " + String(index + 1)}</b>
            </div>
            <div className="float-right">
              <div className="row">
                {childRows
                  .filter(
                    (x) =>
                      masterFieldTypes.includes(x.fieldTypeId) &&
                      x.masterFieldId == field.id
                  )
                  .map((el) => (
                    <>
                      <label className="iqvia-color cursor-pointer margin-right-20" 
                        onClick={() => {
                          addEndpointTypes(index);
                        }}
                      >
                        <AddIcon className="instrument-endpoint-type-add" /> &nbsp;
                        {el.label}
                      </label>
                    </>
                  ))}
                <label className="iqvia-color cursor-pointer margin-right-30" onClick={() => deleteInstrument(index)}>
                  <RiDeleteBinLine className="margin-bottom-3" /> Instrument
                </label>
              </div>
            </div>
            {/* <hr /> */}
            <div
            style={{
              width:"100%",
              height:"0.5px",
              marginTop: "41px",
              marginBottom: "1rem",
              border: "0",
              borderTop: "1px solid rgba(0,0,0,.1)"
            }}
          >           
          </div>
            {childRows
              .filter(
                (x) =>
                  !masterFieldTypes.includes(x.fieldTypeId) &&
                  x.masterFieldId == field.id &&
                  !x.readOnly 
              )
              .map((row) => (
                <>            
                  <FieldView
                    field={row}
                    values={values}
                    fieldChanged={fieldChanged}
                    RichtextFieldChanged={RichtextFieldChanged}
                    datasourcename={datasourcename}
                    section={section}
                    datasourceId={datasourceId}
                    parentObject={values[field.name][index]}
                    setObj={updateCOA}
                    index={index}
                  />
                </>
              ))}

            {!!values[field.name][index].endPoint &&
              values[field.name][index].endPoint.map((el: any, i: number) => (
                <>
                <br />
                  <div style={{ display: "flow-root" }}>
                    <div className="float-left">
                      <b>Endpoint type - {el.EndpointName}</b>
                    </div>
                    <div className="float-right">
                      <select
                      // style={{color: "#337ab7",height:"28px",marginTop:"15px",marginBottom:"15px",width:"350px",marginRight:"200px",border:"1px solid #ced4da"}}
                      // style={{color:"#337ab7",margin:"13px",border:"1px solid black"}}
                      className="instrument-add-all-result-option"
                        value={""}
                        disabled={
                          !!el["Results"] &&
                          [
                            ...(el["Results"] || []),
                            ...(el["RealtiveDifference"] || []),
                          ].some((x: any) => !x["ResultName"])
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          resultChange(
                            e.target.value,
                            String(i),
                            e.target.selectedIndex,
                            index
                          );
                        }}
                      >
                        <option className="instrument-endpoint-type-option-color" value={""} disabled selected>
                          + Results
                        </option>
                        <optgroup label="Individual result">
                          {!!el["Results"] &&
                            el["Results"].map((item: any, ri: number) => (
                              <>
                                {!item["ResultName"] && (
                                  <option key={ri} value={item.Id}>
                                    Result - {item.Value}
                                  </option>
                                )}
                              </>
                            ))}
                        </optgroup>
                        {el["RealtiveDifference"] && el["RealtiveDifference"].length > 0 && 
                          <optgroup label="Relative difference(s)">
                            {!!el["RealtiveDifference"] &&
                              el["RealtiveDifference"].map(
                                (item: any, ri: number) => (
                                  <>
                                    {!item["ResultName"] && (
                                      <option
                                        key={ri}
                                        value={String(item.Id) + "_"}
                                      >
                                        Result - {item.Value}
                                      </option>
                                    )}
                                  </>
                                )
                              )}
                          </optgroup>
                        }
                      </select>
                      <div className="row">
                      <label className="iqvia-color instrument-add-all-result cursor-pointer"  onClick={() => addAllResults(i,index)}>
                        <AddIcon className="iqvia-color instrument-add-all-result-icon" /> Add all results
                      </label>
                      <label className="iqvia-color instrument-endpoint-type-delete cursor-pointer" 
                          onClick={() => deleteEndPoint(index, el.EndpointID, i)}>
                          <RiDeleteBinLine className="margin-bottom-3" /> Endpoint type
                        </label>
                      </div>
                      
                    </div>
                    <div>
                      {childRows
                        .filter(
                          (x) =>
                            x.name ==
                            "COA_Endpoints.EndpointType.COAEndpointName"
                        )
                        .map((item) => (
                          <>
                            <br />
                            <div
                              className="float-right col-sm-12 row form-group margin-bottom-0"
                            >
                              <label
                                className="col-sm-4 text-right control-label"
                                style={{ paddingRight: "4px" }}
                              >
                                {item.label}
                                {
                                  <FaInfoCircle
                                    onClick={() => {
                                      userContext.showAlert(
                                        <>
                                          <span
                                            style={{
                                              fontSize: "70%",
                                              paddingLeft: "2px",
                                            }}
                                          >
                                            {datasourcename}
                                            <DoubleArrowIcon
                                              style={{
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {section}
                                            <DoubleArrowIcon
                                              style={{
                                                strokeWidth: "5",
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {field.label}
                                          </span>
                                        </>,
                                        "info",
                                        field.description,
                                        "https://google.com",
                                        true
                                      );
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      cursor: "pointer",
                                    }}
                                  />
                                }
                              </label>
                              <input
                                className="col-sm-7 form-control"
                                style={{ marginLeft: "15px" }}
                                value={
                                  values[field.name][index]["endPoint"][i][
                                    item.name
                                  ] || ""
                                }
                                placeholder={item.label}
                                onChange={(e) => {
                                  const obj = values[field.name];
                                  obj[index]["endPoint"][i][item.name] =
                                    e.target.value;
                                  fieldChanged(field.name, "", obj);
                                }}
                              />
                            </div>
                          </>
                        ))}
                      {!!values[field.name][index]["endPoint"][i]["Results"] &&
                        values[field.name][index]["endPoint"][i]["Results"].map(
                          (res: any, resi: number) => (
                            <>
                              {res["ResultName"] && (
                                <div
                                  className="float-right col-sm-12 row form-group margin-bottom-0"
                                >
                                  <label
                                    className="col-sm-4 text-right control-label"
                                    style={{
                                      paddingRight: "4px",
                                      paddingTop: 10,
                                    }}
                                  >
                                    Result - {res.Value && res.Value}

                                    {
                                      <FaInfoCircle
                                        onClick={() => {
                                          userContext.showAlert(
                                            <>
                                              <span
                                                style={{
                                                  fontSize: "70%",
                                                  paddingLeft: "2px",
                                                }}
                                              >
                                                {datasourcename}
                                                <DoubleArrowIcon
                                                  style={{
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {section}
                                                <DoubleArrowIcon
                                                  style={{
                                                    strokeWidth: "5",
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {field.label}
                                              </span>
                                            </>,
                                            "info",
                                            field.description,
                                            "https://google.com",
                                            true
                                          );
                                        }}
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "3px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  </label>
                                  <input
                                    className="col-sm-7 form-control margin-t-l-15"
                                    value={res["ResultName"]}
                                    type="text"
                                    onChange={(e) =>
                                      onResultNameChange(
                                        index,
                                        i,
                                        resi,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="iqvia-color cursor-pointer instrument-endpoint-result-delete"
                                    onClick={() => {
                                      onResultNameChange(index, i, resi);
                                    }}
                                  >
                                    <RiDeleteBinLine
                                      style={{
                                        marginTop: "22px",
                                        marginLeft: "5px",
                                        fontSize: "medium",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        )}
                      <div style={{ float: "left" }}>
                        <p>Relative difference(s) / Effect estimate(s) : </p>
                      </div>
                     

                      {!!values[field.name][index]["endPoint"][i][
                        "RealtiveDifference"
                      ] &&
                        values[field.name][index]["endPoint"][i][
                          "RealtiveDifference"
                        ].map((res: any, resi: number) => (
                          <>
                            {res["ResultName"] && (
                              <div
                                className="float-right col-sm-12 row form-group margin-bottom-0"
                              >
                                <label
                                  className="col-sm-4 text-right control-label"
                                  style={{
                                    paddingRight: "4px",
                                    paddingTop: 10,
                                  }}
                                >
                                  Result - {res.Value && res.Value}

                                  {
                                    <FaInfoCircle
                                      onClick={() => {
                                        userContext.showAlert(
                                          <>
                                            <span
                                              style={{
                                                fontSize: "70%",
                                                paddingLeft: "2px",
                                              }}
                                            >
                                              {datasourcename}
                                              <DoubleArrowIcon
                                                style={{
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {section}
                                              <DoubleArrowIcon
                                                style={{
                                                  strokeWidth: "5",
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {field.label}
                                            </span>
                                          </>,
                                          "info",
                                          field.description,
                                          "https://google.com",
                                          true
                                        );
                                      }}
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "3px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                </label>
                                <input
                                  className="col-sm-7 form-control margin-t-l-15"
                                  value={res["ResultName"]}
                                  type="text"
                                  onChange={(e) =>
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi,
                                      e.target.value
                                    )
                                  }
                                />
                                <div className="iqvia-color cursor-pointer instrument-endpoint-result-delete"
                                  onClick={() => {
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi
                                    );
                                  }}
                                >
                                  <RiDeleteBinLine
                                    style={{
                                      marginTop: "22px",
                                      marginLeft: "5px",
                                      fontSize: "medium",
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              ))}
              {(values[field.name][index].endPoint == undefined) && (
                <>
                  <br />
                  <div
                    className="col-sm-12 text-center bg-white-smoke"
                  >
                    No endpoints have been specified
                  </div>
                  <div style={{width:"100%",height:"0.5Px",marginTop: "0rem",marginBottom: "0.5rem"}}> </div>
                </>
              )}
            
          </>
        ))}
        {(!values[field.name] || values[field.name]?.length == 0) && (
              <>
                <br />
                <div
                  className="col-sm-12 text-center bg-white-smoke"
                >
                  No further COA information was provided
                </div>
                <div style={{width:"100%",height:"0.5Px",marginTop: "0rem",marginBottom: "0.5rem"}}> </div>
              </>
            )}
    </>
  );
};

export default HTARecord_CoaInstrument;
