import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface ISplitComponent extends ICellRendererParams {
      name: string;
      datasourcId: string;
}


export const SplitComponent = (props: ISplitComponent) => {
      const name = props.data[props.name];
      return (
            <>
                  {
                        name?.split(",").map((el: string) => (
                              <span style={{ display: "block" }}>{el}</span>
                        ))
                  }
            </>
      )
}

export const SplitWithTilde = (props: ISplitComponent) => {
      const name = props.data[props.name];
      return (
            <>
                  {
                        name?.split("~").map((el: string) => (
                              <span style={{ display: "block" }}>{el}</span>
                        ))
                  }
            </>
      )
}

export const removeExtraNull = (arr: any[]) => {
      let blankFound = false;
      const result = [];
        // if(dsID === 7){
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === "" || arr[i] === null) {
              if (!blankFound) {
                result.push(arr[i]);
                blankFound = true;
              }
            } else {
              result.push(arr[i]);
            }
          }
      
      return result;
    }