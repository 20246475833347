import React, { useContext, useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { UserContext } from "../../Editor";
import { useAppSelector } from "../../../../store/hooks";
import Element from "./Utility";

interface ICoaInstrumentForRegulatoryWatchProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
  showEmptyFieldsFlag: boolean;
}
 

const CoaInstrumentForRegulatoryWatch = ({
    fieldInfo,
    datasourceName,
    section,
    showEmptyFieldsFlag
  }: ICoaInstrumentForRegulatoryWatchProp) => {
    const userContext = useContext(UserContext);

    const [field, setField] = useState<any[]>([]);
    const [coaInstrument, setCoaInstrument] = useState<any>([]);
    const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

    useEffect(() => {
        if (field.length == 0 && fieldInfo.length > 0) {
          setField(fieldInfo);
        }
      }, [fieldInfo]);
    
    useEffect(() => {
        setCoaInstrument(subPageDate["coa_instrument_reg"]);
      }, [subPageDate]);

    return (
      <>
        {!!coaInstrument &&
          coaInstrument.length > 0 &&
          coaInstrument.map((el: any, index: number) => (
            <div className="col-sm-12">
              <hr />
              <b>COA instrument {index + 1}</b>
              <>
                {!!field &&
                  field.length > 0 &&
                  field.map((val) => (
                    <Element
                      label={val.label}
                      fieldType={val.fieldTypeId}
                      value={
                        String(el[val.name.split(".")[1]]).includes(",-, ")
                          ? String(el[val.name.split(".")[1]])
                              .split(",-, ")
                              .map((element) => (
                                <>
                                  <label>{element}</label>
                                  <br />
                                </>
                              ))
                          : el[val.name.split(".")[1]]
                      }
                      datasourceName={datasourceName}
                      section={section}
                      description={val.descriptionClient}
                      showEmptyFieldsFlag={showEmptyFieldsFlag}
                    />
                  ))}
              </>
            </div>
          ))}
        {coaInstrument && coaInstrument.length == 0 && (
          <div
            className="col-sm-12"
            style={{ backgroundColor: "whitesmoke", textAlign: "center" }}
          >
            No further COA information was provided
          </div>
        )}
      </>
    );
  };
  export default CoaInstrumentForRegulatoryWatch;