import React, { useEffect, useState } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import apiCall from "../../../service/apiCall";
import { setSelectedLookupGlobe } from "../../../../store/MultiSelectSlice";
import { BiEdit } from "react-icons/bi";
import { IselectedLookup } from "../../../interfaces/multiselect";

interface HTARecord_RWEProps {
    field: Field;
    values: any;
    fieldChanged: (name?: string, value?: string, obj?: any) => void;
    childRows: Field[];
    validateCriteria: (field: Field) => boolean;
    RichtextFieldChanged: (name: string, value: EditorState) => void;
    datasourcename: string;
    section: any;
    datasourceId: number;
    masterFieldTypes: number[];
    child: number[];
}
const HTARecord_RWE = ({
    field,
    values,
    fieldChanged,
    childRows,
    validateCriteria,
    RichtextFieldChanged,
    datasourcename,
    section,
    datasourceId,
    masterFieldTypes,
    child,
  }: HTARecord_RWEProps) => {

    const dispatch = useAppDispatch();
    const selectedLookupGlobe = useAppSelector(
      (state) => state.multiselect.selectedLookupGlobe
    );
   
    const updateRWE = (obj: any, index: number) => {
      const rweObj = [...values[field.name]];
      rweObj[index] = obj;
      fieldChanged(field.name, "", rweObj);
    };
  
    const deleteRWESource = (rwe_index: number) => {

      let slg = { ...selectedLookupGlobe };
      for (let i = rwe_index;i < values[field.name].length-1; i++){
        // slg[`RWE_Datasource.AreasSupportedName_${i}`] = slg[`RWE_Datasource.AreasSupportedName_${i+1}`];
        // slg[`RWE_Datasource.RationaleName_${i}`] = slg[`RWE_Datasource.RationaleName_${i+1}`];

        slg[`RWE_Datasource.AreasSupportedName_${i+1}`] != undefined ?
        slg[`RWE_Datasource.AreasSupportedName_${i}`] =
        slg[`RWE_Datasource.AreasSupportedName_${i+1}`] :
        slg[`RWE_Datasource.AreasSupportedName_${i}`] = [];

        slg[`RWE_Datasource.RationaleName_${i+1}`] != undefined ?
        slg[`RWE_Datasource.RationaleName_${i}`] =
        slg[`RWE_Datasource.RationaleName_${i+1}`] :
        slg[`RWE_Datasource.RationaleName_${i}`] = [];
      }
      const rweLength =  values[field.name].length-1;
      delete slg[`RWE_Datasource.AreasSupportedName_${rweLength}`];
      delete slg[`RWE_Datasource.RationaleName_${rweLength}`];

      dispatch(setSelectedLookupGlobe(slg));
      if (values[field.name].length == 1) fieldChanged(field.name, "", []);
      else {
        const rwe_source: any[] = [...values[field.name]];
        fieldChanged(field.name, "", [
          ...rwe_source.filter((item: any, xi: number) => xi != rwe_index),
        ]);
      }
    };

    const renderAutoPopulateRwe = (ind: number) => {
      const rwe = values["RWE_Datasource"][ind]['RWE_Datasource.RWEDatasourceID'];
      apiCall(
        `/api/RWEInformationForAutoPopulate/${rwe}`
      ).then((resp) => {
        if (!!resp && !!resp.data) {
          const rweObj = [...values[field.name]];
          let parentObj = values[field.name][ind];
          parentObj['RWE_Datasource.TypeofDatasourceID'] = resp.data.rwe_row[0].TypeofDatasourceID;
          parentObj['RWE_Datasource.PayerAcceptanceID'] = resp.data.rwe_row[0].PayerAcceptanceID;
          parentObj['RWE_Datasource.AdditionalRWEDetails'] = resp.data.rwe_row[0].AdditionalRWEDetails;
          fieldChanged("RWE_Datasource", "", rweObj);

          let newSelectedlookupGlobe = { ...selectedLookupGlobe };
          let areaSupported: IselectedLookup[] = resp.data.rwe_ms
            .filter((x) => x["LValue"].indexOf("--AS") > 0)
            .map((row: any) => ({
              Id: row["Id"],
              Value: String(row["LValue"].split("--")[0]),
            }));

          let rationale: IselectedLookup[] = resp.data.rwe_ms
            .filter((x) => x["LValue"].indexOf("--RR") > 0)
            .map((row: any) => ({
              Id: row["Id"],
              Value: String(row["LValue"].split("--")[0]),
            }));
          newSelectedlookupGlobe = {
            ...newSelectedlookupGlobe,
            [`RWE_Datasource.AreasSupportedName_${ind}`]: areaSupported,
          };
          newSelectedlookupGlobe = {
            ...newSelectedlookupGlobe,
            [`RWE_Datasource.RationaleName_${ind}`]: rationale,
          };
          dispatch(setSelectedLookupGlobe(newSelectedlookupGlobe));
        }
      });
    }

    return (
        <>
          {!!values[field.name] && values[field.name].map((parent: any, index: number) => (
            <React.Fragment key ={`RWE_${index+1}`}>
            <div>
              <div className="float-left">
                <b> {"RWE Source" + " " + String(index + 1)}</b>
              </div>
              <div className="float-end">
                <div className="row">
                  {/* {childRows
                    .filter((x) => masterFieldTypes.includes(x.fieldTypeId))
                    .map((el) => (
                      <>
                        <label onClick={() => console.log("Add RWE")}>
                          <AddIcon />
                          {el.label}
                        </label>
                      </>
                    ))} */}
                  <label style={{color:"#337ab7"}} onClick={() => deleteRWESource(index)}>
                    <RiDeleteBinLine style={{fontSize:"medium"}} /> RWE source
                  </label>
                </div>
              </div>
              </div>
              <br />
              <hr />
              {childRows
                .filter(
                  (x) =>
                    !masterFieldTypes.includes(x.fieldTypeId) &&
                    x.masterFieldId == field.id &&
                    !x.readOnly
                )
                .map((row) => (
                  <React.Fragment key = {row.id}>
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section}
                        datasourceId={datasourceId}                        
                        parentObject={values[field.name][index]}
                        setObj={updateRWE}
                        index={index}
                      />
                      {row.name.indexOf('RWEDatasourceID') >= 0 && row.fieldTypeId == 41 &&
                        <BiEdit className={index.toString()} onClick={() => renderAutoPopulateRwe(index)} style={{marginLeft: "57em"}} />
                      }
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}  
             {(!values[field.name] || values[field.name]?.length == 0) && (
              <>
                <br />
                <div
                  className="col-sm-12"
                  style={{ backgroundColor: "whitesmoke", textAlign: "center" }}
                >
                  No further RWE information was provided
                </div>
                <br />
                {/* <div style={{width:"100%",height:"0.5Px",marginTop: "0rem",marginBottom: "1rem",border: "0",borderTop: "1px solid rgba(0,0,0,.1)"}}> </div> */}
              </>
            )}       
        </>
      );
} 

export default HTARecord_RWE;