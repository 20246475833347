// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-underline {
      text-decoration: none !important;
}

.no-underline:hover {
      text-decoration: underline !important;
}

.text-align-center {
      text-align: center;
}

.no-border-radius {
      border-radius: 0 !important;
      border-radius: initial !important;
}

.modal-header-footer-bg-color {
      background-color: #FAFAFA;
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;MACM,gCAAgC;AACtC;;AAEA;MACM,qCAAqC;AAC3C;;AAEA;MACM,kBAAkB;AACxB;;AAEA;MACM,2BAA+B;MAA/B,iCAA+B;AACrC;;AAEA;MACM,yBAAyB;AAC/B","sourcesContent":[".no-underline {\n      text-decoration: none !important;\n}\n\n.no-underline:hover {\n      text-decoration: underline !important;\n}\n\n.text-align-center {\n      text-align: center;\n}\n\n.no-border-radius {\n      border-radius: unset !important;\n}\n\n.modal-header-footer-bg-color {\n      background-color: #FAFAFA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
