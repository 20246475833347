import React, { useContext, useEffect, useState } from "react";
import { EditorState } from "draft-js";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { Field } from "../../../../store/DataSourceMetaData";

import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
import { UserContext } from "../../Editor";
import { setSelectedDataStateSpecial, setSelectedLookupGlobe, setSelectedLookupGlobeSpecial } from "../../../../store/MultiSelectSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import apiCall from "../../../service/apiCall"

interface InfoPopupProps {
  datasourceName: string;
  section: string;
  fId: number;
  label: string;
  infoLabel: string;
  description: string;
  required?: boolean;
  values?: any;
}
export const LabelInfoPopup = ({
  datasourceName,
  section,
  fId,
  label,
  infoLabel,
  description,
  required,
  values
}: InfoPopupProps) => {
  const userContext = useContext(UserContext);
  return (
    <label
      className={`control-label ${required && "required"}`}
    >
      <span style={{paddingRight:"5px"}}>{label}</span>
      <FaInfoCircle 
        style={{height:"13px",width:"16px",cursor:"pointer"}}
        onClick={() => {
          const showAlertFunc = async() => {
          const res = await apiCall(`/api/admin/fetch/description/${fId}/${values["Status_ID"] || 1}/${0}/${'write'}`);
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                {datasourceName}
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                {section}
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {infoLabel}
              </span>
            </>,
            "info",
            res.data,
            `/admin/instructions/${fId}`,
            true
          );
        }
        showAlertFunc()
        }}
      />
    </label>
  );
};
interface ApprovedIndicationsProps {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any,last_index_check?: boolean) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

const ApprovedIndications = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,
}: ApprovedIndicationsProps) => {
  // console.log('Approved indication child rows:', childRows);
  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  // const [approvedIndicationFields, setApprovedIndicationFields] = useState<Field[]>([]);
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );
  
  const updateApprovedIndication = (obj: any, index: number) => {
    
    const armObj = [...values[field.name]];
    let last_index_check = false;
    if(index == armObj.length -1)
      last_index_check = true
    armObj[index] = obj;
    fieldChanged(field.name, "", armObj,last_index_check);
  };

  const deleteApprovedIndication = (arm_index: number) => {
    let _selectedLookupGlobe = {...selectedLookupGlobe};
    let _selectedLookupGlobeSpecial= {...selectedLookupGlobeSpecial};

    for ( let i = arm_index; i < values[field.name].length-1 ; i++){
      // _selectedLookupGlobeSpecial[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i}`] =
      //     _selectedLookupGlobeSpecial[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i+1}`] 

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_Item_${i}`] = [];

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_${i}`] = [];

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Patient_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Patient_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Patient_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Patient_${i}`] = [];

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_TreatmentLines_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_TreatmentLines_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_TreatmentLines_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_TreatmentLines_${i}`] = [];

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Modalities_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Modalities_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Modalities_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Modalities_${i}`] = [];

      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Disease_${i+1}`] != undefined ?
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Disease_${i}`] =
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Disease_${i+1}`] :
      _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Disease_${i}`] = [];
    }

    const approvedIndicationLength = values[field.name].length-1;
    delete _selectedLookupGlobeSpecial[`RegulatoryWatch_ApprovedIndication_Combination_Item_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Patient_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Combination_Item_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_TreatmentLines_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Modalities_${approvedIndicationLength}`];
    delete _selectedLookupGlobe[`RegulatoryWatch_ApprovedIndication_Disease_${approvedIndicationLength}`];

    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    dispatch(setSelectedLookupGlobeSpecial(_selectedLookupGlobeSpecial));
    if (values[field.name].length === 1) {
      fieldChanged(field.name, "", []);
      setTimeout(() => {
        fieldChanged(field.name, "", [{}]);
      }, 20);
      
    } else {
      const arm: any[] = [...values[field.name]];
      fieldChanged(field.name, "", [
        ...arm.filter((item: any, xi: number) => xi != arm_index),
      ]);
    }
  };

  // useEffect(() => {
  //   const getApprovedIndicationFields = async() => {
  //     const res = await apiCall("/api/sub-page/approved_indication/fields");
  //     setApprovedIndicationFields(res.data);
  //     console.log("indication data",res.data);
  //   } 
  //   getApprovedIndicationFields()
  // },[])

  return (
    <>
      <div
        id="approved-indication-header"
        className="col-sm-12"
        style={{
          display: "flex",
          paddingTop: "5px",
          paddingBottom: "5px",
          marginTop: "5px",
        }}
      >
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndications.ApprovalDate"
            ).map(({id}) => id)[0]}
            label={"Approval date"}
            infoLabel={"Approval date"} 
            // label={"Approval date/Indication"}
            // infoLabel={"Approval date/Indication"}
            description={
              "This field provides the initial approval date for original approvals and the approval date for the extension for line extensions."
            }
            required={true}
            values={values}
          />
        </div>
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndications.Type_ID"
            ).map(({id}) => id)[0]}
            label={"Approval type/Indication"}
            infoLabel={"Approval type/Indication"}
            // label={"Approval type"}
            // infoLabel={"Approval type"}
            description={`This field provides the regulatory approval types
                * Original
                * Extension of indication`}
            required={true}
            values={values}
          />
        </div>
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndication_Combination"
            ).map(({id}) => id)[0]}
            label={"In combination with/ Combinations"}
            infoLabel={"Combination"}
            description={
              "This field specifies if the drugs used in combination with assessed drug."
            }
            required={false}
            values={values}
          />
        </div>
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndication_Patient"
            ).map(({id}) => id)[0]}
            label={"Patient population"}
            infoLabel={"Population"}
            description={
              "This field specifies the patient population for the assessed drug."
            }
            required={false}
            values={values}
          />
        </div>
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndication_TreatmentLines"
            ).map(({id}) => id)[0]}
            label={"Treatment line/modality"}
            infoLabel={"Treatment line"}
            description={
              "This field specifies the treatment line and treatment modality of the drug assessed by the regulator."
            }
            required={false}
            values={values}
          />
        </div>
        <div className="header-cell col-sm-2" style={{ display: "flex" }}>
          <LabelInfoPopup
            datasourceName={datasourcename}
            section={section}
            fId = {childRows.filter(
              (x) =>
                x.name ==
                "RegulatoryWatch_ApprovedIndication_Disease"
            ).map(({id}) => id)[0]}
            label={"Disease"}
            infoLabel={"Disease"}
            description={
              "This field specifies the disease(s) assessed by the regulator."
            }
            required={true}
            values={values}
          />
        </div>
      </div>
      <hr />
      <div id="approved-indication-rows" className="col-sm-12">
        <>
          {values &&
            values[field.name] &&
            childRows.length > 0 &&
            values[field.name].map((val: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  id={`approved-indication-row-${index}`}
                  className="col-sm-12"
                  style={{ display: "flex", paddingLeft: 0 }}
                >
                  <div className="row-cell col-sm-2">
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndications.ApprovalDate"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                  <div className="row-cell col-sm-2">
                    <FieldView
                      field={{
                        ...(childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndications.Type_ID"
                        ) || childRows[0]),
                        fieldTypeId: 4,
                      }}
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                  <div className="row-cell col-sm-2">
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndication_Combination"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                    <hr />
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndication_Combination_Item"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                  <div className="row-cell col-sm-2">
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndication_Patient"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                  <div className="row-cell col-sm-2">
                    <>
                      <FieldView
                        field={
                          childRows.find(
                            (x) =>
                              x.name ==
                              "RegulatoryWatch_ApprovedIndication_TreatmentLines"
                          ) || childRows[0]
                        }
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[field.name][index]}
                        setObj={updateApprovedIndication}
                        index={index}
                        hideTitleLabel={true}
                      />
                      <hr />
                      <FieldView
                        field={
                          childRows.find(
                            (x) =>
                              x.name ==
                              "RegulatoryWatch_ApprovedIndication_Modalities"
                          ) || childRows[0]
                        }
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[field.name][index]}
                        setObj={updateApprovedIndication}
                        index={index}
                        hideTitleLabel={true}
                      />
                    </>
                  </div>
                  <div className="row-cell col-sm-2">
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndication_Disease"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                  <label
                    onClick={() => deleteApprovedIndication(index)}
                    style={{ color: "rgb(0, 170, 231)", paddingTop: "100px",marginLeft:"-27px"}}
                  >
                    {/* <HtmlTooltip
                      title={
                      <React.Fragment>
                        <Typography color="inherit" sx={{ padding: 1 }}>
                          Remove this indication
                        </Typography>
                        </React.Fragment>
                      }
                    >  */}
                    <RiDeleteBinLine className="Delete-Icon-styles" title="Remove this indication" />
                    {/* </HtmlTooltip> */}
                    {/* <RiDeleteBinLine style={{fontSize:"medium"}} /> */}
                  </label>
                </div>

                <div
                  id={`approved-indication-row-${index}-indication`}
                  className="col-sm-12"
                  style={{ display: "flex", paddingLeft: 0 }}
                >
                  <div className="row-cell col-sm-4">
                    <FieldView
                      field={
                        childRows.find(
                          (x) =>
                            x.name ==
                            "RegulatoryWatch_ApprovedIndications.Indication"
                        ) || childRows[0]
                      }
                      values={values}
                      fieldChanged={fieldChanged}
                      RichtextFieldChanged={RichtextFieldChanged}
                      datasourcename={datasourcename}
                      section={section.name}
                      datasourceId={datasourceId}
                      parentObject={values[field.name][index]}
                      setObj={updateApprovedIndication}
                      index={index}
                      hideTitleLabel={true}
                    />
                  </div>
                </div>
                <hr />
              </React.Fragment>
            ))}
        </>
      </div>
    </>
  );
};

export default ApprovedIndications;
