// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
 width: 420px !important;
}

#toast-container > div {
      width: 400px;
}

#toast-container>.toast-info {
      background-color: #2f96b4;
      opacity:1;
      box-shadow:0px 0px 10px rgba(0,0,0,0.2);
    }
    
#toast-container>.toast-info:hover {
      background-color: #2f96b4;
      opacity:1;
      box-shadow:0px 0px 10px rgba(0,0,0,0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;;AAEA;MACM,YAAY;AAClB;;AAEA;MACM,yBAAyB;MACzB,SAAS;MACT,uCAAuC;IACzC;;AAEJ;MACM,yBAAyB;MACzB,SAAS;MACT,uCAAuC;AAC7C","sourcesContent":[".toast {\n width: 420px !important;\n}\n\n#toast-container > div {\n      width: 400px;\n}\n\n#toast-container>.toast-info {\n      background-color: #2f96b4;\n      opacity:1;\n      box-shadow:0px 0px 10px rgba(0,0,0,0.2);\n    }\n    \n#toast-container>.toast-info:hover {\n      background-color: #2f96b4;\n      opacity:1;\n      box-shadow:0px 0px 10px rgba(0,0,0,0.4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
