import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Filter from "../Dashboard/filter";
import Pathway from "../Dashboard/pathway";
import TableData from "../Table/tabledata";
import Overview from "./overview";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
// import Overview from "./overview";
// import SearchIcon from "@mui/icons-material/Search";
// import GridExample from "../Table/AGGrid";
// import SearchResult from "../SearchResult/SearchResult";
import Spinner from "react-bootstrap/Spinner";
//import SaveIcon from "@mui/icons-material/Save";
import { BiSave } from "react-icons/bi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GetDatasourceName from "../AutocompleteModule/utility";
import {
  saveSearchData,
  setDataSource,
  setDefaultColumnData,
  setEditColumnData,
  setUserPreferenceData,
  setColumnRearranged,
  setFilterState,
  setPriorFilterState,
  setIsSearchGridLoading,
  setColumnFilters,
  setInitiateSearch,
  setFilterColumnTypes,
  setSelectedColumnFiters
} from "../../store/SearchSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import TableGrid from "../Table/TableGrid";
import ClientNews from "../ClientNewsArticles/clientNews";
import TMEditor from "../RichTextEditor/TinyMCEEditor";
import {
  setLookupData,
  setLookupGlobe,
  setSelectedLookupGlobe,
  setExportModalShow,
  setSuperDisease,
  setLookupSource,
} from "../../store/MultiSelectSlice";
import DraggablePopover from "../Popups/Popup_MaterialUI";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SearchOffIcon from '@mui/icons-material/SearchOff';

import {
  useGetDefaultColumnsMutation,
  useGetEditColumnsDataMutation,
  useSetUserPreferenceMutation,
  useGetPageMutation,
  useGetColumnFilterValuesMutation,
  useGetFilteredValuesForColumnFIlterMutation
  // useGetRecordIdDataMutation,
} from "../../store/SearchResultApiSlice";
import AssignModal from "../Popup/AssignModal";
import InternalUserTracker from "./InternalUserTracker";
import {
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useSubscriptionsQuery } from "../../store/DataSourceMetaDataApiSlice";
// import { useGetExportOptionsMutation } from "../../store/SearchResultApiSlice";
import apiCall from "../service/apiCall";

import PostCall from "../service/postCall";
import { getIdFieldName } from "../../utils";
import { useFetchLookupItemsQuery } from "../../store/LookupApiSlice";
import MoreClientNews from "../ClientNewsArticles/moreClientNews";
import {
  setChangeFilter,
  setLevel,
  setPathwayFilter,
  setPathwayRule,
  setSearch,
} from "../../store/pathwaySlice";
import NewModal from "../Popup/NewModal";
import {
  saveSavedSearchCount,
  savedSelectionCount,
  saveSubscribedDs,
} from "../../store/UserProfileApiSlice";
import DropDownForExport from "../Export/exportOption";
import ExportModal from "../Export/exportModal";
import settings from "../../img/settings.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import PricingView from "../Pricing/PricingView";
import { remove } from "toastr";
import {
  DataSourceListTypes,
  UserRoles,
  isCommaSeparatedNumbers,
} from "../Shared/Utility";

import {
  useFetchSavedSearchAlertDataMutation,
  useFetchSavedSelectionDataMutation,
} from "../../store/SavedSearchAlertApiSlice";

import {
  saveSavedSearchData,
  saveSavedSelectionData,
  setLoading, 
  storeSavedSearchAlertData, 
  storeSavedSearchAlertDeactiveData
} from "../../store/SavedSearchSlice";

import { getDatasourceId } from "../../utils";
import UpdateCall from "../service/updateCall";
import { useGetSavedSearchAlertActiveDataMutation, useGetSavedSearchAlertDeactiveDataMutation } from "../../store/AdminApiSlice";
//import AuditLogChangeButtion from "../Forms/AuditLogChangebuttion";
export interface IfilterState {
  [name: string]: string | null;
}

interface dashBoardProps {
  roleId: number;
  clientName: string;
  clientId: number;
  accountId: number;
  pathwayFixed: boolean;
}

type AdminObj = {
  label: string;
  ID: number;
  UserID?: number;
};

const { useRef } = React;

export default function Dashboard(props: dashBoardProps) {
  let { roleId, clientName, clientId, accountId, pathwayFixed } = props;
  const dispatch = useAppDispatch();
  const [internalUsage, setInternalUsage] = useState(false);
  const [datasourceWithLargeDataset, setDatasourceWithLargeDataset] = useState(false);
  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const initiateSearch = useAppSelector((state) => state.search.initiateSearch);
  const searchData = useAppSelector((state) => state.search.searchData);
  const accountid = useAppSelector((state) => state.userProfile.accountId);
  const clientid = useAppSelector((state) => state.userProfile.clientId);
  const defaultColumn = useAppSelector((state) => state.search.defaultColumn);
  const editColumnData = useAppSelector(
    (state) => state.search.editColumnData.gridData
  );
  const columnSectionInfo = useAppSelector(
    (state) => state.search.editColumnData.sectionsData
  );
  const preferenceData = useAppSelector((state) => state.search.preferenceData);
  const columnRearranged = useAppSelector(
    (state) => state.search.columnRearranged
  );
  const searchDataSourceId = useAppSelector(
    (state) => state.search.searchDataSourceId
  );
  const isSearchgGridLoading = useAppSelector(
    (state) => state.search.isSearchgGridLoading
  );
  const searchError = useAppSelector((state) => state.search.searchError);
  const selectedDatasourceDbName = useAppSelector((state) => state.search.selectedDatasourceDbName);
  const currClientName = useAppSelector(
    (state) => state.userProfile.currentClientName
  );
  const currentRole = useAppSelector((state) => state.userProfile.currentRole);
  const currRoleId = localStorage.getItem("CurrRoleId");
  const currentUserId = useAppSelector(
    (state) => state.userProfile.currentUserId
  );
  const currentSavedSearchCount = useAppSelector(
    (state) => state.userProfile.savedSearchCount
  );
  const currentSavedSelectionCount = useAppSelector(
    (state) => state.userProfile.selectionCount
  );
  const [currentDataSource, setCurrentDataSource] = useState<number>(0);
  const [currentDataSourceName, setCurrentDataSourceName] =
    useState<string>("");
  const [currentSearchDataSource, setcurrentSearchDataSource] =
    useState<number>(0);
  const [currentSearchDataSourceName, setCurrentSearchDataSourceName] =
    useState<string>("");
  const [getEditColumnsData, { isLoading: isColumnGridLoading }] =
    useGetEditColumnsDataMutation();
  const [isAssignDisabled, setIsAssignDisabled] = useState<boolean>(true);
  const [selectedRowsId, setSelectedRowsId] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCompareButton, setShowCompareButton] = useState<boolean>(false);
  const [compareRecords, setCompareRecords] = useState<any[]>([]);
  const [pricingChartView, setPricingChartView] = useState<boolean>(false);
  const [pricingCompareRecord, setPricingCompareRecord] = useState({});

  const [embeddedDS, setEmbeddedDS] = useState<number>(0);
  const [isEmbeddedDisabled, setIsEmbeddedDisabled] = useState<boolean>(true);
  const [isHTANameDisabled, setIsHTANameDisabled] = useState<boolean>(true);
  const [isClinicalNameDisabled, setIsClinicalNameDisabled] =
    useState<boolean>(true);
  const [isRegNameDisabled, setIsRegNameDisabled] = useState<boolean>(true);
  const [embDdOpen, setEmbDdOpen] = React.useState(false);
  const [selectedRecordIds, setSelectedRecordIds] = React.useState<string>();

  const [showFilter, setShowFilter] = React.useState<boolean>(true);
  const [subscribedDS, setSubscribedDS] = React.useState<string[]>([]);

  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [pathwayShow, setPathwayShow] = useState(true);
  const [newsArticleShow, setNewsArticleShow] = useState(true);
  const [topTenRecords, setTopTenRecords] = useState(true);
  const search = useAppSelector((state) => state.pathway.search);

  const [currOption, setCurrOption] = useState<number>(0);

  const resultGridRef = useRef();
  const [getDefaultColumns] = useGetDefaultColumnsMutation();
  const [setUserPreference] = useSetUserPreferenceMutation();
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const searchFilterState = useAppSelector((state) => state.search.filterState);
  const pathwayFilterState = useAppSelector((state) => state.search.pathwayFilterState);
  const [ssStatusVal, setSsStatusVal] = useState<string>("new");
  const [searchType, setSearchType] = useState<string>("individual");
  const [saveFor, setSaveFor] = useState<string>("self");
  const [userList, setUserList] = useState<AdminObj[]>([]);
  const [clientList, setClientList] = useState<AdminObj[]>([]);
  const [clientGroupList, setClientGroupList] = useState<AdminObj[]>([]);
  const [userOpen, setUserOpen] = React.useState(false);
  const [clientOpen, setClientOpen] = React.useState(false);
  const [clientGroupOpen, setClientGroupOpen] = React.useState(false);
  const [alertChecked, setAlertChecked] = React.useState(true);
  const [isActive, setIsActive] = React.useState(true);
  const [ssName, setSsName] = useState<string>("");
  const [ssDesc, setSsDesc] = useState<string>("");
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [selCriteria, setSelCriteria] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserAccount, setSelectedUserAccount] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientGroup, setSelectedClientGroup] = useState(null);
  const [selectedAccName, setSelectedAccName] = useState<string>("");
  //const saveSearchTypes = ["new", "existing"];

  const [disableState, setDisableState] = useState(false);

  const [nameAlertOpen, setNameAlertOpen] = useState(false);
  const [userAlertOpen, setUserAlertOpen] = useState(false);
  const [clientAlertOpen, setClientAlertOpen] = useState(false);
  const currUserId = localStorage.getItem("CurrUserId");
  const [gridRowCount, setGridRowCount] = useState(searchData.rowData.length);
  const [totalGridRowCount, setTotalGridRowCount] = useState(
    searchData.rowData.length
  );
  const [pathwaySearch, setPathwaySearch] = useState(true);
  const [freeText, setFreeText] = useState("");
  const [savedSearchOrSelectionName, setSavedSearchOrSelectionName] = useState("");
  const [triggerSearchFunction, setTriggerSearchFunction] =
    useState<boolean>(false);

  const accountType = useAppSelector((state) => state.userProfile.accountType);
  const userAccessData = useAppSelector(
    (state) => state.userProfile.userAccessData
  );
  const pathwayRule = useAppSelector((state) => state.pathway.pathwayRule);
  const [enableSelectionButton, setEnableSelectionButton] = useState(false);

  const [datasourceChange, setDatasourceChange] = useState(false);

  const isEclient = Number(currRoleId) > 4 && Number(currRoleId) < 8;
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const savedFilterState = useAppSelector((state) => state.search.filterState);
  const [searchClicked, setSearchClicked] = useState(false);
  const isExeedingSearchThreshold = useAppSelector(
    (state) => state.search.isExeedingSearchThreshold
  );
  const totalResultCount = useAppSelector(
    (state) => state.search.totalResultCount
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const isPathwaySearch = useAppSelector((state) => state.search.isPathwaySearch); // for pathway pagination
  const pathwayColumns = useAppSelector(state => state.pathway.columnsData);
  const subscribedDatasources = useAppSelector((state) => state.userProfile.subscribedDs);

  useEffect(() => {
    setSuccessAlertOpen(false);
    setGridRowCount(searchData.rowData.length);
    setTotalGridRowCount(searchData.rowData.length);
    if (searchData.rowData.length > 0 && searchData.rowData.length <= 2) {
      setPricingChartView(true);
    } else setPricingChartView(false);
  }, [searchData.rowData]);

  const history = useHistory();
  let savedSearchId: number = 0;
  const routeMatch: any = useRouteMatch();
  savedSearchId = Number(routeMatch.params.savedSearchId);
  let savedSelectionId: number = 0;
  const routeMatchselection: any = useRouteMatch();
  savedSelectionId = Number(routeMatchselection.params.savedSelectionId);

  const fetchUsers = useFetchLookupItemsQuery({
    lookup_id: 302,
    datasource_id: dataSourceId,
  });
  const fetchClients = useFetchLookupItemsQuery({
    lookup_id: 127,
    datasource_id: dataSourceId,
  });
  const currSavedSearchCount = useAppSelector(
    (state) => state.userProfile.savedSearchCount
  );
  const currSavedSelectionCount = useAppSelector(
    (state) => state.userProfile.selectionCount
  );
  const [fetchSavedSearchAlertData, { data: savedSearchAlertData }] =
    useFetchSavedSearchAlertDataMutation();
  const [fetchSavedSelectionData, { data: savedSelectionData }] =
    useFetchSavedSelectionDataMutation();
  const GetSavedSearchAlertData = async () => {
    const savedSearchAlertResult: any = await fetchSavedSearchAlertData({});
    dispatch(saveSavedSearchData(savedSearchAlertResult.data));
  };
  const GetSavedSelectionData = async () => {
    const savedSelectionResult: any = await fetchSavedSelectionData({});
    dispatch(saveSavedSelectionData(savedSelectionResult.data));
  };
  const SavedSearchactiveData =useAppSelector((state) => state.savedSearch.searchData.rowData)
  const [getSavedSearchAlertActiveData, {data:SavedSearchActiveData}] = useGetSavedSearchAlertActiveDataMutation();
  const GetSavedSearchActiveData = async () => {
    const searchResult:any = await getSavedSearchAlertActiveData({});
    dispatch(setLoading(true));
    dispatch(
      storeSavedSearchAlertData({
        data: searchResult.data,
        datasourceId: dataSourceId,
      })
    );
  };
  const [getSavedSearchAlertDeactiveData, {data:SavedSearchDeActiveData}] = useGetSavedSearchAlertDeactiveDataMutation();
  const GetSavedSearchDeActiveData = async () => {
    const searchResult = await getSavedSearchAlertDeactiveData({});
    dispatch(setLoading(true));
    dispatch(
      storeSavedSearchAlertDeactiveData({
        data: searchResult.data,
        datasourceId: dataSourceId,
      })
    );
  };

  const recordIdsearch = useAppSelector((state) => state.search.recordIdSearch);
  const selectedColFilters = useAppSelector((state) => state.search.selectedColumnFilter);
  const filterColumnTypes = useAppSelector((state) => state.search.filterColumnTypes);
  const totalFilteredCount = useAppSelector((state) => state.search.totalFilteredCount);

  useEffect(() => {
    // if(Object.keys(selectedColFilters).length !== 0 || totalFilteredCount!= null){// removing this as grid was not loading correct data when we clear and then apply filter
    if(totalFilteredCount == null){
      setCurrentPage(1);
      setPageSize(100);
    }
    if(totalResultCount > 0){  // to avoid this call on switching between datasources
      dispatch(setIsSearchGridLoading(true));
      const  payload = {
        accountID: accountid,
        datasourceId: dataSourceId,
        totalCount: totalResultCount,
        filters: selectedColFilters,
        visibleCol: defaultColumn.showColumnsNames,
        filterColumnTypes,
        currentPage: totalFilteredCount!= null ? currentPage : 1,
        pageSize: totalFilteredCount!= null ? pageSize : 100,
        isPagination: datasourceWithLargeDataset
      };
      getFilteredSearchResults(payload);
    }
  }, [selectedColFilters]);

  const [getFilteredResults] = useGetFilteredValuesForColumnFIlterMutation();
  const getFilteredSearchResults = async(payload: any) => {
    

    const searchResult = await getFilteredResults(payload);
    dispatch(saveSearchData(searchResult));
    
  }

  //We need to have the useEffect to call the recordIdSearch function and we need to have the if Statement
  //to check if the recordIdsearch is true then setPathwayshow to false:
  useEffect(() => {
    if (recordIdsearch) {
      setPathwayShow(false);
      setNewsArticleShow(false);
      setTopTenRecords(false);
    }
  }, [recordIdsearch]);

  const recordIdloading = useAppSelector(
    (state) => state.search.recordIdDataLoading
  );

  const recordIdData = useAppSelector((state) => state.search.recordIdData);

  const nodes = useAppSelector((state) => state.multiselect.SuperDisease);
  const [formSavingProgress, setFormSavingProgress] = useState<boolean>(false);
  //savedsearch data from redex store
  const newSavedSearchData = useAppSelector(
    (state) => state.savedSearch.searchSavedSearchData
  );
  //savedselection data from redex store
  const newSavedSelectionData = useAppSelector(
    (state) => state.savedSearch.searchSavedSelectionData
  );



  useEffect(() => {
    GetSavedSearchAlertData();
  }, [currSavedSearchCount]);

  useEffect(() => {
    GetSavedSelectionData();
  }, [currSavedSelectionCount]);

  ////Commented this part as for getting the name for saved search can be done from GetSpecificSavedSearchData
  // useEffect(() => {
  //   GetSavedSearchActiveData();
  // }, []);
  // useEffect(() => {
  //   GetSavedSearchDeActiveData();
  // }, []);

  const handleChangeAlert = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertChecked(!alertChecked);
  };

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data.length > 0) {
      let arr = [];
      for (let c of fetchUsers.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
          UserID: c.Parent_ID,
        });
      }
      setUserList(arr);
    }
    if (fetchClients.data && fetchClients.data.length > 0) {
      let arr = [];
      for (let c of fetchClients.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setClientList(arr);
    }
  }, [fetchUsers.isLoading, fetchClients.isLoading]);

  const renderOptions = (
    props: React.HTMLAttributes<HTMLElement>,
    option: Partial<any>
  ) => {
    return (
      <li {...props} key={option.ID}>
        {option.label}
      </li>
    );
  };

  let locationPath : any = useLocation();
  let path = locationPath.pathname.split("/");


  useEffect(() => {
    if (location.href.toLowerCase().indexOf("news/list") > 0) {
      changeDataSource(276, 276);
    } else if (path[2] === "ByDrug") {
      changeDataSource(1, 11);
      getSubscriptions();  
      setTriggerSearchFunction(true);
    } else if (path[2] === "ByDrugPricing") {
      changeDataSource(4, 13);
      getSubscriptions();  
      setTriggerSearchFunction(true);
    } else if (path[2] === "ByDrugClinical") {
      changeDataSource(2, 9);
      getSubscriptions();  
      setTriggerSearchFunction(true);
    } else if (path[2] === "ByDrugRegulatory") {
      changeDataSource(3, 14);
      getSubscriptions();  
      setTriggerSearchFunction(true);
    } else {
      const prevDatasourceId = Number(sessionStorage.getItem("prevDatasourceId")) || 0;
      const prevSearchId = Number(sessionStorage.getItem("prevSearchId"))|| 0;
      sessionStorage.removeItem("prevDatasourceId");
      sessionStorage.removeItem("prevSearchId");
      if (isEclient) {
        setTimeout(() => {
              apiCall("/api/subscriptions").then((resp) => {
                if (resp.data.toString().indexOf(",") > 0) {
                  const sortedDatasources = resp.data.split(',').map(Number).sort(function (a: any, b: any) {
                    return a - b;
                  });
                  setSubscribedDS(resp.data.split(","));
                  dispatch(saveSubscribedDs(resp.data));
                  setCurrentDataSource(prevDatasourceId || sortedDatasources[0]);
                  setcurrentSearchDataSource(prevSearchId || getSearchDatasourceId(sortedDatasources[0]));
                  dispatch(setDataSource(prevDatasourceId || sortedDatasources[0]));
                  // dispatch(setInitiateSearch(false));
                  // dispatch(setIsSearchGridLoading(false));
                } else {
                  let t = [];
                  t.push(resp.data);
                  setSubscribedDS(t);
                  dispatch(saveSubscribedDs(resp.data));
                  setCurrentDataSource(prevDatasourceId || resp.data);
                  setcurrentSearchDataSource(prevSearchId || getSearchDatasourceId(resp.data));
                  changeDataSource(prevDatasourceId || resp.data, prevSearchId || getSearchDatasourceId(resp.data));
                }
              });
        }, 1000);
      } else {
  
        changeDataSource(prevDatasourceId || 1, prevSearchId || 11);
        dispatch(setDataSource(prevDatasourceId || 1));
        dispatch(setInitiateSearch(false));
        dispatch(setIsSearchGridLoading(false));
        setSubscribedDS("1,2,3,4,5,6,7,20,23,25".split(","));
        dispatch(saveSubscribedDs("1,2,3,4,5,6,7,20,23,25"));
      }
    }
  }, []);

  useEffect(() => {
    setDatasourceWithLargeDataset([1,2,3,4,5,6,7,20,23,25].includes(dataSourceId));
    if (dataSourceId !== currentDataSource) {
      setCurrentDataSource(dataSourceId);
      setcurrentSearchDataSource(getSearchDatasourceId(dataSourceId));
    }

    if (
      nodes.length == 0 &&
      [
        DataSourceListTypes.HTARecord,
        DataSourceListTypes.ClinicalData,
        DataSourceListTypes.RegulatoryWatch,
        DataSourceListTypes.ReimbursementStatus,
      ].includes(dataSourceId)
    ) {
      apiCall("/api/diseaseDetails").then((resp) => {
        let response = resp.data;
        response = response.filter((el: any) => el["children"].length > 0);
        dispatch(setSuperDisease(response));
        // setLoading(false);
      });
      apiCall("/api/AllDiseaseDetails").then((resp) => {
        dispatch(setLookupSource(resp.data));
      });
    }
  }, [dataSourceId]);

  useEffect(() => {
    setCurrOption(0);
  }, [currentDataSource]);

  useEffect(() => {
    InitDataSource(currentDataSource);
    if (accountType !== "" && currentSearchDataSource > 0) {
      getDefaultCol();
      getEditColumns();
    }
  }, [currentDataSource, accountType]);

  // useEffect(() => {
  //   setCurrentDataSource(dataSourceId);
  //   setcurrentSearchDataSource(getSearchDatasourceId(dataSourceId));
  // }, [dataSourceId]);

  // useEffect(() => {
  //   if(!pathwayFixed) {
  //     setPathwayDatasourceId(dataSourceId);
  //   }else {
  //     if(pathwayRule === "pathway-c-1") {
  //       setPathwayDatasourceId(2);
  //     }
  //     if(pathwayRule === "pathway-r-1") {
  //       setPathwayDatasourceId(3);
  //     }
  //   }

  // },[currentDataSource]);

  // useEffect(() => {
  //   getExportOptions();
  // }, [currentSearchDataSource]);

  const getSubscriptions = () => {
    if (isEclient) {
      if(subscribedDatasources?.includes(',')){
        setSubscribedDS(subscribedDatasources.split(','));
      }else{
        setTimeout(() => {
          const getSubs = async() => {
            const resp = await apiCall("/api/subscriptions");
            if (resp.data.toString().indexOf(",") > 0) {
              setSubscribedDS(resp.data.split(","));
              dispatch(saveSubscribedDs(resp.data));
    
            } else {
              let t = [];
              t.push(resp.data);
              setSubscribedDS(t);
              dispatch(saveSubscribedDs(resp.data));
            }
          }
          getSubs();
        }, 1000);
      }
    } else {
      setSubscribedDS("1,2,3,4,5,6,7,20,23,25".split(","));
      if(!subscribedDatasources?.includes(','))
        dispatch(saveSubscribedDs("1,2,3,4,5,6,7,20,23,25"));
    }
  }

  const changeDataSource = (
    dsId: number,
    searchDsId: number,
    navigateDS: boolean = false
  ) => {
    if (navigateDS) {
      savedSearchId = 0;
      if(path[2]){
        sessionStorage.setItem("prevDatasourceId",String(dsId));
        sessionStorage.setItem("prevSearchId",String(searchDsId))
      }
      history.push("/");
    }
    dispatch(setChangeFilter(0));
    setAnchorEl(null);
    setCurrentDataSource(dsId);
    setCurrentPage(1);
    setPageSize(100);
    dispatch(setFilterColumnTypes({}));
    dispatch(setSelectedColumnFiters({}));
    InitDataSource(dsId);
    setcurrentSearchDataSource(searchDsId);
    //setPathwaySearch(false);
    dispatch(setLevel(0));
    dispatch(setDataSource(dsId));
    dispatch(setInitiateSearch(false));
    dispatch(setIsSearchGridLoading(false));
    // window.history.replaceState(null, "HOME-IQVIA HTAA", "/");
    if (!path.includes("Search")) {
      dispatch(setSelectedLookupGlobe({}));
      dispatch(setLookupGlobe({}));
      dispatch(setLookupData([]));
    }
    if (path.includes("savedSelection")) {
      newSavedSelectionData &&
        newSavedSelectionData
          .filter((obj) => obj.Id == savedSelectionId)
          .map((el) => {
            return dispatch(setDataSource(getDatasourceId(el.Entity_Type)));
          });
      setNewsArticleShow(false);
      setTopTenRecords(false);
      setDatasourceChange(true);
    }
    if (path.includes("savedSearch")) {
      newSavedSearchData &&
        newSavedSearchData
          .filter((obj) => obj.ID == savedSearchId)
          .map((el) => {
            return dispatch(setDataSource(el.DatasourceID));
          });
      setNewsArticleShow(false);
      setTopTenRecords(false);
      setDatasourceChange(true);
    }
    setFreeText("");
    dispatch(setFilterState({}));
    dispatch(setPriorFilterState({}));
    //dispatch(setSearchDataSource(searchDsId));
  };

  const getSearchSectionId = (dsId: number) => {
    switch (dsId) {
      case 8:
        return 111;
      case 9:
        return 112;
      case 10:
        return 113;
      case 11:
        return 114;
      case 12:
        return 115;
      case 13:
        return 116;
      case 14:
        return 117;
      case 22:
        return 156;
      case 24:
        return 193;
      case 26:
        return 181;
      case 28:
        return 254;
      default:
        return null;
    }
  };

  const getSearchDatasourceId = (dsId: number) => {
    switch (dsId) {
      case 1:
        return 11;
      case 2:
        return 9;
      case 3:
        return 14;
      case 4:
        return 13;
      case 5:
        return 12;
      case 6:
        return 8;
      case 7:
        return 10;
      case 20:
        return 22;
      case 23:
        return 24;
      case 25:
        return 26;
      case 27:
        return 28;
      default:
        return 0;
    }
  };

  const getDefaultCol = async () => {
    setFormSavingProgress(true)
    const defColPayload = {
      accountid: accountid,
      dsId: currentDataSource,
      searchDSId: currentSearchDataSource,
      roleID: currentRole,
    };
    const defColData: any = await getDefaultColumns(defColPayload);
    dispatch(setDefaultColumnData(defColData.data));
    setFormSavingProgress(false)
  };

  const getEditColumns = async () => {
    const sectionID = getSearchSectionId(searchDataSourceId);
    const editColumns: any = await getEditColumnsData({
      dsId: sectionID,
      roleID: currentRole,
    });
    dispatch(
      setEditColumnData({ data: editColumns.data, accountType: accountType })
    );
  };

  // const [getExportOptionsData] = useGetExportOptionsMutation();

  // const getExportOptions = async () => {
  //   const exportOptions = await getExportOptionsData({
  //     datasource_id: currentSearchDataSource,
  //   });
  //   console.log("getExportOptions : ", exportOptions);
  //   // dispatch(setLoading(true));
  //   // dispatch(
  //   //   storeSavedSearchData({
  //   //     data: toptenRecordsData.data,
  //   //     datasourceId: sourceId,
  //   //   })
  //   // );
  // };

  const exportCurrentView = () => {
    //if(resultGridRef.current != null)
    resultGridRef.current.exportCurrentview;
  };
  const InitDataSource = (dsId: number) => {
    let pendoPath;
    switch (dsId) {
      case 1:
        setCurrentDataSourceName("HTA");
        setCurrentSearchDataSourceName("HTARecord_Search");
        pendoPath = "HTA";
        break;
      case 2:
        setCurrentDataSourceName("Clinical");
        setCurrentSearchDataSourceName("ClinicalData_Search");
        pendoPath = "Clinical";
        break;
      case 3:
        setCurrentDataSourceName("Regulatory");
        setCurrentSearchDataSourceName("Regulatory_Search");
        pendoPath = "Regulatory";
        break;
      case 4:
        setCurrentDataSourceName("Pricing");
        setCurrentSearchDataSourceName("Pricing_Search");
        pendoPath = "Pricing";
        break;
      case 5:
        setCurrentDataSourceName("Policy");
        setCurrentSearchDataSourceName("Policy_Search");
        pendoPath = "Policy";
        break;
      case 6:
        setCurrentDataSourceName("Agency");
        setCurrentSearchDataSourceName("Agency_Search");
        pendoPath = "Agency";
        break;
      case 7:
        setCurrentDataSourceName("Country");
        setCurrentSearchDataSourceName("Country_Search");
        pendoPath = "Country";
        break;
      case 15:
        setCurrentDataSourceName("PLI");
        pendoPath = "PLI";
        break;
      case 20:
        setCurrentDataSourceName("Drug");
        setCurrentSearchDataSourceName("Drug_Search");
        pendoPath = "Drug";
        break;
      case 23:
        setCurrentDataSourceName("Agreement");
        setCurrentSearchDataSourceName("Agreement_Search");
        pendoPath = "Agreement";
        break;
      case 25:
        setCurrentDataSourceName("Reimbursement");
        setCurrentSearchDataSourceName("ReimbursementStatus_Search");
        pendoPath = "Reimbursement";
        break;
      case 27:
        setCurrentDataSourceName("Treatment cost");
        setCurrentSearchDataSourceName("TreatmentCost_Search");
        pendoPath = "Treatment cost";
        break;
      case 276:
        setCurrentDataSourceName("News");
        pendoPath = "News";
        break;
      default:
        setCurrentDataSourceName("");
        pendoPath = "Unknown";
        break;
    }
    if (pendo.location) {
      pendo.location.addTransforms([
        {
          attr: "pathname",
          action: "Replace",
          data: "/homepage/" + pendoPath,
        },
      ]);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElEmb, setAnchorElEmb] =
    React.useState<HTMLButtonElement | null>(null);

  const [anchorElSs, setAnchorElSs] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorSs, setAnchorSs] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleEditColumnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmbClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElEmb(event.currentTarget);
    switch (currentDataSource) {
      case 1:
        setIsClinicalNameDisabled(false);
        setIsRegNameDisabled(false);
        break;
      case 2:
        setIsHTANameDisabled(false);
        setIsClinicalNameDisabled(true);
        setIsRegNameDisabled(false);
        break;
      case 3:
        setIsHTANameDisabled(false);
        setIsClinicalNameDisabled(false);
        break;
    }
  };
  const handleSsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSs(event.currentTarget);
    setDisableState(false);
    if (roleId == UserRoles.QAdmin) {
      setSearchType("individual");
    } else if (roleId != UserRoles.QAdmin && saveFor == "self") {
      setSelectedUser(currentUserId);
      setSelectedUserAccount(accountid);
    } else {
      setSelectedUser(currentUserId);
      setSelectedUserAccount(accountid);
      setSelectedClient(clientid);
    }
  };
  const handleSsClickSavedselection = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorSs(event.currentTarget);
    setDisableState(false);
    if (locationPath.pathname.includes("savedSearch")) {
      setSsName("");
      setSsDesc(" ");
      setSsStatusVal("new");
    }
    if (roleId == 1) {
      setSearchType("individual");
    } else if (!(roleId == 1)) {
      setSaveFor("self");
      setSelectedUser(currUserId);
      setSelectedUserAccount(accountid);
    }
  };
  const saveEditColumnChanges = (tableName: string) => {
    saveColumnChanges(tableName);
    tableName == "EditColumnTable" && setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseEmb = () => {
    setAnchorElEmb(null);
  };
  const handleCloseSs = () => {
    setAnchorElSs(null);
  };
  const closeSs = () => {
    setAnchorSs(null);
  };

  const updateSelectedCriteria = (cr) => {
    Object.entries(cr).forEach(([key, val]) => {
      if (
        val != null &&
        (typeof val == "boolean" ||
          key === "FreeText" ||
          !isCommaSeparatedNumbers(val))
      ) {
        cr[key] = val;
      } else if (val != null && (!isNaN(val) || val.indexOf(",") > 0)) {
        if (val.length >= 1) {
          cr[key] = val.split(",").map(Number);
        } else {
          if (val.length == 0) {
            delete cr.key;
          }
          if (val[0] != "") cr[key] = val[0];
        }
      }
    });
    setSelectedCriteria(cr);
  };

  const updateSelectedSearch = (cr) => {
    if (cr.sData.UserID != null && cr.sData.UserID > 0) {
      setSearchType("individual");
    } else if (cr.sData.ClientID != null && cr.sData.ClientID > 0) {
      setSearchType("group");
    }
    if (
      cr.sData.ID != null &&
      cr.sData.ID > 0 &&
      cr.sData.IsSearchCriteria != 1
    ) {
      setSsName(cr.sData.Name);
      setSsDesc(cr.sData.Description);
      setSelectedUserAccount(cr.sData.AccountID);
      setSsStatusVal("existing");
      cr.sData.SearchCriteriaBlob &&
        setSelectedCriteria(JSON.parse(cr.sData.SearchCriteriaBlob));
      setSelectedUser(cr.sData.UserID);
    }
  };
  //while opening saved selection records from saved selection dropdown and clicking the saved selection link it opens the existing saves selection.
  const updateSelectedSavedSelection = (cr) => {
    if (cr.sData.Id != null && cr.sData.Id > 0) {
      setSsName(cr.sData.SelectionName);
      setSsDesc(cr.sData.Description);
      setSelectedUserAccount(cr.sData.AccountID);
      setSsStatusVal("existing");
      setSelectedCriteria(cr.sData.SearchCriteriaBlob);
      //setSelectedUser(cr.sData.UserId);
    }
  };

  useEffect(() => {
    columnRearranged && saveColumnChanges("SearchGrid");
  }, [columnRearranged]);

  const saveColumnChanges = async (tableName: string) => {
    const userPreferenceData = {
      accountId: accountid,
      preferenceKey: `ss-${currentDataSource}-NewUI`,
      preferenceData: preferenceData,
    };
    await setUserPreference(userPreferenceData);
    tableName == "SearchGrid" && dispatch(setColumnRearranged(false));
    // if (tableName == "EditColumnTable" && dataSourceId !== dataSourceId) {
    //   dispatch(saveSearchData({ data: searchData.rowData }));
    //   dispatch(setColumnFilters({ data: searchData.rowData }));
    // }

    setSuccessAlertOpen(true);
    if (tableName == "EditColumnTable" && dataSourceId == dataSourceId) {
      let selectedCloumnFilterTemp = {...selectedColFilters};
      let filterColumnTypesTemp = {...filterColumnTypes};
      Object.keys(selectedCloumnFilterTemp).forEach(function(key) {
        if(!defaultColumn.showColumnsNames.includes(key)){
          delete selectedCloumnFilterTemp[key];
          delete filterColumnTypesTemp[key];
          dispatch(setSelectedColumnFiters(selectedCloumnFilterTemp));
          dispatch(setFilterColumnTypes(filterColumnTypesTemp));
        }
          
      });
      dispatch(setIsSearchGridLoading(true));
      // initiateSearchResult(searchFilterState, dataSourceId);
      const  payload = {
        accountID: accountid,
        datasourceId: dataSourceId,
        totalCount: totalResultCount,
        filters: selectedCloumnFilterTemp,
        visibleCol: defaultColumn.showColumnsNames,
        filterColumnTypes,
        currentPage,
        pageSize,
        isPagination: datasourceWithLargeDataset,
      };
      getFilteredSearchResults(payload);
    }
  };

  const getSelectedColumns = (rows: any[]) => {
    dispatch(setUserPreferenceData(rows));
  };
  const getSelectedSearchRows = (rows: any[]) => {
    let currDs = GetDatasourceName(currentDataSource) + "_ID";
    if (rows) {
      const recordIds = rows.map((x) => {
        return x[`${currDs}`];
      });
      setSelectedRecordIds(recordIds.join(","));
    }
  };
  const checkSelection = (value: any[]) => {
    if (value.length > 0) {
      if (value.length === 2) {
        setShowCompareButton(true);
        setCompareRecords(value);
      } else {
        setShowCompareButton(false);
        setCompareRecords([]);
      }
      if (value.length >= 1) {
        setEnableSelectionButton(true);
      }
      let tmp = [];
      for (let x of value) {
        if (!!x.ID) {
          tmp.push(x.ID);
        }
      }

      setSelectedRowsId(tmp);
      setIsAssignDisabled(false);
      setIsEmbeddedDisabled(false);
    } else {
      setIsAssignDisabled(true);
      setIsEmbeddedDisabled(true);
      setSelectedRowsId([]);
      setEnableSelectionButton(false);
    }
  };

  const showAssignModal = () => {
    if (isAssignDisabled === false && showModal === false) {
      setShowModal(true);
    }
  };

  const initiateEmbeddedSearch = () => {
    initEmbSearch();
  };
  const initiateSaveSearch = async (event: any) => {
    if (disableState === true) {
      return;
    } else {
      setDisableState(true);
      if (ssName == "") {
        setNameAlertOpen(true);
        return false;
      } else {
        setNameAlertOpen(false);
      }
      if (roleId === 1 && searchType == "individual" && selectedUser == null) {
        setUserAlertOpen(true);
        return false;
      }
      if (roleId === 1 && searchType == "group" && selectedClient == null) {
        setClientAlertOpen(true);
        return false;
      }
      if (searchType == "individual") {
        setSelectedClient(null);
        setSelectedClientGroup(null);
      }
      if (searchType == "group") {
        setSelectedUserAccount(null);
        setSelectedUser(null);
      }
      if (roleId != 1 && saveFor && saveFor == "self") {
        setSelectedUser(currentUserId);
        setSelectedUserAccount(accountid);
        setSelectedClient(null);
        setSelectedClientGroup(null);
      }
      if (roleId != 1 && saveFor && saveFor == "client") {
        setSelectedUser(null);
        setSelectedUserAccount(null);
      }
      let currUserId = currentUserId;
      let criteria = JSON.stringify(selectedCriteria);
      let isCriteria: boolean = false;
      if (ssStatusVal === "existing") {
        let response = await UpdateCall(
          "/api/admin/updateSaveSearch",
          savedSearchId,
          {
            ssName,
            ssDesc,
            dataSourceId,
            alertChecked,
            isActive,
            currUserId,
            selectedUser,
            selectedUserAccount,
            selectedClient,
            selectedClientGroup,
            criteria,
            roleId,
            isCriteria,
          }
        );
        if (!!response) {
          setAnchorElSs(null);
          setAnchorSs(null);
          setNameAlertOpen(false);
          setUserAlertOpen(false);
          setClientAlertOpen(false);
        }
      } else {
        let response = await PostCall(`/api/admin/createSaveSearch/`, {
          ssName,
          ssDesc,
          dataSourceId,
          alertChecked,
          isActive,
          currUserId,
          selectedUser,
          selectedUserAccount,
          //selectedClient: clientid,
          selectedClient,
          selectedClientGroup,
          criteria,
          roleId,
          isCriteria,
        });
        if (!!response) {
          setSsName("");
          setSsDesc("");
          setSelectedUser(null);
          setSelectedUserAccount(null);
          setSelectedClient(null);
          setSelectedClientGroup(null);
          setAnchorElSs(null);
          setAnchorSs(null);
          setNameAlertOpen(false);
          setUserAlertOpen(false);
          setClientAlertOpen(false);
          setSaveFor("self");
          dispatch(saveSavedSearchCount(currentSavedSearchCount + 1));
        }
      }
    }
  };

  const initiateSaveSelection = async (event: any) => {
    if (disableState === true) {
      return;
    } else {
      setDisableState(true);
      if (ssName == "") {
        setNameAlertOpen(true);
        return false;
      } else {
        setNameAlertOpen(false);
      }
      if (searchType == "individual" && selectedUser == null) {
        setUserAlertOpen(true);
        return false;
      }
      if (searchType == "individual") {
        setSelectedClient(null);
        setSelectedClientGroup(null);
      }
      let currUserId = currentUserId;
      let criteria = JSON.stringify(selectedCriteria);
      let isCriteria: boolean = false;
      let response = await PostCall(`/api/admin/createSaveSelection/`, {
        ssName,
        ssDesc,
        dataSourceName: selectedDatasourceDbName,
        alertChecked,
        isActive,
        currUserId,
        selectedUser,
        selectedUserAccount,
        selectedClient,
        selectedClientGroup,
        criteria,
        roleId,
        isCriteria,
        selectedRecordIds,
      });
      if (!!response) {
        setSsName("");
        setSsDesc("");
        setSelectedUser(null);
        setSelectedUserAccount(null);
        setSelectedClient(null);
        setSelectedClientGroup(null);
        setAnchorElSs(null);
        setAnchorSs(null);
        setNameAlertOpen(false);
        setUserAlertOpen(false);
        setClientAlertOpen(false);
        setSaveFor("self");
        dispatch(savedSelectionCount(currentSavedSelectionCount + 1));
      }
    }
  };

  useEffect(() => {
    apiCall(`/api/admin/getCgByClient/${selectedClient}`).then(({ data }) => {
      if (data && data.length > 0) {
        let arr = [];
        for (let c of data) {
          arr.push({
            label: c.Name,
            ID: c.ID,
          });
        }
        setClientGroupList(arr);
      }
    });
  }, [selectedClient]);

  const initEmbSearch = async () => {
    let recordDrugIds: string[] = [];
    let recordDiseaseIds: string[] = [];
    let filterState: IfilterState = {};

    apiCall(
      `/api/getRecordsByIds/${currentDataSource}/${embeddedDS}/${selectedRecordIds}`
    ).then((response) => {
      filterState["FreeText"] = null;
      if (currentDataSource == 1) {
        if (Number(embeddedDS) == 2) {
          filterState["HTADrugDiseaseIDs"] = response.data.clinicalParam;
        } else if (Number(embeddedDS) == 3) {
          filterState["DrugDiseaseRegulatorIDs"] = response.data.regParam;
        }
      } else if (currentDataSource == 2) {
        if (Number(embeddedDS) == 1) {
          filterState["DrugDiseaseIDs"] = response.data.htaParam;
        } else if (Number(embeddedDS) == 3) {
          filterState["DrugDiseaseIDs"] = response.data.regParam;
        }
      } else if (currentDataSource == 3) {
        if (Number(embeddedDS) == 1) {
          filterState["DrugDiseaseCountryIDs"] = response.data.htaParam;
        } else if (Number(embeddedDS) == 2) {
          filterState["RegulatoryDrugDiseaseIDs"] = response.data.clinicalParam;
        }
      }
      setCurrentDataSource(Number(embeddedDS));
      setcurrentSearchDataSource(getSearchDatasourceId(Number(embeddedDS)));
      dispatch(setDataSource(Number(embeddedDS)));
      dispatch(setInitiateSearch(false));
      dispatch(setIsSearchGridLoading(false));
      dispatch(setFilterState(filterState));
      // dispatch setInitiateSearch and setIsSearchGridLoading
      dispatch(setIsSearchGridLoading(true));
      dispatch(setInitiateSearch(true));
      initiateSearchResult(filterState, Number(embeddedDS));
      setAnchorElEmb(null);
    });
  };

  const initiateSearchResult = (
    filterState: IfilterState,
    dataSourceId: number
  ) => {
    GetSearchData({
      searchTerm: "",
      datasourceId: dataSourceId,
      filterData: filterState,
      visibleColumns: defaultColumn.showColumnsNames,
      pathway: false,
      currentPage,
      pageSize
    });

    const fieldRenderMethods = defaultColumn.showColumnsNames.map((colName: string)=> {
      const colInfo = defaultColumn.defaultColumnInfo.find(
        (x:any) => x.Name == colName
      );

      return colInfo && colInfo.hasOwnProperty("FieldRenderMethod")
      ? colInfo["FieldRenderMethod"]
      : null;
    }) 
    GetColumFilterForSearch({
      accountID: accountid,
      datasourceId: dataSourceId,
      columns: defaultColumn.showColumnsNames,
      colFieldRenderMethod: fieldRenderMethods
    });
  };
  const [getPage] = useGetPageMutation();
  const GetSearchData = async (payload: any) => {
    const searchResult = await getPage(payload);
    dispatch(saveSearchData(searchResult));
    // dispatch(setColumnFilters(searchResult));
  };

  const [getColumnFilterValues] = useGetColumnFilterValuesMutation();
  const GetColumFilterForSearch = async (payload: any) => {
    const colFilter = await getColumnFilterValues(payload);
    console.log('colFilter: ', colFilter);
    dispatch(setColumnFilters({...colFilter, from: "SearchTable"}));
  }

  const onDropdownItem_Click = (sender) => {
    var newState = {
      dropdownvalue: sender.currentTarget.getAttribute("dropdownvalue"),
    };
    setEmbeddedDS(sender.currentTarget.getAttribute("dropdownvalue"));
  };

  const compareBtnStyle = {
    position: "fixed",
    bottom: "10em",
    right: "3em",
    zIndex: "99",
    border: "none",
    outline: "none",
    backgroundColor: "rgb(0, 170, 231)",
    color: "white",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "10px",
  };

  const navigateRecordsCompareView = () => {
    let propertyName = getIdFieldName(currentDataSource);
    return `/${currentDataSource}/CompareRecordsViews?id1=${compareRecords[0][propertyName]}&id2=${compareRecords[1][propertyName]}`;
    // window.location.href = String(
    //   window.location.protocol +
    //     "//" +
    //     window.location.host +
    //     `/${currentDataSource}/CompareRecordsViews?id1=${compareRecords[0][propertyName]}&id2=${compareRecords[1][propertyName]}`
    // );
  };
  const [dsValue, setDsValue] = React.useState("HTA");
  const handleDsChange = (event: React.SyntheticEvent, newValue: string) => {
    setDsValue(newValue);
  };

  useEffect(() => {
    if (userAccessData && userAccessData != null && accountType === "EClient") {
      // check account type for Eclient
      const accessDate = new Date(userAccessData.DateAccessed);

      let currentDate_14 = new Date();

      currentDate_14.setDate(currentDate_14.getDate() - 14);

      const accessDateValid =
        userAccessData != null && accessDate >= currentDate_14;

      if (!accessDateValid) {
        setInternalUsage(true);
      }
    }
  }, [userAccessData, accountType]);

  useEffect(() => {
    console.log("initiateSearch",initiateSearch,"isSearchgGridLoading",isSearchgGridLoading,"searchError",searchError,searchData?.rowData.length);
  },[initiateSearch,isSearchgGridLoading,searchError])


  const setExportModal = (val: boolean) => {
    dispatch(setExportModalShow(val));
  };
  // useEffect(() => {
  //   console.log("test2", exportModalShow);
  // }, [exportModalShow]);

  const initiatePaginationSearch = (pageCurrent: number, size: number) => {
    
    dispatch(setIsSearchGridLoading(true));
    if(Object.keys(selectedColFilters).length !== 0 || totalFilteredCount!= null){
      const  payload = {
        accountID: accountid,
        datasourceId: dataSourceId,
        totalCount: totalResultCount,
        filters: selectedColFilters,
        visibleCol: defaultColumn.showColumnsNames,
        filterColumnTypes,
        currentPage: pageCurrent,
        pageSize: size,
        isPagination: datasourceWithLargeDataset
      };
      getFilteredSearchResults(payload);
    } else{
      GetSearchData({
        searchTerm: "",
        datasourceId: dataSourceId,
        filterData: isPathwaySearch ? pathwayFilterState : searchFilterState, //Added pathwayFilterState as moving to next page in pathway result grid getting all result.
        visibleColumns: isPathwaySearch ? pathwayColumns[dataSourceId] : defaultColumn.showColumnsNames,
        pathway: isPathwaySearch,
        currentPage: pageCurrent,
        pageSize: size
      });
    }
    //col filter not required to fetch for pagination
  }

  
  return (
    <>
      <div style={{ padding: "0em 5em 0em 5em" }}>
        <div>
          {/* <NewModal/> */}
          {/* <Tabs
          value={dsValue}
          onChange={handleDsChange}
          TabIndicatorProps={{ style: { background: "#00aae7" } }}
          aria-label="secondary tabs example"
        >
          {subscribedDS.some((x) => x == "1") && (
            <Tab
              value="HTA"
              label="HTA"
              onClick={() => changeDataSource(1, 11)}
            />
          )}
          {subscribedDS.some((x) => x == "2") && (
            <Tab
              value="Clinical"
              label="Clinical"
              onClick={() => changeDataSource(2, 9)}
            />
          )}
          {subscribedDS.some((x) => x == "3") && (
            <Tab
              value="Regulatory"
              label="Regulatory"
              onClick={() => changeDataSource(3, 14)}
            />
          )}
          {subscribedDS.some((x) => x == "4") && (
            <Tab
              value="Pricing"
              label="Pricing"
              onClick={() => changeDataSource(4, 13)}
            />
          )}
          {subscribedDS.some((x) => x == "5") && (
            <Tab
              value="Policy"
              label="Policy"
              onClick={() => changeDataSource(5, 12)}
            />
          )}
          {subscribedDS.some((x) => x == "6") && (
            <Tab
              value="Agency"
              label="Agency"
              onClick={() => changeDataSource(6, 8)}
            />
          )}
          {subscribedDS.some((x) => x == "7") && (
            <Tab
              value="Country"
              label="Country"
              onClick={() => changeDataSource(7, 10)}
            />
          )}
          {subscribedDS.some((x) => x == "20") && (
            <Tab
              value="Drug"
              label="Drug"
              onClick={() => changeDataSource(20, 22)}
            />
          )}
          {subscribedDS.some((x) => x == "25") && (
            <Tab
              value="Reimbursement"
              label="Reimbursement"
              onClick={() => changeDataSource(25, 26)}
            />
          )}
          {subscribedDS.some((x) => x == "23") && (
            <Tab
              value="Agreement"
              label="Agreement"
              onClick={() => changeDataSource(23, 24)}
            />
          )}
        </Tabs> */}
          <ul
            className="nav datasource-submenu mb-1"
            id="navTabs"
            style={{ marginLeft: "-21px" }}
          >
            {subscribedDS.some((x) => x == "1") && (
              <li className="ds-menu-style">
                <button
                  value="1"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(1, 11, true);
                    setPathwaySearch(true);
                    dispatch(setSearch(false));
                    dispatch(setPathwayFilter([]));
                    dispatch(setDataSource(1));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  HTA
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "2") && (
              <li className="ds-menu-style">
                <button
                  value="2"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(2, 9, true);
                    setPathwaySearch(true);
                    dispatch(setSearch(false));
                    dispatch(setPathwayFilter([]));
                    dispatch(setDataSource(2));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Clinical
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "3") && (
              <li className="ds-menu-style">
                <button
                  value="3"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(3, 14, true);
                    setPathwaySearch(true);
                    dispatch(setSearch(false));
                    dispatch(setPathwayFilter([]));
                    dispatch(setDataSource(3));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Regulatory
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x === "4") && (
              <li className="ds-menu-style">
                <button
                  value="4"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(4, 13, true);
                    dispatch(setSearch(false));
                    dispatch(setDataSource(4));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Pricing
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "5") && (
              <li className="ds-menu-style">
                <button
                  value="5"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(5, 12, true);
                    dispatch(setDataSource(5));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Policy
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "6") && (
              <li className="ds-menu-style">
                <button
                  value="6"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(6, 8, true);
                    dispatch(setDataSource(6));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Agency
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "7") && (
              <li className="ds-menu-style">
                <button
                  value="7"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(7, 10, true);
                    dispatch(setDataSource(7));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Country
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "20") && (
              <li className="ds-menu-style">
                <button
                  value="20"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(20, 22, true);
                    dispatch(setDataSource(20));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Drug
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "23") && (
              <li className="ds-menu-style">
                <button
                  value="23"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(23, 24, true);
                    dispatch(setDataSource(23));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Agreement
                </button>
              </li>
            )}
            {subscribedDS.some((x) => x == "25") && (
              <li className="ds-menu-style">
                <button
                  value="25"
                  className="btn btn-sm iqvia-color btn-font"
                  onClick={() => {
                    changeDataSource(25, 26, true);
                    dispatch(setDataSource(25));
                    dispatch(setInitiateSearch(false));
                    dispatch(setIsSearchGridLoading(false));
                  }}
                >
                  Reimbursement
                </button>
              </li>
            )}
          </ul>
        </div>
        {/* <Overview /> */}
        <div className="row" style={{ padding: "0" }}>
          <div
            className="col-md-2"
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
              border: "5px solid #eDeDeD",
              borderRight: "none",
              paddingTop: "9px",
              display: showFilter ? "block" : "none",
            }}
          >
            <Filter
              currentDataSource={currentDataSource}
              currentDataSourceName={currentDataSourceName}
              currentSearchDataSource={currentSearchDataSource}
              savedSearchId={savedSearchId}
              setSelectedCriteria={updateSelectedCriteria}
              triggerSearchFunction={triggerSearchFunction}
              resetHook={() => setTriggerSearchFunction(false)}
              currentAccId={Number(accountid)}
              currentRoleId={Number(currentRole)}
              savedSearchInfo={updateSelectedSearch}
              savedSelectionId={savedSelectionId}
              savedSelectionInfo={updateSelectedSavedSelection}
              setPathwaySearch={setPathwaySearch}
              freeText={freeText}
              setFreeText={setFreeText}
              setSearchClicked={setSearchClicked}
              setSavedSearchOrSelectionName={setSavedSearchOrSelectionName}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
              setSsStatusVal={setSsStatusVal}
            ></Filter>
          </div>
          <div
            className={showFilter ? "col-md-10" : "col-md-12"}
            style={{ border: "5px solid #eDeDeD", paddingTop: "9px" }}
          >
            <button
              className="btn btn-sm iqvia-color"
              style={{
                marginLeft: "-41px",
                paddingLeft: "0",
                paddingRight: "0",
                position: "absolute",
                marginTop: "-0.7em",
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              {!showFilter && <KeyboardArrowRightIcon></KeyboardArrowRightIcon>}
              {showFilter && <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>}
            </button>
               {
              recordIdsearch && !recordIdloading && !initiateSearch && !pathwayShow && !newsArticleShow && !topTenRecords  &&
              //how to add the title for below table grid:
                recordIdData.rowData.length == 0 && (
                <div style={{ textAlign: "center", marginTop: 250 }}>
                  <h5>No records found</h5>

                </div>
              ) 
              }
                {
                  recordIdsearch && !recordIdloading && !initiateSearch && !pathwayShow && !newsArticleShow && !topTenRecords  &&
                  //how to add the title for below table grid:
                    recordIdData.rowData.length > 0 &&
              <div>
                <h5><b>Search Results</b></h5>
              <TableGrid
                tableName={"RecordSearchGrid"}  
                isLoading={recordIdloading}
                pageSize={100}
                columnDefs={recordIdData.colDef}
                rowData={recordIdData.rowData}
                />
                </div>
            }
            {!search && !initiateSearch && !datasourceChange && !recordIdsearch &&(
              <div>
                <Dropdown
                  color="link"
                  className="float-right"
                  toggle={() => {
                    setSettingsOpen(!settingsOpen);
                  }}
                  isOpen={settingsOpen}
                  style={{ border: "none" }}
                >
                  <DropdownToggle
                    tag="button"
                    className="dropdown-toggle btn-link iqvia-color"
                    style={{ border: "none" }}
                  >
                    <img style={{ width: "20px" }} src={settings} />
                  </DropdownToggle>
                  <DropdownMenu style={{ left: "-110px" }}>
                    {pathwayShow && [1, 2, 3].includes(dataSourceId) && (
                      <DropdownItem
                        key={0}
                        style={{
                          fontSize: "12px",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                        className="iqvia-color"
                        toggle={false}
                        onClick={(e) => setPathwayShow(false)}
                      >
                        Pathway{" "}
                        <FontAwesomeIcon
                          icon={faEye as IconProp}
                          style={{ paddingLeft: "2px", color: "#63a5e3" }}
                        />
                      </DropdownItem>
                    )}
                    {!pathwayShow && [1, 2, 3].includes(dataSourceId) && (
                      <DropdownItem
                        key={0}
                        style={{
                          fontSize: "12px",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                        toggle={false}
                        className="iqvia-color"
                        onClick={() => setPathwayShow(true)}
                      >
                        Pathway
                        <FontAwesomeIcon
                          icon={faEyeSlash as IconProp}
                          style={{ paddingLeft: "2px", color: "#63a5e3" }}
                        />
                      </DropdownItem>
                    )}

                    {!initiateSearch && (
                      <>
                        {newsArticleShow && (
                          <DropdownItem
                            key={1}
                            style={{
                              fontSize: "12px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            toggle={false}
                            className="iqvia-color"
                            onClick={() => setNewsArticleShow(false)}
                          >
                            News Article
                            <FontAwesomeIcon
                              icon={faEye as IconProp}
                              style={{ paddingLeft: "2px", color: "#63a5e3" }}
                            />
                          </DropdownItem>
                        )}
                        {!newsArticleShow && (
                          <DropdownItem
                            key={1}
                            style={{
                              fontSize: "12px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            toggle={false}
                            className="iqvia-color"
                            onClick={() => setNewsArticleShow(true)}
                          >
                            News Article
                            <FontAwesomeIcon
                              icon={faEyeSlash as IconProp}
                              style={{ paddingLeft: "2px", color: "#63a5e3" }}
                            />
                          </DropdownItem>
                        )}
                      </>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {currentDataSource <= 3 &&
              pathwayRule &&
              pathwaySearch &&
              pathwayShow && (
                <Pathway
                  currentDataSource={currentDataSource}
                  currentDataSourceName={currentDataSourceName}
                  setSelectedCriteria={updateSelectedCriteria}
                  setPathwaySearch={setPathwaySearch}
                  pathwaySearch={pathwaySearch}
                  pathwayFixed={pathwayFixed}
                  defaultColumn={defaultColumn.showColumnsNames}
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                ></Pathway>
              )}
            {initiateSearch && isSearchgGridLoading && (
              <div style={{ marginTop: 250, textAlign: "center" }}>
                <Spinner animation="border" style={{ color: "#00aae7" }} />
                <h5>Loading, please wait...</h5>
              </div>
            )}
            {initiateSearch && !isSearchgGridLoading && searchError && (
              <div style={{ textAlign: "center", marginTop: 250 }}>
                <h5>Something went wrong. Please try again...</h5>
              </div>
            )}
            {initiateSearch &&
              !isSearchgGridLoading &&
              !searchError &&
              searchData.rowData.length == 0 && (
                <div className="error-container">
                  <div className="well-no-result" style={{ textAlign: "center", marginTop: "9em" }}>
                    <h5>
                      <SearchOffIcon fontSize="large" sx={{ color: "#00aae7"}} />
                      No matching results found</h5>
                  </div>
                </div>
              )}
            {initiateSearch &&
              !isSearchgGridLoading &&
              !searchError &&
              searchData.rowData.length > 0 && (
                <>
                  <Collapse in={successAlertOpen}>
                    <Alert
                      onClose={() => {
                        setSuccessAlertOpen(false);
                      }}
                    >
                      User preference saved successfully!
                    </Alert>
                  </Collapse>
                  <h4 className="text-left custom-h5">
                    {/* Results &nbsp; */}
                    {path.includes("savedSelection") &&
                      savedSelectionData &&
                      !searchClicked &&
                      (<>{savedSearchOrSelectionName}</>)}
                    {path.includes("savedSearch") &&
                      savedSearchAlertData && !searchClicked && 
                      (<>{savedSearchOrSelectionName}</>)}
                    {/* {path.includes("savedSearch") &&
                      SavedSearchActiveData && !searchClicked && 
                      SavedSearchActiveData
                        .filter((obj) => obj.ID == savedSearchId)
                        .map((el) => <>{el.Name}</>)}
                    {path.includes("savedSearch") &&
                      SavedSearchDeActiveData && !searchClicked && 
                      SavedSearchDeActiveData
                        .filter((obj) => obj.ID == savedSearchId)
                        .map((el) => <>{el.Name}</>)}     */}
                    {!!searchClicked &&
                      ((gridRowCount === totalGridRowCount && (totalFilteredCount == null || totalFilteredCount === totalResultCount)) ? (
                        <>
                          Results &nbsp;
                          <strong
                            className="iqvia-color"
                            title={`${
                              isExeedingSearchThreshold
                                ? `There are total ${totalResultCount} records for this search, showing top ${totalGridRowCount} for better performance`
                                : `Total count ${datasourceWithLargeDataset ? totalResultCount : gridRowCount}`
                            }`}
                          >
                            {`${datasourceWithLargeDataset ? totalResultCount : gridRowCount} ${
                              isExeedingSearchThreshold ? " +" : ""
                            }`}
                          </strong>
                        </>
                      ) : (
                        <>
                          Results &nbsp;
                          <strong
                            className="iqvia-color"
                            title={`${
                              isExeedingSearchThreshold
                                ? `There are total ${totalResultCount} records for this search, showing top ${totalGridRowCount} for better performance`
                                : `Total count ${datasourceWithLargeDataset ? totalResultCount :totalGridRowCount}`
                            }`}
                          >
                            {`${totalFilteredCount !== null && gridRowCount === totalGridRowCount  ? totalFilteredCount : gridRowCount} (${totalFilteredCount !== null && gridRowCount === totalGridRowCount  ? totalFilteredCount : gridRowCount}  out of ${datasourceWithLargeDataset ? totalResultCount :totalGridRowCount} ${
                              isExeedingSearchThreshold ? " +" : ""
                            })`}
                          </strong>
                        </>
                      ))}
                  </h4>
                  <div className="text-right">
                    {!isEclient && (
                      <>
                        <button
                          className="btn btn-font iqvia-color"
                          disabled={isAssignDisabled}
                          onClick={showAssignModal}
                        >
                          <PersonAddIcon />
                          Assign users
                        </button>
                        
                      </>
                    )}
                    {/* <button
                      className="btn btn-font iqvia-color"
                      disabled={isEmbeddedDisabled}
                      onClick={handleEmbClick}
                    >
                      <SyncAltIcon /> Embedded search
                    </button> */}
                    <button
                      className="btn btn-font iqvia-color"
                      onClick={handleEditColumnClick}
                    >
                      <VerticalSplitIcon></VerticalSplitIcon> Edit columns
                    </button>

                    <button
                      className="btn btn-font iqvia-color"
                      onClick={() => {
                        dispatch(setExportModalShow(true));
                      }}
                    >
                      <FileDownloadIcon></FileDownloadIcon> Export
                      <ExportModal
                        datasource_id={currentDataSource}
                        datasource_name={currentSearchDataSourceName}
                        currOption={currOption}
                        setCurrOption={setCurrOption}
                      />
                    </button>

                    {/* <UncontrolledDropdown group>
                      
                      <DropdownToggle
                        caret
                        color=""
                        className="dropdown-toggle btn btn-link iqvia-color btn-font"
                      />
                      <DropdownMenu>
                        <DropdownItem header>Export options</DropdownItem>
                        <DropdownItem>All fields</DropdownItem>
                        <DropdownItem>
                          All fields + Summary of related datasources
                        </DropdownItem>
                        <DropdownItem>
                          All fields including all related datasources
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                    {locationPath.pathname &&
                    !locationPath.pathname.includes("savedSelection") ? (
                      <>
                        <button
                          className="btn btn-font iqvia-color"
                          onClick={handleSsClick}
                        >
                          <BiSave style={{ fontSize: "22px" }}></BiSave>Save
                          search
                        </button>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <button
                      className="btn btn-font iqvia-color"
                      onClick={handleSsClick}
                    >
                      <SaveIcon></SaveIcon>Save search
                    </button> */}
                    <button
                      className="btn btn-font iqvia-color"
                      disabled={!enableSelectionButton}
                      onClick={handleSsClickSavedselection}
                    >
                      <BiSave style={{ fontSize: "22px" }}></BiSave>Save
                      selection
                    </button>
                  </div>
                  {currentDataSource === 4 &&
                    searchData.rowData.length <= 2 && (
                      <div
                        className="iqvia-color"
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          marginTop: 10,
                          textDecoration: "underline",
                        }}
                        onClick={() => setPricingChartView(!pricingChartView)}
                      >
                        Switch between graph & grid
                      </div>
                    )}
                  {currentDataSource === 4 &&
                  searchData.rowData.length <= 2 &&
                  pricingChartView ? (
                    <div style={{ marginTop: 15 }}>
                      {console.log(searchData.rowData[0])}
                      {searchData.rowData[0] && (
                        <div>
                          <PricingView
                            values={searchData.rowData[0]}
                            datasourceName={"Pricing"}
                            pricingCompareRecord={pricingCompareRecord}
                            title={searchData.rowData[0].Name}
                          />
                        </div>
                      )}

                      {searchData.rowData[1] && (
                        <div
                          style={{
                            borderTop: "1px solid grey",
                            marginTop: 10,
                            paddingTop: 20,
                          }}
                        >
                          <PricingView
                            values={searchData.rowData[1]}
                            datasourceName={"Pricing"}
                            pricingCompareRecord={pricingCompareRecord}
                            title={searchData.rowData[1].Name}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <TableGrid
                      tableName={"SearchGrid"}
                      ref={resultGridRef}
                      rowData={searchData.rowData}
                      columnDefs={searchData.colDef}
                      isLoading={isSearchgGridLoading}
                      pageSize={pageSize}
                      checkSelection={checkSelection}
                      getSelectedRows={getSelectedSearchRows}
                      setGridRowsCount={setGridRowCount}
                      currentPage={currentPage}
                      setPageSize={setPageSize}
                      setCurrentPage={setCurrentPage}
                      initiatePaginationSearch={initiatePaginationSearch}
                    />
                  )}
                </>
              )}
            {showCompareButton &&
              (currentDataSource === 1 || currentDataSource === 3) && (
                <Link
                  to={() => navigateRecordsCompareView()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button style={compareBtnStyle}>Compare</button>
                </Link>
              )}
            {formSavingProgress && (
              <div className="search-grid-result loadingdiv" >
                <div className="loadingResult-popup">
                  <div className="loadingResult-content">
                    <div>
                      <br />
                      <div>
                        <Spinner animation="border" style={{ color: "#00aae7" }} />
                      </div>
                      <br />
                      <h4 className="saving-wait"> Please wait...</h4>
                    </div>
                  </div>
                </div>
                <div className="loadingResult-Background"></div>
              </div>
            )}

            {!initiateSearch &&
              topTenRecords == true &&
              currentDataSource > 3 &&
              currentDataSource !== 4 &&
              currentDataSource !== 15 &&
              currentDataSource !== 276 && (
                <TableData
                  sourceId={currentDataSource}
                  userId={Number(currUserId)}
                  role={Number(currRoleId)}
                  sourceName={currentDataSourceName}
                ></TableData>
              )}
            {anchorEl && (
              <DraggablePopover
                title="Change columns"
                anchorEl={anchorEl}
                popupBody={
                  <TableGrid
                    tableName="EditColumnTable"
                    defaultColumnList={defaultColumn.showColumnsNames}
                    rowData={editColumnData.rowData}
                    columnDefs={editColumnData.colDef}
                    isLoading={isColumnGridLoading}
                    pageSize={100}
                    getSelectedRows={getSelectedColumns}
                    sectionsInfo={columnSectionInfo}
                    // dataSourceID={currentDataSource}
                  />
                }
                buttonsArray={["Accept"]}
                btnCallbacks={[() => saveEditColumnChanges("EditColumnTable")]}
                onPopupClose={handleClose}
              ></DraggablePopover>
            )}
            {/* <TMEditor initialValue={""} getEditorValue={getEditorValue} /> */}
            {!initiateSearch && currentDataSource != 276 && newsArticleShow && (
              <ClientNews></ClientNews>
            )}
            {currentDataSource == 276 && newsArticleShow && <MoreClientNews />}
            <AssignModal
              selectedRowsId={selectedRowsId}
              open={showModal}
              handleClose={() => setShowModal(false)}
              dataSource_id={currentDataSource}
            />
            {anchorElEmb && (
              <DraggablePopover
                title="Search other datasources"
                anchorEl={anchorElEmb}
                popupBody={
                  <Dropdown
                    color="link"
                    className="float-right"
                    toggle={() => {
                      setEmbDdOpen(!embDdOpen);
                    }}
                    isOpen={embDdOpen}
                  >
                    <DropdownToggle
                      tag="button"
                      className="dropdown-toggle btn btn-link iqvia-color"
                    >
                      Select datasource
                    </DropdownToggle>
                    <DropdownMenu title="Select datasource" right>
                      <DropdownItem
                        disabled={isHTANameDisabled}
                        onClick={onDropdownItem_Click}
                        dropdownvalue="1"
                      >
                        HTA
                      </DropdownItem>
                      <DropdownItem
                        disabled={isClinicalNameDisabled}
                        onClick={onDropdownItem_Click}
                        dropdownvalue="2"
                      >
                        Clinical
                      </DropdownItem>
                      <DropdownItem
                        disabled={isRegNameDisabled}
                        onClick={onDropdownItem_Click}
                        dropdownvalue="3"
                      >
                        Regulatory
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                }
                buttonsArray={["Confirm"]}
                btnCallbacks={[initiateEmbeddedSearch]}
                onPopupClose={handleCloseEmb}
              ></DraggablePopover>
            )}
            {anchorElSs && (
              <DraggablePopover
                title="Save search"
                anchorEl={anchorElSs}
                popupBody={
                  <div className="bodyContainer">
                    <div className={"col-sm-12 row form-group"}>
                      <label
                        className={"col-sm-3 text-right control-label"}
                      ></label>
                      <div className="col-sm-9 control-label">
                        <FormControl>
                          <RadioGroup row>
                            <FormControlLabel
                              onClick={() => setSsStatusVal("new")}
                              control={
                                <Radio checked={ssStatusVal === "new"} />
                              }
                              label="New"
                            />
                            <FormControlLabel
                              onClick={() => setSsStatusVal("existing")}
                              control={
                                <Radio checked={ssStatusVal === "existing"} />
                              }
                              disabled
                              label="Existing"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className={"col-sm-12 row form-group"}>
                      <label
                        className={"col-sm-3 text-right control-label required"}
                      >
                        Name
                      </label>
                      <div className="col-sm-9 text-right control-label">
                        <TextField
                          id="outlined-multiline-static"
                          fullWidth
                          value={ssName}
                          required
                          disabled={ssStatusVal == "existing"}
                          size="small"
                          onChange={(e) => {
                            setSsName(e.target.value);
                          }}
                        />
                        {nameAlertOpen && (
                          <>
                            <br />
                            <Alert severity="warning">Please enter name</Alert>
                          </>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className={"col-sm-12 row form-group"}>
                      <label className={"col-sm-3 text-right control-label"}>
                        Description
                      </label>
                      <div className="col-sm-9 text-right control-label">
                        <TextField
                          id="outlined-multiline-static"
                          fullWidth
                          value={ssDesc}
                          size="small"
                          onChange={(e) => setSsDesc(e.target.value)}
                        />
                      </div>
                    </div>
                    <br />
                    {Number(currRoleId) == 1 && (
                      <>
                        <div className={"col-sm-12 row form-group"}>
                          <label
                            className={"col-sm-3 text-right control-label"}
                          >
                            Search type
                          </label>
                          <div className="col-sm-9 control-label">
                            <FormControl>
                              <RadioGroup row>
                                <FormControlLabel
                                  onClick={() => {
                                    setSearchType("individual");
                                    setSelectedClient(null);
                                    setSelectedClientGroup(null);
                                  }}
                                  control={
                                    <Radio
                                      checked={searchType === "individual"}
                                    />
                                  }
                                  label="Individual search"
                                />
                                <FormControlLabel
                                  onClick={() => {
                                    setSearchType("group");
                                    setSelectedUser(null);
                                  }}
                                  control={
                                    <Radio checked={searchType === "group"} />
                                  }
                                  label="Group search"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                        <br />
                        {searchType === "individual" && (
                          <div className={"col-sm-12 row form-group"}>
                            <label
                              className={"col-sm-3 text-right control-label"}
                            >
                              User
                            </label>
                            <div className="col-sm-9 text-right control-label">
                              <div className="autocomplete">
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  onChange={(
                                    event: any,
                                    newValue: string | null
                                  ) => {
                                    setSelectedUser(newValue.UserID);
                                    setSelectedUserAccount(newValue.ID);
                                    setUserAlertOpen(false);
                                  }}
                                  open={userOpen}
                                  onOpen={() => {
                                    setUserOpen(true);
                                  }}
                                  onClose={() => {
                                    setUserOpen(false);
                                  }}
                                  options={userList}
                                  getOptionLabel={(userList) => userList.label}
                                  sx={{ width: 300 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="select"
                                      variant="outlined"
                                    />
                                  )}
                                  renderOption={renderOptions}
                                />
                              </div>
                              {userAlertOpen && (
                                <>
                                  <br />
                                  <Alert severity="warning">
                                    Please select user from the list
                                  </Alert>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {searchType === "group" && (
                          <>
                            <div className={"col-sm-12 row form-group"}>
                              <label
                                className={"col-sm-3 text-right control-label"}
                              >
                                Client
                              </label>
                              <div className="col-sm-9 text-right control-label">
                                <div className="autocomplete">
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={(
                                      event: any,
                                      newValue: string | null
                                    ) => {
                                      setSelectedClient(newValue.ID);
                                      setClientAlertOpen(false);
                                    }}
                                    open={clientOpen}
                                    onOpen={() => {
                                      setClientOpen(true);
                                    }}
                                    onClose={() => {
                                      setClientOpen(false);
                                    }}
                                    options={clientList}
                                    getOptionLabel={(clientList) =>
                                      clientList.label
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="select"
                                        variant="outlined"
                                      />
                                    )}
                                    renderOption={renderOptions}
                                  />
                                </div>
                                {clientAlertOpen && (
                                  <>
                                    <br />
                                    <Alert severity="warning">
                                      Please select client from the list
                                    </Alert>
                                  </>
                                )}
                              </div>
                            </div>
                            <br />
                            <div className={"col-sm-12 row form-group"}>
                              <label
                                className={"col-sm-3 text-right control-label"}
                              >
                                Client group
                              </label>
                              <div className="col-sm-9 text-right control-label">
                                <div className="autocomplete">
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={(
                                      event: any,
                                      newValue: string | null
                                    ) => {
                                      setSelectedClientGroup(newValue.ID);
                                    }}
                                    open={clientGroupOpen}
                                    onOpen={() => {
                                      setClientGroupOpen(true);
                                    }}
                                    onClose={() => {
                                      setClientGroupOpen(false);
                                    }}
                                    options={clientGroupList}
                                    getOptionLabel={(clientGroupList) =>
                                      clientGroupList.label
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="select"
                                        variant="outlined"
                                      />
                                    )}
                                    renderOption={renderOptions}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <br />
                        <div
                          key={"test1"}
                          className={"col-sm-12 row form-group"}
                        >
                          <label
                            className={"col-sm-3 text-right control-label"}
                          >
                            Alerts
                          </label>
                          <div className="col-sm-9 control-label">
                            <Checkbox
                              checked={alertChecked}
                              onClick={(e) => handleChangeAlert(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                        </div>
                        <br />
                        <div
                          key={"test2"}
                          className={"col-sm-12 row form-group"}
                        >
                          <label
                            className={"col-sm-3 text-right control-label"}
                          >
                            Is active
                          </label>
                          <div className="col-sm-9 control-label">
                            <Checkbox
                              checked={isActive}
                              onClick={(e) => handleChangeIsActive(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {(Number(currRoleId) == 2 ||
                      Number(currRoleId) == 3 ||
                      Number(currRoleId) == 6 ||
                      Number(currRoleId) == 7 ||
                      Number(currRoleId) == 11) && (
                      <>
                        <div className={"col-sm-12 row form-group"}>
                          <label
                            className={"col-sm-3 text-right control-label"}
                          >
                            Save for
                          </label>
                          <div className="col-sm-9 control-label">
                            <FormControl>
                              <RadioGroup row>
                                <FormControlLabel
                                  onClick={() => {
                                    setSaveFor("self");
                                    setSelectedUser(currUserId);
                                    setSelectedUserAccount(accountid);
                                    setSelectedClient(null);
                                    setSelectedClientGroup(null);
                                  }}
                                  control={
                                    <Radio checked={saveFor === "self"} />
                                  }
                                  label="Only yourself"
                                />
                                <FormControlLabel
                                  onClick={() => {
                                    setSaveFor("client");
                                    setSelectedUser(currUserId);
                                    setSelectedUserAccount(null);
                                    setSelectedClient(clientid);
                                    setSelectedClientGroup(null);
                                  }}
                                  control={
                                    <Radio checked={saveFor === "client"} />
                                  }
                                  label={currClientName}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                        <div className={"col-sm-12 row form-group"}>
                          <label
                            className={"col-sm-3 text-right control-label"}
                          ></label>
                          <div
                            className="col-sm-1 control-label"
                            style={{ paddingLeft: "0.5em" }}
                          >
                            <Checkbox
                              checked={alertChecked}
                              onClick={(e) => handleChangeAlert(e)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </div>
                          <label className="" style={{ paddingTop: "0.8em" }}>
                            Check for alerts subscription, for changes in search
                            results
                          </label>
                        </div>
                      </>
                    )}
                    <div className={"col-sm-12 row form-group"}>
                      <label className={"col-sm-3 text-right control-label"}>
                        Filters
                      </label>
                      <div>
                        {Object.keys(savedFilterState).map((key) => (
                          <div key={key}>
                            <div>
                              <ul>
                                {(key && key == "FreeText") ||
                                key.includes("IDs") ? (
                                  <li>{(key = " ")}</li>
                                ) : (
                                  <li
                                    style={{
                                      listStyleType: "disc",
                                      marginLeft: "3.5rem",
                                    }}
                                  >
                                    {key.includes("Id")
                                      ? key.replace("Id", " ")
                                      : key.replace("Is", " ")}
                                  </li>
                                )}
                                <ul>
                                  <div className="col-md-10" id="filter-save">
                                    <ul>
                                      {/* <li
                                        style={{
                                          listStyleType: "circle",
                                          marginLeft: "4.3rem",
                                        }}
                                      >
                                        {savedFilterState[key]}
                                      </li> */}
                                      <li style={{ marginLeft: "3.3rem" }}>
                                        {savedFilterState[key]?.toString()}
                                      </li>
                                    </ul>
                                  </div>
                                </ul>
                              </ul>
                            </div>
                          </div>
                        ))}
                        <div>
                          {Object.keys(selectedLookupGlobe).map((key) => {
                            if (selectedLookupGlobe[key].length > 0)
                              return (
                                <div key={key}>
                                  <div>
                                    <ul>
                                      <li
                                        style={{
                                          listStyleType: "disc",
                                          marginLeft: "3.5rem",
                                        }}
                                      >
                                        {key.replace("IDs", " ")}
                                      </li>
                                    </ul>
                                  </div>
                                  <ul>
                                    {selectedLookupGlobe[key].map(
                                      (item: any) => (
                                        <div
                                          className="col-md-10"
                                          id="filter-save"
                                        >
                                          <ul>
                                            {/* <li
                                        style={{
                                          listStyleType: "circle",
                                          marginLeft: "4.3rem",
                                        }}
                                      >
                                        {item.Value}
                                      </li>  */}
                                            <li
                                              style={{ marginLeft: "4.3rem" }}
                                            >
                                              {item.Value}
                                            </li>
                                          </ul>
                                        </div>
                                      )
                                    )}
                                  </ul>
                                </div>
                              );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                buttonsArray={["Save"]}
                btnCallbacks={[initiateSaveSearch]}
                onPopupClose={handleCloseSs}
              ></DraggablePopover>
            )}
            {anchorSs && (
              <DraggablePopover
                title="Save selection"
                anchorEl={anchorSs}
                popupBody={
                  <div
                    className="bodyContainer"
                    style={{ height: "550px", overflow: "hidden" }}
                  >
                    <div className={"col-sm-12 row form-group"}>
                      <label
                        className={"col-sm-3 text-right control-label"}
                      ></label>
                      <div className="col-sm-9 control-label">
                        <FormControl>
                          <RadioGroup row>
                            <FormControlLabel
                              onClick={() => setSsStatusVal("new")}
                              control={
                                <Radio checked={ssStatusVal === "new"} />
                              }
                              label="New"
                            />
                            <FormControlLabel
                              onClick={() => setSsStatusVal("existing")}
                              control={
                                <Radio checked={ssStatusVal === "existing"} />
                              }
                              disabled
                              label="Existing"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className={"col-sm-12 row form-group"}>
                      <label
                        className={"col-sm-3 text-right control-label required"}
                      >
                        Name
                      </label>
                      <div className="col-sm-9 text-right control-label">
                        <TextField
                          id="outlined-multiline-static"
                          fullWidth
                          value={ssName}
                          required
                          size="small"
                          onChange={(e) => {
                            setSsName(e.target.value);
                          }}
                        />
                        {nameAlertOpen && (
                          <>
                            <br />
                            <Alert severity="warning">Please enter name</Alert>
                          </>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className={"col-sm-12 row form-group"}>
                      <label className={"col-sm-3 text-right control-label"}>
                        Description
                      </label>
                      <div className="col-sm-9 text-right control-label">
                        <TextField
                          id="outlined-multiline-static"
                          fullWidth
                          value={ssDesc}
                          size="small"
                          onChange={(e) => setSsDesc(e.target.value)}
                        />
                      </div>
                    </div>
                    <br />
                    {/* {searchType === "individual" && ( */}
                    <div className={"col-sm-12 row form-group"}>
                      <label className={"col-sm-3 text-right control-label"}>
                        User
                      </label>
                      <div className="col-sm-9 text-right control-label">
                        <div className="autocomplete">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            onChange={(event: any, newValue: string | null) => {
                              setSelectedUser(newValue.UserID);
                              setSelectedUserAccount(newValue.ID);
                              setUserAlertOpen(false);
                            }}
                            open={userOpen}
                            onOpen={() => {
                              setUserOpen(true);
                            }}
                            onClose={() => {
                              setUserOpen(false);
                            }}
                            options={userList}
                            getOptionLabel={(userList) => userList.label}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="select"
                                variant="outlined"
                              />
                            )}
                            renderOption={renderOptions}
                          />
                        </div>
                        {userAlertOpen && (
                          <>
                            <br />
                            <Alert severity="warning">
                              Please select user from the list
                            </Alert>
                          </>
                        )}
                      </div>
                    </div>
                    {/* )} */}

                    <br />
                  </div>
                }
                buttonsArray={["Save"]}
                btnCallbacks={[initiateSaveSelection]}
                onPopupClose={closeSs}
              ></DraggablePopover>
            )}
          </div>
        </div>
        {/* <div className="disclaimer-footer">
          <span style={{ fontSize: "11px" }}>
            Copyright © 2022-20203 IQVIA. All Rights Reserved. Application is
            best viewed in Microsoft Edge / Google Chrome.
          </span>
        </div> */}
      </div>
      <div className="disclaimer-footer">
        <span style={{ fontSize: "12px !important" }}>
          Copyright © {new Date().getFullYear()}-{new Date().getFullYear() + 1} IQVIA. All Rights Reserved. Application is best
          viewed in Microsoft Edge / Google Chrome.
        </span>
      </div>
      <InternalUserTracker
        open={internalUsage}
        handleClose={() => setInternalUsage(false)}
      />
    </>
  );
}
