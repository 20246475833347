import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import NameLink from "../components/Table/NameLink";
import moment from "moment";
import { getIdFieldName } from "../utils/index";
import {AdminSavedSearchDelete} from "./AdminSavedSearchIcons/AdminSavedSearchDelete";
import AdminSavedSearchUser from "./AdminSavedSearchIcons/AdminSavedSearchEmail";
import AdminSavedSearchDeactiveData from "./AdminSavedSearchIcons/AdminSavedSearchDeactiveData";
import { convert } from "html-to-text";
import TitleLink from "../components/Table/TitleLink";



export interface SavedSearchData {
  rowData: any[];
  colDef: ColDef[];
}
export interface SavedSearchState {
  searchData: SavedSearchData;
  loading: boolean;
  searchSavedSearchData: any[];
  searchSavedSelectionData: any[];
}
const initialState: SavedSearchState = {
  searchData: { rowData: [], colDef: [] },
  loading: true,
  searchSavedSearchData: [],
  searchSavedSelectionData: [],
};

// const dateFormatter = (params) => {
//   console.log("params.ModifiedDate :", params.ModifiedDate);
//   return moment(params.CreatedDate).format("MM/DD/YYYY");
// };
var removeHtmlTags = (p:any) => convert(p.value);
const columns = (sourceId:number) => {
  switch (sourceId) {
    case 5:
      return [
        { field: "Name", headerName: "Name" },
        {
          field: "ModifiedDate",
          headerName: "Modified Date",
          cellRenderer: (data:any) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
          //valueFormatter: dateFormatter,
        },
        { field: "Country", headerName: "Country" },
        { field: "Summary", headerName: "Summary",valueFormatter: removeHtmlTags },

      ];
    case 6:
      return [
        { field: "Name", headerName: "Name" },
        {
          field: "ModifiedDate",
          headerName: "Modified Date",
          cellRenderer: (data:any) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
          //valueFormatter: dateFormatter,
        },
        { field: "CountryName", headerName: "Country" },
        { field: "Region", headerName: "Region" },
      ];
    case 7:
      return [
        { field: "Name", headerName: "Name" },
        {
          field: "ModifiedDate",
          headerName: "Modified Date",
          cellRenderer: (data:any) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
          //valueFormatter: dateFormatter,
        },
        { field: "GroupNames", headerName: "GroupName" },
        { field: "Region_LIValue", headerName: "Region" },
      ];
    case 20:
      return [
        { field: "Name", headerName: "Name" },
        {
          field: "ModifiedDate",
          headerName: "Modified Date",
          //valueFormatter: dateFormatter,
          cellRenderer: (data:any) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
        },
        { field: "GroupNames", headerName: "GroupName" },
        { field: "Region", headerName: "Region" },
      ];
    case 23:
      return [
        { field: "Name", headerName: "Name" },
        {
          field: "ModifiedDate",
          headerName: "Modified Date",
          //valueFormatter: dateFormatter,
          cellRenderer: (data:any) => {
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
        },
        { field: "DrugName", headerName: "Product" },
        { field: "CountryName", headerName: "Country" },
        { field: "AgreementCategoryName", headerName: "Agreement category" },
        { field: "AgreementResultName", headerName: "Agreement type" },
        { field: "AgreementTypeName", headerName: "Agreement result" },
      ];
    case 25:
      return [
        {
          field: "Name",
          headerName: "Title",
          wrapText: true,
          autoHeight: true,
          cellRenderer: TitleLink,
          cellRendererParams: {
            link: `/view/${sourceId}`,
            recordIdField: "ID",
          },
        },
        {
          field: "Drug_Name",
          headerName: "Drug",
        },
        { field: "CountryName", headerName: "Country" },
        {
          field: "ReimbursementStatusLIValue",
          headerName: "Reimbursement Status",
        },
        {
          field: "ReimbursementStatusDate",
          headerName: "Reimbursement Start Date",
          cellRenderer: (data:any) => {
            if(data.value == null){
              return '';
            } 
            return moment(data.value).format('DD/MM/YYYY')
          }
          // valueFormatter: (param: ValueFormatterParams) => {
          //   var Date = param.value == null ? null : param.value.slice(0, 10);
          //   return moment(Date).format("DD/MM/YYYY");
          // },
        },
      ];
    case 27:
      return [
        { field: "Name", headerName: "Name" },
        { field: "CountryName", headerName: "Country" },
        { field: "DrugNames", headerName: "Product" },
        { field: "DiseaseNames", headerName: "Disease" },
        { field: "TreatmentLineNames", headerName: "Treatment line" },
        {
          field: "TCCostsPerMonthInLocalCurrency1",
          headerName: "Total cost per month in local currency",
        },
      ];
  }
};
const savedSearchColumns = () => {
  return [
    { field: "Name", headerName: "Name",minWidth: 350,lockPosition: "left"},
    { field: "DisplayName", headerName: "Data source" },
    { field: "newusername", headerName: "User name" },
    {
      field: "CreatedDate",
      headerName: "Created date",
      cellRenderer: (data:any) => {
        return moment(data.value).format('DD/MM/YYYY')
      }
      // valueFormatter: (param: ValueFormatterParams) => {
      //     var Date = param.value == null ? null : param.value.slice(0, 10);
      //     return moment(Date).format("DD/MM/YYYY");
      // },
      //valueFormatter: dateFormatter,
    },
    { field: "", headerName: "Subscription status"},
    { field: "", headerName: ""},
  ];
}
const savedSearchDeactiveColumns = () => {
  return [
    { field: "Name", headerName: "Name",minWidth: 350,lockPosition: "left"},
    { field: "DisplayName", headerName: "Data source" },
    { field: "newusername", headerName: "User name" },
    {
      field: "CreatedDate",
      headerName: "Created date",
      format: 'dd/mm/yyyy',
      cellRenderer: (data:any) => {
        return moment(data.value).format('DD/MM/YYYY')
      }
      // valueFormatter: (param: ValueFormatterParams) => {
      //   var Date = param.value == null ? null : param.value.slice(0, 10);
      //   return moment(Date).format("DD/MM/YYYY");
      // },  
      //valueFormatter: dateFormatter,
    },
    { field: "", headerName: "Subscription status"},
  ];
}

export const SavedSearchSlice = createSlice({
  name: "SavedSearch",
  initialState,
  reducers: {
    storeSavedSearchData: (state, action: PayloadAction<any>) => {
      state.searchData.rowData = action.payload.data;

      const dsId = action.payload.datasourceId;
      const accountType = action.payload.accountType;

      state.loading = false;
      const payloadData = action.payload.data;

      let rowData = payloadData as object[];

      let colDef : any = columns(dsId);
      if (accountType === "EClient") {
        colDef = colDef.filter((s:any) => s.field !== "ModifiedDate");
      }
      const idFieldName = getIdFieldName(Number(dsId));

      colDef.forEach((col:any) => {
        if (col.field == "Name") {
          col["cellRenderer"] = NameLink;
          col["cellRendererParams"] = {
            link: `/view/${dsId}`,
            recordIdField: idFieldName, 
          }
        }
      });
      state.searchData = { rowData, colDef };
    },
    storeSavedSearchAlertData: (state, action: PayloadAction<any>) => {
      state.searchData.rowData = action.payload.data;
      state.loading = false;
      const payloadData = action.payload.data;
      let rowData = payloadData as object[];
      let colDef : any = savedSearchColumns();
      let searchClicked = false;
      //const idFieldName = getIdFieldName(Number(dsId));
      colDef.forEach((col:any) => {
        if (col.field == "Name") {
          col["cellRenderer"] = NameLink;
          col["cellRendererParams"] = {
            link: `/savedSearch`,
            // datasourceId:"DatasourceID",
            recordIdField: "ID",
            isSavedSearch: true,
            newusername : "newusername",
            searchClicked : false
          };
        }
        if (col.field == "" && col.headerName == "Subscription status") {
          col["cellRenderer"] = AdminSavedSearchUser;
          col["cellRendererParams"] = {
            recordIdField: "ID",
            isSavedSearch: true,
            IsSubscribed :"IsSubscribed",
            DatasourceID: "DatasourceID",
          };
        }
        if (col.field == "" && col.headerName == "") {
          col["cellRenderer"] = AdminSavedSearchDelete;
          col["cellRendererParams"] = {
            recordIdField: "ID",
            isSavedSearch: true,
          };
        }
      });
      state.searchData = { rowData, colDef };
    },
    storeSavedSearchAlertDeactiveData: (state, action: PayloadAction<any>) => {
      state.searchData.rowData = action.payload.data;
      state.loading = false;
      const payloadData = action.payload.data;
      let rowData = payloadData as object[];
      let colDef:any = savedSearchDeactiveColumns();
      //const idFieldName = getIdFieldName(Number(dsId));
      colDef.forEach((col:any) => {
        if (col.field == "Name") {
          col["cellRenderer"] = NameLink;
          col["cellRendererParams"] = {
            link: `/savedSearch`,
            //datasourceId:"DatasourceID",
            recordIdField: "ID",
            isSavedSearch: true,
            newusername : "newusername"
          };
        }
        if (col.field == "" && col.headerName == "Subscription status") {
          col["cellRenderer"] = AdminSavedSearchDeactiveData;
          col["cellRendererParams"] = {
            recordIdField: "ID",
            isSavedSearch: true,
            IsSubscribed :"IsSubscribed",
            DatasourceID: "DatasourceID"
          };
        }
      });
      state.searchData = { rowData, colDef };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    saveSavedSearchData: (state, action: PayloadAction<any[]>) => {
      state.searchSavedSearchData = action.payload;
    },
    saveSavedSelectionData: (state, action: PayloadAction<any[]>) => {
      state.searchSavedSelectionData = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { storeSavedSearchData,storeSavedSearchAlertData, setLoading,saveSavedSearchData,saveSavedSelectionData,storeSavedSearchAlertDeactiveData} = SavedSearchSlice.actions;
export default SavedSearchSlice.reducer;
