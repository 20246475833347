// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-signin[data-v-857fd648] {
  width: 100%;
  max-width: 480px;
  margin: auto;
  color: #212529;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 11px 0 rgb(0 0 0 / 10%);
}

.form-signin .text-link[data-v-857fd648] {
  text-align: left;
}

.form-signin .text-link label[data-v-857fd648] {
  padding-left: 5px;
}

.form-signin .text-link a[data-v-857fd648] {
  color: inherit;
  text-decoration: none;
}

.form-signin .btn[data-v-857fd648] {
  margin-left: 5px;
  padding: 6px 15px !important;
  font-weight: 600;
}
.log-out {
  color: deepskyblue;
  font-size: 12px;
  outline: none !important;
}

span {
  font-size: 12px; /* Changed to 12 from 13 as application disclaimer text was big. */
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Logout/Logout.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,eAAe,EAAE,kEAAkE;EACnF,eAAe;AACjB","sourcesContent":[".form-signin[data-v-857fd648] {\n  width: 100%;\n  max-width: 480px;\n  margin: auto;\n  color: #212529;\n  padding: 30px;\n  background-color: #fff;\n  box-shadow: 0 0 11px 0 rgb(0 0 0 / 10%);\n}\n\n.form-signin .text-link[data-v-857fd648] {\n  text-align: left;\n}\n\n.form-signin .text-link label[data-v-857fd648] {\n  padding-left: 5px;\n}\n\n.form-signin .text-link a[data-v-857fd648] {\n  color: inherit;\n  text-decoration: none;\n}\n\n.form-signin .btn[data-v-857fd648] {\n  margin-left: 5px;\n  padding: 6px 15px !important;\n  font-weight: 600;\n}\n.log-out {\n  color: deepskyblue;\n  font-size: 12px;\n  outline: none !important;\n}\n\nspan {\n  font-size: 12px; /* Changed to 12 from 13 as application disclaimer text was big. */\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
