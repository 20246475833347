import { EditorState } from 'draft-js';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Field } from '../../../../store/DataSourceMetaData';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import FieldView from '../../FieldView';
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { useTable } from 'react-table';
import { UserContext } from '../../Editor';
import apiCall from '../../../service/apiCall';
import { getLookupValue } from './Utilty';
import { ToastrAlert } from '../../../ToastrAlert/ToastrAlert';

interface IRegulatory_DosageAndModeForm {
      field: Field;
      values: any;
      fieldChanged: (name?: string, value?: string, obj?: any) => void;
      childRows: Field[];
      validateCriteria: (field: Field) => boolean;
      RichtextFieldChanged: (name: string, value: EditorState) => void;
      datasourcename: string;
      section: any;
      datasourceId: number;
      formMode: string
}

const Regulatory_DosageAndModeForm = ({
      field,
      values,
      fieldChanged,
      childRows,
      validateCriteria,
      RichtextFieldChanged,
      datasourcename,
      section,
      datasourceId,
      formMode
}: IRegulatory_DosageAndModeForm) => {
      const selectedLookupGlobe = useAppSelector(
            (state) => state.multiselect.selectedLookupGlobe
      );
      const userContext = useContext(UserContext);
      const [assumptions, setassumptions] = useState<any>({});
      // const [regulatorValue, setRegulatorValue] = useState<string>("");

      const RegulatoryDosageInformation : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.RegulatoryDosageInformation")?.[0];
      const DaysPerCycle : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.DaysPerCycle")?.[0];
      const DosageUnit_ID : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.DosageUnit_ID")?.[0];

      const CyclesPerMonth : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.CyclesPerMonth")?.[0];
      const CyclesPerYear : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.CyclesPerYear")?.[0];

      const CycleNumber : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.CycleNumber")?.[0];
      const DosingUnitsPerCycle : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.DosingUnitsPerCycle")?.[0];
      const WastagePerCycle : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.WastagePerCycle")?.[0];

      const DosePerTreatment : Field = childRows.filter((x) => x.name == "RegulatoryWatch_DosageAndMode.DosePerTreatment")?.[0];

      const [showWarning,setShowWarning] = useState<boolean>(false);

      useEffect(() => {
            if(Object.keys(assumptions).length == 0){
                  const fetchassumptions = async() => {
                        const res = await apiCall('/api/sub-page/assumptions');
                        if(res.data){
                              setassumptions(res?.data[0]);                              
                        }
                  }
                  fetchassumptions();
            }
      },[])

      useEffect(() => {

            const keys = Object.keys(selectedLookupGlobe).filter((x) => x.includes("RegulatoryWatch_ApprovedIndication_Combination_"));
            let dosageDrugs = selectedLookupGlobe.RegulatoryWatch_Drug ?  [...selectedLookupGlobe.RegulatoryWatch_Drug] : [];
            keys.forEach((el) => {
                  dosageDrugs = [...dosageDrugs,...selectedLookupGlobe[el]];
                  dosageDrugs = dosageDrugs.filter((x) => x.Id < 100000);
            });

            dosageDrugs.filter((obj1 : any, i : number, arr : any) => 
                  arr.findIndex((obj2 : any) => (obj2.id === obj1.id)) === i
            );

            if (dosageDrugs?.length > 0) {
                  if (values.RegulatoryWatch_DosageAndMode?.length > 0) {
                        let _RegulatoryWatch_DosageAndMode: any[] = values.RegulatoryWatch_DosageAndMode;
                        const dosageIds: number[] = _RegulatoryWatch_DosageAndMode.map((el: any) => el.Dosage_ID);
                        const RegulatoryWatch_DrugIds: number[] =
                                    dosageDrugs.map((el) => el.Id);
                        let idsToBeRemoved: number[] = [];
                        dosageIds.forEach((id, index) => {
                              if (!RegulatoryWatch_DrugIds.includes(id)) {
                                    idsToBeRemoved.push(id);
                              }
                        });
                        const multiselectValuesTobeAdded =
                                    dosageDrugs.filter((el) => !dosageIds.includes(el.Id));
                        _RegulatoryWatch_DosageAndMode =
                              _RegulatoryWatch_DosageAndMode.filter((el) => !idsToBeRemoved.includes(el.Dosage_ID));
                        multiselectValuesTobeAdded.forEach((el) => {
                              _RegulatoryWatch_DosageAndMode.push({
                                    Dosage_ID: el.Id,
                                    Dosage_Name: el.Value
                              });
                        });

                        if (multiselectValuesTobeAdded.length > 0 || idsToBeRemoved.length > 0) {
                              fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);
                        }

                  } else {
                        const RegulatoryWatch_Drug = dosageDrugs;
                        let _RegulatoryWatch_DosageAndMode: any = [];
                        RegulatoryWatch_Drug.forEach((el) => {
                              _RegulatoryWatch_DosageAndMode.push({
                                    Dosage_ID: el.Id,
                                    Dosage_Name: el.Value
                              });
                        });
                        fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);
                  }
            } else {
                  fieldChanged("RegulatoryWatch_DosageAndMode", "", []);
            }
      }, [selectedLookupGlobe])

      const updateRegulatoryWatch_DosageAndMode = (obj: any, index: number) => {
            const _RegulatoryWatch_DosageAndMode = [...values.RegulatoryWatch_DosageAndMode];
            _RegulatoryWatch_DosageAndMode[index] = obj;
            fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);
      };

      const deleteCycle = (parentIndex : number,cycleIndex : number) => {
            let _RegulatoryWatch_DosageAndMode = [...values.RegulatoryWatch_DosageAndMode];
            _RegulatoryWatch_DosageAndMode[parentIndex]["Cycles"] = _RegulatoryWatch_DosageAndMode[parentIndex]["Cycles"]?.filter((x : any,i : number) => i != cycleIndex );
            let _DosePerTreatment = 0
            _RegulatoryWatch_DosageAndMode[parentIndex]["Cycles"]?.forEach((el:any ) => {
                  const max = Number(el["RegulatoryWatch_DosageAndMode.CycleNumber_Max"]) || 0;
                  const min = Number(el["RegulatoryWatch_DosageAndMode.CycleNumber"]) || 0;
                  if(el.dosesPerCycle){
                        const _DosingUnitsPerCycle = Object.values(el.dosesPerCycle)?.reduce((partialSum , a) => partialSum + a, 0);
                        _DosePerTreatment += ((max - min ) + 1) * Number(_DosingUnitsPerCycle || 0);
                  }
            });
            _RegulatoryWatch_DosageAndMode[parentIndex]["RegulatoryWatch_DosageAndMode.DosePerTreatment"] = _DosePerTreatment;
            fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);
      };

      const addCycles = async (index: number) => {
            let _RegulatoryWatch_DosageAndMode = [...values.RegulatoryWatch_DosageAndMode];
            if (Number(_RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.DaysPerCycle"]) > 0) {

                  if (_RegulatoryWatch_DosageAndMode[index].Cycles?.length > 0) {
                        const l = _RegulatoryWatch_DosageAndMode[index].Cycles.length - 1;
                        const CycleNumber_Max = _RegulatoryWatch_DosageAndMode[index].Cycles[l]["RegulatoryWatch_DosageAndMode.CycleNumber_Max"];

                        _RegulatoryWatch_DosageAndMode[index].Cycles?.push({
                              "RegulatoryWatch_DosageAndMode.CycleNumber": Number(CycleNumber_Max) + 1,
                              "RegulatoryWatch_DosageAndMode.CycleNumber_Max": Number(CycleNumber_Max) + 1
                        });
                  } else {
                        _RegulatoryWatch_DosageAndMode[index].Cycles = [{
                              "RegulatoryWatch_DosageAndMode.CycleNumber": 1,
                              "RegulatoryWatch_DosageAndMode.CycleNumber_Max": 1
                        }];
                  }
                  setShowWarning(false);
                  // fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);

                  let daysPerMonth = 30.4375;
                  let daysPerYear = 365.25;

                  const regulatorValue: string = values.Regulator_ID ? await getLookupValue(values.Regulator_ID) : "";
                  if (regulatorValue && Object.keys(assumptions).length > 0) {
                        daysPerMonth = assumptions?.DaysPerMonthValue;
                        daysPerYear = assumptions?.DaysPerYearValue;
                  }

                  const CyclesPerMonth = (daysPerMonth / Number(_RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.DaysPerCycle"])).toFixed(2);
                  const CyclesPerYear = (daysPerYear / Number(_RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.DaysPerCycle"])).toFixed(2);

                  _RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.CyclesPerMonth"] = CyclesPerMonth;
                  _RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.CyclesPerYear"] = CyclesPerYear;
                  fieldChanged("RegulatoryWatch_DosageAndMode", "", _RegulatoryWatch_DosageAndMode);
            } else if (Number(_RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.DaysPerCycle"]) == 0 || !_RegulatoryWatch_DosageAndMode[index]["RegulatoryWatch_DosageAndMode.DaysPerCycle"])
                  ToastrAlert("Please provide value for days per cycle greater than zero","success")
                  // setShowWarning(true);
      };

      return (<>
            <div>
                  {
                        values?.RegulatoryWatch_DosageAndMode &&
                        values.RegulatoryWatch_DosageAndMode?.map((el: any, index: number) => (
                              <>
                                    <h6>
                                          <label>{el.Dosage_Name}</label>
                                    </h6>
                                    <hr />

    
                                     <FieldView
                                          field={RegulatoryDosageInformation}
                                          values={values}
                                          fieldChanged={fieldChanged}
                                          RichtextFieldChanged={RichtextFieldChanged}
                                          datasourcename={datasourcename}
                                          section={section}
                                          datasourceId={datasourceId}
                                          parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                          setObj={updateRegulatoryWatch_DosageAndMode}
                                          index={index}
                                          vi={index}
                                          formMode={formMode}
                                    />
                                                                         
                                    <FieldView
                                          field={DaysPerCycle}
                                          values={values}
                                          fieldChanged={fieldChanged}
                                          RichtextFieldChanged={RichtextFieldChanged}
                                          datasourcename={datasourcename}
                                          section={section}
                                          datasourceId={datasourceId}
                                          parentObject={el}
                                          setObj={updateRegulatoryWatch_DosageAndMode}
                                          index={index}
                                          vi={index}
                                          formMode={formMode}
                                    />
                                   {/* {showWarning && <label style={{color:"#f00000",fontWeight:"bold",display:"flex",marginTop:"-28px",float:"right",paddingRight:"37%"}}>The DaysPerCycle field is required.</label>} */}
                                    
                                    <FieldView
                                          field={DosageUnit_ID}
                                          values={values}
                                          fieldChanged={fieldChanged}
                                          RichtextFieldChanged={RichtextFieldChanged}
                                          datasourcename={datasourcename}
                                          section={section}
                                          datasourceId={datasourceId}
                                          parentObject={el}
                                          setObj={updateRegulatoryWatch_DosageAndMode}
                                          index={index}
                                          vi={index}
                                          formMode={formMode}
                                    />
                                    {
                                          el.Cycles?.length > 0 && <>
                                                <FieldView
                                                      field={CyclesPerMonth}
                                                      values={values}
                                                      fieldChanged={fieldChanged}
                                                      RichtextFieldChanged={RichtextFieldChanged}
                                                      datasourcename={datasourcename}
                                                      section={section}
                                                      datasourceId={datasourceId}
                                                      parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                                      setObj={updateRegulatoryWatch_DosageAndMode}
                                                      index={index}
                                                      vi={index}
                                                      formMode={"read"}
                                                />
                                                <FieldView
                                                      field={CyclesPerYear}
                                                      values={values}
                                                      fieldChanged={fieldChanged}
                                                      RichtextFieldChanged={RichtextFieldChanged}
                                                      datasourcename={datasourcename}
                                                      section={section}
                                                      datasourceId={datasourceId}
                                                      parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                                      setObj={updateRegulatoryWatch_DosageAndMode}
                                                      index={index}
                                                      vi={index}
                                                      formMode={"read"}
                                                />
                                          </>
                                    }


                                    {
                                          el.Cycles?.map((cycle: any, i: number) => (
                                                <>
                                                      <FieldView
                                                            field={CycleNumber}
                                                            values={values}
                                                            fieldChanged={fieldChanged}
                                                            RichtextFieldChanged={RichtextFieldChanged}
                                                            datasourcename={datasourcename}
                                                            section={section}
                                                            datasourceId={datasourceId}
                                                            parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                                            setObj={updateRegulatoryWatch_DosageAndMode}
                                                            index={index}
                                                            vi={i}
                                                            formMode={formMode}
                                                      />
                                                      <div className="col-sm-12">
                                                            <label className="col-sm-3 text-right control-label" style={{ paddingRight: "10px" }}>Doses per cycle
                                                            </label>
                                                            <DosageTableElement RegulatoryWatch_DosageAndMode = {el} value = {el["RegulatoryWatch_DosageAndMode.DaysPerCycle"]} cycle = {cycle} drugIndex = {index} cycleIndex = {i} assumptions = {assumptions} dosageUnitId ={el["RegulatoryWatch_DosageAndMode.DosageUnit_ID"]}  updateRegulatoryWatch_DosageAndMode = {updateRegulatoryWatch_DosageAndMode} Regulator_ID = {values.Regulator_ID} />
                                                      </div>
                                                      <div className="col-sm-12">
                                                            <label className="col-sm-3 text-right control-label" style={{ paddingRight: "10px" }}>
                                                                  Total dosage per cycle
                                                            </label>
                                                            <label className="sol-sm-8" style={{"paddingLeft" : "10px"}}>{cycle?.TotalDosagePerCycle || 0}</label>
                                                      </div>
                                                      <FieldView
                                                            field={DosingUnitsPerCycle}
                                                            values={values}
                                                            fieldChanged={fieldChanged}
                                                            RichtextFieldChanged={RichtextFieldChanged}
                                                            datasourcename={datasourcename}
                                                            section={section}
                                                            datasourceId={datasourceId}
                                                            parentObject={cycle}
                                                            setObj={updateRegulatoryWatch_DosageAndMode}
                                                            index={index}
                                                            vi={i}
                                                            formMode={"read"}
                                                      />
                                                      <FieldView
                                                            field={WastagePerCycle}
                                                            values={values}
                                                            fieldChanged={fieldChanged}
                                                            RichtextFieldChanged={RichtextFieldChanged}
                                                            datasourcename={datasourcename}
                                                            section={section}
                                                            datasourceId={datasourceId}
                                                            parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                                            setObj={updateRegulatoryWatch_DosageAndMode}
                                                            index={index}
                                                            vi={index}
                                                            formMode={"read"}
                                                      />
                                                            <div style={{display:"block",paddingBottom:"10px",}} onClick = {() => deleteCycle(index,i)}>
                                                                  <label className="iqvia-color" style = {{cursor:"pointer",float:"right",marginBottom:"10px"}}>  <RiDeleteBinLine className="margin-bottom-3" /> Cycle</label>
                                                            </div>
                                                     
                                                      
                                                </>
                                          ))
                                    }
                                    {
                                          el.Cycles?.length > 0 &&
                                          <>
                                                <hr style={{marginBottom:"0rem"}} />
                                                <FieldView
                                                      field={DosePerTreatment}
                                                      values={values}
                                                      fieldChanged={fieldChanged}
                                                      RichtextFieldChanged={RichtextFieldChanged}
                                                      datasourcename={datasourcename}
                                                      section={section}
                                                      datasourceId={datasourceId}
                                                      parentObject={values.RegulatoryWatch_DosageAndMode[index]}
                                                      setObj={updateRegulatoryWatch_DosageAndMode}
                                                      index={index}
                                                      vi={index}
                                                      formMode={"read"}
                                                />
                                                <hr />
                                          </>
                                    }
                                    <div>
                                          <label
                                                className="iqvia-color"
                                                onClick={() => addCycles(index)}
                                                style={{ float: "right", cursor: "pointer" }}>
                                                <AddIcon className="iqvia-color" /> Add cycle</label>
                                    </div>  
                              </>
                        ))
                  }
            </div>
      </>)
}
export default Regulatory_DosageAndModeForm;

const Table = ({columns, data }:any) => {
      const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
      } = useTable({
        columns,
        data
      });
    
      return (
        <table className="dosage-table" style={{margin:"0 auto"}}   {...getTableProps()}>
          <thead >
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className="dosage-head dosage-head-fields" {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className="dosage-head-fields dosage-fields" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    
    }

interface IDosageTableElement {
      RegulatoryWatch_DosageAndMode : any;
      value : number;
      cycle : any;
      drugIndex : number;
      cycleIndex : number;
      assumptions: any;
      dosageUnitId: number
      updateRegulatoryWatch_DosageAndMode : (obj : any , i : number) => void;
      Regulator_ID : number
}

const DosageTableElement = ({ RegulatoryWatch_DosageAndMode,value,cycle,drugIndex,cycleIndex,assumptions,dosageUnitId,updateRegulatoryWatch_DosageAndMode,Regulator_ID}: IDosageTableElement) => {
      const [data, setData] = useState({});
      const [columns, setColumns] = useState<any[]>([]);
    
      useEffect(() => {

            const xy: any[] = [];

            let count = 0;
            let week = 0;

            let data_: any = {};

            for (let i = 0; i < value; i++) {
                  data_[i] = 0

                  // generating headers as per react-table
                  if (count == 0) xy.push({ Header: "Week " + (week + 1), columns: [] });

                  xy[week]["columns"].push({
                        Header: i + 1,
                        accessor: String(i),
                        Cell: ({ cell } : any) => (                             
                              <input
                                    type={"text"}
                                    onChange={(e) => {
                                          setCycleValues(e.target.value,i);
                                    }}
                                    value={cycle.dosesPerCycle?.[i]}
                                    // min={0}
                                    defaultValue={0}
                                    style={{ border: "none", width: "35px",outline:"none" }}
                              />                                                          
                            ),
                  });

                  count = count + 1;

                  if (count == 7) {
                        week = week + 1;
                        count = 0;
                  }
            }

            setData([data_]);
            setColumns(xy);
      }, [value]);

      const setCycleValues = async(e : any, i  :number ) => {
            let averageBSA = 0;
            let averageWeight = 0;
            let dosePerUnit = 1.0;
            let packSize = 1.0;
            let dosePerPack = dosePerUnit * packSize;
            let _RegulatoryWatch_DosageAndMode  :any = {...RegulatoryWatch_DosageAndMode};
            const dosageUnitValue : string = dosageUnitId  ? await getLookupValue(dosageUnitId) : "";
            const regulatorValue  : string = Regulator_ID ? await getLookupValue(Regulator_ID) : "";
            if(Object.keys(assumptions).length > 0)
                  switch (regulatorValue.toUpperCase() )
            {
                case "FDA":
                    averageWeight = assumptions.Region1AverageWeight ?? 0;
                    averageBSA = assumptions.Region1AverageBSA ?? 0;
                    break;
                case "EMA":
                    averageWeight = assumptions.Region2AverageWeight ?? 0;
                    averageBSA = assumptions.Region2AverageBSA ?? 0;
                    break;
                case "HEALTH CANADA":
                    averageWeight = assumptions.Region3AverageWeight ?? 0;
                    averageBSA = assumptions.Region3AverageBSA ?? 0;
                    break;
                case "MHRA":
                    averageWeight = assumptions.Region4AverageWeight ?? 0;
                    averageBSA = assumptions.Region4AverageBSA ?? 0;
                    break;
                case "NMPA":
                    averageWeight = assumptions.Region5AverageWeight ?? 0;
                    averageBSA = assumptions.Region5AverageBSA ?? 0;
                    break;
                case "PMA":
                    averageWeight = assumptions.Region6AverageWeight ?? 0;
                    averageBSA = assumptions.Region6AverageBSA ?? 0;
                    break;
                case "PMDA":
                    averageWeight = assumptions.Region7AverageWeight ?? 0;
                    averageBSA = assumptions.Region7AverageBSA ?? 0;
                    break;
                case "TGA":
                    averageWeight = assumptions.Region8AverageWeight ?? 0;
                    averageBSA = assumptions.Region8AverageBSA ?? 0;
                    break;
                default:
                    averageWeight = assumptions.Region9AverageWeight ?? 0;
                    averageBSA = assumptions.Region9AverageBSA ?? 0;
                    break;
            }
            if(!_RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["dosesPerCycle"]){
                  _RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["dosesPerCycle"] = {};
            }
            _RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["dosesPerCycle"][i] = Number(e) || 0;

            let totalValue  : number = Object.values(_RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["dosesPerCycle"])?.reduce((partialSum , a) => Number(partialSum + a), 0);
            if(Object.keys(assumptions).length > 0){
                  if (dosageUnitValue.toLowerCase() == "mg/kg"){
                        totalValue = totalValue * averageWeight;
                  }else if (dosageUnitValue.toLowerCase() == "mg/m^2" || dosageUnitValue.toLowerCase() == "mg/m2"){
                        totalValue = totalValue * averageBSA;
                  }
            }
            let DosingUnitsPerCycle = Math.ceil(totalValue / (dosePerPack > 0 ? dosePerPack : 1 ));
            let wastagePerCycle =  (DosingUnitsPerCycle * dosePerPack) - totalValue;
            _RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["TotalDosagePerCycle"] = totalValue;
            _RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["RegulatoryWatch_DosageAndMode.DosingUnitsPerCycle"] = DosingUnitsPerCycle;
            _RegulatoryWatch_DosageAndMode["Cycles"][cycleIndex]["RegulatoryWatch_DosageAndMode.WastagePerCycle"] = wastagePerCycle.toFixed(2);
            let _DosePerTreatment = 0
            _RegulatoryWatch_DosageAndMode["Cycles"]?.forEach((el:any ) => {
                  const max = Number(el["RegulatoryWatch_DosageAndMode.CycleNumber_Max"]) || 0;
                  const min = Number(el["RegulatoryWatch_DosageAndMode.CycleNumber"]) || 0;
                  if(el.dosesPerCycle){
                        const _DosingUnitsPerCycle = Object.values(el.dosesPerCycle)?.reduce((partialSum , a) => partialSum + a, 0);
                        _DosePerTreatment += ((max - min ) + 1) * Number(_DosingUnitsPerCycle || 0);
                  }
            });
            _RegulatoryWatch_DosageAndMode["RegulatoryWatch_DosageAndMode.DosePerTreatment"] = _DosePerTreatment;
            updateRegulatoryWatch_DosageAndMode(_RegulatoryWatch_DosageAndMode,drugIndex);
      }
    
      return (
            <>
                  <div
                        className="col-sm-12 row form-group"
                        style={{ marginBottom: "0rem" }}
                  >

                        <div className="col-sm-12">
                              {columns.length > 0 && <Table columns={columns} data={data} />}
                        </div>
                  </div>
            </>
      );
    };