import React, { useContext, useEffect } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../../Editor";
import AddIcon from "@mui/icons-material/Add";
import { RiDeleteBinLine } from "react-icons/ri";

interface IArmsAndCohortsForm {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

const ClinicalDataArmsAndCohortsForm = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,
}: IArmsAndCohortsForm) => {
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (values["CohortsPresent"] && values[field.name]) {
      const armObj = values[field.name];
      armObj.forEach((el: any) => {
        if (!el["Cohorts"]) el["Cohorts"] = [{}];
      });
      fieldChanged(field.name, "", armObj);
    }
  }, [values["CohortsPresent"]]);

  const updateArms = (obj: any, index: number) => {
    const armObj = [...values[field.name]];
    armObj[index] = obj;
    fieldChanged(field.name, "", armObj);
  };

  const updateCohorts = (parent: any, i: number, obj: any, c_i: number) => {
    const armObj = [...values[field.name]];
  };

  //index reprs arm index
  const addCohort = (index: number) => {
    const armObj = values[field.name];

    armObj[index]["Cohorts"].push({});

    fieldChanged(field.name, "", armObj);
  };

  const onCohortValueChange = (
    arm_index: number,
    c_i: number,
    name: string,
    value: string
  ) => {
    const armObj = values[field.name];
    armObj[arm_index]["Cohorts"][c_i][name] = value;
    fieldChanged(field.name, "", armObj);
  };

  //index reprs arm index
  const deleteCohort = (index: number, c_i: number) => {
    const armObj = values[field.name];
    //c_i reprs cohorts index
    //delete armObj[index]["Cohorts"][c_i];
    armObj[index]["Cohorts"] = !!armObj[index]["Cohorts"] && armObj[index]["Cohorts"].filter(
      (el: any, i: number) => i != c_i
    );
    fieldChanged(field.name, "", armObj);
  };

  const deleteArm = (arm_index: number) => {
    if (values[field.name].length == 1) return; //fieldChanged(field.name, "", []);
    else {
      const arm: any[] = [...values[field.name]];
      fieldChanged(field.name, "", [
        ...arm.filter((item: any, xi: number) => xi != arm_index),
      ]);
    }
  };
  return (
    <>
      {values[field.name] &&
        values[field.name].map((val: any, index: number) => (
          <React.Fragment key={`arms_${index}`}>
             <div className="float-left" style={{marginTop:"40px"}}>
              <b style={{marginTop:"15px"}}> {"Arm " + String(index + 1)}</b>
            </div>
            <div className="float-end">
              <div className="row" style={{marginTop: index == 0 ? "65px" : "27px"}}>
                {childRows
                  .filter((x) =>
                    [
                      "ClinicalData_Arms.Name",
                      "ClinicalData_Arms.Description",
                      "ClinicalData_Arms.NumberOfPatients",
                    ].includes(x.name)
                  )
                  .map((row,i) => (
                    <React.Fragment key={`child_${i}`}>
                      {row.name.includes('Name') && index != 0 &&
                        <hr style={{ margin: "0 auto", width: "96%" }} />
                      }
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section}
                        datasourceId={datasourceId}
                        parentObject={values[field.name][index]}
                        setObj={updateArms}
                        index={index}
                      />
                      {row.name == "ClinicalData_Arms.Name" && (
                        <div style={{textAlign:"right",display:"contents"}}>
                          <label  >
                            <RiDeleteBinLine
                              onClick={() => deleteArm(index)}
                              style={{ color: "rgb(0, 170, 231)", fontSize: "19px", cursor: "pointer", position: "absolute",marginTop:"23px",marginLeft:"-10px" }}
                            />
                          </label>
                        </div>
                      )}
                    </React.Fragment>
                  ))}

                {values["CohortsPresent"] &&
                  !!values[field.name][index] &&
                  !!values[field.name][index].Cohorts &&
                  values[field.name][index].Cohorts.length > 0 &&
                  values[field.name][index].Cohorts.map(
                    (el: any, c_i: number) => (
                      <>
                        {childRows
                          .filter(
                            (x) =>
                              x.name ==
                              "ClinicalData_Arms.ClinicalData_ArmsCohorts.Name"
                          )
                          .map((f, f_i) => (
                            <div
                              key={`cohort_${f_i}`}
                              className={
                                "col-sm-12 row form-group field-type-" +
                                f.fieldTypeId.toString() +
                                " " +
                                "field-name-" +
                                f.name +
                                " field-id-" +
                                f.id.toString()
                              }
                              style={{ marginBottom: "0rem" }}
                            >
                              <label
                                className={
                                  "col-sm-4 text-end control-label "
                                }
                                title={f.description}
                                style={{
                                  paddingRight: "4px",
                                  paddingTop: 10,
                                }}
                              >
                                {f.label} <span>{c_i + 1}</span>
                                {f.label && (
                                  <FaInfoCircle
                                    onClick={() => {
                                      userContext.showAlert(
                                        <>
                                          <span
                                            style={{
                                              fontSize: "70%",
                                              paddingLeft: "2px",
                                            }}
                                          >
                                            {datasourcename}
                                            <DoubleArrowIcon
                                              style={{
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {section.name}
                                            <DoubleArrowIcon
                                              style={{
                                                strokeWidth: "5",
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {f.label}
                                          </span>
                                        </>,
                                        "info",
                                        f.description,
                                        "https://google.com",
                                        true
                                      );
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </label>
                              <div className="col-sm-8">
                                <textarea
                                  id={String(f.fieldTypeId)}
                                  required={f.required}
                                  name={f.name}
                                  value={el[ f.name ]}
                                  onChange={(e) =>
                                    onCohortValueChange(
                                      index,
                                      c_i,
                                      f.name,
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                  placeholder={f.label}
                                ></textarea>
                              </div>
                              {!!val &&
                                !!val.Cohorts &&
                                val.Cohorts.length &&
                                val.Cohorts.length - 1 == c_i ? (
                                <label
                                  style={{
                                    paddingTop: "30px",
                                    // paddingLeft: "40px",
                                    display: "contents"
                                  }}
                                  onClick={() => addCohort(index)}
                                >
                                  <AddIcon style={{ fontSize: "14px", color: "rgb(0, 170, 231)", marginTop: "2.3em" }} />
                                </label>
                              ) : (
                                <label
                                  style={{
                                    paddingTop: "30px",
                                    display: "contents"
                                    // paddingLeft: "40px",
                                  }}
                                  onClick={() => deleteCohort(index, c_i)}
                                >
                                  <RiDeleteBinLine style={{ fontSize: "medium", color: "rgb(0, 170, 231)", marginTop: "2em" }} />
                                </label>
                              )}
                            </div>
                          ))}
                      </>
                    )
                  )}
                {childRows
                  .filter((x) =>
                    [
                      "ClinicalData_Arms.OutcomeResultID",
                      "ClinicalData_Arms.Details",
                      // "ClinicalData_Arms.ClinicalData_ArmsCohorts"
                    ].includes(x.name)
                  )
                  .map((row,rf) => (
                    <React.Fragment key={rf}>
                      {/* {validateCriteria(row) && ( */}
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section}
                        datasourceId={datasourceId}
                        parentObject={values[field.name][index]}
                        setObj={updateArms}
                        index={index}
                      />
                      {/* )} */}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </React.Fragment>
        ))}
    </>
  );
};

export default ClinicalDataArmsAndCohortsForm;
