import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DraggablePopover from '../../Popups/Popup_MaterialUI';
import apiCall from '../../service/apiCall';
import DeleteCall from '../../service/deleteCall';

interface ShowRuleProp {
      dataSourceID: number;
      fieldCriteriaDatasourceID: number;
      fieldId: number;
      recordID: number;
}

interface IFieldCriteriaProp {
      ID: number
      "FieldName": string,
      "Value": string,
      "Fieldtype": string,
      "Set": number
}

export const htaButtonStyle = { color: "rgb(0, 170, 231)", border: "none", background: "white" };

const ShowRule = ({ dataSourceID, fieldCriteriaDatasourceID, fieldId, recordID }: ShowRuleProp) => {
      const navigate = useHistory();
      const [fieldCriteria, setFieldCriteria] = useState<IFieldCriteriaProp[]>([]);
      const [showRuleDel, setShowRuleDel] =
            React.useState<HTMLButtonElement | null>(null);
      const [ruleTobeDeleted, setRuleTobeDeleted] = useState<number>(0);
      const width = {
            minWidth: "80px"
      };

      useEffect(() => {
            const getFieldcriteria = async () => {
                  const res = await apiCall(`/api/admin/fieldcriteriabyid/${fieldId}`);
                  const fieldCriteria: IFieldCriteriaProp[] = res.data;
                  setFieldCriteria(fieldCriteria);
            }
            if (!showRuleDel)
                  getFieldcriteria()
      }, [showRuleDel])

      const navigateToEditForm = (id: number) => {
            navigate.push(`/admin/fieldcriteria/277/${fieldCriteriaDatasourceID}/${fieldId}/${id}`);
      };

      const handleCloseRuleDel = () => {
            setShowRuleDel(null);
      }

      const setUpDelete = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
            setRuleTobeDeleted(id);
            setShowRuleDel(event.currentTarget);
      }

      const deleteShowRule = () => {
            DeleteCall(`/api/admin/fieldcriteria/${ruleTobeDeleted}`);
            setTimeout(() => setShowRuleDel(null), 100);
      }

      return (
            <>
                  <div style={{paddingTop:"25px"}}>
                        <div className="col-sm-3 text-end control-label " style={{ paddingRight: "80px" }}>
                              <h4>Show Rules</h4>
                        </div>
                        <div className="col-sm-3 text-end control-label " style={{ paddingRight: "65px" }}>
                              <a href={`/admin/fieldcriteria/277/${fieldCriteriaDatasourceID}/${fieldId}`} style={{ color: "rgb(0, 170, 231)" }}> Create new Show Rule</a>
                        </div>
                        {
                              fieldCriteria.length > 0 && <div style={{ paddingLeft: "182px" }}>
                                    <table style={{ width: "90%" }}>
                                          <tr style={{ borderBottom: "1px solid #ccc" }}>
                                                <th>
                                                      Field Name
                                                </th>
                                                <th>
                                                      Value
                                                </th>
                                                <th>
                                                      Field type
                                                </th>
                                                <th>
                                                      Set
                                                </th>
                                          </tr>

                                          {
                                                fieldCriteria.map((el) => (
                                                      <tr>
                                                            <td style={width}>{el.FieldName}</td>
                                                            <td style={width}>{el.Value}</td>
                                                            <td style={width}>{el.Fieldtype}</td>
                                                            <td style={width}>{el.Set}</td>
                                                            <td>
                                                                  <button type="button" style={htaButtonStyle} onClick={() => navigateToEditForm(el.ID)}>Edit</button>|
                                                                  <button type="button" style={htaButtonStyle} onClick={(e) => setUpDelete(e, el.ID)} >Delete</button>
                                                            </td>
                                                      </tr>
                                                ))
                                          }

                                    </table>
                              </div>
                        }
                        
                  </div>
                  {showRuleDel && (
                        <DraggablePopover
                              title="Delete confirmation"
                              anchorEl={showRuleDel}
                              popupBody={<p>Are you sure you want to delete this field criteria?</p>}
                              buttonsArray={["Yes"]}
                              btnCallbacks={[deleteShowRule]}
                              onPopupClose={handleCloseRuleDel}
                              keyName={"ShowRule"}
                        ></DraggablePopover>
                  )}
            </>)
}

export default ShowRule;