import React, { useState, useEffect, useContext } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { useAppSelector } from "../../../../store/hooks";
import { sizeColor } from "../../SectionView";
import {
  showMultiSelectPopup,
  setRecordVersionId,
  setSuperDisease,
} from "../../../../store/MultiSelectSlice";
import { useAppDispatch } from "../../../../store/hooks";
import { UserContext } from "../../Editor";

interface InfoPopupProps {
  datasourceName: string;
  section: string;
  label: string;
  infoLabel: string;
  description: string;
}
const LabelInfoPopup = ({
  datasourceName,
  section,
  label,
  infoLabel,
  description,
}: InfoPopupProps) => {
  const userContext = useContext(UserContext);
  return (
    <label className="control-label " style={{ display: "flex" }}>
      {label}
      <FaInfoCircle
        onClick={() => {
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                {datasourceName}
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                {section}
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {infoLabel}
              </span>
            </>,
            "info",
            description ? description : "",
            "https://google.com",
            true
          );
        }}
        style={{
          fontSize: label == "Approval type/Indication" ? "1em" : label=="In combination with/ Combinations" ? "1.4em": label == "Treatment line/modality" ? "12px" : label == "Approval date" ? "0.9em" : "12px",
          marginRight: label == "Approval type/Indication" ? "0px" : label=="In combination with/ Combinations" ? "1.2em" : label == "Treatment line/modality" ? "0" : "",
          marginTop: label == "Approval type/Indication" ? "0.4em" : label=="In combination with/ Combinations" ? "0.1em" : label == "Treatment line/modality" ? "0.3em" : label == "Approval date" ? "0.3em" : label == "Patient population" ? "0.3em" : label == "Disease" ? "0.3em" : "",
          marginLeft: label == "Approval type/Indication" ? "0.5em" : label=="In combination with/ Combinations" ? "0px" : label == "Treatment line/modality" ? "1em" : label == "Approval date" ? "1em" : label == "Patient population" ? "1em" : label == "Disease" ? "0.6em" : "3px",
          cursor: "pointer",
        }}
      />
    </label>
  );
};

export interface RegulatoryWatch_ApprovedIndicationsProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}

const RegulatoryWatch_ApprovedIndications = ({
  fieldInfo,
  datasourceName,
  section,
}: RegulatoryWatch_ApprovedIndicationsProp) => {
  const dispatch = useAppDispatch();
  const [field, setField] = useState<any[]>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
  const [approvedIndications, setApprovedIndications] = useState<any>({});
  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      setField(fieldInfo);
    }
  }, [fieldInfo]);

  useEffect(() => {
    setApprovedIndications(subPageDate["approved_indication"]);
  }, [subPageDate]);

  return (
    <>
      {approvedIndications &&
        approvedIndications.approvedIndications &&
        approvedIndications.approvedIndications.length > 0 && (
          <>
            <div id="approved-indications">
              <hr />
              <div
                id="approved-indication-header"
                className="col-sm-12"
                style={{
                  display: "flex",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    // label={"Approval date/Indication"}
                    // infoLabel={"Approval date/Indication"}
                    label={"Approval date"}
                    infoLabel={"Approval date"} 
                    description={
                      "This field provides the initial approval date for original approvals and the approval date for the extension for line extensions."
                    }
                  />
                </div>
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Approval type/Indication"}
                    infoLabel={"Approval type/Indication"}
                    // label={"Approval type"}
                    // infoLabel={"Approval type"}
                    description={`This field provides the regulatory approval types
                        * Original
                        * Extension of indication`}
                  />
                </div>
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"In combination with/ Combinations"}
                    infoLabel={"Combination"}
                    description={
                      "This field specifies if the drugs used in combination with assessed drug."
                    }
                  />
                </div>
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Patient population"}
                    infoLabel={"Population"}
                    description={
                      "This field specifies the patient population for the assessed drug."
                    }
                  />
                </div>
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Treatment line/modality"}
                    infoLabel={"Treatment line"}
                    description={
                      "This field specifies the treatment line and treatment modality of the drug assessed by the regulator."
                    }
                  />
                </div>
                <div
                  className="header-cell col-sm-2"
                  style={{ display: "flex" }}
                >
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Disease"}
                    infoLabel={"Disease"}
                    description={
                      "This field specifies the disease(s) assessed by the regulator."
                    }
                  />
                </div>
              </div>
              <div id="approved-indication-rows" className="col-sm-12">
                <>
                  {approvedIndications.approvedIndications.map(
                    (x: any, index: any) => (
                      <>
                        <hr />
                        <div
                          id={`approved-indication-row-${index}`}
                          className="col-sm-12"
                          style={{ display: "flex" }}
                        >
                          <div className="row-cell col-sm-2">
                            {new Date(x.ApprovalDate).toLocaleDateString(
                              "en-GB"
                            )}
                          </div>
                          <div className="row-cell col-sm-2">
                            {x.indicationType}
                          </div>
                          <div className="row-cell col-sm-2">
                            {x.combination &&
                                x.combination
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                            <hr />
                            {x.combination_item &&
                                x.combination_item
                                  .split(",")
                                  .map((c: string) => <div>{c.substring(2)}</div>)}
                          </div>
                          <div className="row-cell col-sm-2">
                            {x.patientPopulation &&
                                x.patientPopulation
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                          </div>
                          <div className="row-cell col-sm-2">
                            <>
                              {x.treatmentLine &&
                                x.treatmentLine
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                              {x.treatmentModality && <hr style={{ width: "82%"}} />}
                              {x.treatmentModality &&
                                x.treatmentModality
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                            </>
                          </div>
                          <div className="row-cell col-sm-2">
                            <button
                              type="button"
                              id={x.disease}
                              className="btn btn-link"
                              style={{
                                ...sizeColor,
                                paddingTop: "1px",
                                paddingLeft: "0px",
                              }}
                              onClick={() => {
                                console.log("open disease hierarchy popup");
                                dispatch(
                                  setRecordVersionId({
                                    version_id: x.RegulatoryWatch_Version_ID,
                                    indication_id: x.ID,
                                  })
                                );
                                // dispatch(setSuperDisease([]));
                                dispatch(
                                  showMultiSelectPopup(
                                    169,
                                    true,
                                    "RegulatoryWatch_ApprovedIndication_Disease_"+String(index),
                                    "Disease Hierarchy"
                                  )
                                );
                              }}
                            >
                              {" Show disease hierarchy"}
                            </button>
                            <div>
                              {x.disease_ID && (
                                <>
                                  {x.disease?.split(",").map((el: string) => (
                                    <div>{el}</div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          id={`approved-indication-row-description-${index}`}
                          className="col-sm-12"
                          style={{ display: "flex", marginBottom:"40px"}}
                        >
                          <div className="row-cell col-sm-4">
                            <hr />

                            
                            {x.Indication && (
                              <IndicationDetails
                                indication={x.Indication}
                                ID={x.ID}
                                IsPrevIndi={false}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </>
              </div>
            </div>
            <hr/>
            <div id="previously-approved-indications">
              <hr style={{marginTop:"38px", color:"#FFF"}}/>
              
              <div className="record-view-height panel-title" style={{marginTop:"-15px",fontSize:"12px",paddingBottom:"20px"}}>
                {" "}
                Previously approved indications
              </div>
              <hr />
              <hr style={{marginTop:"20px"}} />
              <div
                id="previously-approved-indication-header"
                className="col-sm-12"
                style={{
                  display: "flex",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    // label={"Approval date/Indication"}
                    // infoLabel={"Approval date/Indication"}
                    label={"Approval date"}
                    infoLabel={"Approval date"} 
                    description={
                      "This field provides the initial approval date for original approvals and the approval date for the extension for line extensions."
                    }
                  />
                </div>
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Approval type/Indication"}
                    infoLabel={"Approval type/Indication"}
                    // label={"Approval type"}
                    // infoLabel={"Approval type"}
                    description={`This field provides the regulatory approval types
                        * Original
                        * Extension of indication`}
                  />
                </div>
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"In combination with"}
                    infoLabel={"Combination"}
                    description={
                      "This field specifies if the drugs used in combination with assessed drug."
                    }
                  />
                </div>
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Patient population"}
                    infoLabel={"Population"}
                    description={
                      "This field specifies the patient population for the assessed drug."
                    }
                  />
                </div>
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Treatment line/modality"}
                    infoLabel={"Treatment line"}
                    description={
                      "This field specifies the treatment line and treatment modality of the drug assessed by the regulator."
                    }
                  />
                </div>
                <div className="header-cell col-sm-2">
                  <LabelInfoPopup
                    datasourceName={datasourceName}
                    section={section}
                    label={"Disease"}
                    infoLabel={"Disease"}
                    description={
                      "This field specifies the disease(s) assessed by the regulator."
                    }
                  />
                </div>
              </div>
              <div id="approved-indication-rows" className="col-sm-12">
                <>
                  {approvedIndications.previouslyApprovedIndications &&
                    approvedIndications.previouslyApprovedIndications.length >
                      0 &&
                    approvedIndications.previouslyApprovedIndications.map(
                      (x: any, index: any) => (
                        <>
                          <hr />
                          <div
                            id={`previously-approved-indication-row-${index}`}
                            className="col-sm-12"
                            style={{ display: "flex" }}
                          >
                            <div className="row-cell col-sm-2">
                              {new Date(x.ApprovalDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </div>
                            <div className="row-cell col-sm-2">
                              {x.indicationType}
                            </div>
                            <div className="row-cell col-sm-2">
                              {x.combination &&
                                x.combination
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                            </div>
                            <div className="row-cell col-sm-2">
                              {x.patientPopulation &&
                                x.patientPopulation
                                  .split(",")
                                  .map((c: string) => <div>{c}</div>)}
                            </div>
                            <div className="row-cell col-sm-2">
                              <>
                                {x.treatmentLine &&
                                  x.treatmentLine
                                    .split(",")
                                    .map((c: string) => <div>{c}</div>)}

                                {x.treatmentModality && <hr style={{ width: "82%"}} />}
                                {x.treatmentModality &&
                                  x.treatmentModality
                                    .split(",")
                                    .map((c: string) => <div>{c}</div>)}
                              </>
                            </div>
                            <div className="row-cell col-sm-2">
                              <button
                                type="button"
                                id={x.disease}
                                className="btn btn-link"
                                style={{
                                  ...sizeColor,
                                  paddingTop: "1px",
                                  paddingLeft: "0px",
                                }}
                                onClick={() => {
                                  console.log("open disease hierarchy popup");
                                  dispatch(
                                    setRecordVersionId({
                                      version_id: x.RegulatoryWatch_Version_ID,
                                      indication_id: x.ID,
                                    })
                                  );
                                  dispatch(setSuperDisease([]));
                                  dispatch(
                                    showMultiSelectPopup(
                                      169,
                                      true,
                                      "SuperSearchDiseaseIDs",
                                      "Disease Hierarchy"
                                    )
                                  );
                                }}
                              >
                                {" Show disease hierarchy"}
                              </button>
                              <div>
                                {x.disease_ID && (
                                  <>
                                    {x.disease?.split(",").map((el: string) => (
                                      <div>{el}</div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            id={`approved-indication-row-description-${index}`}
                            className="col-sm-12"
                            style={{ display: "flex" }}
                          >
                            <div className="row-cell col-sm-4">
                              <hr />
                              {x.Indication && (
                                // <a
                                //   href={`view/3/${x.RegulatoryWatch_ID}`}
                                //   style={{ color: "rgb(57, 57, 57)" }}
                                // >
                                  <IndicationDetails
                                    indication={x.Indication}
                                    ID={x.ID}
                                    IsPrevIndi={true}
                                    RecID={x.RegulatoryWatch_ID}
                                  />
                                //  </a>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}
                </>
              </div>
            </div>
          </>
        )}
    </>
  );
};

interface IndicationDetailsProps {
  indication: string;
  ID: number;
  IsPrevIndi: boolean;
  RecID?: number;
}
export const IndicationDetails = ({
  indication,
  ID,
  IsPrevIndi,
  RecID,
}: IndicationDetailsProps) => {
  const truncLength = 295;
  const [readMore, setReadMore] = useState(false);

  return (
    <>
      {readMore
        ? (IsPrevIndi ? <a
          href={`View/3/${RecID}`}
          target="_blank"
          className="no-underline 1"
          style={{ color: "rgb(57, 57, 57)" }}
        >
        {indication}</a> : <React.Fragment key={ID}>{indication}</React.Fragment>)
        : (IsPrevIndi ? <a
          href={`View/3/${RecID}`}
          target="_blank"
          className="no-underline 2"
          style={{ color: "rgb(57, 57, 57)" }}
        >{indication.substring(0, truncLength)}
          {indication.length > 295 ? "..." : ""}
          </a> : <React.Fragment key={ID}>{indication.substring(0, truncLength)}
          {indication.length > 295 ? "..." : ""}</React.Fragment>)
        }
      {indication.length > 295 && (
        <button
          type="button"
          id={ID.toString()}
          className="btn btn-link"
          style={{ ...sizeColor, paddingTop: "1px", paddingLeft: "0px" }}
          onClick={() => {
            console.log("read more");
            setReadMore((previous) => !previous);
          }}
        >
          {!readMore ? " Read more" : " Read less"}
        </button>
      )}
    </>
  );
};

export default RegulatoryWatch_ApprovedIndications;
