import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
interface TMEditorProps {
  value: any;
  name: string;
  fieldChanged: Function; //(name:string, value: any) => void;
  parentObject?: any;
  updateObj?: any;
  subPageIndex?: any;
}
const TMEditor = (props: TMEditorProps) => {
  const { value, name, parentObject, fieldChanged, updateObj, subPageIndex } =
    props;
  const editorRef = useRef(null);
  const handleEditorStateChange = (newValue: any) => {
    if (parentObject) {
      let id = name;
      let lastPeriodIndex = id.lastIndexOf(".");

      let detailName = id.substring(lastPeriodIndex + 1);
      console.log(name, parentObject);
      if (
        name == "ReimbursementStatus_Dosing" &&
       
        subPageIndex >= 0
      ) {
        let tempObj = { ...parentObject };
        tempObj[name] = newValue;
        updateObj(tempObj, subPageIndex);
        // parentObject = tempObj
      } else {
        parentObject[name] = newValue;
        fieldChanged();
      }
    } else {
      props.fieldChanged(props.name, newValue);
    }
  };


   // Function to convert blob to base64
   const blobToBase64 = (blob, callback) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      callback(event.target.result);
    };
    reader.readAsDataURL(blob);
  };

  // Custom paste preprocess function
  const pastePreprocess = async (plugin, args) => {
    let content = args.content;
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=|!:,.;]*[-A-Z0-9+&@#\/%=|])/ig;

    // Parse the content into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Handle images
    const images = doc.querySelectorAll('img');
    for (const img of images) {
      const blobUrl = img.src;
      if (blobUrl.startsWith('blob:')) {
        try {
          const response = await fetch(blobUrl);
          const blob = await response.blob();
          const base64 = await new Promise((resolve) => {
            blobToBase64(blob, resolve);
          });
          img.src = base64;
        } catch (error) {
          console.error('Error converting blob to base64:', error);
        }
      }
    }

    // Ensure URLs are converted to links and clean up unwanted attributes
    const links = doc.querySelectorAll('a');
    for (const link of links) {
      const href = link.getAttribute('href');
      if (href && urlRegex.test(href)) {
        // Clean unwanted attributes
        link.removeAttribute('data-ved');
        link.removeAttribute('ping');
        link.removeAttribute('style');
        link.removeAttribute('color');
        link.removeAttribute('-webkit-tap-highlight-color');
        link.removeAttribute('white-space');
        link.removeAttribute('outline');

        // Ensure href is properly set
        link.setAttribute('href', href);
      }
    }

    // Convert text nodes containing URLs to links
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
    let node;
    while ((node = walker.nextNode())) {
      const text = node.nodeValue;
      if (urlRegex.test(text)) {
        const span = document.createElement('span');
        span.innerHTML = text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
        node.parentNode.replaceChild(span, node);
      }
    }

    // Update content with cleaned up HTML
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = doc.body.innerHTML;

    args.content = tempDiv.innerHTML;
  };

  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}

      value={value}
      onEditorChange={(newValue, editor) => handleEditorStateChange(newValue)}
      ref={editorRef}
      init={{
        selector: "textarea#file-picker",
        license_key: 'gpl',
        theme: "silver",
        height: 300,
        toolbar:
          "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link | table help",
        plugins: 'table paste advlist lists link image help wordcount',
        menubar: "file edit format table help",
        browser_spellcheck: true,
        paste_data_images: true,
        paste_block_drop: false,
        paste_as_text: false,
        // paste_retain_style_properties: 'all',
        paste_webkit_styles: 'all',
        paste_merge_formats: false,
        paste_convert_urls: true,
        paste_preprocess: pastePreprocess,
        file_browser_callback: true,
        image_advtab: true,
        image_title: true,
        automatic_uploads: true,
        forced_root_block_attrs: { "style": "margin: 0rem" },
        
        valid_elements: '*[*]', // Allow all elements
        extended_valid_elements: 'table[width|height|border|cellspacing|cellpadding|class|id|style],tr,td[width|height|class|style],th[width|height|class|style]',
        file_picker_types: "file image media",

        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e) => {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener("load", () => {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              const id = "blobid" + new Date().getTime();
              const blobCache = tinymce.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(",")[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
      }}
    />
  );
};

export default TMEditor;
