import React, { useEffect, useState } from "react";
import {
  Field,
  FieldCriteria,
  Section,
  Comment,
} from "../../../../store/DataSourceMetaData";
import AddIcon from "@mui/icons-material/Add";
import FieldView from "../../FieldView";
import { EditorState } from "draft-js";
import { setSelectedLookupGlobe } from "../../../../store/MultiSelectSlice";
import HTARecord_CoaInstrument from "./HTARecord_CoaInstrumentForm";
import DrugData_BrandLicenseForm from "./DrugData_BrandLicenseForm";
import DrugData_LovForm from "./DrugData_LovForm";
import HTARecord_RWE from "./HTARecord_RWE";
import ClinicalData_CoaInstrument from "./ClinicalData_CoaInstrumentForm";
import ClinicalDataArmsAndCohortsForm from "./ClinicalData_ArmAndCohortsForm";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import EndpointsAndResults from "./ClinicalData_EndpointAndResults";
import ApprovedIndications from "./RegulatoryWatch_ApprovedIndication";
import RegulatoryWatch_CoaInstrument from "./RegulatoryWatch_CoaInstrument";
import RegulatoryWatch_PreviouslyApprovedIndications from "./RegulatoryWatch_PreviouslyApprovedIndications";
import ReimbursementStatus_ProductStrengthForm from "./ReimbursementProductStrengthForm";
import HTARecord_ExtrapolationForm from "./HTARecord_ExtrapolationForm";
import Regulatory_DosageAndModeForm from "./Regulatory_DosageAndModeForm";
interface ISubPageForm {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
  formMode: string
}
const SubPageForm = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,
  formMode
}: ISubPageForm) => {
  const [child, setChild] = useState<number[]>([]);
  const [drugDataBrandLicenseRowsNumber, setDrugDataBrandLicenseRowsNumber] =
    useState<any[]>([]);
  const [drugDataLovRowsNumber, setDrugDataLovRowsNumber] = useState<any[]>([]);
  const [productStrengthCheck, setProductStrengthCheck] = useState<boolean>(false)
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  // useEffect(() => {

  // },[selectedLookupGlobe.HTARecord_ExtrapolationMultiselet])

  const dispatch = useAppDispatch();

  const handleClick = (field: string) => {
    if (field === "DrugData_BrandLicense") {
      let tmp = [...drugDataBrandLicenseRowsNumber];
      tmp.push(tmp.length === 0 ? 1 : tmp[tmp.length - 1] + 1);
      setDrugDataBrandLicenseRowsNumber(tmp);
    } else if (field === "DrugData_Lov") {
      let tmp = [...drugDataLovRowsNumber];
      tmp.push(tmp.length === 0 ? 1 : tmp[tmp.length - 1] + 1);
      setDrugDataLovRowsNumber(tmp);
    }
  };

  const deleteRows = (row: number, name: string) => {
    console.log("selectedLookupGlobe", selectedLookupGlobe, "row", row);
    let tmpObj = { ...selectedLookupGlobe };
    if (name === "brandLicence") {
      for (let x in tmpObj) {
        let split = x.split("_");
        if (split[1].includes("BrandLicense")) {
          if (Number(split[split.length - 1]) === row) {
            delete tmpObj[x];
          }
        }
      }
      let tmp = drugDataBrandLicenseRowsNumber;
      let index = tmp.indexOf(row) + 1;
      tmp.splice(index, 1);
      // console.log("tmp", tmp, "tmpObj", tmpObj, index, drugDataBrandLicenseRowsNumber);
      setDrugDataBrandLicenseRowsNumber(tmp);
    } else if (name === "lov") {
      let tmp = drugDataLovRowsNumber;
      let index = tmp.indexOf(row);
      tmp.splice(index, 1);
      console.log("tmp", tmp);
      setDrugDataLovRowsNumber(tmp);
    }
    dispatch(setSelectedLookupGlobe(tmpObj));
  };

  const AddSubPageOnClick = () => {
    if (values[field.name]) {
      //speacial condition for arms and cohorts
      if (field.name == "ArmsCohorts" && values["CohortsPresent"]) {
        const obj = [...values[field.name], { Cohorts: [{}] }];
        fieldChanged(field.name, "", obj);
      } else if (field.name == "ClinicalData_Endpoints") {
        const obj = [
          ...values[field.name],
          { "ClinicalData_Endpoints.Primary": false },
        ];
        fieldChanged(field.name, "", obj);
      } else if(field.name == "ReimbursementStatus_ProductStrength") {
        const pSLength = values[field.name].length - 1
        if(pSLength >=0){
          const productStrength = values[field.name][pSLength];
          const key = "ReimbursementStatus_AdministrationRoute_" + String(pSLength);
          if(Object.keys(productStrength).some((x) => productStrength[x]) || selectedLookupGlobe[key]?.length > 0){
            fieldChanged(field.name, "", [...values[field.name], {}]);
            setProductStrengthCheck(false);
          }else
            setProductStrengthCheck(true);
        } else {
          fieldChanged(field.name, "", [...values[field.name], {}]);
          setProductStrengthCheck(false);
        }
      } else fieldChanged(field.name, "", [...values[field.name], {}]);
    } else {
      //speacial condition for arms and cohorts
      if (field.name == "ArmsCohorts" && values["CohortsPresent"]) {
        const obj = [{ Cohorts: [{}] }];
        fieldChanged(field.name, "", obj);
      } else {
        if (field.name == "ClinicalData_Endpoints") {
          fieldChanged(field.name, "", [
            { "ClinicalData_Endpoints.Primary": false },
          ]);
        } else {
          fieldChanged(field.name, "", [{}]);
        }
      }
    }
  };



  // useEffect(() => {
  //   if (["ArmsCohorts","ClinicalData_Endpoints"].includes(field.name) ) {
  //     AddSubPageOnClick();
  //   }
  // }, []);

  return (
    <>
      {field.name == "COA_Endpoints" && datasourceId == 1 && (
        <div style={{ padding: "15px" }}>
          <HTARecord_CoaInstrument
            field={field}
            values={values}
            fieldChanged={fieldChanged}
            childRows={childRows}
            validateCriteria={validateCriteria}
            RichtextFieldChanged={RichtextFieldChanged}
            datasourcename={datasourcename}
            section={section}
            datasourceId={datasourceId}
            masterFieldTypes={masterFieldTypes}
            openModal={openModal}
          />
        </div>
      )}

      {field.name == "RWE_Datasource" && (
        <div style={{ padding: "15px" }}>
          <HTARecord_RWE
            field={field}
            values={values}
            fieldChanged={fieldChanged}
            childRows={childRows}
            validateCriteria={validateCriteria}
            RichtextFieldChanged={RichtextFieldChanged}
            datasourcename={datasourcename}
            section={section}
            datasourceId={datasourceId}
            masterFieldTypes={masterFieldTypes}
            child={child}
          />
        </div>
      )}

      {
        field.name == "HTA_Extrapolation" &&
        <HTARecord_ExtrapolationForm
          values={values}
          fieldChanged={fieldChanged}
          RichtextFieldChanged={RichtextFieldChanged}
          datasourcename={datasourcename}
          datasourceId={datasourceId}
          formMode={formMode} />
        //HTARecord_ExtrapolationMultiselet
      }
      {/* clinicalData */}
      {field.name == "COA_Endpoints" && datasourceId == 2 && (
        <div style={{ padding: "15px" }}>
          <ClinicalData_CoaInstrument
            field={field}
            values={values}
            fieldChanged={fieldChanged}
            childRows={childRows}
            validateCriteria={validateCriteria}
            RichtextFieldChanged={RichtextFieldChanged}
            datasourcename={datasourcename}
            section={section}
            datasourceId={datasourceId}
            masterFieldTypes={masterFieldTypes}
            openModal={openModal}
          />
        </div>
      )}

      {field.name == "ArmsCohorts" && (
        <ClinicalDataArmsAndCohortsForm
          field={field}
          values={values}
          fieldChanged={fieldChanged}
          childRows={childRows}
          validateCriteria={validateCriteria}
          RichtextFieldChanged={RichtextFieldChanged}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          masterFieldTypes={masterFieldTypes}
          openModal={openModal}
        />
      )}

      {field.name == "ClinicalData_Endpoints" && (
        <EndpointsAndResults
          field={field}
          values={values}
          fieldChanged={fieldChanged}
          childRows={childRows}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          RichtextFieldChanged={RichtextFieldChanged}
        />
      )}

      {field.name === "DrugData_BrandLicense" && (
        <DrugData_BrandLicenseForm
          fieldInfo={field}
          values={values}
          fieldChanged={fieldChanged}
          // childRows={childRows}
          childRows={childRows.filter((x) => x.masterFieldId == field.id)}
          RichtextFieldChanged={RichtextFieldChanged}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          masterFieldTypes={masterFieldTypes}
          // openModal={openModal}
          // validateCriteria={validateCriteria}
          // rowsArray={drugDataBrandLicenseRowsNumber}
          // deleteRows={deleteRows}
        />
      )}

      {field.name === "DrugData_Lov" && (
        <DrugData_LovForm
          fieldInfo={field}
          values={values}
          fieldChanged={fieldChanged}
          childRows={childRows.filter((x) => x.masterFieldId == field.id)}
          RichtextFieldChanged={RichtextFieldChanged}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          masterFieldTypes={masterFieldTypes}
          // rowsArray={drugDataLovRowsNumber}
          // deleteRows={deleteRows}
        />
      )}

      {field.name == "COA_Endpoints" && datasourceId == 3 && (
        <div style={{ padding: "15px" }}>
          <RegulatoryWatch_CoaInstrument
            field={field}
            values={values}
            fieldChanged={fieldChanged}
            childRows={childRows}
            validateCriteria={validateCriteria}
            RichtextFieldChanged={RichtextFieldChanged}
            datasourcename={datasourcename}
            section={section}
            datasourceId={datasourceId}
            masterFieldTypes={masterFieldTypes}
            openModal={openModal}
          />
        </div>
      )}

{field.name == "RegulatoryWatch_DosageAndMode" && (
        <div style={{ padding: "15px" }}>
          <Regulatory_DosageAndModeForm
            field={field}
            values={values}
            fieldChanged={fieldChanged}
            childRows={childRows}
            validateCriteria={validateCriteria}
            RichtextFieldChanged={RichtextFieldChanged}
            datasourcename={datasourcename}
            section={section}
            datasourceId={datasourceId}
            formMode= {formMode}
          />
        </div>
      )}

      {field.name == "RegulatoryWatch_ApprovedIndication" && (
        <ApprovedIndications
          field={field}
          values={values}
          fieldChanged={fieldChanged}
          childRows={childRows}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          RichtextFieldChanged={RichtextFieldChanged}
          masterFieldTypes={masterFieldTypes}
          openModal={openModal}
          validateCriteria={validateCriteria}
        />
      )}
      {field.name == "ReimbursementStatus_ProductStrength" && (
        <ReimbursementStatus_ProductStrengthForm
          field={field}
          values={values}
          fieldChanged={fieldChanged}
          childRows={childRows.filter((x) => x.masterFieldId == field.id)}
          datasourcename={datasourcename}
          section={section}
          datasourceId={datasourceId}
          RichtextFieldChanged={RichtextFieldChanged}
          masterFieldTypes={masterFieldTypes}
          openModal={openModal}
          validateCriteria={validateCriteria}
        />
      )}
      {/* <div style={{ textAlign: "left" }}>
        <label>ReimbursementProductStrength</label>
      </div> */}
      <hr></hr>
      <div className="row">
        {
          field.name == "ReimbursementStatus_ProductStrength" && 
          productStrengthCheck && 
          values[field.name]?.length > 0 &&
          <p className="col-sm-8" style={{ fontSize: "small", color: "red", textAlign: "center", paddingLeft: "400px" }}>
            Please add atlease one value for the product dosage {values[field.name]?.length}
          </p>
        }

        {(field.name !== "HTA_Extrapolation" && field.name !== "RegulatoryWatch_DosageAndMode") &&
          productStrengthCheck &&
          <br />
        }
        {(field.name !== "HTA_Extrapolation" && field.name !== "RegulatoryWatch_DosageAndMode") && 
          <div className="col-sm-12" style={{ textAlign: "right", marginTop: 5, paddingBottom: "50px" }}>
          {/* <button
          type="button"
          className="btn btn-small"
          key={field.name}
          onClick={() => {
            //fieldChanged(field.name, "X");
            AddSubPageOnClick();
            handleClick(field.name);
          }}
        >
          <AddIcon /> Add {getAddButtonLabel(field)}
        </button> */}
          <AddIcon className="subpage-add-icon-style" />&nbsp;

          <a
            href="#"
            key={field.name}
            className="subpage-add-link-style"
            onClick={(e) => {
              e.preventDefault();
              //fieldChanged(field.name, "X");
              AddSubPageOnClick();
              handleClick(field.name);

            }}
          >
            Add {getAddButtonLabel(field)}
          </a>
        </div>}
      </div>

      {field.name == "RegulatoryWatch_ApprovedIndication" && (
        <RegulatoryWatch_PreviouslyApprovedIndications
          datasourceName={datasourcename}
          section={section}
        />
      )}
      {/* <hr></hr> */}
      {field.name == "RWE_Datasource" && (
        <>
          <span>
            <b>RWE critique</b>
          </span>
        </>
      )}
    </>
  );
};

const getAddButtonLabel = (field: Field) => {
  switch (field.name) {
    case "ReimbursementStatus_ProductStrength":
      return " product dosage";
    case "ClinicalData_Endpoints":
      return " endpoint";
    case "DrugData_Lov":
      return " LOE data";
    case "ArmsCohorts":
      return " arm";
    case "COA_Endpoints":
      return "COA instrument";
    case "RWE_Datasource":
      return "RWE source";
    case "RegulatoryWatch_ApprovedIndication":
      return "approved indication";
    default:
      return " " + field.label.replace("-", "");
  }
};

export default SubPageForm;
