// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.google-visualization-tooltip {
  font-size: 10px; /* Adjust the font size here */
  font-weight: 400;   /* Add other styles if needed */
}`, "",{"version":3,"sources":["webpack://./src/components/Charts/charts.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,8BAA8B;EAC/C,gBAAgB,IAAI,+BAA+B;AACrD","sourcesContent":[".google-visualization-tooltip {\n  font-size: 10px; /* Adjust the font size here */\n  font-weight: 400;   /* Add other styles if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
