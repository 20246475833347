import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import DeleteCall from "../service/deleteCall";
import "../../app.css";

interface LinkCellRendererParams extends ICellRendererParams {
  link: string;
  recordIdField: string;
}

export default (props: LinkCellRendererParams) => {
  const recordId = props.data[`${props.recordIdField}`];
  let targetURL = `${props.link}/${recordId}`;
  if(props.link && (props.link.indexOf('list/270') >= 0 || props.link == 'reports/home'))
    targetURL = props.data['Report_Link'];
  if(props.link && props.link.indexOf('RowNumber') >= 0)  
    {
    targetURL = targetURL.replace("RowNumber", props.data.RowNumber)
    }

  return (
    <a target={"_blank"} className="no-underline" href={targetURL} style={{color: "rgb(0, 170, 231)"}} title={props.caption ? props.caption : props.value}>
      {props.caption ? props.caption : props.value}
    </a>
  );
};

interface IDLinkRendererParams extends ICellRendererParams {
  link: string;
  recordId: string;
}


export const IDLink =  (props: IDLinkRendererParams) => {
  const recordId = props.data[`${props.recordId}`];
  let targetURL = `${props.link}/${recordId}`;
  if(props.link && props.link.indexOf('list/270') >= 0)
    targetURL = props.data['Report_Link'];

  return (
    <a target={"_blank"} href={targetURL} style={{color: "rgb(0, 170, 231)"}} title={props.caption ? props.caption : props.value}>
      {props.caption ? props.caption : props.value}
    </a>
  );
};

interface deleteButtonRendererParams extends ICellRendererParams {
  caption: string;
  recordIdField: string;
  name:string
}
export const deleteButton =  (props: deleteButtonRendererParams) => {
  const recordId = props.data[`${props.recordIdField}`];
  // let targetURL = `${props.link}/${recordId}`;
  // if(props.link && props.link.indexOf('list/270') >= 0)
  //   targetURL = props.data['Report_Link'];
  const deleteClientGroup = async() => {
    if(confirm(`Remove Group [${props.data[props.name]}]?`))
    {
      await DeleteCall(`/api/admin/delete/clientGroup/${recordId}`);
      window.location.reload();
    }
  }

  return (
    <button style={{ color: "rgb(0, 170, 231)", border: "none", background: "none", cursor: "pointer" }} onClick={deleteClientGroup} >
      {props?.caption ? props?.caption : props.value}
    </button>
  );
};