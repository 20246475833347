import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './Styles.css';

interface PopupContentProps {
    title: string;
    popupBody: React.ReactNode;
    buttonsArray: String[];
    btnCallbacks: React.MouseEventHandler<HTMLButtonElement>[];
    onPopupClose: React.MouseEventHandler<HTMLButtonElement>;
    keyName? : string
};

const KeyNames = ["SubGroup","ShowRule","NewArticle","diableExport","clientNews"];

export default function PopupContent(props: PopupContentProps){


return (
  <div className="popuoContent">
    <div className="header">
      <span style={{ width: "100%", textAlign: "start", margin: "auto",fontSize:"18px" }} key={props.title}>
        {props.title}
      </span>
      <div style={{ width: "100%", textAlign: "end" }}>
        <button className="popupBtn" onClick={props.onPopupClose}>
          <CloseIcon className="Close-icon" />
        </button>
      </div>
    </div>
    <div
      className="custom_popupBody"
      style={{ minHeight: props.keyName && KeyNames.includes(props.keyName) ? "150px" : "400px" }}
    >
      {props.popupBody}
    </div>
    <div className="footer">
      {props.buttonsArray.length == 0 && (
        <button className="popupBtn" key={1000} onClick={props.onPopupClose}>
          Close
        </button>
      )}
      {props.buttonsArray &&
        props.buttonsArray.length > 0 &&
        props.buttonsArray.map((btn, index) => (
          <React.Fragment key={index+1}>
            {
              index == 0 ? <>
                <button
                  style={{ fontSize: "12px" }}
                  className="popupBtn"
                  key={index}
                  onClick={props.onPopupClose}
                >
                  Cancel
                </button>{" "}
                |{" "}
                <button
                  style={{ fontSize: "12px" }}
                  className="popupBtn"
                  key={index + 1}
                  onClick={props.btnCallbacks[ index ]}
                >
                  {btn}
                </button>
              </> : <>
                &nbsp;&nbsp;|&nbsp;{" "}
                <button
                  style={{ fontSize: "12px" }}
                  className="popupBtn"
                  key={index + 2}
                  onClick={props.btnCallbacks[ index ]}
                >
                  {btn}
                </button>
              </>
            }
          </React.Fragment>
        ))}
    </div>
  </div>
);
};

