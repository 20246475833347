import React, { useEffect, useContext, useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Field, Section } from "../../store/DataSourceMetaData";
import {
  useFetchSearchFieldQuery,
  useFetchSectionMutation,
} from "../../store/DataSourceMetaDataApiSlice";
import { useLocation } from "react-router-dom";
import "./overview.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PathwayChart from "../Charts/PathwayChart";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { useState } from "react";
import MyPieChart from "../Charts/MyPieChart";
import Overview from "./overview";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { RiCloseLine } from "react-icons/ri";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ILookupData } from "../Popup/NewModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  showMultiSelectPopup,
  setSelectedLookupGlobe,
  setLookupGlobe,
  setLookupData,
  setNoRecordFound
} from "../../store/MultiSelectSlice";
import fetchRecord from "../service/fetchRecord";
import {
  saveSearchData,
  setFilterState,
  setPriorFilterState,
  setDataSource,
  setColumnFilters,
  setInitiateSearch,
  setIsSearchGridLoading,
  setDefaultColumnData,
  setFilterColumnTypes,
  setSelectedColumnFiters,
  setIsPathwaySearch,
  setTotalResultCount  
} from "../../store/SearchSlice";
import AdvanceFilter from "./advanceFilter";
import { useGetColumnFilterValuesMutation, useGetPageMutation } from "../../store/SearchResultApiSlice";
import TextField from "@mui/material/TextField";
import apiCall from "../service/apiCall";
import { IselectedLookup, IselectedLookupGlobe } from "../interfaces/multiselect";
import {
  saveFilterNewsData,
  saveNewsData,
  useFilteringClientNewsMutation,
} from "../../store/ClientNewsApiSlice";
import PostCall from "../service/postCall";

import {
  getDatasourceId,
  getIdFieldName,
  byDrugShowColumnsName,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faRefresh, faXmark } from "@fortawesome/free-solid-svg-icons";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { setSearch } from "../../store/pathwaySlice";
import { BsWindowSidebar } from "react-icons/bs";
import { DataSourceListTypes, LookupIds, isCommaSeparatedNumbers } from "../Shared/Utility";
import fetchComplexLookup from "../service/fetchComplexLookup";
import { DatePicker, DatePickerProps } from "antd";
import { CoaInstrumentSelectedItemView } from "../Forms/Utility";
import { debounce } from "../Popup/Utility";


const validateNumber = (elem: any) => {
  let validNumber = new RegExp(/^\d*\.?\d*$/);
  if (validNumber.test(elem)) {
    return elem;
  } else {
    return "";
  }
}
interface filterProps {
  currentDataSource: number;
  currentDataSourceName: string;
  currentSearchDataSource: number;
  savedSearchId: number;
  setSelectedCriteria: any;
  triggerSearchFunction: boolean;
  resetHook: Function;
  currentAccId: number;
  currentRoleId: number;
  savedSearchInfo: any;
  savedSelectionInfo: any;
  savedSelectionId: number;
  setPathwaySearch: (val: boolean) => void;
  freeText: string;
  setFreeText: Function;
  setSearchClicked? : Function;
  setSavedSearchOrSelectionName?: Function;
  setPageSize?: Function;
  setCurrentPage?: Function;
  setSsStatusVal?: Function;
}

export interface IfilterState {
  [name: string]: string | null;
}

export default function Filter(props: filterProps) {
  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const defaultColumn = useAppSelector((state) => state.search.defaultColumn);
  const savedData = useAppSelector((state) => state.multiselect.selectedData);
  const currentUserId = useAppSelector(
    (state) => state.userProfile.currentUserId
  );
  const currentRole = useAppSelector((state) => state.userProfile.currentRole);
  const currentAccountId = useAppSelector(
    (state) => state.userProfile.accountId
  );

  // To get the saved filters state from redux
  const savedFilterState = useAppSelector((state) => state.search.filterState);
  const savedPriorFilterState = useAppSelector((state) => state.search.priorFilterState);
  const preferenceData = useAppSelector((state) => state.search.preferenceData);

  let {
    currentDataSource,
    currentDataSourceName,
    currentSearchDataSource,
    savedSearchId,
    setSelectedCriteria,
    triggerSearchFunction,
    resetHook,
    currentAccId,
    currentRoleId,
    savedSearchInfo,
    savedSelectionInfo,
    savedSelectionId,
    setPathwaySearch,
    freeText = "",
    setFreeText,
    setSearchClicked,
    setSavedSearchOrSelectionName,
    setPageSize,
    setCurrentPage,
    setSsStatusVal,
  } = props;
  const { data: dataBasicSearch } = useFetchSearchFieldQuery({
    dataSourceID: currentSearchDataSource,
    isAdvanceSearch: false,
    roleId: currentRoleId,
  });
  const { data: dataAdvSearch } = useFetchSearchFieldQuery({
    dataSourceID: currentSearchDataSource,
    isAdvanceSearch: true,
    roleId: currentRoleId,
  });
  const [fetchSection, { isLoading, isSuccess }] = useFetchSectionMutation({
    dataSourceID: currentSearchDataSource,
    roleId: currentRoleId,
  });
  const currUserId = Number(sessionStorage.getItem("userid"));
  const [srows, setSrows] = useState<Section[]>([]);
  const [sectionRows, setSectionRows] = useState<Section[]>([]);
  const [rows, setRows] = React.useState<Field[]>([]);
  const [advRows, setAdvRows] = React.useState<Field[]>([]);
  const [dropdownOpen, setOpen] = React.useState(false);
  const [openCriteriaSuccess, setOpenCriteriaSuccess] = React.useState(false);
  const [checkBoxVal, setCheckBoxVal] = React.useState<IfilterState[]>([]);
  //   const [selectedData, setSelectedData] = useState<selData[]>()
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [datePickerValue, setDatePickerValue] = useState<any>({});
  const [startDateValue, setStartDateValue] = useState<Dayjs | null>(null);
  const [endDateValue, setEndDateValue] = useState<Dayjs | null>(null);
  const changeFilter = useAppSelector((state) => state.pathway.changeFilter);
  const dispatch = useAppDispatch();

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const lookupKey = useAppSelector((state) => state.multiselect.lookupKey);
  const searchData = useAppSelector((state) => state.search.searchData);
  // const [freeText, setFreeText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setEditSearchValue("");
    setShow(false);
  }
  const showAdvancedCriteria = () => setShow(true);
  const [clearTextKeyArray, setClearTextKeyArray] = useState<string[]>([]);

  let locationPath = useLocation();
  let path = locationPath.pathname.split("/");
  useEffect(() => {
    dispatch(setLookupData(lookupGlobe[lookupKey]));
  }, [lookupGlobe]);

  const updateFreeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreeText(e.target.value);
  };

  const updateDateCriteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckValue({ ...checkValue, [e.target.name]: e.target.value });
  };
  const [checkValue, setCheckValue] = useState<any>(savedFilterState);
  const [fieldRows, setFieldRows] = useState({});

  useEffect(() => {
    if (triggerSearchFunction) {
      onSearch();
      resetHook();
    }
  }, [triggerSearchFunction]);

  useEffect(() => {
    setCheckValue({});
    setStartDateValue(null);
    setEndDateValue(null);
    setDatePickerValue({});
  }, [currentSearchDataSource]);


  // useEffect(() => {

  // },[selectedLookupGlobe.])

  const onSearch = (searchClicked= false) => {
    dispatch(setFilterColumnTypes({}));
    dispatch(setSelectedColumnFiters({}));
    dispatch(setTotalResultCount(0));
    setSearchClicked && setSearchClicked(searchClicked);
    dispatch(setIsPathwaySearch(false));
    const keyesSavedFilters = Object.keys(savedFilterState);
    const keys = Object.keys(selectedLookupGlobe);
    let filterState: IfilterState = {};
    // setupForSearch(filterState);

    if (currentSearchDataSource == 276) {
      filterState["FreeText"] =
        !freeText || freeText.trim() == "" ? null : freeText;
      // filterState["UserID"] = currentUserId.toString();
      // for (const savedFilterKey in savedFilterState) {
      //   filterState[savedFilterKey] = savedFilterState[savedFilterKey];
      // }
      for (const checkKey in checkValue) {
        if (!filterState.hasOwnProperty(checkKey))
          filterState[checkKey] = checkValue[checkKey];
      }
      for (const key in keys) {
        // ['ClientId', 'DrugIDs', 'DeviceIDs', 'ManufacturerIDs', 'AgencyAbbreviationIDs', 'CountryIDs', 'DiseaseIDs','CategoryIDs']
        let newState = [...selectedLookupGlobe[keys[key]]];

        if (newState.length > 0) {
          let Ids = "";
          newState.forEach((element) => {
            Ids += element["Id"];
            Ids += ",";
          });
          Ids = Ids.substring(0, Ids.length - 1);
          if (Ids === "") {
            if (filterState.hasOwnProperty(keys[key])) {
              delete filterState[keys[key]];
            }
          } else {
            filterState[keys[key]] = Ids;
          }
        }
      }
    }
    if (currentSearchDataSource != 276) {
      setupForSearch(filterState, searchClicked);
      setSelectedCriteria(filterState);
    }

    if (currentSearchDataSource == 276) {
      dispatch(saveFilterNewsData(filterState));
    } else {
      dispatch(setFilterState(filterState));
      dispatch(setPriorFilterState(filterState));
      // dispatch setInitiateSearch and setIsSearchGridLoading
      dispatch(setInitiateSearch(true));
      dispatch(setIsSearchGridLoading(true));
    }
    
    
    setCurrentPage && setCurrentPage(1);
    setPageSize && setPageSize(100);
    initiateSearchResult(filterState, dataSourceId);
    // initiateSearchResult(filterState, currentDataSource);
    if (currentSearchDataSource != 276) {
      setPathwaySearch(false);
      if (path.includes("savedSearch")) {
        setSsStatusVal && setSsStatusVal("existing");
      }
    }
  };

  const initiateSearchResult = (
    filterState: IfilterState,
    dataSourceId: number, 
    fromsavedSearch?: boolean
  ) => {
    if (currentSearchDataSource == 276) {
      GetNewsData(filterState);
    } else {
      GetSearchData({
        searchTerm: "",
        datasourceId: dataSourceId,
        filterData: filterState,
        visibleColumns: fromsavedSearch === true ? [] : defaultColumn.showColumnsNames,
        pathway: false,
        currentPage: 1,
        pageSize:100
      });

      // const fieldRenderMethods = defaultColumn.showColumnsNames.map((colName: string)=> {
      //   const colInfo = defaultColumn.defaultColumnInfo.find(
      //     (x:any) => x.Name == colName
      //   );

      //   return colInfo && colInfo.hasOwnProperty("FieldRenderMethod")
      //   ? colInfo["FieldRenderMethod"]
      //   : null;
      // }) 
      // GetColumFilterForSearch({
      //   accountID: currentAccountId,
      //   datasourceId: dataSourceId,
      //   columns: defaultColumn.showColumnsNames,
      //   colFieldRenderMethod: fieldRenderMethods
      // });
    }
  };

  
  const [getColumnFilterValues] = useGetColumnFilterValuesMutation();
  const GetColumFilterForSearch = async (payload: any) => {
    const colFilter = await getColumnFilterValues(payload);
    // console.log('colFilter: ', colFilter);

    dispatch(setColumnFilters({...colFilter, from: "SearchTable"}));
  }


  const initiateSearchResultForSavedSelection = (
    filterState: IfilterState,
    dataSourceId: number,
    recordIds: string
  ) => {
    GetSearchDataSavedSelection({
      searchTerm: "",
      datasourceId: dataSourceId,
      filterData: filterState,
      visibleColumns: [], //defaultColumn.showColumnsNames, fetch column list in backend based on ds
      recordIds: recordIds,
      pathway: false,
    });
    // if (currentSearchDataSource == 276) {
    //   GetNewsData(filterState);
    // } else {
    //   GetSearchData({
    //     searchTerm: "",
    //     datasourceId: dataSourceId,
    //     filterData: filterState,
    //     visibleColumns: defaultColumn.showColumnsNames,
    //   });
    // }
  };

  const [getPage] = useGetPageMutation();
  const GetSearchData = async (payload: any) => {
    if (path.includes("ByDrug") && payload.visibleColumns.length === 0)
      payload.visibleColumns = [...byDrugShowColumnsName()];

    const searchResult = await getPage(payload);
    dispatch(saveSearchData(searchResult));
    // dispatch(setColumnFilters(searchResult));
  };
  const [filteringClientNews] = useFilteringClientNewsMutation();
  const GetNewsData = async (payload: any) => {
    const paramArray = [
      "AgencyAbbreviationIDs",
      "CountryIDs",
      "DeviceIDs",
      "DrugIDs",
      "ManufacturerIDs",
      "DiseaseIDs",
      "CategoryIDs",
    ];
    const newFilter: any = {};
    paramArray.forEach(function (key) {
      if (payload.hasOwnProperty(key)) {
        newFilter[key] = payload[key];
      } else {
        newFilter[key] = null;
      }
    });

    const searchNewsResult = await filteringClientNews({
      ...newFilter,
    });
    dispatch(saveNewsData(searchNewsResult.data));
  };

  const setupForSearch = (filterState: IfilterState, searchClicked = false ) => {
    let newSelectedglobeData = {...selectedLookupGlobe};
    if([1,3].includes(currentDataSource)){
      if(newSelectedglobeData.hasOwnProperty('TherapeuticAreaIDs')){
        delete newSelectedglobeData.TherapeuticAreaIDs;
      }
      if(newSelectedglobeData.hasOwnProperty('DiseaseIDs')){
        delete newSelectedglobeData.DiseaseIDs;
      }
      dispatch(setSelectedLookupGlobe(newSelectedglobeData));
    }
    
    let keys = Object.keys(newSelectedglobeData);
    
    //filterState["UserID"] = currentUserId.toString();
    for (const savedFilterKey in savedPriorFilterState) {
      filterState[savedFilterKey] = savedPriorFilterState[savedFilterKey];
    }
    // if(searchClicked) //Commented as filter saving is not working when just putting the value and saving the filter. 
      filterState["FreeText"] =
        !freeText || freeText.trim() == "" ? null : freeText;
    for (const checkKey in checkValue) {
      // if (!filterState.hasOwnProperty(checkKey))
      filterState[checkKey] = checkValue[checkKey];
    }
    for (const key in keys) {
      let newState = [...newSelectedglobeData[keys[key]]];
      if (newState.length > 0) {
        let Ids = "";
        newState.forEach((element) => {
          Ids += element["Id"];
          Ids += ",";
        });
        Ids = Ids.substring(0, Ids.length - 1);
        if (Ids === "") {
          if (filterState.hasOwnProperty(keys[key])) {
            delete filterState[keys[key]];
          }
        } else {
          filterState[keys[key]] = Ids;
        }
      }
      else {
        delete filterState[keys[key]];
      }
    }
    if (filterState["FreeText"] != null && [1,2,4,5,6,7].includes(currentDataSource)) {
      let defColumnInfo: any;
      defColumnInfo = {...defaultColumn};
      if (!defColumnInfo.defaultColumnInfo.some(e => e.Name == "RelevanceScore")) {
        let relCols = [...defColumnInfo.defaultColumnInfo];
        relCols.push({
          ID: 9998,
          Name: "RelevanceScore",
          Label: "Relevance score",
          Category_ID: null,
          FieldRenderMethod: '',
        }
        , {
          ID: 9999,
          Name: "RelevanceScoreSum",
          Label: "Relevance score sum",
          Category_ID: null,
          FieldRenderMethod: '',
        }
      );
        defColumnInfo.defaultColumnInfo = relCols;
        defColumnInfo.preferenceData = preferenceData;
        dispatch(setDefaultColumnData(defColumnInfo));
      }
    }
    if (defaultColumn.defaultColumnInfo.some(e => e.Name == "RelevanceScore") && 
      filterState["FreeText"] == null && 
      [1,2,4,5,6,7].includes(currentDataSource)) {
        let defColumnInfo: any;
        defColumnInfo = {...defaultColumn};
        let relCols = [...defColumnInfo.defaultColumnInfo];
        relCols = relCols.filter((c) => {
          return c.Name.toLowerCase().indexOf("relevance")
        })
        defColumnInfo.defaultColumnInfo = relCols;
        defColumnInfo.preferenceData = preferenceData;
        dispatch(setDefaultColumnData(defColumnInfo));
      }
  };

  const GetSearchDataSavedSelection = async (payload: any) => {
    if (path.includes("ByDrug") && payload.visibleColumns.length === 0)
      payload.visibleColumns = [...byDrugShowColumnsName()];

    const searchResult = await getPage(payload);
    dispatch(saveSearchData(searchResult));
    dispatch(setColumnFilters(searchResult));
  };
  const buttonClick = (
    lookup_id: number,
    lookup_key: string,
    title: string,
    fieldTypeId: number
  ) => {
    if (fieldTypeId != 4) {
      dispatch(showMultiSelectPopup(lookup_id, true, lookup_key, title, false));
    }   

    if (!lookupGlobe.hasOwnProperty(lookup_key) || lookupGlobe[lookup_key].length ==0 || lookup_id == LookupIds.ProductDosage) {
      let newLookupGlobe;
      if (
        (lookup_key == "CountryIDs" &&
        lookup_id == LookupIds.DS_CountryPricing) || (lookup_key == "DrugIDs" &&
        lookup_id == LookupIds.DS_DrugPricing) || (lookup_key == "PackSizes" &&
        lookup_id == LookupIds.DS_PackSize)
      ) {
        const CountryIds = selectedLookupGlobe?.CountryIDs?.map((el) => el.Id) || [];
        const DrugIds = selectedLookupGlobe?.DrugIDs?.map((el) => el.Id) || [];
        const PricingIds = selectedLookupGlobe?.PackSizes?.map((el) => el.Id) || []; 
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          dataSourceId,
          0,
          0,
          "",
          CountryIds,
          DrugIds,
          PricingIds
        );
        // let keys = ["CountryIDs","DrugIDs","PackSizes"];
        // if(keys.includes(lookup_key)){
        //   keys = keys.filter((x) => x != lookup_key);
        //   keys.forEach((el) => _lookupGlobe[el] = []);
        // }
      } else if (lookup_id == LookupIds.DS_Country_RegulatoryBodyAbbr) {
        const searchType = "Regulator"; 
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          dataSourceId,
          0,
          0,
          searchType,
          [],
          [],
          []
        );
      } else if (lookup_id == LookupIds.DS_Country_ReimbursementAbbr) {
        const searchType = "Reimbursement"; 
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          dataSourceId,
          0,
          0,
          searchType,
          [],
          [],
          []
        );
      } else if (lookup_id == LookupIds.DS_Country_PricingBodyAbbr) {
        const searchType = "Pricing"; 
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          dataSourceId,
          0,
          0,
          searchType,
          [],
          [],
          []
        );
      } else if (lookup_id == LookupIds.DS_Country_HTABody) {
        const searchType = "HTA"; 
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          10,
          0,
          0,
          searchType,
          [],
          [],
          []
        );
      } else if (lookup_id == LookupIds.ProductDosage) {
        const CountryIds = selectedLookupGlobe?.CountryIDs?.map((el) => el.Id) || [];
        const DrugIds = selectedLookupGlobe?.DrugIDs?.map((el) => el.Id) || [];
        newLookupGlobe = fetchComplexLookup(
          lookup_id,
          dataSourceId,
          0,
          0,
          "",
          CountryIds,
          DrugIds,
          []
        );
      } else {
        newLookupGlobe = fetchRecord(lookup_id, dataSourceId);
      }
      newLookupGlobe.then((result) => {
        if (result.length == 0) dispatch(setNoRecordFound(true));
        let newlookupGlobe = { ...lookupGlobe, [lookup_key]: result };
        dispatch(setLookupGlobe(newlookupGlobe));
      });

    }
  };

  const filterChanged = (_rows: any) => {
    setRows(_rows);
    if(currentDataSource == 4 && !("DataVersion" in savedFilterState)){
      dispatch(
        setFilterState({ ...savedFilterState, ["DataVersion"]: 2 })
      );
    }
  };

  const clearFilter = () => {
    dispatch(setSelectedLookupGlobe({}));
    dispatch(setFilterState({}));
    dispatch(setPriorFilterState({}));
    dispatch(setLookupGlobe({}));
    setFreeText("");
    if (clearTextKeyArray.length > 0) {
      let _checkValue = checkValue;
      for (let key of clearTextKeyArray) {
        // _checkValue = { ..._checkValue, [key]: null };
        delete _checkValue[key];
        document.getElementsByName(key)[0].value = "";
      }
      setCheckValue(_checkValue);
    }
    setCheckValue({});
    if (dataBasicSearch) {
      setRows(dataBasicSearch || []);
      setDatePickerValue({});
    }
  };

  const saveFilter = () => {
    let filterState: IfilterState = {};
    setupForSearch(filterState);
    Object.entries(filterState).forEach(([key, val]) => {
      
      // if (val != null && (typeof val == "boolean" || key == "FreeText")) {
      //   filterState[key] = val;
      // } else if (val != null && (!isNaN(val) || val.indexOf(",") > 0)) {
      //   filterState[key] = val.split(",").map(Number);
      // }
      if (val != null && (typeof val == "boolean" || key === "FreeText" || !isCommaSeparatedNumbers(val)) ) {
        filterState[key] = val;
      } else if (val != null && !isNaN(val)) {
        filterState[key] = Number(val);
      } else if (val != null && (!isNaN(val) || val.indexOf(",") > 0)) {
        if (val.length >= 1) {
          filterState[key] = val.split(",").map(Number);
        } else {
          if(val.length == 0){
            delete filterState[key];
          }
          if(val[0] != '')
          filterState[key] = val[0];
        };
      }

      if (
        val != null &&
        val === "" &&
        key.toLowerCase().includes("date")
      ) {
        delete filterState[key];
      }
    });
    initiateSaveCriteria(filterState);
  };

  const handleCloseCriteria = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenCriteriaSuccess(false);
  };

  const initiateSaveCriteria = async (filterState: IfilterState) => {
    let ssName: string = "";
    let ssDesc: string = "";
    let alertChecked: boolean = false;
    let isActive: boolean = true;
    let dataSourceId: number = currentDataSource;
    let currUserId: number = currentUserId;
    let selectedUser: number = currentUserId;
    let selectedUserAccount: number = currentAccId;
    let selectedClient: number = null;
    let selectedClientGroup: number = null;
    let roleId: number = 0;
    let isCriteria: boolean = true;

    let criteria = JSON.stringify(filterState);
    let response = await PostCall(`/api/admin/createSaveSearch/`, {
      ssName,
      ssDesc,
      dataSourceId,
      alertChecked,
      isActive,
      currUserId,
      selectedUser,
      selectedUserAccount,
      selectedClient,
      selectedClientGroup,
      criteria,
      roleId,
      isCriteria,
    });
    if (!!response) {
      setOpenCriteriaSuccess(true);
    }
  };
  const [aRows, setARows] = React.useState<Field[]>([]);
  const getSpecificSavedSearch = () => {
    if (
      path.includes("ByDrug") || 
      path.includes("ByDrugClinical") || 
      path.includes("ByDrugRegulatory") || 
      path.includes("ByDrugPricing")) {
      return false;
    }
    if (savedSearchId) {
      dispatch(setFilterColumnTypes({}));
      dispatch(setSelectedColumnFiters({}));
      dispatch(setIsPathwaySearch(false));
      dispatch(setSearch(true));
      dispatch(setSelectedLookupGlobe({}));
    }

    let isSavedCriteria: boolean = true;
    if (isNaN(savedSearchId)) savedSearchId = 0;
    if (
      // !isNaN(savedSearchId) &&
      // typeof savedSearchId === "number" &&
      // savedSearchId > 0
      currentAccountId > 0
    ) {
      if (
        !isNaN(savedSearchId) &&
        typeof savedSearchId === "number" &&
        savedSearchId > 0
      ) {
        isSavedCriteria = false;
        setRows(dataBasicSearch);
      } else {
        savedSearchId = 0;
      }
      apiCall(
        `/api/getSpecificSavedSearch/${savedSearchId}/${currentAccId}/${currentDataSource}/${isSavedCriteria}`
      ).then((response) => {
        // if (
        //   response.data[0] &&
        //   currentDataSource != JSON.parse(response.data[0]?.DatasourceID)
        // ) {
        //   setPathwaySearch(false);
        //   return false;
        // }
        
        let ssData: any;
        if (response.data && response.data.saved_search_data[0]) {
          setSavedSearchOrSelectionName && setSavedSearchOrSelectionName(response.data.saved_search_data[0].Name);
          ssData = JSON.parse(response.data.saved_search_data[0].SearchCriteriaBlob);
          let ssDs = JSON.parse(response.data.saved_search_data[0].DatasourceID);
          let filterState: IfilterState = {};
          let ssInfo: any = {};
          let newSelectedglobeData: IselectedLookupGlobe = {};
          if (response.data.saved_search_data[0].DatasourceID != dataSourceId) {
            dispatch(setDataSource(ssDs));
          }
          ssInfo = {
            sData: response.data.saved_search_data[0],
          };
          savedSearchInfo(ssInfo);
          //let selectedLocalData: IselectedLookup[] = [];
          let advFieldsForss: any[] = [];
          for (const checkKey in ssData) {
            let Ids = "";
            if (ssData[checkKey] && ssData[checkKey] != null) {
              let newSelectedData: IselectedLookup[] = [];

              let filterAdvField: any;
              let filterAdvanceDateFields: any;
              let filterAdvanceCheckBoxFields: any;
              let filterAdvanceInputFields: any;

              if (!!dataAdvSearch && dataAdvSearch.length > 0) {
                filterAdvField = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId !== 31
                );
                filterAdvanceDateFields = dataAdvSearch.filter(
                  (element) =>
                    checkKey.toLowerCase().indexOf(element.name.toLowerCase()) >= 0 &&
                    element.fieldTypeId == 39 
                    // && element.name.toLowerCase().indexOf("date") >= 0   //commenting this as it is not working for "status since" field
                );
                filterAdvanceCheckBoxFields = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 10
                );
                filterAdvanceInputFields = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 1
                );
              }
              if (filterAdvField != undefined && filterAdvField.length > 0) {
                setRows((currentFiltered) => [
                  ...currentFiltered,
                  ...filterAdvField.filter((x) =>
                    currentFiltered.every((obj) => {
                      return obj.name !== x.name;
                    })
                  ),
                ]);
              }
              if (filterAdvanceDateFields != undefined && filterAdvanceDateFields.length > 0) {
                setRows((currentFiltered) => [
                  ...currentFiltered,
                  ...filterAdvanceDateFields.filter((el) =>
                    currentFiltered.every((obj) => {
                      return obj.id !== el.id;
                    })
                  ),
                ]);
              }
              response.data.ss_obj.length > 0 && response.data.ss_obj.forEach((x: any) => {
                if (checkKey === x.field_name && typeof(x.field_value) == 'object' ) {
                  x.field_value && x.field_value.length > 0 && x.field_value.forEach((y: any) => {
                    let newSelDat: IselectedLookup = {
                      Id: y["ID"],
                      Value: y["Value"],
                    };
                    newSelectedData = [...newSelectedData, newSelDat];
                  });
                  if(newSelectedData.length > 0)
                    newSelectedglobeData = {
                      ...newSelectedglobeData,
                      [checkKey]: newSelectedData,
                    };
                }
                // console.log(x.field_name)
              });
              if(Array.isArray(ssData[checkKey]))
                ssData[checkKey].length > 0 ? filterState[checkKey] = ssData[checkKey] : null;
              else
               if(checkKey !== 'UserSubscriptionModel')
                filterState[checkKey] = ssData[checkKey];
            }
          }
          dispatch(setSelectedLookupGlobe(newSelectedglobeData));

          if (isSavedCriteria == true) {
            // dispatch(setDataSource(ssDs));
            dispatch(setFilterState(filterState));
            dispatch(setPriorFilterState(filterState));
            if(filterState.hasOwnProperty("FreeText"))
              setFreeText(filterState["FreeText"]);
          }
          if (savedSearchId > 0) {
            dispatch(setFilterState(filterState));
            dispatch(setPriorFilterState(filterState));
            if(filterState.hasOwnProperty("FreeText")){
              setFreeText(filterState["FreeText"]);
            }
            else {
              setFreeText("");
            }
            dispatch(setInitiateSearch(true));
            dispatch(setIsSearchGridLoading(true));
            setPathwaySearch(false);
            const keys = Object.keys(newSelectedglobeData);
            // filterState["FreeText"] =
            //   !freeText || freeText.trim() == "" ? null : freeText;
            for (const key in keys) {
              let newState = [...newSelectedglobeData[keys[key]]];
              if (newState.length > 0) {
                let Ids = "";
                newState.forEach((element) => {
                  Ids += element["Id"];
                  Ids += ",";
                });
                Ids = Ids.substring(0, Ids.length - 1);
                if (Ids === "") {
                  if (filterState.hasOwnProperty(keys[key])) {
                    delete filterState[keys[key]];
                  }
                } else {
                  filterState = { ...filterState, [keys[key]]: Ids };
                }
              }
            }
            initiateSearchResult(filterState, ssDs, true);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (savedSearchId > 0 && dataAdvSearch?.length > 0) { 
      dispatch(setSelectedLookupGlobe({}));
      dispatch(setFilterColumnTypes({}));
      dispatch(setSelectedColumnFiters({}));
      dispatch(setIsPathwaySearch(false)); 
      const isSavedSelection = path.includes("savedSelection")
      !isSavedSelection && getSpecificSavedSearch();
    }
    const isSavedSelection = path.includes("savedSelection") || path.includes("savedSearch");
    !isSavedSelection && getSpecificSavedSearch();
  }, [currentAccId, dataAdvSearch, savedSearchId]);

  useEffect(() => {
    const isSavedSelection = path.includes("savedSelection");
    if (
      !isNaN(savedSelectionId) &&
      typeof savedSelectionId === "number" &&
      savedSelectionId > 0 &&
      isSavedSelection
    ) {
      apiCall(`/api/getSpecificSelectionData/${savedSelectionId}`).then(
        (response) => {
          let datasourceId = getDatasourceId(response.data.result[0]["Entity_Type"]);
          console.log("datasourceId from filter file : ",datasourceId);
          setSavedSearchOrSelectionName && setSavedSearchOrSelectionName(response.data.result[0].SelectionName);
          let recordIds = response.data.result[0]["Selection"];
          let ssData = JSON.parse(response.data.result[0].SearchCriteriaBlob);
          let ssInfo: any = {};

          let newSelectedglobeData = {};
          if (getDatasourceId(response.data.result[0]["Entity_Type"]) != currentDataSource) {
            dispatch(setDataSource(datasourceId));
            setPathwaySearch(false);
          }
          ssInfo = {
            sData: response.data.result[0],
          };

          savedSelectionInfo(ssInfo);
          let filterState: IfilterState = {};
          for (const checkKey in ssData) {
            let Ids = "";
            if (ssData[checkKey] && ssData[checkKey] != null) {
              let newSelectedData: IselectedLookup[] = [];

              let filterAdvField: any;
              let filterBasicField: any;
              let filterBasicDateFields: any;
              let filterAdvanceDateFields: any;
              let filterBasicCheckBoxFields: any;
              let filterAdvanceCheckBoxFields: any;
              let filterBasicInputFields: any;
              let filterAdvanceInputFields: any;

              if (!!dataAdvSearch && dataAdvSearch.length > 0) {
                filterAdvField = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId !== 31
                );
                filterAdvanceDateFields = dataAdvSearch.filter( 
                  (element) =>
                    checkKey
                      .toLowerCase()
                      .indexOf(element.name.toLowerCase()) >= 0 &&
                    element.fieldTypeId == 39 &&
                    element.name.toLowerCase().indexOf("date") >= 0
                );
                filterAdvanceCheckBoxFields = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 10
                );
                filterAdvanceInputFields = dataAdvSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 1
                );
              }

              if (!!dataBasicSearch && dataBasicSearch.length > 0) {
                filterBasicField = dataBasicSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId != 31
                );
                filterBasicDateFields = dataBasicSearch.filter(
                  (element) =>
                    checkKey
                      .toLowerCase()
                      .indexOf(element.name.toLowerCase()) >= 0 &&
                    element.fieldTypeId == 39 &&
                    element.name.toLowerCase().indexOf("date") >= 0
                );
                filterBasicCheckBoxFields = dataBasicSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 10
                );
                filterBasicInputFields = dataBasicSearch.filter(
                  (x) => x.name == checkKey && x.fieldTypeId == 1
                );
              }
              response.data.ss_obj.length > 0 && response.data.ss_obj.forEach((x: any) => {
                if (checkKey === x.field_name && typeof(x.field_value) == 'object' ) {
                  x.field_value && x.field_value.length > 0 && x.field_value.forEach((y: any) => {
                    let newSelDat: IselectedLookup = {
                      Id: y["ID"],
                      Value: y["Value"],
                    };
                    newSelectedData = [...newSelectedData, newSelDat];
                  });
                  if(newSelectedData.length > 0)
                    newSelectedglobeData = {
                      ...newSelectedglobeData,
                      [checkKey]: newSelectedData,
                    };
                }
              });
              if (filterBasicDateFields && filterBasicDateFields.length > 0) {
                filterState[checkKey] = ssData[checkKey];
              }
              if (filterBasicInputFields && filterBasicInputFields.length > 0) {
                filterState[checkKey] = ssData[checkKey];
              }
              if (
                filterBasicCheckBoxFields &&
                filterBasicCheckBoxFields.length > 0
              ) {
                filterState[checkKey] = ssData[checkKey];
              }
              if (
                filterAdvanceInputFields &&
                filterAdvanceInputFields.length > 0
              ) {
                filterState[checkKey] = ssData[checkKey];
              }
              if (
                filterAdvanceCheckBoxFields &&
                filterAdvanceCheckBoxFields.length > 0
              ) {
                filterState[checkKey] = ssData[checkKey];
              }
              if (
                filterAdvanceDateFields &&
                filterAdvanceDateFields.length > 0
              ) {
                filterState[checkKey] = ssData[checkKey];

                setRows((currentFiltered) => [
                  ...currentFiltered,
                  ...filterAdvanceDateFields.filter((el: any) =>
                    currentFiltered.every((obj) => {
                      return obj.name !== el.name;
                    })
                  ),
                ]);
              }
            }
          }
          dispatch(setSelectedLookupGlobe(newSelectedglobeData));
         
          if (savedSelectionId > 0) {
            dispatch(setDataSource(datasourceId));
            dispatch(setFilterState(filterState));
            dispatch(setPriorFilterState(filterState));
            if(filterState.hasOwnProperty("FreeText")){
              setFreeText(filterState["FreeText"]);
            } 
            else {
              setFreeText("");
            }
            dispatch(setInitiateSearch(true));
            dispatch(setIsSearchGridLoading(true));
            setPathwaySearch(false);
            debouncedSearch(
              filterState,
              datasourceId,
              recordIds
            );
          }
        }
      );
    }
  }, [currentAccId, dataBasicSearch, dataAdvSearch, savedSelectionId]);

  const debouncedSearch = useCallback(
    debounce((filterState:any,datasourceId:number,recordIds:string) => {
      // API call or any heavy operation
      initiateSearchResultForSavedSelection(
        filterState,
        datasourceId,
        recordIds
      );
    }, 4000),
    []
  );

  const GetSectionData = async (payload: any) => {
    let sectionData = [];
    let result = await fetchSection(payload);
    if (result && result.data) {
      sectionData = result.data;
      if (sectionData) {
        let _rows = [];

        _rows = sectionData.filter(
          (x) =>
            x.name != "Record details" &&
            x.name.toLowerCase() != "basic search" &&
            x.name.toLowerCase() != "search results" &&
            x.name.toLowerCase() != "risk management plans"
            // x.name.toLowerCase() != "disease" &&
            // x.name.toLowerCase() != "related links"
        );

        setSrows(_rows);
        setSectionRows(_rows);
      }
    }
  };

  useEffect(() => {
    GetSectionData({
      dataSourceID: currentSearchDataSource,
      roleId: currentRoleId,
    });
  }, [currentSearchDataSource, currentRoleId]);

  useEffect(() => {
    if (dataBasicSearch) {
      setRows(dataBasicSearch || []);
    }
  }, [dataBasicSearch]);

  useEffect(() => {
    if (dataAdvSearch) {
      setAdvRows(dataAdvSearch || []);
    }
  }, [dataAdvSearch]);

  const [deafultActiveKeys, setDefaultActiveKeys] = useState<string[]>([]);
  const [editSearchValue, setEditSearchValue] = useState<string>("");
  const OnEditFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value.toLowerCase();
    console.log("searchString : ", searchString);
    setEditSearchValue(searchString);
    if (searchString == "") {
      clearInput();
    } else {
      let newRows = dataAdvSearch || [];
      newRows = newRows.filter((x) =>
        x.label.toLowerCase().includes(searchString)
      );
      const sectionIds = newRows.map((item) => item.sectionId);
      const filteredSections = sectionRows.filter((x) =>
        sectionIds.includes(x.id)
      );
      setDefaultActiveKeys(filteredSections.map((x) => x.name));
      setSrows(filteredSections);
      setAdvRows(newRows);
    }
  };

  const clearInput = () => {
    setEditSearchValue("");
    setDefaultActiveKeys([]);
    dataAdvSearch && setAdvRows(dataAdvSearch || []);
    setSrows(sectionRows);
  };

  const customFormat = 'DD-MM-YYYY';
  const selectStartDate = (value: any, id: number, name: string) => {
    // console.log(value, "value", id);
    if (value !== null) {
      let obj = datePickerValue;
      obj[name + "Start"] = value;
      setDatePickerValue({
        ...datePickerValue,
        obj,
      });
      console.log("In Filter values", datePickerValue)
      let date = createDate(value);
      setCheckValue({ ...checkValue, [name + "Start"]: date });
      // setCheckValue({ ...checkValue, DateOfDecisionStart: date });
      // To update the date value in redux store
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Start"]: date }));
    } else {
      // delete savedFilterState[name + "Start"];
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Start"]: "" }));
      setCheckValue({ ...checkValue, [name + "Start"]: "" });
    }
  };

  const selectEndDate = (value: any, id: number, name: string) => {
    if (value !== null) {
      let obj = datePickerValue;
      obj[name + "End"] = value;
      setDatePickerValue({
        ...datePickerValue,
        obj,
      });
      let date = createDate(value);
      setCheckValue({ ...checkValue, [name + "End"]: date });
      // setCheckValue({ ...checkValue, DateOfDecisionEnd: date });
      //To update the date value in redux store
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "End"]: date }));
    } else {
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "End"]: "" }));
      setCheckValue({ ...checkValue, [name + "End"]: "" });
    }
  };

  const createDate = (value: any) => {
    let day = value.$D;
    let month = value.$M + 1;
    let year = value.$y;
    let date = `${month}-${day}-${year}`;
    return date;
  };

  const criteriaChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === true) {
      console.log("e.target.checked : ", e.target.checked);
      setCheckValue({ ...checkValue, [e.target.name]: e.target.checked });
      dispatch(
        setPriorFilterState({
          ...savedPriorFilterState,
          [e.target.name]: e.target.checked,
        })
      );
    }

    if (e.target.checked === false) {
      console.log("!e.target.checked : ", e.target.checked);
      setCheckValue({ ...checkValue, [e.target.name]: null });
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [e.target.name]: null }));
    }
  };

  const updateTextCriteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckValue({ ...checkValue, [e.target.name]: e.target.value });
    if (!clearTextKeyArray.includes(e.target.name)) {
      setClearTextKeyArray([...clearTextKeyArray, e.target.name]);
    }
    dispatch(
      setPriorFilterState({ ...savedPriorFilterState, [e.target.name]: e.target.value })
    );
  };
  const updateTextNumberCriteria = (e: React.ChangeEvent<HTMLInputElement>) => {
    
      let { value } = e.target;
      if (value) {
        value = validateNumber(value);
        // value = value.replace(/\D/g, "");
      }
    
    setCheckValue({ ...checkValue, [e.target.name]: value });
    if (!clearTextKeyArray.includes(e.target.name)) {
      setClearTextKeyArray([...clearTextKeyArray, e.target.name]);
    }
    dispatch(
      setPriorFilterState({ ...savedPriorFilterState, [e.target.name]: value })
    );
  };

  const removeFilter = (
    objName: string,
    obj: { Id: number; Value: string }
  ) => {
    console.log("objName",objName);
    let newSelectedglobeData = { ...selectedLookupGlobe };
    newSelectedglobeData[objName] = newSelectedglobeData[objName].filter(
      (x: any) => x.Id !== obj.Id
    );
    dispatch(setSelectedLookupGlobe(newSelectedglobeData));

    let newSavedFilterState = { ...savedPriorFilterState };
    if (
      Object.entries(newSavedFilterState).length > 0 &&
      !!newSavedFilterState[objName]
    ) {
      if (Array.isArray(newSavedFilterState[objName])) {
        newSavedFilterState[objName] = newSavedFilterState[objName]?.filter(
          (x: any) => x !== obj.Id
        );
      } else delete newSavedFilterState[objName];
      // dispatch(setFilterState(newSavedFilterState));
      dispatch(setPriorFilterState(newSavedFilterState));
    }

    
    let keys = ["CountryIDs", "DrugIDs", "PackSizes", "ProductDosageIDs"];
    if (keys.includes(objName) && dataSourceId == DataSourceListTypes.Pricing) {
      let _lookupGlobe = {...lookupGlobe};
      keys = keys.filter((x) => x != lookupKey);
      keys.forEach((el) => (_lookupGlobe[el] = []));
      dispatch(setLookupGlobe(_lookupGlobe));
    }
  };

  const selectMinRange = (value: any, id: number, name: string) => {
    // console.log(value, "value", id);
    if (value !== null) {
      let date = createDate(value);
      setCheckValue({ ...checkValue, [name + "Min"]: value });
      // To update the date value in redux store
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Min"]: value }));
    } else {
      // delete savedFilterState[name + "Start"];
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Min"]: "" }));
      setCheckValue({ ...checkValue, [name + "Min"]: "" });
    }
  };

  const selectMaxRange = (value: any, id: number, name: string) => {
    // console.log(value, "value", id);
    if (value !== null) {
      let date = createDate(value);
      setCheckValue({ ...checkValue, [name + "Max"]: value });
      // To update the date value in redux store
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Max"]: value }));
    } else {
      // delete savedFilterState[name + "Start"];
      dispatch(setPriorFilterState({ ...savedPriorFilterState, [name + "Max"]: "" }));
      setCheckValue({ ...checkValue, [name + "Max"]: "" });
    }
  };

  useEffect(() => {
    if (dataSourceId === 2) {
      if (changeFilter === 0) {
        let n_rows = rows.filter(
          (x) => x.name !== "DiseaseIDs" && x.name !== "TherapeuticAreaIDs"
        );
        setRows(n_rows);
      } else if (changeFilter === 1) {
        const items: Field = {
          id: 1586,
          name: "TherapeuticAreaIDs",
          sectionId: 90,
          sortOrder: 2,
          label: "Therapeutic area",
          description: "",
          descriptionClient: "",
          required: false,
          readOnly: true,
          visible: true,
          isRemoved: false,
          isSummary: false,
          relatedDataType: null,
          displaySource: null,
          displayIdName: null,
          displayFieldName: null,
          fieldInfo: null,
          categoryId: null,
          masterFieldId: null,
          externallyvisible: true,
          fieldTypeId: 7,
          lookupId: 120,
        };
        let n_rows = rows.filter(
          (x) => x.name !== "DiseaseIDs" && x.name !== "TherapeuticAreaIDs"
        );
        setRows([...n_rows, items]);
      } else if (changeFilter === 2) {
        const items: Field = {
          id: 1587,
          name: "DiseaseIDs",
          sectionId: 90,
          sortOrder: 3,
          label: "Disease",
          description: "",
          descriptionClient: "",
          required: false,
          readOnly: true,
          visible: true,
          isRemoved: false,
          isSummary: false,
          relatedDataType: null,
          displaySource: null,
          displayIdName: null,
          displayFieldName: null,
          fieldInfo: null,
          categoryId: null,
          masterFieldId: null,
          externallyvisible: true,
          fieldTypeId: 7,
          lookupId: 170,
        };
        setRows([...rows, items]);
      }
    } else {
      if (!path.includes("Search")) {
        dispatch(setSelectedLookupGlobe({}));
      }
    }
  }, [changeFilter]);

  return (
    <>
      <h5 className="custom-h5" style={{ marginLeft: "0.5em", marginTop:"2px"}}>
        Search{" "}
        {currentDataSourceName == "HTA"
          ? currentDataSourceName
          : currentDataSourceName.toLowerCase()}
      </h5>
      <div className="left-filter-menu" style={{marginTop:"11px"}}>
        {currentSearchDataSource && currentSearchDataSource != 276 ? (
          <>
            <div style={{ marginBottom: "15px" }}>
              <input
                type="text"
                id="FreeText"
                name="FreeText"
                placeholder="Enter search terms"
                style={{
                  height: "27px",
                  width: "11em",
                  verticalAlign: "bottom",
                  marginLeft: "0.7em",
                  border: "1px solid #d5d5d5",
                  paddingLeft: "5px",
                }}
                value={freeText}
                onChange={updateFreeText}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onSearch(true);
                  }
                }}
              />
              <button
                type="submit"
                className="btn"
                data-source="1"
                style={{
                  verticalAlign: "bottom",
                  padding: "0",
                  backgroundColor: "rgb(0, 170, 231)",
                  color: "#FFF",
                  marginLeft: "-1px",
                  borderRadius: "inherit",
                  height: 27,
                }}
                onClick={() => onSearch(true)}
              >
                <div className="search-box">
                {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SearchIcon"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                   */}
                  <SearchIcon />
                </div>
              </button>
            </div>
            <div
              style={{
                display: "inline !important",

                fontWeight: "bold",
              }}
              className="row justify-content-between"
            >
              <div style={{ paddingTop: 13, fontSize: 13, paddingLeft: 23 }}>
                Filters
              </div>
              <Dropdown
                color="link"
                className="float-right"
                toggle={() => {
                  setOpen(!dropdownOpen);
                }}
                isOpen={dropdownOpen}
                style={{ border: "none" }}
              >
                <DropdownToggle
                  tag="button"
                  className="dropdown-toggle btn-link iqvia-color"
                  style={{ border: "none" }}
                >
                  <MoreVertIcon
                    style={{
                      marginRight: "0.25em",
                      fontSize: "1.2em",
                      color: "rgb(0, 170, 231)",
                    }}
                  />
                </DropdownToggle>
                <DropdownMenu style={{ left: "-110px" }}>
                  <DropdownItem
                    key={0}
                    style={{
                      fontSize: "12px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                    className="iqvia-color"
                    onClick={showAdvancedCriteria}
                  >
                    Edit filters
                  </DropdownItem>
                  <DropdownItem
                    key={1}
                    style={{
                      fontSize: "12px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                    className="iqvia-color"
                    onClick={() => clearFilter()}
                  >
                    Clear filters
                  </DropdownItem>
                  <DropdownItem
                    key={2}
                    style={{
                      fontSize: "12px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                    className="iqvia-color"
                    onClick={() => saveFilter()}
                  >
                    Save filters
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </>
        ) : currentSearchDataSource != 0 ? (
          <>
            <RestartAltIcon
              style={{
                marginTop: "-4rem",
                marginLeft: "9em",
                color: "rgb(0, 170, 231)",
              }}
              className="iqvia-color"
              onClick={() => clearFilter()}
            />
          </>
        ) : <></>}
      </div>

      <ul className="navbar-nav" style={{ width: "100%", paddingLeft:"0px"}}>
        {rows && rows
          .filter((f) => f.name.toLowerCase() != "freetext")
          .map((x: Field, index: number) => {
            switch (x.fieldTypeId) {
              case 1:
                return (
                  <li key={x.id} className={"btn btn-sm search-criteria-li " + x.name}>
                    <div
                      style={{
                        display: "inline-block",
                        paddingTop: "5px",
                        width: "188px",
                      }}
                    >
                      {x.label}
                    </div>
                    {/* <input style={{'display':'inline-block'}} type="checkbox" id={x.name}  ></input> */}
                    <input
                      name={x.name}
                      onChange={updateTextCriteria}
                      className="search-field-textbox"
                      type="text"
                      value={
                        savedPriorFilterState[x.name] ? savedPriorFilterState[x.name] : ""
                      }
                      placeholder={x.label}
                    />
                  </li>
                );
              case 3:
                  return (
                    <li key={x.id} className={"btn btn-sm search-criteria-li " + x.name}>
                      <div
                        style={{
                          display: "inline-block",
                          paddingTop: "5px",
                          width: "188px",
                        }}
                      >
                        {x.label}
                      </div>
                      {/* <input style={{'display':'inline-block'}} type="checkbox" id={x.name}  ></input> */}
                      <input
                        name={x.name}
                        onChange={updateTextNumberCriteria}                        
                        className="search-field-textbox"
                        type="text"
                        maxLength={9}
                        value={
                          savedPriorFilterState[x.name] ? savedPriorFilterState[x.name] : ""
                        }
                        placeholder={x.label}
                      />
                    </li>
                  );
              case 4:
                return (
                  <div key={x.id} className="search-criteria-li">
                    <li value={x.id} className={"btn btn-sm " + x.name}>
                      {x.label} :
                    </li>
                    <br></br>
                    <select
                      className="search-field-dropDown"
                      onClick={() => {
                        buttonClick(x.lookupId, x.name, x.label, x.fieldTypeId);
                      }}
                      onChange={(event) => {
                        const getcategoryId = event.target.value;
                        const dropdownFilter = lookupGlobe.CategoryId.filter(
                          (e) => e.ID == parseInt(getcategoryId)
                        ).map((el) => {
                          el.ID == parseInt(getcategoryId);
                          setCheckValue({
                            ...checkValue,
                            ["CategoryIDs"]: [el.ID],
                          });
                        });
                      }}
                    >
                      <option value="-1" label="All Categories">
                        All Categories
                      </option>
                      {lookupGlobe.hasOwnProperty(x.name) &&
                        lookupGlobe[x.name].map((item) => (
                          <option value={item.ID} label={item.Value}>
                            {item.Value}
                          </option>
                        ))}
                    </select>
                  </div>
                );
              case 10:
                return (
                  <li key={x.id} className={"btn btn-sm search-criteria-li " + x.name}>
                    <div
                      style={{
                        display: "inline-block",
                        paddingTop: "5px",
                        width: "188px",
                      }}
                    >
                      {x.label}
                    </div>
                    <input
                      name={x.name}
                      onChange={criteriaChanged}
                      style={{ display: "inline-block" }}
                      type="checkbox"
                      value={
                        savedPriorFilterState[x.name] ? savedPriorFilterState[x.name] : ""
                      }
                      checked={
                        savedPriorFilterState[x.name] ? savedPriorFilterState[x.name] : ""
                      }
                      id={x.name}
                    ></input>
                  </li>
                );
              case 7:
              case 8:
              case 9:
              case 12:
              case 24:
              case 25:
              case 26:
                
                  return (
                    <div key={x.id} className="search-criteria-li">
                      <li
                        value={x.id}
                        onClick={() =>
                          buttonClick(x.lookupId, x.name, x.label, x.fieldTypeId)
                        }
                        className={"btn btn-sm search-criteria " + x.name}
                      >
                        {x.label}
                      </li>
                      {(x.name === "CoaInstrumentIDs" || x.name === "COA_InstrumentIDs") && selectedLookupGlobe[x.name] &&
                      (<CoaInstrumentSelectedItemView
                      coa_instruments={selectedLookupGlobe[x.name]}
                      objName={x.name}
                      filterUpdateCoaInstrument={removeFilter}
                    />)}
                      {(x.name !== "CoaInstrumentIDs" && x.name !== "COA_InstrumentIDs") &&
                        <div className="custom-scroll" style={{ padding: "0px 0px 0px 6px" }}>
                        {selectedLookupGlobe.hasOwnProperty(x.name) &&
                          selectedLookupGlobe[x.name].map((row: any, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  width: "100%",
                                  display: "block",
                                  fontSize: "12px",
                                  color: "#00aae7",
                                }}
                                onClick={() => {
                                  removeFilter(x.name, row);
                                }}
                                onMouseEnter={() => {
                                  setHoveredIndex(row.Id);
                                }}
                                onMouseLeave={() => {
                                  setHoveredIndex(-1);
                                }}
                              >
                                {row.Id === hoveredIndex && (
                                  <span>
                                    <CloseIcon
                                      style={{
                                        color:
                                          row.Id === hoveredIndex
                                            ? "#ff0000"
                                            : "#00aae7",
                                        transition: "color 0.3s ease",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                )}
                                <span
                                  style={{
                                    color:
                                      row.Id === hoveredIndex
                                        ? "#ff0000"
                                        : "#00aae7",
                                    transition: "color 0.3s ease",
                                    cursor: "pointer",
                                  }}
                                >
                                  {row.Value}
                                </span>
                              </span>
                            );
                          })}
                      </div>
                      }
                      
                    </div>
                  );
                
              case 39:
                return (
                  <li
                    key={x.id}
                    style={{
                      // border: "1px solid #EFEFEF",
                      textAlign: "left",
                      // padding: 7,
                    }}
                  >
                    <div style={{ paddingLeft:"15px",
                      fontSize:"13px",
                      marginTop:"3px"}}>{x.label}</div>
                    <div className={"input-group date " + x.name}>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                        <DatePicker
                      size="small"
                        placeholder="From" style={{marginLeft:"-8px", fontSize:"12px"}}
                        // style={{
                        //   marginLeft:"-11px",
                        //   // backgroundColor:"red"
                        // }}
                          // label={
                          //   !!datePickerValue[x.name + "Start"] ? "" : "From"
                          // }
                          value={
                            savedPriorFilterState[x.name + "Start"]
                              ? dayjs(savedPriorFilterState[x.name + "Start"])
                              : null
                          }
                          format={customFormat}
                          // onChange={selectStartDate}
                          onChange={(e) => {
                            selectStartDate(e, x.id, x.name);
                          }}
                          className="datePicker"
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        />
                      {/* </LocalizationProvider> */}
                    </div>
                    -
                     <div className="input-group date">
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                        <DatePicker
                        placeholder="To"
                        size="small"
                          label={!!datePickerValue[x.name + "End"] ? "" : "To"}
                          value={
                            savedPriorFilterState[x.name + "End"]
                              ? dayjs(savedPriorFilterState[x.name + "End"])
                              : null
                          }
                          format={customFormat}
                          //onChange={(e) => handleEndDate(e, `${x.name}Start`)}
                          onChange={(e) => selectEndDate(e, x.id, x.name)}
                          className="datePicker"
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                        />
                        </div>
                      {/* </LocalizationProvider> */}
                    
                  </li>
                );

              case 55:
                return(
                  <div key={x.id} className="search-criteria-li">
                      <li
                        value={x.id}
                        className={"btn btn-sm search-criteria " + x.name}
                      >
                        {x.label}
                      </li>
                      <input
                        style={{ maxWidth: "150px", display: "inline" }}
                        type="text"
                        maxLength={9}
                        id={x.name+"Min"}
                        value={savedPriorFilterState[x.name+"Min"] ? savedPriorFilterState[x.name+"Min"] : ""}
                        onChange={(e) => {
                          let { value } = e.target;
                          if (value) {
                            value = validateNumber(value);
                            // value = value.replace(/\D/g, "");
                          }
                          selectMinRange(value, x.id, x.name);
                        }}
                        className="input-group range"
                        placeholder={"min"}
                      />
                      -
                      <input
                        style={{ maxWidth: "150px", display: "inline" }}
                        type="text"
                        maxLength={9}
                        id={x.name+"Max"}
                        value={savedPriorFilterState[x.name+"Max"] ? savedPriorFilterState[x.name+"Max"] : ""}
                        onChange={(e) => {
                          let { value } = e.target;
                          if (value) {
                            value = validateNumber(value);
                            // value = value.replace(/\D/g, "");
                          }
                          selectMaxRange(value, x.id, x.name);
                        }}
                        className="input-group range"
                        placeholder={"max"}
                      />
                  </div>
                );
            }
          })}
      </ul>
      <br />
      <button
        className=" col-sm-10 button-upload mb-3"
        onClick={() => {
          onSearch(true);
          dispatch(setSearch(true));
        }}
      >
        {path.includes("savedSearch") && savedSearchId > 0 ? "Refine" : "Search"}
      </button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#FAFAFA" }}>
          <Modal.Title>
            <div
              id="modal"
              style={{
                backgroundColor: "#FAFAFA",
                display: "block",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <h4
                style={{
                  marginLeft: "10px",
                  marginTop: "15px",
                  float: "left",
                  fontWeight: "bold",
                }}
              >
                Edit search filters
              </h4>
              <input
                value={editSearchValue}
                placeholder="Search columns"
                onChange={(e) => OnEditFilterSearch(e)}
                className="editColumn"
              />
              <FontAwesomeIcon
                onClick={clearInput}
                style={{
                  fontSize: "14px",
                  padding: "20px 15px",
                  cursor: "pointer",
                }}
                icon={faRefresh as IconProp}
              />
              <button
                style={{
                  background: "rgb(250, 250, 250)",
                  marginRight: "10px",
                  float: "right",
                  border: 0,
                }}
                onClick={handleClose}
              >
                <FontAwesomeIcon
                  onClick={clearInput}
                  style={{
                    fontSize: "30px",
                    marginTop: "15px",
                    fontWeight: 700,
                    color: "#7d7d7d",
                  }}
                  icon={faXmark as IconProp}
                />
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ overflowY: "auto", maxHeight: "70vh", overflowX: "hidden" }}
        >
          <AdvanceFilter
            sections={srows}
            fields={rows}
            aFields={advRows}
            filterChanged={filterChanged}
            currentSearchDataSource={currentSearchDataSource}
            defaultActiveAcc={deafultActiveKeys}
          ></AdvanceFilter>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#FAFAFA",
            minHeight: "60px",
            padding: "0px",
          }}
        >
          <div style={{ float: "right" }}>
            <a
              style={{
                color: "#00aae7",
                fontSize: 14,
                padding: 15,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              Save changes
            </a>
            &nbsp;&nbsp;
          </div>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={openCriteriaSuccess}
        autoHideDuration={6000}
        onClose={handleCloseCriteria}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseCriteria}
          severity="success"
          sx={{ width: "100%" }}
        >
          Search filters saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
