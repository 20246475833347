import React, { useEffect, createContext, useContext, useState, useCallback, useRef } from "react";
import {
  Section,
  Comment,
  Field
} from "../../store/DataSourceMetaData";
import {
  useFetchSectionMutation,
  // useFetchSectionsMutation
} from "../../store/DataSourceMetaDataApiSlice";
import { useParams, useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SectionView from "./SectionView";
import RecordNotFound from "./RecordNotFound";
import NewAlert from "../AlertBox/NewAlert";
import NewModal, { ILookupData } from "../Popup/NewModal";
import { EditorState } from "draft-js";
import { useHistory, useLocation } from "react-router-dom";
import {
  IselectedLookup,
  IselectedLookupGlobe,
  IselectedLookupGlobeSpecial,
  ILookupGlobe,
} from "../interfaces/multiselect";
import {
  setDataSourceId,
  setEndpointResultOption,
  setLookupData,
  setSelectedDataState,
  setSelectedLookupGlobe,
  setSelectedLookupGlobeSpecial,
  setSubpageData,
  setFieldListAudit,
  setSelectedLookupGlobeDbVal,
  setFormValues,
  setLookupGlobe,
  setSuperDisease,
  setLookupSource,
  setLoeBrandName,
} from "../../store/MultiSelectSlice";
import apiCall from "../service/apiCall";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "../ToastrAlert/ToastrStyle.css";
import { getIdFieldName } from "../../utils";
import ScrollButton from "./ScrollToTop";
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";
import { DataSourceListTypes, Status } from "../Shared/Utility";
import { lookupItemT } from "./FieldView";
import { changeFieldCriteriaTypeId, changeFieldTypeId } from "./SubPages/Views/Utility";
import PostCall from "../service/postCall";
import DeleteCall from "../service/deleteCall";
import { hasNonEmptyArrayOrValue} from "./Utility";

interface EditorIdentifierProp {
  dataSourceID: number;
  recordID: number;
}

export const UserContext = createContext({
  showAlert: (
    title: any,
    type: any,
    message: any,
    url: any,
    modalValue: any
  ) => {},
  countryList: [],
  countryNames: [],
  drugSpecificDataList: [],
  mandatoryFields: [],
  conditionalFormFields:{},
  setConditionalFormFields : (val : any) => {}, 
  onClick: (type: string, title: string, msg: string) => {},
  setValues: (values : any) => {},
});

export interface IForm {
  [Id: string]: any;
};

interface ILookupLabelList {
  [key: string]: string;
}

export const warningOptions = {
  positionClass: "toast-top-center",
  hideDuration: 300,
  timeOut: 60000,
  closeButton: true,
};

export const infoOptions = {

  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-bottom-right",
  preventDuplicates: false,
  onclick: () => {},
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 0,
  extendedTimeOut: 0,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  tapToDismiss: false,
};

export const MandatoryFieldForHTA = [
  "Name",
  "HTARecord_PrimaryTherapeuticArea",
  "HTARecord_PrimaryDisease_ID",
  "COA_Endpoints.Significances",
  "COA_Endpoints.InstrumentID",
  "COA_Endpoints.ImportanceID",
  "RWE_Datasource.RWEDatasourceID",
  "RWE_Datasource.AreasSupportedName"
];

export const MandatoryFieldForClinicalData = [
  "Name",
  "ClinicalData_TherapeuticArea",
  "ClinicalData_Disease_ID",
  "ClinicalData_Arms.Name",
  "COA_Endpoints.Significances"
];

const MandatoryFieldForRegulatoryWatch = [
  "MarketingAuthHolder_ID",
  "RegulatoryWatch_Drug",
  "Regulator_ID",
  "RegulatoryStatus_ID",
  "PubDate",
  "FullIndication",
  "StandardApproval",
  "ConditionalApproval",
  "ParallelReviewWithHTAAgency",
  "Extraordinaryuse",
  "FDA_PROLabelClaim",
  "RegulatoryOrphanStatus",
  "Biosimilars",
  "SafetyWarning",
  "RegulatoryWatch_ApprovedIndication_Disease",
  "COA_Endpoints.Significances",
  "COA_Endpoints.ImportanceID",
  "RegulatoryWatch_DosageAndMode.RegulatoryDosageInformation",
  // "RegulatoryWatch_DosageAndMode.DaysPerCycle",
  "RegulatoryWatch_DosageAndMode.CyclesPerMonth",
  "RegulatoryWatch_DosageAndMode.CyclesPerYear",
  "RegulatoryWatch_DosageAndMode.CycleNumber",
  "AcceleratedApproval", 
  "ExceptionalCircumstances", 
  "PrimeStatus",
  "FDAFastTrackDesignation",
  "PriorityReview",
  "BreakthroughTherapy",
  "ProjectORBIS",
  "InnovativeLicensingAndAccessPathway",
  "EAMSopinion"

];

const MandatoryFieldsForCountry = [
  "Abbreviation"
];

const MandatoryFieldsForDrugData = [
  "DrugData_ActiveSubstance",
  "DrugData_BrandLicense.RegionalDrug_ID_Text",
  "DrugData_Lov.DrugData_Lov_BrandName"
];

const MandatoryFieldsForAgency = [
  "Abbreviation",
  "Name",
  "AgencyStatus_ID",
  "AgencySinceDate",
  "Country_ID",
];

const MandatoryFieldsForAgreement = [
  "Name",
  "Country_ID"
];
export interface IconditionalFormFields {
  [name :string] : string
}


 
function Editor() {
  const accountid = useAppSelector((state) => state.userProfile.accountId);
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const lookupKey = useAppSelector((state) => state.multiselect.lookupKey);
  const fieldListAudit = useAppSelector((state) => state.multiselect.fieldListAudit);
  // const selectedLookupGlobeDbVal = useAppSelector((state) => state.multiselect.selectedLookupGlobeDbVal);
  const [selectedLookupGlobe, setSelectedLookup] =
    useState<IselectedLookupGlobe>({});
  const [selectedLookupGlobeSpecial, setSelectedLookupSpl] =
    useState<IselectedLookupGlobeSpecial>({});
  const [selectedLookupGlobeDbVal, setSelectedLookupDbVal] =
    useState<IselectedLookupGlobe>({});
  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [lookupLabelList, setLookupLabelList] = useState<ILookupLabelList>({});

  const dispatch = useAppDispatch();

  const navigate = useHistory();

  const searchParams = new URLSearchParams(document.location.search);

  //const { dataSourceID, recordID } = props;

  let dataSourceID: number = 0;
  let recordID: number = 0;
  const params : any = useParams()
  dataSourceID = Number(params.dataSourceID);
  recordID = Number(params.recordID);
  const [record_ID] = useState<number>(recordID);
  const [idColumnName] = useState<string>(getIdFieldName(dataSourceID));
  const [Eclient, setEclient] = useState<boolean>(false);
  const [recordNotFound, setRecordNotFound] = useState<boolean>(false);
  const currRoleId = localStorage.getItem("CurrRoleId");
  const currUserId = localStorage.getItem("CurrUserId");
  const [countryList, setCountryList] = useState<any>([]);
  const [countryNames, setCountryNames] = useState<any>([]);
  const [dsId, setDsId] = useState<number>(0);
  const [drugSpecificDataList, setDrugSpecificDataList] = useState<any>([]);
  const [formMode, setFormMode] = useState<string>("");
  const [recordId, setRecordId] = useState<number>(0);
  const [datasourceVal, setDataSourceVal] = useState<number>(0);
  const lookupSource : any[] = useAppSelector((state) => state.multiselect.lookupSource);

  useEffect(() => {
    dispatch(setDataSourceId(Number(dataSourceID)))
    setDsId(dataSourceID);
    GetSectionData({ dataSourceID: dataSourceID });
  }, [dataSourceID]);

  useEffect(() => {
    if (Eclient) {
      let newrows = [...srows];
      //delete newrows[0];
      setSrows(newrows);
    }
  }, [Eclient]);

  const [alertState, setAlertState] = useState(["", "", "Info", false]);
  //let _dataSourceID: number = props.match.params.dataSourceID;
  const [fetchSection, { data, error, isLoading, isSuccess }] =
    useFetchSectionMutation();

  const AddLookupLabelList = (data: ILookupLabelList) => {

    setLookupLabelList({ ...lookupLabelList, [data["id"]]: data["label"] });
  };

  const GetSectionData = async (payload: any) => {
    const result : any = await fetchSection(payload);
    if (Number(currRoleId) == 6) {
      setEclient(true);
    }
    let _rows = [];

    if (result && result.data) {
      if (
        recordID == 0 ||
        recordID == null ||
        isNaN(recordID) ||
        (Number(currRoleId) > 4 && Number(currRoleId) < 8)
      )
        _rows = result.data.filter(
          (x:any) => x.name != "Record details" && x.name != "Search results"
        );     
      else _rows = result.data.filter((x:any) => x.name != "Search results");
      // if (Eclient) {
      //   delete _rows[0];
      // }
      if (Number(dataSourceID) == 7) {
        _rows.splice(6, 0, _rows[_rows.length - 1]);
        _rows = _rows.filter((x:any, i:number) => i != _rows.length - 1);
      }
      else if(Number(dataSourceID) == 274){
        _rows = _rows.filter((x:any) =>  x.name != "General");
      }else if(Number(dataSourceID) == 276)
        _rows = _rows.filter((x:any) => x.name !="Basic search");
      setSrows(_rows);
    }
  };

  const [srows, setSrows] = useState<Section[]>([]);
  const [values, setValues] = useState<IForm>({});
  const [dbValues, setDbValues] = useState<IForm>({});
  const [mandatoryFields, setMandatoryFields] = useState<any>([]);
  const [conditionalFormFields, setConditionalFormFields] = useState<IconditionalFormFields>({});
  const location = useLocation();
  const user_id = sessionStorage.getItem("user_id");
  const [lastStatus, setLastStatus] = useState("info");
  const subPageData = useAppSelector((state) => state.multiselect.subpageData);
  const selectedLookupGlobeValue = useAppSelector((state) => state.multiselect.selectedLookupGlobe);
  const selectedLookupGlobeSpecialValue = useAppSelector((state) => state.multiselect.selectedLookupGlobeSpecial);
  // const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const nodes = useAppSelector((state) => state.multiselect.SuperDisease);
  const drugCombination = useAppSelector((state) => state.multiselect.drugCombination);
  const redirectToAuth = useAppSelector((state) => state.UniversalValue.redirectToAuth);
  const redirect : string = localStorage.getItem("redirect") || "";
  const [fieldCriteriaFieldId,setFieldCriteriaFieldId] = useState<number>(Number(params.fieldCriteriaID));
  const [dynamicField, setDynamicField] = useState<Field>({
    id: 0,
    name: "",
    sectionId: 0,
    sortOrder: 0,
    fieldTypeId: 0,
    label: "",
    description: "",
    descriptionClient: "",
    required: false,
    readOnly: false,
    lookupId: 0,
    visible: false,
    is_removed: false,
    issummary: false,
    parentFieldId: 0,
    relateddatatype: 0,
    displaySource: "",
    displayidname: "",
    displayfieldname: "",
    fieldinfo: "",
    category_id: 0,
    filter_field_id: 0,
    result_Field_id: 0,
    externallyvisible: false,
    masterFieldId: 0,
});
  const confirmData = location.state;
  
  const isViewVersion = location.pathname.includes("ViewVersion");
  const versionID = isViewVersion ? params.versionID : undefined;
  const autoSaveFrequency = Number(process.env.REACT_APP_AUTOSAVE_FREQUENCY) || 300000;
  let fieldForAudit = "";

  const onClick = (type: string, title: string, msg: string) => {
    if (lastStatus != type) {
      toastr.remove();
    }
    setLastStatus(type);
    if (type == "warning") {
      toastr.warning(msg, title, warningOptions);
    }
    if (type == "info") {
      toastr.info(msg, title, infoOptions);
    }
  };

  useEffect(() => {
    if(Object.keys(confirmData?.values || {}).length > 0){
      setValues(confirmData.values)
    }
  },[confirmData])

  useEffect(() => {
    if (searchParams.get("redirect") == "true") {
      let msg = "";
      let Q_associate = values["QAssociate"];
      let Q_reviewer = values["QReviewer"];
      let status = values["Status_ID"];

      if (Q_associate == "undefined") {
        msg =
          "No associate is assigned for this record. Please assign user to continue editing.";
      } else if (
        status == 1 &&
        Q_associate == "undefined" &&
        Q_reviewer == "undifined"
      ) {
        msg =
          "No associate/reviewer is assigned for this record. Please assign user to continue editing.";
      } else if (Q_associate != user_id) {
        msg = "This record is only editable by associate of the record.";
      } else if ([3, 5, 6, 7].includes(status) && Q_reviewer != user_id) {
        msg = "This record is only editable by reviewer of the record.";
      } else if (status > 6 && Q_reviewer != user_id) {
        msg = "This record is only editable by reviewer of the record.";
      }

      setTimeout(() => {
        onClick(
          "warning",
          msg,
          '<br/> <button style = "border-radius: 8px;background-color: orange;border: 1px solid white;color: #fff;float:right;">Ignore </button>'
        );
      }, 1000);
    }

    if(Number(dataSourceID == 25)){
      dispatch(setFormValues(values));
    }

  }, [values]);

  useEffect(() => {
    if (Number(dataSourceID) == 1) {
      setMandatoryFields(MandatoryFieldForHTA);
    } else if (Number(dataSourceID) == 2) {
      setMandatoryFields(MandatoryFieldForClinicalData);
    } else if (Number(dataSourceID) == 3) {
      setMandatoryFields(MandatoryFieldForRegulatoryWatch);
    } else if (Number(dataSourceID == 6)) {
      setMandatoryFields(MandatoryFieldsForAgency);
    } else if (Number(dataSourceID) == 7) {
      setMandatoryFields(MandatoryFieldsForCountry);
    } else if (Number(dataSourceID) == 20) {
      setMandatoryFields(MandatoryFieldsForDrugData);
    } else if (Number(dataSourceID == 23)) {
      setMandatoryFields(MandatoryFieldsForAgreement);
    }
  }, [dataSourceID]);

  useEffect(() => {
    (async () => {
      if (Number(dataSourceID) === DataSourceListTypes.Country) {
        let res = await apiCall(`/api/country_list`);
        const data: any = res["data"];
        setCountryList(data["country_list"]);
        setCountryNames(data["country_names"]);
      }
      else if (Number(dataSourceID) === DataSourceListTypes.ReimbursementStatus) {
        let res = await apiCall(`/api/drug_specific_data_list`);
        const data: any = res["data"];
        setDrugSpecificDataList(data["drug_list"]);
      }
    })();
  }, []);

  useEffect(() => {
    const prevValues = JSON.parse(localStorage.getItem("prevValues") || "{}");
    localStorage.removeItem("prevValues");
    if (Object.keys(prevValues).length > 0) {
      setValues(prevValues);
      const _selectedLookupGlobe: IselectedLookupGlobe = JSON.parse(
        localStorage.getItem("selectedLookupGlobe") || "{}"
      );
      const _selectedLookupGlobeSpecial: IselectedLookupGlobeSpecial =
        JSON.parse(localStorage.getItem("selectedLookupGlobeSpecial") || "{}");
      localStorage.removeItem("selectedLookupGlobe");
      localStorage.removeItem("selectedLookupGlobeSpecial");
      if (Object.keys(_selectedLookupGlobe).length > 0)
        dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
      if (Object.keys(_selectedLookupGlobeSpecial).length > 0)
        dispatch(setSelectedLookupGlobeSpecial(_selectedLookupGlobeSpecial));

      if (location.pathname.includes("form") && recordID)
        setFormMode("write")
      else if (location.pathname.includes("form"))
        setFormMode("new")
      else
        setFormMode("read")
        
    } else {
      let pattern = "/form/([0-9]*)";
      let pattern1 = "/view/([0-9]*)";
      let pattern2 = "/Form/([0-9]*)";
      let pattern3 = "/View/([0-9]*)";

      let datasource: any = location.pathname.match(pattern);
      if (datasource == null) {
        datasource = location.pathname.match(pattern1);
      }
      if (datasource == null) {
        datasource = location.pathname.match(pattern2);
      }
      if (datasource == null) {
        datasource = location.pathname.match(pattern3);
      }
      if (datasource == null) {
        datasource = location.pathname.match("/Form/([0-9]*)");
      }
      if (datasource == null) {
        datasource = location.pathname.match("/confirm/([0-9]*)");
      }
      if (datasource == null) {
        datasource = location.pathname.match("/create/([0-9]*)");
      }
      if (datasource == null) {
        datasource = location.pathname.match("/fieldcriteria/([0-9]*)");
      }
      if (datasource == null) {
        datasource = location.pathname.match("/ViewVersion/([0-9]*)");
      }
      if (datasource == null) {
        datasource = location.pathname.match("/FormClone/([0-9]*)");
      }

      setDataSourceVal(Number(datasource[1]));
      pattern = "/form/[0-9]*/([0-9]*)";
      pattern1 = "/view/[0-9]*/([0-9]*)";
      pattern2 = "/Form/[0-9]*/([0-9]*)";
      pattern3 = "/View/[0-9]*/([0-9]*)";
      let pattern4 = "/ViewVersion/[0-9]*/([0-9]*)";
      let pattern5 = "/FormClone/[0-9]*/([0-9]*)";
      
      let recordInfo = location.pathname.match(pattern);
      if (recordInfo == null) {
        recordInfo = location.pathname.match(pattern1);
      }
      if (recordInfo == null) {
        recordInfo = location.pathname.match(pattern2);
      }
      if (recordInfo == null) {
        recordInfo = location.pathname.match(pattern3);
      }
      if (recordInfo == null) {
        recordInfo = location.pathname.match(pattern4);
      }
      if (recordInfo == null) {
        recordInfo = location.pathname.match(pattern5);
      }

      let recordId = recordID;
      if (recordInfo != null) recordId = Number(recordInfo![1]);
      setRecordId(recordId);
      
      if (location.pathname.includes("clientgroup")) {
        if (params.clientID && params.recordID)
          setFormMode("write");
        else if (params.clientID && !params.recordID)
          setFormMode("new");
        else
          setFormMode("read");
      } else if (location.pathname.includes("lookupItem")) {
        if (params.lookupID && params.recordID)
          setFormMode("write");
        else if (params.lookupID && !params.recordID)
          setFormMode("new");
        else
          setFormMode("read");
      } else if (location.pathname.includes("fieldcriteria")) {
        if (params.dataSourceID && params.fieldCriteriaDatasourceID && params.fieldCriteriaID && params.recordID)
          setFormMode("write");
        else if (params.dataSourceID && params.fieldCriteriaDatasourceID && params.fieldCriteriaID)
          setFormMode("new");
        else
          setFormMode("read");
      } else {
        if (location.pathname.toLowerCase().includes("formclone") && params.recordID) {
          setFormMode("new");
        }
        else if (location.pathname.toLowerCase().includes("form") && params.recordID) {
          setFormMode("write");
        }
        else if (location.pathname.toLowerCase().includes("form") && !params.recordID) {
          setFormMode("new");
        }
        else {
          setFormMode("read");
        }
      }
    }
  }, []);

  const getFormMode = () =>{
    if (location.pathname.includes("clientgroup")) {
      if (params.clientID && params.recordID)
        return "write";
      else if (params.clientID && !params.recordID)
        return "new";
      else
        return "read";
    } else if (location.pathname.includes("lookupItem")) {
      if (params.lookupID && params.recordID)
        return "write";
      else if (params.lookupID && !params.recordID)
        return "new";
      else
        return "read";
    } else if (location.pathname.includes("fieldcriteria")) {
      if (params.dataSourceID && params.fieldCriteriaDatasourceID && params.fieldCriteriaID && params.recordID)
        return "write";
      else if (params.dataSourceID && params.fieldCriteriaDatasourceID && params.fieldCriteriaID)
      return "new";
      else
      return "read";
    } else {
      if (location.pathname.toLowerCase().includes("formclone") && params.recordID) {
        return "new";
      }
      else if (location.pathname.toLowerCase().includes("form") && params.recordID) {
        return "write";
      }
      else if (location.pathname.toLowerCase().includes("form") && !params.recordID) {
        return "new";
      }
      else {
        return "read";
      }
    }
  }

  const valuesRef = useRef(values);
  const selectedLookupGlobeValueRef = useRef(selectedLookupGlobeValue);
  const selectedLookupGlobeSpecialValueRef = useRef(selectedLookupGlobeSpecialValue);

  useEffect(() => {
    valuesRef.current = values;
    selectedLookupGlobeValueRef.current = selectedLookupGlobeValue;
    selectedLookupGlobeSpecialValueRef.current = selectedLookupGlobeSpecialValue;
  }, [values, selectedLookupGlobeValue, selectedLookupGlobeSpecialValue]);

  // Debounce function with TypeScript
  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const AutoSaveInvocation = useCallback(
    debounce(async () => {
      const _recordId = location.pathname.toLowerCase().includes("formclone") || !recordID ? 0 : recordID;
      await PostCall(`/api/autosave/${dataSourceID}/${_recordId || 0}`,
        {
          values: valuesRef.current,
          selectedLookupGlobeValue: selectedLookupGlobeValueRef.current,
          selectedLookupGlobeSpecialValue: selectedLookupGlobeSpecialValueRef.current
        })
    }, autoSaveFrequency),
    []
  );

  const DebounceAutoSaveInvocation = () => {
    const _formMode = getFormMode()
    if (dataSourceID < 31 && (_formMode == "write" || _formMode  == "new")) {
      const workflowRes = checkWorkflowForAutoSave();
      if (workflowRes) {
        AutoSaveInvocation()
      }
    };
  }

  useEffect(() => {

    document.addEventListener('click', DebounceAutoSaveInvocation);
    document.addEventListener('keydown', DebounceAutoSaveInvocation);
    document.addEventListener('keypress', DebounceAutoSaveInvocation);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('click', DebounceAutoSaveInvocation);
      document.removeEventListener('keydown', DebounceAutoSaveInvocation);
      document.removeEventListener('keypress', DebounceAutoSaveInvocation);
    };

  }, [])


  useEffect(() => {
    if ((redirectToAuth || redirect == "true")
      &&
      (formMode == "write" || formMode == "new")) {
      localStorage.setItem("prevUrl", window.location.href);
      if(datasourceVal < 31)
        DebounceAutoSaveInvocation();
    }
  }, [ redirectToAuth, redirect ])


  const OnClickIgnore = async(id:  number) => {
    toastr.remove()
    await DeleteCall(`/api/autosave/${id}`);
    window.location.reload();
  }

  const showAutoSaveInfo = (id : number) => {
    let alertBody = `This record is auto recovered from the previously captured data.
    <br>
    Click ignore to discard the unsaved data and start fresh.
    <br>
    <br>
    <div style = "padding-bottom:10px">
    <button id='autoSave-ignore-btn' style ="background-color: orange;border: 1px solid white;color:white;padding: 1px 10px;border-radius: 5px;float: right;">Ignore and start fresh</button><br>
    </div>`;
    toastr.remove()
    toastr.success(alertBody, "<h5 style='font-weight:bold'>This record is auto recovered</h5>", infoOptions);

    document?.getElementById('autoSave-ignore-btn')?.addEventListener('click', () => OnClickIgnore(id), { passive: true });
    
  }

  // const debounce = <T extends (...args: any[]) => void>(func: T, delay: number):
  //   (...args: Parameters<T>) => void => {
  //   let timeoutId: ReturnType<typeof setTimeout>;
  //   return (...args: Parameters<T>) => {
  //     if (timeoutId) clearTimeout(timeoutId)
  //     timeoutId = setTimeout(() => {
  //       func(...args)
  //     }, delay)
  //   };
  // };



  const fetchDynamicField = async(Value : any) => {
    values["Field_CriteriaField_ID"] = Value;
    const res = await apiCall(`/api/admin/field/${Value}`);
    let _field : Field = res.data;
    _field.name = "Value";
    _field.label = "Value";
    _field.required = true;
    let fieldtypeid = changeFieldCriteriaTypeId(_field.fieldTypeId);
    _field.fieldTypeId = changeFieldTypeId(_field.fieldTypeId);
    setDynamicField(_field);
    // if(fieldtypeid == 10)
    //       values.Value = true
    delete values.Value;
    if(fieldtypeid == 1)
      values.Value = false;
    setValues({...values,['Field_Criteria_Type_ID'] : fieldtypeid});
}

  const getItemsforField = async() => {        
    const res: any = await apiCall(`/api/admin/label/${fieldCriteriaFieldId}`);
    const labelData : lookupItemT = res.data;
    let _values :any= {};

    if(formMode == "new"){
      const fieldCriterialIdRes = await apiCall(`/api/admin/fieldCriteria/defaultId/${params.fieldCriteriaDatasourceID}`);
      _values["CombinationSets"] = "1";
      _values["Field_CriteriaField_ID"] = fieldCriterialIdRes.data[0]["ID"];
      _values['Field_ID'] = fieldCriteriaFieldId;
    }else{
      const response  = await apiCall(`/api/admin/table/get/${dataSourceID}/${recordID}`);
      _values = {..._values,...response.data[0]}  
    }
     const fieldRes = await apiCall(`/api/admin/field/${_values["Field_CriteriaField_ID"]}`);
    let _field : Field = fieldRes.data;
    _field.name = "Value";
    _field.label = "Value";
    let fieldtypeid = changeFieldCriteriaTypeId(_field.fieldTypeId);
    _field.fieldTypeId = changeFieldTypeId(_field.fieldTypeId);
    setDynamicField(_field);
    _values.Field_Criteria_Type_ID = fieldtypeid;
    setValues({..._values,['Field']: labelData.label});
  }

  useEffect(() => {
    if (
      nodes.length == 0 &&
      [
        DataSourceListTypes.HTARecord,
        DataSourceListTypes.ClinicalData,
        DataSourceListTypes.RegulatoryWatch,
        DataSourceListTypes.ReimbursementStatus,
        DataSourceListTypes.Agreement
      ].includes(dataSourceID)
    ) {
      const callDiseaseApi = async () => {
        const resp = await apiCall("/api/AllDiseaseDetails");
        dispatch(setLookupSource(resp.data));
      }
      callDiseaseApi();
    }
  }, []);


  const updateSuperSearchDisease = (key:string,newObj:any,Keys:string[],_lookupSource:any) => {  
    let therapeuticArea = [...newObj[ Keys[ 5 ] ]  || []];
    therapeuticArea = therapeuticArea.map((x) => ({
      Id : x.Id + 101000000,
      Value : x.Value
    }));

    let superDisease = [ ...newObj[ Keys[ 0 ] ] || [],
    ...newObj[ Keys[ 1 ] ] || [],
    ...newObj[ Keys[ 2 ] ] || [],
    ...newObj[ Keys[ 3 ] ] || [],
    ...newObj[ Keys[ 4 ]  ] || [],
    ...therapeuticArea ] || [];

    const ids = superDisease.map((x) => x.Id);
    const parentIds = ids.map((x) => _lookupSource[ x ]);
    superDisease = superDisease.filter((x) => !parentIds.includes(x.Id));
    newObj[ key ] = superDisease;
    return superDisease;
  }

  useEffect(() => {
    if (Object.keys(selectedLookupGlobeValue)?.length > 0 && lookupSource.length > 0 && formMode !== "read") {
      if ((dataSourceID === 1 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||49" ]) 
          || (dataSourceID == 2 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||122" ]) 
            || (dataSourceID == 23 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||184" ])) {
        let newObj = { ...selectedLookupGlobeValue }
        let _lookupSource: any = {};
        lookupSource.forEach((x) => { _lookupSource[ x.value ] = x.ParentID });
        if (dataSourceID === 1 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||49" ]) {
          const primaryKeys = [ "HTARecord_PrimaryDisease_ID", "HTARecord_PrimaryDiseaseSubtype", "HTARecord_PrimaryDiseaseSubtype2", "HTARecord_PrimaryDiseaseSubtype3","HTARecord_PrimaryDiseaseSubtype4", "HTARecord_PrimaryTherapeuticArea" ];
          newObj[ "PrimaryAutocompelete_ID||49" ] = updateSuperSearchDisease("PrimaryAutocompelete_ID||49", newObj, primaryKeys, _lookupSource);
          const secondaryPrimaryKeys = [ "HTARecord_SecondaryDisease_ID", "HTARecord_SecondaryDiseaseSubtype", "HTARecord_SecondaryDiseaseSubtype2", "HTARecord_SecondaryDiseaseSubtype3","HTARecord_SecondaryDiseaseSubtype4", "HTARecord_SecondaryTherapeuticArea" ];
          newObj[ "SecondaryAutocompelete_ID||49" ] = updateSuperSearchDisease("SecondaryAutocompelete_ID||49", newObj, secondaryPrimaryKeys, _lookupSource);
        } else if (dataSourceID == 2 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||122" ]) {
          const primaryKeys = [ "ClinicalData_Disease_ID", "ClinicalData_DiseaseSubtype", "ClinicalData_DiseaseSubtype2", "ClinicalData_DiseaseSubtype3","ClinicalData_DiseaseSubtype4", "ClinicalData_TherapeuticArea" ];
          newObj[ "PrimaryAutocompelete_ID||122" ] = updateSuperSearchDisease("PrimaryAutocompelete_ID||122", newObj, primaryKeys, _lookupSource);;
        } else if (dataSourceID == 23 && !selectedLookupGlobeValue[ "PrimaryAutocompelete_ID||184" ]) {
          const primaryKeys = [ "Agreement_Disease_ID", "Agreement_DiseaseSubtype", "Agreement_DiseaseSubtype2", "Agreement_DiseaseSubtype3","Agreement_DiseaseSubtype4", "Agreement_TherapeuticArea" ];
          newObj[ "PrimaryAutocompelete_ID||184" ] = updateSuperSearchDisease("PrimaryAutocompelete_ID||184", newObj, primaryKeys, _lookupSource);;
        }
        dispatch(setSelectedLookupGlobe(newObj))
      }
    }

  }, [ lookupSource, selectedLookupGlobeValue ])

  const FetchAutoSaveData = async () => {
    try {
      const resp = await apiCall(`/api/autosave/${dataSourceID}/${recordID || 0}`);
      console.log("resp", resp)
      if (resp?.data?.FormDataBlob) {
        const { values, selectedLookupGlobeValue, selectedLookupGlobeSpecialValue } = JSON.parse(resp.data?.FormDataBlob);
        setValues(values || {});
        dispatch(setSelectedLookupGlobe(selectedLookupGlobeValue || {}));
        dispatch(setSelectedLookupGlobeSpecial(selectedLookupGlobeSpecialValue || {}));
        setTimeout(() => showAutoSaveInfo(resp.data.ID),2000);        
        return true
      }
      return false

    } catch (error) {
      console.log("error", error)
      return false
    }
  }

  useEffect(() => {
    const setUpForm = async () => {
      if (formMode !== "" && formMode != "read" && dataSourceID != 31 && dataSourceID < 250 && !location.pathname.toLowerCase().includes("formclone")) {
        const res: boolean = await FetchAutoSaveData()
        if (res)
          return
      }

      if (fieldCriteriaFieldId && dataSourceID == 277 && formMode) {
        getItemsforField();
      }

      if (dataSourceID == 31) {
        const callApi = async () => {
          const res: any = await apiCall(`/api/assumption/${recordId ? recordId : ""}`)
          if (res.status == 200 && res.data)
            setValues(res.data);
          else
            window.location.href = '/error!';
        }
        callApi()
      }
      else if (
        !isNaN(recordId) && typeof recordId === "number" &&
        recordId > 0 && typeof datasourceVal === "number" &&
        datasourceVal && recordId &&
        formMode && !confirmData
      ) {
        if (!location.pathname.includes("confirm")) {
          if (dataSourceID > 250) {
            const callApi = async () => {
              const response = await apiCall(`/api/admin/table/get/${dataSourceID}/${recordID}`);
              setValues(response.data[ 0 ]);
            }
            callApi();
          } else {
            apiCall(`/api/datasource/${datasourceVal}/${recordId}/${formMode}/${versionID}`).then(
              (response) => {
                response.data[ 0 ] == undefined || response.data[ 0 ] == null || response.data == ''
                  ? setRecordNotFound(true)
                  : setRecordNotFound(false);
                if (!recordNotFound) {
                  let values_ = response.data[ 0 ];
                  if (datasourceVal == 2) {
                    if (!values_[ "ArmsCohorts" ]) values_[ "ArmsCohorts" ] = [ {} ];
                    if (!values_[ "ClinicalData_Endpoints" ])
                      values_[ "ClinicalData_Endpoints" ] = [
                        { "ClinicalData_Endpoints.Primary": false },
                      ];
                  }
                  if(location.pathname.toLowerCase().includes("formclone")){
                    const idField = getIdFieldName(dataSourceID);
                  let created_date = new Date().toISOString().slice(0, 19).replace('T', ' ');
                  values_ = {...values_, ['Status_ID']: Status.RC, ['Clone_Record']: record_ID, 
                    ['CreatedDate']: created_date, ['ModifiedDate']: created_date, 
                    ['CreatedBy']: currUserId, ['ModifiedBy']: currUserId, 
                    ['QAssociate']: null, ['QReviewer']: null, 
                    ['Due_date_review']: null, ['Due_date_data']: null, 
                   };
                   delete values_[idField];
                   delete values_['ID'];
                  }

                  setValues(values_);
                  setDbValues(values_);
                }
              }
            );
          }
        }
        fetchMultiSelectValues();
      } else {
        if (formMode == "new")
          setDefaultValues()
      }
    }
    if (formMode)
      setUpForm()
  }, [ formMode ])

  useEffect(() => {
    const getPreviouslyApprovedIndications = async () => {
      const resp = await apiCall(
        `/api/previouslyapprovedindications/${selectedLookupGlobeValue.RegulatoryWatch_Drug[0].Id}/${values.Regulator_ID}/${values.RegulatoryWatch_ID ? values.RegulatoryWatch_ID : 0}`
      );
      let _subPageData = { ...subPageData };
      _subPageData.approved_indication = {
        ..._subPageData.approved_indication,
        previouslyApprovedIndications: resp.data,
      };
      dispatch(setSubpageData(_subPageData));
    };
    if (
      values &&
      values.Regulator_ID &&
      selectedLookupGlobeValue.RegulatoryWatch_Drug
    )
      getPreviouslyApprovedIndications();
  }, [
    values?.Regulator_ID,
    selectedLookupGlobeValue.RegulatoryWatch_Drug,
  ]);

  useEffect(() => {
    if(dataSourceID < 251)
      checkWorkflow();
  },[values?.Status_ID, values?.QAssociate, values?.QReviewer])

  useEffect(() => {
    console.log("values",values);
  },[values])

  const setDefaultValues = () => {
    if (datasourceVal == 7) {
      setValues({
        TherapeuticReferencing: "15386",
        GenericsSubstition: "15386",
        Engagement: "15386",
        Available: "15386",
      });
    } else if (datasourceVal == 1) {
      setValues({
        DataVersion: "2",
      });
    } else if (datasourceVal == 2) {
      let values_ = { ...values };
      if (!values_[ "ArmsCohorts" ]) values_[ "ArmsCohorts" ] = [ {} ];
      if (!values_[ "ClinicalData_Endpoints" ])
        values_[ "ClinicalData_Endpoints" ] = [
          { "ClinicalData_Endpoints.Primary": false },
        ];
      setValues(values_);
    } else if (datasourceVal == 3) {
      setValues({
        StandardApproval: "15386",
        ConditionalApproval: "15386",
        ParallelReviewWithHTAAgency: "15386",
        Extraordinaryuse: "15386",
        RegulatoryOrphanStatus: "15386",
        Biosimilars: "15386",
        InnovativeLicensingAndAccessPathway: "15386",
        RegulatoryWatch_ApprovedIndication: [ {} ],
      });
    } else if (datasourceVal == 267) {
      setValues({
        "IsActive": true
      });
    } else if (datasourceVal == 268) {
      const d = new Date();
      const d2 = d.toJSON().slice(0, 19).split('T');
      const val = d2[ 0 ].split('-')
      values.EndDate = `${val[ 1 ]}-${val[ 2 ]}-${val[ 0 ]}`;
      values.UserReportType = '0';
      setValues(values);
    } else if (datasourceVal == 269) {
      const d = new Date();
      const d2 = d.toJSON().slice(0, 19).split('T');
      const [ year, month, day ] = d2[ 0 ]?.split('-');
      values.EndDate = `${month}-${day}-${year}`;
      values.DataSourceID = '0';
      values.ToStatusID = '11';
      setValues(values);
    }
    else if (datasourceVal == 271) {
      const d = new Date();
      const d2 = d.toJSON().slice(0, 19).split('T');
      values.EndDate = d2[ 0 ];
      setValues(values);
    }
    else if (datasourceVal == 274 && !location.pathname.includes("confirm") && !confirmData) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const currDate = yyyy + '-' + mm + '-' + dd;
      setValues({
        ...values,
        [ "ExpirationDate" ]: currDate
      });
    }
    else if (datasourceVal == 275) {
      setValues({
        "IsActive": true,
        "ReceiveEmails": true
      });
    }
    else if (datasourceVal == 252) {
      setValues({
        "IsActive": true
      });
    }
  }

  const fetchMultiSelectValues = () => {
    let multiselect_api =
      dataSourceID < 250
        ? `/api/multiselectvalues/${datasourceVal}/${recordId}/${formMode}/${versionID}`
        : `/api/admin/multiselectvalues/${datasourceVal}/${recordId}`;
    apiCall(multiselect_api).then((response) => {
      let selLookup = response.data[ "multiselectValues" ];
      let selLookupSpecial = response.data[ "multiselectValuesSpecial" ];
      const s_data = response.data[ "subPageData" ];
      if (Number(datasourceVal) == 20 && response.data[ "subPageData" ][ "loe_brands" ]) {
        let newData: ILookupData[] = response.data[ "subPageData" ][ "loe_brands" ].map(
          (row: any) => ({
            ID: row[ "ID" ],
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: row[ "Value" ],
            isChecked: false,
            val: "",
          })
        );
        dispatch(setLoeBrandName(newData));
      }
      dispatch(setSubpageData(s_data));
      let newObj: IselectedLookupGlobe = {};
      let newObjSpecial: IselectedLookupGlobeSpecial = {};
      for (let i = 0; i < selLookup.length; i++) {
        if (selLookup[ i ] != null) {
          if (newObj.hasOwnProperty(selLookup[ i ][ "FieldSource" ])) {
            newObj[ selLookup[ i ][ "FieldSource" ] ].push({
              Id: selLookup[ i ][ "LookupId" ],
              Value: selLookup[ i ][ "Value" ],
            });
          } else {
            newObj[ selLookup[ i ][ "FieldSource" ] ] = [
              { Id: selLookup[ i ][ "LookupId" ], Value: selLookup[ i ][ "Value" ] },
            ];
          }
        }
      }
      for (let i = 0; i < selLookupSpecial.length; i++) {
        if (
          newObjSpecial.hasOwnProperty(selLookupSpecial[ i ][ "FieldSource" ])
        ) {
          newObjSpecial[ selLookupSpecial[ i ][ "FieldSource" ] ].push({
            Id: selLookupSpecial[ i ][ "LookupId" ],
            Value: selLookupSpecial[ i ][ "Value" ],
          });
        } else {
          newObjSpecial[ selLookupSpecial[ i ][ "FieldSource" ] ] = [
            {
              Id: selLookupSpecial[ i ][ "LookupId" ],
              Value: selLookupSpecial[ i ][ "Value" ],
            },
          ];
        }
      }

      if (dataSourceID == 275) {
        newObj[ 'Client_Group' ] = newObj[ 'User_Client_group' ]
        delete newObj[ 'User_Client_group' ];
      }

      dispatch(
        setSelectedLookupGlobe({ ...selectedLookupGlobeValue, ...newObj })
      );
      dispatch(
        setSelectedLookupGlobeDbVal({
          ...selectedLookupGlobeDbVal,
          ...newObj,
        })
      );
      dispatch(
        setSelectedLookupGlobeSpecial({
          ...selectedLookupGlobeSpecial,
          ...newObjSpecial,
        })
      );
    });
  }

  const checkWorkflowForAutoSave = ():boolean => {
    if (values != null && 
      values != undefined && 
      location.pathname.toLowerCase().includes("form") && 
      params.recordID ) {
      if (values["Status_ID"] == Status.WIPR && values["QAssociate"] == null) {
        return false
      }
      else if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && values["QAssociate"] == accountid && values["QReviewer"] != accountid) {
        return false
      }
      else if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && values["QReviewer"] == null) {
        return false
      }
      else if (values["Status_ID"] >= Status.RC && values["QAssociate"] == null && values["QReviewer"] == null) {
        return false
      }
      else if ((values["Status_ID"] == Status.A ||
        values["Status_ID"] == Status.AR ||
        values["Status_ID"] == Status.WIP ||
        values["Status_ID"] == Status.WIPR
      ) && values["QAssociate"] != accountid) {
        return false
      }
      else if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && !(values["QReviewer"] == accountid)) {
        return false
      }
      else if (values["Status_ID"] == Status.R && values["QAssociate"] == accountid && values["QReviewer"] != accountid) {
        return false
      }
      else if (values["Status_ID"] == Status.R && values["QReviewer"] != accountid) {
        return false
      }
    }
    return true
  }

  const checkWorkflow = () => {
    if (values != null && 
      values != undefined && 
      location.pathname.toLowerCase().includes("form") && 
      params.recordID ) {
      if (values["Status_ID"] == Status.WIPR && values["QAssociate"] == null) {
        ToastrAlert("No associate is assigned for this record. Please assign user to continue editing", "warning");
        redirectFunc();
      }
      if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && values["QAssociate"] == accountid && values["QReviewer"] != accountid) {
        ToastrAlert("This record is only editable by reviewer of the record", "warning");
        redirectFunc();
      }
      if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && values["QReviewer"] == null) {
        ToastrAlert("No reviewer is assigned for this record. Please assign user to continue editing", "warning");
        redirectFunc();
      }
      if (values["Status_ID"] >= Status.RC && values["QAssociate"] == null && values["QReviewer"] == null && !location.pathname.toLowerCase().includes("formclone")) {
        ToastrAlert("No associate/reviewer is assigned for this record. Please assign user to continue editing", "warning");
        redirectFunc();
      }
      if ((values["Status_ID"] == Status.A ||
        values["Status_ID"] == Status.AR ||
        values["Status_ID"] == Status.WIP ||
        values["Status_ID"] == Status.WIPR
      ) && values["QAssociate"] != accountid) {
        ToastrAlert("This record is only editable by associate of the record", "warning");
        redirectFunc();
      }
      if ((values["Status_ID"] == Status.SFR || 
        values["Status_ID"] == Status.SFRR
      ) && !(values["QReviewer"] == accountid)) {
        ToastrAlert("This record is only editable by reviewer of the record", "warning");
        redirectFunc();
      }
      if (values["Status_ID"] == Status.R && values["QAssociate"] == accountid && values["QReviewer"] != accountid) {
        redirectFunc();
      }
      if (values["Status_ID"] == Status.R && values["QReviewer"] != accountid) {
        ToastrAlert("This record is only editable by reviewer of the record", "warning");
        redirectFunc();
      }
    }
  }

  const redirectFunc = () => {
    const _pathname : string = navigate.location.pathname;
    !_pathname.toLowerCase().includes("formclone") && navigate.push(_pathname.replace("form", "view").replace("Form", "View"));    
  }

  const fieldChanged = (name?: string, value?: any, obj?: any,approved_indication?:boolean) => {
    let _values = values;

    if (obj != undefined && name != null) {
      // if (values.hasOwnProperty(name)) {
      //   setValues({ ...values, [name]: obj });
      // } else {
      
      if (approved_indication) {
        let _values = {...values};
        _values.PubDate =
          obj[obj.length - 1][
            "RegulatoryWatch_ApprovedIndications.ApprovalDate"
          ];
          setValues({ ..._values, [name]: obj });
      }else
        setValues( prev => ({...prev,[name]: obj}));
      // }
    } else if (name && String(name).includes(",")) {
      const names = name.split(",");
      const vals = value.split(",");
      let obj: any = {};

      names.forEach((x, i) => {
        obj[x] = vals[i];
      });
      setValues({ ...values, ...obj });
    } else if (name && !value) {
      setValues({ ...values, [name]: value });
      if (name && value == null) {
        delete values[name];
      }
      if (
        (value == dbValues[name] || value == null) &&
        fieldListAudit.indexOf(name) >= 0
      ) {
        let tempValue1 = fieldListAudit;
        tempValue1 = tempValue1.replace(name + ",", "");
        dispatch(setFieldListAudit(tempValue1));
      }
    } else if (name == null && value == null) {
      setValues({ ..._values });
    } else if (name && value) {
      if (name === "PubDate") {
        let _values = { ...values };
        if (
          values[name] ||
          _values.RegulatoryWatch_ApprovedIndication[
            _values.RegulatoryWatch_ApprovedIndication.length - 1
          ]["RegulatoryWatch_ApprovedIndications.ApprovalDate"]
        ) {
          const msg = `You are about to change the latest approval date "${
            values[name]
              ? values[name]
              : _values.RegulatoryWatch_ApprovedIndication[
                  _values.RegulatoryWatch_ApprovedIndication.length - 1
                ]["RegulatoryWatch_ApprovedIndications.ApprovalDate"]
          }" with "${value}". Do you want to proceed?`;
          const check = window.confirm(msg);
          if (check) {
            _values.RegulatoryWatch_ApprovedIndication[
              _values.RegulatoryWatch_ApprovedIndication.length - 1
            ]["RegulatoryWatch_ApprovedIndications.ApprovalDate"] = value;
            setValues({ ..._values, [name]: value });
          }
        } else {
          _values.RegulatoryWatch_ApprovedIndication[
            _values.RegulatoryWatch_ApprovedIndication.length - 1
          ]["RegulatoryWatch_ApprovedIndications.ApprovalDate"] = value;
          setValues({ ..._values, [name]: value });
        }
      }else if( name == "Field_CriteriaField_ID"){
        fetchDynamicField(value);
        // setValues({ ...values, [name]: value });
      } else {
        setValues({ ...values, [name]: value });
        if (value != dbValues[name] && fieldListAudit.indexOf(name) < 0) {
          fieldForAudit += name + ",";
          dispatch(setFieldListAudit(fieldListAudit + fieldForAudit));
        } else if (
          value == dbValues[name] &&
          fieldListAudit.indexOf(name) >= 0
        ) {
          let tempValue = fieldListAudit;
          tempValue = tempValue.replace(name + ",", "");
          dispatch(setFieldListAudit(tempValue));       
        }

        if (
          Number(dataSourceID) == 25 &&
          (name == "Country_ID" || name == "Drug_ID")
        ) {
          let _selectedLookupGlobe = {
            ...selectedLookupGlobeValue,
          };

          if (Object.keys(_selectedLookupGlobe).length > 0) {
            if (
              _selectedLookupGlobe.ReimbursementStatus_HTARecord?.length > 0
            ) {
              _selectedLookupGlobe = {
                ..._selectedLookupGlobe,
                ["ReimbursementStatus_HTARecord"]: [],
              };
              // delete _selectedLookupGlobe.ReimbursementStatus_HTARecord;
              const label = name == "Country_ID" ? "country" : "drug";
              const msg = `Related HTA selections are reset due to the ${label} change`;
              ToastrAlert(msg, "warning");
            }
            if (_selectedLookupGlobe.Related_Reimbursementrecords?.length > 0) {
              _selectedLookupGlobe = {
                ..._selectedLookupGlobe,
                ["Related_Reimbursementrecords"]: [],
              };
              // delete _selectedLookupGlobe.Related_Reimbursementrecords;
              const label = name == "Country_ID" ? "country" : "drug";
              const msg = `Related reimbursment selections are reset due to the ${label} change`;
              ToastrAlert(msg, "warning");
            }
            // delete _selectedLookupGlobe.ReimbursementStatus_RegulatoryWatch;

            // delete _selectedLookupGlobe.ReimbursementStatus_Disease;
            dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
          }

          let _lookupGlobe: ILookupGlobe = { ...lookupGlobe };
          delete _lookupGlobe.ReimbursementStatus_HTARecord;
          delete _lookupGlobe.ReimbursementStatus_RegulatoryWatch;
          delete _lookupGlobe.Related_Reimbursementrecords;

          dispatch(setLookupGlobe(_lookupGlobe));
          dispatch(setSuperDisease([]));
        }else if(Number(dataSourceID) ==275 && name == "Client_ID"){
          let _selectedLookupGlobe = {
            ...selectedLookupGlobeValue,
          };
          delete _selectedLookupGlobe.Client_Group;
          let _lookupGlobe: ILookupGlobe = { ...lookupGlobe };
          delete _lookupGlobe.Client_Group;
          dispatch(setLookupGlobe(_lookupGlobe));
          dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));          
         }

      }
    }
  };

  const RichtextFieldChanged = (name: string, value: EditorState) => {
    setValues({ ...values, [name]: value });
  };


  useEffect(() => {
    dispatch(setLookupData(lookupGlobe[lookupKey]));
  }, [lookupGlobe]);
 
  const showAlert = (
    title: any,
    type: any,
    message: any,
    url: any,
    modalValue: any
  ) => {
    setAlertState([title, type, message, url, modalValue]);
  };

  const findComments = async (value: string) => {
    if (value !== "" && record_ID && idColumnName) {
      let response = await apiCall(
        `/api/fetchComments/${value}/${record_ID}/${idColumnName}`
      );
      if (!!response && response.data.length > 0) {
        setCommentList(response.data);
      } else if (!!response && response.data.length === 0) {
        let tmp = commentList;
        if (tmp.length > 0) setCommentList([]);
      }
    }
  };

  useEffect(() => {
    let tmp: any = [];
    if (
      selectedLookupGlobeValue.HTARecord_Drug &&
      selectedLookupGlobeValue.HTARecord_Drug.length > 0
    ) {
      for (let x of selectedLookupGlobeValue.HTARecord_Drug) {
        tmp.push({ label: x.Value, id: x.Id });
      }
    }
    if (
      selectedLookupGlobeValue.HTARecord_ProcedureIntervention &&
      selectedLookupGlobeValue.HTARecord_ProcedureIntervention
        .length > 0
    ) {
      for (let x of selectedLookupGlobeValue
        .HTARecord_ProcedureIntervention) {
        tmp.push({ label: x.Value, id: x.Id });
      }
    }
    if (
      selectedLookupGlobeValue.HTARecord_Device &&
      selectedLookupGlobeValue.HTARecord_Device.length > 0
    ) {
      for (let x of selectedLookupGlobeValue.HTARecord_Device) {
        tmp.push({ label: x.Value, id: x.Id });
      }
    }

    if (
      drugCombination &&
      drugCombination.length > 0
    ) {
      let tmp2 = [];
      for (let items of drugCombination) {
        if (Object.entries(items).length > 0) {
          let labels = "";
          let ids = "";
          for (let x of Object.entries(items)) {
            let val : any = x;
            labels +=
              Number(val[0]) === 0 ||
              Number(val[0]) === Object.entries(items).length + 1
                ? val[1].Value
                : " + " + val[1].Value;
            ids +=
              Number(x[0]) === 0 ||
              Number(x[0]) === Object.entries(items).length + 1
                ? val[1].Id
                : " + " + val[1].Id;

            let index = tmp.findIndex(
              (item: any) => item.id === Number(val[1].Id)
            );
            if (index !== -1) {
              tmp.splice(index, 1);
            }
          }
          tmp2.push({ labels, id: ids });
        }
      }
      tmp.push(...tmp2);
    }
    if (tmp.length > 0) {
      dispatch(setEndpointResultOption(tmp));
    }
  }, [
    selectedLookupGlobeValue.HTARecord_Drug,
    selectedLookupGlobeValue.HTARecord_ProcedureIntervention,
    selectedLookupGlobeValue.HTARecord_Device,
    drugCombination,
  ]);


  return (
    <>
      {!isLoading && (
        <UserContext.Provider
          value={{
            showAlert: showAlert,
            countryList : countryList,
            countryNames : countryNames,
            drugSpecificDataList: drugSpecificDataList,
            mandatoryFields : mandatoryFields,
            conditionalFormFields : conditionalFormFields,
            setConditionalFormFields : setConditionalFormFields,
            onClick : onClick,
            setValues : setValues
            // showMultiSelectPopup: showMultiSelectPopup,
            // setLookupGlobe: LookupglobeSet,
            // setSelectedlookupglobe: selectedlookupGlobeSet,
            // selectedLookupGlobe : selectedLookupGlobe
          }}
        >
          {!recordNotFound &&  <>
           <SectionView
            sections={srows}
            dataSourceID={dataSourceID}
            values={values}
            fieldChanged={fieldChanged}
            RichtextFieldChanged={RichtextFieldChanged}
            record_ID={record_ID}
            findComments={findComments}
            commentList={commentList}
            currRole={Number(currRoleId)}
            setLookupLabelList={AddLookupLabelList}
            formMode={formMode}
            dynamicField={dynamicField}
          ></SectionView>
          <ScrollButton/>
          </>
          }
          {recordNotFound && 
            <RecordNotFound></RecordNotFound>
          }
          <NewAlert alertState={alertState} setModalShow={setAlertState} />
          {/* <NewModal /> */}
        </UserContext.Provider>
      )}
      {isLoading && <div style={{marginLeft:"3em"}}>Form is being loaded...</div>}
    </>
  );
}

export default Editor;
