import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import TableGrid from "../../Table/TableGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { addDate } from "../../../utils/index";
import Loader from "../../../utils/Loader";
import { AgGridReact } from "ag-grid-react";

const Pricing = () => {
  const state = useAppSelector((state: any) => state);
  const [rowData, setRowData] = useState<any[]>([]);

  const filterData = () => {
    const filteredData = state.productProfile.pricingData.filter(
      (item: any) => item.ParallelImport === false
    );
    setRowData(filteredData);
    // console.log(filteredData, "filteredData");
  };

  useEffect(() => {
    filterData();
  }, []);

  const columnDefs = [
    {
      headerName: "Title",
      field: "Name",
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Launch date",
      field: "LaunchDate",
      cellRenderer: (params: any) => {
        const dateArray =
          params.value !== null && params.value !== undefined
            ? addDate(params.value)
            : "-";
        return (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
          >
            {" "}
            {dateArray}
          </span>
        );
      },
      wrapText: true,
      autoHeight: true,
      minWidth: 130,
    },
    {
      headerName: "Currency",
      field: "Currency",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Most recent price (date)",
      field: "PricePointsDateMostRecent",
      cellRenderer: (params: any) => {
        const dateArray =
          params.value !== null && params.value !== undefined
            ? addDate(params.value)
            : "-";
        return (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
          >
            {" "}
            {dateArray}
          </span>
        );
      },
      wrapText: true,
      autoHeight: true,
      minWidth: 220,
    },
    {
      headerName: "MSP (most recent)",
      valueGetter: function (params: any) {
        return params.data.PricePointsMSPMostRecent
          ? params.data.PricePointsMSPMostRecent
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 180,
    },
    {
      headerName: "LP (most recent)",
      valueGetter: function (params: any) {
        return params.data.PricePointsLPMostRecent
          ? params.data.PricePointsLPMostRecent
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Price to hospital (most recent)",
      valueGetter: function (params: any) {
        return params.data.PricePointsPriceToHospitalMostRecent
          ? params.data.PricePointsPriceToHospitalMostRecent
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 250,
    },
    {
      headerName: "Manufacturer (PI)",
      valueGetter: function (params: any) {
        return params.data.Manufacturer ? params.data.Manufacturer : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 180,
    },
    {
      headerName: "Corporation (PI)",
      valueGetter: function (params: any) {
        return params.data.Corporation ? params.data.Corporation : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 170,
    },

    {
      headerName: "",
      field: "Pricing_ID",
      cellRendererFramework: (params: any) => (
        <a
          href={`/view/4/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      ),
      maxWidth: 50,
      width: 50,
      minWidth: 50,
    },
  ];

  return (
    <div className="pricingDataContainer">
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <TableGrid
            tableName={"ProductProfile"}
            columnDefs={columnDefs}
            rowData={rowData}
            isLoading={false}
            pageSize={10}
          />
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "200px", width: "90%", marginTop: 10 }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={"No data available in table"}
          />
        </div>
      )}
    </div>
  );
};

export default Pricing;
