import React, { useEffect, useState } from "react";
import "./ReadMore.css";

import DOMPurify from 'dompurify';

interface ReadMoreProps {
  children: string
}
const ReadMore = ({ children }: ReadMoreProps) => {
  const text: string = children;
  const [ isLongText, setIsLongText ] = useState(false); // text.length > 40 ? true : false
  const [ isReadMore, setIsReadMore ] = useState(true);
  const [ txt, setTxt ] = useState(text);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }


  // Helper function to remove all images from HTML content
  const removeImagesFromHtml = (htmlContent) => {
    // Parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Remove all <img> elements
    const images = doc.querySelectorAll('img');
    images.forEach(img => img.remove());

    return doc;
  };

  // Helper function to trim HTML content to a specified number of characters
  const trimHtmlContent = (doc, maxLength) => {
    // Extract text content
    const textContent = doc.body.textContent || "";

    if (textContent.length < 180) {
      setIsLongText(false);
      return doc.body.innerHTML;
    }

    setIsLongText(true);

    // Trim the text content to the desired length
    const trimmedText = textContent.trim().substring(0, maxLength);

    // Reconstruct the HTML with the trimmed text
    let charCount = 0;
    const traverseAndTrim = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = maxLength - charCount;
        if (node.textContent.length > remainingChars) {
          node.textContent = node.textContent.substring(0, remainingChars);
          charCount = maxLength; // We are done trimming
        } else {
          charCount += node.textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(traverseAndTrim);
      }
    };

    traverseAndTrim(doc.body);

    return doc.body.innerHTML;
  };

  useEffect(() => {
    const maxLength = 180;
    const docWithoutImage = removeImagesFromHtml(text);

    // Trim and sanitize the HTML content
    const trimmedContent = isReadMore ? trimHtmlContent(docWithoutImage, maxLength) : docWithoutImage.body.innerHTML;
    let sanitizedContent = DOMPurify.sanitize(trimmedContent);
    sanitizedContent = sanitizedContent.replace(/<\/?[^>]+(>|$)/g, "");

    setTxt(sanitizedContent);
  }, [ text, isReadMore ]);

  return (
    <>
      <label
        style={{ paddingLeft: "4px", paddingTop: "2px", display: "inline" }}
        dangerouslySetInnerHTML={{
          __html: txt,
          // __html: DOMPurify.sanitize(isReadMore ? text.slice(0, 180) : text),
        }}
      ></label>
      {isLongText && <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? " ...more" : " ...less"}
      </span>}
    </>
  )
}

interface ContentProps {
  txt: string
}
const Content = ({ txt }: ContentProps) => {
  return (
    <ReadMore>
      {txt}
    </ReadMore>
  )
}

export default Content;
