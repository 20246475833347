import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { CompareElement } from "../SubPages/Views/Utility";
import { SubGroup } from "./CompareRecords";


interface IHTARecord_SubGroupPropCompareRecords {
      fieldID: number;
      datasourceName: string;
      section: string;
      subGroup_data: SubGroup[];

}

const HTARecord_SubGroupPropCompareRecords = ({
      fieldID,
      datasourceName,
      section,
      subGroup_data
}: IHTARecord_SubGroupPropCompareRecords) => {
      return (
            <>
                  {subGroup_data && subGroup_data.length > 0 &&
                        subGroup_data.map((el: any, index: number) => (
                              <div className="col-sm-12">
                                    <>
                                          <CompareElement
                                                label={`Subgroup ${index + 1}`}
                                                value1={String(el[ "SubGroupIDText" ][ 0 ] || "")}
                                                value2={String(el[ "SubGroupIDText" ][ 1 ] || "")}
                                                datasourceName={datasourceName}
                                                section={section}
                                                showInfoIcon={false}
                                          />
                                    </>
                                    {fieldID == 526 && (el[ "Recommendation" ][ 0 ] != null || el[ "Recommendation" ][ 1 ]) && (
                                          <>
                                                <CompareElement
                                                      label={`Recommendation - Subgroup ${index + 1}`}
                                                      value1={String(el[ "Recommendation" ][ 0 ] || "")}
                                                      value2={String(el[ "Recommendation" ][ 1 ] || "")}
                                                      datasourceName={datasourceName}
                                                      section={section}
                                                      showInfoIcon={false}
                                                />
                                          </>
                                    )}
                                    {fieldID == 526 && (el[ "SMR" ][ 0 ] != null || el[ "SMR" ][ 1 ] != null) && (
                                          <>
                                                <CompareElement
                                                      label={`SMR - Subgroup ${index + 1}`}
                                                      value1={String(el[ "SMR" ][ 0 ] || "")}
                                                      value2={String(el[ "SMR" ][ 1 ] || "")}
                                                      datasourceName={datasourceName}
                                                      section={section}
                                                      showInfoIcon={false}
                                                />
                                          </>
                                    )}
                                    {fieldID == 526 && (el[ "ASMR" ][ 0 ] != null || el[ "ASMR" ][ 1 ] != null) && (
                                          <>
                                                <CompareElement
                                                      label={`ASMR - Subgroup ${index + 1}`}
                                                      value1={String(el[ "ASMR" ][ 0 ] || "")}
                                                      value2={String(el[ "ASMR" ][ 1 ] || "")}
                                                      datasourceName={datasourceName}
                                                      section={section}
                                                      showInfoIcon={false}
                                                />
                                          </>
                                    )}
                                    {fieldID == 526 && (el[ "BenifitRating" ][ 0 ] != null || el[ "BenifitRating" ][ 1 ] != null) && (
                                          <>
                                                <CompareElement
                                                      label={`BenifitRating - Subgroup ${index + 1}`}
                                                      value1={String(el[ "BenifitRating" ][ 0 ] || "")}
                                                      value2={String(el[ "BenifitRating" ][ 1 ] || "")}
                                                      datasourceName={datasourceName}
                                                      section={section}
                                                      showInfoIcon={false}
                                                />
                                          </>
                                    )}
                              </div>
                        ))}
            </>
      )
}

export default HTARecord_SubGroupPropCompareRecords;