import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  ColDef,
  ColGroupDef,
  Grid,
  GridOptions,
  GridReadyEvent,
  IsRowSelectable,
  RowNode,
  FirstDataRenderedEvent
} from 'ag-grid-community';
import {
  ButtonDropdown,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { IOlympicData } from './interfaces';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetAdminPageMutation } from '../../store/AdminApiSlice';
import { saveSearchData, setColumns, setDataSource } from '../../store/SearchSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFetchUserAccountIdMutation } from "../../store/UserProfileApiSlice";
import { useHistory, useParams } from 'react-router';
import SectionDropDown from './FieldManagement/SectionDropdown';
import DatasourceDropDown from './FieldManagement/DatasourceDrodown';
import { exportGetCall } from '../service/exportCall';
import apiCall from '../service/apiCall';
import axiosInstance from '../service/axiosInstance';
// --------- date comparator for sorting date column -------------
function dateComparator(date1: string, date2: string) {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}

// eg 29/08/2004 gets converted to 20040829
function monthToComparableNumber(date: string) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = Number.parseInt(date.substring(6, 10));
  const monthNumber = Number.parseInt(date.substring(3, 5));
  const dayNumber = Number.parseInt(date.substring(0, 2));
  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
}
// ---------------------------------------------------------------------
interface AdminGridProps {
  dataSourceID: number;
  Title: string;
  isAdminData: boolean;
  recordID:number;
}
const AdminGrid = (props: AdminGridProps) => {
  let { dataSourceID, Title,recordID, isAdminData } = props;
  const dispatch = useAppDispatch();
  const searchData = useAppSelector((state) => state.search.searchData);
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<any[]>();
  const [isViewMenuOpen, setIsViewMenuOpen] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const currUserId = sessionStorage.getItem("userid");
  const [fetchUserAccountId] = useFetchUserAccountIdMutation();
  const [roleId, setRoleId] = React.useState<number>(1);
  const [currClientId, setCurrClientId] = React.useState<number>(0);
  const [currAccountId, setCurrAccountId] = React.useState<number>(0);
  const [fieldDatasourceId, setFieldDatasourceId] = useState<number>(recordID);
  const [fieldSectionId, setFieldSectionId] = useState<number>(47);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [showInactive,setShowInactive] = useState<number>(3);
  const [pageTitle, setPageTitle] = useState<string>("Untitled");
  const navigate = useHistory();

  const urlParams: any = useParams();

  let _token = localStorage.getItem("token");

  useEffect(() => {
    if (_token) {
      const tokenData = JSON.parse(atob(_token.split('.')[1]))
      getAccoundId(tokenData.userid, tokenData.role, tokenData.clientId);
    }
  }, [_token]);

  const getAccoundId = async (userId: number, roleId: number, clientId: number) => {
    const accountId: any = await fetchUserAccountId({ userId, roleId, clientId });
    setCurrClientId(accountId.data.ClientId);
    setCurrAccountId(accountId.data.ID);
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      sortable: true
    };
  }, []);

  const viewMenuToggle = () => {
    setIsViewMenuOpen(!isViewMenuOpen);
  }

  const [getAdminPage, { isLoading }] = useGetAdminPageMutation();

  const GetSearchData = async (payload: any) => {
    const searchResult = await getAdminPage(payload);

    //dispatch(resetSearchResult());

    dispatch(setDataSource(dataSourceID));

    switch (dataSourceID) {
      case 252:
        dispatch(setColumns(["Name", ""]));
        break;
      case 253:
        dispatch(setColumns(["Name", "Generic", "Manufacturer"]));
        break;
      case 255:
        dispatch(setColumns(["Name", "Display Name", "Therapeutic Area", "ICD Code"]));
        break;
      case 256:
        dispatch(setColumns(["Name", "Display Name", "Parent Disease", "TherapeuticArea", "ICD Code"]));
        break;
      case 257:
        dispatch(setColumns(["Name", "Display Name", "Parent Disease Subtype1", "Parent Disease", "TherapeuticArea", "ICD Code"]));
        break;
      case 258:
        dispatch(setColumns(["Name", "Display Name", "Parent Disease Subtype2", "Parent Disease Subtype1", "Parent Disease", "TherapeuticArea", "ICD Code"]));
        break;
      case 259:
        dispatch(setColumns(["Name", "Display Name", "Parent Disease Subtype3", "Parent Disease Subtype2", "Parent Disease Subtype1", "Parent Disease", "TherapeuticArea", "ICD Code"]));
        break;
      case 261:
        dispatch(setColumns(["Code", "Value", "Associated Status(es)"]));
        break;
      case 262:
        dispatch(setColumns(["Instrument (abbreviation)", "Instrument name (expansion)", "Parent instrument (abbreviation)", "Type of COA"]));
        break;
      case 264:
        dispatch(setColumns(["ID", "Name", "Type", "Visible", "Read Only", "Show Rules", "Sort"]));
        break;
      case 265:
        dispatch(setColumns(["Datasource","Client","Client group","User Count","Edit"]));
        break;
      case 267:
        dispatch(setColumns(["Value", "Active"]));
        break;
      case 270:
        if (location.href.toLowerCase().indexOf('admin') > 0)
          dispatch(setColumns(["Report Name", "Client","ClientGroup","User Count"]));
        else
          dispatch(setColumns(["Report Name", "Description"]));
        break;
      case 273:
        dispatch(setColumns(["Name", "DatasourceID", "CreatedDate"]));
        break;
      case 274:
        dispatch(setColumns(["ID", "Name", "Subscription Type", "Expiration Date"]));
        break;
      case 275:
        dispatch(setColumns(["Name", "Client", "Role", "ID"]));
        break;
      default:
        dispatch(setColumns(["Name", "Description"]));
        break;
    }
    dispatch(saveSearchData(searchResult));
  }

  useEffect(() => {
    let Client_ID;
    if ((dataSourceID == 252 || dataSourceID == 267 ) && urlParams.recordID)
      Client_ID = urlParams.recordID;
    else
      Client_ID = currClientId
    GetSearchData({
      dataSourceID: dataSourceID,
      userId: Number(currUserId),
      isAdminData: isAdminData,
      clientId: Client_ID,
      fieldDatasourceId: fieldDatasourceId
    });
  }, [dataSourceID, currClientId, fieldDatasourceId]);

  useEffect(() => {
    if(dataSourceID == 264)
      filterRowData()
  }, [fieldSectionId]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    filterRowData()
    setColumnDefs(searchData.colDef);
    setDataSource(dataSourceID);
  }, [searchData]);

  const filterRowData = () => {
    let filteredRowData;
    if (dataSourceID == 264)
      filteredRowData = searchData.rowData.filter((el: any) => el.section_id == fieldSectionId);
    else if (dataSourceID > 250 && dataSourceID != 267) {
      filteredRowData = searchData.rowData.filter((el: any) => el.IsActive == 1);
      setShowInactive(1);
    }
    else {
      filteredRowData = searchData.rowData;
      setShowInactive(3);
    }
    setRowData(filteredRowData);
  }

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (params: RowNode<IOlympicData>) => {
      //   return !!params.data && params.data.year === 2012;
      return true;
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById('grid-free-text-search') as HTMLInputElement).value
    );
  }, []);

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<IOlympicData>) => {
      gridRef.current!.api.forEachNode((node) =>
        node.setSelected(false)
      );
      gridRef.current!.api.sizeColumnsToFit({
        defaultMinWidth: 100,
        // columnLimits: [{ key: 'country', minWidth: 900 }],
      });
    },
    []
  );

  const CreateNew = () => {
    if (dataSourceID == 252 ) {
      location.href = `/admin/clientgroup/create/${dataSourceID}/${urlParams.recordID}`;
    }else if(dataSourceID == 267){
      location.href = `/admin/lookupItem/create/${dataSourceID}/${urlParams.recordID}`;
    } else
      location.href = `/admin/form/${dataSourceID}`;
  }

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    console.log('selectedRows: ', selectedRows);
    (document.querySelector('#selectedRows') as any).innerHTML =
      selectedRows.length === 1 ? selectedRows[0].athlete : '';
  }, []);

  const isExternalFilterPresent = () => {
    // if ageType is not everyone, then we are filtering
    return applyFilter == true;
  };

  const doesExternalFilterPass = (node: any) => {
    if (node.data) {
      if (showInactive)
        return node.data.IsActive == false || node.data.is_removed == true || node.data.IsDeleted == true;
      else
        return node.data.IsActive != false && node.data.is_removed != true && node.data.IsDeleted != true;
    }
    return true;
  };

  const changeFilter = (type: number) => {
    let _rowData: any[] = searchData.rowData;
    if(type == 1){
      _rowData = _rowData.filter((el:any) => el.IsActive == 1);
      // setApplyFilter(false);
    }else if (type == 2){
      _rowData = _rowData.filter((el:any) => el.IsActive != 1);
      // setApplyFilter(true);
    }
    setShowInactive(type);
    setRowData(_rowData);
    // gridRef.current!.api.onFilterChanged();
  };


  const getLookupName = async() => {
    const res:any = await apiCall(`/api/admin/lookup/name/${urlParams.recordID}`);
    setPageTitle(res.data.Title);
  }

  useEffect(() => {
    let _pageTitle = ''
    switch (dataSourceID) {
      case 251: _pageTitle = 'Client management'; break;
      case 252: _pageTitle = 'Client groups'; break;
      case 253: _pageTitle = 'Device'; break;
      case 254: _pageTitle = 'Disease Display Name'; break;
      case 255: _pageTitle = 'Diseases'; break;
      case 256: _pageTitle = 'Disease Subtype 1'; break;
      case 257: _pageTitle = 'Disease Subtype 2'; break;
      case 258: _pageTitle = 'Disease Subtype 3'; break;
      case 259: _pageTitle = 'Disease Subtype 4'; break;
      case 260: _pageTitle = 'Therapeutic area'; break;
      case 261: _pageTitle = 'Workflow Reasons Management'; break;
      case 262:
        if (showInactive == 1)
          _pageTitle = 'Active Instrument';
        else if (showInactive == 2)
          _pageTitle = 'De-Active Instrument';
        else _pageTitle = 'Instrument';
        break;
      case 263: _pageTitle = 'Field Management - Select a Data source'; break;
      case 264: _pageTitle = 'Field Management'; break;
      case 265: _pageTitle = 'Disable export options'; break;
      case 266: _pageTitle = 'Lookups'; break;
      case 266: _pageTitle = 'Lookup'; break;
      case 267: 
          getLookupName();
          break;
      case 270: _pageTitle = 'Dashboards'; break;
      case 272: _pageTitle = 'Product profile readiness'; break;
      case 273: _pageTitle = 'Saved searches and alerts'; break;
      case 274: _pageTitle = 'Subscriptions'; break;
      case 275: _pageTitle = 'Users'; break;
      default: break;
    }
    setPageTitle(_pageTitle);
  },[dataSourceID])

  useEffect(() => {
    if (dataSourceID == 262) {
      let _pageTitle = 'Instrument';
      if (showInactive == 1)
        _pageTitle = 'Active Instrument';
      else if (showInactive == 2)
        _pageTitle = 'De-Active Instrument';
      setPageTitle(_pageTitle);
    }
  }, [showInactive])


  useEffect(() => {
      document.title = pageTitle;
  }, [pageTitle]);


  const datasourceOnchangeHandler = (e: any) => {
    setFieldDatasourceId(Number(e.target.value))
  }

  const exportFieds = () => {
    const url =
      process.env.REACT_APP_API_URL +
      `/api/export/FieldBySection/${fieldDatasourceId}`;
    const headers = { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" };
    axiosInstance.get(
      url,
      {
        responseType: "blob",
        headers: headers
      },
    ).then((resp: any) => {
      const href = URL.createObjectURL(new Blob([ resp.data ]));

      const link = document.createElement("a");

      link.href = href;

      const currentdate = new Date();
      const datetime =
        "_" +
        currentdate.getFullYear() +
        (currentdate.getMonth() + 1) +
        "" +
        currentdate.getDate() +
        "_" +
        currentdate.getHours() +
        +currentdate.getMinutes();

      link.setAttribute(
        "download",
        `SectionByFieldList.xlsx`
      );
      document.body.appendChild(link);

      link.click();
      // fs.writeFileSync(`${datasourceName[datasource_id]}_${datetime}.xlsx`, resp.data);

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch((x: any) => {
      console.log(x);
    })
  }

  const exportUsers = () => {
    exportGetCall(`/api/export/userslist/full`,"AllUsersList.xlsx");
  }

  return (
    <div style={containerStyle}>
      <div className="outer-div">
        {/* <div className="button-bar">
          <button onClick={sizeToFit}>Size to Fit</button>
        </div> */}

        <h4 className="text-left">{pageTitle}</h4>
        {dataSourceID == 275 && <button onClick={exportUsers} style={{ cursor:"pointer",background: "none", border: "none", color: "rgb(0, 170, 231)",float:"left",padding:"0px" }}>Export all users list</button>}

        {dataSourceID === 265 && <button style={{color:"rgb(0, 170, 231)",paddingLeft:"0px"}} className="btn" onClick={CreateNew}>
                Disable new
              </button>}
        <div className="text-right">
          {dataSourceID != 263 &&
            dataSourceID != 264 &&
            location.href.toLowerCase().indexOf("admin") > 0 && (
              <>              
                {dataSourceID !==265 && <button className="btn" onClick={CreateNew}>
                  <AddCircleOutlineIcon />
                 Create new
                </button>}
               { (dataSourceID !==265 && dataSourceID !==266 && dataSourceID !==270)  && <ButtonDropdown isOpen={isViewMenuOpen} toggle={viewMenuToggle} color='danger' className='btn btn-link'>
                  <DropdownToggle caret color='light'><VisibilityIcon />View</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>Status</DropdownItem>
                    <DropdownItem onClick={() => changeFilter(1)}>Active</DropdownItem>
                    <DropdownItem onClick={() => changeFilter(2)}>Inactive</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => changeFilter(3)}>Show all</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>}
              </>
            )}
        </div>
        {
          dataSourceID == 264 && <>
            <DatasourceDropDown fieldDataSourceId={fieldDatasourceId} setFieldDatasourceId={setFieldDatasourceId} />
            <div>
              <button onClick={() => exportFieds()} style={{ background: "none", border: "none", color: "rgb(0, 170, 231)",float:"right" }}>ExcelExport</button>
            </div>

            <SectionDropDown fieldDataSourceId={fieldDatasourceId} roleId={roleId} fieldSectionId={fieldSectionId} setFieldSectionId={setFieldSectionId} />

          </>
        }
        {dataSourceID != 264 && <div className="searchtoolbar">
          <span id="filter-label">Filter search results : </span>
          <input
            type="text"
            id="grid-free-text-search"
            placeholder="Enter Search Terms"
            onInput={onFilterTextBoxChanged}
          />
        </div>}
        {isLoading && (
          <div style={{ textAlign: "center", paddingTop: "100px" }}>
            <Spinner animation="border" style={{ color: "#00aae7" }} />
            <h6>Loading search result...</h6>
          </div>
        )}
        {!isLoading &&
          searchData &&
          searchData.rowData &&
          searchData.rowData.length > 0 && (
            <div className="grid-wrapper">
              <div
                style={{ width: "100%", height: 500 }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection={"single"}
                  suppressRowClickSelection={true}
                  isRowSelectable={isRowSelectable}
                  onFirstDataRendered={onFirstDataRendered}
                  onSelectionChanged={onSelectionChanged}
                  pagination={true}
                  paginationPageSize={100}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                ></AgGridReact>
              </div>
            </div>
          )}
        <div id="selectedRows"></div>
      </div>
      {
                      (dataSourceID == 267) &&
                      <button
                        onClick={() => navigate.push(`/admin/list/266`)}
                        style={{ background: "none", border: "none", color: "rgb(0, 170, 231)" }}
                      >
                        Back to List
                      </button>
                    }
    </div>
  );
};

export default AdminGrid;