import React, { useEffect, useState } from "react";
import { UserContext } from "../../Editor";
import { useAppSelector } from "../../../../store/hooks";
import Element, { DosageElement, DosageTableElement } from "./Utility";
import apiCall from "../../../service/apiCall";

interface IRegulatoryDosageAndMode {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
}

const Regulatory_DosageAndMode = ({
  fieldInfo,
  datasourceName,
  section,
}: IRegulatoryDosageAndMode) => {
  const [field, setField] = useState<any[]>([]);
  const [dosageAndMode, setDosageAndMode] = useState<any>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);

  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      (async () => {
        const res = await apiCall("/api/sub-page/dosage/fields");
        setField(res.data);
      })();
    }
  }, [fieldInfo]);

  useEffect(() => {
    setDosageAndMode(subPageDate["dosage_and_mode_regulatory"]);
  }, [subPageDate]);


  return (
    <>
      {!!dosageAndMode && Object.keys(dosageAndMode).length > 0 && Object.keys(dosageAndMode).map((key: string) => (
        <>
          {
            <div className="col-sm-12">
              <b>{key}</b>
              <hr />
              {field.map((val) => (
                <>
                  <Element
                    label={val.Label}
                    fieldType={val.Field_Type_ID}
                    value={!!dosageAndMode[key]? dosageAndMode[key][val.Name.split(".")[1]] : null}
                    datasourceName={datasourceName}
                    section={section}
                    description={val.DescriptionClient}
                  />
                  {!!val.Name && val.Name.split(".")[ 1 ] == "CyclesPerYear"
                                           &&   dosageAndMode[ key ][ "DaysPerCycle" ] > 0
                                          
                    && (
                    <>
                      {!!dosageAndMode[ key ][ "cycles" ] &&
                        dosageAndMode[ key ][ "cycles" ].length > 0 &&
                        dosageAndMode[ key ][ "cycles" ].map((x: any) => (
                          <>
                            <hr />
                             {
                              // dosageAndMode[ key ][ "DaysPerCycle" ] && 
                             Object.keys(x).map((x_key: string) => (
                              <>
                                {field
                                  .filter(
                                    (f) =>
                                      f.Name ==
                                      "RegulatoryWatch_DosageAndMode." + x_key
                                  )
                                  .map((l) => l[ "Label" ])[ 0 ] && (
                                    <Element
                                      label={
                                        field
                                          .filter(
                                            (f) =>
                                              f.Name ==
                                              "RegulatoryWatch_DosageAndMode." +
                                              x_key
                                          )
                                          .map((l) => l[ "Label" ])[ 0 ]
                                      }
                                      fieldType={val.Field_Type_ID}
                                      value={x[ x_key ]}
                                      datasourceName={datasourceName}
                                      section={section}
                                      description={val.DescriptionClient}
                                    />
                                  )}
                                {x_key == "DosesPerCycle" && (
                                  <DosageTableElement
                                    label="Doses per cycle"
                                    value={x[ x_key ]}
                                    DaysPerCycle={Number(dosageAndMode[ key ][ "DaysPerCycle" ])}
                                  />
                                )}
                                {x_key == "TotalDosagePerCycle" && (
                                  <DosageElement
                                    label={"Total dosage per cycle"}
                                    value={x[ x_key ]}
                                  />
                                )}
                              </>
                            ))}
                          </>
                        ))}
                      <hr />
                    </>
                    )}
                </>
              ))}
            </div>
          }
        </>
      ))}
    </>
  );
};

export default Regulatory_DosageAndMode;
