import React, { useEffect, useState } from "react";
import { Chart, ReactGoogleChartEvent } from 'react-google-charts';
import { IfilterState } from "../Dashboard/filter";
import apiCall, { _userId } from "../service/apiCall";
import PostCall from "../service/postCall";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  saveSearchData,
  setPathwayFilterState,
  setInitiateSearch,
  setIsSearchGridLoading,
  setIsPathwaySearch,
  setFilterColumnTypes,
  setSelectedColumnFiters,
  setColumnFilters,
  setTotalResultCount,
} from "../../store/SearchSlice";
import { useGetPageMutation } from "../../store/SearchResultApiSlice";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import {
  setPathwayFilter,
  setLevel,
  setPathwayData,
  setSearchParams,
  setSearchParamsState,
  setChangeFilter,
  setMaxLevel,
  setvisibleColumns,
} from "../../store/pathwaySlice";
import { IselectedLookup } from "../interfaces/multiselect";
import { setSelectedLookupGlobe } from "../../store/MultiSelectSlice";
import "./charts.css";
import "../../app.css";
import { Spinner } from "reactstrap";

interface chartProp {
  ds_id: number;
  setSelectedCriteria: any;
  setPathwaySearch: any;
  pathwaySearch?: boolean;
  pathwayLevel: string[];
  pathwayFixed: boolean;
  pathwayPending: boolean;
  setPathwayPending: (val: boolean) => void;
  pathwayFilterLevel: number;
  setPathwayFilterLevel: (val: number) => void;
  currpathwayList: string[];
  defaultColumn: any;
  setPageSize: any;
  setCurrentPage:any;

}

export interface IPathway {
  [num: string]: string[];
}

interface IchartOptions {
  Category: string[];
  Count: number[];
}

interface Idata {
  [key: string]: string;
}

// const pathway_level = [
//   "PrimaryTherapeuticAreaIDs",
//   "PrimaryDiseaseIDs",
//   "DrugIDs",
//   "DrugIDs",
//   "CountryIDs",
//   "AssessmentStatusIDs",
// ];

const searchParamsKeys_p1 = [
  "TherapeuticAreaIDs",
  "DiseaseIDs",
  "DrugIDs",
  "DrugIDs",
  "CountryIDs",
  "AssessmentStatusIDs",
];

const searchParamsKeys_p2 = [
  "DiseaseIDs",
  "DrugIDs",
  "DrugIDs",
  "CountryIDs",
  "AssessmentStatusIDs",
];

interface IColumns {
  [key: string]: string[];
}

export default function PathwayChart(prop: chartProp) {
  const {
    ds_id,
    setSelectedCriteria,
    setPathwaySearch,
    pathwaySearch,
    pathwayLevel,
    pathwayFixed,
    pathwayPending,
    setPathwayPending,
    pathwayFilterLevel,
    setPathwayFilterLevel,
    currpathwayList,
    defaultColumn,
    setPageSize,
    setCurrentPage,
  } = prop;
  const pathwayData = useAppSelector((state) => state.pathway.pathwayData);
  const [searchParamsKeys, setsearchParamsKeys] = useState<string[]>([]);
  const [count, setCount] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const columns = useAppSelector((state) => state.pathway.columnsData);
  //const defaultColumn = useAppSelector((state) => state.search.defaultColumn);
  const dataSourceId = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const pathway_filter = useAppSelector((state) => state.pathway.pathwayFilter);
  const [t_id, setT_id] = useState<string>("");
  const level = useAppSelector((state) => state.pathway.level);
  const maxLevel = useAppSelector((state) => state.pathway.maxLevel);
  const searchParams = useAppSelector((state) => state.pathway.searchParams);
  const pathwayRule = useAppSelector((state) => state.pathway.pathwayRule);
  const searchParamsState = useAppSelector(
    (state) => state.pathway.searchParamsState
  );
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const defaultColumns :string[] = useAppSelector((state) => state.search.defaultColumn.showColumnsNames);
  const visibleColumns = useAppSelector((state) => state.pathway.visibleColumns);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }: { chartWrapper: any }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length > 0) {
          const [ selectedItem ] = selection;
          const dataTable = chartWrapper.getDataTable();
          const value = dataTable.getValue(selectedItem.row, 0);

          let len = searchParamsKeys.length;
          let data: Idata = {};
          let d_id = ds_id;
          if (pathwayRule === "pathway-c-1") {
            d_id = 2;
            len = 4;
          } else if (pathwayRule === "pathway-r-1") {
            d_id = 3;
            len = 5;
          }

          if (
            (dataSourceId === 2 && level + 1 === 4) ||
            (dataSourceId === 3 && level + 1 === 5)
          )
            return;
          if (len == level + 1) {
            return;
          }
          setPathwaySearch(false);
          dispatch(setInitiateSearch(true));
          dispatch(setIsSearchGridLoading(true));

          for (let i = 0; i < level; i++) {
            data[ `level_${i}` ] = pathway_filter[ i ];
          }

          data[ "UserID" ] = Number(_userId).toString();
          data[ `level_${level}` ] = value;
          let arr = [ ...pathway_filter ];

          arr.push(value);

          const npf = pathway_filter.filter((x, i) => i <= level - 1);

          dispatch(
            setPathwayFilter([ ...npf, value ])
          );

          //let params = {...searchParams};
          const p_key: string = `${d_id}_${level + 1}`;

          PostCall(
            `/api/pathway/get/search/${ds_id}/${pathwayRule}/${level}`,
            data
          ).then((resp) => {
            if (dataSourceId == 2) {
              if (level + 1 == 1) {
                dispatch(setChangeFilter(1));
              } else if (level + 1 == 2) {
                dispatch(setChangeFilter(2));
              }
            }

            if (
              currpathwayList[ level ] !==
              "HTAs by ATC 4th Level Description" &&
              currpathwayList[ level ] !== "Trials by ATC 4th level description"
                &&
              currpathwayList[level] !== "MAs by ATC 4th level description"
            ) {
              let newSelectedData: IselectedLookup[] = [
                {
                  Id: Number(resp.data),
                  Value: value,
                },
              ];

              let newSelectedglobeData = {
                ...selectedLookupGlobe,
                [ searchParamsKeys[ level ] ]: newSelectedData,
              };

              dispatch(setSelectedLookupGlobe(newSelectedglobeData));
            }

            setT_id(resp.data);

            let params = { ...searchParams };
            params[ searchParamsKeys[ level ] ] = String(resp.data);

            dispatch(
              setSearchParamsState({ ...searchParamsState, [ p_key ]: params })
            );

            PostCall(
              `/api/pathway/new/${d_id}/${pathwayRule}/${level + 1}`,
              params
            ).then((res) => {
              let val = res.data;
              let tempData: any[] = [ [ 'Category', 'Reports' ,{ role: 'annotation' }] ]

              if (val.length) {
                val.forEach((element: any) => {
                  if (element[ "GrpName" ]) {
                    tempData.push([ element[ "GrpName" ], element[ "RecordCount" ],element[ "RecordCount" ] ])
                  }
                });
              } else {
                const keys = Object.keys(res.data);
                for (let i = 0; i < 10; i++) {
                  if (val[ keys[ i ] ]) {
                    tempData.push([ keys[ i ], val[ keys[ i ] ], val[ keys[ i ] ] ])
                  }
                }
              }
              if(tempData.length == 1)
                tempData.push(["",0,""])
              setgoogleChartData(tempData);
              dispatch(
                setPathwayData({
                  ...pathwayData,
                  [ p_key ]: tempData,
                })
              );

              dispatch(setLevel(level + 1));
              dispatch(setMaxLevel(level + 1));

              onSearch(d_id, params, resp.data);
              dispatch(
                setSearchParams({
                  ...searchParams,
                  [ searchParamsKeys[ level ] ]: String(resp.data),
                })
              );
              setPathwayPending(true);
            }).catch((error) => {
              console.log("Error", error);
            });
          });
        }
      },
    },
  ];

  const _options = {
    chartArea: { top : 20,width: '50%' },
    tooltip: { isHtml: true },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        auraColor: 'none',
        color: '#555',
      },
    
    },
    hAxis: {
      viewWindow: {
        min: 0,
      },
    },
    vAxis: {
      textStyle: {
        fontSize: 11, // Adjust the font size here
      },
      viewWindow: {
        min: 0,
      },
    },
    colors: ['#00aae7'],
    bar: { groupWidth: '75%' },
    legend: { position: 'none' }
  };

  const [googleChartData, setgoogleChartData] = useState<any[]>([]);
  const onSearch = (
    d_id: number,
    searchParams_: any,
    id?: any,
    lvl?: number
  ) => {
    let lvl_ = level;
    if (lvl) lvl_ = lvl;

    let filterState: IfilterState = {};
    const keys = Object.keys(searchParams_);

    if (keys.length > 0) {
      for (let i = 0; i < lvl_ + 1; i++) {
        if (!filterState[keys[i]]) {
          filterState[keys[i]] = searchParams_[keys[i]];
        } else {
          filterState[keys[i]] =
            filterState[keys[i]] + "," + searchParams_[keys[i]];
        }
      }
    }

    if (id != 0 && id) {
      filterState[searchParamsKeys[level]] = String(id);
    }
    dispatch(setTotalResultCount(0));
    dispatch(setColumnFilters({data: {}, from: "SearchTable"}));
    dispatch(setFilterColumnTypes({}));
    dispatch(setSelectedColumnFiters({}));
    setSelectedCriteria(filterState);
    dispatch(setPathwayFilterState(filterState));
    // dispatch setInitiateSearch and setIsSearchGridLoading
    dispatch(setIsPathwaySearch(true));
    dispatch(setInitiateSearch(true));
    dispatch(setIsSearchGridLoading(true));
    setCurrentPage(1);
    setPageSize(100);
    initiateSearchResult(filterState, d_id);
  };

  useEffect(() => {
    let _visisbleColumns = {...visibleColumns};
    if ((!_visisbleColumns[dataSourceId] || level) && defaultColumns?.length > 0){
      _visisbleColumns[dataSourceId] = defaultColumns;
    }
    dispatch(setvisibleColumns(_visisbleColumns));
  },[defaultColumns])

  const initiateSearchResult = (
    filterState: IfilterState,
    dataSourceId: number
  ) => {
    GetSearchData({
      searchTerm: "",
      datasourceId: dataSourceId,
      filterData: filterState,
      visibleColumns: defaultColumns, //visibleColumns[dataSourceId], commenting this as pathway search is not taking correct default cols
      pathway: true,
    });
  };

  const [getPage] = useGetPageMutation();

  const GetSearchData = async (payload: any) => {
    const searchResult = await getPage(payload);
    setPathwaySearch(true);
    dispatch(saveSearchData(searchResult));
  };

  useEffect(() => {
    setgoogleChartData([['Category', 'Reports', { role: 'annotation' }]]);
    let s_p_keys = searchParamsKeys_p1;
    if (pathwayRule === "pathway-2" && ds_id === 1) {
      s_p_keys = searchParamsKeys_p2;
    }
    setsearchParamsKeys(s_p_keys);

    let d_id = ds_id;
    if (pathwayRule === "pathway-c-1") d_id = 2;
    else if (pathwayRule === "pathway-r-1") d_id = 3;

    if (d_id !== 0 && pathwayRule) {
      const keys = Object.keys(pathwayData);
      const p_key: string = `${d_id}_${level}`;

      if (keys.length > 0 && keys.includes(String(p_key))) {
        setgoogleChartData(pathwayData[p_key]);
        setPathwayPending(true);
        if (level == 0) dispatch(setSearchParams({}));
      } else {
        if (level == 0) {
          dispatch(setPathwayFilter([]));
          dispatch(setSearchParams({}));
          PostCall(`/api/pathway/new/${d_id}/${pathwayRule}/${level}`, {})
            .then((repsonse) => {
              let tempData : any[] = [['Category', 'Reports', { role: 'annotation' }]]
              repsonse?.data.forEach((element: any) => {
                tempData.push([element["GrpName"],element["RecordCount"],element["RecordCount"]])
              });
              if(tempData.length == 1)
                tempData.push(["",0,""])
              setgoogleChartData(tempData);
             
              dispatch(
                setPathwayData({
                  ...pathwayData,
                  [p_key]: tempData,
                })
              );
              setPathwayPending(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        }
      }
    }
  }, [ds_id]);

  useEffect(() => {
    if (pathwayFilterLevel != 7 && pathwayFilterLevel <= maxLevel) {
      const p_key: string = `${ds_id}_${pathwayFilterLevel}`;
      //const n_pf = pathway_filter.filter((el,index) => index < pathwayFilterLevel);
      setgoogleChartData(pathwayData[p_key]);
      dispatch(setLevel(pathwayFilterLevel));
      if (pathwayFilterLevel === 0) {
        dispatch(setInitiateSearch(false));
      }
      if (pathwayFilterLevel !== 0) {
        onSearch(ds_id, searchParamsState[p_key], 0, pathwayFilterLevel);
        dispatch(setSearchParams(searchParamsState[p_key]));
      }
      removeFilters(pathwayFilterLevel);
      dispatch(setSearchParams({}));
    }
  }, [pathwayFilterLevel]);


  const removeFilters = (lvl: number) => {
    let selectedLookupGlobe_ = { ...selectedLookupGlobe };
    const newObj = searchParamsKeys.filter((el, i) => i >= lvl);
    newObj.forEach((el) => delete selectedLookupGlobe_[el]);
    dispatch(setSelectedLookupGlobe(selectedLookupGlobe_));
  };

  const levelChange = (lvl: number) => {
    if (level - 1 == lvl) {
      return;
    }

    if (ds_id === 2) {
      if (lvl === 0) {
        dispatch(setChangeFilter(1));
      }
    }

    let d_id = ds_id;
    if (pathwayRule === "pathway-c-1") d_id = 2;
    else if (pathwayRule === "pathway-r-1") d_id = 3;
    const p_key: string = `${d_id}_${lvl + 1}`;
    setgoogleChartData(pathwayData[p_key]);
    onSearch(d_id, searchParamsState[p_key]);
    dispatch(setLevel(lvl + 1));
    setPathwayFilterLevel(7);

    // let p_filter = [...pathway_filter].slice(0,lvl+1);
    // dispatch(setPathwayFilter(p_filter));
    dispatch(setSearchParams(searchParamsState[p_key]));
    removeFilters(lvl + 1);
  };

  // useEffect(() => {
  //   console.log("defaultColumns",defaultColumns);
  // },[defaultColumns])

  return (
    <>
      {pathwayPending ? (
        <>
          {!!pathway_filter && pathway_filter.length > 0 && level - 1 >= 0 && (
            <>
              <div className="row" style={{ paddingLeft: "15px",margin:"inherit" }}>
                <p style={{display:"contents"}}>pathway : </p>
                <Breadcrumbs style={{display:"contents"}} aria-label="breadcrumb">
                  {pathway_filter
                    .filter((x, i) => i <= level - 1)
                    .map((crumb, idx) => (
                      <Link
                        key={idx}
                        className={`no-underline ${crumb}`}
                        color={
                          level - 1 == idx ? "inherit" : "rgb(0, 170, 231)"
                        }
                        //value = {idx}
                        onClick={(e) => levelChange(idx)}
                      >
                        {crumb}
                      </Link>
                    ))}
                </Breadcrumbs>
              </div>
            </>
          )}

          <div id="chart">
            <Chart
              chartType="BarChart"
              data={googleChartData}
              options={_options}
              width="1090px"
              height="400px"
              legendToggle
              chartEvents= {chartEvents}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingLeft: "480px", minHeight: "300px" }}>
            <Spinner
              style={{ alignContent: "center" }}
              size="sm"
              variant="Info"
            />
            <span style={{ padding: "5px" }}>Loading</span>{" "}
          </div>
        </>
      )}
    </>
  );
}
