// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-modal {
     border-radius: 0px;
  } 

.dialog-modal {
    min-width: 986px;
    height: 900px;
    border-radius: 0px;
}

input[type='checkbox']:disabled+label {
  color: #ccc;
}

.disabled-ms-item {
  color: #55d2ff !important;
  pointer-events: none;
}

.selectallmultiselect:hover{
  color: #393939 !important;
 }
 
 .clearallmultiselect:hover{
   color: #393939 !important;
   text-decoration: none;
 }
 
 .saveselectionsmultiselect:hover{
   color: #393939 !important;
   text-decoration: none;
 }
 
 .closemultiselect:hover{
   color: #393939 !important;
   text-decoration: none;
 }
`, "",{"version":3,"sources":["webpack://./src/components/Popup/MyModal.css"],"names":[],"mappings":"AAAA;KACK,kBAAkB;EACrB;;AAEF;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;CAC1B;;CAEA;GACE,yBAAyB;GACzB,qBAAqB;CACvB;;CAEA;GACE,yBAAyB;GACzB,qBAAqB;CACvB;;CAEA;GACE,yBAAyB;GACzB,qBAAqB;CACvB","sourcesContent":[".my-modal {\n     border-radius: 0px;\n  } \n\n.dialog-modal {\n    min-width: 986px;\n    height: 900px;\n    border-radius: 0px;\n}\n\ninput[type='checkbox']:disabled+label {\n  color: #ccc;\n}\n\n.disabled-ms-item {\n  color: #55d2ff !important;\n  pointer-events: none;\n}\n\n.selectallmultiselect:hover{\n  color: #393939 !important;\n }\n \n .clearallmultiselect:hover{\n   color: #393939 !important;\n   text-decoration: none;\n }\n \n .saveselectionsmultiselect:hover{\n   color: #393939 !important;\n   text-decoration: none;\n }\n \n .closemultiselect:hover{\n   color: #393939 !important;\n   text-decoration: none;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
