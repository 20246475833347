import React, { useState, useEffect, useContext } from "react";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../../../store/hooks";
import { IselectedLookup, IselectedLookupGlobeSpecial } from "../../../interfaces/multiselect";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useAppDispatch } from "../../../../store/hooks";
import {
  setEndpointResultOption,
  showMultiSelectPopup,
  setLookupGlobe,
  setEndpointResultList,
  setSelectedDataState,
  setSelectedLookupGlobe,
  setFieldListAudit,
} from "../../../../store/MultiSelectSlice";
// import { useAppDispatch } from "../../store/hooks";
import { UserContext } from "../../Editor";
import { sortArray } from "./Utilty";
interface ClinicalData_CoaInstrument {
  field: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  validateCriteria: (field: Field) => boolean;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  openModal: (ID: number, Name: string, title: string) => void;
}

interface IEndPoint {
  [key: string]: string;
}

interface IResult {
  [i: string]: IselectedLookup[];
}

const ClinicalData_CoaInstrument = ({
  field,
  values,
  fieldChanged,
  childRows,
  validateCriteria,
  RichtextFieldChanged,
  datasourcename,
  section,
  datasourceId,
  masterFieldTypes,
  openModal,

}: ClinicalData_CoaInstrument) => {


  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );

  const userContext = useContext(UserContext);

  useEffect(() => {
    const ep_keys: string[] = Object.keys(selectedLookupGlobe).filter((x) =>
      x.includes("COA_Endpoints.EndpointType.EndpointID")
    );

    const coa = values[field.name];

    ep_keys.forEach((x, i) => {
      const items = x.split("_");
      const coa_index = Number(items[items.length - 1]);
      const selectedEndPoints = selectedLookupGlobe[x];

      const [allResults,RealtiveDifference] = getRelatedLookups();

      selectedEndPoints.forEach((ep) => {
       
        if (coa[coa_index]["endPoint"]) {
          const ep_names = coa[coa_index]["endPoint"].map(
            (x: any) => x["EndpointName"]?.trim()
          );
          const lastIndex : number = ep_names.lastIndexOf(ep.Value?.trim());
          if(lastIndex > -1){ 
            coa[coa_index]["endPoint"].splice(lastIndex+1,0, {
              EndpointID: ep.Id,
              EndpointName: ep.Value,
              Results: allResults,
              RealtiveDifference: RealtiveDifference,
            });

          }else {
            coa[coa_index]["endPoint"].push({
              EndpointID: ep.Id,
              EndpointName: ep.Value,
              Results: allResults,
              RealtiveDifference: RealtiveDifference,
            });
          }
        } else {
          coa[coa_index]["endPoint"] = [
            {
              EndpointID: ep.Id,
              EndpointName: ep.Value,
              Results: allResults,
              RealtiveDifference: RealtiveDifference,
            },
          ];
        }
      });
    });

    let StateCheck = false;

    ep_keys.forEach((x) => {
      if(selectedLookupGlobe[x].length > 0) StateCheck = true;
    });

    if (coa != undefined && StateCheck) {
      fieldChanged(field.name, "", coa);
      let _selectedLookupGlobe = {...selectedLookupGlobe};
      ep_keys.forEach((x) => { 
        _selectedLookupGlobe[x] = []
      })
      dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));
    }
    
  }, [selectedLookupGlobe,selectedLookupGlobeSpecial]);

  useEffect(() => {
    if(values?.COA_Endpoints?.length > 0 && Object.keys(selectedLookupGlobe).length > 0){
      values.COA_Endpoints.forEach((el:any) =>{
        const [allResults,RealtiveDifference] = getRelatedLookups();
        if (el.endPoint != undefined && el.endPoint.length > 0) {
          el.endPoint.forEach((ep:any) => {
            let _allResults = [...allResults] || [];
            let _RealtiveDifference = [...RealtiveDifference] || [];
            
            let drugsIds = _allResults.map((x:any) => String(x.Id));
            ep.Results = ep.Results.filter((x:any) => drugsIds.includes(String(x.Id)));
            let resultIds :any[] = ep.Results.map((x:any) => String(x.Id));
            _allResults = _allResults.filter((x:any) => !resultIds.includes(String(x.Id)));       
            ep.Results = [...ep.Results,..._allResults];  
  
            if(ep.RealtiveDifference){
              let comparatorIds = _RealtiveDifference.map((x:any) => x.Id);
              ep.RealtiveDifference = ep.RealtiveDifference.filter((x:any) => comparatorIds.includes(x.Id));
              let RealtiveDifferenceIds :any[] = ep.RealtiveDifference.map((x:any) => x.Id);
              _RealtiveDifference =  _RealtiveDifference.filter((x:any) => !RealtiveDifferenceIds.includes(x.Id));
              ep.RealtiveDifference = [...ep.RealtiveDifference,..._RealtiveDifference];
            }else {
              ep.RealtiveDifference = _RealtiveDifference;
            }
          });
        }   
      });
      fieldChanged(field.name, "", values.COA_Endpoints);
    }
  },[selectedLookupGlobe.ClinicalData_Drug,
    selectedLookupGlobe.ClinicalData_ProcedureIntervention,
    selectedLookupGlobe.ClinicalData_Device,
    selectedLookupGlobe.ClinicalData_DrugControl,
    selectedLookupGlobe.ClinicalData_DeviceControl,
    selectedLookupGlobe.ClinicalData_ProcedureInterventionControl,
    // selectedLookupGlobeSpecial.ClinicalData_Drug_Combination ,
    // selectedLookupGlobeSpecial.ClinicalData_DrugControl_Combination  
  ]);

  const getRelatedLookups = () => {
    let ClinicalData_Drug: any =
      !!selectedLookupGlobe["ClinicalData_Drug"] && selectedLookupGlobe["ClinicalData_Drug"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];

    const ClinicalData_ProcedureIntervention: any =
      !!selectedLookupGlobe["ClinicalData_ProcedureIntervention"] && selectedLookupGlobe["ClinicalData_ProcedureIntervention"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];

    const ClinicalData_Device: any =
      !!selectedLookupGlobe["ClinicalData_Device"] && selectedLookupGlobe["ClinicalData_Device"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];

    // ClinicalData_Drug = [...ClinicalData_Drug,
    // ...(selectedLookupGlobeSpecial["ClinicalData_Drug_Combination"] || []),
    // ];

    if (ClinicalData_Drug.length == 0)
      ClinicalData_Drug =
        !!selectedLookupGlobe["ClinicalData_Drug"] && selectedLookupGlobe["ClinicalData_Drug"].map((x) => ({
          Id: String(x.Id),
          Value: x.Value,
        })) || [];

    let ClinicalData_DrugControl: any =
      !!selectedLookupGlobe["ClinicalData_DrugControl"] && selectedLookupGlobe["ClinicalData_DrugControl"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];

    // ClinicalData_DrugControl = [...ClinicalData_DrugControl,
    // ...(selectedLookupGlobeSpecial["ClinicalData_DrugControl_Combination"] || []),
    // ];

    if (ClinicalData_DrugControl.length == 0)
      ClinicalData_DrugControl =
        !!selectedLookupGlobe["ClinicalData_DrugControl"] && selectedLookupGlobe["ClinicalData_DrugControl"].map((x) => ({
          Id: String(x.Id),
          Value: x.Value,
        })) || [];

    const ClinicalData_DeviceControl: any =
      !!selectedLookupGlobe["ClinicalData_DeviceControl"] && selectedLookupGlobe["ClinicalData_DeviceControl"].map((x) => ({
        Id: String(x.Id),
        Value: x.Value,
      })) || [];

    const ClinicalData_ProcedureInterventionControl: any =
      !!selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"] && selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"].map(
        (x) => ({
          Id: String(x.Id),
          Value: x.Value,
        })
      ) || [];

    let intervention = [...ClinicalData_ProcedureIntervention,
    ...ClinicalData_Drug,
    ...ClinicalData_Device];

    intervention = intervention.sort(sortArray);

    let comparators = [...ClinicalData_DrugControl,
    ...ClinicalData_DeviceControl,
    ...ClinicalData_ProcedureInterventionControl];

    comparators = comparators.filter((x:any) => {
      return intervention.every((item: any) => String(item.Id) !== String(x.Id) && item.Value?.trim() !== x.Value?.trim())
    })

    comparators = comparators.sort(sortArray);

    let allResults : {Id : string, Value : string}[] = [...intervention, ...comparators]

      let RealtiveDifference: any = [];
      intervention.forEach((x: any) => {
        comparators.forEach((el: any) => {
          RealtiveDifference.push({
            Id: String(x.Id) + "+" + String(el.Id),
            Value: x.Value + " vs " + el.Value,
          });
        });
      });
    return [allResults,RealtiveDifference]
  }

  const resultChange = (
    id: string,
    i: string,
    currentItem: number,
    coa_index: number,
   
  ) => {
    //
    //const ResultInfo : any[] = [...values[field.name][i]]
    let coa = values[field.name];
    if (!String(id).includes("_")) {
      let results: any[] = [...coa[coa_index]["endPoint"][i]["Results"]];

      for (let ri = 0; ri < results.length; ri++) {
        if (results[ri].Id == id && !results[ri]["ResultName"]) {
          results[ri] = {
            ...results[ri],
            ResultName: " ",
          };
          break;
        }
      }
      coa[coa_index]["endPoint"][i]["Results"] = [...results];
    } else {
      const n_id = id.replace("_","");
      let RealtiveDifference: any[] = [
        ...coa[coa_index]["endPoint"][i]["RealtiveDifference"],
      ];

      for (let ri = 0; ri < RealtiveDifference.length; ri++) {
        if (
          RealtiveDifference[ri].Id == n_id &&
          !RealtiveDifference[ri]["ResultName"]
        ) {
          RealtiveDifference[ri] = {
            ...RealtiveDifference[ri],
            ResultName: " ",
          };
          break;
        }
      }

      coa[coa_index]["endPoint"][i]["RealtiveDifference"] = [
        ...RealtiveDifference,
      ];
    }

    fieldChanged(field.name, "", coa);
  };

  //Adding all result from dropdown to endpoints
  const addAllResults = (i:number,coa_index:number) => {
      let coa = values[field.name];
      let results: any[] = [...coa[coa_index]["endPoint"][i]["Results"]];
      for (let ri = 0; ri < results.length; ri++) {
        if (!results[ri]["ResultName"]) {
          results[ri] = {
            ...results[ri],
            ResultName: " ",
          };        
        }
      }
      coa[coa_index]["endPoint"][i]["Results"] = [...results];
      
      let RealtiveDifference: any[] = [
        ...coa[coa_index]["endPoint"][i]["RealtiveDifference"] || []
      ] ;
  
      for (let ri = 0; ri < RealtiveDifference.length; ri++) {
        if (
          !RealtiveDifference[ri]["ResultName"]
        ) {
          RealtiveDifference[ri] = {
            ...RealtiveDifference[ri],
            ResultName: " ",
          };
        }
      }
  
      coa[coa_index]["endPoint"][i]["RealtiveDifference"] = [
        ...RealtiveDifference,
      ];
      
      fieldChanged(field.name, "", coa);
    }

  const onResultNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
    console.log("resultChange");
   
    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["Results"][result_index].ResultName;
    }else {
      coa[coa_index]["endPoint"][ep_index]["Results"][result_index] = {
        ...coa[coa_index]["endPoint"][ep_index]["Results"][result_index],
        ResultName: name,
      };
    }

    fieldChanged(field.name,"",coa);
  };

  const onRelativeDifferenceNameChange = (
    coa_index: number,
    ep_index: number,
    result_index: number,
    name?: string
  ) => {
    const coa = values[field.name];
    console.log("resultChange");
   
    if (name === undefined) {
      delete coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index].ResultName;
    }else {
      coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index] = {
        ...coa[coa_index]["endPoint"][ep_index]["RealtiveDifference"][result_index],
        ResultName: name,
      };
    }

    fieldChanged(field.name,"",coa);
  };

  const updateCOA = (obj: any, index: number) => {
    const coaObj = [...values[field.name]];
    coaObj[index] = obj;
    fieldChanged(field.name, "", coaObj);
  };

  const addEndpointTypes = (coa_index: number) => {
    const id: number = childRows
      .filter((f) => f.name == "COA_Endpoints.EndpointType.EndpointID")
      .map((f) => f.lookupId)[0];

    openModal(
      id,
      "COA_Endpoints.EndpointType.EndpointID_" + String(coa_index),
      "Endpoint Type"
    );
  };

  const deleteInstrument = (coa_index: number) => {
    const msg = "Are you sure you want to delete this COA instrument?";
    const check = window.confirm(msg);
    if (check) {
      if (values[field.name].length == 1) fieldChanged(field.name, "", []);
      else {
        const coa_instruments: any[] = [...values[field.name]];
        fieldChanged(field.name, "", [
          ...coa_instruments.filter((item: any, xi: number) => xi != coa_index),
        ]);
      }
    }
  };

  const deleteEndPoint = (coa_index: number, ep_index: number, endPointId: number) => {
    const coa = values[field.name];

    const endPointObj = coa[coa_index]["endPoint"].filter(
      (x: any, i: number) => i != ep_index
    );
    coa[coa_index]["endPoint"] = endPointObj;
    console.log(coa);
    fieldChanged(field.name, "", coa);
    let newSelectedlookupGlobe = { ...selectedLookupGlobe };
    let newSelectedlookupGlobe1 = newSelectedlookupGlobe["COA_Endpoints.EndpointType.EndpointID_"+coa_index].filter(function( obj ) {
      return obj.Id !== endPointId;
    });
    newSelectedlookupGlobe = {...newSelectedlookupGlobe, ["COA_Endpoints.EndpointType.EndpointID_"+coa_index]: newSelectedlookupGlobe1};
    dispatch(setSelectedLookupGlobe(newSelectedlookupGlobe));
  };

  return (
    <>
      {values[field.name] &&
        values[field.name].map((parent: any, index: number) => (
          <>
            <div className="float-left">
              <b> {"COA instrument  " + String(index + 1)}</b>
            </div>
            <div className="float-right">
              <div className="row">
                {childRows
                  .filter(
                    (x) =>
                      masterFieldTypes.includes(x.fieldTypeId) &&
                      x.masterFieldId == field.id
                  )
                  .map((el) => (
                    <>
                      <label className="iqvia-color cursor-pointer margin-right-20"
                        onClick={() => {
                          addEndpointTypes(index);
                        }}
                      >
                        <AddIcon className="instrument-endpoint-type-add"/>&nbsp;
                        {el.label}
                      </label>
                    </>
                  ))}

                <label className="iqvia-color cursor-pointer margin-right-30" onClick={() => deleteInstrument(index)}>
                  <RiDeleteBinLine className="margin-bottom-3" /> Instrument

                </label>
              </div>
            </div>
            <div
            style={{
              width:"100%",
              height:"0.5px",
              marginTop: "41px",
              marginBottom: "1rem",
              border: "0",
              borderTop: "1px solid rgba(0,0,0,.1)"
            }}
          > </div> 
            {childRows
              .filter(
                (x) =>
                  !masterFieldTypes.includes(x.fieldTypeId) &&
                  x.masterFieldId == field.id &&
                  x.name !== "COA_Endpoints.Result"
              )
              .map((row) => (
                <>
                  {/* {validateCriteria(row) && ( */}
                  <FieldView
                    field={row}
                    values={values}
                    fieldChanged={fieldChanged}
                    RichtextFieldChanged={RichtextFieldChanged}
                    datasourcename={datasourcename}
                    section={section}
                    datasourceId={datasourceId}
                    parentObject={values[field.name][index]}
                    setObj={updateCOA}
                    index={index}
                  />
                  {/* )} */}
                </>
              ))}

            {!!values[field.name][index].endPoint &&
              values[field.name][index].endPoint.map((el: any, i: number) => (
                <>
                  <br />
                  <div style={{ display: "flow-root" }}>
                    <div className="float-left">
                      <b>Endpoint type - {el.EndpointName}</b>
                    </div>
                    <div className="float-right">
                      <select
                        // style={{color:"#337ab7",margin:"13px",border:"1px solid black"}}
                        className="clinical-instrument-add-all-result-option"
                        value={""}
                        disabled={
                          !!el["Results"] &&
                          [
                            ...(el["Results"] || []),
                            ...(el["RealtiveDifference"] || []),
                          ].some((x: any) => !x["ResultName"])
                            ? false
                            : true
                        }
                        onChange={(e) => {
                          resultChange(
                            e.target.value,
                            String(i),
                            e.target.selectedIndex,
                            index
                          );
                        }}
                      >
                        <option className="instrument-endpoint-type-option-color" value={""} disabled selected>
                          + Results
                        </option>
                        <optgroup label="Individual result">
                          {!!el["Results"] &&
                            el["Results"].map((item: any, ri: number) => (
                              <>
                                {!item["ResultName"] && (
                                  <option key={ri} value={item.Id}>
                                    Result - {item.Value}
                                  </option>
                                )}
                              </>
                            ))}
                        </optgroup>
                        {el["RealtiveDifference"] && el["RealtiveDifference"].length > 0 &&
                          <optgroup label="Relative difference(s)">
                            {!!el["RealtiveDifference"] &&
                              el["RealtiveDifference"].map(
                                (item: any, ri: number) => (
                                  <>
                                    {!item["ResultName"] && (
                                      <option
                                        key={ri}
                                        value={String(item.Id) + "_"}
                                      >
                                        Result - {item.Value}
                                      </option>
                                    )}
                                  </>
                                )
                              )}
                          </optgroup>
                        }
                      </select>
                      <div className = "row">
                      <label className="iqvia-color cursor-pointer clinical-margin-top-22" onClick={() => addAllResults(i,index)}>
                        <AddIcon className="iqvia-color instrument-add-all-result-icon" /> Add all results
                      </label>
                      <label className="iqvia-color instrument-endpoint-type-delete cursor-pointer clinical-margin-top-22" onClick={() => deleteEndPoint(index, i, el.EndpointID)}>
                        <RiDeleteBinLine className="clinical-endpoint-margin-top-3" /> Endpoint type
                      </label>
                      </div>
                      
                    </div>
                    <div>
                      {childRows
                        .filter(
                          (x) =>
                            x.name ==
                            "COA_Endpoints.EndpointType.COAEndpointName"
                        )
                        .map((item) => (
                          <>
                            <br />
                            <div
                              className="float-right col-sm-12 row form-group margin-bottom-0 margin-top-12"
                            >
                              <label
                                className="col-sm-4 text-right control-label"
                                style={{ paddingRight: "4px" }}
                              >
                                {item.label}
                                {
                                  <FaInfoCircle
                                    onClick={() => {
                                      userContext.showAlert(
                                        <>
                                          <span
                                            style={{
                                              fontSize: "70%",
                                              paddingLeft: "2px",
                                            }}
                                          >
                                            {datasourcename}
                                            <DoubleArrowIcon
                                              style={{
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {section}
                                            <DoubleArrowIcon
                                              style={{
                                                strokeWidth: "5",
                                                fontSize: "18px",
                                                paddingBottom: "2px",
                                              }}
                                            />
                                            {field.label}
                                          </span>
                                        </>,
                                        "info",
                                        field.description,
                                        "https://google.com",
                                        true
                                      );
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "3px",
                                      cursor: "pointer",
                                    }}
                                  />
                                }
                              </label>
                              <input
                                className="col-sm-7 form-control"
                                style={{ marginLeft: "15px" }}
                                value={
                                  values[field.name][index]["endPoint"][i][
                                    item.name
                                  ] || ""
                                }
                                placeholder={item.label}
                                onChange={(e) => {
                                  const obj = values[field.name];
                                  obj[index]["endPoint"][i][item.name] =
                                    e.target.value;
                                  fieldChanged(field.name, "", obj);
                                }}
                              />
                            </div>
                          </>
                        ))}
                      {!!values[field.name][index]["endPoint"][i]["Results"] &&
                        values[field.name][index]["endPoint"][i]["Results"].map(
                          (res: any, resi: number) => (
                            <>
                              {res["ResultName"] && (
                                <div
                                  className="float-right col-sm-12 row form-group margin-bottom-0"
                                >
                                  <label
                                    className="col-sm-4 text-right control-label"
                                    style={{
                                      paddingRight: "4px",
                                      paddingTop: 10,
                                    }}
                                  >
                                    Result - {res.Value && res.Value}

                                    {
                                      <FaInfoCircle
                                        onClick={() => {
                                          userContext.showAlert(
                                            <>
                                              <span
                                                style={{
                                                  fontSize: "70%",
                                                  paddingLeft: "2px",
                                                }}
                                              >
                                                {datasourcename}
                                                <DoubleArrowIcon
                                                  style={{
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {section}
                                                <DoubleArrowIcon
                                                  style={{
                                                    strokeWidth: "5",
                                                    fontSize: "18px",
                                                    paddingBottom: "2px",
                                                  }}
                                                />
                                                {field.label}
                                              </span>
                                            </>,
                                            "info",
                                            field.description,
                                            "https://google.com",
                                            true
                                          );
                                        }}
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "3px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  </label>
                                  <input
                                    className="col-sm-7 form-control margin-t-l-15"
                                    value={res["ResultName"]}
                                    type="text"
                                    onChange={(e) =>
                                      onResultNameChange(
                                        index,
                                        i,
                                        resi,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <div className="iqvia-color cursor-pointer instrument-endpoint-result-delete"
                                    onClick={() => {
                                      onResultNameChange(index, i, resi);
                                    }}
                                  >
                                    <RiDeleteBinLine
                                      style={{
                                        marginTop: "22px",
                                        marginLeft: "5px",
                                        fontSize:"medium"
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        )}
                      <p>Relative difference(s) / Effect estimate(s) :</p>

                      {!!values[field.name][index]["endPoint"][i][
                        "RealtiveDifference"
                      ] &&
                        values[field.name][index]["endPoint"][i][
                          "RealtiveDifference"
                        ].map((res: any, resi: number) => (
                          <>
                            {res["ResultName"] && (
                              <div
                                className="float-right col-sm-12 row form-group margin-bottom-0"
                              >
                                <label
                                  className="col-sm-4 text-right control-label"
                                  style={{
                                    paddingRight: "4px",
                                    paddingTop: 10,
                                  }}
                                >
                                  Result - {res.Value && res.Value}

                                  {
                                    <FaInfoCircle
                                      onClick={() => {
                                        userContext.showAlert(
                                          <>
                                            <span
                                              style={{
                                                fontSize: "70%",
                                                paddingLeft: "2px",
                                              }}
                                            >
                                              {datasourcename}
                                              <DoubleArrowIcon
                                                style={{
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {section}
                                              <DoubleArrowIcon
                                                style={{
                                                  strokeWidth: "5",
                                                  fontSize: "18px",
                                                  paddingBottom: "2px",
                                                }}
                                              />
                                              {field.label}
                                            </span>
                                          </>,
                                          "info",
                                          field.description,
                                          "https://google.com",
                                          true
                                        );
                                      }}
                                      style={{
                                        fontSize: "12px",
                                        marginLeft: "3px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                </label>
                                <input
                                  className="col-sm-7 form-control margin-t-l-15"
                                  value={res["ResultName"]}
                                  type="text"
                                  onChange={(e) =>
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi,
                                      e.target.value
                                    )
                                  }
                                />
                                <div className="iqvia-color cursor-pointer instrument-endpoint-result-delete"
                                  onClick={() => {
                                    onRelativeDifferenceNameChange(
                                      index,
                                      i,
                                      resi
                                    );
                                  }}
                                >
                                  <RiDeleteBinLine
                                    style={{
                                      marginTop: "22px",
                                      marginLeft: "5px",
                                      fontSize:"medium"
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </>
              ))}

            {values[field.name][index].endPoint == undefined && (
              <>
                <br />
                <div
                  className="col-sm-12 text-center bg-white-smoke"
                >
                  No endpoints have been specified
                </div>
                <br />
              </>
            )}
          </>
        ))}
         {(!values[field.name] || values[field.name]?.length == 0) && (
              <>
                <br />
                <div
                  className="col-sm-12 text-center bg-white-smoke"
                >
                  No further COA information was provided
                </div>
                <br />
              </>
            )}
    </>
  );
};

export default ClinicalData_CoaInstrument;
