import React, { useEffect, useState } from "react";
import PPCards from "./PPCards";
import { useAppSelector } from "../../../store/hooks";
import TableGrid from "../../Table/TableGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { CardObj, defineCardData, addDate } from "../../../utils/index";
import { AgGridReact } from "ag-grid-react";

const HTASummary = () => {
  const state = useAppSelector((state: any) => state);
  const [rowData] = useState<any[]>(state.productProfile.htaSummaryData);
  const [cardData, setCardData] = useState<CardObj>({
    positiveRecommendations: 0,
    positiveWithRestriction: 0,
    negativeRecommendations: 0,
    multipleRecommendations: 0,
    noRecommendations: 0,
    ongoingPlanned: 0,
    suspendedCanceled: 0,
  });

  const calculateRecommendation = () => {
    let res = defineCardData(rowData);

    setCardData({
      positiveRecommendations: res.positiveRecommendations,
      positiveWithRestriction: res.positiveWithRestriction,
      negativeRecommendations: res.negativeRecommendations,
      multipleRecommendations: res.multipleRecommendations,
      noRecommendations: res.noRecommendations,
      ongoingPlanned: res.ongoingPlanned,
      suspendedCanceled: res.suspendedCanceled,
    });
  };

  useEffect(() => {
    calculateRecommendation();
  }, [rowData]);

  const columnDefs = [
    {
      headerName: "Decision date / expected publication date",
      valueGetter: function (params: any) {
        return params.data.Decision
          ? params.data.Decision
          : params.data.ExpectedPublicationDate;
      },
      cellRenderer: (params: any) => {
        const dateArray =
          params.value !== null && params.value !== undefined
            ? addDate(params.value)
            : "-";
        return (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
          >
            {" "}
            {dateArray}
          </span>
        );
      },
      wrapText: true,
      autoHeight: true,
      minWidth: 300,
    },
    {
      headerName: "Country",
      field: "CountryName",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Agency",
      valueGetter: function (params: any) {
        return params.data.AgencyAbbreviation + " - " + params.data.AgencyName;
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Disease",
      field: "PrimaryDiseaseNames",
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "HTA status",
      field: "HTAStatusName",
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "HTA recommendation (prelim)",
      valueGetter: function (params: any) {
        return params.data.PreliminaryRecommendationName
          ? params.data.PreliminaryRecommendationName
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 250,
    },
    {
      headerName: "HTA recommendation (final)",
      valueGetter: function (params: any) {
        return params.data.FinalRecommendationName
          ? params.data.FinalRecommendationName
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 250,
    },
    {
      headerName: "Benefit rating",
      valueGetter: function (params: any) {
        return params.data.BestBenefitRatting
          ? params.data.BestBenefitRatting
          : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 150,
    },
    {
      headerName: "ASMR rating",
      valueGetter: function (params: any) {
        return params.data.BestASMR ? params.data.BestASMR : "-";
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 150,
    },
    {
      headerName: "",
      field: "HTARecord_ID",
      cellRendererFramework: (params: any) => (
        <a
          href={`/view/1/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      ),
      maxWidth: 50,
      width: 50,
      minWidth: 50,
    },
  ];

  return (
    <div className="htaSummaryContainer">
      <div style={{ width: "90%", margin: "10px auto" }}>
        <PPCards cardData={cardData} />
      </div>
      <div
        style={{
          margin: "20px 0 10px",
          border: "1px solid #e9e9e9",
          width: "96%",
        }}
      ></div>
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <TableGrid
            tableName={"ProductProfile"}
            columnDefs={columnDefs}
            rowData={rowData}
            isLoading={false}
            pageSize={10}
          />
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "200px", width: "90%", marginTop: 10 }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={"No data available in table"}
          />
        </div>
      )}
    </div>
  );
};

export default HTASummary;
