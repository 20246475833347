import React, { useEffect, useContext, useState, useCallback } from "react";
import { Field, Section } from "../../store/DataSourceMetaData";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import TextField from "@mui/material/TextField";
// import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import { useFetchLookupItemsQuery } from "../../store/LookupApiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "./Editor";
import { ILookupData, ILookupDataSpecial } from "../Popup/NewModal";
import {
  IselectedLookupGlobe,
  IselectedLookup,
  ILookupGlobe,
} from "../interfaces/multiselect";
import { IForm } from "./Editor";
import RteForForm from "../RichTextEditor/RteForForm";
import moment from "moment";
import { EditorState } from "draft-js";
import TMEditor from "../RichTextEditor/TinyMCEEditor";
import {
  setModalShow,
  setLookupGlobe,
  showMultiSelectPopup,
  setSelectedDataState,
  setSelectedLookupGlobe,
  setLookupData,
  setEndpointResultOption,
  setPricingTileSource,
  setLoeBrandName,
  setLookupGlobeSpecial,
  setNoRecordFound,
  setSelectedLookupGlobeSpecial,
} from "../../store/MultiSelectSlice";
import fetchRecord from "../service/fetchRecord";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { AssignMe, sizeColor } from "./SectionView";
import { col_items } from "../service/colItems";
import apiCall from "../service/apiCall";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import DatePicker from "antd";
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import ComplexLookupSplit, { CoaInstrumentSelectedItemView, diseaseKeys, subscriptionDateEndName, subscriptionDateKeys } from "./Utility";
import AutocompleteWithAdd from "./AutocompleteWithAdd";
//import RefreshIcon from "@mui/icons-material/Refresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, fas } from "@fortawesome/free-solid-svg-icons";

import { approvedIndicationsFields, columnTypeFieldBrandLicence, columnTypeFieldLove } from "../../utils";
import {
  DataSourceListTypes,
  FormFieldType,
  Status,
  UserRoles,
  LookupIds,
} from "../Shared/Utility";
import { warningOptions, infoOptions } from "./Editor";
import CloseIcon from "@mui/icons-material/Close";
import { RiDeleteBinLine } from "react-icons/ri";
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";
import { ReimbursementStatus_ProductStrengthFields, ReimbursementStatus_ProductStrengthDateFields } from "./SubPages/Forms/ReimbursementProductStrengthForm";
import fetchComplexLookup from "../service/fetchComplexLookup";
import DraggablePopover from "../Popups/Popup_MaterialUI";
import PostCall from "../service/postCall";
import Modal from "react-bootstrap/Modal";
import { GetDependentFieldName } from "../Shared/Utility";
import Spinner from "react-bootstrap/Spinner";
import DOMPurify from 'dompurify';
import './FieldView.css';

interface FieldViewProp {
  field: Field;
  datasourcename: string;
  section: string;
  vi?: number;
  values: IForm;
  parentObject?: any;
  fieldChanged: (name?: string, value?: any, obj?: any, last_index_check?: boolean) => void;
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourceId: number;
  setLookupLabelList?: (val: any) => void;
  setObj?: (val: any, index: number) => void;
  index?: number;
  allFields?: Field[];
  UpdateLookupField?: (val1: string, val2: string) => void;
  lookupDisplay?: any;
  resetValue?: boolean;
  hideTitleLabel?: boolean;
  UpdateNonVersionCol1?: (val1: string) => void;
  nonVersionCol1?: any;
  UpdateNonVersionCol2?: (val1: string) => void;
  nonVersionCol2?: any;
  datasourceDbName?: string;
  formMode?: string
  // showAlert: (title:any,type:any, message:any,modalValue:any) => void;
}

export interface lookupItemT {
  id: string;
  label: string;
  // value?: string;
  parentId?: string;
}

export const datasourceMap: any = {
  Agency_ID: 6,
  Country_ID: 7,
  Drug_ID: 20,
  HTARecord_Drug: 20,
  ClinicalData_Drug: 20,
  RegulatoryWatch_Drug: 20,
  HTARecord_ClinicalData: 2,
  HTARecord_ApprovedRegulatoryInfo: 3,
  ReimbursementStatus_RegulatoryWatch: 3,
  HTARecord_ReportsFromSameAgency: 1,
  ReimbursementStatus_HTARecord: 1,
  Related_Reimbursementrecords: 25,
  Agreement_HTARecord: 1,
  Agreement_ReimbursementStatus: 25,
  Agreement_RelatedAgreements: 23,
  ClinicalData_ParentStudy: 2,
  ClinicalData_ChildStudy: 2,
  ClinicalData_TwinStudy: 2,
  ClinicalData_PreviousPhase: 2,
  ClinicalData_LaterPhase: 2,
  RegulatoryWatch_ClinicalTrials: 2,
  RegulatoryWatch_HTARecords: 1,
  Country_HTABody: 6,
  Policy_ReportsFromSameCountry: 5
};

export const LinkStyle = {
  fontSize: "12px",
  color: "#00aae7",
  textDecoration: "underline",
  textDecorationColor: "#00aae7",
  marginRight: 6,
};

export const hyperlinkList = [
  "Agency_ID",
  "Country_ID",
  "Drug_ID",
  "HTARecord_Drug",
  "ClinicalData_Drug",
  "RegulatoryWatch_Drug",
  "HTARecord_ClinicalData",
  "HTARecord_ApprovedRegulatoryInfo",
  "HTARecord_ReportsFromSameAgency",
  "ReimbursementStatus_HTARecord",
  "ReimbursementStatus_RegulatoryWatch",
  "Related_Reimbursementrecords",
  "Agreement_HTARecord",
  "Agreement_ReimbursementStatus",
  "Agreement_RelatedAgreements",
  "ClinicalData_ParentStudy",
  "ClinicalData_ChildStudy",
  "ClinicalData_TwinStudy",
  "ClinicalData_PreviousPhase",
  "ClinicalData_LaterPhase",
  "RegulatoryWatch_ClinicalTrials",
  "RegulatoryWatch_HTARecords",
  "Country_HTABody",
  "Policy_ReportsFromSameCountry"
];

export const exceptionFields = [
  "RegulatoryWatch_ClinicalTrials",
  "ReimbursementStatus_Region"
];

export const ComplexDataForLookups = [
  LookupIds.DS_AgreementHTARecord,
  LookupIds.DS_AgreementReimbursementStatus,
  LookupIds.DS_AgreementRelatedAgreements,
  LookupIds.DS_Country_HTABody,
];

export const DrugDropDownName = ["GlobalBrand_ID_Text", "GlobalCorporation_ID_Text"];
// const lookupIdWithParentValue = [9, 10];
export const lookupIdWithParentValue = [
  LookupIds.SourceOfComparator,
  LookupIds.HtaRecordCOA_Instrument,
  LookupIds.ClinicalPositives,
  LookupIds.ClinicalNegatives];

const  FieldView = (props: FieldViewProp) =>  {
  const userContext = useContext(UserContext);
  const {
    field,
    datasourcename,
    section,
    vi,
    values,
    parentObject,
    fieldChanged,
    RichtextFieldChanged,
    datasourceId,
    setLookupLabelList,
    setObj,
    index,
    allFields,
    UpdateLookupField,
    lookupDisplay,
    resetValue,
    hideTitleLabel,
    UpdateNonVersionCol1,
    nonVersionCol1,
    UpdateNonVersionCol2,
    nonVersionCol2,
    datasourceDbName,
    formMode
  } = props;
  const [open, setOpen] = React.useState(false);
  const [items, setItems] = React.useState<lookupItemT[]>([]);
  const [originalItems, setOriginalItems] = React.useState<lookupItemT[]>([]);
  const [rawData, setRawData] = useState<any[]>([]);
  let lookupLoading = open && !!items && items.length === 0;
  const [language, setLanguage] = useState([]);
  const [relatedItems, setRelatedItems] = React.useState<lookupItemT[]>([]);
  const dispatch = useAppDispatch();
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const lookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.lookupGlobeSpecial
  );
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );
  const drugCombination = useAppSelector(
    (state) => state.multiselect.drugCombination
  );
  const subscribedDs = useAppSelector(
    (state) => state.userProfile.subscribedDs
  );

  const [drugNameList, setDrugNameList] = useState([]);
  const [procedureInterventionList, setProcedureInterventionList] = useState(
    []
  );
  // const [mandatoryFields, setMandatoryFields] = useState<string[]>([]);
  // useEffect(() => {
  //   dispatch(setLookupGlobe({ ...lookupGlobe, ["RegulatoryWatch_Drug"]: [] }));
  // }, [selectedLookupGlobe.RegulatoryWatch_Drug]);

  const mandatoryFields : string[] = userContext.mandatoryFields;
  const conditionalFormFields: any = userContext.conditionalFormFields;
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [hoveredIndexSpecial, setHoveredIndexSpecial] = useState<string>("");
  const [showDeviceBrandNameModal, setShowDeviceBrandNameModal] =
    React.useState<HTMLButtonElement | null>(null);
  const [showRelatedHTAsModal, setShowRelatedHTAsModal] =
    React.useState<HTMLButtonElement | null>(null);
  const [relatedLink, setRelatedLink] = React.useState(false);
  const pricingTitleSource = useAppSelector(
    (state) => state.multiselect.pricingTitleSource
  );
  const urlparams: any = useParams();
  const fieldCriteriaDatasourceID = Number(urlparams.fieldCriteriaDatasourceID);
  const subpageData : any = useAppSelector((state) => state.multiselect.subpageData);
  // const [pricingTitleSource, setPricingTileSource] = useState({});


  const onAbbrevationChange = async (name: string, value: any) => {
    const label = value["label"];
    value = value["value"];
    let countryNames: any[] = userContext.countryNames;
    if (countryNames.every((x) => x["ID"] != value)) {
      const country_list: any[] = userContext.countryList;
      const countryData: any[] = country_list.filter(
        (x: any) => x["ID"] === Number(value)
      );
      let new_name = name + ",Name";
      let new_value = value + "," + label;
      if (countryData.length > 0) {
        const Abbreviation = countryData[0]["Abbreviation"];
        const Currency_ID = countryData[0]["Currency_ID"];
        const Region_ID = countryData[0]["Region_ID"];
        new_name += `,Abbreviation,Currency_ID,Region_ID`;
        new_value += `,${Abbreviation},${Currency_ID},${Region_ID}`;
      }
      // else {
      //   new_name += `,Abbreviation,Currency_ID,Region_ID`;
      //   new_value = `,${""},${""},${""}`;
      // }
      fieldChanged(new_name, new_value);
      return true;
    } else {
      // toastr.warning("Country alread exists", "title", warningOptions);
      const msg = `Country alread exists`;
      ToastrAlert(msg, "warning");
      fieldChanged(name + `,Abbreviation,Currency_ID,Region_ID`, ",,,");
    }
  };

  const onDrugChange = async (value: any) => {
    // value = value["value"];
    let drugListInfo: any[] = userContext.drugSpecificDataList;
    let filteredDrug = drugListInfo.filter((x: any) => x["DrugData_ID"] === Number(value));
    if (filteredDrug.length > 0) {
      if (UpdateNonVersionCol1)
        UpdateNonVersionCol1(filteredDrug[0]["ActiveSubstanceNames"]);
      if (UpdateNonVersionCol2)
      UpdateNonVersionCol2(filteredDrug[0]["GlobalCorporationName"]);
    }
  }
  
  const renderOptions = (props: React.HTMLAttributes<HTMLElement>, option: Partial<any>) => {
    return <li {...props} key={option.id}>{option.label}</li>
  }

  const validateNumber = (elem: any, form?: string) => {
    if (elem != null) {
      if (form == 'read') {
        while (/(\d+)(\d{3})/.test(elem.toString())) {
          elem = elem.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }
      } else {
        if (elem.indexOf('.') >= 0) {        
        }
        else {
          if (elem.indexOf(',') !== -1)
            elem = elem.replaceAll(',', '');
          // elem = parseFloat(elem);
          while (/(-?\d+)(\d{3})/.test(elem.toString())) { //-? is added to consider -ve values in the textbox
            elem = elem.toString().replace(/(-?\d+)(\d{3})/, '$1' + ',' + '$2');
          }
        }
      }
    }
    return elem;
  }
  
  const onFieldChange = (name: string, value: any, fId?: number) => {
    // resetDiseaseForAdmin(childName);
    if(name != "DisplayName_ID" && diseaseKeys.includes(name) && [256,257,258,259].includes(datasourceId)){
      const diseaseIndex = diseaseKeys.indexOf(name); 
      resetDiseaseForAdmin(diseaseKeys[diseaseIndex],value);
       return 
    }
    if(typeof fId !== "undefined") {
      let childField = !!allFields && allFields.find((x) => x.parentFieldId == fId);
      if (childField && values[childField.name] != undefined) {
        fieldChanged(childField.name, null);
      }
    }
    if (
      name == "CountryList_ID_Text" &&
      datasourceId == DataSourceListTypes.Country
    ) {
      return onAbbrevationChange(name, value);
    }
    else if (name == "IsSubgroupEnabled") {
      if (!values.Agency_ID) {
        ToastrAlert("Select agency", "error");
        return
      }
    }
    else if (name == "Drug_ID" && datasourceId == DataSourceListTypes.ReimbursementStatus) {
      onDrugChange(value);
    }
    if (parentObject !== undefined) {
      let child_obj = { ...parentObject };
      if (
        name == "RegulatoryWatch_ApprovedIndications.ApprovalDate" &&
        index == values?.RegulatoryWatch_ApprovedIndication.length - 1 &&
        values.PubDate
      ) {
        const msg = `You are about the change the latest approval date "${values.PubDate}" with "${value}". Do you want to proceed?`;
        window.confirm(msg);
      }

      child_obj[name] = value;
      if((name == "RegulatoryWatch_DosageAndMode.CycleNumber" || name == "RegulatoryWatch_DosageAndMode.CycleNumber_Max") && setObj)
      {
        child_obj["Cycles"][vi][name] = value
        setObj(child_obj, index || 0);
      }else if (setObj)
        setObj(child_obj, index || 0);
    } else if (field.name.indexOf(".") > 0) {
      let id = field.name;
      let lastPeriodIndex = id.lastIndexOf(".");
      let masterName = id.substring(0, lastPeriodIndex);
      let detailName = id.substring(lastPeriodIndex + 1);
      let split = id.split(".");
      if (false) { //(split[0] === "DrugData_BrandLicense" || split[0] === "DrugData_Lov") {
        let tmp = { ...selectedLookupGlobe };
        tmp[id + "_" + index] = [];
        tmp[id + "_" + index].push(value);
        // console.log(tmp, "tmp", index, "index");
        dispatch(setSelectedLookupGlobe(tmp));
      } else {
        parentObject[detailName] = value;
      }

      fieldChanged();
    } else {
      let dependentChild : any = allFields?.filter((f) => f.parentFieldId == field.id);
      let id1: string = "";
      if (dependentChild && dependentChild.length > 0) {
        dependentChild = dependentChild.filter((d:any) => d.displaySource != null);
        if (dependentChild.length > 0) {
          let displaySource =
            dependentChild[0].displaySource != undefined &&
              dependentChild[0].displaySource != null
              ? dependentChild[0].displaySource
              : "";
          if (value != 0 && displaySource != "") {
            getLookupDisplayData(value, displaySource).then((resp) => {
              if (resp.data[0] != null && resp.data[0].ID != null) {
                id1 = String(resp.data[0].ID);
                fieldChanged(
                  name + "," + dependentChild[0].name,
                  value + "," + id1
                );
                if(UpdateLookupField)
                  UpdateLookupField(id1, resp.data[0].Value);
              } else {
                fieldChanged(name, value);
              }
            });
          } else if (value != 0 && dependentChild[0].parentFieldId != 0) {
          }
        } else {
          fieldChanged(name, value);
        }
      } else {
        fieldChanged(name, value);
      }
    }
  };

  const location = useLocation();
  // let formMode = "read";

  // if (location.pathname.toLowerCase().indexOf("view") < 0) formMode = "write";

  const getValue = (endDatename?: string) => {
    let _value = values[endDatename || field.name];
    if (field.fieldTypeId == 10 && formMode == 'new' && datasourceId == 253) {
      _value = "true";
    }
    if ((field.fieldTypeId == 2|| 
      field.fieldTypeId == 3 || 
      field.fieldTypeId == 55 ||
      field.fieldTypeId == 58) && formMode == 'write' && _value != null) {
      _value = validateNumber(_value, 'read');
    }
    if (parentObject) {
      let id = field.name;
      let lastPeriodIndex = id.lastIndexOf(".");
      let masterName = id.substring(0, lastPeriodIndex);
      let detailName = id.substring(lastPeriodIndex + 1);
      if(field.name == "RegulatoryWatch_DosageAndMode.CycleNumber"){
        _value = parentObject["Cycles"][vi][field.name];
      }else
        _value = parentObject[field.name];
    }

    return _value ? _value : "";
  };

  const getValueForMax = (fieldName: string) => {
    let _value = values[fieldName];

    if (parentObject) {
      let id = fieldName;
      let lastPeriodIndex = id.lastIndexOf(".");
      let masterName = id.substring(0, lastPeriodIndex);
      let detailName = id.substring(lastPeriodIndex + 1);
      if(fieldName == "RegulatoryWatch_DosageAndMode.CycleNumber_Max"){
        _value = parentObject["Cycles"][vi][fieldName];
      }else
        _value = parentObject[fieldName];
    }

    return _value ? _value : "";
  };

  const getLookupLabel = (id: string, val?: number): string => {

    if (id == null || id == "") {
      return "";
    } 
    // if(id.indexOf("RegulatoryWatch_DosageAndMode") > 0){
    //   if(!values[id])
    //     return "0"
    //   else
    //     return values[id]
    // }
    if(id === "Cloned_From"){
      if(!values["Clone_Record"])
        return "0"
      return values["Clone_Record"]
    }

    if(id == "ArmsCount" && formMode == "read" ){
      return subpageData.arms_and_cohort_clinical?.length || "";
    }
    else if(id == "ArmsCount" ){
      return values.ArmsCohorts?.length || "";
    }

    else if (id == "CombinationSets") {
      const _item = items.find((el) => el.id == values[id]);
      return _item?.label || "";
    }

    if (field.name === "Acronym" && (formMode == 'write' || formMode == 'read')) {
      let lookupItems: lookupItemT[] = [];
      useEffect(() => {
        apiCall(`/api/GetRelatedData/${values["ClinicalData_ID"]}/${field.relatedDataType}`).then((r) => {
          if (r.data && r.data.length > 0) {
            setRelatedLink(true);
            for (let x of r.data) {
              lookupItems.push({ label: String(x.Name), id: String(x.HTARecord_ID) });
            }
            setRelatedItems(lookupItems);
          }
        });
      }, []);
    }

    if (val && id) {

      const _lookupItem = items.find((opt) => opt.id == String(val));
      let _label = "";

      if (_lookupItem) _label = _lookupItem.label;
      return _label;
    }

    // if (id.toLowerCase().includes("instrument")) {
    //   console.log(id);
    // }

    let _value = values[id];

    if(field.name == 'Region_ID' && datasourceId == DataSourceListTypes.Agreement && _value != null && typeof(values["Region_ID"]) == 'object')
      _value = _value[0];

    if(field.fieldTypeId == FormFieldType.TextareaRichtext && _value && formMode == "read")
      return _value


    if (parentObject) {
      let detailName = '';
      if(id.includes("RegulatoryWatch_DosageAndMode")){
        _value = parentObject[id];
        if(!_value)
          return "0"
        return _value
      }else{
        let lastPeriodIndex = id.lastIndexOf(".");
        let masterName = id.substring(0, lastPeriodIndex);
        detailName = id.substring(lastPeriodIndex + 1);
        _value = parentObject[detailName];
        if(id == "OtherComments")
        return _value
      }


    }


    if (_value == null || _value == "") return "";

    if (field.fieldTypeId == FormFieldType.DatePicker) {
      if (_value == null || _value == "") return "";
      else return dayjs(_value).format("DD-MM-YYYY");
    }

    if ((field.lookupId == null && id != "Lookup_Type") ||( id == "Datasource_id" && datasourceId == 264)) return _value;

    if (field.lookupId != null && field.fieldTypeId == 6 && id != "Lookup_Type") return _value;

    const lookupItem = items?.find((opt) => opt.id == _value);

    let label = "";

    if (lookupItem) label = lookupItem.label;
    // if (Number(datasourceId) == 4 && (id == "Country_ID" || id ) ) {
    //   setLookupLabelList({id : id , label : label})
    // }

    // if(datasourceId == 4) {
    //   if (!pricingTitleSource.hasOwnProperty(id) && label != "error" && (["Drug_ID","Country_ID",,"Unit_Dose_ID","Unit_Pack_ID"].includes(id))) {
    //     dispatch(setPricingTileSource({...pricingTitleSource, [id] : label}));
    //   }
    // }
    return label;
  };

  const AddWebLink = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!values.WebLink) {
      fieldChanged("WebLink", "", [{ WebLinkURL: "" }]);
      values.WebLink = [{ WebLinkURL: "" }];
    } else {
      let WebLink = [...values.WebLink];
      WebLink.push({ WebLinkURL: "" });
      fieldChanged(
        "WebLink",
        "",
        WebLink
      );
    }
  };

  const createDate = (value: any) => {
    let day = value.$D;
    let month = value.$M + 1;
    let year = value.$y;
    let date = `${month}-${day}-${year}`;
    return date;

  };
  // const [checkValue, setCheckValue] = useState<any>(null);
  // const [datePickerValue,setDatePickerValue]=useState<any>({});

  // const selectStartDate = (value: any, id: number, name: string) => {

  //   console.log(value, "value", id);
  //   if (value! == null) {

  //     console.log("Nothing to be changed")
  //     let date = createDate(value);
  //     // setCheckValue({ ...checkValue, [name]: date });
  //     fieldChanged(name,date)

  //   }
  //   else{
  //     console.log("Everything is done")
  //   }


  // }

  const DeleteWebLink = (i: number) => {
    let ReimbursementStatus_WebLink = [...values.WebLink].filter(
      (el, index) => index != i
    );

    fieldChanged("WebLink", "", ReimbursementStatus_WebLink);
  };

  const inputControl = (vi?: number) => {

    //let lookupItems = [];
    const { data, error, isLoading } = useFetchLookupItemsQuery({
      lookup_id: field.lookupId,
      datasource_id: datasourceId == 264 && (field.name =="Section_ID" || field.name == "FilterFieldSection") ? urlparams.recordID : field.name == "Field_CriteriaField_ID" ? fieldCriteriaDatasourceID : datasourceId,
      // datasource_id: datasourceId,
    });
    const [drugPopulationProgress,setDrugPopulationProgress] = useState<boolean>(false);
    const [keyClinicalTrail, setKeyClinicalTrail] = useState<boolean>(false);

    if (field.name == "PrimaryAutocompelete_ID") {
      field.name = "PrimaryAutocompelete_ID||" + field.sectionId;
    }

    if (field.name == "SecondaryAutocompelete_ID") {
      field.name = "SecondaryAutocompelete_ID||" + field.sectionId;
    }

    // useEffect(() => {
    //   if(field.name == "Lookup_Type"){
    //     const _items:lookupItemT[] = [{id:"1",label:"Internal"},{id:"2",label:"External"},{id:"3",label:"Federated (New SSO)"},{id:"4",label:"Federated (Old SSO)"}];
    //     setItems(_items);
    //     lookupLoading = false;
    //   }
    // },[])

    useEffect(() => {
      if (data && fieldCriteriaDatasourceID) {
        let lookupItems: lookupItemT[] = [];
        let tmpDrugName: any = [];
        setRawData(data);
        // let tmpProcedureIntervenion: any = [];
        // let endpointResult: any = [];

        for (let x of data) {
          lookupItems.push({ label: String(x.Value), id: String(x.ID), parentId: x.Parent_ID  != null ? String(x.Parent_ID ) : '' });
          // Drug brand name
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.HTARecord_Drug &&
            selectedLookupGlobe.HTARecord_Drug.length > 0
          ) {
            for (let drug of selectedLookupGlobe.HTARecord_Drug) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
                // endpointResult.push({
                //   label: String(x.Value),
                //   id: String(x.ID),
                // });
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.HTARecord_DrugComparator &&
            selectedLookupGlobe.HTARecord_DrugComparator.length > 0
          ) {
            for (let drug of selectedLookupGlobe.HTARecord_DrugComparator) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.ClinicalData_Drug &&
            selectedLookupGlobe.ClinicalData &&
            selectedLookupGlobe.ClinicalData.length > 0
          ) {
            for (let drug of selectedLookupGlobe.ClinicalData_Drug) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.ClinicalData_DrugControl &&
            selectedLookupGlobe.ClinicalData_DrugControl.length > 0
          ) {
            for (let drug of selectedLookupGlobe.ClinicalData_DrugControl) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          // if (
          //   field.lookupId === 43 &&
          //   selectedLookupGlobe.HTARecord_ProcedureIntervention &&
          //   selectedLookupGlobe.HTARecord_ProcedureIntervention.length > 0
          // ) {
          //   for (let drug of selectedLookupGlobe.HTARecord_ProcedureIntervention) {
          //     if (drug.Id === x.ID) {
          //       tmpProcedureIntervenion.push(x);
          //       endpointResult.push({
          //         label: String(x.Value),
          //         id: String(x.ID),
          //       });
          //     }
          //   }
          // }
        }
        if (tmpDrugName.length > 0) {
          setDrugNameList(tmpDrugName);
          dispatch(setLookupData(tmpDrugName));
        }
        setOriginalItems(lookupItems);
        setItems(lookupItems);
        if (lookupItems && !values["Value"])
          fieldChanged("Value", lookupItems[0]?.['id']);        
      }
    }, [data]);

    useEffect(() => {
      if (data) {
        let lookupItems: lookupItemT[] = [];
        let tmpDrugName: any = [];
        setRawData(data);
        // let tmpProcedureIntervenion: any = [];
        // let endpointResult: any = [];

        for (let x of data) {
          lookupItems.push({ label: String(x.Value), id: String(x.ID), parentId: x.Parent_ID  != null ? String(x.Parent_ID ) : '' });
          // Drug brand name
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.HTARecord_Drug &&
            selectedLookupGlobe.HTARecord_Drug.length > 0
          ) {
            for (let drug of selectedLookupGlobe.HTARecord_Drug) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
                // endpointResult.push({
                //   label: String(x.Value),
                //   id: String(x.ID),
                // });
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.HTARecord_DrugComparator &&
            selectedLookupGlobe.HTARecord_DrugComparator.length > 0
          ) {
            for (let drug of selectedLookupGlobe.HTARecord_DrugComparator) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.ClinicalData_Drug &&
            selectedLookupGlobe.ClinicalData &&
            selectedLookupGlobe.ClinicalData.length > 0
          ) {
            for (let drug of selectedLookupGlobe.ClinicalData_Drug) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          if (
            field.lookupId === 122 &&
            selectedLookupGlobe.ClinicalData_DrugControl &&
            selectedLookupGlobe.ClinicalData_DrugControl.length > 0
          ) {
            for (let drug of selectedLookupGlobe.ClinicalData_DrugControl) {
              if (drug.Id === x.ID) {
                tmpDrugName.push(x);
              }
            }
          }
          // if (
          //   field.lookupId === 43 &&
          //   selectedLookupGlobe.HTARecord_ProcedureIntervention &&
          //   selectedLookupGlobe.HTARecord_ProcedureIntervention.length > 0
          // ) {
          //   for (let drug of selectedLookupGlobe.HTARecord_ProcedureIntervention) {
          //     if (drug.Id === x.ID) {
          //       tmpProcedureIntervenion.push(x);
          //       endpointResult.push({
          //         label: String(x.Value),
          //         id: String(x.ID),
          //       });
          //     }
          //   }
          // }
        }
        if (tmpDrugName.length > 0) {
          setDrugNameList(tmpDrugName);
          dispatch(setLookupData(tmpDrugName));
        }
        setOriginalItems(lookupItems);
        if(field.name !== "ClinicalData_Arms.OutcomeResultID")
          setItems(lookupItems);
      }
      if (field.name === "CombinationSets") {
        setItems([
          { id: "1", label: "Set 1" },
          { id: "2", label: "Set 2" },
          { id: "3", label: "Set 3" },
          { id: "4", label: "Set 4" },
          { id: "5", label: "Set 5" },
        ])
      }
      if (!isLoading && !keyClinicalTrail) lookupLoading = isLoading;
    }, [isLoading]);

    useEffect(() => {
      if([256,257,258,259].includes(datasourceId) && field.name === "Parent_Disease_ID")
        filterChildDiseaseBasedOnParent(values.TherapeuticArea_ID,"Parent_Disease_ID")
    },[values.TherapeuticArea_ID])

    useEffect(() => {
      if([256,257,258,259].includes(datasourceId) && field.name === "Parent_SubDiseaseType1_ID")
        filterChildDiseaseBasedOnParent(values.Parent_Disease_ID,"Parent_SubDiseaseType1_ID")
    },[values.Parent_Disease_ID])

    useEffect(() => {
      if([256,257,258,259].includes(datasourceId) && field.name === "Parent_SubDiseaseType2_ID")
        filterChildDiseaseBasedOnParent(values.Parent_SubDiseaseType1_ID,"Parent_SubDiseaseType2_ID")
    },[values.Parent_SubDiseaseType1_ID])

    useEffect(() => {
      if([256,257,258,259].includes(datasourceId) && field.name === "Parent_SubDiseaseType3_ID")
        filterChildDiseaseBasedOnParent(values.Parent_SubDiseaseType2_ID,"Parent_SubDiseaseType3_ID")
    },[values.Parent_SubDiseaseType2_ID])

    const filterChildDiseaseBasedOnParent = (parentID: string,childName: string) => {
        if(parentID){
          let _originalItems: lookupItemT[] = originalItems.filter((x) => x.parentId == parentID);
          setItems(_originalItems);
        }else {
          setItems(originalItems);
        }
      
    }

    useEffect(() => {
      if (((open && field.name == "HTA_PivotalClinicaltrial") ||
        ((formMode == "write" || formMode == "read") && field.name == "HTA_PivotalClinicaltrial")) && !fieldCriteriaDatasourceID)  {
        const DrugIDs =
          selectedLookupGlobe.HTARecord_Drug?.map((drug) => drug.Id) || [];
        PostCall(
          `/api/lookup/keyclinical/${field.lookupId}/${datasourceId}`,
          { DrugIDs: DrugIDs }
        ).then((res) => {
          let lookupItems: lookupItemT[] = [];
          for (let x of res.data) {
            lookupItems.push({ label: String(x.Value), id: String(x.ID) });
          }
          setItems(lookupItems);
          setKeyClinicalTrail(true);
        });
      }
      if (open && field.parentFieldId != null &&  !fieldCriteriaDatasourceID&&
         !["ManufacturersBasecase_ID", "IndependentBasecase_ID", "PayerBasecase_ID","TreatmentManufacturersBasecase_ID"].includes(field.name)) {
        let parentField:any = !!allFields && allFields.find((x) => x.id == field.parentFieldId);
        const parentData = values[parentField.name];
        let lookupItems: lookupItemT[] = [];
        for (let x of originalItems) {
          if (x.parentId == parentData) {
            lookupItems.push({ label: String(x.label), id: String(x.id) });
          }
        }
        if (field.fieldTypeId == FormFieldType.Dropdown && field.parentFieldId != null && values[parentField.name] == undefined  ) {
          setItems([]);
        }
        else {
          setItems(lookupItems);
        }
      }
    }, [open]);

    useEffect(() => {
      if (field.name === "ClinicalData_Arms.OutcomeResultID") {
        const ClinicalData_Drug: lookupItemT[] =
          (selectedLookupGlobe["ClinicalData_Drug"] &&
            selectedLookupGlobe["ClinicalData_Drug"].map((x: any) => {
              return {
                id: String(x.Id),
                label: x.Value,
              };
            })) ||
          [];

        const ClinicalData_Drug_Combination: lookupItemT[] =
          selectedLookupGlobeSpecial["ClinicalData_Drug_Combination"]?.map(
            (x: any) => {
              return {
                id: String(x.Id),
                label: x.Value,
              };
            }
          ) || [];

        const ClinicalData_Device: lookupItemT[] =
          (!!selectedLookupGlobe["ClinicalData_Device"] &&
            selectedLookupGlobe["ClinicalData_Device"].map((x: any) => {
              return {
                id: String(100000 + x.Id),
                label: x.Value,
              };
            })) ||
          [];

        const ClinicalData_ProcedureIntervention: lookupItemT[] =
          (!!selectedLookupGlobe["ClinicalData_ProcedureIntervention"] &&
            selectedLookupGlobe["ClinicalData_ProcedureIntervention"].map(
              (x: any) => {
                return {
                  id: String(200000 + x.Id),
                  label: x.Value,
                };
              }
            )) ||
          [];

        const DrugIds =
          selectedLookupGlobe["ClinicalData_Drug"]?.map((el) => el.Id) || [];
        const ClinicalData_DrugControl: lookupItemT[] =
          (selectedLookupGlobe["ClinicalData_DrugControl"] &&
            selectedLookupGlobe["ClinicalData_DrugControl"]
              .filter((el) => !DrugIds.includes(el.Id))
              .map((x: any) => {
                return {
                  id: String(x.Id),
                  label: x.Value,
                };
              })) ||
          [];

        const Drug_CombinationIds =
          selectedLookupGlobeSpecial["ClinicalData_DrugControl_Combination"]?.map(
            (x) => x.Id
          ) || [];

        const ClinicalData_Drug_CombinationControl: lookupItemT[] =
          selectedLookupGlobeSpecial["ClinicalData_DrugControl_Combination"]
            ?.filter((el) => Drug_CombinationIds.includes(el.Id))
            .map((x: any) => {
              return {
                id: String(x.Id),
                label: x.Value,
              };
            }) || [];

        const DeviceIds =
          selectedLookupGlobe["ClinicalData_Device"]?.map((el) => el.Id) || [];
        const ClinicalData_DeviceControl: lookupItemT[] =
          (!!selectedLookupGlobe["ClinicalData_DeviceControl"] &&
            selectedLookupGlobe["ClinicalData_DeviceControl"]
              .filter((el) => !DeviceIds.includes(el.Id))
              .map((x: any) => {
                return {
                  id: String(100000 + x.Id),
                  label: x.Value,
                };
              })) ||
          [];

        const ProcedureInterventionIds =
          selectedLookupGlobe["ClinicalData_ProcedureIntervention"]?.map(
            (el) => el.Id
          ) || [];

        const ClinicalData_ProcedureInterventionControl: lookupItemT[] =
          (!!selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"] &&
            selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"]
              ?.filter((el) => !ProcedureInterventionIds.includes(el.Id))
              .map((x: any) => {
                return {
                  id: String(200000 + x.Id),
                  label: x.Value,
                };
              })) ||
          [];

        const item: lookupItemT[] = [
          ...ClinicalData_Drug,
          ...ClinicalData_Drug_Combination,
          ...ClinicalData_Device,
          ...ClinicalData_ProcedureIntervention,
          ...ClinicalData_DrugControl,
          ...ClinicalData_Drug_CombinationControl,
          ...ClinicalData_DeviceControl,
          ...ClinicalData_ProcedureInterventionControl,
        ];

        setItems(item || []);
        lookupLoading = true;
      }
      if (field.name === "HTA_PivotalClinicaltrial" && (formMode == "write" || formMode == "read")) {
        const DrugIDs =
          selectedLookupGlobe.HTARecord_Drug?.map((drug) => drug.Id) || [];
        PostCall(
          `/api/lookup/keyclinical/${field.lookupId}/${datasourceId}`,
          { DrugIDs: DrugIDs }
        ).then((res) => {
          let lookupItems: lookupItemT[] = [];
          for (let x of res.data) {
            lookupItems.push({ label: String(x.Value), id: String(x.ID) });
          }
          setItems(lookupItems);
          setKeyClinicalTrail(true);
        });
      }
      if (field.name === "Drug_ID" && (formMode == "write" || formMode == "read") && datasourceId == DataSourceListTypes.ReimbursementStatus) {
        onDrugChange(values["Drug_ID"]);
      }

    }, [selectedLookupGlobe?.ClinicalData_Drug,
      selectedLookupGlobeSpecial?.ClinicalData_Drug_Combination,
      selectedLookupGlobe?.ClinicalData_Device,
      selectedLookupGlobe?.ClinicalData_ProcedureIntervention,
      
      selectedLookupGlobe?.ClinicalData_DrugControl,
      selectedLookupGlobeSpecial?.ClinicalData_DrugControl_Combination,
      selectedLookupGlobe?.ClinicalData_DeviceControl,
      selectedLookupGlobe?.ClinicalData_ProcedureInterventionControl,
      selectedLookupGlobe?.HTARecord_Drug,
      values.Drug_ID]);

    const handleCloseRecDel = () => {
      setShowDeviceBrandNameModal(null);
    };

    const handleCloseRelatedHTAs = () => {
      setShowRelatedHTAsModal(null);
    };

    if (  Number(urlparams.dataSourceID) != 277 && (field.readOnly || formMode == "read")) {
      if (
        !(
          (formMode == "write" || formMode == "new") &&
          field.fieldTypeId == FormFieldType.TextareaRichtext
        )
      ) {
        switch (field.fieldTypeId) {
          case FormFieldType.LookupAutoComplete:
          case FormFieldType.DeviceDropdownRelatedData:
          case FormFieldType.Dropdown:
            if (hyperlinkList.includes(field.name)) {
              return (
                <a
                  style={{
                    color: "rgb(0, 170, 231)",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  href={`/view/${datasourceMap[field.name]}/${values[field.name]
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getLookupLabel(field.name)}
                </a>
              );
            } else {
              return (
                <label>
                  {/* {isLoading && "loading"}{" "} */}
                  {isLoading && <CircularProgress color="inherit" size={10} />}{" "}
                  {!isLoading && getLookupLabel(field.name)}
                </label>
              );
            }

          case FormFieldType.Checkbox:
            return (
              <input
                type="checkbox"
                readOnly
                value={getValue()}
                style={{ color: "rgb(0, 170, 231)", marginTop: 2, width: "16px", height: "16px" }}
                checked={getValue()}
              />
            );
          case FormFieldType.Image:
            let base64Str = "";
            let uint8Array = new Uint8Array(values[field.name].data);
            if (uint8Array != null) {
              base64Str = btoa(String.fromCharCode(...uint8Array));
            }
            return (
              <img
                src={"data:image/png;base64," + base64Str}
                className="image-illustration"
              />
            );
          case FormFieldType.MultiSelect:
          case FormFieldType.DrugSelect:
          case FormFieldType.DeviceSelect:
          case FormFieldType.ClinicalDataSelect:
            let msKey = "";
            if (vi != undefined) {
              msKey = field.name + "_" + String(vi);
            } else {
              msKey = field.name;
            }

            const hasKey = selectedLookupGlobe.hasOwnProperty(msKey);
            const hasKeySpecial =
              selectedLookupGlobeSpecial.hasOwnProperty(msKey);
            return (
              <div className="custom-scroll">
                {hasKey &&
                  hyperlinkList.includes(field.name) &&
                  selectedLookupGlobe[msKey].map((row, index) => {
                    return (
                      <>
                        <a
                          key={index}
                          style={{
                            color: "rgb(0, 170, 231)",
                            border: "none",
                            background: "none",
                            padding: "0px",
                            fontSize: "12px",
                            display: "block",
                          }}
                          href={`/view/${datasourceMap[msKey]}/${row.Id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.Value}
                        </a>
                        {(field.name === "HTARecord_Drug" ||
                          field.name === "RegulatoryWatch_Drug" ||
                          field.name === "ClinicalData_Drug") &&
                          displayDrugBrandName(row)}
                      </>
                    );
                  })
                }
                {hasKey &&
                  !hyperlinkList.includes(field.name) &&
                  !lookupIdWithParentValue.includes(field.lookupId) &&
                  selectedLookupGlobe[msKey].map((row) => {
                    return (
                      <span
                        key={row.Id}
                        style={{
                          //color: "#00aae7",
                          width: "100%",
                          display: "block",
                          fontSize: "12px",
                        }}
                      >
                        {row.Value}
                      </span>
                    );
                  })}
                {/* {hasKey &&
                  !hyperlinkList.includes(field.name) &&
                  // !lookupIdWithParentValue.includes(field.lookupId) &&
                  ![
                    "HTARecord_Sourceofcomparator",
                    "HTARecord_CoaInstrument",
                  ].includes(field.name) &&
                  selectedLookupGlobe[msKey].map((row) => {
                    return (
                      <span
                        style={{
                          width: "100%",
                          display: "block",
                          fontSize: "12px",
                        }}
                      >
                        {row.Value}
                      </span>
                    );
                  })} */}
                  {hasKeySpecial &&
                  [
                    "HTARecord_Sourceofcomparator"
                  ].includes(field.name) && (
                    <>
                      <ComplexLookupSplit data={selectedLookupGlobeSpecial[field.name]} />
                    </>
                  )}
                {/* Uncommented the below code as the selectedLookupGlobeSpecial is removed from selectedLookupGlobe list. */}
                {hasKeySpecial &&
                ![
                  "HTARecord_Sourceofcomparator"
                ].includes(field.name) &&
                  selectedLookupGlobeSpecial[field.name].map((row) => {
                    return (
                      <>
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            fontSize: "12px",
                          }}
                        >
                          {row.Value}
                        </span>
                      </>
                    );
                  })}
                {hasKey &&
                  [
                    "HTARecord_CoaInstrument",
                    "HTARecord_ClinicalPositives",
                    "HTARecord_ClinicalNegatives",
                  ].includes(field.name) && (
                    <>
                      <ComplexLookupSplit data={selectedLookupGlobe[msKey]} />
                    </>
                  )}
              </div>
            );
          case FormFieldType.Decimal:
            return (
              <label>
                {isLoading && <CircularProgress color="inherit" size={10} />}{" "}
                {!isLoading && validateNumber(values[field.name], 'read')}
              </label>
            );
          case FormFieldType.NumberRange:
            let minVal = "";
            let maxVal = "";
            minVal = validateNumber(values[field.name], 'read');
            if ([1].includes(datasourceId)) {
              maxVal = validateNumber(values[field.name + "_Max"], 'read');
            }
            return (
              <>
                <label>{minVal}</label>
                {[1].includes(datasourceId) && (
                  <>
                    <span> - </span> <label>{maxVal}</label>{" "}
                  </>
                )}
                {field.name == "ClinicalData_Endpoints.ConfidenceInterval" && (
                  <>
                    <span> - </span> <label>{validateNumber(values["ClinicalData_Endpoints.ConfidenceInterval_Max"], 'read')}</label>{" "}
                  </>
                )}
                {field.name == "ReimbursementRate" && (
                  <>
                    <span> - </span> <label>{validateNumber(values["ReimbursementRate_Max"], 'read')}</label>{" "}
                  </>
                )}
              </>
            );
          case FormFieldType.Hyperlink:
            if (field.name.toLowerCase().indexOf("direct_link") >= 0) {
              return (
                <a
                  style={{
                    color: "rgb(0,170,231)",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  href={`/view/${datasourceId}/${values[col_items[datasourceId]]}`}
                  target="_blank"
                >
                  Link
                </a>
              );
            } else if (
              values[field.name] != null ||
              field.name.toLowerCase().indexOf("link") >= 0
            ) {
              return (
                <a
                  style={{
                    color: "rgb(0,170,231)",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  href={values[field.name]}
                  target="_blank"
                >
                  Link
                </a>
              );
            } else {
              return (
                <button
                  type="button"
                  id={field.name}
                  className="btn btn-link"
                  style={{
                    ...sizeColor,
                    paddingTop: "1px",
                    paddingLeft: "0px",
                  }}
                  onClick={() =>
                    AssignMe(
                      datasourceId,
                      field.name,
                      values["ID"],
                      values[col_items[datasourceId]],
                      values,
                      selectedLookupGlobe,
                      selectedLookupGlobeSpecial,
                      allFields
                    )
                  }
                >
                  {" "}
                  Assign
                </button>
              );
            }
          case FormFieldType.WebLinks:
            return values?.WebLink?.map((el: any, i: number) => (
              <>
              <div style={{ marginLeft: i == 0 ? "-9em" : "-4.3em"}} key={i}>
                <span>{i == 0 ? "WebLink" : ""} {i + 1}</span>
                <a
                  style={{ paddingLeft: "48px", color: "rgb(0, 170, 231)" }}
                  href={el.WebLinkURL}
                  target="_blank"
                >
                  Link
                </a>
              </div>
              {i < values?.WebLink?.length-1 && <br /> }
              </>
            ));

          default:
            if (hyperlinkList.includes(field.name)) {
              return (
                <a
                  style={{
                    color: "rgb(0, 170, 231)",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  href={`/view/${datasourceMap[field.name]}/${values[field.name]
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getLookupLabel(field.name)}
                </a>
              );
            } else if (
              field.name == "Molecule" &&
              values?.Drug_ID != null &&
              datasourceId == DataSourceListTypes.ReimbursementStatus
            ) {
              return (
                <label>
                  {nonVersionCol1 != null && nonVersionCol1 != undefined
                    ? nonVersionCol1
                    : ""}
                </label>
              );
            } else if (
              field.name == "Manufacturer" &&
              values?.Drug_ID != null &&
              datasourceId == DataSourceListTypes.ReimbursementStatus
            ) {
              return (
                <label>
                  {nonVersionCol2 != null && nonVersionCol2 != undefined
                    ? nonVersionCol2
                    : ""}
                </label>
              );
            } else {
              return (
                <>
                <label style={{maxWidth: '100%', whiteSpace: "break-spaces"}} className='label-with-image'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getLookupLabel(field.name), {
                      ALLOWED_TAGS: [
                        'a', 'abbr', 'acronym', 'b', 'blockquote', 'code', 'div', 'em', 'i', 'li', 'ol', 'strong', 'ul',
                        'p', 'span', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
                        'img', 'figure', 'figcaption', 'pre', 'br', 'hr', 'del', 'ins', 'mark', 'small', 'sub', 'sup',
                        'time', 'video', 'audio', 'source', 'iframe', 'embed', 'object'
                      ],
                      ALLOWED_ATTR: [
                        'href', 'title', 'src', 'alt', 'class', 'style', 'id', 'name', 'width', 'height', 'data-*',
                        'border', 'cellpadding', 'cellspacing', 'align', 'valign', 'frameborder', 'allowfullscreen',
                        'controls', 'autoplay', 'loop', 'muted', 'poster', 'preload', 'type'
                      ],
                      ADD_ATTR: ['target', 'rel', 'download'], // Add any additional attributes you need
                      KEEP_CONTENT: true
                    }),
                    // __html: getLookupLabel(field.name),
                  }}
                ></label>
                {field.name === "Acronym" && relatedLink &&
                    <span
                      style={{
                        width: "5%",
                        display: "block",
                        fontSize: "12px",
                        color: "#00aae7",
                        textDecoration: "underline",
                        textDecorationColor: "#00aae7",
                        cursor: "pointer"
                      }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setShowRelatedHTAsModal(event.currentTarget);
                      }}
                    >
                      HTA
                    </span>
                }
                 {
              subscriptionDateKeys.includes(field.name) &&
              <>
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  -
                </span>

                <label>
                  {getValue(subscriptionDateEndName[field.name]) == "" ? null : moment(getValue(subscriptionDateEndName[field.name])).format("yyyy-MM-DD")}
                </label>
               
              </>

            }
                {showRelatedHTAsModal && (
                  <RelatedHTAsPopup
                    showRelatedHTAsModal={showRelatedHTAsModal}
                    handleCloseRelatedHTAs={handleCloseRelatedHTAs}
                    formValues={values}
                    relatedData={relatedItems}
                  />
                )}
                </>
              );
            }
        }
      }
    }
    const RegulatoryWatchDateFields = [
      "RegulatoryWatch_ApprovedIndications.ApprovalDate",
    ];

    const populateDrugs = () => {
      if (selectedLookupGlobe?.SuperSearchDiseaseHierIDs?.length > 0) {
          setDrugPopulationProgress(true);
        let diseaseHierIDs = selectedLookupGlobe?.SuperSearchDiseaseHierIDs.map((x) => x.Id);
        let diseaseHierIDsString = diseaseHierIDs.join(",");
        PostCall("/api/admin/getDrugsUsingDisease", {
          DiseaseHierIDs: diseaseHierIDsString,
        }).then((res) => {
          const resp : {Id: number , Value : string}[] = res.data;
          let selectedDrug: IselectedLookup[] = [];
          for (let x of resp) {
            selectedDrug.push({ Id: x.Id, Value: x.Value });
          }
          dispatch(setSelectedLookupGlobe({ ...selectedLookupGlobe, "DS_DrugIds": selectedDrug }));
          setDrugPopulationProgress(false);
          ToastrAlert("Drug data populated successfully", "info");
        }).catch(error => {
          setDrugPopulationProgress(false);        });
      }else{
        ToastrAlert("Please select at least one diseasehierarchy", "warning");
      }
      ;
    }


    const customFormat = 'DD-MM-YYYY';
    switch (field.fieldTypeId) {
      case FormFieldType.LookupAutoComplete:
        return (
          <div style={{ width: field.name === "DrugData_BrandLicense.RegionalDrug_ID_Text" ? "140px" : "53%" ,position: field.name == "ReimbursementAuthority_ID_Text" ? "relative" : undefined, zIndex: 5 }}>
            {/* <div style={{zIndex:5}}> */}
            <AutocompleteWithAdd
              field={field}
              items={items}
              setItems={setItems}
              values={values}
              onFieldChange={onFieldChange}
              createDataNewModal={createDataNewModal}
              resetValue={resetValue}
              index={index}
            />
          </div>
        );
      case FormFieldType.Dropdown:
      case FormFieldType.ComboboxSingle:
      case FormFieldType.DropdownRelatedData:
        return (
          <>
            <Autocomplete
              disablePortal
              freeSolo
              id="combo-box-demo"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              options={items}
              key={field.name}
              loading={lookupLoading}
              autoComplete={false}
              // isOptionEqualToValue={(option, value) => option.id === value.id}
              loadingText="Loading..."
              sx={
                columnTypeFieldBrandLicence.includes(field.name) ||
                  columnTypeFieldLove.includes(field.name)
              ? { width: "100%", height: 38,display:"block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",marginRight:"40px",overFlowwrap:"break-word"}
                  : field.name == "RegulatoryWatch_ApprovedIndications.Type_ID"
                    ? { width: "125px",color:"#999999",backgroundImage:"linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%)",borderRadius:"2px",height:"36px"}
                    : ReimbursementStatus_ProductStrengthFields.includes(field.name)
                      ? { width: "140px",color:"#999999",backgroundImage:"linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%)",borderRadius:"2px",height:"36px"}
                      : DrugDropDownName.includes(field.name)
                        ? { width: "200px",color:"#999999",backgroundImage:"linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%)",borderRadius:"2px",height:"36px" }
                        : { width: "300px",color:"#999999",backgroundImage:"linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%)",borderRadius:"2px",height:"36px" }
              }
              
            style={{color:"#999999",backgroundImage:"linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%)",borderRadius:"2px",width:field.name == "RegulatoryWatch_ApprovedIndications.Type_ID"? "240%": field.name == "DrugData_BrandLicense.Corporation_ID" || field.name == "DrugData_BrandLicense.DealType_ID" ? "140px" : field.name == "ReimbursementStatus_ProductFormulation" || field.name == "ReimbursementStatus_status" ? "120px" : "50%",height:"37px"}}

              // style={{marginRight:"40px"}}
              className={
                columnTypeFieldBrandLicence.includes(field.name) ||
                  columnTypeFieldLove.includes(field.name)
                  ? "columnTypeFieldBrandLicence"
                  : ""
              }
              value={
                parentObject !== undefined
                  ? getLookupLabel(field.name, parentObject[field.name])
                    : getLookupLabel(field.name)
              }
              onChange={(event, newValue: any) => {
                if( field.name == "ParentInstrument_Id" && datasourceId == 262) {   
                    const _values  :any = {...values}            
                    _values["ParentInstrument_Id"] = newValue['id'];
                    _values.Parent = newValue['label'];                  
                    userContext.setValues(_values);
                }else {
                  if (
                    datasourceId == DataSourceListTypes.Agreement &&
                    field.lookupId == LookupIds.DS_DrugGeneric
                  ) {
                    loadManufactureGenericForDrug(newValue["id"]);
                    onFieldChange(field.name, newValue["id"], field.id);
                  } else {
                    if (newValue != null) {
                      onFieldChange(field.name, newValue["id"], field.id);
                    } else {
                      onFieldChange(field.name, "", field.id);
                    }
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  required={
                    field.required || mandatoryFields.includes(field.name)
                  }
                  {...params}
                  placeholder="Select an Option"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {lookupLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  sx={{ height: 38 }}
                />
              )}
              renderOption={renderOptions}
            // renderOptions={(props, options) => {
            //   <li {...props}>
            //     {options.label}
            //     <Tooltip title={options.label} arrow>
            //       <span></span>
            //     </Tooltip>
            //   </li>
            // }}
            />
            {
              diseaseKeys.includes(field.name) && datasourceId && <FontAwesomeIcon
                style={{
                  fontSize: "11px",
                  marginLeft: "5px",
                  cursor: "pointer",
                  marginTop : "-25px",
                  marginRight : "410px",
                  float : "right",
                  
                }}
                onClick={() => {
                  resetDiseaseForAdmin(field.name,null);
                }}
                icon={faArrowsRotate}
              />
            }
            {values && values['Drug_ID'] && field.name == 'Drug_ID' &&
              <a
                style={{
                  color: "rgb(0, 170, 231)",
                  border: "none",
                  background: "none",
                  padding: "0px",
                  fontSize: "12px",
                }}
                href={`/view/20/${values['Drug_ID']}`}
                target="_blank"
                rel="noreferrer"
              >
                Drug details
              </a>
            }
          </>
        );

      case FormFieldType.MultiSelect:
      case FormFieldType.DrugSelect:
      case FormFieldType.DeviceSelect:
      case FormFieldType.ClinicalDataSelect:
        // let value = String(field.lookupId) + '_' + field.name
        //const msKey = field.name;// + (detailIndex >= 0 ? detailIndex.toString() : '');

        let msKey = "";
        if (index != undefined) {
          msKey = field.name + "_" + String(index);
        } else {
          msKey = field.name;
        }

        const hasKey = selectedLookupGlobe.hasOwnProperty(msKey);
        const hasKeySpecial = selectedLookupGlobeSpecial.hasOwnProperty(msKey);
        return (
          <>
            {formMode != "read" && datasourceId != 264 && (
              <>
                <button 
                  style={{
                    color: "#00aae7",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                    textAlign: "left",
                    marginTop:
                      columnTypeFieldBrandLicence.includes(field.name) ||
                        columnTypeFieldLove.includes(field.name)
                        ? 2
                        : approvedIndicationsFields.includes(field.name)
                          ? 22
                          : 0,
                  }}
                  onClick={() => openModal(field.lookupId, msKey, field.label)}
                  type="button"
                >
                  {" "}
                  {buttonLabel(field.name)}
                </button>
                {field.name != "RegulatoryWatch_ApprovedIndication_Disease" && (field.name.toLowerCase().indexOf("disease") > 0 ||
                  field.name.toLowerCase().indexOf("therapeuticarea") > 0) && (
                    <FontAwesomeIcon
                    style={{
                      fontSize: "11px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => 
                      {
                        resetFieldDisease(field);
                      }}
                    icon={faArrowsRotate}
                  />
                    // <RefreshIcon
                    //   style={{
                    //     fontSize: "16px",
                    //     marginLeft: "5px",
                    //     cursor: "pointer",
                    //   }}
                    //   onClick={() => {
                    //     resetFieldDisease(field);
                    //   }}
                    // ></RefreshIcon>
                  )}
                {(((field.fieldTypeId == FormFieldType.ClinicalDataSelect ||
                  field.fieldTypeId == FormFieldType.DeviceSelect) &&
                  datasourceId == DataSourceListTypes.HTARecord) ||
                  field.name == "ClinicalData_Device") && (
                    <button
                      style={{
                        color: "#00aae7",
                        border: "none",
                        background: "none",
                        padding: "0px",
                        fontSize: "12px",
                        marginTop: 10,
                        paddingLeft: "3em",
                      }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (
                          field.name != "ClinicalData_Device" &&
                          field.fieldTypeId != FormFieldType.DeviceSelect
                        ) {
                          window.open("form/2", "_blank", "noreferrer");
                        } else setShowDeviceBrandNameModal(event.currentTarget);
                      }}
                      type="button"
                    >
                      Create
                    </button>
                  )}
                  {
                    field.name == "SuperSearchDiseaseHierIDs" && datasourceId == 274 &&
                    <button style={{
                      marginRight:"200px",
                      padding:"5px",
                      border:"none",
                      background:"rgb(0, 170, 231)",
                      color:"white",
                      fontWeight:"400",
                      fontSize:"1rem",
                      float:"right",
                    }}
                    type="button"
                    onClick = {() =>  populateDrugs()}
                    >Populate drugs</button>
                  }
                        {drugPopulationProgress && (
                              <div className="search-grid-result loadingdiv" >
                                <div className="loadingResult-popup">
                                    <div className="loadingResult-content">
                                        <div>
                                            <br />
                                            <div>
                                                <Spinner animation="border" style={{color: "#00aae7"}} />
                                            </div>
                                            <br />
                                            <h4 className="saving-wait">Loading, please wait...</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="loadingResult-Background"></div>
                              </div>
                            )}

                {showDeviceBrandNameModal && (
                  <DevicePopup
                    showDeviceBrandNameModal={showDeviceBrandNameModal}
                    handleCloseRecDel={handleCloseRecDel}
                    datasourceId={datasourceId}
                  />
                )}
                {field.name == "HTARecord_DrugComparatorPayer" &&
                  datasourceId == DataSourceListTypes.HTARecord && (
                    <button
                      style={{
                        color: "#00aae7",
                        border: "none",
                        background: "none",
                        padding: "0px",
                        fontSize: "12px",
                        marginTop: "0px",
                        paddingLeft: "3em",
                      }}
                      onClick={() =>
                        copyFieldData(field.name, "HTARecord_DrugComparator")
                      }
                      type="button"
                    >
                      {" "}
                      Same as above
                    </button>
                  )}
              </>
            )}
            <div className="custom-scroll">
            {hasKey &&
              hyperlinkList.includes(msKey) &&
              // !lookupIdWithParentValue.includes(field.lookupId) &&
              selectedLookupGlobe[msKey]?.map((row, index) => {
                return (
                  <>
                    <span
                      style={{ display: "block" }}
                      onMouseEnter={() => {
                        setHoveredIndex(row.Id);
                      }}
                      onMouseLeave={() => {
                        setHoveredIndex(-1);
                      }}
                    >
                      {row.Id === hoveredIndex && (
                        <span onClick={() => removeMultiSelect(msKey, index, row.Id, field.lookupId)}>
                          <CloseIcon
                            style={{
                              color:
                                row.Id === hoveredIndex ? "#ff0000" : "#00aae7",
                              transition: "color 0.3s ease",
                              cursor: "pointer",
                              fontSize: "16px"
                            }}
                          />
                        </span>
                      )}
                      <a
                        key={index}
                        style={{
                          color: "rgb(0, 170, 231)",
                          border: "none",
                          background: "none",
                          padding: "0px",
                          fontSize: "12px",
                          // display: "block",
                        }}
                        href={`/view/${datasourceMap[msKey]}/${row.Id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.Value}
                      </a>
                    </span>

                    {(field.name === "HTARecord_Drug" ||
                      field.name === "RegulatoryWatch_Drug" ||
                      field.name === "ClinicalData_Drug") &&
                      displayDrugBrandName(row)}
                  </>
                );
              })}
              </div>
            {(mandatoryFields.includes(field.name) || ([265,270, 271, 274,276].includes(datasourceId) && field.required)) && (
              <div style={{ height: "0px" }}>
                <input
                  required={true}
                  style={{
                    border: "none",
                    width: "0px",
                    height: "0px",
                    outline: "none",
                  }}
                  onChange={() => {}}
                  value={
                    selectedLookupGlobe[msKey] &&
                      selectedLookupGlobe[msKey].length > 0
                      ? "test"
                      : ""
                  }
                />
              </div>
            )}
            <div className="custom-scroll">

              {hasKey &&
                !(
                  msKey.indexOf("PrimaryAutocompelete_ID") >= 0 ||
                  msKey.indexOf("SecondaryAutocompelete_ID") >= 0 ||
                  msKey.indexOf("HTARecord_CoaInstrument") >= 0 ||
                  msKey.indexOf("HTARecord_ClinicalPositives") >= 0 ||
                  msKey.indexOf("HTARecord_ClinicalNegatives") >= 0 ||
                  msKey.indexOf("Child_fields") >= 0 ||
                  msKey.indexOf("Parent_fields") >= 0 ||
                 (msKey.indexOf("UserIds") >= 0 && datasourceId == 270) || 
                 (msKey.indexOf("User_Id") >= 0 && datasourceId == 265)

                ) &&
                !hyperlinkList.includes(field.name) &&
                // !lookupIdWithParentValue.includes(field.lookupId) &&
                selectedLookupGlobe[msKey]?.map((row, index) => {
                  return (
                    <span
                      key={row.Id}
                      style={{
                        //color: "#00aae7",
                        width: "100%",
                        display: "block",
                        fontSize: "12px",
                      }}
                      onClick={() => removeMultiSelect(msKey, index, row.Id, field.lookupId)}
                      onMouseEnter={() => {
                        setHoveredIndex(row.Id);
                      }}
                      onMouseLeave={() => {
                        setHoveredIndex(-1);
                      }}
                    >
                      {row.Id === hoveredIndex && (!msKey.includes("TherapeuticArea") &&
                        !msKey.includes("Disease")) && (
                          <span>
                            <CloseIcon
                              style={{
                                color:
                                  row.Id === hoveredIndex ? "#ff0000" : "#00aae7",
                                transition: "color 0.3s ease",
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        )}
                      {row.Value}
                    </span>
                  );
                })}
                 
                {
                  hasKey && ((msKey.indexOf("UserIds") >=0 && datasourceId ==270) || (msKey.indexOf("User_Id") >= 0 && datasourceId ==265)) && 
                  selectedLookupGlobe[msKey].map((row) => (
                    <span>
                      {row.Value}
                    </span>
                  ))
                }
               
                {
                  hasKey && (msKey.indexOf("Child_fields") >= 0 ||
                  msKey.indexOf("Parent_fields") >= 0 )  && selectedLookupGlobe[msKey]?.map((row, index) => {
                    return (
                      <a key={row.Id} style={{
                        color: "#00aae7",
                        width: "100%",
                        display: "block",
                        fontSize: "12px",
                      }} href={`/admin/form/264/${row.Id}`
                  } rel = "noopener" target = "_blank" >
                  { row.Value }
                      </a>                      
                    );
                  })
                }
            </div>
            {hasKey && datasourceId == 1 && ["HTARecord_CoaInstrument"].includes(field.name) && (
              <div className="custom-scroll">
                <CoaInstrumentSelectedItemView
                  coa_instruments={selectedLookupGlobe[field.name]}
                  updateCoaInstrument={updateCoaInstrument}
                />
              </div>
            )}
            {hasKeySpecial &&
              field.lookupId != LookupIds.SourceOfComparator &&
              selectedLookupGlobeSpecial[msKey]?.map((row, index) => {
                return (
                  <div className="custom-scroll">
                   <span
                      key={row.Id}
                      style={{
                        //color: "#00aae7",
                        width: "100%",
                        display: "block",
                        fontSize: "12px",
                      }}
                      onClick={() => removeMultiSelectSpecial(msKey, index, field.lookupId)}
                      onMouseEnter={() => {
                        setHoveredIndexSpecial(row.Id);
                      }}
                      onMouseLeave={() => {
                        setHoveredIndexSpecial("");
                      }}
                    >
                      {row.Id === hoveredIndexSpecial && (
                        <span>
                          <CloseIcon
                            onClick={() => console.log("testing....")}
                            style={{
                              color:
                                row.Id === hoveredIndexSpecial
                                  ? "#ff0000"
                                  : "#00aae7",
                              transition: "color 0.3s ease",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      )}
                      {row.Value}
                    </span>
                  </div>
                );
              })}
            {/* {hasKey && ["HTARecord_CoaInstrument"].includes(field.name) && (
              <>
                <ComplexLookupSplit
                  data={selectedLookupGlobe[msKey]}
                  lookupId={field.lookupId}
                />
              </>
            )} */}
            {hasKeySpecial &&
              field.lookupId == LookupIds.SourceOfComparator && (
                <div className="custom-scroll">
                  <ComplexLookupSplit
                    data={selectedLookupGlobeSpecial[msKey]}
                    lookupId={field.lookupId}
                  />
                </div>
              )}
              {hasKey &&
                  [
                    "HTARecord_ClinicalPositives",
                    "HTARecord_ClinicalNegatives",
                  ].includes(field.name) && (
                    <div className="custom-scroll">
                      <ComplexLookupSplit 
                        data={selectedLookupGlobe[msKey]}
                        lookupId={field.lookupId} 
                      />
                    </div>
                  )}
          </>
        );
      case FormFieldType.Textarea:
        return (
          <textarea
            id={String(field.fieldTypeId)}
            required={field.required || mandatoryFields.includes(field.name)}
            name={field.name}
            value={getValue()}
            onChange={(e) => {
              onFieldChange(field.name, e.target.value, field.id);
            }
            }
            placeholder={
              field.name == "RegulatoryWatch_ApprovedIndications.Indication" &&
                hideTitleLabel
                ? "Latest Indication"
                : field.label
            }
            className="form-control"
            style={{
              width:
                field.name == "RegulatoryWatch_ApprovedIndications.Indication"
                  ? "255px"
                  : "inherit",
              height: field.id == 356 && field.sectionId == 48 ? "35px" : "70px",
            }}
          ></textarea>
        );
      case FormFieldType.Checkbox:
        return (
          <input
            type="checkbox"
            id={field.name}
            checked={getValue()}
            required = {Number(urlparams.dataSourceID) == 277 && field.required ? true : false}
            value={getValue()}
            onChange={(e) => {
              onFieldChange(field.name, e.target.checked, field.id);
            }}
            style={{ marginTop: "2px", width: "16px", height: "16px", cursor: "pointer" }}
          ></input>
        );
      case FormFieldType.DatePicker:
        return (
          <>
            <DatePicker
              id="date"
              required={field.required || mandatoryFields.includes(field.name)}
              value={
                getValue() == "" ? null : dayjs(getValue())
              }
              //style={{height:"31px",borderRadius:"1px", width: field.name=="RegulatoryWatch_ApprovedIndications.ApprovalDate" ? "240%" : "42%"}}
              format={customFormat}
          
              allowClear
              type="date"
              onChange={(e: any) => {
                console.log("eValue", e);
                // selectStartDate(field.name, e, field.id);
                let val;
                if (e) {
                  val = createDate(e)
                }
                else {
                  val = e
                }
                onFieldChange(field.name, val);
              }
              }
              // allowClear
              className={
                columnTypeFieldBrandLicence.includes(field.name) ||
                  columnTypeFieldLove.includes(field.name)
                  ? "columnTypeFieldBrandLicence"
                  : ""
              }
              // slotProps={{
              //   actionBar: {
              //     actions: ["clear"],
              //   },
              // }}
              style={
                columnTypeFieldBrandLicence.includes(field.name)
                  ? { width: "100%", minHeight: 38, height: 38 } :
                  columnTypeFieldLove.includes(field.name)
                  ? { width: "70%", minHeight: 38, height: 38 }
                  : RegulatoryWatchDateFields.includes(field.name)
                    ? { width: "115px",height:"33px",borderRadius:"1px" }
                    : ReimbursementStatus_ProductStrengthDateFields.includes(field.name)
                      ? { width: "130px",height:"31px",borderRadius:"1px"}
                      : { width: "29%",height:"31px",borderRadius:"1px"}
              }
              placeholder={field.label}
            />
            {
              subscriptionDateKeys.includes(field.name) &&
              <>
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  -
                </span>
                <DatePicker
                  id="date"
                  required={field.required || mandatoryFields.includes(subscriptionDateEndName[field.name])}
                  value={
                    getValue(subscriptionDateEndName[field.name]) == "" ? null : dayjs(getValue(subscriptionDateEndName[field.name]))
                  }

                  // value={
                  //   getValue() == "" ? null : dayjs(getValue())
                  // }
                  format={customFormat}
                  inputReadOnly
                  allowClear
                  type="date"
                  style={{ width: "29%",height:"31px",borderRadius:"1px" }}
                  // onChange={(e) =>
                  //   onFieldChange(subscriptionDateEndName[field.name], e.target.value, field.id)
                  // }
                  onChange={(e: any) => {
                    console.log("eValue", e);
                    // selectStartDate(field.name, e, field.id);
                    let val;
                    if (e) {
                      val = createDate(e)
                    }
                    else {
                      val = e
                    }
                    onFieldChange(subscriptionDateEndName[field.name], val);
                  }
                  }
                  
                  className={
                    columnTypeFieldBrandLicence.includes(field.name) ||
                      columnTypeFieldLove.includes(field.name)
                      ? "columnTypeFieldBrandLicence"
                      : ""
                  }
                  sx={
                    columnTypeFieldBrandLicence.includes(field.name)
                      ? { width: "100%", minHeight: 38, height: 38 }
                      : RegulatoryWatchDateFields.includes(field.name)
                        ? { width: "140px" }
                        : ReimbursementStatus_ProductStrengthDateFields.includes(field.name)
                          ? { width: 150 }
                          : { width: 220 }
                  }
                  placeholder={field.label}
                />
              </>

            }

          </>
        );
      case FormFieldType.StaticLabel:
        if (
          field.name == "ICDCode" &&
          datasourceId == DataSourceListTypes.ClinicalData
        )
          return values["ICDCode"]
            ?.split(",")
            .map((el: string, index: number) => (
              <>
                <label key={index}>{el}</label>
                <br />
              </>
            ));
        return <label>{values[field.name]}</label>;
      case FormFieldType.TextareaRichtext:
        if (field.name == "ClinicalEvidence") {
          // console.log("checking...", field.name)
        }

        return (
          <>
            <TMEditor
              name={field.name}
              value={getValue() == null ? "" : getValue()}
              parentObject={parentObject}
              fieldChanged={fieldChanged}
              updateObj={setObj}
              subPageIndex={index}
            />
            {(mandatoryFields.includes(field.name) || field.required) && <div style={{ height: "0px" }}>
              <input
                required={true}
                style={{
                  border: "none",
                  width: "0px",
                  height: "0px",
                  outline: "none",
                }}
                onChange={() => { }}
                value={
                  (parentObject && parentObject[field.name] && parentObject[field.name].length > 0) || (values[field.name] && values[field.name].length > 0) ? "test" : ""
                }
              />
            </div>}
          </>
        );
      case FormFieldType.LookupDisplay:
        if (hyperlinkList.includes(field.name)) {
          return (
            <>
              {
                <a
                  style={{
                    color: "rgb(0, 170, 231)",
                    border: "none",
                    background: "none",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  href={`/view/${datasourceMap[field.name]}/${values[field.name]
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getLookupLabel(field.name)}
                </a>
              }
            </>
          );
        } else {
          return (
            <label>
              {lookupDisplay[values[field.name]]
                ? lookupDisplay[values[field.name]]
                : typeof(values[field.name]) == 'object' ? getLookupLabel(field.name) : ""}
            </label>
          );
        }
      case FormFieldType.WebLinks:
        return (
          <div className="weblink-add-div">
            <AddIcon className="weblink-add"/>&nbsp;
            <a
              href="#"
              key={field.name}
              className="weblink-link"
              onClick={AddWebLink}
            >
              Add weblink
            </a>
          </div>
        );
      // <button onClick={AddWebLink} type="button"> + Add weblink</button>
      case FormFieldType.Decimal: 
        return (
          <input
            style={{ maxWidth: "270px", display: "inline" }}
            type="text"
            maxLength={21}
            required={field.required || mandatoryFields.includes(field.name)}
            id={field.name}
            value={getValue()}
            onChange={(e) => {
              let { value } = e.target;
              if (value)
                value = validateNumber(value);
              // var ex = /^[0-9]+\.?[0-9]*$/;
              //   if(ex.test(value)==false){
              //     value = value.substring(0,value.length - 1);
              //     }
              onFieldChange(field.name, value, field.id)
            }}
            className="form-control"
            placeholder={field.label}
          />
        );
      case FormFieldType.Integer:
        return (
          <input
            type="text"
            maxLength={9}
            required={field.required || mandatoryFields.includes(field.name)}
            id={field.name}
            value={getValue()}
            onChange={(e) => {
              let { value } = e.target;
              if (value) {
                value = validateNumber(value);
                // value = value.replace(/\D/g, "");
              }
              onFieldChange(field.name, value, field.id)
            }}
            className="form-control"
            placeholder={field.label}
            style={{ width: field.name == "PatientsIntervention" ? "30%" : "" }}
          />
        );
      case FormFieldType.NumberRange:
        return (
          <>
            <input
              style={{ maxWidth: "150px", display: "inline" }}
              type="text"
              maxLength={21}
              id={field.name}
              value={getValue()}
              onChange={(e) => {
                let { value } = e.target;
                if (value) {
                  value = validateNumber(value);
                  // value = value.replace(/\D/g, "");
                }
                onFieldChange(field.name, value)
              }}
              className="form-control"
              placeholder={"min"}
            />
            {field.name == "ClinicalData_Endpoints.ConfidenceInterval" && (
              <>
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  -
                </span>
                <input
                  style={{ maxWidth: "150px", display: "inline" }}
                  type="text"
                  maxLength={21}
                  id={"ClinicalData_Endpoints.ConfidenceInterval_Max"}
                  value={validateNumber(getValueForMax("ClinicalData_Endpoints.ConfidenceInterval_Max"), 'read')}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (value) {
                      value = validateNumber(value);
                      // value = value.replace(/\D/g, "");
                    }
                    onFieldChange("ClinicalData_Endpoints.ConfidenceInterval_Max", value)
                  }}
                  className="form-control"
                  placeholder={"max"}
                />
              </>
            )}
            {field.name == "RegulatoryWatch_DosageAndMode.CycleNumber" && (
              <>
                <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  -
                </span>
                <input
                  style={{ maxWidth: "150px", display: "inline" }}
                  type="text"
                  maxLength={9}
                  id={"RegulatoryWatch_DosageAndMode.CycleNumber_Max"}
                  value={getValueForMax("RegulatoryWatch_DosageAndMode.CycleNumber_Max")}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (value) {
                      value = validateNumber(value);
                      // value = value.replace(/\D/g, "");
                    }
                    onFieldChange("RegulatoryWatch_DosageAndMode.CycleNumber_Max", value)
                  }}
                  className="form-control"
                  placeholder={"max"}
                />
              </>
            )}
            {
              [1].includes(datasourceId) && (
                <>
                  <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                    -
                  </span>
                  <input
                    style={{ maxWidth: "150px", display: "inline" }}
                    type="text"
                    maxLength={21}
                    id={field.name + "_Max"}
                    value={validateNumber(values[field.name + "_Max"], 'read')}
                    onChange={(e) => {
                      let { value } = e.target;
                      if (value) {
                        value = validateNumber(value);
                        // value = value.replace(/\D/g, "");
                      }
                      onFieldChange(field.name + "_Max", value)
                    }}
                    className="form-control"
                    placeholder={"max"}
                  />
                </>
              )
            }
            {
              field.name == "ReimbursementRate" && (
                <>
                  <span style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                    -
                  </span>
                  <input
                    style={{ maxWidth: "150px", display: "inline" }}
                    type="text"
                    maxLength={21}
                    id={"ReimbursementRate_Max"}
                    value={validateNumber(values["ReimbursementRate_Max"], 'read')}
                    onChange={(e) => {
                      let { value } = e.target;
                      if (value) {
                        value = validateNumber(value);
                        // value = value.replace(/\D/g, "");
                      }
                      onFieldChange("ReimbursementRate_Max", value)
                    }}
                    className="form-control"
                    placeholder={"max"}
                  />
                </>
              )
            }
          </>
        );
      case FormFieldType.DecimalUptoTwoDigit:
        return (
          <>
            <input
              style={{ maxWidth: "150px", display: "inline" }}
              type="text"
              maxLength={21}
              id={field.name}
              value={getValue()}
              onChange={(e) => {
                let { value } = e.target;
                if (value) {
                  // if (typeof(value) == 'string' && isNaN(value)) {
                  //   onFieldChange(field.name, null);
                  //   return;
                  // }
                  // var v = parseFloat(value);
                  // value = (isNaN(v)) ? '' : v.toFixed(2)
                  // // var ex = /^[0-9]+\.?[0-9]*$/;
                  // // if(ex.test(value) == true) {
                  // //   value = parseFloat(value);
                  // //   value = (isNaN(value)) ? null : value.toFixed(2);
                  // // }
                  value = validateNumber(value);
                }
                onFieldChange(field.name, value)
              }}
              className="form-control"
              placeholder={"min"}
            />
          </>
        );
      // case FormFieldType.NumberRange:
      //   if (field.name.endsWith("_Max"))
      //     return false;
      //   return (
      //     <>
      //       <input
      //         type="text"
      //         maxLength={9}
      //         id={field.name}
      //         value={getValue()}
      //         onChange={(e) =>
      //           onFieldChange(field.name, e.target.value, field.id)
      //         }
      //         className="form-control"
      //         placeholder={field.label}
      //       />
      //       &nbsp;-&nbsp;
      //       <input
      //         type="text"
      //         maxLength={9}
      //         id={field.name + "_Max"}
      //         value={getValue()}
      //         onChange={(e) =>
      //           onFieldChange(field.name + "_Max", e.target.value, field.id)
      //         }
      //         className="form-control"
      //         placeholder={field.label}
      //       />
      //     </>
      //   );
      case FormFieldType.Number:
          return (<>
            <input
              style={{ width:"26%" }}
              type="number"
              required={field.required || mandatoryFields.includes(field.name)}
              id={field.name}
              value={getValue()}
              onChange={(e) =>
                onFieldChange(field.name, e.target.value, field.id)
              }
              defaultValue={0}
              className="form-control"
              
            />
          </>)
      case FormFieldType.DecimalMonthRange:    
        return (
          <>
            <input
              style={{ maxWidth: "150px", display: "inline" }}
              type="text"
              maxLength={2}
              id={field.name}
              value={getValue()}
              onChange={(e) => {
                let { value } = e.target;
                if (value) {
                  let ex = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
                  if(ex.test(value) == false) {
                    onFieldChange(field.name, null);
                    return;
                  } 
                  if (Number(value) > 60) {
                    onFieldChange(field.name, null);
                    return;
                  }
                  const v = parseFloat(value);
                  value = (isNaN(v)) ? '' : v.toString();
                }
                onFieldChange(field.name, value, field.id)
              }}
              className="form-control"
              placeholder={field.label}
            />
          </>
        );
      default:
        if (field.name == "Abbreviation") {
          return (
            <input
              type="text"
              required={field.required || mandatoryFields.includes(field.name)}
              readOnly={values["CountryList_ID_Text"] ? true : false}
              style={{ caretColor: "transparent !important" }}
              id={field.name}
              value={getValue()}
              onChange={(e) =>
                onFieldChange(field.name, e.target.value, field.id)
              }
              className="form-control"
              placeholder={field.label}
            />
          );
        } else
          return (
            <>

              <input
                style={{ width: ReimbursementStatus_ProductStrengthFields.includes(field.name) ? "100px" : field.id == 4024 ? "31%" : "", height: field.id == 4022 ? "70px" : "" }}
                type="text"
                required={field.required || mandatoryFields.includes(field.name)}
                id={field.name}
                value={getValue()}
                onChange={(e) =>
                  onFieldChange(field.name, e.target.value, field.id)
                }
                disabled={field.name == "Identity_Manager_ID" && formMode == "write" ? true : false}
                className="form-control"
                placeholder={field.label}
              />
            </>
          );
    }
  };

  const removeMultiSelect = (msKey: string, index: number, id: number, lId?: number) => {
    let key = '';
    if (["HTARecord_PrimaryDisease_ID",
      "HTARecord_PrimaryDiseaseSubtype",
      "HTARecord_PrimaryDiseaseSubtype2",
      "HTARecord_PrimaryDiseaseSubtype3",
      "HTARecord_PrimaryDiseaseSubtype4"].includes(field.name)) {
      key = "PrimaryAutocompelete_ID||49";
    }
    const disease = selectedLookupGlobe[key]?.filter((el, i) => el.Id != id) || [];
    const sl = selectedLookupGlobe[msKey].filter((el, i) => i != index);
    let slg = { ...selectedLookupGlobe, [msKey]: sl };
    
    // if(disease.length > 0)
    if (lId === 434) {
      let selectedLGSpecial = { ...selectedLookupGlobeSpecial };
      let depField = GetDependentFieldName(msKey);
      if (depField != "" && depField != undefined) {
        let newlookupG = { ...lookupGlobe };
        let newData: ILookupData[] = sl.map(
          (row: any) => ({
            ID: row["Id"],
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: row["Value"],
            isChecked: false,
            val: "",
          })
        );
        newlookupG = {
          ...newlookupG,
          [String(depField)]: newData,
        };
        dispatch(setLookupGlobe(newlookupG));
        selectedLGSpecial = {
          ...selectedLGSpecial,
          [String(depField)]: [],
        }
        if (msKey == "HTARecord_DrugComparator") {
          selectedLGSpecial = {
            ...selectedLGSpecial,
            ["HTARecord_Sourceofcomparator"]: [],
          }
        }
        dispatch(setSelectedLookupGlobeSpecial(selectedLGSpecial));
        // setSelectedDataSpecial([]);
      }
    }
    if(msKey == "Users" && datasourceId === 252){
      if(confirm("Remove "+ selectedLookupGlobe[msKey][index]["Value"] + "?")){
        let removedValue = selectedLookupGlobe[msKey].filter((el, i) => i == index);
        let prevValue = selectedLookupGlobe["UserClientRole"] || [];
        prevValue = [...prevValue,...removedValue]
        slg["UserClientRole"] = prevValue;
      }
      else
        return false;
    }
    slg = { ...slg, [key]: disease };
    dispatch(setSelectedLookupGlobe(slg));
  };

  const removeMultiSelectSpecial = (msKey: string, index: number, lId?: number) => {
    const sl = selectedLookupGlobeSpecial[msKey].filter((el, i) => i != index);
    let slg = { ...selectedLookupGlobeSpecial, [msKey]: sl };
    if (lId === 427) {
      let depField = GetDependentFieldName(msKey);
      if (depField != "" && depField != undefined)
        slg = { ...slg, [String(depField)]: [] };
    }
    dispatch(setSelectedLookupGlobeSpecial(slg));
  };

  const getLookupDisplayData = async (value: number, source: string) => {
    return await apiCall(`/api/lookup/displaySourceField/${value}/${source}`);
  };

  const getDependentFieldValues = async (
    lookupId: number,
    typeAhead: string
  ) => {
    return await apiCall(
      `/api/GetDependentMultiSelect/${lookupId}/${typeAhead}/${datasourceId}`
    );
  };

  const loadManufactureGenericForDrug = async (drugId: number) => {
    apiCall(`/api/GetManufactureGenericForDrug/${drugId}`).then((resp) => {
      let globalBrand = resp.data.filter((x) => x["LID"] == 0);
      let genericData: IselectedLookup[] = resp.data
        .filter((x) => x["LValue"].indexOf("--AS") > 0)
        .map((row: any) => ({
          Id: row["LID"],
          Value: String(row["LValue"].split("--")[0]),
        }));
      let manufactureData: IselectedLookup[] = resp.data
        .filter((x) => x["LValue"].indexOf("--M") > 0)
        .map((row: any) => ({
          Id: row["LID"],
          Value: String(row["LValue"].split("--")[0]),
        }));
      manufactureData.push({
        Id: globalBrand[0]["GlobalCorporation_ID"],
        Value: String(globalBrand[0]["GlobalCorporationName"]),
      });
      let uniqueManufactureData = manufactureData.filter((obj, index) => {
        return index === manufactureData.findIndex((o) => obj.Id === o.Id);
      });
      let newSelectedlookupGlobe = { ...selectedLookupGlobe };
      newSelectedlookupGlobe = {
        ...newSelectedlookupGlobe,
        ["Agreement_Manufacturer"]: uniqueManufactureData,
      };
      newSelectedlookupGlobe = {
        ...newSelectedlookupGlobe,
        ["Agreement_ActiveSubstance"]: genericData,
      };
      dispatch(setSelectedLookupGlobe(newSelectedlookupGlobe));
    });
  };

  const displayDrugBrandName = (row: any) => {
    return (
      <span
        style={{
          width: "100%",
          display: "block",
          fontSize: "12px",
          paddingLeft: "10px",
          //color: "#00aae7",
        }}
      >
        {subscribedDs && subscribedDs.includes('2') &&
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Clinical",
              datasourceDbName,
              selectedLookupGlobe[
              datasourceDbName == "HTARecord" ?
                datasourceDbName + "_PrimaryDisease_ID" :
                datasourceDbName + "_Disease_ID"
              ]
            )
          }
        >
          Clinical
        </Link>
        }
        {subscribedDs && subscribedDs.includes('3') &&
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Regulatory",
              datasourceDbName,
              selectedLookupGlobe[
              datasourceDbName == "HTARecord" ?
                datasourceDbName + "_PrimaryDisease_ID" :
                datasourceDbName + "_Disease_ID"
              ]
            )
          }
        >
          Regulatory
        </Link>
        }
        {subscribedDs && subscribedDs.includes('1') &&
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "HTA",
              datasourceDbName,
              selectedLookupGlobe[
              datasourceDbName == "HTARecord" ?
                datasourceDbName + "_PrimaryDisease_ID" :
                datasourceDbName + "_Disease_ID"
              ]
            )
          }
        >
          HTA
        </Link>
        }
        {subscribedDs && subscribedDs.includes('4') &&
          <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Pricing",
              datasourceDbName,
              ""
            )
          }
        >
          Pricing
        </Link>
        }
      </span>
    );
  };

  const navigate = useHistory();

  const drugSearch = async (
    event: any,
    item: any,
    searchName: string,
    sourceName?: string,
    disease?: any,
  ) => {
    event.preventDefault();
    let newSelectedlookupGlobe = { ...selectedLookupGlobe };
    if (searchName === "HTA") {
      let obj = {
        DrugIDs: [item],
      };
      if (sourceName === "RegulatoryWatch") {
        let countries = await apiCall(`/api/getCountryByRegulator/${values.Regulator_ID}`);
        if (countries.data && countries.data.length > 0) {
          let obj1 = {
            DrugIDs: [item],
            CountryIDs: countries.data.map((arr: any) => {
              return arr;
            })
          };
          dispatch(setSelectedDataState(item));
          dispatch(setSelectedLookupGlobe(obj1));
        }
      } else if (sourceName === "ClinicalData") {
        let obj2 = {
          DrugIDs: [item],
          SuperSearchDiseaseIDs: disease,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj2));
      } else if (sourceName === "HTARecord") {
        let obj3 = {
          DrugIDs: [item],
          DrugPrimaryDiseaseIds: disease,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj3));
      } else {
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
      }
      navigate.push("/Search/ByDrug");
    } else if (searchName === "Pricing") {
      if (sourceName === "HTARecord") {
        let country = await apiCall(`/api/getCountry/${values.Country_ID}`);
        if (country.data && country.data.length > 0) {
          let obj = {
            DrugIDs: [item],
            CountryIDs: [{ Id: values.Country_ID, Value: country.data[0].Name }],
          };
          dispatch(setSelectedDataState(item));
          dispatch(setSelectedLookupGlobe(obj));
        }
      } else if (sourceName === "ClinicalData") {
        let obj1 = {
          DrugIDs: [item]
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj1));
      } else if (sourceName === "RegulatoryWatch") {
        let countries = await apiCall(`/api/getCountryByRegulator/${values.Regulator_ID}`);
        if (countries.data && countries.data.length > 0) {
          let obj2 = {
            DrugIDs: [item],
            CountryIDs: countries.data.map((arr: any) => {
              return arr;
            })
          };
          dispatch(setSelectedDataState(item));
          dispatch(setSelectedLookupGlobe(obj2));
        }
      }
      navigate.push("/Search/ByDrugPricing");
    } else if (searchName === "Clinical") {
      if (sourceName === "HTARecord" || sourceName === "RegulatoryWatch" || sourceName === "ClinicalData") {
        let obj = {
          DrugIDs: [item],
          SuperSearchDiseaseIDs: disease,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
      } else if (sourceName === "Pricing") {
        let obj1 = {
          DrugIDs: [item],
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj1));
      }
      navigate.push("/Search/ByDrugClinical");
    } else if (searchName === "Regulatory") {
      if (sourceName === "HTARecord") {
        let regulator = await apiCall(`/api/getRegulator/${values.Country_ID}`);
        if (regulator.data && regulator.data.length > 0) {
          newSelectedlookupGlobe = {
            DrugIDs: [item],
            SuperSearchDiseaseIDs: disease,
            RegulatorIDs: regulator.data,
          };
        } else {
          newSelectedlookupGlobe = {
            DrugIDs: [item],
            SuperSearchDiseaseIDs: disease,
          };
        }
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(newSelectedlookupGlobe));
      } else if (sourceName === "ClinicalData" || sourceName === "RegulatoryWatch") {
        let obj1 = {
          DrugIDs: [item],
          SuperSearchDiseaseIDs: disease,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj1));
      }
      navigate.push("/Search/ByDrugRegulatory");
    }
  };

  const loadDependentDiseaseValues = (fieldName: string, lookupId: number) => {
    let dsName = fieldName.split("_")[0];
    let currLevel =
      fieldName.indexOf("DiseaseSubtype4") != -1
        ? "DS4"
        : fieldName.indexOf("DiseaseSubtype3") != -1
          ? "DS3"
          : fieldName.indexOf("DiseaseSubtype2") != -1
            ? "DS2"
            : fieldName.indexOf("Disease_ID") != -1
              ? "D"
              : "DS1";
    if (
      dsName.toLowerCase() == "htarecord" &&
      (fieldName.indexOf("Primary") > 0 ||
        fieldName.toLowerCase() == 'htarecord_finalreimbursed' ||
        fieldName.toLowerCase() == 'htarecord_prelimreimbursed')
    ) {
      dsName = dsName + "_Primary";
    } else if (
      dsName.toLowerCase() == "htarecord" &&
      fieldName.indexOf("Secondary") > 0
    ) {
      dsName = dsName + "_Secondary";
    } else {
      dsName = dsName + "_";
    }
    let parentData = selectedLookupGlobe[fieldName];
    let parentLevel = "TA";
    let isSkipLevel = true;

    switch (currLevel) {
      case "DS4":
        if (selectedLookupGlobe[dsName + "DiseaseSubtype3"] != undefined && 
            selectedLookupGlobe[dsName + "DiseaseSubtype3"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype3"];
          parentLevel = "DS3";
          isSkipLevel = false;
        } else if (selectedLookupGlobe[dsName + "DiseaseSubtype2"] != undefined && 
            selectedLookupGlobe[dsName + "DiseaseSubtype2"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype2"];
          parentLevel = "DS2";
        } else if (selectedLookupGlobe[dsName + "DiseaseSubtype"] != undefined && 
            selectedLookupGlobe[dsName + "DiseaseSubtype"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype"];
          parentLevel = "DS1";
        } else if (selectedLookupGlobe[dsName + "Disease_ID"] != undefined && 
            selectedLookupGlobe[dsName + "Disease_ID"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "Disease_ID"];
          parentLevel = "D";
        } else if (selectedLookupGlobe[dsName + "TherapeuticArea"] != undefined && 
            selectedLookupGlobe[dsName + "TherapeuticArea"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "TherapeuticArea"];
          parentLevel = "T";
        }
        break;
      case "DS3":
        if (selectedLookupGlobe[dsName + "DiseaseSubtype2"] != undefined &&
            selectedLookupGlobe[dsName + "DiseaseSubtype2"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype2"];
          parentLevel = "DS2";
          isSkipLevel = false;
        } else if (selectedLookupGlobe[dsName + "DiseaseSubtype"] != undefined &&
            selectedLookupGlobe[dsName + "DiseaseSubtype"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype"];
          parentLevel = "DS1";
        } else if (selectedLookupGlobe[dsName + "Disease_ID"] != undefined &&
            selectedLookupGlobe[dsName + "Disease_ID"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "Disease_ID"];
          parentLevel = "D";
        } else if (selectedLookupGlobe[dsName + "TherapeuticArea"] != undefined &&
            selectedLookupGlobe[dsName + "TherapeuticArea"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "TherapeuticArea"];
          parentLevel = "T";
        }
        break;
      case "DS2":
        if (selectedLookupGlobe[dsName + "DiseaseSubtype"] != undefined &&
            selectedLookupGlobe[dsName + "DiseaseSubtype"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "DiseaseSubtype"];
          parentLevel = "DS1";
          isSkipLevel = false;
        } else if (selectedLookupGlobe[dsName + "Disease_ID"] != undefined &&
            selectedLookupGlobe[dsName + "Disease_ID"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "Disease_ID"];
          parentLevel = "D";
        } else if (selectedLookupGlobe[dsName + "TherapeuticArea"] != undefined &&
            selectedLookupGlobe[dsName + "TherapeuticArea"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "TherapeuticArea"];
          parentLevel = "T";
        }
        break;
      case "DS1":
        if (selectedLookupGlobe[dsName + "Disease_ID"] != undefined &&
            selectedLookupGlobe[dsName + "Disease_ID"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "Disease_ID"];
          parentLevel = "D";
          isSkipLevel = false;
        } else if (selectedLookupGlobe[dsName + "TherapeuticArea"] != undefined &&
            selectedLookupGlobe[dsName + "TherapeuticArea"].length > 0) {
          parentData = selectedLookupGlobe[dsName + "TherapeuticArea"];
          parentLevel = "T";
        }
        break;
      default:
        parentData = selectedLookupGlobe[dsName + "TherapeuticArea"];
    }

    if (parentData != null && parentData.length > 0) {
      const diseaseIds = parentData.map((i) => {
        return i.Id;
      });
      let diseaseIdsString = diseaseIds.join(",");
      apiCall(
        `/api/GetDiseasesBasedOnParent/${diseaseIdsString}/${parentLevel}/${currLevel}/${isSkipLevel}`
      ).then((resp) => {
        if (resp.data.length <= 0) {
          dispatch(setNoRecordFound(true));
          let emptylookupGlobe = { ...lookupGlobe, [fieldName]: [] };
          dispatch(setLookupGlobe(emptylookupGlobe));
        }
        else {
          let lookup_item: ILookupData[] = resp.data.map((row: any) => ({
            ID: row["ID"],
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: row["Name"],
            isChecked: false,
          }));
          let newlookupGlobe = { ...lookupGlobe, [fieldName]: lookup_item };
          dispatch(setLookupGlobe(newlookupGlobe));
        }
      });
    } else {
      fetchRecordBasedOnLookup(lookupId, fieldName);
    }
  };

  const loadDependentMultiSelect = async (currField: Field) => {
    if (currField.name.toLowerCase().indexOf("combination") == -1) {
      let nulllookupGlobe1 = { ...lookupGlobe, [currField.name]: [] };
      dispatch(setLookupGlobe(nulllookupGlobe1));
    }
    let parentValue: any;
    let parentId = currField.parentFieldId;
    let parentField = !!allFields && allFields.find((x) => x.id == parentId);
    if (currField.name.toLowerCase().indexOf("combination") >= 0 && 
      !!parentField && lookupGlobe[parentField.name] != undefined) 
      return;
    if (currField.name.toLowerCase().indexOf("combination") >= 0 &&
      !!parentField &&
      lookupGlobe[parentField.name] == undefined) {
        let parentData = selectedLookupGlobe[parentField.name];
        let lookup_item: ILookupData[] = parentData.map((row: any) => ({
          ID: row["Id"],
          Lookup_Item_Parent_ID: 0,
          Parent_ID: 0,
          SortOrder: 0,
          Value: row["Value"],
          isChecked: false,
          val: row["val"],
        }));
        let newlookupGlobe = { ...lookupGlobe, [currField.name]: lookup_item };
        dispatch(setLookupGlobe(newlookupGlobe));
        return;
      }
    let isSpecialType = false;
    if (
      (!!parentField && parentField.fieldTypeId == FormFieldType.MultiSelect) ||
      (!!parentField && parentField.fieldTypeId == FormFieldType.DrugSelect)
    ) {
      parentValue = selectedLookupGlobe[parentField.name];
      if (selectedLookupGlobe[parentField.name] != undefined && selectedLookupGlobe[parentField.name].length > 0) {
        parentValue =
          Array.prototype.map
            .call(parentValue, function (item) {
              return item.Id;
            })
            .join(",") +
          "--" +
          parentField.name +
          "~";
        if (currField.lookupId == LookupIds.ApprovedRegulatoryInfo) {
          let htaDiseaseValues =
            Array.prototype.map
              .call(
                selectedLookupGlobe["HTARecord_PrimaryDisease_ID"],
                function (item) {
                  return item.Id;
                }
              )
              .join(",") + "--HTARecord_PrimaryDisease_ID~";
          let countryValue = values["Country_ID"] + "--Country_ID";
          parentValue += htaDiseaseValues + countryValue;
        } else if (currField.lookupId == LookupIds.SourceOfComparator) {
          isSpecialType = true;
          if (
            selectedLookupGlobe["HTARecord_DrugComparator_Combination"] !=
            undefined
          ) {
            let htaComparatorComb =
              Array.prototype.map
                .call(
                  selectedLookupGlobeSpecial[
                  "HTARecord_DrugComparator_Combination"
                  ],
                  function (item) {
                    return item.Id;
                  }
                )
                .join(",") + "--HTARecord_DrugComparator_Combination";
            parentValue += htaComparatorComb;
          } else {
            parentValue = parentValue.slice(0, -1);
          }
        } else {
          parentValue = parentValue.slice(0, -1);
        }
      }
    } else if (
      !!parentField &&
      (parentField.fieldTypeId == FormFieldType.Dropdown)
    ) {
      parentValue = values[parentField.name];
    } else if (
      !!parentField &&
      (parentField.fieldTypeId == FormFieldType.DropdownRelatedData)
    ) {
      parentValue = values[parentField.name] + "--" + parentField.name;
    }
    //////Fields under below conditions have no parent id defined in the db.
    else if (
      values["Drug_ID"] != null && values["Country_ID"] != null &&
      (currField.lookupId == LookupIds.DS_AgreementHTARecord ||
        currField.lookupId == LookupIds.DS_AgreementReimbursementStatus ||
        currField.lookupId == LookupIds.DS_AgreementRelatedAgreements)
    ) {
      parentValue = values["Drug_ID"] + "--Drug_ID~";
      let countryValue = values["Country_ID"] + "--Country_ID";
      parentValue += countryValue;
    } else if (currField.lookupId == LookupIds.DS_Country_HTABody) {
      parentValue = "HTA";
    } else if (
      currField.lookupId == LookupIds.DS_ClinicalTrialsIncluded &&
      datasourceId == DataSourceListTypes.ClinicalData
    ) {
      parentValue = "0";
    }
    if (currField.name === "RegulatoryWatch_ClinicalTrials") {
      parentValue = selectedLookupGlobe["RegulatoryWatch_Drug"] != null && selectedLookupGlobe["RegulatoryWatch_Drug"].length > 0 ? selectedLookupGlobe["RegulatoryWatch_Drug"]?.[0]["Id"] : 0 || 0;
      getDependentFieldValues(currField.lookupId, parentValue).then((resp) => {
        if (resp.data.length == 0) {
          dispatch(setNoRecordFound(true));
        }
        else {
          let lookup_item: ILookupData[] = resp.data.map((row: any) => ({
            ID: row["ID"],
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: row["Name"],
            isChecked: false,
            val: row["val"],
          }));
          let newlookupGlobe = { ...lookupGlobe, [currField.name]: lookup_item };
          dispatch(setLookupGlobe(newlookupGlobe));
        }
      });
    }
    else if (datasourceId == 265 && currField.name == "User_Id"){
      fetchRecordBasedOnLookup(currField.lookupId,currField.name);
    }
    else if (parentValue != undefined) {
      getDependentFieldValues(currField.lookupId, parentValue).then((resp) => {
        if (resp.data.length == 0) {
          dispatch(setNoRecordFound(true));
          let nulllookupGlobe = { ...lookupGlobe, [currField.name]: [] };
          dispatch(setLookupGlobe(nulllookupGlobe));
        }
        else {
          let lookup_item: ILookupData[] = resp.data.map((row: any) => ({
            ID: row["ID"],
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: row["Name"],
            isChecked: false,
            val: row["val"],
          }));
          let newlookupGlobe = { ...lookupGlobe, [currField.name]: lookup_item };
          dispatch(setLookupGlobe(newlookupGlobe));
        }
      });
    }
    else {
      dispatch(setNoRecordFound(true));
    }
  };

  const fetchRecordBasedOnLookup = (lookupId: number, lookupKey: string) => {
    let newLookupGlobe;
    const CountryID = values?.Country_ID || 0;
    const Drug_ID = values?.Drug_ID || 0;

    if (lookupKey == "ReimbursementStatus_HTARecord") {
      if (CountryID && Drug_ID)
        newLookupGlobe = fetchComplexLookup(
          lookupId,
          datasourceId,
          CountryID,
          Drug_ID
        );
      else {
        ToastrAlert("Please select the drug & country first", "warning");
        dispatch(setNoRecordFound(true));
        return false;
      }
    } else if (lookupKey == "Client_Group") {
      const clientID = values.Client_ID
      newLookupGlobe = fetchComplexLookup(
        lookupId,
        datasourceId,
        clientID,
        0
      );
    }

    else if (lookupKey == "ReimbursementStatus_RegulatoryWatch") {
      if (Drug_ID)
        newLookupGlobe = fetchComplexLookup(
          lookupId,
          datasourceId,
          CountryID,
          Drug_ID
        );
      else {
        //alert("Please choose drug and country first");
        dispatch(setNoRecordFound(true));
        return false;
      }
    } else if (lookupKey == "Related_Reimbursementrecords") {
      const ReimbursementStatus_Disease: IselectedLookup[] =
        selectedLookupGlobe["ReimbursementStatus_Disease"] || [];
      if (CountryID && Drug_ID && ReimbursementStatus_Disease.length > 0) {
        const Disease_ID: number[] = ReimbursementStatus_Disease.map(
          (el) => el.Id
        );
        const ids = Disease_ID.join(",");
        newLookupGlobe = fetchComplexLookup(
          lookupId,
          datasourceId,
          CountryID,
          Drug_ID,
          ids
        );
      } else {
        ToastrAlert(
          "Please choose drug, country first and disease hierarchy",
          "warning"
        );
        dispatch(setNoRecordFound(true));
        return false;
      }
    } else if (lookupKey == "ReimbursementStatus_Disease") {
      const ReimbursementStatusID = values.ReimbursementStatusID || 0;
      if (ReimbursementStatusID) {
        if (CountryID && Drug_ID) {
          newLookupGlobe = fetchRecord(lookupId, datasourceId);
        } else {
          ToastrAlert("Please select the drug & country first", "warning");
          dispatch(setNoRecordFound(true));
          return false;
        }
      } else {
        ToastrAlert("Please select the reimbursement status first", "warning");
        dispatch(setNoRecordFound(true));
        return false;
      }

      // newLookupGlobe =
    } else if (
      lookupKey.includes("RegulatoryWatch_ApprovedIndication_Combination_Item")
    ) {
      let combIndex = lookupKey.split('_')[4];
      if (
        (selectedLookupGlobe.RegulatoryWatch_Drug &&
        selectedLookupGlobe?.RegulatoryWatch_Drug?.length > 0) ||
        (selectedLookupGlobe['RegulatoryWatch_ApprovedIndication_Combination_'+ combIndex] &&
        selectedLookupGlobe['RegulatoryWatch_ApprovedIndication_Combination_'+ combIndex].length > 0)
      ) {
        let result: ILookupData[] = [
          {
            ID: selectedLookupGlobe.RegulatoryWatch_Drug[0].Id,
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: selectedLookupGlobe?.RegulatoryWatch_Drug[0].Value,
            isChecked: false,
            val: "",
          },
        ];

        selectedLookupGlobe['RegulatoryWatch_ApprovedIndication_Combination_'+ combIndex].forEach(
          (el) => {
            result.push({
              ID: el.Id,
              Lookup_Item_Parent_ID: 0,
              Parent_ID: 0,
              SortOrder: 0,
              Value: el.Value,
              isChecked: false,
              val: "",
            });
          }
        );
        let newlookupGlobe = { ...lookupGlobe, [lookupKey]: result };
        dispatch(setLookupGlobe(newlookupGlobe));
        return true;
      } else {
        dispatch(setNoRecordFound(true));
        return false;
      }
    }
    else if (lookupKey.includes("DrugData_Lov_BrandName")) {
      if (loeBrandName) {
        let newlookupGlobe = { ...lookupGlobe, [lookupKey]: loeBrandName };
        dispatch(setLookupGlobe(newlookupGlobe));
        return true;
      } else {
        dispatch(setNoRecordFound(true));
        return false;
      }
    }
    //ds - 265
    else if(lookupKey == "ClientGroup_Id"){
      const clientids = selectedLookupGlobe.client_Id?.map((el) => el.Id) || [];
      newLookupGlobe = fetchComplexLookup(lookupId, datasourceId, 0, 0, "", clientids);
    }
    else if(lookupKey == "User_Id"){
      const newclientids = selectedLookupGlobe?.client_Id?.map((el) => el.Id) || [];
      const ClientGroupIds = selectedLookupGlobe.ClientGroup_Id?.map((el) => el.Id) || [];
      if (newclientids.length > 0)
        newLookupGlobe = fetchComplexLookup(lookupId, datasourceId, 0, 0, "", newclientids, ClientGroupIds);
      else {
        ToastrAlert("The Client Name field is required.", "error");
        dispatch(setNoRecordFound(true));
        return false;
      }
    }
    else if (lookupKey == "ClientGroupIds") {
      const clientids = selectedLookupGlobe.ClientIds?.map((el) => el.Id) || [];
      if (clientids.length > 0)
        newLookupGlobe = fetchComplexLookup(lookupId, datasourceId, 0, 0, "", clientids);
      else {
        ToastrAlert("The Client Name field is required.", "error");
        dispatch(setNoRecordFound(true));
        return false;
      }

    }
    else if (lookupKey == "UserIds") {
      const newclientids = selectedLookupGlobe?.ClientIds?.map((el) => el.Id) || [];
      const ClientGroupIds = selectedLookupGlobe.ClientGroupIds?.map((el) => el.Id) || [];
      if (newclientids.length > 0)
        newLookupGlobe = fetchComplexLookup(lookupId, datasourceId, 0, 0, "", newclientids, ClientGroupIds);
      else {
        ToastrAlert("The Client Name field is required.", "error");
        dispatch(setNoRecordFound(true));
        return false;
      }
    }
    else if (lookupKey == "Users") {
      //passing clientid as country id

      newLookupGlobe = fetchComplexLookup(
        lookupId,
        datasourceId,
        urlparams.clientID,
        0,

      );
    }
    else newLookupGlobe = fetchRecord(lookupId, datasourceId);

    newLookupGlobe.then((result: any) => {
      if (result.length == 0) dispatch(setNoRecordFound(true));
      let newlookupGlobe = { ...lookupGlobe, [lookupKey]: result };
      dispatch(setLookupGlobe(newlookupGlobe));
    });
  };

  async function openModal(ID: number, Name: string, title: string) {
    if((Name == "ClientGroup_Id" || Name == "User_Id") && datasourceId == 265 ) {
      let dataSource = selectedLookupGlobe.dataSource_Id;
      let dataSourceIds = dataSource?.map((el) => el.Id) || [];
      if(dataSourceIds.length == 0) {
        ToastrAlert("The Datasource field is required.", "error");
        return;
      }      
      let Clients = selectedLookupGlobe.client_Id;
      let ClientIds = Clients?.map((el) => el.Id) || [];
      if(ClientIds.length == 0) {
        ToastrAlert("The Client Name field is required", "error");
        return;
      }
    }
    if((Name == "ClientGroupIds" || Name == "UserIds") && (datasourceId == 270 || datasourceId == 276) ) {
      let Clients = selectedLookupGlobe.ClientIds;
      let ClientIds = Clients?.map((el) => el.Id) || [];
      if(ClientIds.length == 0) {
        ToastrAlert("The Client Name field is required", "error");
        return;
      }
    }
    if (ID === 427) dispatch(setLookupData(drugNameList));
    const lookupKey = Name;
    let currentField = !!allFields && allFields.find((x) => x.name == lookupKey);
    let parentField = !!allFields && allFields.find((x) => x.id == currentField.parentFieldId);
    let parentData = selectedLookupGlobe[parentField?.name];
    let parentDataSingleField = values[parentField?.name];
    if (((lookupKey.toLowerCase().indexOf("disease") >= 0 && datasourceId != 274) && !lookupKey.includes("ApprovedIndication") && !lookupKey.includes('ReimbursementStatus_Disease')) ||
      lookupKey.toLowerCase() == 'htarecord_finalreimbursed' ||
      lookupKey.toLowerCase() == 'htarecord_prelimreimbursed') {
      let emptylookupGlobe = { ...lookupGlobe, [lookupKey]: [] };
      dispatch(setLookupGlobe(emptylookupGlobe));
      loadDependentDiseaseValues(lookupKey, ID);
    } else if (
      !!currentField &&
      ((currentField.parentFieldId != null && (parentData != undefined && parentData.length > 0)) ||
        (currentField.parentFieldId != null && (parentDataSingleField != undefined && parentDataSingleField.toString().length > 0)) ||
        (ComplexDataForLookups.includes(currentField.lookupId)) ||
        (currentField.lookupId == LookupIds.DS_ClinicalTrialsIncluded && datasourceId == DataSourceListTypes.ClinicalData) ||
        (exceptionFields.includes(currentField.name)) //This is for loading all values for the current field even if the parent field value is not selected.
      )) {
        // let emptylookupGlobe1 = { ...lookupGlobe, [lookupKey]: [] };
        // dispatch(setLookupGlobe(emptylookupGlobe1));
      loadDependentMultiSelect(currentField);
    } else {
      if (
        lookupKey.includes(
          "RegulatoryWatch_ApprovedIndication_Combination_Item"
        ) || lookupKey.includes("DrugData_Lov_BrandName")
      ) {
        fetchRecordBasedOnLookup(ID, lookupKey);
      } else if (!lookupGlobe.hasOwnProperty(lookupKey)
        || (datasourceId == 270 && lookupKey == "UserIds")
        // || (datasourceId == 265 && lookupKey == "User_Id")
        ) {
        fetchRecordBasedOnLookup(ID, lookupKey);
      } else if (lookupGlobe[lookupKey]?.length == 0)
        dispatch(setNoRecordFound(true));
    }
    if (lookupKey.includes("RegulatoryWatch_ApprovedIndication_Combination")) {
      title = "Combination with";
    }

    if (lookupKey.indexOf("RegulatoryWatch_ApprovedIndication_Disease") >= 0) {
      //169, true, 'SuperSearchDiseaseIDs', 'Disease Hierarchy'
      //RegulatoryWatch_ApprovedIndication_Disease
      dispatch(showMultiSelectPopup(ID, true, lookupKey, "Disease Hierarchy", true));
    } else {
      dispatch(showMultiSelectPopup(ID, true, lookupKey, title, true));
    }
  }

  const containerClass = (value: any) => {
    let className = "";
    if (
      columnTypeFieldBrandLicence.includes(value.name) ||
      value.name === "DrugData_Lov.DrugData_Lov_BrandName" ||
      value.name === "DrugData_Lov.DrugData_Lov_Geography"
    ) {
      className += "col-sm-2 ";
    } else if (
      value.name === "DrugData_Lov.LovDate" ||
      value.name === "DrugData_Lov.DrugData_Lov_Regulator"
    ) {
      className += "col-sm-3 ";
    } else {
      className += "col-sm-12 row ";
    }
    className += "col-sm-12 row ";
    className +=
      "form-group field-typeId-" +
      value.fieldTypeId.toString() +
      " " +
      "field-name-" +
      value.name +
      " field-id-" +
      value.id.toString() +
      " field-lookupId-" +
      value.lookupId +
      " field-section-id-" +
      value.sectionId.toString();

    return className;
  };

  const buttonLabel = (text: string) => {
    switch (text) {
      case "DrugData_Lov.DrugData_Lov_BrandName":
        return "Brand name";
      case "DrugData_Lov.DrugData_Lov_Regulator":
        return "Regulator";
      case "DrugData_BrandLicense.DrugData_BrandLicense_Geography":
        return "Geography";
      case "DrugData_Lov.DrugData_Lov_Geography":
        return "LOE geography";
      case "RegulatoryWatch_ApprovedIndication_Combination":
        return "Combination with";
      case "RegulatoryWatch_ApprovedIndication_Combination_Item":
        return "Combinations";
      case "RegulatoryWatch_ApprovedIndication_Patient":
        return "Population";
      case "RegulatoryWatch_ApprovedIndication_TreatmentLines":
        return "Treatment line";
      case "RegulatoryWatch_ApprovedIndication_Modalities":
        return "Treatment modality";
      case "RegulatoryWatch_ApprovedIndication_Disease":
        return "Disease";
      default:
        return "Select";
    }
  };

  const loeBrandName = useAppSelector((state) => state.multiselect.loeBrandName);
  const createDataNewModal = (item: any) => {
    let initialState =
      loeBrandName === undefined
        ? []
        : [...loeBrandName];
    if (datasourceId == DataSourceListTypes.DrugData) {
      let currBrand: number = 0;
      if (field.name.indexOf('GlobalBrand_ID') >= 0 ) {
        currBrand = values['GlobalBrand_ID'];
      }
      else if (field.name.indexOf('RegionalDrug_ID') >= 0 ) {
        currBrand = values['DrugData_BrandLicense'][index]['DrugData_BrandLicense.RegionalDrug_ID_Text']?.Id;
      }
      initialState = initialState.filter(function( obj ) {
        return obj.ID !== Number(currBrand);
      });
      let tmp = initialState;
      for (let x of rawData) {
        if (x.ID === Number(item)) {
          tmp.push(x);
        }
      }
      dispatch(setLoeBrandName(tmp));
    }
  };

  const resetFieldDisease = (field: Field) => {
    let key = 'PrimaryAutocompelete_ID||' + field.sectionId;
    let dsName = field.name.split("_")[0];
    let subType4FieldName = dsName + "_DiseaseSubtype4";
    let subType3FieldName = dsName + "_DiseaseSubtype3";
    let subType2FieldName = dsName + "_DiseaseSubtype2";
    let subType1FieldName = dsName + "_DiseaseSubtype";
    let diseaseFieldName = dsName + "_Disease_ID";
    let taFieldName = dsName + "_TherapeuticArea";
    if (
      dsName.toLowerCase() == "htarecord" &&
      field.name.indexOf("Primary") > 0
    ) {
      subType4FieldName = dsName + "_PrimaryDiseaseSubtype4";
      subType3FieldName = dsName + "_PrimaryDiseaseSubtype3";
      subType2FieldName = dsName + "_PrimaryDiseaseSubtype2";
      subType1FieldName = dsName + "_PrimaryDiseaseSubtype";
      diseaseFieldName = dsName + "_PrimaryDisease_ID";
      taFieldName = dsName + "_PrimaryTherapeuticArea";
      key = "PrimaryAutocompelete_ID||49";
    } else if (
      dsName.toLowerCase() == "htarecord" &&
      field.name.indexOf("Secondary") > 0
    ) {
      subType4FieldName = dsName + "_SecondaryDiseaseSubtype4";
      subType3FieldName = dsName + "_SecondaryDiseaseSubtype3";
      subType2FieldName = dsName + "_SecondaryDiseaseSubtype2";
      subType1FieldName = dsName + "_SecondaryDiseaseSubtype";
      diseaseFieldName = dsName + "_SecondaryDisease_ID";
      taFieldName = dsName + "_SecondaryTherapeuticArea";
      key = "SecondaryAutocompelete_ID||49";
    }
    let resetSelectedLookupG = { ...selectedLookupGlobe };
    if (field.name.toLowerCase().indexOf("therapeuticarea") > 0) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: [] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [diseaseFieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType1FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType2FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType3FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType4FieldName]: [],
      };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } else if (field.name.toLowerCase().indexOf("disease_id") > 0) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: resetSelectedLookupG[taFieldName] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType1FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType2FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType3FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType4FieldName]: [],
      };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } else if (field.name.toLowerCase().endsWith("diseasesubtype")) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: resetSelectedLookupG[diseaseFieldName] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType2FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType3FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType4FieldName]: [],
      };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } else if (field.name.toLowerCase().indexOf("diseasesubtype2") > 0) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: resetSelectedLookupG[subType1FieldName] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType3FieldName]: [],
      };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType4FieldName]: [],
      };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } else if (field.name.toLowerCase().indexOf("diseasesubtype3") > 0) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: resetSelectedLookupG[subType2FieldName] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      resetSelectedLookupG = {
        ...resetSelectedLookupG,
        [subType4FieldName]: [],
      };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } else if (field.name.toLowerCase().indexOf("diseasesubtype4") > 0) {
      resetSelectedLookupG = { ...resetSelectedLookupG, [key]: resetSelectedLookupG[subType3FieldName] };
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
      // dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
    } 
    if (dsName.toLowerCase() == "reimbursementstatus") {
      resetSelectedLookupG = { ...resetSelectedLookupG, [field.name]: [] };
    }
    // let names: string[] = [taFieldName, diseaseFieldName, subType1FieldName, subType2FieldName, subType3FieldName, subType4FieldName];
    // const lastIndex = names.indexOf(field.name);
    // names = names.slice(0, lastIndex);
    // resetSelectedLookupG[field.name] = [];
    // let savedDisease = resetSelectedLookupG?.[key] || [];
    // let allDisease: number[] = [];
    // if (savedDisease.length > 0) {
    //   names.forEach((el) => {
    //     const _diseaseTypes: IselectedLookup[] = resetSelectedLookupG[el];
    //     _diseaseTypes.forEach((item) => {
    //       allDisease.push(item.Id);
    //     });
    //   });
    //   const _savedDisease = savedDisease.filter((x) => allDisease.includes(x.Id));
    //   resetSelectedLookupG = { ...resetSelectedLookupG, [key]: _savedDisease };
    // }
    dispatch(setSelectedLookupGlobe(resetSelectedLookupG));
  };

  const copyFieldData = (currFName: string, srcFName: string) => {
    let parentData = selectedLookupGlobe[srcFName];
    if (parentData != undefined || parentData.length > 0) {
      let parentSelectedLookupG = { ...selectedLookupGlobe };
      parentSelectedLookupG = {
        ...parentSelectedLookupG,
        [currFName]: parentData,
      };
      dispatch(setSelectedLookupGlobe(parentSelectedLookupG));
    }
  };

  const onWebLinkChange = (wi: number, value: string) => {
    let webLinkData = [...values.WebLink];
    webLinkData[wi] = { WebLinkURL: value };
    // ["WebLinkURL"] = value
    fieldChanged(
      "WebLink",
      "",
      webLinkData
    );
  };

  const updateCoaInstrument = (id: number) => {
    let selectedData = selectedLookupGlobe[field.name];
    const newData = selectedData.filter((el) => el.Id != id);
    dispatch(setSelectedLookupGlobe({ ...selectedLookupGlobe, [field.name]: newData }));
  }

  const resetDiseaseForAdmin = (fieldName : string,diseaseValue : any) => {
    let _values = { ...values };
    if("DisplayName_ID" !== fieldName) {      
      const indexToStart = diseaseKeys.indexOf(fieldName);
      if(diseaseValue)
        _values[diseaseKeys[indexToStart]] = diseaseValue;
      else 
        delete _values[fieldName];
      const keys = diseaseKeys.slice(indexToStart+1);
      keys.forEach((key) => {
        delete _values[key];
      });      
    }else { 
      delete _values[fieldName];
    }
    userContext.setValues(_values);
  }

  return (
    <>
      {field?.name == "ReimbursementStatus_WebLink" && formMode != "read" && (
        <>
          {values?.WebLink?.map((el: any, el_i: number) => (
            <>
            <div
              className="col-sm-12 row form-group"
              style={{ marginBottom: "0rem", marginTop: "30px", height: "0px" }}
              key={el_i}
            >
              <label
                className="col-sm-4 text-right control-label "
                style={{ paddingRight: "10px" }}
              >
                {el_i == 0 ? "WebLink" : ""} {el_i + 1}
              </label>
              <div
                className="col-sm-8 row"
                style={{ paddingTop: "10px", paddingLeft: "1px" }}
              >
                <input
                  type="text"
                  required={field.required}
                  readOnly={values["CountryList_ID_Text"] ? true : false}
                  style={{
                    caretColor: "transparent !important",
                    width: "80%",
                    marginLeft: "30px",
                  }}
                  id={field?.name}
                  value={el["WebLinkURL"]}
                  onChange={(e) => onWebLinkChange(el_i, e.target.value)}
                  className="form-control"
                  placeholder="Value"
                />
                <br />
                <label
                  onClick={() => {
                    DeleteWebLink(el_i)
                  }}
                  className="iqvia-color weblink-delete"
                >
                  <RiDeleteBinLine className="cursor-pointer weblink-delete-icon-style"/>
                </label>

                {/* <button onClick={() => DeleteWebLink(el_i)}>delete</button> */}
              </div>
            </div>
            <br />
            </>
          ))}
        </>
      )}
      {field.name == "DateOfAgreementInitiation" &&
        datasourceId == DataSourceListTypes.Agreement && (
          <>
            <div
              className="col-md-8"
              style={{ marginLeft: "16.2em", marginTop: "1em" }}
            >
              <span style={{ fontStyle: "italic" }}>Note</span>: Dates showing
              as blank are not specified for this record
            </div>
          </>
        )}
      <div
        className={containerClass(field)}
        style={{ marginBottom: field.name == "ReimbursementConditionDetail" ? "3rem" : "" }}
        key={field.id}
      >
        {!columnTypeFieldBrandLicence.includes(field.name) &&
          !columnTypeFieldLove.includes(field.name) &&
          !hideTitleLabel && (<>
            <label
              className={
                "col-sm-4 text-right control-label " +
                (!field.readOnly &&
                  (field.required || mandatoryFields.includes(field.name)) &&
                  formMode != "read" && Number(urlparams.dataSourceID) !== 277
                  ? "required"
                  : "") + ((field.name == "RegulatoryWatch_DosageAndMode.CyclesPerMonth" || field.name == "RegulatoryWatch_DosageAndMode.CyclesPerYear") ? "required" : "")
              }
              title={field.description}

              style={{
                paddingRight:
                  field.name == "ControlPatients" ? "2px" : field.name == "InnovativeLicensingAndAccessPathway" ? "-1px" : field.name == "RegulatoryOrphanStatus" || field.name == "MarketingAuthHolder_ID" ? "" : field.name == "Related_Reimbursementrecords" ? "2px" : field.name == "ReimbursementStatusID" ? "" : "4px",
                paddingTop: ([1,2,3,4,13,24,28,32,41,45,55,60].includes(field.fieldTypeId) && !(field.readOnly) && (formMode == "write" || formMode == "new" || formMode == "")) ? "15px" : ([15].includes(field.fieldTypeId) && !(field.readOnly) && (formMode == "write" || formMode == "new")) ? "13px" : ([7,12,24,26].includes(field.fieldTypeId) && formMode == "read") ? "10px" : "6px",
                // height: field.name == "RegulatoryWatch_Drug" || field.name == "ReimbursementStatus_WebLink" || field.name == "ReimbursementAuthority_ID_Text" || field.name == "Drug_ID" ? "68px" : "0px",
                // paddingLeft: field.name == "RegulatoryOrphanStatus" ? "126px" : field.name == "MarketingAuthHolder_ID" ? "100px" : field.name == "ReimbursementStatusID" ? "150px" : "",
              }}
            >
              {field.name == "ReimbursementStatus_WebLink"
                ? values?.WebLink?.length > 0
                  ? ""
                  : "Web link"
                : field.name ==
                  "ReimbursementStatus_ProductFormulation"
                  ? "Formulation"
                  : field.label}
              {[
                "ClinicalData_Arms.OutcomeResultID",
                "ClinicalData_Arms.Name",
              ].includes(field.name) && <span>{index + 1}</span>}
              {field.label && (urlparams.dataSourceID < 250 ) && (
                <FaInfoCircle
                  onClick={() => {
                    const showAlertFunc = async() => {
                      const res = await apiCall(`/api/admin/fetch/description/${field.id}/${values["Status_ID"] || 1}/${values["Agency_ID"] || 0}/${formMode || 'write'}`);
                      userContext.showAlert(
                        <>
                          <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                            {datasourcename}
                            <DoubleArrowIcon
                              style={{ fontSize: "18px", paddingBottom: "2px" }}
                            />
                            {section}
                            <DoubleArrowIcon
                              style={{
                                strokeWidth: "5",
                                fontSize: "18px",
                                paddingBottom: "2px",
                              }}
                            />
                            {field.label}
                          </span>
                        </>,
                        "info",
                        res.data,
                        `/admin/instructions/${field.id}`,
                        true
                      );
                    }
                    showAlertFunc()
                    
                  }}
                  style={{
                    fontSize: "12px",
                    marginLeft: "3px",
                    cursor: "pointer",
                    marginRight: field.name == "InnovativeLicensingAndAccessPathway" || field.name == "RegulatoryOrphanStatus" || field.name == "MarketingAuthHolder_ID" || field.name == "ReimbursementStatusID" ? "-11px" : " ",
                  }}
                />
              )}
            </label>


          </>

          )}
        <div
          className={
            columnTypeFieldBrandLicence.includes(field.name) ||
              columnTypeFieldLove.includes(field.name)
              ? "w-100"
              : approvedIndicationsFields.includes(field.name)
                ? "col-sm-11"
                : "col-sm-8"
          }
        >
          {inputControl(vi)}
        </div>
        {Object.keys(conditionalFormFields).includes(field.name) && (
          <label style={{ paddingLeft: "300px", color: "red" }}>
            <b>{conditionalFormFields[field.name] ? conditionalFormFields[field.name] : "This field is required"}</b>
          </label>
        )}

      </div>

    </>
  );
}

export default FieldView;

interface IDevicePopup {
  showDeviceBrandNameModal: any,
  handleCloseRecDel: any,
  datasourceId: number
}
interface PopupProps {
  showRelatedHTAsModal: any,
  handleCloseRelatedHTAs: any,
  formValues: IForm,
  relatedData: any
}

const DevicePopup = ({
  showDeviceBrandNameModal,
  handleCloseRecDel,
  datasourceId
}: IDevicePopup) => {
  const [values, setValues] = useState<IForm>({});
  const [device, setDevice] = useState<boolean>(false);
  const [name, setname] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const dispatch = useAppDispatch();

  const saveDevice = async () => {
    setLoading(true);
    if (!values.Generic_ID) setDevice(true);
    else setDevice(false);
    if (!values.name) setname(true);
    else setname(false);
    if (values.Generic_ID && values.name) {
      await PostCall("/api/create_device", values);
      let _lookupGlobe = { ...lookupGlobe };
      if (datasourceId == 1)
        delete _lookupGlobe.HTARecord_Device;
      else if (datasourceId == 2) {
        delete _lookupGlobe.ClinicalData_Device;
      }
      dispatch(setLookupGlobe(_lookupGlobe));
      setLoading(false);
      handleCloseRecDel();
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <DraggablePopover
        title="Sub Form"
        anchorEl={showDeviceBrandNameModal}
        popupBody={
          <div>
            <h4 style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px", marginBottom: "10px" }}>Create device</h4>
            <hr />
            <div className="row">
              <label
                className="control-label col-md-4"
                style={{ color: "#333333", textAlign: "right", textDecoration: "none", fontSize: "12px", fontWeight: "400", lineHeight: "17px", width: "290px" }}
              >
                Name
              </label>
              <input
                className="form-control col-md-6"
                style={{ height: "27px", border: "1px sloid #d5d5d5" }}
                onChange={(e) =>
                  setValues({ ...values, ["name"]: e.target.value })
                }
              />
              {name && (
                <span
                  style={{
                    color: "#dc3545",
                    paddingLeft: "320px",
                    fontWeight: "bold",
                  }}
                >
                  A valid Name must be entered
                </span>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <AutoCompleteForDevice
                lookupId={129}
                datasourceId={2}
                fieldName={"Generic_ID"}
                setValues={setValues}
                values={values}
              />

              {device && (
                <span
                  style={{
                    color: "#dc3545",
                    paddingTop: "20px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  A valid Generic item
                </span>
              )}
            </div>
            {device && (
              <span
                style={{
                  color: "#dc3545",
                  paddingLeft: "180px",
                  fontWeight: "bold",
                }}
              >
                must be selected
              </span>
            )}

            <AutoCompleteForDevice
              lookupId={35}
              datasourceId={2}
              fieldName={"Manufacturer_ID"}
              setValues={setValues}
              values={values}
            />
            {loading && (
              <div style={{ paddingLeft: "430px", paddingTop: "100px" }}>
                <CircularProgress color="inherit" size={20} /><span>Loading</span>
              </div>
            )}
          </div>
        }
        buttonsArray={["Save"]}
        btnCallbacks={[saveDevice]}
        onPopupClose={handleCloseRecDel}
      ></DraggablePopover>
    </>
  );
};
  
const RelatedHTAsPopup = ({
  showRelatedHTAsModal,
  handleCloseRelatedHTAs,
  formValues,
  relatedData
}: PopupProps) => {

  return (
    <>
      <Modal size="lg" show={showRelatedHTAsModal} onHide={handleCloseRelatedHTAs} centered>
        <Modal.Header>
           <Modal.Title style={{fontSize:"18px",fontWeight:"bold"}}>HTA related data</Modal.Title>
           <CloseIcon className="Close-icon" onClick={handleCloseRelatedHTAs} />
        </Modal.Header>
       <Modal.Body>
           {relatedData &&
            relatedData.length > 0 ? (
            <>
              <ul className="list-group-fv">
                  {relatedData.map((relatedItem: any, index: number) => (
                    <li className="list-group-item-fv" style={{padding: "12px !important", width: "55rem !important,"}}>
                      <a
                        key={index}
                        style={{
                          color: "rgb(0, 170, 231)",
                          border: "none",
                          background: "none",
                          padding: "0px",
                          fontSize: "12px",
                          display: "block",
                        }}
                        href={`/view/1/${relatedItem.id}`}
                        target="_blank"
                      >
                        {relatedItem.label}
                      </a>
                  </li>
                  ))}
              </ul>
            </>
            ) : <p>No related data found</p>
            }
         </Modal.Body>
         <Modal.Footer style={{color:"#00aae7" }}>
           <span className="spanBtn" onClick={handleCloseRelatedHTAs}>
             Cancel
           </span>
         </Modal.Footer>
       </Modal>
    </>
  );
};

interface IAutoCompleteForDevice {
  lookupId: number,
  datasourceId: number,
  fieldName: string,
  setValues: any,
  values: any
}

const AutoCompleteForDevice = ({
  lookupId,
  datasourceId,
  fieldName,
  setValues,
  values,
}: IAutoCompleteForDevice) => {
  const [items, setItems] = React.useState<lookupItemT[]>([]);
  const { data, error, isLoading } = useFetchLookupItemsQuery({
    lookup_id: lookupId,
    datasource_id: datasourceId,
  });
  const [open, setOpen] = React.useState(false);
  let lookupLoading = open && !!items && items.length === 0;

  useEffect(() => {
    if (data) {
      let lookupItems: lookupItemT[] = [];
      for (let x of data) {
        lookupItems.push({ label: String(x.Value), id: String(x.ID) });
      }
      setItems(lookupItems);
    }
  }, [isLoading]);

  const renderOptions = (props: React.HTMLAttributes<HTMLElement>, option: Partial<any>) => {
    return <li {...props} key={option.id}>{option.label}</li>
  }

  return (
    <div style={{ paddingLeft: "180px", paddingTop: "10px" }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        open={open}
        freeSolo
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={items}
        key={fieldName}
        loading={lookupLoading}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        loadingText="Loading..."
        sx={{ width: 300 }}
        value={items.filter((el) => el.id == values[fieldName])[0]}
        onChange={(event, newValue: any) => {
          setValues({ ...values, [fieldName]: newValue.id });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select an Option"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {lookupLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{ height: 38 }}
          />
        )}
        renderOption={renderOptions}
      />
    </div>
  );
};
