// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-new {    
    min-width: 600px;
}

.my-modal-new {     
      border-radius: 0px;
      max-height: 380.2px;
} 
 
  
  #toast-container {
    margin-top: 1rem;
  }
  
  #toast-container.toast-top-full-width>.toast {
    max-width: 570px;
  }
  
  #toast-container>.toast-info {
    background-color: #51a351;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.2);
  }
  
  #toast-container>.toast-info:hover {
    background-color: #51a351;
    opacity:1;
    box-shadow:0px 0px 10px rgba(0,0,0,0.4);
  }
 
      

  
  `, "",{"version":3,"sources":["webpack://./src/components/Export/exportOption.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;MACM,kBAAkB;MAClB,mBAAmB;AACzB;;;EAGE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,SAAS;IACT,uCAAuC;EACzC;;EAEA;IACE,yBAAyB;IACzB,SAAS;IACT,uCAAuC;EACzC","sourcesContent":[".modal-dialog-new {    \n    min-width: 600px;\n}\n\n.my-modal-new {     \n      border-radius: 0px;\n      max-height: 380.2px;\n} \n \n  \n  #toast-container {\n    margin-top: 1rem;\n  }\n  \n  #toast-container.toast-top-full-width>.toast {\n    max-width: 570px;\n  }\n  \n  #toast-container>.toast-info {\n    background-color: #51a351;\n    opacity:1;\n    box-shadow:0px 0px 10px rgba(0,0,0,0.2);\n  }\n  \n  #toast-container>.toast-info:hover {\n    background-color: #51a351;\n    opacity:1;\n    box-shadow:0px 0px 10px rgba(0,0,0,0.4);\n  }\n \n      \n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
