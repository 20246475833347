// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toast-container > div.parent-toast{
      width: 400px;
}

#toast-container>.toast-info.parent-toast {
      background-color: #2f96b4;
      opacity:1;
      box-shadow:0px 0px 10px rgba(0,0,0,0.2);
    }
    
#toast-container>.toast-info.parent-toast:hover {
      background-color: #2f96b4;
      opacity:1;
      box-shadow:0px 0px 10px rgba(0,0,0,0.4);
}

.autosave-link {
      overflow: hidden !important;
      white-space: nowrap;
      width: 59% !important;
      display: inline-block;
      text-overflow: ellipsis;
}

.ignore-btn {
      margin-top: -2px;
      background-color: orange;
      border: 1px solid white;
      color: white;
      border-radius: 5px;
      float: right;
      padding-left: 9px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;MACM,YAAY;AAClB;;AAEA;MACM,yBAAyB;MACzB,SAAS;MACT,uCAAuC;IACzC;;AAEJ;MACM,yBAAyB;MACzB,SAAS;MACT,uCAAuC;AAC7C;;AAEA;MACM,2BAA2B;MAC3B,mBAAmB;MACnB,qBAAqB;MACrB,qBAAqB;MACrB,uBAAuB;AAC7B;;AAEA;MACM,gBAAgB;MAChB,wBAAwB;MACxB,uBAAuB;MACvB,YAAY;MACZ,kBAAkB;MAClB,YAAY;MACZ,iBAAiB;AACvB","sourcesContent":["#toast-container > div.parent-toast{\n      width: 400px;\n}\n\n#toast-container>.toast-info.parent-toast {\n      background-color: #2f96b4;\n      opacity:1;\n      box-shadow:0px 0px 10px rgba(0,0,0,0.2);\n    }\n    \n#toast-container>.toast-info.parent-toast:hover {\n      background-color: #2f96b4;\n      opacity:1;\n      box-shadow:0px 0px 10px rgba(0,0,0,0.4);\n}\n\n.autosave-link {\n      overflow: hidden !important;\n      white-space: nowrap;\n      width: 59% !important;\n      display: inline-block;\n      text-overflow: ellipsis;\n}\n\n.ignore-btn {\n      margin-top: -2px;\n      background-color: orange;\n      border: 1px solid white;\n      color: white;\n      border-radius: 5px;\n      float: right;\n      padding-left: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
