import React from "react";

export const getIdFieldName = (sourceId: number) => {
  switch (Number(sourceId)) {
    case 1:
      return "HTARecord_ID";
    case 2:
      return "ClinicalData_ID";
    case 3:
      return "RegulatoryWatch_ID";
    case 4:
      return "Pricing_ID";
    case 5:
      return "Policy_ID";
    case 6:
      return "Agency_ID";
    case 7:
      return "Country_ID";
    case 20:
      return "DrugData_ID";
    case 23:
      return "Agreement_ID";
    case 25:
      return "ReimbursementStatus_ID";
    case 27:
      return "TreatmentCost_ID";
    default:
      return "N/A";
  }
};

export const getDatasourceId = (sourceId: string) => {
  switch (sourceId) {
    case "HTA":
      return 1;
    case "ClinicalData":
      return 2;
    case "RegulatoryWatch":
      return 3;
    case "Pricing":
      return 4;
    case "Policy":
      return 5;
    case "Agency":
      return 6;
    case "Country":
      return 7;
    case "Agreement":
      return 23;
    case "ReimbursementStatus":
      return 25;
    default:
      return 1;
  }
};

export const getCommentTableName = (sourceId: number) => {
  switch (Number(sourceId)) {
    case 1:
      return "HTArecord_comments";
    case 2:
      return "ClinicalData_Comments";
    case 3:
      return "RegulatoryWatch_Comments";
    case 4:
      return "Pricing_Comments";
    case 5:
      return "Policy_Comments";
    case 6:
      return "Agency_Comments";
    case 7:
      return "Country_Comments";
    case 20:
      return "DrugData_Comments";
    case 23:
      return "Agreement_Comments";
    case 25:
      return "ReimbursementStatus_Comments";
    default:
      return "N/A";
  }
};
export const getAttachmentInfo = (sourceId: number) => {
  switch (sourceId) {
    case 1:
      return "HTARecord_Attachment";
    case 2:
      return "ClinicalData_Version";
    case 3:
      return "RegulatoryWatch_Version";
    case 4:
      return "Pricing_Version";
    case 5:
      return "Policy_Version";
    case 6:
      return "Agency_Version";
    case 7:
      return "Country_Version";
    case 20:
      return "DrugData_Version";
    case 23:
      return "Agreement_Version";
    case 25:
      return "ReimbursementStatus_Version";
    case 27:
      return "TreatmentCost_Version";
    default:
      return "N/A";
  }
};

export const formatDate = (date: string) => {
  // raw date format: 2021-09-01T00:00:00
  if (!!date) {
    const dateArray = date.split("T")[0].split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const addSpaceBetweenWords = (string: string) => {
  let newString = "";
  for (let i = 0; i < string.length; i++) {
    if (string[i] === string[i].toUpperCase()) {
      newString += " ";
    }
    newString += string[i];
  }
  return capitalizeFirstLetter(newString);
};

export const WrappedHeader = (value: any) => {
  return <div className="header-wrap">{value.displayName}</div>;
};

export type CardObj = {
  positiveRecommendations: number;
  positiveWithRestriction: number;
  negativeRecommendations: number;
  multipleRecommendations: number;
  noRecommendations: number;
  ongoingPlanned: number;
  suspendedCanceled: number;
};

export const defineCardData = (rowData: any) => {
  let positiveRecommendations = 0;
  let positiveWithRestriction = 0;
  let negativeRecommendations = 0;
  let multipleRecommendations = 0;
  let noRecommendations = 0;
  let ongoingPlanned = 0;
  let suspendedCanceled = 0;
  let economicPositivesArray: any[] = [];
  let economicNegativesArray: any[] = [];
  let clinicalPositivesArray: any[] = [];
  let clinialNegativesArray: any[] = [];
  let decisionRationaleArray: any[] = [];
  let comparatorArray: any[] = [];

  rowData.map((row: any) => {
    if (
      row.FinalRecommendation_ID == 206 &&
      (row.HTAStatus_ID == 137 || row.HTAStatus_ID == 138)
    ) {
      positiveRecommendations++;
    } else if (
      row.FinalRecommendation_ID == 207 &&
      (row.HTAStatus_ID == 137 || row.HTAStatus_ID == 138)
    ) {
      positiveWithRestriction++;
    } else if (
      row.FinalRecommendation_ID == 208 &&
      (row.HTAStatus_ID == 137 || row.HTAStatus_ID == 138)
    ) {
      negativeRecommendations++;
    } else if (
      row.FinalRecommendation_ID == 3162 &&
      (row.HTAStatus_ID == 137 || row.HTAStatus_ID == 138)
    ) {
      multipleRecommendations++;
    } else if (
      row.FinalRecommendation_ID == 205 &&
      (row.HTAStatus_ID == 137 || row.HTAStatus_ID == 138)
    ) {
      noRecommendations++;
    } else if (
      row.HTAStatus_ID == 135 ||
      row.HTAStatus_ID == 134 ||
      row.HTAStatus_ID == 136
    ) {
      ongoingPlanned++;
    } else if (row.HTAStatus_ID == 139 || row.HTAStatus_ID == 140) {
      suspendedCanceled++;
    }

    if (row.EconomicPositivesIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.EconomicPositivesNames.split(",").forEach((itemEP: any) => {
        if (itemEP.trim().toLowerCase() != "not specified")
          tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!economicPositivesArray.some((c: any) => c.label == i)) {
            economicPositivesArray.push({ label: i, count: 1 });
          } else {
            economicPositivesArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else {
          if (!economicPositivesArray.some((c: any) => c.label == i)) {
            economicPositivesArray.push({ label: i, count: 0 });
          } else {
            economicPositivesArray.filter((c: any) => c.label == i)[0].count++;
          }
        }
      });
    }

    if (row.ClinicalPositivesIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.ClinicalPositivesNames.split(",").forEach((itemEP: any) => {
        if (itemEP.trim().toLowerCase() != "not specified")
          tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!clinicalPositivesArray.some((c: any) => c.label == i)) {
            clinicalPositivesArray.push({ label: i, count: 1 });
          } else {
            clinicalPositivesArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else {
          if (!clinicalPositivesArray.some((c: any) => c.label == i)) {
            clinicalPositivesArray.push({ label: i, count: 0 });
          } else {
            clinicalPositivesArray.filter((c: any) => c.label == i)[0].count++;
          }
        }
      });
    }

    if (row.ClinicalNegativesIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.ClinicalNegativesNames.split(",").forEach((itemEP: any) => {
        if (itemEP.trim().toLowerCase() != "not specified")
          tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!clinialNegativesArray.some((c: any) => c.label == i)) {
            clinialNegativesArray.push({ label: i, count: 1 });
          } else {
            clinialNegativesArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else {
          if (!clinialNegativesArray.some((c: any) => c.label == i)) {
            clinialNegativesArray.push({ label: i, count: 0 });
          } else {
            clinialNegativesArray.filter((c: any) => c.label == i)[0].count++;
          }
        }
      });
    }

    if (row.EconomicNegativesIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.EconomicNegativesNames.split(",").forEach((itemEP: any) => {
        if (itemEP.trim().toLowerCase() != "not specified")
          tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!economicNegativesArray.some((c: any) => c.label == i)) {
            economicNegativesArray.push({ label: i, count: 1 });
          } else {
            economicNegativesArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else {
          if (!economicNegativesArray.some((c: any) => c.label == i)) {
            economicNegativesArray.push({ label: i, count: 0 });
          } else {
            economicNegativesArray.filter((c: any) => c.label == i)[0].count++;
          }
        }
      });
    }

    if (row.FinalDecisionRationaleIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.FinalDecisionRationaleNames.split(",").forEach((itemEP: any) => {
        if (itemEP.trim().toLowerCase() != "not specified")
          tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!decisionRationaleArray.some((c: any) => c.label == i)) {
            decisionRationaleArray.push({ label: i, count: 1 });
          } else {
            decisionRationaleArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else {
          if (!decisionRationaleArray.some((c: any) => c.label == i)) {
            decisionRationaleArray.push({ label: i, count: 0 });
          } else {
            decisionRationaleArray.filter((c: any) => c.label == i)[0].count++;
          }
        }
      });
    }

    if (row.DrugComparatorIDs != null) {
      let tmp: string[] = [];
      let tmp2: any[] = [];
      row.DrugComparatorNames.split(",").forEach((itemEP: any) => {
        tmp.push(itemEP.trim());
      });
      tmp.forEach(function (i: any) {
        let iteration = 0;
        if (iteration == 0) {
          if (!comparatorArray.some((c: any) => c.label == i)) {
            comparatorArray.push({ label: i, count: 1 });
          } else {
            comparatorArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        } else if (iteration <= 10) {
          if (!comparatorArray.some((c: any) => c.label == i)) {
            comparatorArray.push({ label: i, count: 0 });
          } else {
            comparatorArray.filter((c: any) => c.label == i)[0].count++;
          }
          iteration++;
        }
      });
    }
  });

  return {
    positiveRecommendations,
    positiveWithRestriction,
    negativeRecommendations,
    multipleRecommendations,
    noRecommendations,
    ongoingPlanned,
    suspendedCanceled,
    economicPositivesArray,
    economicNegativesArray,
    clinicalPositivesArray,
    clinialNegativesArray,
    comparatorArray,
    decisionRationaleArray,
  };
};

export const recommendationsColors = (value: string) => {
  switch (value) {
    case "Positive w. restrictions":
      return "orange";
    case "Negative recommendation":
      return "red";
    case "Positive recommendation":
      return "green";
    case "Multiple Recommendations":
      return "deepskyblue";
    default:
      return "darkgray";
  }
};

export const calculateAmnogPercentage = (AMNOG_Rebate: any, MSP: any) => {
  if (MSP !== null && AMNOG_Rebate !== null && MSP > 0) {
    let res = (AMNOG_Rebate / MSP) * 100;
    return parseFloat(res.toFixed(2));
  } else return "-";
};

export const addDate = (date: string) => {
  let tmp = new Date(date);
  tmp.setDate(tmp.getDate() - 1);
  let day = tmp.getDate();
  let oneAdditionalDay = new Date(date).getDate();
  let month = new Date(date).getMonth() + 1;
  let year = new Date(date).getFullYear();
  const lastDayOfMonth = new Date(year, month, 0).getDate();
  const lastDayOfYear = new Date(year, 11, 31).getDate();
  // console.log(day, month, year, 'date', date)
  if (day === lastDayOfMonth) {
    if (month === 12) {
      return `${1}/${1}/${year + 1}`;
    } else {
      return `${1}/${month}/${year}`;
    }
  } else if (day === lastDayOfYear && month === 12) {
    return `${1}/${1}/${year + 1}`;
  } else {
    return `${oneAdditionalDay}/${month}/${year}`;
  }
};

export const byDrugShowColumnsName = () => {
  return [
    "Name",
    "CountryName",
    "AgencyAbbreviation",
    "RecordDrugNames",
    "PrimaryTherapeuticAreaNames",
    "PrimaryDiseaseNames",
    "PrimaryDiseaseSubtypeNames",
    "Decision",
    "StatusName",
    "BestRecommendatioin",
    "Submissiondate",
    "Rapidreviewcommenced",
    "Rapidreviewcompleted",
    "ClinicalDataAcronyms",
    "BestASMR",
    "BestBenefitRatting",
  ];
};

export const brandLicenceFormObj = (obj: any) => {
  let tmpArray: any[] = [];
  for (let x of obj) {
    let newOjb = {
      id: x.ID,
      name: x.Name,
      sectionId: x.Section_ID,
      sortOrder: x.SortOrder,
      label: x.Label,
      description: x.Description,
      descriptionClient: x.DescriptionClient,
      required: x.Required,
      readOnly: x.ReadOnly,
      visible: x.Visible,
      isRemoved: x.is_removed,
      isSummary: x.IsSummary,
      relatedDataType: x.RelatedDataType,
      displaySource: x.DisplaySource,
      displayIdName: x.DisplayIdName,
      displayFieldName: x.DisplayFieldName,
      fieldInfo: x.FieldInfo,
      categoryId: x.Category_ID,
      masterFieldId: x.MasterFieldID,
      externallyvisible: x.Externallyvisible,
      fieldTypeId: x.Field_Type_ID,
      lookupId: x.Lookup_ID,
    };
    tmpArray.push(newOjb);
  }
  return tmpArray;
};

export const columnTypeFieldBrandLicence = [
  "DrugData_BrandLicense.RegionalDrug_ID_Text",
  "DrugData_BrandLicense.Corporation_ID",
  "DrugData_BrandLicense.StartDate",
  "DrugData_BrandLicense.EndDate",
  "DrugData_BrandLicense.DrugData_BrandLicense_Geography",
  "DrugData_BrandLicense.DealType_ID",
];

export const columnTypeFieldLove = [
  "DrugData_Lov.DrugData_Lov_BrandName",
  "DrugData_Lov.LovDate",
  "DrugData_Lov.DrugData_Lov_Regulator",
  "DrugData_Lov.DrugData_Lov_Geography",
];

export const approvedIndicationsFields = [
  "RegulatoryWatch_ApprovedIndication_Combination",
  "RegulatoryWatch_ApprovedIndication_Combination_Item",
  "RegulatoryWatch_ApprovedIndication_Patient",
  "RegulatoryWatch_ApprovedIndication_TreatmentLines",
  "RegulatoryWatch_ApprovedIndication_Modalities",
];