import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { IfilterState } from "../components/Dashboard/dashboard";
import MultiColumnFilters from "../components/Table/MultiColumnFilters";
import TitleLink, { deleteButton, IDLink } from "../components/Table/TitleLink";
import AuditLogButton from "./AuditLogButton";
import CustomHeader from "../components/Table/CustomHeader";

// @ts-ignore
import { convert } from "html-to-text";
import { SplitComponent, SplitWithTilde } from "../components/Table/Utility";
import { rootURL } from "../components/Logout/Login";

function removeExtraBlanks(arr: any[]) {
  let blankFound = false;
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === "[Blank]") {
      if (!blankFound) {
        result.push(arr[i]);
        blankFound = true;
      }
    } else {
      result.push(arr[i]);
    }
  }

  return result;
}

function mixedTypeComparator(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any) {
  const isNumericA = !isNaN(valueA) && valueA !== null;
  const isNumericB = !isNaN(valueB) && valueB !== null;

  if (isNumericA && isNumericB) {
    // Both values are numeric
    return valueA - valueB;
  } else if (!isNumericA && !isNumericB) {
    // Both values are strings
    const stringA = valueA ? valueA.toString().toLowerCase() : '';
    const stringB = valueB ? valueB.toString().toLowerCase() : '';
    return stringA.localeCompare(stringB);
  } else if (isNumericA) {
    // Only A is numeric
    return -1;
  } else {
    // Only B is numeric
    return 1;
  }
}

const getIdFieldName = (sourceId: number) => {
  switch (sourceId) {
    case 1:
      return "HTARecord_ID";
    case 2:
      return "ClinicalData_ID";
    case 3:
      return "RegulatoryWatch_ID";
    case 4:
      return "Pricing_ID";
    case 5:
      return "Policy_ID";
    case 6:
      return "Agency_ID";
    case 7:
      return "Country_ID";
    case 20:
      return "DrugData_ID";
    case 23:
      return "Agreement_ID";
    case 25:
      return "ReimbursementStatus_ID";
    case 27:
      return "TreatmentCost_ID";
    default:
      return "ID";
  }
};

const getSearchDataSourceId = (dsId: number) => {
  switch (dsId) {
    case 1:
      return 11;
    case 2:
      return 9;
    case 3:
      return 14;
    case 4:
      return 13;
    case 5:
      return 12;
    case 6:
      return 8;
    case 7:
      return 10;
    case 20:
      return 22;
    case 23:
      return 24;
    case 25:
      return 26;
    case 27:
      return 28;
    default:
      return 11;
  }
};

export const getDataSourceName = (dsId: number) => {
  switch (dsId) {
    case 1:
      return "HTA";
    case 2:
      return "Clinical";
    case 3:
      return "Regulatory";
    case 4:
      return "Pricing";
    case 5:
      return "Policy";
    case 6:
      return "Agency";
    case 7:
      return "Country";
    case 20:
      return "Drug";
    case 23:
      return "Agreement";
    case 25:
      return "ReimbursementStatus";
    default:
      return "HTA";
  }
};

const getDataSourceDbName = (dsId: number) => {
  switch (dsId) {
    case 1:
      return "HTA";
    case 2:
      return "ClinicalData";
    case 3:
      return "RegulatoryWatch";
    case 4:
      return "Pricing";
    case 5:
      return "Policy";
    case 6:
      return "Agency";
    case 7:
      return "Country";
    case 20:
      return "Drug";
    case 23:
      return "Agreement";
    case 25:
      return "ReimbursementStatus";
    default:
      return "HTA";
  }
};

export interface GridData {
  rowData: any[];
  colDef: ColDef[];
}

export interface SearchResultState {
  searchData: GridData;
  columnFilterData: { [name: string]: any[] };
  searchError: boolean;
  auditData: GridData;
  layoutName: string;
  selectedDatasourceId: number;
  searchDataSourceId: number;
  selectedDatasourceName: string;
  selectedDatasourceDbName: string;
  filterState: IfilterState;
  priorFilterState: IfilterState;
  initiateSearch: boolean;
  searchRecordID:number | null;
  recordIdData:GridData;
  recordIdSearch: boolean;
  recordIdDataLoading: boolean;
  isSearchgGridLoading: boolean;
  editColumnData: { gridData: GridData; sectionsData: any[] };
  defaultColumn: {
    showColumnsNames: string[];
    defaultColumnInfo: any[];
    sortColumn: string;
    sortDirection: string;
  };
  preferenceData: string;
  columnRearranged: boolean;
  showColumnLabels: { [name: string]: string | null };
  currIds : number[];
  isExeedingSearchThreshold: boolean;
  totalResultCount: number;
  searchIdsForExport: number[];
  selectedColumnFilter: { [name: string]: string[] };
  filterColumnTypes: { [name: string]: string };
  totalFilteredCount: number | null;
  isPathwaySearch: boolean;
  pathwayFilterState: IfilterState;
  IsExportDisabled:any;
  columnFilterApplied: boolean;
  activeColumnFilter: string|null;
  selectedValues: any[];
}

const initialState: SearchResultState = {
  searchData: { rowData: [], colDef: [] },
  columnFilterData: {},
  searchError: false,
  auditData: { rowData: [], colDef: [] },
  layoutName: "Default",
  selectedDatasourceId: 0,
  searchDataSourceId: 0,
  selectedDatasourceName: "",
  selectedDatasourceDbName: "",
  filterState: {},
  priorFilterState: {},
  searchRecordID:null,
  recordIdData:{rowData:[],colDef:[]},
  recordIdDataLoading:false,
  recordIdSearch: false,
  initiateSearch: false,
  isSearchgGridLoading: false,
  editColumnData: { gridData: { rowData: [], colDef: [] }, sectionsData: [] },
  defaultColumn: {
    showColumnsNames: [],
    defaultColumnInfo: [],
    sortColumn: "Name",
    sortDirection: "asc",
  },
  preferenceData: "",
  columnRearranged: false,
  showColumnLabels: {},
  currIds : [],
  isExeedingSearchThreshold: false,
  totalResultCount: 0,
  searchIdsForExport:[],
  selectedColumnFilter:{},
  filterColumnTypes:{},
  totalFilteredCount: null,
  isPathwaySearch: false,
  pathwayFilterState: {},
  IsExportDisabled: {},
  columnFilterApplied : false,
  activeColumnFilter: null,
  selectedValues: [],
};

export const SearchSlice = createSlice({
  name: "Search",
  initialState,
  reducers: {
    setActiveColumnFilter: (state:any, action: PayloadAction<string>) => {
      state.activeColumnFilter = action.payload;
    },

    setIsSearchGridLoading: (state:any, action: PayloadAction<boolean>) => {
      state.isSearchgGridLoading = action.payload;
    },

    setColumns: (state:any, action: PayloadAction<string[]>) => {
      // state.showColumns = action.payload;
      state.defaultColumn.showColumnsNames = action.payload;
    },

    setTotalResultCount: (state:any, action: PayloadAction<number>) => {
      state.totalResultCount = action.payload;
    },

    saveSearchData: (state:any, action: PayloadAction<any>) => {
      console.log('save search data..........');
      if (action.payload.hasOwnProperty("error")) {
        state.searchError = true;
        state.searchData = { rowData: [], colDef: [] };
        state.isSearchgGridLoading = false;
      } else {
        state.searchError = false;
        state.columnFilterApplied = false;
        const datasourceID = state.selectedDatasourceId;
        let payloadData = action.payload.data;
        if(action.payload.data && action.payload.data.data){
          payloadData = action.payload.data.data;
        }

        const isExeedingSearchThreshold = !!action.payload.data && !!action.payload.data.isExceedThreshold;
        const totalResultCount = (action.payload.data && action.payload.data.totalCount) || payloadData.length;
        const totalFilteredCount = action.payload.data && action.payload.data.TotalFilteredCount;
        const recordIds = (action.payload.data && action.payload.data.recordIds) || [];

        state.totalFilteredCount = totalFilteredCount || null;
        state.searchIdsForExport = recordIds;
        state.isExeedingSearchThreshold = isExeedingSearchThreshold;
        state.totalResultCount = totalResultCount;
        let rowData : any = payloadData as object[];
        const colDef: ColDef[] = [];
        const rowDataKeys = rowData[0] && Object.keys(rowData[0]);
        rowData = rowData.map((x:any) => {
          let tempRow: any = {};
          for (const k of rowDataKeys) {
            if (typeof(x[k]) == 'string') {
              tempRow[k] = convert(x[k]);
            }
            else {
              tempRow[k] = x[k];
            }
          }
          return tempRow;
        });
        

        const idFieldName = getIdFieldName(state.selectedDatasourceId);
        const displayColmns = state.defaultColumn.showColumnsNames;
        const displayColumnLabel = state.showColumnLabels;
        const dateColumnKey: string[] = [];
        const boolColumnKey: string[] = [];
        let directLinkColumnKey: boolean = rowData.length > 0 && rowData[0].hasOwnProperty("Direct_Link") ? true : false;
        const customComparator = (valueA:string, valueB:string) => {
          return valueA?.toLowerCase()?.localeCompare(valueB?.toLowerCase());
        };
        let alreadyLink: boolean = false;
        let firstCol = '';
        if (rowData != null && rowData.length > 0) {
          if (datasourceID <= 250) {
            const relevanceScorePresent = 
              rowData[0] && rowData[0]["RelevanceScore"] != null 
                ? true
                : false;
            colDef.push({
              headerComponent: CustomHeader,
              headerCheckboxSelection: false,
              checkboxSelection: true,
              suppressMenu: true,
              width: 50,
              lockPosition: "left",
            });
            for (const key of displayColmns) {
              if (rowData[0].hasOwnProperty(key)) {
                const columnHeaderName =
                  displayColumnLabel && displayColumnLabel[key];
                  const colInfo = state.defaultColumn.defaultColumnInfo.find(
                  (x:any) => x.Name == key
                );
                const colType =
                  colInfo && colInfo.hasOwnProperty("FieldRenderMethod")
                    ? colInfo["FieldRenderMethod"]
                    : null;
                const colRelevance = 
                  colInfo && colInfo["Name"] == 'RelevanceScore'
                    ? true
                    : false;
                const colRelevanceSum = 
                  colInfo && colInfo["Name"] == 'RelevanceScoreSum'
                    ? true
                    : false;
                if (!alreadyLink && ((datasourceID == 6 && key == "Abbreviation") || (datasourceID != 6 && (key == "Title" || key == "Name" || key == "Value")))) {
                  alreadyLink = true;
                  firstCol = key;
                  colDef.push({
                    field: key,
                    headerName: columnHeaderName || key,
                    cellRenderer: TitleLink,
                    cellRendererParams: {
                      link: `/view/${datasourceID}`,
                      recordIdField: idFieldName,
                    },
                    sort: !relevanceScorePresent ? "asc" : null,
                    minWidth: 350,
                    wrapText: true,
                    autoHeight: true,
                    lockPosition: !relevanceScorePresent ? true : undefined,
                    comparator: mixedTypeComparator,
                  });
                } else if (colRelevance) {
                  firstCol = key;
                  colDef.push({
                    field: key,
                    lockPosition: "left",
                    cellStyle: {fontSize: '12px'},
                  });
                } else if (colRelevanceSum) {
                  colDef.push({
                    field: key,
                    sort: "desc",
                    hide: true,
                  });
                } else if (colType && colType === "QHTASearch_mRenderDate") {
                  dateColumnKey.push(key);
                  colDef.push({
                    field: key,
                    headerName: columnHeaderName || key,
                    headerTooltip: columnHeaderName || key,
                    tooltipField: key,
                    valueFormatter: (param: ValueFormatterParams) => {
                      const date=new Date(param.value)
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const year = date.getFullYear();
                      const formattedDate = `${day}-${month}-${year}`;
                      const demo=formattedDate;
                      return param.value==null?null:demo
                    },
                    filter: MultiColumnFilters,
                    filterParams: {
                      columnType: colType,
                      datasourcId: datasourceID,
                      closeFilterPopup: null, // Placeholder for the closeFilterPopup function
                    },
                    cellStyle: {fontSize: '12px'},
                    minWidth:
                      columnHeaderName &&
                      columnHeaderName.split("").length >= 15
                        ? 220
                        : 150,
                  });
                } else if (colType && colType === "QHTASearch_mRenderBoolean") {
                  boolColumnKey.push(key);
                  colDef.push({
                    field: key,
                    headerName: columnHeaderName || key,
                    headerTooltip: columnHeaderName || key,
                    tooltipField: key,
                    filter: MultiColumnFilters,
                    filterParams: {
                      columnType: colType,
                      datasourcId: datasourceID,
                      closeFilterPopup: null, // Placeholder for the closeFilterPopup function
                    },
                    cellStyle: {fontSize: '12px'},
                    minWidth:
                      columnHeaderName &&
                      columnHeaderName.split("").length >= 15
                        ? 220
                        : 150,
                  });
                } else {
                  let minWidth = 150;
                  if (
                    columnHeaderName &&
                    columnHeaderName.split("").length >= 15 &&
                    columnHeaderName.split("").length <= 25
                  ) {
                    minWidth = 220;
                  } else if (
                    columnHeaderName &&
                    columnHeaderName.split("").length > 26
                  ) {
                    minWidth = 300;
                  }
                  colDef.push({
                    field: key,
                    //valueFormatter: removeHtmlTags,
                    headerName: columnHeaderName || key,
                    filter: MultiColumnFilters,
                    filterParams: {
                      columnType: colType,
                      datasourcId: datasourceID,
                      closeFilterPopup: null,
                    },
                    headerTooltip: columnHeaderName || key,
                    tooltipField: key,
                    minWidth,
                    comparator: mixedTypeComparator,
                    cellStyle: {fontSize: '12px'}
                  });
                }
              }
            }


            if (dateColumnKey.length > 0) {
              let updatedArray = [...rowData];
              dateColumnKey.forEach((key: string) => {
                
                updatedArray = updatedArray.map((row) => {
                  let _row :any = { ...row };
                  _row[key] = _row[key] == null ? null : _row[key].slice(0, 10);
                  return _row;
                });

                
              });
              rowData = [...updatedArray];
              
            }
            if (boolColumnKey.length > 0) {
              let updatedArray2 = [...rowData];
              boolColumnKey.forEach((key: string) => {
                updatedArray2 = updatedArray2.map((row) => {
                  let _row :any = { ...row };
                  _row[key] = _row[key] == null ? null : _row[key] == true ? 'Yes' : 'No';
                  return _row;
                });
              });
              rowData = [...updatedArray2];
            }
            if (directLinkColumnKey) {
              let updatedArray1 = [...rowData];
              updatedArray1 = updatedArray1.map((row) => {
                let _row :any = { ...row };
                _row["Direct_Link"] = `${rootURL}/view/${datasourceID}/${_row["ID"]}`;
                return _row;
              });
              rowData = [...updatedArray1];
            }
          } else {
            for (var key in rowData[0]) {
              if (
                rowData[0].hasOwnProperty(key) &&
                displayColmns.includes(key)
              ) {
                if( (key =="Name" &&  [253,255,256,258,259,260,266].includes(datasourceID)) || (key == "Code" && datasourceID == 261) ){
                  colDef.push({
                    field: key,
                    cellRenderer: TitleLink,
                    cellRendererParams: {
                      link: `/admin/form/${datasourceID}`,
                      recordIdField: idFieldName,
                      // filter: 'agTextColumnFilter'
                    },
                    sort: "asc",
                    comparator: customComparator
                  });
                }
                else if ((key == "Title" || key == "Name" || key == "Value") && datasourceID!= 261 && datasourceID != 263) {
                  colDef.push({
                    field: key,
                    cellRenderer: TitleLink,
                    cellRendererParams: {
                      link: `/admin/form/${datasourceID}`,
                      recordIdField: idFieldName,
                      // filter: 'agTextColumnFilter'
                    },                    
                    // sort: "asc",
                  });
                }
                else if( key == "Report Name" && datasourceID == 270){
                  let _coldef  :any = {
                    field: key,
                    cellRenderer: TitleLink,
                    cellRendererParams: {
                      link: rowData[0]["Report_Link"] ? `reports/home` : `/admin/form/${datasourceID}`,
                      recordIdField: idFieldName,
                      // filter: 'agTextColumnFilter'
                    },
                    sort: "asc",
                    comparator: customComparator,
                  }
                  
                  if(Object.keys(rowData[0]).length == 4)
                    _coldef = {..._coldef,width : 20}
                  colDef.push(_coldef);
                }
               
                
                else if(key == "Instrument (abbreviation)"){
                  colDef.push({
                    field: key,
                    cellRenderer: TitleLink,
                    cellRendererParams: {
                      link: `/admin/form/${datasourceID}`,
                      recordIdField: idFieldName,
                      // filter: 'agTextColumnFilter'
                    },
                    sort: "asc",
                    comparator: customComparator
                  });
                }
                
                else if (["Datasource"].includes(key) && datasourceID == 265){
                  colDef.push({
                    field: key,
                    cellRenderer: SplitWithTilde,
                    cellRendererParams: {
                      name: key,
                      datasourceID: datasourceID,
                    },
                    autoHeight : true,
                    sort: "asc",
                    comparator: customComparator
                  });
                }       
                else if (["Client","Client group"].includes(key) && datasourceID == 265){
                  colDef.push({
                    field: key,
                    cellRenderer: SplitWithTilde,
                    cellRendererParams: {
                      name: key,
                      datasourceID: datasourceID,
                    },
                    autoHeight : true,
                  });
                }                
                else if (key == "Client" && datasourceID == 270){
                  colDef.push({
                    field: key,
                    cellRenderer: SplitComponent,
                    cellRendererParams: {
                      name: "Client",
                      datasourceID: datasourceID,
                    },
                    
                  });
                }
                else if (key =="ClientGroup" && datasourceID == 270){
                  colDef.push({
                    field: key,
                    
                    cellRenderer: SplitComponent,
                    cellRendererParams: {
                      name: "ClientGroup",
                      datasourceID: datasourceID,
                    },
                  });
                }else if (key == "ID" && datasourceID == 264){
                  colDef.push({
                    field: key,
                    cellRenderer: IDLink,
                    cellRendererParams: {
                      link: `/admin/form/${datasourceID}`,
                      recordId: "ID",
                      // filter: 'agTextColumnFilter'
                    },
                    // sort: "asc",
                })}
                else if (key == "ID" && datasourceID == 274){
                  colDef.push({
                    field: key,
                    cellRenderer: IDLink,
                    cellRendererParams: {
                      link: `/admin/form/${datasourceID}`,
                      recordId: "ID",
                      // filter: 'agTextColumnFilter'
                    },
                    // sort: "asc",
                })}
                else if(datasourceID == 261 && key == "Associated Status(es)") {
                  let actionColDef = {
                    headerName: key,
                    cellRenderer: SplitComponent,
                    cellRendererParams: {                
                      name: "Associated Status(es)",
                      datasourceID: datasourceID,
                    },
                    autoHeight : true,
                  };
                  colDef.push(actionColDef);
                }
                 
                 else {
                  colDef.push({ field: key });
                }
              }
            }

            if (datasourceID == 251) {
              let actionColDef = {
                headerName: "Action",
                cellRenderer: TitleLink,
                cellRendererParams: {
                  link: `/admin/list/252`,
                  recordIdField: "ID",
                  caption: "Groups",
                },
              };
              colDef.push(actionColDef);
            }else if (datasourceID == 252) {
              let actionColDef = {
                headerName: "",
                cellRenderer: deleteButton,
                cellRendererParams: {
                  recordIdField: "ID",
                  name : "Name",
                  caption: "Delete",
                },
              };
              colDef.push(actionColDef);
            } else if (datasourceID == 263) {
              let actionColDef = {
                headerName: "",
                cellRenderer: TitleLink,
                cellRendererParams: {
                  link: `/admin/list/264`,
                  recordIdField: "ID",
                  caption: "Fields",
                },
              };
              colDef.push(actionColDef);
            } else if (datasourceID == 266) {
              let actionColDef = {
                headerName: "Action",
                cellRenderer: TitleLink,
                cellRendererParams: {
                  link: `/admin/list/267`,
                  recordIdField: "ID",
                  caption: "Items",
                },
              };
              colDef.push(actionColDef);
            } else if (datasourceID == 270) {
              
              for (let x of colDef) {
                if (x.field === "Description") {
                  x.wrapText = true;
                  x.autoHeight = true;
                }else if((x.field == "Client" || x.field =="ClientGroup" )&& datasourceID ==270){
                  x.autoHeight = true;
                }

              }

              // colDef.push(actionColDef);
            } else if (datasourceID == 265){
              let actionColDef = {
                headerName: "Edit disable option",
                cellRenderer: TitleLink,
                cellRendererParams: {
                  link: `/admin/form/265`,
                  recordIdField: "ID",
                  caption: "Edit",
                },
              };
              colDef.push(actionColDef);
            }
          }
        }
        state.searchData = { rowData, colDef };
      }

      state.isSearchgGridLoading = false;
    },

    setColumnFilters: (state:any, action: PayloadAction<any>) => {
      if (!action.payload.hasOwnProperty("error")) {
        const colFilterData : any = {};
        let rowData = [];
        let from = "SearchTable";
        let payloadData = action.payload.data;
        // from = action.payload.from ;
        if (action.payload.from && action.payload.from == "SearchTable"){
          const rowDataKeys = payloadData && Object.keys(payloadData);
          let filterData = {...payloadData};
          for (const k of rowDataKeys) {
            filterData[k] = filterData[k].map((item: any)=> {
              if (typeof(item) == 'string') {
                // return convert(item);
                return item;
              }
              else {
                return item;
              }
            }); 
          }
          console.log('filterData: ', filterData);
          state.columnFilterData = filterData;
        }else{
          if(action.payload.data && action.payload.data.data){
            payloadData = action.payload.data.data;
          }
          if (action.payload.from && action.payload.from == "AuditLog") {
            rowData = action.payload.rowData;
            from = "AuditLog";
          } else {
            rowData = payloadData as object[];
            const rowDataKeys = rowData[0] && Object.keys(rowData[0]);
            rowData = rowData.map((x:any) => {
              let tempRow: any = {};
              for (const k of rowDataKeys) {
                if (typeof(x[k]) == 'string') {
                  tempRow[k] = convert(x[k]);
                }
                else {
                  tempRow[k] = x[k];
                }
              }
              return tempRow;
            });
          } 
  
          const datasourceID = state.selectedDatasourceId;
          const displayColmns = state.defaultColumn.showColumnsNames;
          if (rowData != null && rowData.length > 0) {
            if (from == "AuditLog") {
              let uniqueVal = [];
              for (var key in rowData[0]) {
                if (rowData[0].hasOwnProperty(key) && key != 'ID') {
                  if (key == "Title" || key == "Name") {
                  } else if (key.toLowerCase().includes("date")) {
                    const arr = rowData.map((item:any) =>
                      item[key] == null ? null : item[key]//.slice(0, 10)
                    );
                    uniqueVal = arr.filter(
                      (value:any, index:any, self:any) => self.indexOf(value) === index
                    );
                    // uniqueVal = uniqueVal.map(x => x == null ? null : x.slice(0,10));
                  } else {
                    const arr: any[] = rowData.map((item:any) => {
                      if (item[key] !== null) return item[key].toString();
                      else {
                        return item[key];
                      }
                    });
                    uniqueVal = arr.filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                    uniqueVal = uniqueVal.filter((x) => x !== false);
                  }
                }
                if(key != 'ID')
                  colFilterData[key] = uniqueVal;
              }
              state.columnFilterData = colFilterData;
            } else if (datasourceID <= 250) {
              for (const key of displayColmns) {
                let uniqueVal = [];
                const colInfo = state.defaultColumn.defaultColumnInfo.find(
                  (x:any) => x.Name == key
                );
                const colType =
                  colInfo && colInfo.hasOwnProperty("FieldRenderMethod")
                    ? colInfo["FieldRenderMethod"]
                    : null;
                if (rowData[0].hasOwnProperty(key)) {
                  if (colType && colType === "QHTASearch_mRenderDate") {
                    const arr = rowData.map((item:any) =>{
                      const demodate=item[key] == null ? null : item[key].slice(0, 10)
                      const date=new Date(demodate)
                      const day = date.getDate().toString().padStart(2, '0');
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const year = date.getFullYear();
                      const formattedDateColumns = `${day}-${month}-${year}`;
                      const demo=formattedDateColumns;
                      return item[key]==null?null:demo
              
               });
                    uniqueVal = arr.filter(
                      (value:any, index:any, self:any) => self.indexOf(value) === index
                    );
                    // uniqueVal = uniqueVal.map(x => x == null ? null : x.slice(0,10));
                  } else {
                    const arr: any[] = rowData.map((item:any) => {
                      if (item[key] !== null) return item[key].toString();
                      else {
                        return item[key];
                      }
                    });
                    const isEmpty = (str: any) => str === null || str === '';
                    uniqueVal = arr.filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                    uniqueVal = uniqueVal.filter((x) => x !== false);
                    uniqueVal = uniqueVal.map((x) =>
                      isEmpty(x) ? "[Blank]" : x
                    );
                    uniqueVal = removeExtraBlanks(uniqueVal);
                  }
                  colFilterData[key] = uniqueVal;
                }
              }
              state.columnFilterData = colFilterData;
            }
          }
        }
      }
    },

    setDataSource: (state:any, action: PayloadAction<number>) => {
      state.selectedDatasourceId = action.payload;
      state.searchDataSourceId = getSearchDataSourceId(action.payload);
      state.selectedDatasourceName = getDataSourceName(action.payload);
      state.selectedDatasourceDbName = getDataSourceDbName(action.payload);
      // state.initiateSearch = false;
      // state.isSearchgGridLoading = false;
      state.searchData = { rowData: [], colDef: [] };
    },

    setFilterState: (state:any, action: PayloadAction<any>) => {
      // state.initiateSearch = true;
      state.filterState = action.payload;
      // state.isSearchgGridLoading = true;
    },
    setPriorFilterState: (state:any, action: PayloadAction<any>) => {
      state.priorFilterState = action.payload;
    },

    setPathwayFilterState: (state:any, action: PayloadAction<any>) => {

      state.pathwayFilterState = action.payload;
    },

    setEditColumnData: (state:any, action: PayloadAction<any>) => {
      let rows = action.payload.data.searchFields;
      const accountType = action.payload.accountType;
      let sectionInfo = action.payload.data.sectionsInfo;
      if (accountType === "EClient") {
        const recordInfoSection = sectionInfo.find(
          (s:any) => s.Name == "Record information"
        );
        rows = rows.filter((x:any) => x.Category_ID != recordInfoSection.ID || x.Name === 'Name' || x.Name === 'Abbreviation');
        sectionInfo = sectionInfo.filter((s:any) => s.ID != recordInfoSection.ID);
      }
      const columnDef: ColDef[] = [
        {
          field: "ID",
          headerName: "Enabled",
          maxWidth: 200,
          // checkboxSelection: true,
          checkboxSelection: (params) => {
            return !!params.data && params.data.Name !== 'Name';
          },
          showDisabledCheckboxes: true,
          valueFormatter: () => "",
        },
        { field: "Label", headerName: "Name" },
      ];

      state.editColumnData = {
        gridData: { rowData: rows, colDef: columnDef },
        sectionsData: sectionInfo,
      };
    },

    setDefaultColumnData: (state:any, action: PayloadAction<any>) => {
      const defColumnData = action.payload;
      if(defColumnData){
        const showColumnsNames = defColumnData.defaultColumnInfo.map(
          (col:any) => col.Name
        );
        const showColumnsLabels: { [name: string]: string | null } = {};
        defColumnData.defaultColumnInfo.map((col:any) => {
          showColumnsLabels[col.Name] = col.Label;
          return { [col.Name]: col.Label };
        });
        state.showColumnLabels = showColumnsLabels;
        state.defaultColumn = {
          showColumnsNames,
          defaultColumnInfo: defColumnData.defaultColumnInfo,
          sortColumn: defColumnData.sortColumn,
          sortDirection: defColumnData.sortDirection,
        };
        state.preferenceData = defColumnData.preferenceData;
      }      
    },

    setUserPreferenceData: (state:any, action: PayloadAction<any>) => {
      const selectedRows = action.payload;
      const selectedColumnIDs = selectedRows.map((row: any) => row.ID);
      state.defaultColumn.showColumnsNames = selectedRows.map(
        (row: any) => row.Name
      );
      const colInfoData = selectedRows.map((row: any) => {
        return {
          ID: row.ID,
          Name: row.Name,
          Label: row.Label,
          Category_ID: row.Category_ID,
          FieldRenderMethod: row.FieldRenderMethod,
        };
      });
      state.defaultColumn.defaultColumnInfo = colInfoData;
      const showColumnsLabels: { [name: string]: string | null } = {};
      selectedRows.map((col:any) => {
        showColumnsLabels[col.Name] = col.Label;
        return { [col.Name]: col.Label };
      });
      state.showColumnLabels = showColumnsLabels;
      const userPrefs = JSON.parse(state.preferenceData);
      userPrefs.visibleColumns = selectedColumnIDs;
      state.preferenceData = JSON.stringify(userPrefs);
    },

    rearrangeGridColumn: (state:any, action: PayloadAction<string[]>) => {
      const colNames = action.payload;
      const colInfo = state.defaultColumn.defaultColumnInfo.filter((col: any) =>
        colNames.includes(col.Name)
      );

      state.defaultColumn = {...state.defaultColumn, showColumnsNames: colNames};
      let columnIDs: number[] = [];
      for (const cName of colNames) {
        columnIDs.push(colInfo.find((c:any) => c.Name == cName).ID);
      }
      const userPrefs = JSON.parse(state.preferenceData);
      userPrefs.visibleColumns = columnIDs;
      state.preferenceData = JSON.stringify(userPrefs);
      state.columnRearranged = true;
    },

    setColumnRearranged: (state:any, action: PayloadAction<boolean>) => {
      state.columnRearranged = action.payload;
    },

    setAuditLogData: (state:any, action: PayloadAction<any>) => {
      const payloadData = action.payload.auditData;
      const recordID = action.payload.record_ID;
      const rowData = payloadData as object[];
      const colDef = [];
      const datasourceID = action.payload.dataSourceID;
      const dateKeyArray = [];
      for (var key in rowData[0]) {
        if (rowData[0].hasOwnProperty(key) && key != 'ID') {
          if (key == "Title" || key == "Name") {
            colDef.push({
              field: key,
              cellRenderer: TitleLink,
              cellRendererParams: {
                link: `/ViewVersion/${datasourceID}/${recordID}`,
                recordIdField: "ID",
              },
              // sort: "asc",
            });
          } 
          else if (key.toLowerCase().includes("date")) {
            dateKeyArray.push(key);
            colDef.push({
              field: key,
              tooltipField: key,
              valueFormatter: (param: ValueFormatterParams) => {
                // return param.value == null ? null : param.value.slice(0, 10);
                return param.value == null ? null : new Date(param.value).toLocaleString("en-UK");
              },
              filter: MultiColumnFilters,
              filterParams: {
                datasourcId: datasourceID,
                closeFilterPopup: null,
              },
              sort: "desc",
            });
          } 
          
          else {
            colDef.push({
              field: key,
              filter: MultiColumnFilters,
              filterParams: {
                datasourcId: datasourceID,
                closeFilterPopup: null,
              },
            });
          }
        }     
      }
      colDef.push({
        //field: key,
        headerName:" ",
        minWidth: 155, cellRenderer: AuditLogButton,
        //filter: MultiColumnFilter,
        filterParams: {
          datasourcId: datasourceID,
          closeFilterPopup: null,
        },
      });

      let updatedArray = [...rowData];
      if (dateKeyArray.length > 0) {
        dateKeyArray.forEach((key: string) => {
          updatedArray = updatedArray.map((row) => {
            let _row:any = { ...row };
            _row[key] = _row[key] == null ? null : _row[key];
            return _row;
          });
        });
      }
      state.auditData = { rowData: updatedArray, colDef };
      state.isSearchgGridLoading = false;
    },

    setInitiateSearch: (state:any, action: PayloadAction<boolean>) => {
      state.initiateSearch = action.payload;
    },
    setCurrIds : (state:any, action: PayloadAction<number[]>) => {
      state.currIds = action.payload;
    },
    setSearchRecordID : (state:any, action: PayloadAction<any>) => {
      state.searchRecordID = action.payload===""?null:parseInt(action.payload);

    },
    setRecordIdData: (state:any, action: PayloadAction<any>) => {
      // state.recordIdData = action.payload;
      const columnDef: ColDef[]=[
        {
          field:"RecordID",
          headerName: "ID",
          maxWidth: 100,
        },
        {
          field: "Name",
          headerName: "Title",
          cellRenderer: TitleLink,
          //valueFormatter: removeHtmlTags,
          cellRendererParams:{
            // link: ((datasourceID < 250) ? `http://localhost:3001/view/${datasourceID}` : `/admin/form/${datasourceID}`),
            link: `/view/RowNumber`,
            recordIdField: "RecordID",
          },
          wrapText: true,
          autoHeight: true,
          maxWidth: 740,
        },
        {
          field:"DataSourceType",
          headerName:"Type",
          maxWidth: 200,
        }
        ];
        const ColumnsDef=action.payload;
        const rowData = action.payload;
        state.recordIdData = {rowData,colDef:columnDef};
        state.recordIdDataLoading=false;
    },
    setReacordIDDataLoading: (state:any, action: PayloadAction<boolean>) => {
      state.recordIdDataLoading = action.payload;
    },
    setRecordIdSearch: (state:any, action: PayloadAction<any>) => {
      state.recordIdSearch = action.payload;

    },
    setSearchIdsForExport: (state:any, action: PayloadAction<any>) => {
      state.searchIdsForExport = action.payload;
    },
    setSelectedColumnFiters: (state:any, action:PayloadAction<any>) => {
      state.selectedColumnFilter = action.payload;
      if(Object.keys(action.payload).length === 0)
        state.totalFilteredCount = null;
    },
    setFilterColumnTypes: (state:any, action:PayloadAction<any>) => {
      state.filterColumnTypes = action.payload;
    },
    setIsPathwaySearch: (state:any, action:PayloadAction<boolean>) => {
      state.isPathwaySearch = action.payload;
    },
    setIsExportDisabled: (state:any, action:PayloadAction<any>) => {
      state.IsExportDisabled = action.payload;
    },
    setColumnFilterApplied: (state:any, action:PayloadAction<boolean>) => {
      state.columnFilterApplied = action.payload;
    },
    setSelectedValues: (state:any, action:PayloadAction<any>) => {
      state.selectedValues = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  saveSearchData,
  setColumns,
  setDataSource,
  setFilterState,
  setPriorFilterState,
  setEditColumnData,
  setUserPreferenceData,
  setDefaultColumnData,
  setAuditLogData,
  rearrangeGridColumn,
  setColumnRearranged,
  setIsSearchGridLoading,
  setColumnFilters,
  setInitiateSearch,
  setCurrIds,
  setSearchRecordID,
  setRecordIdData,
  setReacordIDDataLoading,
  setRecordIdSearch,
  setSearchIdsForExport,
  setSelectedColumnFiters,
  setFilterColumnTypes,
  setIsPathwaySearch,
  setPathwayFilterState,
  setTotalResultCount,
  setIsExportDisabled,
  setColumnFilterApplied,
  setActiveColumnFilter,
  setSelectedValues
} = SearchSlice.actions;

export default SearchSlice.reducer;
