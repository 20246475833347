import React, { useEffect, useState } from "react";
import apiCall from "../service/apiCall";

interface IDiseaseHierarchy {
      recordID: number;
      dataSourceID: number;
}

const DiseaseHierarchy = ({ recordID, dataSourceID }: IDiseaseHierarchy) => {

      const [ diseaseTablePrimary, setDiseaseTableOnePrimary ] = useState<any>({});
      const [ diseaseTableSecondary, setDiseaseTableOneSecondary ] = useState<any>({});
      const [ showTableSecondDiseaseHierarchy, setShowTableSecondDiseaseHierarchy ] = useState<boolean>(false);

      const createCategory = (name: string) => [
            {
                  name,
                  rowSpan: 1,
                  title: true,
                  level: 0,
            },
      ];

      const updateCategory = (
            category: any,
            cell: any,
            index: number,
            isICD: boolean
      ) => {
            let found = false;
            for (let x of category) {
                  if (x.name === cell.Name && x.name !== null && category !== "ICD_Code") {
                        x.rowSpan++;
                        found = true;
                        break;
                  }
            }
            if (!found) {
                  category.push({
                        name: isICD ? cell.ICD : cell.Name ? cell.Name : " ",
                        rowSpan: 1,
                        title: false,
                        level: index,
                  });
            }
      };

      const initializeNewObj = () => ({
            TherapeuticArea: createCategory("Therapeutic area"),
            Disease: createCategory("Disease"),
            SubType1: createCategory("Disease subtype 1"),
            SubType2: createCategory("Disease subtype 2"),
            SubType3: createCategory("Disease subtype 3"),
            SubType4: createCategory("Disease subtype 4"),
            Invisible: createCategory(""),
            ICD_Code: createCategory("ICD code"),
      });

      const addEmptyCell = (category: any, rowIndex: any) => {
            console.log("roxIndex", rowIndex);
            category.push({
                  name: " ",
                  rowSpan: 1,
                  title: false,
                  level: rowIndex,
            });
      };

      const replaceOncology = (
            firstTherapeuticArea: string,
            replaceLabel: string,
            label: string,
            tableNumber: number
      ) => {
            if (firstTherapeuticArea === "Oncology") {
                  let obj: any = {
                        TherapeuticArea: "Therapeutic area",
                        Disease: "Disease",
                        SubType1: "Cell Type / Location",
                        SubType2: "Histology",
                        SubType3: "Biomarker",
                        SubType4: "Stage",
                        ICD_Code: "ICD code",
                  };
                  return obj[ replaceLabel ];
            } else {
                  if (tableNumber === 1) return label;
                  else {
                        let obj: any = {
                              TherapeuticArea: "Secondary therapeutic area(s)",
                              Disease: "Secondary disease",
                              SubType1: "Secondary disease subtype 1",
                              SubType2: "Secondary disease subtype 2",
                              SubType3: "Secondary disease subtype 3",
                              SubType4: "Secondary disease subtype 4",
                              ICD_Code: "Secondary icd code",
                        };
                        return obj[ replaceLabel ];
                  }
            }
      };

      const formateReimbursementDiseaseHierachyData = (value: any) => {
            console.log(value, "value");
            let newObj = initializeNewObj();
            let rowIndex = 0;

            value.map((row: any, index: number) => {
                  let icdInfo;
                  rowIndex = index + 1;
                  let emptyCell = 6;

                  row
                        .sort((a: any, b: any) => a.Level - b.Level)
                        .forEach((cell: any) => {
                              if (cell.Level === 0) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "TherapeuticArea" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                              if (cell.Level === 1) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "Disease" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                              if (cell.Level === 2) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "SubType1" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                              if (cell.Level === 3) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "SubType2" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                              if (cell.Level === 4) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "SubType3" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                              if (cell.Level === 5) {
                                    icdInfo = cell;
                                    updateCategory(newObj[ "SubType4" ], cell, rowIndex, false);
                                    emptyCell--;
                              }
                        });
                  if (emptyCell === 1) {
                        addEmptyCell(newObj[ "SubType4" ], rowIndex);
                  } else if (emptyCell === 2) {
                        addEmptyCell(newObj[ "SubType3" ], rowIndex);
                        addEmptyCell(newObj[ "SubType4" ], rowIndex);
                  } else if (emptyCell === 3) {
                        addEmptyCell(newObj[ "SubType2" ], rowIndex);
                        addEmptyCell(newObj[ "SubType3" ], rowIndex);
                        addEmptyCell(newObj[ "SubType4" ], rowIndex);
                  } else if (emptyCell === 4) {
                        addEmptyCell(newObj[ "SubType1" ], rowIndex);
                        addEmptyCell(newObj[ "SubType2" ], rowIndex);
                        addEmptyCell(newObj[ "SubType3" ], rowIndex);
                        addEmptyCell(newObj[ "SubType4" ], rowIndex);
                  } else if (emptyCell === 5) {
                        addEmptyCell(newObj[ "Disease" ], rowIndex);
                        addEmptyCell(newObj[ "SubType1" ], rowIndex);
                        addEmptyCell(newObj[ "SubType2" ], rowIndex);
                        addEmptyCell(newObj[ "SubType3" ], rowIndex);
                        addEmptyCell(newObj[ "SubType4" ], rowIndex);
                  }
                  updateCategory(newObj[ "ICD_Code" ], icdInfo, rowIndex, true);
            });
            setDiseaseTableOnePrimary(newObj);
      };

      const formateDiseaseHierachyData = (value: any, tableNum: number) => {
            // console.log("formateDiseaseHierachyData", value);
            let newObj: any = {
                  TherapeuticArea: [
                        {
                              name: "Therapeutic area",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  Disease: [
                        {
                              name: "Disease",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  SubType1: [
                        {
                              name: "Disease subtype 1",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  SubType2: [
                        {
                              name: "Disease subtype 2",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  SubType3: [
                        {
                              name: "Disease subtype 3",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  SubType4: [
                        {
                              name: "Disease subtype 4",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
                  Invisible: [
                        {
                              name: "",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                        },
                  ],
                  ICD_Code: [
                        {
                              name: "ICD code",
                              rowSpan: 1,
                              title: true,
                              level: 0,
                              id: 0,
                        },
                  ],
            };

            if (!!value[ 0 ] && value[ 0 ].length > 0) {
                  value[ 0 ].forEach((item: any, index: number) => {
                        // console.log(item, "item", index);
                        for (const key in item) {
                              // console.log(key, "key");
                              if (newObj[ key ]) {
                                    let found = false;
                                    for (let x of newObj[ key ]) {
                                          if (
                                                x.id === item[ key + "_ID" ] &&
                                                x.id !== null &&
                                                key !== "ICD_Code"
                                          ) {
                                                x.rowSpan++;
                                                found = true;
                                                break;
                                          }
                                    }
                                    if (!found) {
                                          newObj[ key ].push({
                                                name: item[ key ] ? item[ key ] : " ",
                                                rowSpan: 1,
                                                title: false,
                                                level: index + 1,
                                                id: item[ key + "_ID" ] ? item[ key + "_ID" ] : null,
                                          });
                                    }
                              }
                        }
                  });
            }

            // console.log(newObj, "newObj");
            tableNum === 1 ? setDiseaseTableOnePrimary(newObj) : setDiseaseTableOneSecondary(newObj);
      };

      const fetchDiseaseHierachyData = async () => {
            if (recordID && dataSourceID) {
                  const response = await apiCall(`/api/getDiseaseInfo/${recordID}/${dataSourceID}/undefined`);
                  if (
                        response &&
                        response.data &&
                        Object.entries(response.data).length > 0
                  ) {
                        if (dataSourceID !== 25)
                              formateDiseaseHierachyData(response.data.tableOne, 1);
                        else formateReimbursementDiseaseHierachyData(response.data);

                        if (
                              !!response.data.tableTwo &&
                              response.data.tableTwo[ 0 ] &&
                              response.data.tableTwo[ 0 ].length > 0 &&
                              Number(dataSourceID) === 1
                        ) {
                              setShowTableSecondDiseaseHierarchy(true);
                              formateDiseaseHierachyData(response.data.tableTwo, 2);
                        }
                  }
            }

      };

      useEffect(() => {
            if ([ 1, 2, 3, 23, 25 ].includes(Number(dataSourceID))) {
                  fetchDiseaseHierachyData();
            }
      }, [ recordID, dataSourceID ])

      const diseaseTable1 = () => {
            return (
                  <div className="diseaseTable">
                        <div className="tableOne">
                              <div style={{ display: "flex", width: "102%" }}>
                                    {displayTableSection(1)}
                              </div>
                        </div>
                        {showTableSecondDiseaseHierarchy && (
                              <>
                                    <div className="tableSeparator"></div>
                                    <div style={{ display: "flex", width: "102%" }}>
                                          {displayTableSection(2)}
                                    </div>
                              </>
                        )}
                  </div>
            );
      };

      const displayTableSection = (tableNumber: number) => {
            const table = tableNumber === 1 ? diseaseTablePrimary : diseaseTableSecondary;
            return !!table.TherapeuticArea && table.TherapeuticArea.length > 1
                  ? Object.entries(table).map((value: any, key: any) => (
                        <div
                              key={key}
                              style={{ width: value[ 0 ] === "ICD_Code" ? "7%" : value[ 0 ] !== "Invisible" ? "18.5%" : "2%" }}
                        >
                              {value[ 1 ].map((x: any, key2: any) => (
                                    <div
                                          key={"data _" + key2}
                                          style={
                                                value[ 0 ] !== "Invisible"
                                                      ? x.level !== 0
                                                            ? {
                                                                  height: x.rowSpan * 65,
                                                                  borderBottom: "1px solid #ddd",
                                                                  display: "flex",
                                                                  flexDirection: "column",
                                                                  justifyContent: "center",
                                                                  marginLeft: "-8px",
                                                                  marginRight: "1em"
                                                            }
                                                            : {
                                                                  backgroundImage:
                                                                        "linear-gradient(to bottom,#f8f8f8 0%,#ececec 100%)",
                                                                  backgroundRepeat: "repeat-x",
                                                                  textAlign: "left",
                                                                  lineHeight: "25px",
                                                                  borderTop: "1px solid #ddd",
                                                                  borderBottom: "1px solid #ddd",
                                                                  display: "flex",

                                                                  //backgroundColor: "#f4f4f4",
                                                                  //color: "black",
                                                                  //borderTop: "1px solid red",
                                                                  //borderBottom: "1px solid #ddd",
                                                                  fontWeight: 700,
                                                                  height: tableNumber === 1 ? 37 : 70,
                                                                  marginLeft: "-9px",
                                                                  //padding: "9px 0px",
                                                            }
                                                      : {
                                                            width: 20,
                                                      }
                                          }
                                    >
                                          <span
                                                style={{
                                                      padding: "0px 0px",
                                                      display: x.level === 0 ? "inline-block" : "contents",
                                                      fontSize: 12,
                                                      width: "120px",
                                                      wordWrap: "break-word",
                                                      whiteSpace: "normal",
                                                      lineHeight: "20px",
                                                }}
                                          >
                                                {x.level === 0
                                                      ? replaceOncology(
                                                            table.TherapeuticArea[ 1 ].name,
                                                            value[ 0 ],
                                                            x.name,
                                                            tableNumber
                                                      )
                                                      : x.name}
                                          </span>
                                    </div>
                              ))}
                        </div>
                  ))
                  : " ";
      };
      return (
            <>
                  {
                        diseaseTable1()
                  }
            </>
      )
}

export default DiseaseHierarchy;