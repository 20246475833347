import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import apiCall from "../service/apiCall";
import { fabClasses, paginationItemClasses } from "@mui/material";
import { RiCloseLine, RiCompassDiscoverLine } from "react-icons/ri";
import styles from "./Modal.module.css";
import "./MultiSelectModal.css";
import "../../app.css";
import { infoOptions, UserContext } from "../Forms/Editor";
import {
  IselectedLookup,
  IselectedLookupSpecial,
  IselectedLookupGlobe,
} from "../interfaces/multiselect";
import { Spinner } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setModalShow,
  setSelectedDataState,
  setSelectedDataStateSpecial,
  setFilterAll,
  setLookupData,
  setSelectedLookupGlobe,
  setLookupGlobe,
  setCheckedState,
  setDrugCombination,
  setSelectedLookupGlobeSpecial,
  setEndpointResultList,
  setLookupKey,
  setNoRecordFound,
  // setSelectedLookupGlobeDbVal,
} from "../../store/MultiSelectSlice";
import Disease from "../Disease/Disease";
import NewNode from "../Disease/DiseaseHierarchy";
import "./MyModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, fas, faPlus } from "@fortawesome/free-solid-svg-icons";
import { debounce, diseaseLevel, findChild, findChildIds } from "./Utility";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { GetDependentFieldName, LookupIds, GetCorporationForDrug } from "../Shared/Utility";
import PolicyCategoryHierarchy from "../PolicyCategory/PolicyCategory";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useParams } from "react-router";
import Parser from 'html-react-parser';
import AddIcon from "@mui/icons-material/Add";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

const buttonStyleOnClick = {
  height: "25px",
  backgroundColor: "rgb(0, 170, 231)",
  width: "30px",
  color: "white",
  display: "inline-block",
  padding: "3px",
  margin: "0px",
  border: "0px",
  fontSize: "13px",
};

const buttonStyle = {
  height: "25px",
  width: "30px",
  background: "white",
  color: "#00aae7",
  display: "inline-block",
  padding: "3px",
  margin: "0px",
  border: "0px",
  fontSize: "13px",
};

// interface IsOpenFunc {

//   url: string;

//   title : string;
// }

export interface selData {
  Id: number;
  Value: string;
}

export interface selDataSpecial {
  Id: string;
  Value: string;
}

export interface ILookupData {
  ID: number;
  Lookup_Item_Parent_ID: number;
  Parent_ID: number;
  SortOrder: number;
  Value: string;
  isChecked: boolean;
  val: string;
}

export interface ILookupDataSpecial {
  ID: string;
  Lookup_Item_Parent_ID: number;
  Parent_ID: number;
  SortOrder: number;
  Value: string;
  isChecked: boolean;
  val: string;
}

function NewModal() {
  const currentUrl = window.location.href;
  const isRecordViewPage = currentUrl.toString().includes("/view/");
  const lookupGlobe = useAppSelector((state) => state.multiselect.lookupGlobe);
  const LookupData = useAppSelector((state) => state.multiselect.lookupData);
  const LookupDataSpecial = useAppSelector(
    (state) => state.multiselect.lookupDataSpecial
  );
  const savedData = useAppSelector((state) => state.multiselect.selectedData);
  const savedDataSpecial = useAppSelector(
    (state) => state.multiselect.selectedDataSpecial
  );
  const filterAll = useAppSelector((state) => state.multiselect.filterAll);
  const modalShow = useAppSelector((state) => state.multiselect.modalShow);
  const lookupKey = useAppSelector((state) => state.multiselect.lookupKey);
  const lookupId = useAppSelector((state) => state.multiselect.lookupId);
  const title = useAppSelector((state) => state.multiselect.title);
  const isForm = useAppSelector((state) => state.multiselect.isForm);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  // const selectedLookupGlobeDbVal = useAppSelector(
  //   (state) => state.multiselect.selectedLookupGlobeDbVal
  // );
  const loeBrandName = useAppSelector(
    (state) => state.multiselect.loeBrandName
  );
  const [diseaseHierarchy, setDiseaseHierarchy] = useState(false);
  const [policyCategory, setPolicyCategory] = useState(false);
  const [drugModal, setDrugModal] = useState(false);
  const [showRegionalDrug, setShowRegionalDrug] = useState(false);
  const [noRegionalItems, setNoRegionalItems] = useState(false);
  const [regionalItemsLoading, setRegionalItemsLoading] = useState(false);
  const [checkRegional, setCheckRegional] = React.useState(false);
  const [openCombinationExist, setOpenCombinationExist] = React.useState(false);
  const dispatch = useAppDispatch();

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCombinationExist = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenCombinationExist(false);
  };

  const savedStateChecked = useAppSelector(
    (state) => state.multiselect.checkedState
  );

  const [checked, setChecked] = useState<string[]>(savedStateChecked);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [partiallyCheckedValues, setPartiallyCheckedValues] = useState<
    number[]
  >([]);
  const userContext = useContext(UserContext);
  const [show, setShow] = useState(true);
  //const [drugCombination , setDrugCombination] = useState<any>([]);
  const drugCombination = useAppSelector(
    (state) => state.multiselect.drugCombination
  );
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );
  const handleClose = () => {
    dispatch(setFilterAll(false));
    setCheckRegional(false);
    dispatch(setModalShow(false));
    dispatch(setLookupData([]));
    setRegionalItems([]);
    dispatch(setNoRecordFound(false));
    setFilter("All");
    let saveLookupData: any = [...items];
    for (let x in selectedData) {
      let selectedDataCheck = false;
      for (let y in savedData) {
        if (selectedData[x].Id == savedData[y].Id) {
          selectedDataCheck = true;
        }
      }
      if (selectedDataCheck == false) {
        saveLookupData[selectedData[x].Id] = {
          ...saveLookupData,
          isChecked: false,
        };
      }
    }
    for (let x in selectedDataSpecial) {
      let selectedDataCheck = false;
      for (let y in savedDataSpecial) {
        if (selectedDataSpecial[x].Value == savedDataSpecial[y].Value) {
          selectedDataCheck = true;
        }
      }
      if (selectedDataCheck == false) {
        saveLookupData[selectedDataSpecial[x].Id] = {
          ...saveLookupData,
          isChecked: false,
        };
      }
    }
    for (let y in savedData) {
      saveLookupData[savedData[y]["Id"]] = {
        ...saveLookupData,
        isChecked: true,
      };
    }
    for (let y in savedDataSpecial) {
      saveLookupData[savedDataSpecial[y]["Id"]] = {
        ...saveLookupData,
        isChecked: true,
      };
    }
    if ([LookupIds.DS_InternalProjectCode, 
      LookupIds.ActiveSubstance,
      LookupIds.InCountryRegion,
      LookupIds.RegionalBrandName,
      LookupIds.ReimbursementCondition,
      LookupIds.AgreementBenefitForPayer,
      LookupIds.AgreementBenefitForManufacturer,
      LookupIds.AgreementOutcomesTracked,
      LookupIds.AgreementPatientsIncluded].includes(lookupId)) {
        let currlookupData = [...lookupGlobe[lookupKey]];
        let newData = currlookupData.map((x) => {
          return {
            ...x,
            isChecked: false,
          }; 
        });
        dispatch(setLookupGlobe({ ...lookupGlobe, [lookupKey]:newData }));
      }
    setItems(saveLookupData);
  };

  const handleShow = () => setShow(true);
  const [items, setItems] = useState<ILookupData[]>([]);
  const [itemsSpecial, setItemsSpecial] = useState<ILookupDataSpecial[]>([]);
  const [regionalItems, setRegionalItems] = useState<ILookupData[]>([]);
  const [lookupLength, setLookupLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(99);
  const [end, setEnd] = useState(199);
  const [pending, setPending] = useState(true);
  const [paging, setPaging] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [selectedData, setSelectedData] = useState<selData[]>(savedData);
  const [selectedDataSpecial, setSelectedDataSpecial] =
    useState<selDataSpecial[]>(savedDataSpecial);

  const alphabet = [
    "All",
    "0-9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const [currData, setCurrData] = useState<ILookupData[]>([]);
  const [currDataSpecial, setCurrDataSpecial] = useState<ILookupDataSpecial[]>(
    []
  );
  // const [Mydata, setMydata] = useState<any>(LookupData)
  const [filter, setFilter] = useState("All");
  const [filterStart, setFilterStart] = useState(0);
  const [searchFilterStart, setSearchFilterStart] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const nodes = useAppSelector((state) => state.multiselect.SuperDisease);
  const lookupSource = useAppSelector(
    (state) => state.multiselect.lookupSource
  );

  //const[ childCounts, setChildCounts] = useState<IDictionary>({});
  const [clearAll, setClearAll] = useState<boolean>(false);
  const ds_id = useAppSelector((state) => state.search.searchDataSourceId);
  const prevId = useRef<number>(1);
  const dataSourceID = useAppSelector(
    (state) => state.multiselect.datasource_id
  );
  const noRecordFound = useAppSelector((state) => state.multiselect.noRecordFound);
  const selectedDataRef = useRef(selectedData);

  useEffect(() => {
    if (ds_id != prevId.current) {
      prevId.current = ds_id;
      setShowSelected(false);
    }
  }, []);


  useEffect(() => {
    if (modalShow) {
      setChecked(savedStateChecked);
      setSelectedData(savedData);
      setSelectedDataSpecial(savedDataSpecial);

      if (LookupData) {
        if (LookupData.length > 0) {
          setPending(false);
        }
      }
      
      if (selectedLookupGlobe[lookupKey]?.length > 0) {
        const ids = selectedLookupGlobe[lookupKey].map((el) => el.Id);
        const cids =  lookupSource
        .filter((el: any) => ids.includes(el.ParentID))
        .map((x: any) => x.value);
        let pids: number[] = []; 

        let check_ids = true;
        let temp = ids
        while (check_ids) {
          const samples = lookupSource
            .filter((el: any) => temp.includes(el.value))
            .map((x: any) => x.ParentID);
          if (samples[0]) {
            pids = pids.concat(samples);
            temp = samples;
          } else check_ids = false;
        }

        setCheckedValues([...ids,...cids]);
        setPartiallyCheckedValues(pids);
        setShowSelected(true);
      } else {
        setCheckedValues([]);
        setPartiallyCheckedValues([]);
        setShowSelected(false);
      } 
      if (
        lookupKey ==  "SuperSearchDiseaseHierIDs" || 
        lookupKey == "SuperSearchDiseaseIDs" ||
        lookupKey.indexOf("PrimaryAutocompelete_ID") == 0 ||
        lookupKey.indexOf("SecondaryAutocompelete_ID") == 0 ||
        lookupKey.indexOf("RegulatoryWatch_ApprovedIndication_Disease") == 0 ||
        lookupKey.indexOf("ReimbursementStatus_Disease") == 0
      ) {
        setDiseaseHierarchy(true);
      } else if (lookupKey == "Policy_Category_Hierarchy") {
        setPolicyCategory(true);
      } else {
        setDiseaseHierarchy(false);
        setPolicyCategory(false);
      }

      if (lookupId == 122 || lookupId == 434) {
        setDrugModal(true);
        setShowRegionalDrug(false);
      } else {
        setDrugModal(false);
        setShowRegionalDrug(false);
      }
    } else {
      setPending(true);
      dispatch(setLookupData([]));
      dispatch(setLookupKey(""));
      setSearchValue("");
    }

    if (modalShow == false) {
      // setCheckedValues([]);
      // setPartiallyCheckedValues([]);
      setShowSelected(false);
    }
  }, [modalShow]);

  useEffect(() => {
    if (currData) {
      setPending(true);
      setHasMore(true);
      if (currData.length > 0) {
        setNewPage(Math.trunc(currData.length / 100));
        var startVal: number = 0;
        var endVal: number = 99;
        let arr: any = [];
        let len = Math.trunc(currData.length / 100);

        for (let i = 0; i <= len; i++) {
          let newArr = [];
          newArr.push(startVal, endVal);
          arr.push(newArr);
          startVal = startVal + 100;
          endVal = endVal + 100;
        }

        setPaging(arr);
        let val = currData.length < 99 ? currData.length : 99;
        setLookupLength(val);
        const newItems: ILookupData[] = [];

        const selids = selectedData?.map(x => x.Id) || [];
        for (let i = 0; i < val; i++) {
          if(selids.includes(currData[i].ID))
            newItems.push({ ...currData[i],["isChecked"] : true });
          else
            newItems.push({ ...currData[i],["isChecked"] : false});
        }

        setItems([...newItems]);
        setPage(page + 1);
        // setCurrData([...currData]);
        setPending(false);
      }
    }
  }, [currData]);

  useEffect(() => {
    if (LookupData) {
      if (LookupData.length > 0) {
        const selectedIds = savedData?.map((x) => x.Id) || [];
        const selectedIdsSpecial = savedDataSpecial?.map((x) => x.Value);
        let newData = LookupData.map((x) => {
          if (selectedIds.includes(x["ID"])) {
            return {
              ...x,
              isChecked: true,
            };
          } else if (selectedIdsSpecial.includes(x["Value"])) {
            return {
              ...x,
              isChecked: true,
            };
          }
           else {
            return x;
          }
        });
        setCurrData(newData);
      }
    }
    if (
      lookupKey.includes("DrugData_Lov_BrandName") &&
      loeBrandName.length > 0
    ) {
      const selectedIds = savedData.map((x) => x.Id);
      let newData = loeBrandName.map((x) => {
        if (selectedIds.includes(x["ID"])) {
          return {
            ...x,
            isChecked: true,
          };
        } else {
          return x;
        }
      });
      setCurrData(newData);
    }
  }, [LookupData]);

  useEffect(() => {
    selectedDataRef.current = selectedData;
  },[selectedData])

  const fetchData = () => {
    const newItems: any[] = [];

    let newSelDat: selData[] = [];

    if (filter != "All" && isFilter) {
      for (let i = filterStart; i < currData.length; i++) {
        try {
          if (currData[i]["Value"][0] == filter) {
            newItems.push(currData[i]);
            if (filterAll == true) {
              newSelDat.push({ Id: i, Value: currData[i]["Value"] });
            }
          }
        } catch (e) {}
        if (i + 1 == currData.length) {
          setHasMore(false);
        }
        if (newItems.length >= filterStart + 100) {
          setFilterStart(i);
          break;
        }
      }
    } else if (searchValue != "" && isSearch) {
      for (let val = 0; val < currData.length; val++) {
        try {
          let output: string = currData[val]["Value"].toLowerCase();
          output = output.replace(/[{()}]/g, "");
          let output_list = output.trim().split(/\s+/);
          for (let y in output_list) {
            if (output_list[y].startsWith(searchValue)) {
              currData[val]["isChecked"] = filterAll;
              newItems.push(currData[val]);
              if (filterAll == true) {
                newSelDat.push({ Id: val, Value: currData[val]["Value"] });
              }
            }
          }
          if (val + 1 >= currData.length) {
            setHasMore(false);
          }
          if (newItems.length >= 100) {
            setSearchFilterStart(val);
            break;
          }
        } catch (e) {
          // console.log((e as Error).message);
        }
      }
    } else {
      for (let i = start; i <= end && i < currData.length; i++) {
        // NewMydata[i]['isChecked'] = filterAll
        let tempData: any = [...currData];
        tempData[i] = { ...tempData, isChecked: filterAll };
        newItems.push(currData[i]);
        if (filterAll == true) {
          // let mySelData : selData  = {Id:i,Value:currData[i]['Value']}
          newSelDat.push({ Id: i, Value: currData[i]["Value"] });
        }
      }
    }
    setSelectedData([...selectedData, ...newSelDat]);
    let n_page = page + 1;
    if (n_page > newPage) {
      setHasMore(false);
    }
    setPage(n_page);
    if (paging.length > n_page) {
      setStart(paging[n_page][0]);
      setEnd(paging[n_page][1]);
    }
    if (filterStart == 0 && filter != "All") {
      setItems([...newItems]);
    } else {
      setItems([...items, ...newItems]);
    }
  };

  const ButtonComponent = (props: any, index: any) => {
    const handleClick = () => {
      setIsFilter(true);
      setIsSearch(false);
      setHasMore(true);
      let upDateddata = [];
      setFilter(props.text);
      let sData = [...selectedData];
      let NewMydataCopy = [...currData];
      const selectedIds = selectedData.map((x) => x.Id);
      if (props.text == "All") {
        for (let i = 0; i < lookupLength; i++) {
          if (selectedIds.includes(Number(NewMydataCopy[i]["ID"]))) {
            upDateddata.push({ ...NewMydataCopy[i], isChecked: true });
          } else {
            upDateddata.push({ ...NewMydataCopy[i], isChecked: false });
          }
        }
      } else {
        for (let val in NewMydataCopy) {
          try {
            if (NewMydataCopy[val]["Value"][0].toLowerCase() == props.text[0].toLowerCase() || (props.text == "0-9" && !isNaN(Number(NewMydataCopy[val]["Value"][0])))) {
              if (selectedIds.includes(Number(NewMydataCopy[val]["ID"]))) {
                upDateddata.push({ ...NewMydataCopy[val], isChecked: true });
              } else {
                upDateddata.push({ ...NewMydataCopy[val], isChecked: false });
              }
            }
            if (upDateddata.length == 100) {
              setFilterStart(Number(val));
              break;
            }
          } catch (e) {}
          if (Number(val) + 1 == Number(NewMydataCopy.length)) {
            setHasMore(false);
          }
        }
      }

      // let selIds = selectedData.map((e) => e.Id);
      // // let upData = [...upDateddata];

      // upDateddata.forEach((el: ILookupData, index: number) => {
      //   if (selIds.includes(el.ID)) {
      //     upDateddata[index] = { ...el, isChecked: true };
      //   }
      // });

      setItems([...upDateddata]);
    };
    return (
      <button
        style={
          String(props.text).trim() === String(filter).trim()
            ? buttonStyleOnClick
            : buttonStyle
        }
        key={index + props.text}
        onClick={handleClick}
      >
        {props.text}
      </button>
    );
  };

  const debouncedSearch = useCallback(
    debounce((value:string[]) => {
      // API call or any heavy operation
      fetchRegionalDrug(value)
    }, 500),
    []
  );

  const OnSearch = (props: any) => {
    setIsSearch(true);
    setIsFilter(false);

    let upDateddata = [];
    // let upDatedDataForBrand = [];
    let drugIdsForRegional: any[] = [];

    if (props.target.value != "") {
      let searchTest: string = props.target.value;
      setSearchValue(searchTest);
      for (let val = 0; val < currData.length; val++) {
        try {
          let output: string = currData[val]["Value"].toLowerCase();
          let regex1 = new RegExp('\\b' + searchTest.toLowerCase(), 'g');
          // if (output.includes(searchTest.toLowerCase())) {
          if (output.match(regex1)) {
            const selids = selectedData.map((x) => x.Id)
            if(selids.includes(currData[val].ID))
              upDateddata.push({...currData[val],["isChecked"] : true});
            else
              upDateddata.push({...currData[val],["isChecked"] : false});
          }
          if (
            (lookupId == 434 || lookupId == 122) &&
            dataSourceID != 4 &&
            searchTest.length >= 3 &&
            checkRegional == true &&
            output.match(regex1)
          ) {
            setShowRegionalDrug(true);
            setNoRegionalItems(false);
            drugIdsForRegional.push(String(currData[val]["ID"]));

            debouncedSearch(drugIdsForRegional);

          }
          if (
            (lookupId == 434 || lookupId == 122) &&
            dataSourceID != 4 &&
            searchTest.length >= 3 &&
            checkRegional == false &&
            output.match(regex1)
          ) {
            setShowRegionalDrug(false);
            setRegionalItems([]);
          } else if (searchTest.length < 3) {
            setShowRegionalDrug(false);
            setNoRegionalItems(true);
            setRegionalItems([]);
          }
          if (val + 1 >= currData.length) {
            setHasMore(false);
          }
          if (upDateddata.length >= 100) {
            setSearchFilterStart(val);
            break;
          }
        } catch (e) {
          //console.log((e as Error).message);
        }
      }
    } else {
      setSearchValue("");
      for (let i = 0; i <= 99; i++) {
        if (currData[i]) {
          const selids = selectedData.map((x) => x.Id)
          if(selids.includes(currData[i].ID))
            upDateddata.push({...currData[i],["isChecked"] : true});
          else
            upDateddata.push({...currData[i],["isChecked"] : false});
        }
      }
    }
    setItems([...upDateddata]);
  };

  const fetchRegionalDrug = async(drugIds: string[]) => {
    setRegionalItemsLoading(true);
    setRegionalItems([]);
    const regionalData: ILookupData[] = [];
    try {
      const resp = await apiCall(`/api/getRegionalDrugs/${drugIds}`);
      const selIds = [...selectedDataRef.current || []]?.map((x) => x.Id);
      for (let i = 0; i < resp.data.length; i++) {
        let newSelDat: ILookupData = {
          ID: resp.data[i]["DrugId"],
          Lookup_Item_Parent_ID: 0,
          Parent_ID: 0,
          SortOrder: 0,
          Value: resp.data[i]["DrugName"],
          isChecked: selIds.includes(resp.data[i]["DrugId"]) ? true : false,
          val : ""
        };
        regionalData.push(newSelDat);
      }
    } catch (error) {   
        console.error('Error fetching data:', error);
    }

    setRegionalItems(regionalData);
    setRegionalItemsLoading(false);
  };

  const SelectAll = (checked: boolean) => {
    if (diseaseHierarchy) {
      if (!checked) {
        setChecked([]);
      }
      if (checked) {
        let arr: string[] = [];
        lookupSource.map((element: any) => arr.push(String(element["value"])));
        setChecked(arr);
      }
    } else if (!noRecordFound) {
      if (lookupId == LookupIds.SourceOfComparator && isForm) {
        let selDatasSpl: selDataSpecial[] = [];
        for (let item in items) {
          items[item] = { ...items[item], isChecked: checked };
          if (checked == true) {
            let newSelDat: selDataSpecial = {
              Id: items[item]["val"],
              Value: items[item]["Value"],
            };
            selDatasSpl.push(newSelDat);
          }
        }
        let newData = selDatasSpl.filter((x) => {
          return selectedDataSpecial.every((el) => {
            return el.Value != x.Value;
          });
        });
        if (!checked) {
          setSelectedDataSpecial([]);
          for (let i in currData) {
            currData[i] = { ...currData[i], isChecked: checked };
          }
        } else {
          setSelectedDataSpecial([...selectedDataSpecial, ...newData]);
        }
      }
      else {
        dispatch(setFilterAll(checked));
        let selDatas: selData[] = [];
        for (let item in items) {
          items[item] = { ...items[item], isChecked: checked };
          // items[item]['isChecked'] = checked
          if (checked == true) {
            let newSelDat: selData = {
              Id: items[item]["ID"],
              Value: items[item]["Value"],
            };
            selDatas.push(newSelDat);
          }
        }
        let newData = selDatas.filter((x) => {
          return selectedData.every((el) => {
            return el.Id != x.Id;
          });
        });
        if (!checked) {
          setSelectedData([]);
          for (let i in currData) {
            currData[i] = { ...currData[i], isChecked: checked };
          }
        } else {
          setSelectedData([...selectedData, ...newData]);
        }
      }
    } else if (noRecordFound)
        setSelectedData([]);
  };

  const handleChangeCategory = (e: any, catName: string) => {
    const { value, checked } = e.target;
    if (lookupId == LookupIds.SourceOfComparator) {
      let selDatasSpl: selDataSpecial[] = [];
      for (let item in items) {
        if (items[item].Value.split("~")[1] == catName) {
          items[item] = { ...items[item], isChecked: checked };
          // let catItems = items[item]["Value"];
          // if (catItems.indexOf("~") > 0) {
          //   catItems = catItems.split("~")[0];
          // }
          let newSelDat: selDataSpecial = {
            Id: items[item]["val"],
            Value: items[item]["Value"],
          };
          selDatasSpl.push(newSelDat);
        }
      }
      if (!checked) {
        const checkData = selectedDataSpecial.filter((x) =>
          selDatasSpl.every((y) => x.Value != y.Value)
        );
        setSelectedDataSpecial([...checkData]);
      } else {
        setSelectedDataSpecial([...selectedDataSpecial, ...selDatasSpl]);
      }
    }
    else {
      let selDatas: selData[] = [];
      for (let item in items) {
        if (items[item].Value.split("~")[1] == catName) {
          items[item] = { ...items[item], isChecked: checked };
          let catItems = items[item]["Value"];
          if (catItems.indexOf("~") > 0) {
            if (!(lookupId == LookupIds.ClinicalPositives ||
              lookupId == LookupIds.ClinicalNegatives
            )) {
              catItems = catItems.split("~")[0];
            }
          }
          let newSelDat: selData = {
            Id: items[item]["ID"],
            Value: catItems,
          };
          selDatas.push(newSelDat);
        }
      }
      if (!checked) {
        const checkData = selectedData.filter((x) =>
          selDatas.every((y) => x.Id != y.Id)
        );
        setSelectedData([...checkData]);
      } else {
        setSelectedData([...selectedData, ...selDatas]);
      }
    }
  };

  const handleChange = (e: any, Id: number, val: string, index?: number) => {
    const { value, checked } = e.target;
    if (false) {// (val != null) {
      const values = [...items];
      values[index] = { ...values[index], isChecked: checked };
      setItems([...values]);

      let currVal = items.find((x) => x.val == val);
      let newSelDat: selDataSpecial = {
        Id: val,
        Value: currVal != null ? currVal.Value : "",
      };
      if (checked) {
        let selection = [...selectedDataSpecial, newSelDat];

        setSelectedDataSpecial(selection);
      } else {
        let prevSelected = [...selectedDataSpecial];
        for (let x in prevSelected) {
          if (prevSelected[x]["Value"] == value) {
            prevSelected.splice(Number(x), 1);
          }
        }
        setSelectedDataSpecial(prevSelected);
      }
    } else {
      //let _items = [...items];
      let _items: ILookupData[] = [...items];
      if (lookupKey == "RegulatoryWatch_Drug") {
        // _items.forEach((el) => (el.isChecked = false));
        _items = items.map((el) => {
          return { ...el, isChecked: false };
        });
        const regionalValues = [...regionalItems];
        regionalValues.forEach((el) => {
            el.isChecked = false;
        });
        setRegionalItems([...regionalValues]);
      }
      if (index >= 0 && lookupKey != "CountryIDs") {
        _items[index] = { ..._items[index], isChecked: checked };
      }
      else{
        _items.forEach((el) => {
          if(el.ID === Id){
            el.isChecked = checked;
          }            
        })
      };

      setItems([..._items]);

      let newSelDat: selData = { Id: Id, Value: value };
      if (checked) {
        if (lookupId == LookupIds.SourceOfComparator && isForm) {
          let newSelDat: selDataSpecial = {
            Id: val,
            Value: value,
          };
          setSelectedDataSpecial([...selectedDataSpecial, newSelDat]);
        }
        else {
          let selection = [...selectedData || [], newSelDat];
          if (lookupKey == "RegulatoryWatch_Drug") selection = [newSelDat];
          if (lookupKey === "COA_Endpoints.EndpointType")
            dispatch(setEndpointResultList(selection));
          setSelectedData(selection);
        }
      } else {
        if (lookupId == LookupIds.SourceOfComparator && isForm) {
          let prevSelectedSpecial = [...selectedDataSpecial];
          for (let x in prevSelectedSpecial) {
            if (prevSelectedSpecial[x]["Value"] == value) {
              prevSelectedSpecial.splice(Number(x), 1);
            }
          }
          setSelectedDataSpecial(prevSelectedSpecial);
        }
        else {
          let prevSelected = [...selectedData];
          if(lookupKey != "CountryIDs"){
            for (let x in prevSelected) {
              if (prevSelected[x]["Id"] == Id) {
                prevSelected.splice(Number(x), 1);
              }
            }
          }else{
            for (let x in prevSelected) {
              if (prevSelected[x]["Value"].includes(value.split("~")[0])) {
                prevSelected.splice(Number(x), 1);
              }
            }
          }
          setSelectedData(prevSelected);
          if (lookupKey === "COA_Endpoints.EndpointType")
            dispatch(setEndpointResultList(prevSelected));
        }
      }
    }
  };

  const handleChangeSpecial = (e: any, index: number, Id: string) => {
    const { value, checked } = e.target;

    const values = [...items];
    values[index] = { ...values[index], isChecked: checked };

    setItems([...values]);

    let newSelDat: selDataSpecial = { Id: Id, Value: value };
    if (checked) {
      let selection = [...selectedDataSpecial, newSelDat];
      setSelectedDataSpecial(selection);
    } else {
      let prevSelected = [...selectedDataSpecial];
      for (let x in prevSelected) {
        if (prevSelected[x]["Value"] == value) {
          prevSelected.splice(Number(x), 1);
        }
      }
      setSelectedDataSpecial(prevSelected);
    }
  };

  const handleChangeCombination = () => {
    let combinationDrug: string = "";
    let combinationDrugName: string = "";
    // selectedData.sort((a, b) => a.Value.localeCompare(b.Value));

    for (let x in selectedData) {
      (combinationDrug += selectedData[x].Id + "|"),
        (combinationDrugName += selectedData[x].Value + " + ");
    }
    combinationDrug = combinationDrug.slice(0, -1);
    combinationDrugName = combinationDrugName.slice(0, -3);
    if (selectedDataSpecial.length > 0) {
      selectedDataSpecial.forEach((el) => {
        if (
          el.Id.split("|").sort().join(",") ===
          combinationDrug.split("|").sort().join(",")
        ) {
          SelectAll(false);
          setOpenCombinationExist(true);
          throw new Error(
            "This is not an error. This is just to abort javascript"
          );
        }
      });
    }
    let newSelDat: selDataSpecial = {
      Id: combinationDrug,
      Value: combinationDrugName,
    };
    setSelectedDataSpecial([...selectedDataSpecial, newSelDat]);
    SelectAll(false);
    setSelectedData([]);
  };

  const handleChangeRegional = (e: any, index: number, Id: number) => {
    const { value, checked } = e.target;
    const regionalValues = [...regionalItems];
    regionalValues.forEach((el) => {
      if(el.ID == Id){
        el.isChecked = checked;
      }       
      else if( lookupKey == "RegulatoryWatch_Drug")
        el.isChecked = false;
    });
    setRegionalItems([...regionalValues]);
    if( lookupKey == "RegulatoryWatch_Drug"){      
      const _items = [...items];
      _items.forEach((el) => el.isChecked = false);
      setItems(_items);
    }
    let newSelDat: selData = { Id: Id, Value: value };
    if (checked && lookupKey == "RegulatoryWatch_Drug") {
      setSelectedData([newSelDat]);
    }else if(checked){
      setSelectedData([...selectedData,newSelDat]);
    } else {
      let prevSelected = [...selectedData];
      for (let x in prevSelected) {
        if (prevSelected[x]["Value"] == value) {
          prevSelected.splice(Number(x), 1);
        }
      }
      setSelectedData(prevSelected);
    }
  };

  const PopulateBottomUpDiseaseData = (
    fieldName: string,
    diseaseIds: number[],
    selectedLIGolobe: any,
    diseaseInfo?: any,
    superSearchField?: string,
    checkedValues?: number[],
  ) => {
    let diseaseData: any[] = [];
    let currLevel = "";
    let dsName = "";
    let subType4FieldName = "";
    let subType3FieldName = "";
    let subType2FieldName = "";
    let subType1FieldName = "";
    let diseaseFieldName = "";
    let taFieldName = "";
    let parentData = selectedLookupGlobe[fieldName];
    if (diseaseInfo != null && (superSearchField == null || superSearchField == "")) {
      dsName =
        fieldName.split("||")[1] == "49"
          ? "HTARecord"
          : fieldName.split("||")[1] == "122"
          ? "ClinicalData"
          : fieldName.split("||")[1] == "184"
          ? "Agreement"
          : "";
      subType4FieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryDiseaseSubtype4"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryDiseaseSubtype4"
          : dsName + "_DiseaseSubtype4";
      subType3FieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryDiseaseSubtype3"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryDiseaseSubtype3"
          : dsName + "_DiseaseSubtype3";
      subType2FieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryDiseaseSubtype2"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryDiseaseSubtype2"
          : dsName + "_DiseaseSubtype2";
      subType1FieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryDiseaseSubtype"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryDiseaseSubtype"
          : dsName + "_DiseaseSubtype";
      diseaseFieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryDisease_ID"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryDisease_ID"
          : dsName + "_Disease_ID";
      taFieldName =
        fieldName.startsWith("Primary") && dsName == "HTARecord"
          ? dsName + "_PrimaryTherapeuticArea"
          : fieldName.startsWith("Secondary") && dsName == "HTARecord"
          ? dsName + "_SecondaryTherapeuticArea"
          : dsName + "_TherapeuticArea";
      parentData = [];
    } else {
      dsName = fieldName.split("_")[0];
      subType3FieldName = dsName + "_DiseaseSubtype3";
      subType2FieldName = dsName + "_DiseaseSubtype2";
      subType1FieldName = dsName + "_DiseaseSubtype";
      diseaseFieldName = dsName + "_Disease_ID";
      taFieldName = dsName + "_TherapeuticArea";
      if (
        dsName.toLowerCase() == "htarecord" &&
        fieldName.indexOf("Primary") > 0
      ) {
        subType3FieldName = dsName + "_PrimaryDiseaseSubtype3";
        subType2FieldName = dsName + "_PrimaryDiseaseSubtype2";
        subType1FieldName = dsName + "_PrimaryDiseaseSubtype";
        diseaseFieldName = dsName + "_PrimaryDisease_ID";
        taFieldName = dsName + "_PrimaryTherapeuticArea";
      } else if (
        dsName.toLowerCase() == "htarecord" &&
        fieldName.indexOf("Secondary") > 0
      ) {
        subType3FieldName = dsName + "_SecondaryDiseaseSubtype3";
        subType2FieldName = dsName + "_SecondaryDiseaseSubtype2";
        subType1FieldName = dsName + "_SecondaryDiseaseSubtype";
        diseaseFieldName = dsName + "_SecondaryDisease_ID";
        taFieldName = dsName + "_SecondaryTherapeuticArea";
      }
      currLevel =
        fieldName.indexOf("DiseaseSubtype3") != -1
          ? "DS3"
          : fieldName.indexOf("DiseaseSubtype2") != -1
          ? "DS2"
          : fieldName.indexOf("Disease_ID") != -1
          ? "D"
          : "DS1";
      let parentLevel = "TA";
      switch (currLevel) {
        case "DS4":
          parentData = selectedLookupGlobe[subType3FieldName];
          parentLevel = "DS3";
          break;
        case "DS3":
          parentData = selectedLookupGlobe[subType2FieldName];
          parentLevel = "DS2";
          break;
        case "DS2":
          parentData = selectedLookupGlobe[subType1FieldName];
          parentLevel = "DS1";
          break;
        case "DS1":
          parentData = selectedLookupGlobe[diseaseFieldName];
          parentLevel = "D";
          break;
        default:
          parentData = selectedLookupGlobe[taFieldName];
      }
      let tempSuperSearchField: IselectedLookup[] = [];
      let filteredValues = lookupSource.filter((x) => diseaseInfo.map((y) => y.Id).includes(x.value));
      if (selectedLookupGlobe[superSearchField] != undefined) {
        filteredValues.forEach((q) => {
          if(selectedLookupGlobe[superSearchField].map((y) => y.Id).includes(q.ParentID)) {
            tempSuperSearchField = selectedLookupGlobe[superSearchField].filter((t) => t.Id != q.ParentID);
            tempSuperSearchField.push({Id: q.value, Value: q.label});
          }
        });
      }
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [superSearchField]: tempSuperSearchField,
      };
      dispatch(setSelectedLookupGlobe(selectedLIGolobe));
    }
    if (checkedValues?.length == 0 && fieldName.includes('Autocompelete_ID')) {
      selectedLIGolobe = { 
        ...selectedLIGolobe,
        [taFieldName]: [] };
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [diseaseFieldName]: [],
      };
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [subType1FieldName]: [],
      };
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [subType2FieldName]: [],
      };
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [subType3FieldName]: [],
      };
      selectedLIGolobe = {
        ...selectedLIGolobe,
        [subType4FieldName]: [],
      };
      dispatch(setSelectedLookupGlobe(selectedLIGolobe));
    } else {
    if (parentData == undefined || parentData.length == 0) {
      let diseaseIdsString = diseaseIds.join(",");
      apiCall(
        `/api/GetDiseaseHierarchyForSmartSearch/${diseaseIdsString}`
      ).then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].forEach((element) => {
            diseaseData.push(element);
          });
        }
        diseaseData = diseaseData.filter(
          (v, i, a) => a.findIndex((v2) => (v2.ID === v.ID && v2.Level === v.Level)) === i
        );
        const level_zero = diseaseData.filter((x) => x.Level == 0);
        const level_one = diseaseData.filter((x) => x.Level == 1);
        const level_two = diseaseData.filter((x) => x.Level == 2);
        const level_three = diseaseData.filter((x) => x.Level == 3);
        const level_four = diseaseData.filter((x) => x.Level == 4);
        const level_five = diseaseData.filter((x) => x.Level == 5);

        if (level_five.length > 0) {
          if (fieldName.toLowerCase().indexOf("autocompelete") > 0) {
            let newSelectedDataDependent4: IselectedLookup[] = level_five.map(
              (arr) => ({
                Id: arr["ID"],
                Value: arr["Name"],
              })
            );
            selectedLIGolobe = {
              ...selectedLIGolobe,
              [subType4FieldName]: newSelectedDataDependent4,
            };
          }
          let newSelectedDataDependent3: IselectedLookup[] = level_four.map(
            (arr) => ({
              Id: arr["ID"],
              Value: arr["Name"],
            })
          );
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [subType3FieldName]: newSelectedDataDependent3,
          };
          let newSelectedDataDependent2: IselectedLookup[] = level_three.map(
            (arr) => ({
              Id: arr["ID"],
              Value: arr["Name"],
            })
          );
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [subType2FieldName]: newSelectedDataDependent2,
          };
          let newSelectedDataDependent1: IselectedLookup[] = level_two.map(
            (arr) => ({
              Id: arr["ID"],
              Value: arr["Name"],
            })
          );
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [subType1FieldName]: newSelectedDataDependent1,
          };
          let newSelectedDataDependent0: IselectedLookup[] = level_one.map(
            (arr) => ({
              Id: arr["ID"],
              Value: arr["Name"],
            })
          );
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [diseaseFieldName]: newSelectedDataDependent0,
          };
          let newSelectedDataDependentTA: IselectedLookup[] = level_zero.map(
            (arr) => ({
              Id: arr["ID"],
              Value: arr["Name"],
            })
          );
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [taFieldName]: newSelectedDataDependentTA,
          };
        } else {
          if (level_four.length > 0) {
            if (fieldName.toLowerCase().indexOf("autocompelete") > 0) {
              let newSelectedDataDependent3: IselectedLookup[] = level_four.map(
                (arr) => ({
                  Id: arr["ID"],
                  Value: arr["Name"],
                })
              );
              selectedLIGolobe = {
                ...selectedLIGolobe,
                [subType3FieldName]: newSelectedDataDependent3,
              };
            }
            let newSelectedDataDependent2: IselectedLookup[] = level_three.map(
              (arr) => ({
                Id: arr["ID"],
                Value: arr["Name"],
              })
            );
            selectedLIGolobe = {
              ...selectedLIGolobe,
              [subType2FieldName]: newSelectedDataDependent2,
            };
            let newSelectedDataDependent1: IselectedLookup[] = level_two.map(
              (arr) => ({
                Id: arr["ID"],
                Value: arr["Name"],
              })
            );
            selectedLIGolobe = {
              ...selectedLIGolobe,
              [subType1FieldName]: newSelectedDataDependent1,
            };
            let newSelectedDataDependent0: IselectedLookup[] = level_one.map(
              (arr) => ({
                Id: arr["ID"],
                Value: arr["Name"],
              })
            );
            selectedLIGolobe = {
              ...selectedLIGolobe,
              [diseaseFieldName]: newSelectedDataDependent0,
            };
            let newSelectedDataDependentTA: IselectedLookup[] = level_zero.map(
              (arr) => ({
                Id: arr["ID"],
                Value: arr["Name"],
              })
            );
            selectedLIGolobe = {
              ...selectedLIGolobe,
              [taFieldName]: newSelectedDataDependentTA,
            };
          } else {
            if (level_three.length > 0) {
              if (fieldName.toLowerCase().indexOf("autocompelete") > 0) {
                let newSelectedDataDependent2: IselectedLookup[] =
                  level_three.map((arr) => ({
                    Id: arr["ID"],
                    Value: arr["Name"],
                  }));
                selectedLIGolobe = {
                  ...selectedLIGolobe,
                  [subType2FieldName]: newSelectedDataDependent2,
                };
              }
              let newSelectedDataDependent1: IselectedLookup[] = level_two.map(
                (arr) => ({
                  Id: arr["ID"],
                  Value: arr["Name"],
                })
              );
              selectedLIGolobe = {
                ...selectedLIGolobe,
                [subType1FieldName]: newSelectedDataDependent1,
              };
              let newSelectedDataDependent0: IselectedLookup[] = level_one.map(
                (arr) => ({
                  Id: arr["ID"],
                  Value: arr["Name"],
                })
              );
              selectedLIGolobe = {
                ...selectedLIGolobe,
                [diseaseFieldName]: newSelectedDataDependent0,
              };
              let newSelectedDataDependentTA: IselectedLookup[] =
                level_zero.map((arr) => ({
                  Id: arr["ID"],
                  Value: arr["Name"],
                }));
              selectedLIGolobe = {
                ...selectedLIGolobe,
                [taFieldName]: newSelectedDataDependentTA,
              };
            } else {
              if (level_two.length > 0) {
                if (fieldName.toLowerCase().indexOf("autocompelete") > 0) {
                  let newSelectedDataDependent1: IselectedLookup[] =
                    level_two.map((arr) => ({
                      Id: arr["ID"],
                      Value: arr["Name"],
                    }));
                  selectedLIGolobe = {
                    ...selectedLIGolobe,
                    [subType1FieldName]: newSelectedDataDependent1,
                  };
                }
                let newSelectedDataDependent0: IselectedLookup[] =
                  level_one.map((arr) => ({
                    Id: arr["ID"],
                    Value: arr["Name"],
                  }));
                selectedLIGolobe = {
                  ...selectedLIGolobe,
                  [diseaseFieldName]: newSelectedDataDependent0,
                };
                let newSelectedDataDependentTA: IselectedLookup[] =
                  level_zero.map((arr) => ({
                    Id: arr["ID"],
                    Value: arr["Name"],
                  }));
                selectedLIGolobe = {
                  ...selectedLIGolobe,
                  [taFieldName]: newSelectedDataDependentTA,
                };
              } else {
                if (level_one.length > 0) {
                  if (fieldName.toLowerCase().indexOf("autocompelete") > 0) {
                    let newSelectedDataDependent0: IselectedLookup[] =
                      level_one.map((arr) => ({
                        Id: arr["ID"],
                        Value: arr["Name"],
                      }));
                    selectedLIGolobe = {
                      ...selectedLIGolobe,
                      [diseaseFieldName]: newSelectedDataDependent0,
                    };
                  }
                  let newSelectedDataDependentTA: IselectedLookup[] =
                    level_zero.map((arr) => ({
                      Id: arr["ID"],
                      Value: arr["Name"],
                    }));
                  selectedLIGolobe = {
                    ...selectedLIGolobe,
                    [taFieldName]: newSelectedDataDependentTA,
                  };
                }
              }
            }
          }
        }
        if (superSearchField != "" && superSearchField != null) {
          selectedLIGolobe = {
            ...selectedLIGolobe,
            [superSearchField]: diseaseInfo
          }
        }
        dispatch(setSelectedLookupGlobe(selectedLIGolobe));
      });
    }
  } 
  };

  const showSelectedFunc = (x: boolean) => {
    setShowSelected(x);
  };
  let categoryName = "";
  let categoryArray: any = [];

  const findItem = (items: any, property: string) => {
    const item = items.find((x: any) => x.Value == property);
    const ids : number[] = items.map((x:any,i:number) => x.Id);
    const itemIndex = ids.indexOf(item.Id);
    let li_item = item.Value;
    return (
      <div className="form-check" style={{ padding: "20px 0" }}>
        <input
          type="checkbox"
          style={{
            color: "#005486",
            display: item.isChecked ? "inline" : "none",
            border: "0px",
          }}
          name="languages"
          defaultValue={li_item}
          checked={item.isChecked}
          id={li_item}
          key={item.ID + li_item}
          onChange={(e) => handleChange(e, item.ID, item.val)}
        />
        <label
          className="ms-item"
        
          htmlFor={li_item}
          key={li_item + "_"}
        >
          {li_item}
        </label>
      </div>
    );
  };

  const addMsValueFromPopup = async() => {
    const res = await apiCall(
      `/api/lookup/addLookupItem/${lookupId}/${searchValue}`
    );
    const newItem : IselectedLookup  = {Id : res.data[0].id , Value : searchValue};
    const newlookup : ILookupData = {
      ID: res.data[0].id,
      Parent_ID : 0,
      SortOrder : 0,
      Lookup_Item_Parent_ID  : 0,
      Value : searchValue,
      isChecked : true,
      val : ""
    };    
    let currSelectedData : IselectedLookup[] = selectedData || [];
    if(!currSelectedData)
      currSelectedData = [];
    currSelectedData = [...currSelectedData,newItem];
    setSelectedData(currSelectedData);
    let currlookupData = [...lookupGlobe[lookupKey]] || [];
    currlookupData = [...currlookupData,newlookup];
    let lg = { ...lookupGlobe,[lookupKey]:currlookupData };                             
    dispatch(setLookupGlobe(lg));
    setSearchValue("");  
  }

  const checkDrugDeviceProcedure = () => {
    let datasource = "HTARecord";
    switch (Number(dataSourceID)) {
      case 2:
        datasource = "ClinicalData";
        break;
      case 3:
        datasource = "RegulatoryWatch";
        break;
      default:
        datasource = "HTARecord";
        break;
    }
    const _Drug = selectedLookupGlobe[`${datasource}_Drug`] || [];
    const _Device = selectedLookupGlobe[`${datasource}_Device`] || [];
    const _Procedure = selectedLookupGlobe[`${datasource}_ProcedureIntervention`] || [];

    let _DrugAdditional = [];
    let _DeviceAdditional = [];
    let _ProcedureAdditional = [];

    if (Number(dataSourceID) == 1) {
      _DrugAdditional =
        selectedLookupGlobe[`${datasource}_DrugComparator`] || [];
      _DeviceAdditional =
        selectedLookupGlobe[`${datasource}_DeviceComparator`] || [];
      _ProcedureAdditional =
        selectedLookupGlobe[`${datasource}_ProcedureInterventionComparator`] || [];
    } else if (Number(dataSourceID) == 2) {
      _DrugAdditional = selectedLookupGlobe[`${datasource}_DrugControl`] || [];
      _DeviceAdditional =
        selectedLookupGlobe[`${datasource}_DeviceControl`] || [];
      _ProcedureAdditional =
        selectedLookupGlobe[`${datasource}_ProcedureControl`] || [];
    }

    if (
      _Drug.length == 0 &&
      _Device.length == 0 &&
      _Procedure.length == 0 &&
      _DrugAdditional.length == 0 &&
      _DeviceAdditional.length == 0 &&
      _ProcedureAdditional.length == 0
    )
      return false;

    return true;
  };

  const saveSelections = () => {
    ////For filtering unique data from the selectedData.
    let uniqueSelectedData = selectedData?.filter((obj, index) => {
      return (
        obj.Id != null && index === selectedData.findIndex((o) => obj.Id === o.Id)
      );
    }) || [];
    dispatch(setSelectedDataState(uniqueSelectedData));
    dispatch(setSelectedDataStateSpecial(selectedDataSpecial));
    setRegionalItems([]);
    let newSelectedData: IselectedLookup[] =
      uniqueSelectedData.map((arr) => ({
        Id: arr.Id,
        Value: arr.Value.indexOf("~") > 0 && ![LookupIds.ClinicalNegatives,LookupIds.ClinicalPositives].includes(lookupId) ? arr.Value.split("~")[0] : arr.Value, //Fix for Approval date MS selection showing ~ in the form
      }));
    let newSelectedDataSpecial: IselectedLookupSpecial[] =
      selectedDataSpecial.map((arr) => ({
        Id: arr.Id,
        Value: arr.Value,
      }));
    let newSelectedglobeData = {
      ...selectedLookupGlobe,
      [lookupKey]: newSelectedData,
    };
    // let newSelectedglobeDataDbVal = {
    //   ...selectedLookupGlobe,
    //   [lookupKey]: newSelectedData,
    // };
    if (
      lookupId == LookupIds.HTA_Drug_Combination ||
      (lookupId == LookupIds.SourceOfComparator && isForm) ||
      lookupKey.includes(
        "RegulatoryWatch_ApprovedIndication_Combination_Item"
      )
    ) {
      newSelectedglobeData = {
        ...selectedLookupGlobe,
        [lookupKey]: [],
      };
      let newSelectedglobeDataSpecial = {
        ...selectedLookupGlobeSpecial,
        [lookupKey]: newSelectedDataSpecial,
      };
      dispatch(
        setSelectedLookupGlobeSpecial(newSelectedglobeDataSpecial)
      );
      setSelectedDataSpecial([]);
    }
    // const m_keys = Object.keys(newSelectedglobeData);
    // dispatch(setSelectedLookupGlobeDbVal(newSelectedglobeDataDbVal));

    if(lookupKey == "ClientIds")
          {
            delete newSelectedglobeData.ClientGroupIds;
            delete newSelectedglobeData.UserIds;

            let lookupGlobeCopy = {...lookupGlobe};
            delete lookupGlobeCopy.ClientGroupIds;
            delete lookupGlobeCopy.UserIds;
            dispatch(setLookupGlobe(lookupGlobeCopy));
          } 
    if (
      lookupKey.toLocaleLowerCase().includes("endpointtype.endpointid") &&
      Number(dataSourceID) != 3
    ) {
      if (checkDrugDeviceProcedure())
        dispatch(setSelectedLookupGlobe(newSelectedglobeData));
      else {
        // console.log("Please choose a drug/device/procedure first");
        // toastr.info("Please choose a drug/device/procedure first", "title", infoOptions);
        // const showWarning = userContext.onClick;
        // showWarning(
        //   "warning",
        //   "Please choose a drug/device/procedure first...",
        //   '<br/> <button style = "border-radius: 8px;background-color: orange;border: 1px solid white;color: #fff;float:right;">Ignore </button>'
        // );
        alert("Please choose a drug/device/procedure first...");
      }
    } else {
      dispatch(setSelectedLookupGlobe(newSelectedglobeData));
    }

    if (diseaseHierarchy) {
      let newnodes = lookupSource.filter(
        (e: any) =>
          checkedValues.includes(e["value"]) &&
          !checkedValues.includes(e["ParentID"])
      );
      if (
        lookupKey.indexOf("PrimaryAutocompelete_ID") == 0 ||
        lookupKey.indexOf("SecondaryAutocompelete_ID") == 0
      ) {
        const selectedDiseaseIds = newnodes.map(
          (element: any) => {
            return element.value;
          }
        );
        let selectedDiseaseData = newnodes.map((element: any) => {
          return {
            Id: element["value"],
            Value: element["label"],
          };
        });
        let selectedDiseaseData1 = newnodes.map(
          (element: any) => {
            return {
              Id: element["value"],
              Level: diseaseLevel[element["Level"]],
            };
          }
        );
        let newSelectedDiseaseGlobeData = {
          ...selectedLookupGlobe,
          [lookupKey]: selectedDiseaseData,
        };
        dispatch(
          setSelectedLookupGlobe(newSelectedDiseaseGlobeData)
        );
        PopulateBottomUpDiseaseData(
          lookupKey,
          selectedDiseaseIds,
          newSelectedDiseaseGlobeData,
          selectedDiseaseData1,
          '',
          checkedValues
        );
      } else {
        let selecteddata = newnodes.map((element: any) => {
          const getChildCount = (val: number, curr: number) => {
            return checkedValues.includes(curr) ? val + 1 : val;
          };
          const res = findChild(element["label"], nodes);
          const cids = findChildIds(res);
          const el_count = cids.reduce(getChildCount, 0);
          const label =
            el_count > 0
              ? `[${diseaseLevel[element["Level"]]}] ${
                  element["label"]
                } (${el_count})`
              : `[${diseaseLevel[element["Level"]]}] ${
                  element["label"]
                }`;
          return {
            Id: element["value"],
            Value: label,
          };
        });
        if (
          lookupKey == "ReimbursementStatus_Disease" ||
          lookupKey.includes(
            "RegulatoryWatch_ApprovedIndication_Disease"
          )
        ) {
          const t_ids: number[] = nodes.map((x: any) => x.value);
          selecteddata = selecteddata.filter(
            (el: any) => !t_ids.includes(el.Id)
          );
        }
        let newSelectedglobeData = {
          ...selectedLookupGlobe,
          [lookupKey]: selecteddata,
        };
        dispatch(setSelectedLookupGlobe(newSelectedglobeData));

        // if (selecteddata.length > 0) setShowSelected(true);
        // else setShowSelected(false);
      }
    } else if (policyCategory) {
      let newnodes = lookupSource.filter(
        (e: any) =>
          checkedValues.includes(e["value"]) &&
          !checkedValues.includes(e["ParentID"])
      );
      let selectedDiseaseData = newnodes.map((element: any) => {
        return {
          Id: element["value"],
          Value: element["label"],
        };
      });
      let newSelectedDiseaseGlobeData = {
        ...selectedLookupGlobe,
        [lookupKey]: selectedDiseaseData,
      };
      dispatch(
        setSelectedLookupGlobe(newSelectedDiseaseGlobeData)
      );
      if (selectedDiseaseData.length > 0) setShowSelected(true);
      else setShowSelected(false);
    } else {
      let newlookupG = { ...lookupGlobe };
      let selectedLGSpecial = { ...selectedLookupGlobeSpecial };
      //Filling combination drug data start
      // let depField = "";
      if (lookupId === 434) {
        let depField = GetDependentFieldName(lookupKey);
        // depField.then((result) => {
        if (depField != "") {
          var drugArr = uniqueSelectedData.map(function (e) {
            return e.Id;
          }).join(',');
          // let drugCorp = GetCorporationForDrug(uniqueSelectedData.map(function (e) {return e.Id;}).join(','));
          let newData: ILookupData[] = uniqueSelectedData.map(
            (row: any) => ({
              ID: row["Id"],
              Lookup_Item_Parent_ID: 0,
              Parent_ID: 0,
              SortOrder: 0,
              Value: row["Value"],
              isChecked: false,
              val: "",
            })
          );
          newlookupG = {
            ...newlookupG,
            [String(depField)]: newData,
          };
          selectedLGSpecial = {
            ...selectedLGSpecial,
            [String(depField)]: [],
          }
          dispatch(setSelectedLookupGlobeSpecial(selectedLGSpecial));
          setSelectedDataSpecial([]);
        }
        // );
      }
      //Filling combination drug data end
      // let newData = { ...newlookupG, [lookupKey]: items };

      if (lookupKey.indexOf("Disease") != -1) {
        let superSearchField = "";
        let newSelectedData: IselectedLookup[] =
          uniqueSelectedData.map((arr) => ({
            Id: arr.Id,
            Value: arr.Value,
          }));
        let newSelectedglobeDataDis = {
          ...selectedLookupGlobe,
          [lookupKey]: newSelectedData,
        };
        const diseaseIds = uniqueSelectedData.map((i) => {
          return i.Id;
        });
        if (lookupKey.indexOf("HTARecord_Primary") >= 0) {
          superSearchField = "PrimaryAutocompelete_ID||49";
        } else if (lookupKey.indexOf("HTARecord_Secondary") >= 0) {
          superSearchField = "SecondaryAutocompelete_ID||49";
        } else if (lookupKey.indexOf("ClinicalData_Disease") >= 0) {
          superSearchField = "PrimaryAutocompelete_ID||122";
        } else if (lookupKey.indexOf("Agreement_Disease") >= 0) {
          superSearchField = "PrimaryAutocompelete_ID||184";
        }
        newSelectedglobeDataDis = {
          ...newSelectedglobeDataDis,
          [superSearchField]: newSelectedData,
        };
        dispatch(setSelectedLookupGlobe(newSelectedglobeDataDis));
        PopulateBottomUpDiseaseData(
          lookupKey,
          diseaseIds,
          newSelectedglobeDataDis,
          newSelectedData,
          superSearchField
        );
      }

      let keys = ["CountryIDs", "DrugIDs", "PackSizes", "ProductDosageIDs"];
      if (keys.includes(lookupKey)) {
        keys = keys.filter((x) => x != lookupKey);
        keys.forEach((el) => (newlookupG[el] = []));
      }
      let newData = { ...newlookupG };
      if ([LookupIds.DS_InternalProjectCode, 
        LookupIds.ActiveSubstance,
        LookupIds.InCountryRegion,
        LookupIds.RegionalBrandName,
        LookupIds.ReimbursementCondition,
        LookupIds.AgreementBenefitForPayer,
        LookupIds.AgreementBenefitForManufacturer,
        LookupIds.AgreementOutcomesTracked,
        LookupIds.AgreementPatientsIncluded].includes(lookupId)) {
          const newLookup = newData[lookupKey].map((x) => {
            return {
              ...x,
              isChecked: false,
            }; 
          });
          newData = {...newData, [lookupKey]: newLookup }
        }
      if(lookupKey == "ClientIds")
      {
        delete newData.ClientGroupIds;
        delete newData.UserIds;
      }
      
      if(lookupKey == "client_Id"){
        delete newData.ClientGroup_Id;
        delete newData.User_Id;
      }
      dispatch(setLookupGlobe(newData));
    }
    dispatch(setFilterAll(false));
    dispatch(setModalShow(false));
    setPending(true);
    setCheckRegional(false);
    setFilter("All");
    dispatch(setNoRecordFound(false));
  }

  return (
    <>
      {modalShow && (
        <Modal
          dialogClassName="dialog-modal"
          contentClassName="my-modal"
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header
            style={{
              backgroundColor: "#FAFAFA",
              height: "70px",
              minHeight: "70px",
            }}
          >
            <Modal.Title>
              {" "}
              <div
                id="modal"
                style={{
                  backgroundColor: "#FAFAFA",
                  display: "flex",
                  overflow: "hidden",
                  marginBottom: lookupKey == "Agency_Implication"? "" : "10px",
                  width: "100%",
                }}
              >
                <h4
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    float: "left",
                    fontWeight:"bold",
                    fontSize:"18px"
                  }}
                >
                  {title}
                </h4>
                {!diseaseHierarchy && (
                  <>
                    <input
                      style={{
                        width: "26%",
                        height: "32px",
                        marginTop: "6px",
                        marginLeft: "7px",
                        float: "left",
                        background: "white",
                        fontSize: "13px",
                        border: "1px solid rgb(213, 213, 213)",
                        padding: "9px",
                      }}
                      value={searchValue}
                      placeholder="Search terms"
                      onChange={(e) => {
                        OnSearch(e);
                      }}
                    />
                    <FontAwesomeIcon
                      style={{
                        paddingTop: "19px",
                        paddingLeft: "9px",
                        fontSize: "11px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSearchValue("");
                        let NewMydataCopy = [...currData];
                        const selectedIds = selectedData.map((x) => x.Id);
                        let upDateddata = [];
                        for (let i = 0; i < lookupLength; i++) {
                          if (
                            selectedIds.includes(Number(NewMydataCopy[i]["ID"]))
                          ) {
                            upDateddata.push({
                              ...NewMydataCopy[i],
                              isChecked: true,
                            });
                          } else {
                            upDateddata.push(NewMydataCopy[i]);
                          }
                        }
                        setItems(upDateddata);
                      }}
                      icon={faArrowsRotate}
                    />
                  </>
                )}
                {[LookupIds.DS_InternalProjectCode, 
                  LookupIds.ActiveSubstance,
                  LookupIds.InCountryRegion,
                  LookupIds.RegionalBrandName,
                  LookupIds.ReimbursementCondition,
                  LookupIds.AgreementBenefitForPayer,
                  LookupIds.AgreementBenefitForManufacturer,
                  LookupIds.AgreementOutcomesTracked,
                  LookupIds.AgreementPatientsIncluded].includes(lookupId) &&
                  isForm &&
                  items.length == 0 &&
                  searchValue.length > 2 && (
                    <div>
                      <FontAwesomeIcon
                        style={{
                          paddingTop: "16px",
                          marginLeft: "15px",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => addMsValueFromPopup()}
                        icon={faPlus}
                      />
                    </div>
                  )}
                {drugModal && ds_id != 4 && (
                  <>
                    <input
                      type="checkbox"
                      style={{
                        color: "#393939",
                        display: "inline",
                        border: "0px",
                        marginLeft: "1.5em",
                        marginTop: "2px",
                        marginRight: "3px",
                      }}
                      checked={checkRegional}
                      name="regionalBrandName"
                      key="regionalBrand"
                      onChange={() => {
                        setCheckRegional(!checkRegional);
                      }}
                    />
                    &nbsp;
                    <span
                      style={{
                        color: "gray",
                        display: "inline",
                        fontSize: "12px",
                        marginLeft: "-4px",
                      }}
                      key="regionalBrandLabel"
                    >
                      Include regional brands
                    </span>
                  </>
                )}
                {diseaseHierarchy && (
                  <h5
                    style={{
                      color: "silver",
                      display: "inline",
                      marginLeft: "30px",
                      fontSize: "14px",
                    }}
                  >
                    (Results shown in hierarchy, from Therapeutic area to
                    Disease subtype 4)
                  </h5>
                )}

                <button
                  style={{
                    background: "rgb(250, 250, 250)",
                    marginTop: "5px",
                    marginRight: "10px",
                    float: "right",
                    border: 0,
                    marginLeft: "auto"
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon className="Close-icon" />
                </button>
              </div>
            </Modal.Title>
            {/* <CloseIcon onClick={handleClose} />
             */}
          </Modal.Header>
          {!diseaseHierarchy && !policyCategory && (
            <Modal.Body>
              {!noRecordFound && (
                <div style={{ padding: "1%", textAlign: "center" }}>
                  {alphabet.map((item, index) => (
                    <ButtonComponent
                      style={{ paddingRight: "5px" }}
                      text={item}
                      key={item}
                    />
                  ))}
                  <hr style={{ marginTop: "0px", width: "830px",borderTop: "1px solid silver" }} />
                </div>
              )}

              {!pending && (
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<h4></h4>}
                  height={400}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b></b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <div
                    className="row"
                  >
                    {
                      items &&
                      items.length > 0 &&
                      items.map((item: any, index: number) => {
                        let showCategory = false;
                        let li_item = item.Value;
                        let catIsCheck: boolean = false;
                        let categoryItems = items.filter(
                          (x) =>
                            x.Value?.split("~")[1] == item.Value?.split("~")[1]
                        );
                        catIsCheck = categoryItems.every((el) => {
                          return el.isChecked == true;
                        });
                        if (item.Value && item.Value.indexOf("~") > 0 && 
                          ![LookupIds.DS_DrugGeneric, 
                          LookupIds.DS_DrugGenericNonReviewed].includes(lookupId)) 
                          {
                          categoryName = item.Value.split("~")[1];
                          categoryArray.push(item.Value.split("~")[1]);
                          if (
                            categoryArray.includes(categoryName) &&
                            categoryArray.filter((i) => i == categoryName)
                              .length == 1
                          ) {
                            showCategory = true;
                          }
                          li_item = item.Value.split("~")[0];
                        }
                        return (
                          <React.Fragment key={item.ID}>
                            {showCategory && (
                              <div
                                className="col-md-12"
                                style={{ paddingLeft: "4.5em", paddingBottom: "1em" }}
                              >
                                {lookupKey !== "DrugData_Characteristics" &&
                                <input
                                  type="checkbox"
                                  checked={catIsCheck}
                                  onChange={(e) =>
                                    handleChangeCategory(
                                      e,
                                      item.Value.split("~")[1]
                                    )
                                  }
                                />
                                }
                                <span>{categoryName}</span>
                              </div>
                            )}
                            <div
                              className="col-md-4"
                              id={item.ID}
                              style={{ paddingLeft: "4em" }}
                            >
                              <div
                                className="form-check"
                                style={{
                                  padding: "5px",
                                  lineHeight: "20px",
                                  marginBottom: "20px",
                                }}
                                //style={{ padding: "20px" }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    color: "#005486",
                                    display: item.isChecked ? "inline" : "none",
                                    border: "0px",
                                  }}
                                  name="languages"
                                  defaultValue={item.Value}
                                  checked={item.isChecked}
                                  id={String(item.ID)+ item.Value}
                                  onChange={(e) =>
                                    handleChange(e, item.ID, item.val, index)
                                  }
                                />
                                <label
                                  className="ms-item"
                                  htmlFor={String(item.ID)+ item.Value}
                                  style={{ display: "contents" }}
                                  title={lookupId === LookupIds.ApprovedRegulatoryInfo ? item.Value.split('~').pop() : item.Value}
                                 
                                  // dangerouslySetInnerHTML={{__html: li_item}}
                                >
                                  {lookupKey == "HTARecord_CoaInstrument"
                                    ? Parser(
                                        String(li_item)
                                          .split("||")
                                          .map((el, check) =>
                                            check == 0
                                              ? "<b>" + el + "</b>"
                                              : el
                                          )
                                          .slice(0, 2)
                                          .join(" || ")
                                      )
                                    : li_item}
                                  {/* {li_item} */}
                                </label>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    {/* {lookupKey === "DrugData_Characteristics" && (
                      <div>{displayCharacteristic(items)}</div>
                    )} */}
                  </div>
                </InfiniteScroll>
              )}
              {showRegionalDrug && (
                <>
                  <span style={{ marginLeft: "45px" }}>
                    Below is a list of regional brands which you might want to
                    consider for your selection
                  </span><br/><br/>
                  <div className="row">
                    {regionalItemsLoading == true && (
                      <div style={{ paddingLeft: "480px" }}>
                        <Spinner
                          style={{ alignContent: "center" }}
                          size="sm"
                          variant="Info"
                        />
                        <span style={{ padding: "5px", color: "black" }}>
                          Loading
                        </span>{" "}
                      </div>
                    )}
                    {noRegionalItems && regionalItemsLoading == false && (
                      <span>There is no matching item to show</span>
                    )}
                  </div>
                  <div className="row" style={{marginLeft: "1em"}}>
                    {regionalItemsLoading == false &&
                      regionalItems &&
                      regionalItems.length > 0 &&
                      regionalItems.map((item1: any, index1: number) => {
                        return (
                          <div className="col-md-4" key={item1.ID}>
                            <div
                              className="form-check"
                              //style={{  padding: "5px",lineHeight:"20px",marginBottom:"20px" }}
                              //style={{  padding: "20px"}}
                            >
                              <input
                                type="checkbox"
                                style={{
                                  color: "#005486",
                                  display: item1.isChecked ? "inline" : "none",
                                  border: "0px",
                                }}
                                name="languages"
                                defaultValue={item1.Value}
                                checked={item1.isChecked}
                                id={item1.Value}
                                key={item1.ID + item1.Value}
                                onChange={(e) =>
                                  handleChangeRegional(e, index1, item1.ID)
                                }
                              />
                              <label
                                className={
                                  item1.ID == 0 ? "disabled-ms-item" : "ms-item"
                                }
                                htmlFor={item1.Value}
                                key={index1 + item1.Value + "_"}
                              >
                                {item1.Value}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
              {noRecordFound && (
                <p
                  style={{
                    fontWeight: 600,
                    marginLeft: "4%",
                    color: "rgb(0, 170, 231)",
                  }}
                >
                  No data found.
                </p>
              )}
              {pending && !noRecordFound && (
                <div style={{ paddingLeft: "480px" }}>
                  <Spinner
                    style={{ alignContent: "center" }}
                    size="sm"
                    variant="Info"
                  />
                  <span style={{ padding: "5px", color: "black" }}>
                    Loading
                  </span>{" "}
                </div>
              )}
            </Modal.Body>
          )}
          {diseaseHierarchy && !noRecordFound && (
            // <Disease
            //   setSelectedData={setSelectedData}
            //   checked={checked}
            //   setChecked={setChecked}
            // />
            <div
              key={"items5"}
              style={{
                paddingLeft: "60px",
              }}
            >
              <NewNode
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
                clearAll={clearAll}
                setClearAll={setClearAll}
                partiallyCheckedValues={partiallyCheckedValues}
                setPartiallyCheckedValues={setPartiallyCheckedValues}
                showSelected={showSelected}
              />
            </div>
          )}
          {diseaseHierarchy && noRecordFound && (
            <p
              style={{
                fontWeight: 600,
                marginLeft: "4%",
                color: "rgb(0, 170, 231)",
              }}
            >
              No data found.
            </p>
          )}

          {policyCategory && (
            <div
              key={"items5"}
              style={{
                paddingLeft: "60px",
              }}
            >
              <PolicyCategoryHierarchy
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
                clearAll={clearAll}
                setClearAll={setClearAll}
                partiallyCheckedValues={partiallyCheckedValues}
                setPartiallyCheckedValues={setPartiallyCheckedValues}
                showSelected={showSelected}
              />
            </div>
          )}

          <Modal.Footer
            style={{
              backgroundColor: "#FAFAFA",
              height: "100px",
              padding: "0px",
            }}
          >
            <div
              className="text-center"
              style={{
                overflowY: "auto",
                width: "100%",
                overflowX: "hidden",
                minHeight: "65px",
                maxHeight: "70px",
              }}
            >
              {!diseaseHierarchy && !policyCategory && (
                <>
                  {(lookupId === 427 ||
                    lookupKey.includes(
                      "RegulatoryWatch_ApprovedIndication_Combination_Item"
                    )) &&
                    selectedData?.length > 1 && (
                      <div
                        style={{
                          paddingLeft: "25px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="combination-link"
                          onClick={() => handleChangeCombination()}
                        >
                          <span>+</span> Add combination{" "}
                        </a>
                      </div>
                    )}
                  {lookupId === 427 && (
                    <>
                      <ol style={{float:"left"}}>
                        {selectedDataSpecial?.length > 0 &&
                          selectedDataSpecial.map((item, index) => (
                            <React.Fragment key={item.Id}>
                            <li
                              style={{
                                // display: "inline",
                                color: "#00aae7",
                                paddingRight: "5px",
                                textAlign: "left",
                                height:"0px",
                              }}
                            
                            >
                              <label
                                className="selected-item"
                                htmlFor={item.Value}
                                key={index + item.Value + "_"}
                                onClick={() => {
                                  setSelectedDataSpecial((element) => {
                                    return element.filter(
                                      (el) => el.Id !== item.Id
                                    );
                                  });
                                }}
                              >
                                {item.Value.indexOf("~") >= 0
                                  ? item.Value.split("~")[0]
                                  : item.Value}
                              </label>
                            </li>
                            <br/>
                            </React.Fragment>
                          ))}
                      </ol>
                    </>
                  )}

                  {lookupKey.includes(
                    "RegulatoryWatch_ApprovedIndication_Combination_Item"
                  ) &&
                    selectedDataSpecial?.length > 0 && (
                      <ol>
                        {selectedDataSpecial.map((item: any, index: number) => {
                          let li_item = item.Value;
                          if (!!item.Value && item.Value.indexOf("~") > 0) {
                            li_item = item.Value.split("~")[0];
                          }
                          return (
                            <React.Fragment key={item.Id}>
                              <li
                                style={{
                                
                                  color: "#00aae7",
                                  textAlign:"left",
                                  fontWeight: "bold",
                                  
                                }}
                                
                              >
                                <label
                                  
                                  htmlFor={item.Value}
                                  key={index + item.Value + "_"}
                                  onClick={() => {
                                    // updating the modalBody (checkBox) while clicking the link in modalFooter
                                    const values = items.map((i) => {
                                      if (i.Value == item.Value) {
                                        return {
                                          ...i,
                                          isChecked: false,
                                        };
                                      }
                                      return i;
                                    });
                                    setItems([...values]);
                                    // updating the ModalFooter while clicking the link in modalFooter
                                    setSelectedDataSpecial((element) => {
                                      return element.filter(
                                        (el) => el.Value !== item.Value
                                      );
                                    });
                                  }}
                                >
                                  {li_item}
                                </label>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ol>
                    )}

                  <ul style={{ width: "100%", maxHeight: "70px" }}>
                    {selectedData?.length > 0 &&
                      lookupKey !== "HTARecord_Drug_Combination" &&
                      lookupKey !== "HTARecord_DrugComparator_Combination" &&
                      lookupKey !== "ClinicalData_Drug_Combination" &&
                      lookupKey !== "ClinicalData_DrugControl_Combination" &&
                      !lookupKey.includes(
                        "RegulatoryWatch_ApprovedIndication_Combination_Item"
                      ) &&
                      selectedData.map((item: any, index: number) => {
                        let li_item = item.Value;
                        if (!!item.Value && item.Value.indexOf("~") > 0) {
                          li_item = item.Value.split("~")[0];
                        }
                        return (
                          <React.Fragment key={item.Id}>
                            <li
                              style={{
                                display: "inline",
                                color: "#00aae7",
                                paddingRight: "5px",
                                fontWeight: "bold",
                                float: "left",
                              }}
                              
                            >
                              <label
                                className="ms-item"
                                htmlFor={li_item}
                                key={index + li_item + "_"}
                                onClick={() => {
                                  // updating the ModalFooter while clicking the link in modalFooter
                                  setSelectedData((element) => {
                                    return element.filter(
                                      (el) => el.Id !== item.Id
                                    );
                                  });
                                  // updating the modalBody (checkBox) while clicking the link in modalFooter
                                  // const values = items.map((i) => {
                                  //   if (i.ID == item.Id) {
                                  //     return {
                                  //       ...i,
                                  //       isChecked: false,
                                  //     };
                                  //   }
                                  //   return i;
                                  // });
                                  // setItems([...values]);
                                  const updatedCurrData = currData.map((i) => {
                                    if (i.ID == item.Id) {
                                      return {
                                        ...i,
                                        isChecked: false,
                                      };
                                    }
                                    return i;
                                  });
                                  

                                  setCurrData([...updatedCurrData]);
                                }}
                              >
                                {li_item}
                              </label>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    {selectedDataSpecial?.length > 0 &&
                      lookupKey !== "HTARecord_Drug_Combination" &&
                      lookupKey !== "HTARecord_DrugComparator_Combination" &&
                      lookupKey !== "ClinicalData_Drug_Combination" &&
                      lookupKey !== "ClinicalData_DrugControl_Combination" &&
                      !lookupKey.includes(
                        "RegulatoryWatch_ApprovedIndication_Combination_Item"
                      ) &&
                      selectedDataSpecial.map((item: any, index: number) => {
                        let li_item = item.Value;
                        if (!!item.Value && item.Value.indexOf("~") > 0) {
                          li_item = item.Value.split("~")[0];
                        }
                        return (
                          <>
                            <li
                              style={{
                                display: "inline",
                                color: "#00aae7",
                                paddingRight: "5px",
                                fontWeight: "bold",
                                float: "left",
                              }}
                              key={item.Id}
                            >
                              <label
                                className="ms-item"
                                htmlFor={item.Value}
                                key={index + item.Value + "_"}
                                onClick={() => {
                                  // updating the modalBody (checkBox) while clicking the link in modalFooter
                                  const values = items.map((i) => {
                                    if (i.Value == item.Value) {
                                      return {
                                        ...i,
                                        isChecked: false,
                                      };
                                    }
                                    return i;
                                  });
                                  setItems([...values]);
                                  // updating the ModalFooter while clicking the link in modalFooter
                                  setSelectedDataSpecial((element) => {
                                    return element.filter(
                                      (el) => el.Value !== item.Value
                                    );
                                  });
                                }}
                              >
                                {li_item}
                              </label>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </>
              )}
            </div>
            <div className={styles.actionsContainer} style={{ float: "right" }}>
              <a className="closemultiselect no-underline "
                style={{ color: "#00aae7", cursor: "pointer" }}
                onClick={handleClose}
              >
                {diseaseHierarchy || policyCategory ? "Cancel" : "Close"}
              </a>
              &nbsp;&nbsp;|&nbsp;
              <a className="clearallmultiselect no-underline "
                style={{
                  color: "#00aae7",
                  cursor: "pointer",
                  pointerEvents:
                    diseaseHierarchy && isRecordViewPage ? "none" : "auto",
                  opacity: diseaseHierarchy && isRecordViewPage ? "0.4" : "1",
                }}
                onClick={() => {
                  if (diseaseHierarchy || policyCategory) {
                    setClearAll(true);
                  } else {
                    setSelectedDataSpecial([]);
                    SelectAll(false);
                    setShowRegionalDrug(false);
                  }
                }}
              >
                Clear all
              </a>
              &nbsp;&nbsp;|&nbsp;
              {!diseaseHierarchy && !policyCategory && (
                <>
                <a className="selectallmultiselect no-underline "
                    style={{ color: "#00aae7", cursor: "pointer",textDecoration:"none" }}
                    onClick={() => SelectAll(true)}
                  >
                    Select all
                  </a>
                  &nbsp;&nbsp;|&nbsp;
                </>
              )}
              {(diseaseHierarchy || policyCategory) && !showSelected && (
                <>
                  <a
                    style={{ color: "#00aae7", cursor: "pointer" }}
                    onClick={() => showSelectedFunc(true)}
                  >
                    Show selected
                  </a>
                  &nbsp;&nbsp;|&nbsp;
                </>
              )}
              {(diseaseHierarchy || policyCategory) && showSelected && (
                <>
                  <a
                    style={{ color: "#00aae7", cursor: "pointer" }}
                    onClick={() => showSelectedFunc(false)}
                    className="no-underline "
                  >
                    Show All
                  </a>
                  &nbsp;&nbsp;|&nbsp;
                </>
              )}
              <a className="saveselectionsmultiselect no-underline"
                style={{
                  color: "#00aae7",
                  cursor: "pointer",
                  pointerEvents:
                    diseaseHierarchy && isRecordViewPage ? "none" : "auto",
                  opacity: diseaseHierarchy && isRecordViewPage ? "0.4" : "1",
                }}
                onClick={saveSelections}
              >
                {diseaseHierarchy || policyCategory
                  ? "Save changes"
                  : "Save selections"}
              </a>
              &nbsp;&nbsp;
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <Snackbar
        open={openCombinationExist}
        autoHideDuration={6000}
        onClose={handleCombinationExist}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCombinationExist}
          severity="warning"
          sx={{ width: "100%" }}
        >
          The combination already added
        </Alert>
      </Snackbar>
    </>
  );
}

export default NewModal;
