import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Field } from "../../../../store/DataSourceMetaData";
import { LabelInfoPopup } from "./RegulatoryWatch_ApprovedIndication";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { IndicationDetails } from "../Views/RegulatoryWatch_ApprovedIndications";
import {
  setRecordVersionId,
  setSuperDisease,
  showMultiSelectPopup,
} from "../../../../store/MultiSelectSlice";
import { sizeColor } from "../../SectionView";

interface Regulatory_PreviouslyApprovedIndicationsProp {
  datasourceName: string;
  section: string;
}

const RegulatoryWatch_PreviouslyApprovedIndications = ({
  datasourceName,
  section,
}: Regulatory_PreviouslyApprovedIndicationsProp) => {
  const dispatch = useAppDispatch();
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
  const [approvedIndications, setApprovedIndications] = useState<any>({});
  useEffect(() => {
    console.log("approved_indication",subPageDate["approved_indication"])
    setApprovedIndications(subPageDate["approved_indication"]);
  }, [subPageDate]);

  return (
    <div id="previously-approved-indications">
      <hr />
      <div className="record-view-height panel-title" style={{fontSize:"12px",paddingBottom:"20px"}}>
        {" "}
        Previously aprroved indications
      </div>
      <hr/>
      {approvedIndications?.previouslyApprovedIndications &&
        approvedIndications.previouslyApprovedIndications.length > 0 && (
          <div
            id="previously-approved-indication-header"
            className="col-sm-12"
            style={{
              display: "flex",
              paddingTop: "5px",
              paddingBottom: "5px",
              marginTop: "5px",
            }}
          >
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                // label={"Approval date/Indication"}
                // infoLabel={"Approval date/Indication"}
                label={"Approval date"}
                infoLabel={"Approval date"} 
                description={
                  "This field provides the initial approval date for original approvals and the approval date for the extension for line extensions."
                }
              />
            </div>
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                // label={"Approval type"}
                // infoLabel={"Approval type"}
                label={"Approval type/Indication"}
                infoLabel={"Approval type/Indication"}
                description={`This field provides the regulatory approval types
                            * Original
                            * Extension of indication`}
              />
            </div>
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                label={"In combination with/Combinations"}
                infoLabel={"Combination"}
                description={
                  "This field specifies if the drugs used in combination with assessed drug."
                }
              />
            </div>
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                label={"Patient population"}
                infoLabel={"Population"}
                description={
                  "This field specifies the patient population for the assessed drug."
                }
              />
            </div>
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                label={"Treatment line/modality"}
                infoLabel={"Treatment line"}
                description={
                  "This field specifies the treatment line and treatment modality of the drug assessed by the regulator."
                }
              />
            </div>
            <div className="header-cell col-sm-2">
              <LabelInfoPopup
                datasourceName={datasourceName}
                section={section}
                label={"Disease"}
                infoLabel={"Disease"}
                description={
                  "This field specifies the disease(s) assessed by the regulator."
                }
              />
            </div>
          </div>
        )}

      <div id="approved-indication-rows" className="col-sm-12">
        <>
          {approvedIndications?.previouslyApprovedIndications &&
            approvedIndications.previouslyApprovedIndications.length > 0 &&
            approvedIndications.previouslyApprovedIndications.map(
              (x: any, index: any) => (
                <>
                  <hr />
                  <div
                    id={`previously-approved-indication-row-${index}`}
                    className="col-sm-12"
                    style={{ display: "flex" }}
                  >
                    <div className="row-cell col-sm-2">
                      {new Date(x.ApprovalDate).toLocaleDateString("en-GB")}
                    </div>
                    <div className="row-cell col-sm-2">{x.indicationType}</div>
                    <div className="row-cell col-sm-2">
                      {x.combination &&
                        x.combination?.split(',').map((c: string) => <div>{c}</div>)}
                    </div>
                    <div className="row-cell col-sm-2">
                      {x.patientPopulation &&
                        x.patientPopulation?.split(',').map((c: string) => (
                          <div>{c}</div>
                        ))}
                    </div>
                    <div className="row-cell col-sm-2">
                      <>
                        {x.treatmentLine &&
                          x.treatmentLine?.split(',').map((c: string) => <div>{c}</div>)}
                        {x.treatmentModality && <hr />}
                        {x.treatmentModality &&
                          x.treatmentModality?.split(',').map((c: string) => (
                            <div>{c}</div>
                          ))}
                      </>
                    </div>
                    <div
                      className="row-cell col-sm-2"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <button
                        type="button"
                        id={x.disease}
                        className="btn btn-link"
                        style={{
                          ...sizeColor,
                          paddingTop: "1px",
                          paddingLeft: "0px",
                        }}
                        onClick={() => {
                          console.log("open disease hierarchy popup");
                          dispatch(
                            setRecordVersionId({
                              version_id: x.RegulatoryWatch_Version_ID,
                              indication_id: x.ID,
                            })
                          );
                          dispatch(setSuperDisease([]));
                          dispatch(
                            showMultiSelectPopup(
                              169,
                              true,
                              "SuperSearchDiseaseIDs",
                              "Disease Hierarchy"
                            )
                          );
                        }}
                      >
                        {" Show disease hierarchy"}
                      </button>
                      <div>
                        {x.disease_ID && (
                          <>
                            {x.disease?.split(",").map((el: string) => (
                              <div>{el}</div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    id={`approved-indication-row-description-${index}`}
                    className="col-sm-12"
                    style={{ display: "flex" }}
                  >
                    <div className="row-cell col-sm-4">
                      <hr />
                      {x.Indication && (
                        // <a
                        //   href={`view/3/${x.RegulatoryWatch_ID}`}
                        //   style={{ color: "rgb(57, 57, 57)" }}
                        // >
                          <IndicationDetails
                            indication={x.Indication}
                            ID={x.ID}
                          />
                        // </a>
                      )}
                    </div>
                  </div>
                </>
              )
            )}
        </>
      </div>
      {approvedIndications?.previouslyApprovedIndications == undefined || approvedIndications?.previouslyApprovedIndications?.length == 0 && (
        <div>
          <label>No previous approvals</label>
        </div>
      )}
    </div>
  );
};

export default RegulatoryWatch_PreviouslyApprovedIndications;
