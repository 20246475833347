import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./styles.css";
const { forwardRef, useImperativeHandle } = React;

import {
  ColDef,
  GridReadyEvent,
  IsRowSelectable,
  RowNode,
  FirstDataRenderedEvent,
} from "ag-grid-community";
import { rearrangeGridColumn, setActiveColumnFilter, setCurrIds } from "../../store/SearchSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface TableGridProps {
  tableName?: string;
  defaultColumnList?: string[];
  rowData: any[];
  columnDefs: ColDef[];
  isLoading: boolean;
  pageSize: number;
  getSelectedRows?: Function;
  checkSelection?: Function;
  sectionsInfo?: any[];
  setGridRowsCount?: Function;
  // dataSourceID?: number;
  currentPage?: number;
  setCurrentPage?: Function;
  setPageSize?: Function;
  initiatePaginationSearch?: Function;
  gridRowCount?: number;
  selectedValues?: any[];
}

const TableGrid = forwardRef((props: TableGridProps, ref) => {
  const dispatch = useAppDispatch();
  const dataSourceID = useAppSelector(
    (state) => state.search.selectedDatasourceId
  );
  const totalResultCount = useAppSelector(
    (state) => state.search.totalResultCount
  );
  
  const selectedColFilters = useAppSelector((state) => state.search.selectedColumnFilter);
  const totalFilteredCount = useAppSelector((state) => state.search.totalFilteredCount);

  const gridRef = useRef<AgGridReact<any>>(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const {
    rowData,
    columnDefs,
    isLoading,
    tableName = "",
    defaultColumnList = [],
    sectionsInfo = [],
    currentPage = 1,
    pageSize = 100,
    selectedValues = [],
  } = props;

  const [datasourceWithLargeDataset, setDatasourceWithLargeDataset] = useState(false);
  const [isUserAction, setIsUserAction] = useState(false);
  const [activeColumn, setActiveColumn] = useState('');

  useEffect(()=>{
    setDatasourceWithLargeDataset([1,2,3,4,5,6,7,20,23,25].includes(dataSourceID));
  },[dataSourceID]);
  

  const totalCountDisplay = useMemo(() => {
    return (Object.keys(selectedColFilters).length !== 0 && totalFilteredCount != null) ? totalFilteredCount : totalResultCount;
  }, [totalResultCount, totalFilteredCount]);
  const totalPages = useMemo(()=>{
    return Math.ceil(totalCountDisplay / pageSize);
  },[totalCountDisplay, pageSize]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
    };
  }, []);

  useImperativeHandle(ref, () => ({
    exportCurrentview() {
      gridRef.current!.api.exportDataAsCsv();
    },
  }));

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (params: RowNode<any>) => {
      //   return !!params.data && params.data.year === 2012;
      return true;
    };
  }, []);

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<any>) => {
      if (tableName == "EditColumnTable") {
        const selectedRows = rowData.filter((x) =>
          defaultColumnList.includes(x.Name)
        );
        const unselectedRows = rowData.filter(
          (x) => !defaultColumnList.includes(x.Name)
        );
        gridRef.current!.api.setRowData(selectedRows.concat(unselectedRows));
        gridRef.current!.api.forEachNode((node) =>
          node.setSelected(
            !!node.data && defaultColumnList.includes(node.data.Name)
          )
        );
      } else {
        // gridRef.current!.api.forEachNode((node) => node.setSelected(false));
        gridRef.current!.api &&
          gridRef.current!.api.forEachNode((node) => {
            node.setSelected(selectedValues.some(val => val.ID === node.data.ID));

        });
      }
    },
    []
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const refreshFilterIcons = useCallback(() => {
    if (columnApi && gridApi) {
      const allColumns = columnApi.getAllGridColumns();
      allColumns.forEach((column) => {
        gridApi.getFilterInstance(column.getColId(), (filterInstance) => {
          if (filterInstance && selectedColFilters[column.getColId()] && selectedColFilters[column.getColId()].length > 0) {
            filterInstance.setModel({
              value: selectedColFilters[column.getColId()] || null,
            });
            filterInstance.onFilterChanged();
          }
        });
        
      });
    }
  },[columnApi, gridApi]);


  useEffect(() => {
    if (gridRef && gridRef.current) {
      if (tableName == "EditColumnTable" && gridRef.current!.api) {
        gridRef.current!.api.forEachNode((node) =>
          node.setSelected(
            !!node.data &&
              (defaultColumnList.includes(node.data.Name) ||
                node.data.Name == "Name")
          )
        );
      } else {
        gridRef.current!.api &&
          gridRef.current!.api.forEachNode((node) => node.setSelected(false));
      }
    }

    const ids : number[] = rowData?.map((el) => el.ID)
    dispatch(setCurrIds(ids));

}, [rowData]);

  const onFilterTextBoxChanged = useCallback(() => {

    gridRef.current!.api.setQuickFilter(
      (document.getElementById("audit-grid-free-text-search") as HTMLInputElement)
        .value
    );
  }, []);

  const onColumnFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("col-grid-free-text-search") as HTMLInputElement)
        .value
    );
  }, []);

  const onFilterTextBoxChangedSearchGrid = useCallback(() => {
    gridRef.current!.api.setQuickFilter(
      (document.getElementById("search-grid-free-text-search") as HTMLInputElement)
        .value
    );
  }, []);

  const [lastSelectedColID, setLastSelectedColID] = useState<number>();
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (tableName === "EditColumnTable") {
      if (selectedRows.length == 1) {
        const selectedFieldID = selectedRows[0]["ID"];
        setLastSelectedColID(selectedFieldID);
      }
      if (selectedRows.length == 0) {
        gridRef.current!.api.forEachNode((node) =>
          node.setSelected(!!node.data && node.data.ID == lastSelectedColID)
        );
      }
    }

    props.getSelectedRows && props.getSelectedRows(selectedRows);
    props.checkSelection && props.checkSelection(selectedRows);
  }, [lastSelectedColID]);

  const onBtExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv();
  }, []);

  const [isColumnDropdownOpen, toggleColumnDropdown] = useState(false);
  const [categoryID, setCategoryID] = useState(0);
  const [sectionCatogory, setSectionCatogory] = useState("All Sections");

  const externalFilterChanged = useCallback(
    (newValue: number, categoryName: string) => {
      setCategoryID(newValue);
      setSectionCatogory(categoryName);
    },
    []
  );

  useEffect(() => {
    if (gridRef && gridRef.current)
      gridRef.current!.api && gridRef.current!.api.onFilterChanged();
  }, [categoryID]);

  const isExternalFilterPresent = useCallback((): boolean => {
    return tableName === "EditColumnTable" && categoryID !== 0;
  }, [categoryID]);

  const doesExternalFilterPass = useCallback(
    (node: RowNode<any>): boolean => {
      if (node.data) {
        return node.data.Category_ID == categoryID;
      }
      return true;
    },
    [categoryID]
  );

  const saveState = useCallback(() => {
    // window.colState = gridRef.current!.columnApi.getColumnState();
    let columnList =
      gridRef &&
      gridRef.current &&
      gridRef.current!.columnApi.getAllGridColumns();
    if(columnList && columnList.length > 0){
      columnList = columnList.filter(x => x.getColId() !== "0");
    }
    let colNames: string[] | null = [];
    colNames =
      columnList &&
      columnList.map((column) => {
        return column.getColId();
      });

    dispatch(rearrangeGridColumn(colNames));
  }, []);

  const onFilterChanged = (ev) => {
    // console.log(gridRef)
    // Commenting this code as it is giving incorrect value
    if (tableName == "SearchGrid" && ev && ev.api && ev.api.rowModel && ev.api.rowModel.rowsToDisplay) {
      if(document.getElementById("search-grid-free-text-search")?.value != ''){
        props.setGridRowsCount &&
        props.setGridRowsCount(ev.api.rowModel.rowsToDisplay.length);
      }else{
        props.setGridRowsCount &&
        props.setGridRowsCount(props.gridRowCount);
      }
      
    }

    

    const IDS :number[]= [];
    gridRef.current!.api?.rowModel.rowsToDisplay.forEach((el) => {
      IDS.push(el.data.ID);
    })
    dispatch(setCurrIds(IDS));
  };

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    props.setPageSize && props.setPageSize(Number(value));
    props.setCurrentPage && props.setCurrentPage(1);
    
    if(tableName === "SearchGrid" && datasourceWithLargeDataset){
      gridRef &&
      gridRef.current &&
      gridRef.current!.api.paginationSetPageSize(Number(value));

      props.initiatePaginationSearch && props.initiatePaginationSearch(1,Number(value));
    }else{
      gridRef &&
      gridRef.current &&
      gridRef.current!.api.paginationSetPageSize(Number(value));
    }
    
  }, [datasourceWithLargeDataset]);

  const closeFilterPopup = () => {
    // console.log("closeFilterPopup");
    // console.log("gridRef.current : ", gridRef.current);
    gridRef && gridRef.current && gridRef.current!.api.hidePopupMenu();
  };


  useEffect(()=>{
    if(isUserAction && activeColumn && activeColumn != ''){
      dispatch(setActiveColumnFilter(activeColumn));
    }
  },[isUserAction,activeColumn]);

  const [_columnDefs, setColumnDefs] = useState<ColDef<any>[]>(columnDefs);
  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (gridRef && gridRef.current && gridRef.current!.api) {
        setIsUserAction(false);
        if (
          tableName === "SearchGrid" &&
          rowData &&
          rowData.length > 0 &&
          columnDefs &&
          columnDefs.length > 0
        ) {
          setGridApi(params.api);
          setColumnApi(params.columnApi);
          let tempColDef = columnDefs.map((cd) => {
            if (!cd.hasOwnProperty("filterParams")) return cd;
            else {
              let fm = { ...cd.filterParams };
              fm = { ...fm, closeFilterPopup: closeFilterPopup };
              return { ...cd, filterParams: fm };
            }
          });
          // gridRef && gridRef.current && gridRef.current!.api .setColumnDefs(_columnDefs);
          setColumnDefs(tempColDef);
        } else {
          setColumnDefs(columnDefs);
        }
      }
    },
    [columnDefs]
  );

  useEffect(() => {
    Object.keys(selectedColFilters).length > 0 && refreshFilterIcons();
  }, [columnApi]);

  const onPageChanged = (value: number) => {
    props.setCurrentPage && props.setCurrentPage(Number(value));
    datasourceWithLargeDataset && props.initiatePaginationSearch && props.initiatePaginationSearch(Number(value), pageSize);
  };

  return (
    <div style={containerStyle}>
      <div className="outer-div">
        {tableName === "EditColumnTable" &&
          rowData &&
          rowData.length > 0 &&
          dataSourceID != 5 &&
          sectionsInfo && (
            <div style={{ display: "flex" }}>
              <Dropdown
                isOpen={isColumnDropdownOpen}
                color="link"
                toggle={() => toggleColumnDropdown(!isColumnDropdownOpen)}
              >
                <DropdownToggle
                  tag="button"
                  className="dropdown-toggle btn btn-link iqvia-color"
                  caret
                >
                  Section
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    transform: "none !important",
                    maxWidth: "400px",
                    minWidth: "350px",
                    overflow: "hidden",
                  }}
                >
                  {sectionsInfo.map((section: any, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        dropdownvalue={section.ID}
                        onClick={() =>
                          externalFilterChanged(section.ID, section.Name)
                        }
                        style={{
                          whiteSpace: "break-spaces",
                          // white-space: break-spaces
                        }}
                      >
                        {section.Name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <span style={{ padding: "6px" }}>{`: ${sectionCatogory}`}</span>
            </div>
          )}
          
        {tableName === "EditColumnTable" && rowData && rowData.length > 0 && (
          <div className="searchtoolbar" style={{ padding: "6px 12px" }}>
            <span id="filter-label">Filter search results: </span>
            <input
              type="text"
              id="col-grid-free-text-search"
              placeholder="Search columns"
              onInput={onColumnFilterTextBoxChanged}
            />
          </div>
        )}
        {tableName === "AuditLogTable"&&  (
          <div style={{ padding: "7px 0px 7px 0px"}}>
            <span id="filter-label">Show </span>
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span id="filter-label"> entries</span>
          </div>
        )}
        {tableName === "SavedSearchAlert" && (
          <div>
            <span id="filter-label">Show </span>
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="100">10</option>
              <option value="500">25</option>
              <option value="1000">150</option>
              <option value="5000">100</option>
            </select>
            <span id="filter-label"> entries</span>
          </div>
        )}
        {tableName === "AuditLogTable" && rowData && rowData.length > 0 && (
          <div className="searchtoolbar" style={{ marginBlock: "20px", marginLeft: "63.5rem",marginTop:"-7rem" }}>
            <span id="filter-label">Search: </span>
            <input
              type="text"
              id="audit-grid-free-text-search"
              //placeholder="Search columns"
              onInput={onFilterTextBoxChanged}
              onKeyDown ={(e) => {
                e.key === 'Enter' && e.preventDefault()
              } }
            />
          </div>
        )} 
        {tableName === "SavedSearchAlert"  && rowData && rowData.length > 0 && (
          <div className="searchtoolbar" style={{marginLeft:"67.7rem",marginBottom:"35px",marginTop:"-21px"}} >
            <span id="filter-label">Search: </span>
            <input
              type="text"
              id="col-grid-free-text-search"
              //placeholder="Search columns"
              onInput={onColumnFilterTextBoxChanged}
            />
          </div>
        )} 
        {tableName != "EditColumnTable" && tableName != "AuditLogTable" && tableName != "SavedSearchAlert" && tableName != "ProductProfile" && rowData && rowData.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "15px 0px 40px",
            }}
          >
            <div className="searchtoolbar">
              <span id="filter-label">Filter search results: </span>
              <input
                type="text"
                id="search-grid-free-text-search"
                placeholder="Enter search terms"
                onInput={onFilterTextBoxChangedSearchGrid}
                style={{ width: "220px", padding: "5px 4px" }}
              />
            </div>
            {tableName == "SearchGrid" && rowData && rowData.length > 0 && (
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    margin: "2px 0px",
                    color: "white",
                    backgroundColor: "rgb(0, 170, 231)",
                    border: "1px solid rgb(0, 170, 231)",
                    borderRadius: "5px",
                    lineHeight: "20px",
                  }}
                  onClick={saveState}
                >
                  Save column preference
                </button>
              </div>
            )}
          </div>
        )}
        {tableName == "ProductProfile" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "15px 70px 40px 2px",
              borderBottom:"1px solid #ddd",
              paddingBottom: "15px",
            }}
          >
            <div className="searchtoolbar" style={{marginLeft:"18px",marginTop: "5px"}}>
              <span id="filter-label">Filter search results: </span>
              <input
                type="text"
                id="grid-free-text-search"
                onInput={onFilterTextBoxChanged}
                style={{ width: "150px",height:"23px", padding: "5px 4px",border: "1px solid lightslategray" }}
              />
            </div>
          </div>
               )}

        {isLoading && (
          <>
            <Spinner animation="border" style={{ color: "#00aae7" }} />
            <h6>Loading search result...</h6>
          </>
        )}
        {!isLoading && rowData && rowData.length > 0 && (
          <div className="grid-wrapper">
            <div
              style={{ width: "100%", height: 500 }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={_columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection={"multiple"}
                // animateRows={true}
                rowBuffer={10}
                rowHeight = {25}
                debounceVerticalScrollbar={true}
                suppressRowClickSelection={true}
                isRowSelectable={isRowSelectable}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                pagination={true}
                paginationPageSize={pageSize}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                onFilterChanged={onFilterChanged}
                tooltipShowDelay={0}
                suppressDragLeaveHidesColumns={true}
                suppressPaginationPanel={tableName == "SearchGrid" && datasourceWithLargeDataset}
                onFilterOpened={(event)=> { 
                  setIsUserAction(true);
                  setActiveColumn(event.column.colId);                  
                }}
              ></AgGridReact>
              {tableName == "SearchGrid" && datasourceWithLargeDataset && 
              (<div className="ag-paging-panel ag-unselectable" id="ag-161">
                  <span className="ag-paging-row-summary-panel" role="status">
                      <span id="ag-161-first-row" className="ag-paging-row-summary-panel-number">{(currentPage -1)*pageSize +1}</span>
                      <span id="ag-161-to">{` to `}</span>
                      <span id="ag-161-last-row" className="ag-paging-row-summary-panel-number">{Math.min(currentPage * pageSize, totalCountDisplay)}</span>
                      <span id="ag-161-of">{` of `}</span>
                      <span id="ag-161-row-count" className="ag-paging-row-summary-panel-number">{totalCountDisplay}</span>
                  </span>
                  <span className="ag-paging-page-summary-panel" role="presentation">
                      <div onClick={()=> onPageChanged(1)} className={`ag-paging-button ${currentPage === 1 ? 'ag-disabled': ''}`} role="button" aria-label="First Page" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto' }} aria-disabled={currentPage === 1 ? 'true': 'false'}>
                        <span className="ag-icon ag-icon-first" unselectable="on" role="presentation"></span>
                      </div>
                      <div onClick={()=> onPageChanged(currentPage - 1)} className={`ag-paging-button ${currentPage === 1 ? 'ag-disabled': ''}`} role="button" aria-label="Previous Page" style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto'}} aria-disabled={currentPage === 1 ? 'true': 'false'}>
                        <span className="ag-icon ag-icon-previous" unselectable="on" role="presentation"></span>
                      </div>
                      <span className="ag-paging-description" role="status">
                          <span id="ag-161-start-page">{` Page `}</span>
                          <span id="ag-161-start-page-number" className="ag-paging-number">{ currentPage }</span>
                          <span id="ag-161-of-page">{` of `}</span>
                          <span id="ag-161-of-page-number" className="ag-paging-number">{ totalPages }</span>
                      </span>
                      <div onClick={()=> onPageChanged(currentPage + 1)} className={`ag-paging-button ${currentPage === totalPages ? 'ag-disabled': ''}`} role="button" aria-label="Next Page" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto'}} aria-disabled={currentPage === totalPages ? 'true': 'false'}>
                        <span className="ag-icon ag-icon-next" unselectable="on" role="presentation"></span>
                      </div>
                      <div onClick={()=> onPageChanged(totalPages)} className={`ag-paging-button ${currentPage === totalPages ? 'ag-disabled': ''}`} role="button" aria-label="Last Page" style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto'}} aria-disabled={currentPage === totalPages ? 'true': 'false'}>
                        <span className="ag-icon ag-icon-last" unselectable="on" role="presentation"></span>
                      </div>
                  </span>
              </div>)
              }
            </div>
          </div>
        )}
        {tableName != "EditColumnTable" && tableName != "AuditLogTable" && tableName != "SavedSearchAlert" && (
          <div style={{ padding: "7px 0px 7px 0px", margin: "auto" }}>
            <span id="filter-label">Show entries: </span>
            <select onChange={onPageSizeChanged} id="page-size" value={pageSize}>
              <option value="100" >100</option>
              <option value="500" >500</option>
              <option value="1000" >1000</option>
              <option value="5000" >5000</option>
            </select>
          </div>
        )}
        
      </div>
    </div>
  );
});

export default TableGrid;
