// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.toast-top-center {
  margin-top: 20px;
}

.container {
  max-width: none;
  max-width: initial;
}
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.iqvia-color {
  color: rgb(0, 170, 231) !important;
}
.user-acc-name:first-child {
  color: rgb(0, 170, 231) !important;
  font-weight: bold;
}
.btn-font {
  font-size: 0.9rem !important;
}
.main-nav-menu-padding {
  padding: 0em 5em 0em 5em !important;
}

.user-acc-name {
  padding-bottom: 8px !important;
}

.user-acc-name:hover {
  background-color: transparent !important;
}
.dropdown-menu-item {
  color: black;
  word-wrap: break-word;
  white-space: normal;
  margin-left: 28px;
  margin-top: -20px;
}
.dropdown-menu-item-new {
  color: black;
  word-wrap: break-word;
  white-space: normal;
  margin-bottom: -15px; 
}
.dropdown-menu-item :hover {
  color: black !important;
}
.dropdown-menu-item-new :hover {
  color: black !important;
}
.txt:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(0, 170, 231);
          text-decoration-color: rgb(0, 170, 231);
}`, "",{"version":3,"sources":["webpack://./src/components/NavMenu.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAkB;EAAlB,kBAAkB;AACpB;AACA;EACE,iDAAiD;AACnD;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kCAAkC;EAClC,iBAAiB;AACnB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;AAC1C;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,0BAA0B;EAC1B,+CAAuC;UAAvC,uCAAuC;AACzC","sourcesContent":["a.navbar-brand {\n  white-space: normal;\n  text-align: center;\n  word-break: break-all;\n}\n\nhtml {\n  font-size: 14px;\n}\n\n@media (min-width: 768px) {\n  html {\n    font-size: 16px;\n  }\n}\n\n.toast-top-center {\n  margin-top: 20px;\n}\n\n.container {\n  max-width: initial;\n}\n.box-shadow {\n  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);\n}\n\n.iqvia-color {\n  color: rgb(0, 170, 231) !important;\n}\n.user-acc-name:first-child {\n  color: rgb(0, 170, 231) !important;\n  font-weight: bold;\n}\n.btn-font {\n  font-size: 0.9rem !important;\n}\n.main-nav-menu-padding {\n  padding: 0em 5em 0em 5em !important;\n}\n\n.user-acc-name {\n  padding-bottom: 8px !important;\n}\n\n.user-acc-name:hover {\n  background-color: transparent !important;\n}\n.dropdown-menu-item {\n  color: black;\n  word-wrap: break-word;\n  white-space: normal;\n  margin-left: 28px;\n  margin-top: -20px;\n}\n.dropdown-menu-item-new {\n  color: black;\n  word-wrap: break-word;\n  white-space: normal;\n  margin-bottom: -15px; \n}\n.dropdown-menu-item :hover {\n  color: black !important;\n}\n.dropdown-menu-item-new :hover {\n  color: black !important;\n}\n.txt:hover {\n  text-decoration: underline;\n  text-decoration-color: rgb(0, 170, 231);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
