import React, { useEffect, useState } from "react";
// @ts-ignore
import CreatableSelect from "react-select/creatable";
import { Field } from "../../store/DataSourceMetaData";
import apiCall from "../service/apiCall";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setLoeBrandName, setSelectedLookupGlobe } from "../../store/MultiSelectSlice";
import { ILookupData } from "../Popup/NewModal";

interface lookupItemT {
  label: string;
  id: string;
}
interface lookupItemI {
  label: string;
  value: string;
}

const createOption = (label: string, id: string) => ({
  label: label.trim(),
  value: id,
});
// interface optionInterface {
//   value: string;
//   label: string;
// }

// const createOption = (label: string) => ({
//   label: label.trim(),
//   value: label,
// });

// const defaultOptions = [
//   createOption('One'),
//   createOption('Two'),
//   createOption('Three'),
// ];

interface AutocompleteWithAddProps {
  field: Field;
  items: lookupItemT[];
  setItems: React.Dispatch<React.SetStateAction<lookupItemT[]>>;
  onFieldChange: Function;
  values: any;
  isHTASubGroup?: boolean;
  parentObject?: any;
  index?: number;
  createDataNewModal?: Function;
  resetValue?: boolean;
}

export default ({
  field,
  items,
  setItems,
  onFieldChange,
  values,
  isHTASubGroup = false,
  parentObject,
  index,
  createDataNewModal,
  resetValue,
}: AutocompleteWithAddProps) => {
  const dispatch = useAppDispatch();
  const loeBrandName = useAppSelector((state) => state.multiselect.loeBrandName);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<lookupItemI[]>([]);
  const [value, setValue] = useState<lookupItemI | null>();


  useEffect(() => {
    if (items && items.length > 0) {
      const tempOptions = items.map((item) => {
        return {
          label: item.label,
          value: item.id,
        };
      });

      if (
        field.name == "CountryList_ID_Text" &&
        !!values &&
        values.CountryList_ID_Text
      ) {
        const val = tempOptions.filter(
          (x) => String(x.value) == values[field.name]
        );
        setValue(val[0]);
      } else if (
        field.name == "ReimbursementAuthority_ID_Text" &&
        !!values &&
        values.ReimbursementAuthority_ID_Text
      ) {
        const val = tempOptions.filter(
          (x) => String(x.value) == values[field.name]
        );
        setValue(val[0]);
      } else if(field.name == "RWE_Datasource.RWEDatasourceID" &&
      // @ts-ignore
      !!values  && index >= 0 ) {
        // @ts-ignore
        const value = values["RWE_Datasource"] && values["RWE_Datasource"][index]['RWE_Datasource.RWEDatasourceID'];
        const val = tempOptions.find(
          (x) => x.value == value
        );
        setValue(val);
      } else if(field.name == "DrugData_BrandLicense.RegionalDrug_ID_Text" &&
      // @ts-ignore
      !!values  && index >= 0) {
        // @ts-ignore
        let value = values["DrugData_BrandLicense"] && values["DrugData_BrandLicense"][index]['DrugData_BrandLicense.RegionalDrug_ID_Text'];
        if (typeof(values["DrugData_BrandLicense"][index]['DrugData_BrandLicense.RegionalDrug_ID_Text']) == 'object') {
          value = values["DrugData_BrandLicense"][index]['DrugData_BrandLicense.RegionalDrug_ID_Text'].Id;
        }
        const val = tempOptions.find(
          (x) => x.value == value
        );
        setValue(val);
      }
      // @ts-ignore
      else if(isHTASubGroup && index >= 0 ) 
      {
        // setValue(null);
        // res = await onFieldChange('SubGroupID', newValue.value, parentObject, index);
         // @ts-ignore
        const value = values["SubGroup"] && values["SubGroup"][index]['SubGroupID'];
        const val = tempOptions.find(
          (x) => x.value == value
        );
        setValue(val);
      }else{
        const val = tempOptions.filter(
          (x) => String(x.value) == values[field.name]
        );
        setValue(val[0]);
      }

      setOptions(tempOptions);
    }
  }, [items, values]);
  

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    const newOption = createOption(inputValue, "0");

    const apiURL = isHTASubGroup
      ? `/api/lookup/addLookupItem/184/${inputValue.trim()}`
      : `/api/lookup/addLookupItem/${field.lookupId}/${inputValue.trim()}`;
    apiCall(apiURL).then((response) => {
      console.log("response: ", response);
      let itemID = 0;
      if (response.data == 0)
        itemID = 0;
      else
        itemID = response.data[0].id;
      newOption.value = itemID.toString();
      if (field.name.indexOf('GlobalBrand_ID') >= 0 || field.name.indexOf('RegionalDrug_ID') >= 0) {
        let initialState = loeBrandName === undefined ? [] : [...loeBrandName];
        let result: ILookupData = 
          {
            ID: itemID,
            Lookup_Item_Parent_ID: 0,
            Parent_ID: 0,
            SortOrder: 0,
            Value: inputValue,
            isChecked: false,
            val: "",
          };
          initialState.push(result);
        dispatch(setLoeBrandName(initialState));
      }
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setItems((prev) => [
        ...prev,
        { label: newOption.label, id: newOption.value },
      ]);
      setValue(newOption);
      // if (field.lookupId == 425) {
      //   onFieldChange("DrugData_BrandLicense.Brand", inputValue.trim(), parentObject, index);
      // }
      if (isHTASubGroup) {
        onFieldChange("SubGroupID", newOption.value, parentObject, index);
      } else {
        onFieldChange(field.name, newOption.value);
      }
      if (field.lookupId == 425) {
        let obj = {
          Id: 0,
          Value: inputValue.trim(),
        };
        onFieldChange('DrugData_BrandLicense.RegionalDrug_ID_Text', obj);
      }
    });

    setIsLoading(false);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setIsLoading(false);
    //   setOptions((prev) => [...prev, newOption]);
    //   // setItems((prev) => [...prev, newOption]);
    //   setValue(newOption);
    // }, 1000);
  };

  useEffect(() => {
    if (
      field.name == "CountryList_ID_Text" &&
      !!values &&
      values.CountryList_ID_Text
    ) {
      const val = options.filter((x) => String(x.value) == values[field.name]);
      setValue(val[0]);
    } else if (
      field.name == "ReimbursementAuthority_ID_Text" &&
      !!values &&
      values.ReimbursementAuthority_ID_Text
    ) {
      const val = options.filter((x) => String(x.value) == values[field.name]);
      setValue(val[0]);
    }
  }, []);

  useEffect(() => {
    if (value != null && value.value != "0") {
      if (isHTASubGroup) {
        onFieldChange("SubGroupID", value.value, parentObject, index);
      }
      else if (value.value == "0") {
        onFieldChange("DrugData_BrandLicense.Brand", value.value, parentObject, index);
      }
      if (field.name.includes('DrugData_BrandLicense.RegionalDrug_ID_Text')) {
        let obj = {
          Id: Number(value.value),
          Value: value.label,
        };
        onFieldChange("DrugData_BrandLicense.RegionalDrug_ID_Text", obj, values['DrugData_BrandLicense'], index);
      }
      //  else{
      //     onFieldChange(field.name, value.value);
      //   }
    }
  }, [value]);

  const handleOnChange = (item: any, fieldName: string) => {
    setValue(item);
    console.log(fieldName, "!!!");
    if (
      fieldName === "GlobalBrand_ID_Text" ||
      fieldName === "DrugData_BrandLicense.RegionalDrug_ID_Text"
    ) {
      if(createDataNewModal)
        createDataNewModal(item.value);
    }
    if (Object.entries(item).length > 0 && item.value != "0") {
      if (fieldName === "GlobalBrand_ID_Text") {
        onFieldChange("GlobalBrand_ID_Text", item.value);
      } else if (fieldName === "GlobalCorporation_ID_Text") {
        onFieldChange("GlobalCorporation_ID_Text", item.value);
      } else if (fieldName === "ReimbursementAuthority_ID_Text") {
        onFieldChange("ReimbursementAuthority_ID_Text", item.value);
      } else if (fieldName.includes("DrugData_BrandLicense")) {
        let obj = {
          Id: Number(item.value),
          Value: item.label,
        };
        onFieldChange(fieldName, obj);
      } else {
        onFieldChange(fieldName, item.value);
      }
    }
  };

  useEffect(() => {
    if (resetValue) setValue(null);
  }, [resetValue]);

  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={async (newValue:any) => {
        if (
          field.name === "GlobalBrand_ID_Text" ||
          field.name === "DrugData_BrandLicense.RegionalDrug_ID_Text" ||
          field.name === "GlobalCorporation_ID_Text" ||
          field.name === "ReimbursementAuthority_ID_Text"
        ) {
          setValue(newValue);
          handleOnChange(newValue, field.name);
        } else {
          let res = false;
          if (newValue != null && newValue.value != "0") {
            if (field.name == "CountryList_ID_Text") {
              res = await onFieldChange(field.name, newValue);
            } else {
              // res = await onFieldChange(field.name, newValue.value);

              if(isHTASubGroup) 
              {
                res = await onFieldChange('SubGroupID', newValue.value, parentObject, index);
              }else{
                res = await onFieldChange(field.name, newValue.value);
              }
            }
          }
          // if (res) setValue(newValue);
          // else setValue(null);
          setValue(newValue);
        }
      }}
      onCreateOption={handleCreate}
      options={options}
      value={value}
      required={field.name == "DrugData_BrandLicense.RegionalDrug_ID_Text" ? true : false}
      placeholder={
        field.name === "DrugData_BrandLicense.RegionalDrug_ID_Text"
          ? field.label
          : ""
      }
    />
  );
};
