import axios from 'axios';
import { cal_sda, rootURL } from '../Logout/Login';

const axiosInstance = axios.create({
      baseURL : process.env.REACT_APP_API_URL,
})

axiosInstance.interceptors.request.use(
      async (config : any) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) =>{
        console.log('Retrying request:', error.config);
        return Promise.reject(error)
      } 
    );

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error.message.includes('Network Error')) {
          window.location.href = `${rootURL}/error!`;  
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const newToken = await refreshAuthToken();
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
      }
    );

   
const redirect = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  cal_sda()
}

const refreshAuthToken = async () => {
      try {
        const refresh_token = localStorage.getItem('refresh_token');
        if(refresh_token){
          const response = await axios.post(process.env.REACT_APP_API_URL + '/api/refresh', {
            refresh_token: localStorage.getItem('refresh_token')
          });
          if (response.data.access_token)
            localStorage.setItem('token', response.data.access_token);
          return response.data.access_token;
        }else
          redirect();
      } catch (error : any) {
          if(error.response.status === 401 ){
            console.error('Failed to refresh token', error);
            localStorage.setItem("redirect", "true");
            redirect()
          }
          else
            throw error;

        
        // Handle token refresh failure (e.g., redirect to login)
      }
};



export default axiosInstance;