import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySplitApi } from "./emptyApiSlice";
import { IfilterState } from "../components/Dashboard/dashboard";
import { _token } from "../components/service/apiCall";

export interface NewsResultState {
  searchNewsData: any[];
  isLoading: boolean;
  filterState: IfilterState;
}

const initialState: NewsResultState = {
  searchNewsData: [],
  isLoading: false,
  filterState: {},
};

export const ClientNewsSlice = createSlice({
  name: "ClientNews",
  initialState,
  reducers: {
    saveFilterNewsData: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
    },
    saveNewsData: (state, action: PayloadAction<any[]>) => {
      console.log("Entire news Data :", action.payload);
      state.searchNewsData = action.payload;
      state.isLoading = false;
    },
    setNewsSpinner: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});
export const { saveFilterNewsData, saveNewsData, setNewsSpinner } = ClientNewsSlice.actions;

export default ClientNewsSlice.reducer;

export const ClientNewsApiSlice = emptySplitApi.injectEndpoints({
  endpoints(build) {
    return {
      fetchClientNews: build.mutation({
        query: () => {
          return {
            method: "GET",
            url: `api/clientnews/latestHomeClientNews/find`,
          };
        },
      }),
      fetchAllClientNews: build.mutation<any,{activeArticle : boolean, isAdmin?: boolean}>({
        query: (args) => {
          const {activeArticle, isAdmin} = args
          return {
            method: "GET",
            url: `api/clientnews/getAllClientNews/find/${activeArticle}/${isAdmin}`,
          };
        },
      }),
      fetchSpecificClientNews: build.mutation<any, { clientnewsId: number }>({
        query: (args) => {
          const { clientnewsId } = args;
          return {
            url: `api/clientnews/getSpecificClientNews/${clientnewsId}`,
            method: "GET",
          };
        },
      }),
      filteringClientNews: build.mutation({
        query: (params: any) => {
          const {
            AgencyAbbreviationIDs,
            CountryIDs,
            DeviceIDs,
            DrugIDs,
            ManufacturerIDs,
            DiseaseIDs,
            CategoryIDs,
            ArticleDateStart,
            ArticleDateEnd
          } = params;
          //console.log("params :", params);
          return {
            method: "GET",
            url: `api/clientnews/filteringClientNews/${AgencyAbbreviationIDs}/${CountryIDs}/${DeviceIDs}/${DrugIDs}/${ManufacturerIDs}/${DiseaseIDs}/${CategoryIDs}/${ArticleDateStart}/${ArticleDateEnd}`,
          };
        },
      }),
    };
  },
});
export const {
  useFetchClientNewsMutation,
  useFetchAllClientNewsMutation,
  useFetchSpecificClientNewsMutation,
  useFilteringClientNewsMutation,
} = ClientNewsApiSlice;
