import React, { useEffect, useState } from "react";
import Element from "./Utility";
import { useAppSelector } from "../../../../store/hooks";

interface ICoaInstrumentForClinicalDataProp {
  fieldInfo: any[];
  datasourceName: string;
  section: string;
  showEmptyFieldsFlag: boolean
}
const CoaInstrumentForClinicalData = ({
  fieldInfo,
  datasourceName,
  section,
  showEmptyFieldsFlag
}: ICoaInstrumentForClinicalDataProp) => {
  const [field, setField] = useState<any[]>([]);
  const [CoaInstrument, setCoaInstrument] = useState<any>([]);
  const subPageDate = useAppSelector((state) => state.multiselect.subpageData);
  useEffect(() => {
    if (field.length == 0 && fieldInfo.length > 0) {
      setField(fieldInfo);
    }
  }, [fieldInfo]);

  useEffect(() => {
    setCoaInstrument(subPageDate["coa_instrument"]);
  }, [subPageDate]);

  return (
    <>
      {!!CoaInstrument && CoaInstrument.map((el: any, index: number) => (
        <div className="col-sm-12" style={{marginTop:"15px"}}>
          <b style={{fontSize:"14px"}}>COA instrument {index + 1}</b>
          <hr />
          {field
            .filter((x) => x.name === "COA_Endpoints.InstrumentID")
            .map((val) => (
              <Element
                label={val.label}
                fieldType={val.fieldTypeId}
                value={el["InstrumentID"]}
                datasourceName={datasourceName}
                section={section}
                description={val.description}
                showEmptyFieldsFlag={showEmptyFieldsFlag}
              />
            ))}
          <>
            {
            field
              .filter((x) => x.name === "COA_Endpoints.Significances")
              .map((val) => (
                <Element
                  label={val.label}
                  fieldType={val.fieldTypeId}
                  value={String(el["Significances"])
                    .split("`")
                    .map((element) => (
                      <>
                        <label>{element}</label>
                        <br />
                      </>
                    ))}
                  datasourceName={datasourceName}
                  section={section}
                  description={val.description}
                  showEmptyFieldsFlag={showEmptyFieldsFlag}
                />
              ))}
          </>
          <>
            {field
              .filter((x) => x.name === "COA_Endpoints.Discussiondetails")
              .map((val) => (
                  <>
                  {(el["Discussiondetails"] || showEmptyFieldsFlag) && (
                    <Element
                      label={val.label}
                      fieldType={val.fieldTypeId}
                      value={el["Discussiondetails"]}
                      datasourceName={datasourceName}
                      section={section}
                      description={val.description}
                      showEmptyFieldsFlag={showEmptyFieldsFlag}
                    />
                  )}
                </>
                )
               
              )}
          </>
          {el && el[el["ID"]] == undefined && (
            <>
              <br/>
              <div className="col-sm-12" style={{backgroundColor: "whitesmoke", textAlign: "center"}}>
                No endpoints have been specified
              </div>
              <br/>
            </>
          )}
          {el[el["ID"]] && el[el["ID"]].length > 0 && el[el["ID"]].map((val: any) => (
            <>
              {Object.keys(val).map((key, index) => (
                <>
                  <b>Endpoint type - {key.split('_')[0]} </b>
                  {Object.keys(val[key]).length > 0 && Object.keys(val[key]).map((x: any) => {
                    // console.log("test...",val[key][x]);
                    return (
                      <>
                        <Element
                          label={"COA endpoint name"}
                          value={x}
                          datasourceName={datasourceName}
                          section={section}
                        />
                         {
                          val[key][x]["results"].map((y:any) => (
                            <> 
                            {y["Result"] && y["Result"] !='' && (
                              <Element
                                label={`Result - ${y["Value"]}`}
                                value={y["Result"]}
                                datasourceName={datasourceName}
                                section={section}
                              />
                            )}
                            </>
                          ))
                          
                        }
                        {
                          val[key][x]["relatives"].length > 0 && val[key][x]["relatives"]?.some((x:any) => x.Result) &&  <p>Relative difference(s) / Effect estimate(s) :</p>
                        }
                       
                        {                   

                          val[key][x]["relatives"].map((y:any) => (
                            <> 
                            {y["Result"] && y["Result"] !='' && (
                              <Element
                                label={y["Value"]}
                                value={y["Result"]}
                                datasourceName={datasourceName}
                                section={section}
                              />
                            )}
                            </>
                          ))
                        }
                        {/* {val[key][x].map((y: any) => (
                          <>
                            {y["Result"] && y["Result"] !='' && (
                              <Element
                                label={`Result - ${y["ResultName"]}`}
                                value={y["Result"]}
                                datasourceName={datasourceName}
                                section={section}
                              />
                            )}
                          </>
                        ))} */}

                        {/* {x["Result"] != "" && x["Result"] && (
                          <Element
                            label={`Result - ${x["Name"]}`}
                            value={x["Result"]}
                            datasourceName={datasourceName}
                            section={section}
                          />
                        )} */}
                      </>
                    );
                  })}
                </>
              ))}
            </>
          ))}
        </div>
      ))}
       {CoaInstrument && CoaInstrument.length == 0 && (
        <div className="col-sm-12" style={{backgroundColor: "whitesmoke", textAlign: "center"}}>
          No further COA information was provided
        </div>
      )}
    </>
  );
};

export default CoaInstrumentForClinicalData;
