import React, { useContext, useState, useEffect } from "react";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { UserContext } from "../../Editor";
import { Field } from "../../../../store/DataSourceMetaData";
import { EditorState } from "draft-js";
import apiCall from "../../../service/apiCall";
import { brandLicenceFormObj } from "../../../../utils";
import FieldView from "../../FieldView";
import { RiDeleteBinLine } from "react-icons/ri";
// import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
// import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setSelectedLookupGlobe } from "../../../../store/MultiSelectSlice";

interface DrugData_LovFormProps {
  fieldInfo: Field;
  values: any;
  fieldChanged: (name?: string, value?: string, obj?: any) => void;
  childRows: Field[];
  RichtextFieldChanged: (name: string, value: EditorState) => void;
  datasourcename: string;
  section: any;
  datasourceId: number;
  masterFieldTypes: number[];
  // rowsArray: any[];
  // deleteRows: Function
}

const DrugData_LovForm = (props: DrugData_LovFormProps) => {
  const {
    fieldInfo,
    values,
    fieldChanged,
    childRows,
    RichtextFieldChanged,
    datasourcename,
    section,
    datasourceId,
    masterFieldTypes,
    // rowsArray,
    // deleteRows
  } = props;
  const userContext = useContext(UserContext);

  const dispatch = useAppDispatch();
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );

  const updateLoeData = (obj: any, index: number) => {
    let loeData = [...values[fieldInfo.name]];
    loeData[index] = obj;
    fieldChanged(fieldInfo.name, "", loeData);
  };

  const deleteLoeData = (pro_index: number) => {
    let _selectedLookupGlobe = {...selectedLookupGlobe};
    for ( let i = pro_index; i < values[fieldInfo.name].length-1 ; i++) {      
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_BrandName_${i+1}`] != undefined ?
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_BrandName_${i}`] =
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_BrandName_${i+1}`] :
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_BrandName_${i}`] = [];

      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Geography_${i+1}`] != undefined ?
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Geography_${i}`] =
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Geography_${i+1}`] :
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Geography_${i}`] = [];

      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Regulator_${i+1}`] != undefined ?
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Regulator_${i}`] =
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Regulator_${i+1}`] :
      _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Regulator_${i}`] = [];
    }

    const loeDataLength = values[fieldInfo.name].length - 1;
    delete _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_BrandName_${loeDataLength}`];
    delete _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Geography_${loeDataLength}`];
    delete _selectedLookupGlobe[`DrugData_Lov.DrugData_Lov_Regulator_${loeDataLength}`];

    dispatch(setSelectedLookupGlobe(_selectedLookupGlobe));

    if (values[fieldInfo.name].length == 1) fieldChanged(fieldInfo.name, "", []);
    else {
      const loe: any[] = [...values[fieldInfo.name]];
      fieldChanged(fieldInfo.name, "", [
        ...loe.filter((item: any, xi: number) => xi != pro_index),
      ]);
    }
  };

  // console.log(childRows, "childRows", fieldInfo, "fieldInfo", values, "values");
  const [field, setField] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [rowData, setRowData] = useState<any>({});

  // Methods:
  const initialFetch = () => {
    // setRows(rowsArray);
    getData();
  };

  // useEffect(() => {
  //   initialFetch();
  // }, [rowsArray]);

  const getData = async () => {
    if (field.length == 0 && childRows.length > 0) {
      (async () => {
        const res = await apiCall("/api/sub-page/drugdata/lov/fields");
        console.log(res, "res-lov");
        if (res.data.length > 0) {
          let x = brandLicenceFormObj(res.data);
          setField(x);
        }
      })();
    }
  };
  const showInfoIcon = (field: string, description?: string, fId?: number) => {
    return (
      <FaInfoCircle
        onClick={() => {
          const showAlertFunc = async() => {
          const res = await apiCall(`/api/admin/fetch/description/${fId}/${values["Status_ID"] || 1}/${0}/${'write'}`);
          userContext.showAlert(
            <>
              <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                Drug
                <DoubleArrowIcon
                  style={{ fontSize: "18px", paddingBottom: "2px" }}
                />
                Patent information
                <DoubleArrowIcon
                  style={{
                    strokeWidth: "5",
                    fontSize: "18px",
                    paddingBottom: "2px",
                  }}
                />
                {field}
              </span>
            </>,
            "info",
            res.data,
            `/admin/instructions/${fId}`,
            true
          );
        }
        showAlertFunc()
        }}
        style={{ fontSize: "12px", marginLeft: "1px", cursor: "pointer" }}
      />
    );
  };

  // const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#f5f5f9",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: "1px solid #dadde9",
  //   },
  // }));

  const deleteRow = (num: number) => {
    console.log("rowData", rowData);
    // deleteRows(num, "lov");
    setForceUpdate((k) => k + 1);
    // let tmp = rows;
    // tmp.splice(num, 1);
    // console.log("deleteRow", tmp);
    // setRows(tmp);
  };

  return (
    <div className="DrugData_LovFormContainer">
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#d9d9d9",
          margin: "15px 0px",
        }}
      ></div>
      <div className="headerDrugData_Lov">
        <div className="title" style={{ fontWeight: "bold",fontSize: "12px",marginLeft:"17px",marginBottom:"5px"}}>
          LOE data
        </div>
        <div className="row mb-3">
          <div className="col-sm-3 required" style={{ paddingLeft: "3em"}}>
            Brand brand {showInfoIcon("Brand brand", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_Lov.DrugData_Lov_BrandName"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-3" style={{ paddingLeft: "3em"}}>
            LOE Date
            {showInfoIcon("LOE Date", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_Lov.LovDate"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-3" style={{ paddingLeft: "3em"}}>
            Regulator {showInfoIcon("Regulator", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_Lov.DrugData_Lov_Regulator"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-3" style={{ paddingLeft: "3em"}}>
            LEO geography {showInfoIcon("LEO geography", "", childRows.filter(
              (x) =>
                x.name ==
                "DrugData_Lov.DrugData_Lov_Geography"
            ).map(({id}) => id)[0])}
          </div>
          <div className="col-sm-1"></div>
        </div>
      </div>
      {values[fieldInfo.name]?.length > 0 &&
        values[fieldInfo.name] &&
        values[fieldInfo.name].map((el: any, index: number) => (
          <span key={`lov_${index}`}>
            <div className="row mt-3">
                {childRows
                  .map((row,ri) => (
                    <div className="col-sm-3" key={ri} style={{paddingLeft: "3em"}}>
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[fieldInfo.name][index]}
                        setObj={updateLoeData}
                        index={index}
                        hideTitleLabel={true}
                      />
                    </div>
                  ))}
            </div>
            <div style={{ textAlign: "right", marginTop: "35px", marginBottom: 10 }}>
              <span>
                  <RiDeleteBinLine className="iqvia-color Delete-Icon-styles cursor-pointer" title="Remove this LOV data"
                    onClick={() => deleteLoeData(index)}
                  />
              </span>
              <span style={{ padding: 10, borderLeft: "1px dotted gray" }}>
                {index + 1}
              </span>
            </div>
          </span>
        ))}
    </div>
  );
};

export default DrugData_LovForm;
