// import React, { useEffect, useState } from "react";
// import { Route, Switch } from "react-router";
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
// import { BrowserRouter } from "react-router-dom";
// import {useSendSupportEmailMutation} from '../../store/UserProfileApiSlice';
// // import { useNavigate } from "react-router-dom";
// // @ts-ignore
// import { convert } from 'html-to-text';
// import { ToastrAlert } from "../ToastrAlert/ToastrAlert";
// import stylesCS from './ContactUs.module.css';
// // import { htmlToText } from 'html-to-text';
// // import { useParams } from "react-router";
// // import EditorConvertToHTML from "../RichTextEditor/ReactRTE";
// import TMEditor from "../RichTextEditor/TinyMCEEditor";
// import {NavbarBrand} from "reactstrap";
// import logo from "../../img/hta-logo.png";
// // import { useNavigate } from "react-router-dom";
// interface formStateType {
//     name: string;
//     email: string;
//     subject:string;
//     message: string;
//     html: string;
//     highPriority: boolean;
// }
// // const demoparthistory=useHistory()

// // const[formValidationError,setFormValidationError]=useState<any>()

// //we are having function check whether values there or not:


// const ContactUs = () => {
//     const [sendSupportEmail] = useSendSupportEmailMutation();
//     const toEmail = 'atul.kumar@iqvia.com';
   
//     const [formState, setFormState] = useState<formStateType>({name: '', email: '', subject:'', message: '', html: '', highPriority: false});
//     const [ emailContent, setEmailContent] = useState('');
//     // console.log("formState", formState)
//     useEffect(()=>{
//         const token = localStorage.getItem("token") || '';
//         const tokenDetails = JSON.parse(atob(token.split('.')[1]));
//         setFormState({...formState, email: tokenDetails.email || ''});
//     },[]);

//     const fieldChanged = (name: string , value: any) => {
//         setEmailText(value);
//         setEmailContent(value);
//     }
//     const setEmailText = (msg: string) => {
//         // const emailMessage = msg.replace(/<[^>]+>/g, ''); // htmlToText(msg);
//         const emailMessage = convert(msg);
//         // console.log('emailMessage: ', emailMessage);
//         // console.log('msg: ',msg);
//         setFormState({...formState, message:emailMessage, html:msg});
//     }

//     const changeHandler = (event: any) => {
//         if(event.target.name == 'highPriority'){
//             setFormState({...formState, [event.target.name]: !formState.highPriority});
//         }else{
//             setFormState({...formState, [event.target.name]:event.target.value});
//         }
//     }

//     // const submitHandler = (event:any) => {
//     //     const config = {
//     //         SecureToken :"405a69eb-d4e5-4b3b-8923-6b0ba557c2f6",
//     //         To : toEmail,
//     //         From : formState.email || 'namitarohan.daduria@iqvia.com',
//     //         Subject : formState.subject || "HTAA e-mail support !",
//     //         Body : formState.message || 'Test support email'
//     //     };
//     //     event.preventDefault();
//     //     if(window.Email){
//     //         window.Email.send(config).then(()=> alert('Email sent!!!'));
//     //     }
//     // }
//     const[formValidationError,setFormValidationError]=useState<any>({})
//     // const validateForm=()=>{
//     //     let err={}
//     //     if(formState.subject===''){
//     //         err.subject='Its an required'
//     //     }
//     //     setFormValidationError({... err})
//     //     return false;
//     // }
//     // console.log("Validateformcheck")

//     const submitHandler = (event: any) => {
//         // let isValid=validateForm()
//         // console.log("Validation",isValid)
//         if(formState.subject==='' && formState.message===''){
//             ToastrAlert("Both email subject and message are required", "warning");
//             return false;

//         }
//         // else if(formState.message===''){
//         //     ToastrAlert("Message is required","warning");
//         //     return false;
//         // }
        
      
//         const body = {
//             to:toEmail,
//             from: formState.email,
//             subject: formState.subject,
//             text: formState.message,
//             html: formState.html,
//             highPriority: formState.highPriority,
//         }
      
//         sendMail(body);
//         alert('Mail sent. You can close this window/tab.')
//         console.log("Form data inputs",formState)
     
//     }

//     const sendMail = async (body: any) => {
//         try{
//             const emailStatus = await sendSupportEmail(body);
//             console.log(emailStatus);
//             window.location.href = "/help";
//         }catch(e){
//             console.log(e);
//             window.location.href = "/help";  
//         }
        
//       };
//       console.log("Subject string values", formState.subject)

//     return(
//     <>
//     <div style={{borderBottom:"1px solid #ccc",backgroundColor:"white",marginLeft:"-22px",marginRight:"-25px"}}>
//         <NavbarBrand href="/" style={{ minWidth: "fit-content",paddingTop: '5px' }}>
//                   <img
//                     src={logo}
//                     alt="IQVIA | HTA Accelerator"
//                     style={{ width: "18em",paddingBottom:"20px" }}
//                   />
//         </NavbarBrand>
//     </div>  
//         <div className="form-horizontal" style={{padding: '35px'}}>
//             <h4 style={{fontSize: '18px',fontWeight:'bold'}}>HTAA E-mail support</h4>
//             <hr/>
//             <div className={stylesCS.contactUs}>
//                 <table style={{width: '100%'}}>
//                     <tbody>
//                     <tr>
//                         <td><label>To </label></td>
//                         <td colSpan={2}>
//                             <input
//                                 type="text"
//                                 className={stylesCS.helpPage}
//                                 readOnly
//                                 name="to"
//                                 value={toEmail}
//                                style={{ width: "100%", backgroundColor: "#f5f5f5", lineHeight: "1.5rem",border:"1px solid #d5d5d5",color: "#939192",outline: "none" }}
//                             ></input>
//                         </td>
//                     </tr>
//                     {/* <tr>
//                         <td><label>From </label></td>
//                         <td colSpan={2}>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 placeholder="Your Email (xyz@iqvia.com)"
//                                 readOnly
//                                 value={formState.email || ''}
//                                 onChange={changeHandler}
//                                 style={{ width: "100%", backgroundColor: "lightgray", lineHeight: "1.5rem" }}
//                             ></input>
//                         </td>
//                     </tr> */}
//                     <tr>
//                         <td style={{paddingLeft:"0px"}}><label className={stylesCS.required}>Subject </label></td>
//                         <td colSpan={2}>
//                             <input
//                             required={true}
//                                 type="text"
//                                 name="subject"
//                                 placeholder="HTAA support request"
//                                 className={stylesCS.helpPage}
//                                 value={formState.subject || ''}
//                                 onChange={changeHandler}
//                                 style={{ width: "100%", lineHeight: "1.5rem",border:"1px solid #d5d5d5",color: "#939192",outline: "none"}} 
                                 
//                             ></input>
//                             {/* <span className="non-valid">{formValidationError.subject}</span> */}
//                                 {/* <span className="field-validation-error text-danger">Subject is required</span> */}
                            
//                         </td>
//                     </tr>
//                     <tr>
//                         <td><label>Hight Priority </label></td>
//                         <td colSpan={2}>
//                             <input
//                                 type="checkbox"
//                                 name="highPriority"
//                                 checked={formState.highPriority}
//                                 onChange={changeHandler}
//                                 style={{lineHeight: "1.5rem" }}
                                
//                             ></input>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td colSpan={3}>
//                             <label>Message</label>
//                             <textarea name="message" hidden readOnly value={formState.message}/>
//                             {/* <EditorConvertToHTML setEditorText={setEmailText}/> */}
//                             <TMEditor
//                                 name={"Email_Support"}
//                                 value={emailContent}
//                                 fieldChanged={fieldChanged}                           
//                             />
                            
//                             <br/>
//                         </td>                    
//                     </tr>
//                     </tbody>
//                 </table>

//                 <div >
//                     <input
//                         type="button"
//                         value="Send"
//                         className="button-upload"
//                         style={{marginRight: "-10px", border: "1px solid rgb(0, 170, 231)", width:"62px", height:"40px" }}
//                         onClick={submitHandler}
//                     ></input>
//                     {/* <input
//                         type="button"
//                         value="Cancel"
//                         className="button-cancel"
//                         style={{marginRight: "10px", borderRadius: "3px" }}
//                         // style={{ backgroundColor: "#0056b3", color:"white", borderRadius: "3px" }}
//                         onClick={onSubmit}
//                     ></input> */}
//                     {/* <a href="/" id="cancel" className="btn btn-default btn-large">Cancel</a> */}
//                     <button className={stylesCS.helpButtonpage} style={{backgroundColor:'#00aae7', border: "1px solid rgb(0, 170, 231)", paddingLeft:"6px", marginLeft:"13px", width:"62px", height:"40px"}}>
//                     <Link style={{textDecoration: 'none', color:'#FFFFFF'}} to={'/'} className="button-cancel">Cancel</Link>
//                     </button>
//                 </div>
//             </div>
//         </div>
//         </>
//     );
// }

// export default ContactUs;



import React, { useEffect, useState, ChangeEvent } from "react";
import { useSendSupportEmailMutation } from '../../store/UserProfileApiSlice';
import { convert } from 'html-to-text';
import { ToastrAlert } from "../ToastrAlert/ToastrAlert";
import TMEditor from "../RichTextEditor/TinyMCEEditor";
import { NavbarBrand } from "reactstrap";
import logo from "../../img/hta-logo.png";
import {
  Container,
  NavbarImage,
  FormWrapper,
  FormTitle,
  FormSection,
  Input,
  CheckboxInput,
  TextArea,
  Button,
  CancelButton,
  CancelLink
} from './ContactUs.styles';

interface FormStateType {
  name: string;
  email: string;
  subject: string;
  message: string;
  html: string;
  highPriority: boolean;
}

const ContactUs: React.FC = () => {
  const [sendSupportEmail] = useSendSupportEmailMutation();
  const toEmail = 'atul.kumar@iqvia.com';
  const [formState, setFormState] = useState<FormStateType>({
    name: '',
    email: '',
    subject: '',
    message: '',
    html: '',
    highPriority: false
  });
  const [emailContent, setEmailContent] = useState<string>('');

  useEffect(() => {
    const token = localStorage.getItem("token") || '';
    const tokenDetails = JSON.parse(atob(token.split('.')[1]));
    setFormState({ ...formState, email: tokenDetails.email || '' });
  }, []);

  const fieldChanged = (name: string, value: any) => {
    setEmailText(value);
    setEmailContent(value);
  }

  const setEmailText = (msg: string) => {
    const emailMessage = convert(msg);
    setFormState({ ...formState, message: emailMessage, html: msg });
  }

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  }

  const submitHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    if (formState.subject.trim() === '') {
      ToastrAlert("Email subject is required", "warning");
      return;
    }

    if (formState.message.trim() === '') {
      ToastrAlert("Email message is required", "warning");
      return;
    }
    const body = {
      to: toEmail,
      from: formState.email,
      subject: formState.subject,
      text: formState.message,
      html: formState.html,
      highPriority: formState.highPriority,
    }

    sendMail(body);
    alert('Mail sent. You can close this window/tab.');
  }

  const sendMail = async (body: any) => {
    try {
      const emailStatus = await sendSupportEmail(body);
      console.log(emailStatus);
      window.location.href = "/help";
    } catch (e) {
      console.log(e);
      window.location.href = "/help";
    }
  };

  return (
    <>
      <Container>
        <NavbarBrand href="/" style={{ minWidth: "fit-content", paddingTop: '5px' }}>
          <NavbarImage src={logo} alt="IQVIA | HTA Accelerator" />
        </NavbarBrand>
      </Container>
      <FormWrapper>
        <FormTitle>HTAA E-mail support</FormTitle>
        <hr />
        <FormSection>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td><label>To </label></td>
                <td colSpan={2}>
                  <Input
                    type="text"
                    readOnly
                    name="to"
                    value={toEmail}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "0px" }}><label className="required">Subject </label></td>
                <td colSpan={2}>
                  <Input
                    required
                    type="text"
                    name="subject"
                    placeholder="HTAA support request"
                    value={formState.subject || ''}
                    onChange={changeHandler}
                  />
                </td>
              </tr>
              <tr>
                <td><label>High Priority </label></td>
                <td colSpan={2}>
                  <CheckboxInput
                    type="checkbox"
                    name="highPriority"
                    checked={formState.highPriority}
                    onChange={changeHandler}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <label>Message</label>
                  <TextArea name="message" readOnly value={formState.message} />
                  <TMEditor
                    name={"Email_Support"}
                    value={emailContent}
                    fieldChanged={fieldChanged}
                  />
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <Button
              type="button"
              value="Send"
              onClick={submitHandler}
            />
            <CancelButton>
              <CancelLink to="/">Cancel</CancelLink>
            </CancelButton>
          </div>
        </FormSection>
      </FormWrapper>
    </>
  );
}

export default ContactUs;

