import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import TableGrid from "../../Table/TableGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { addDate } from "../../../utils/index";
import { AgGridReact } from "ag-grid-react";

const ClinicalData = () => {
  const state = useAppSelector((state: any) => state);
  const [rowData] = useState<any[]>(state.productProfile.clinicalData);

  const columnDefs = [
    {
      headerName: "Primary completion date",
      field: "SubDate",
      cellRenderer: (params: any) => {
        const dateArray =
          params.value !== null || params.value !== undefined
            ? addDate(params.value)
            : "-";
        return (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              display: "inline-block",
            }}
          >
            {" "}
            {dateArray}
          </span>
        );
      },
      wrapText: true,
      autoHeight: true,
      minWidth: 220,
    },
    {
      headerName: "Trial",
      field: "Acronym",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Trial phase",
      field: "TrialPhaseLIValue",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Design",
      field: "DesignLIValue",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Disease",
      field: "DiseaseNames",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Active comparator",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      field: "DrugControlNames",
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Primary endpoints",
      field: "PrimaryEndPointNames",
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        if (params.value !== null) {
          const endpoints = params.value
            .split(",")
            .map((endpoint: any, index: number) => (
              <li key={index}>{endpoint.trim()}</li>
            ));
          return <ul className="bullet-list">{endpoints}</ul>;
        } else return <span></span>;
      },
      cellStyle: {
        wordBreak: "break-word",
      },
    },
    {
      headerName: "Met primary endpoint?",
      field: "PrimaryEndPointNames",
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        const hasEndpoints = params.value && params.value.trim().length > 0;
        return hasEndpoints ? "Yes" : "No";
      },
      minWidth: 180,
    },
    {
      headerName: "PRO/QoL instruments",
      field: "QolInstrumentNames",
      cellRenderer: (params: any) => {
        const text = params.value !== null ? params.value : "-";
        return <span> {text}</span>;
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        wordBreak: "break-word",
      },
      minWidth: 200,
    },
    {
      headerName: "",
      field: "ClinicalData_ID",
      cellRendererFramework: (params: any) => (
        <a
          href={`/view/2/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      ),
      maxWidth: 50,
      width: 50,
      minWidth: 50,
    },
  ];

  return (
    <div className="clinicalDataContainer">
      {rowData.length > 0 ? (
        <div
          className="ag-theme-alpine"
          style={{ height: "600px", width: "100%" }}
        >
          <TableGrid
            tableName={"ProductProfile"}
            columnDefs={columnDefs}
            rowData={rowData}
            isLoading={false}
            pageSize={10}
          />
        </div>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: "200px", width: "90%", marginTop: 10 }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            overlayNoRowsTemplate={"No data available in table"}
          />
        </div>
      )}
    </div>
  );
};

export default ClinicalData;
