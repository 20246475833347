import React, { useEffect, createContext, useContext, useState } from "react";
import { useRouteMatch } from "react-router";
import Editor from "../Forms/Editor";

function Form() {
  // let dataSourceID: number = 0;
  // let recordID: number = 0;
  // const routeMatch:any = useRouteMatch();
  // dataSourceID = Number(routeMatch.params.dataSourceID);
  // recordID = Number(routeMatch.params.recordID);

  // const [dsId, setDsId] = useState<number>(0);
  // const [recId, setRecId] = useState<number>(0);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  // useEffect(()=>{
  //   setIsLoading(true);
  //   setDsId(dataSourceID);

  //   setTimeout(()=> setIsLoading(false), 150);
  //   console.log("testing form")
  // }, [dataSourceID,recordID]);

  
  // useEffect(()=>{
  //   setIsLoading(true);
  //   setRecId(recordID);
  //   setTimeout(()=> setIsLoading(false), 150);
  //   console.log("testing form 2")
  // }, [recordID]);

  return <><Editor /></>;
}

export default Form;
