import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
  } from "react";
  import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
  import Spinner from "react-bootstrap/Spinner";
  import { useAppDispatch, useAppSelector } from "../../store/hooks";
  import { setSelectedColumnFiters, setFilterColumnTypes, setColumnFilters, setColumnFilterApplied } from "../../store/SearchSlice";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { IconProp } from "@fortawesome/fontawesome-svg-core";
  import { faRefresh } from "@fortawesome/free-solid-svg-icons";
  import { useGetColumnFilterValuesMutation } from "../../store/SearchResultApiSlice";
  import { convert } from "html-to-text";
  import { removeExtraNull } from "./Utility";
  
  interface MultiColumnFilterParams extends IFilterParams {
    datasourcId?: number;
    columnType?: string | null;
    closeFilterPopup?: Function | null;
  }
  export default forwardRef((props: MultiColumnFilterParams, ref) => {
    const [filterText, setFilterText] = useState<string>("");
    const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
    const [allFilters, setAllFilters] = useState<any[]>([]);
  
    const { column,  columnType, datasourcId = 0 } =
      props;
  
    const isDate = columnType && columnType === "QHTASearch_mRenderDate";
  
    const dispatch = useAppDispatch();
    const selectedColFilters = useAppSelector((state) => state.search.selectedColumnFilter);
    const allColumnfilterValues = useAppSelector(
      (state) => state.search.columnFilterData
    );
    const columnfilterValues = useAppSelector(
      (state) => state.search.columnFilterData[column.colId]
    );
  
    const filterColumnTypes = useAppSelector((state) => state.search.filterColumnTypes);
    const currentAccountId = useAppSelector(
      (state) => state.userProfile.accountId
    );
    const activeColumnFilter = useAppSelector(
      (state) => state.search.activeColumnFilter);
  
    const [getColumnFilterValues, { isLoading: isColumnLoading }] = useGetColumnFilterValuesMutation();
    const GetColumFilterForSearch = async (payload: any) => {
      
      const colFilter = await getColumnFilterValues(payload);
      const valueArray = colFilter.data[column.colId];
      const values = [...new Set([...valueArray])];
      if(selectedColFilters.hasOwnProperty(column.colId)){
        let selectedValues = selectedColFilters[column.colId];
        if(selectedColFilters[column.colId].includes(null) && selectedColFilters[column.colId].includes('')){
          selectedValues = selectedValues.filter((x) => x!=='');
        }
          // Create a set from array2 for faster lookup
          const setArray2 = new Set(selectedValues);
        
          // Separate elements that are in array2 and not in array2
          const result = [
            ...selectedValues.filter(item => setArray2.has(item)),  // elements from array2 that exist in array1
            ...values.filter((item: any) => !setArray2.has(item))  // remaining elements in array1 that are not in array2
          ];
  
        const filterValues = {...allColumnfilterValues, [column.colId]: result};

      setAllFilters(result);
      dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
      }else{
        const filterValues = {...allColumnfilterValues, [column.colId]: values};
        
        setAllFilters(values);
        dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
      }
      
    }
    useEffect(()=> {
      // if(!allColumnfilterValues.hasOwnProperty(column.colId)){
      if(activeColumnFilter && activeColumnFilter === column.colId && !allColumnfilterValues.hasOwnProperty(column.colId)){
        //fetch columnfilterValues here
        GetColumFilterForSearch({
          accountID: currentAccountId,
          datasourceId: datasourcId,
          columns: [`${column.colId}`],
          colFieldRenderMethod: [`${columnType}`]
        });
      }    
      //set selected values
      if(selectedColFilters.hasOwnProperty(column.colId)){
        setSelectedFilters(selectedColFilters[column.colId]);
        if(selectedColFilters[column.colId].includes(null) || selectedColFilters[column.colId].includes('')){
          setBlankCheck(true);
        }
        props.filterChangedCallback();
      }
        
    },[activeColumnFilter]);
  
    useEffect(() => {
      if (allFilters && allFilters.length == 0 && columnfilterValues) {
        setAllFilters(columnfilterValues);   
      }
    }, [columnfilterValues]);
  
    useImperativeHandle(ref, () => {
      return {
        isFilterActive() {
          if([1,2,3,4,5,6,7,20,23,25].includes(datasourcId)){
            const localFilter = selectedFilters.length > 0;
            const filtersForColumn = selectedColFilters[column.colId]?.length > 0;
            return localFilter || filtersForColumn;
            // filtersForColumn && filtersForColumn.length > 0;
          }
          return selectedFilters.length > 0;
        },
  
        doesFilterPass(params: IDoesFilterPassParams) {
          const { node } = params;
  
          if (filterText == "~") {
            setTimeout(() => {
              setFilterText("");
            }, 500);
          }
          if (selectedFilters.length == 0) return true;
          
          let passed = true;
          function convertDateFormat(dateString: string) {
            const [day, month, year] = dateString.split('-');
            return `${year}-${month}-${day}`;
        }
          if(isDate){
            passed=selectedFilters.some((x) => {
              const dateString = x !== null ? convertDateFormat(x) : null;
               return dateString == node.data[column.colId]
              });
            }else{
            passed = selectedFilters.some((x) => {
              if (x === null || x === '') {
                return node.data[ column.colId ] === '' || node.data[ column.colId ] === null;
              }
              else if (typeof(x) == "string"){
                const value = node.data[ column.colId ]?.replace(/(\r\n|\n|\r)/gm, " ");
                // .replace(/\s+/g, '');
                const y = x
                // .replace(/\s+/g, '');
                if (value == y)
                  return true
              } 
              return typeof(x)!= "boolean" && !isNaN(x) ? x == node.data[column.colId] : x === node.data[column.colId]});
          }
          return passed;     
      },
  
        getModel() {
            if (!this.isFilterActive()) {
              return null;
            }
    
            let filterVal = "";
    
            filterVal =
              filterText == "true" || filterText == "false"
                ? JSON.parse(filterText.toLowerCase())
                : filterText?.trim();
    
            return { value: filterVal };        
        },
  
        setModel(model: any) {                    
            setSelectedFilters(model?.value || []);          
        },
  
        onFilterChanged(){
          props.filterChangedCallback();
        }
      };
    });
  
    const onChange = (value: any) => {
      setFilterText(value);
    };
  
  
    useEffect(() => {
      
      const selectedValuesWithoutEmpltyString = selectedFilters.filter(x => x !== '');
      if(filterText && selectedFilters.length > 0){
        const filteredSelected = allFilters.map((val) => {
          if (val === null || val === "" || val === "[Blank]") {
            return null;
          } else {
            return val == "true" || val == "false"
              ? JSON.parse(val.toLowerCase())
              : (typeof (val) == "string" ? val.trim() : val);
          }
        })
        .filter(
          (x) =>          
            (filterText &&
              (x != undefined && x != null) &&
              x
                .toString()
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) >= 0)
        );
  
        if("[blank]".includes(filterText.toLowerCase()) && allFilters.includes(null) && !filteredSelected.includes(null)){
          filteredSelected.push(null);
        }
        if(filteredSelected.length != 0 && selectedFilters.length >= filteredSelected.length){ // incase when you filter and do select all on multiple value 2018, 2017
          let setAll = true;
            const set1 = new Set(filteredSelected);
            const set2 = new Set(selectedFilters);
            for (let item of set1) {
                if (!set2.has(item)){
                  setAll = false;
                }
            }
                 
          setCheckSelectAll(setAll);
        }else{
          setCheckSelectAll(false);
        }
          
      }
      else if(allFilters && allFilters.length > 0 && selectedValuesWithoutEmpltyString &&  selectedValuesWithoutEmpltyString.length === allFilters.length){
        setCheckSelectAll(true);
      } else{
        setCheckSelectAll(false);
      }
    
      ![1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && props.filterChangedCallback();
    }, [filterText, selectedFilters]);

    useEffect(() => {
        if (selectedColFilters[column.colId]) {
          const selectedFiltersForColumn = selectedColFilters[column.colId];
          setSelectedFilters(selectedFiltersForColumn);
      
          // Ensure checkSelectAll is calculated only when allFilters is populated
          if (allFilters.length > 0) {
            setCheckSelectAll(selectedFiltersForColumn.length === allFilters.length);
          }
      
          // Check if null or empty string is included in selected filters
          setBlankCheck(selectedFiltersForColumn.some((filter) => filter === null || filter === ''));
        }
      }, [selectedColFilters, column.colId, allFilters]);
  
    const applyFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    
      event.stopPropagation();
    
      const normalizedFilters = removeExtraNull(selectedFilters.map((filter) =>
        filter === "" ? null : filter
      ));

    
      if ([1, 2, 3, 4, 5, 6, 7, 20, 23, 25].includes(datasourcId)) {
        // const updatedSelectedFilters = { ...selectedColFilters, [column.colId]: normalizedFilters };
        // const updatedFilterTypes = { ...filterColumnTypes, [column.colId]: columnType };

        let selectedFilterData = {...selectedColFilters};
        let filterTypes = {...filterColumnTypes};
        if(normalizedFilters.length == 0){
          delete selectedFilterData[column.colId];
          delete filterTypes[column.colId];
        }else{
          selectedFilterData = {...selectedColFilters, [column.colId]: normalizedFilters};
          filterTypes = {...filterColumnTypes, [column.colId]: columnType};
        }
    
        dispatch(setSelectedColumnFiters(selectedFilterData));
        dispatch(setFilterColumnTypes(filterTypes));



        //bring selectedvalues on top
        let selectedValues = [...normalizedFilters];

        const setArray2 = new Set(selectedValues);
        const selectedCol = allFilters.filter(item => setArray2.has(item));
        const unselectedCol = allFilters.filter((item: any) => !setArray2.has(item)) ;
        
        const result = [
          ...selectedCol,  
          ...unselectedCol  
        ];
  
        const filterValues = {...allColumnfilterValues, [column.colId]: result};  
        
        setAllFilters(result);
        dispatch(setColumnFilters({data: filterValues, from: "SearchTable"}));
      }
    
      dispatch(setColumnFilterApplied(true));
      props.filterChangedCallback();
      props.closeFilterPopup?.();
    }
    const [checkSelectAll, setCheckSelectAll] = useState(false);
  
    const [blankCheck, setBlankCheck] = useState(false);
  
    const closeFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      props.closeFilterPopup && props.closeFilterPopup();
    };
  
    function formatNumber(input: any) {
      // Check if the input is a float
      if (input.toString().includes('.')) {
          const decimalLength = input.split('.')[1].length;
          return parseFloat(input).toFixed(decimalLength);
      } else {
          return parseInt(input);
      }
    }
  
  
    return (
      <>
      {isColumnLoading && (
        <>
          <Spinner size="sm" animation="border" style={{ color: "#00aae7" }} />
          <h6>Loading filters...</h6>
        </>
      )}
      {!isColumnLoading && allFilters && (
        <div style={{ padding: 4, width: 200, backgroundColor: "white" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            style={{
              margin: "2px 0px",
              color: "white",
              backgroundColor: "rgb(0, 170, 231)",
              border: "1px solid rgb(0, 170, 231)",
              borderRadius: "5px",
              lineHeight: "20px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              setCheckSelectAll(false);
              setBlankCheck(false);
              setSelectedFilters([]);
            }}
          >
            Clear
          </button>
          {
            [1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && (
              <button
                style={{
                  margin: "2px 0px",
                  color: "white",
                  backgroundColor: "rgb(0, 170, 231)",
                  border: "1px solid rgb(0, 170, 231)",
                  borderRadius: "5px",
                  lineHeight: "20px",
                }}
                onClick={applyFilter}
              >
                Apply
              </button>
            )
          }
          {
            ![1,2,3,4,5,6,7,20,23,25].includes(datasourcId) && (
              <button
                style={{
                  margin: "2px 0px",
                  color: "white",
                  backgroundColor: "rgb(0, 170, 231)",
                  border: "1px solid rgb(0, 170, 231)",
                  borderRadius: "5px",
                  lineHeight: "20px",
                }}
                onClick={closeFilter}
              >
                Close
              </button>
            )
          }
          
        </div>
        <div>
          <input
            style={{ margin: "4 0 4 0" }}
            type="text"
            value={filterText}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search..."
            width="90%"
          />
          <FontAwesomeIcon
            onClick={() => {
              onChange("");
              setCheckSelectAll(false);
              setBlankCheck(false);
              setSelectedFilters([]);
            }}
            style={{
              fontSize: "14px",
              padding: "0px 5px",
              cursor: "pointer",
            }}
            icon={faRefresh as IconProp}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <ul style={{paddingLeft:"0px"}}>
          <li key="selectAll">
            <input
                type="checkbox"
                checked={checkSelectAll}
                onChange={(e) => {
                const isSelected = e.target.checked;
                if (isSelected) {
                    // Select all currently filtered items
                    const filteredItems = allFilters.filter(
                    (x) =>
                        !filterText ||
                        (x?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
                        (x === null && "[blank]".includes(filterText.toLowerCase())))
                    );

                    const selectedFiltered =  
                        filteredItems.map((val) => {
                        if (val === null || val === "" || val === "[Blank]") {
                            setBlankCheck(true);
                            return null;
                        } else {
                            return typeof (val) == "string" ? val.trim() : (isNaN(val)? val: formatNumber(val));
                        }
                        });

                    const appendRemaining = selectedFilters.filter(element => !selectedFiltered.includes(element));
                    const allSelected = [...appendRemaining,...selectedFiltered];
                    
                    if(filteredItems.includes(null)){
                        allSelected.push("");
                        setBlankCheck(true);
                    }
                    setSelectedFilters(allSelected);

                } else {
                    if(!filterText){
                        // Clear all selected filters
                        setSelectedFilters([]);
                        setBlankCheck(false);
                    }else{
                        const filteredItems = allFilters.filter(
                            (x) =>
                                (x?.toString().toLowerCase().includes(filterText.toLowerCase()) || 
                                (x === null && "[blank]".includes(filterText.toLowerCase())))
                            );
        
                            const selectedFiltered =  
                                filteredItems.map((val) => {
                                if (val === null || val === "" || val === "[Blank]") {
                                    setBlankCheck(false);
                                    return null;
                                } else {
                                    return typeof (val) == "string" ? val.trim() : (isNaN(val)? val: formatNumber(val));
                                }
                                });
        
                            const appendRemaining = selectedFilters.filter(element => !selectedFiltered.includes(element));
                            // const allSelected = [...appendRemaining];
                            
                            setSelectedFilters(appendRemaining);
        
                    }
                    
                }
                setCheckSelectAll(isSelected);
                }}
            />
            Select All
            </li>
            {allFilters &&
              allFilters
                .filter(
                  (x) =>
                    !filterText ||
                    (filterText &&
                      (((x != undefined && x != null) &&
                      x
                        .toString()
                        .toLowerCase()
                        .includes(filterText.toLowerCase()))
                        // .indexOf(filterText.toLowerCase()) >= 0) 
                        || ([null,'','[Blank]'].includes(x) && "[blank]".includes(filterText.toLocaleLowerCase()))) 
                ))
                .map((x,i) => (
                  <li key={i+1}>
                    {" "}
                    <input
                      type="checkbox"
                      data-value={(x === null || x === "" ) ? null : x}
                      checked={checkSelectAll ? true :
                        (x === null || x === "") ? (blankCheck) : (selectedFilters &&
                        selectedFilters.some((f) => {
                          
                          if (isDate) {
                            return f == x;
                          } else {
                            if (f === null || f === ""){ !blankCheck && setBlankCheck(true); return [null, '', '[Blank]'].includes(x)}
                            else{
                              
                              return f.toString().trim()== x.toString().trim();
                            }
                          }
  
                        }
  
                        ))
                      }
                      onChange={(e) => {
                        // const val = e.target.getAttribute("value");
                        const val = e.target.dataset.value;
                        let filterVal: any;                      
                        filterVal = isNaN(val) ? (typeof (val) == "string" ? val.trim() : val) : formatNumber(val);
                        
                        if (e.target.checked) {
                          let sf = [...selectedFilters];
                          if (val === undefined || val == null || val == "" || val == "[Blank]") {
                            sf.push(null);
                            sf.push("");
                            setBlankCheck(true);
                          } else {
                            sf.push(filterVal);
                          }
                          setSelectedFilters(sf);
                        } else {
                          setCheckSelectAll(false);
                          if (val === undefined || val === null || val === "" || val === "[Blank]") {
                            setSelectedFilters(
                              selectedFilters.filter((s) => s !== null && s !== "")
                            );
                            setBlankCheck(false);
                          } else {
                            setSelectedFilters(
                              selectedFilters.filter((s) => {
                                if(typeof(filterVal) === "string" && s != null) 
                                  return s.toString().trim().replace(/\s+/g, '') != filterVal.toString().trim().replace(/\s+/g, '');
                                else return s != filterVal;
                              })
                            );
                          }
                        }
                      }}
                    ></input>{" "}
                    {x === null || x === "" ? "[Blank]" : (typeof(x) == "string" ? `${convert(x)}` :`${x}`)}
                  </li>
                ))}
          </ul>
        </div>
      </div>
      )}
      </>
    );
  });


  