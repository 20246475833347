import React, { useEffect, useState } from 'react';
import { Field } from '../../../../store/DataSourceMetaData';
import FieldView, { lookupItemT } from '../../FieldView';
import { EditorState } from "draft-js";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAppSelector } from '../../../../store/hooks';
import { IselectedLookupSpecial } from '../../../interfaces/multiselect';
interface EndpointsAndResultsProps {
    field: Field;
    values: any;
    fieldChanged: (name?: string, value?: string, obj?: any) => void;
    childRows: Field[];
    datasourcename: string;
    section: any;
    datasourceId: number;    
    RichtextFieldChanged: (name: string, value: EditorState) => void;
  }

const EndpointsAndResults = ({field, values, childRows, fieldChanged, datasourcename, section, datasourceId, RichtextFieldChanged}: EndpointsAndResultsProps) => {
    
    const updateEndpoint = (obj: any, index: number) => {
        const endpoints = [...values[field.name]];
        endpoints[index] = obj;
        fieldChanged(field.name, "", endpoints);
    };
    const deleteEndpoint = (index: number) => {
        if (values[field.name].length == 1) return; // fieldChanged(field.name, "", []);
        else {
        const endpoints: any[] = [...values[field.name]];
        fieldChanged(field.name, "", [
            ...endpoints.filter((item: any, xi: number) => xi != index),
        ]);
        }
    };

    return(
        <>
        {values[field.name] &&
        values[field.name].map((val: any, index: number) => (
          <React.Fragment key={index+1}>
          {
            values['ClinicalData_Endpoints'][index] && values['ClinicalData_Endpoints'][index]['EndpointValue'] &&
              <div className="row" style={{marginLeft:"1em",fontWeight:"bold",marginBottom:"3em"}}>{values['ClinicalData_Endpoints'][index]['EndpointValue']}</div>
          }
          <div className="float-end">
              <div className="row">
                {childRows
                  .filter((x) =>
                    ![
                      "EndpointsConclusion_ID",
                      "ClinicalData_Endpoints",
                    ].includes(x.name)
                  )
                  .map((row, ci) => (
                    <React.Fragment key={ci}>
                      {row.name.includes('Endpoint_ID') && index != 0 &&
                        <hr className="col-sm-11" />
                      }
                      <FieldView
                        field={row}
                        values={values}
                        fieldChanged={fieldChanged}
                        RichtextFieldChanged={RichtextFieldChanged}
                        datasourcename={datasourcename}
                        section={section.name}
                        datasourceId={datasourceId}
                        parentObject={values[ field.name ][ index ]}
                        setObj={updateEndpoint}
                        index={index}
                      />
                      {row.name == "ClinicalData_Endpoints.Endpoint_ID" && (
                        <label style={{ zIndex: 10, cursor: "pointer", textAlign: "right", display: "contents" }} key={`endpoint-delete-${index}`} onClick={() => deleteEndpoint(index)}>
                          <RiDeleteBinLine style={{ color: "rgb(0, 170, 231)", fontSize: "19px", marginLeft: "-10px", marginTop: "22px" }} />
                        </label>
                      )}
                      {row.name == "ClinicalData_Endpoints.AnalysisStage_Date" && (
                        <TreatmentArmResults values={values} endpointIndex={index} updateEndpoint={updateEndpoint} />
                      )}
                    </React.Fragment>
                  )) }
                </div>
        <br />
        {/* {index != 0 && <hr />} */}
        {/* <hr /> */}
            </div>
          </React.Fragment>
        ))}
        {/* <hr /> */}
        </>
    );
}

export default EndpointsAndResults;


const TreatmentArmResults = (props: any) => {

    const {values, endpointIndex, updateEndpoint} = props;

    const [noOfArms, setNoOfArms] = useState(0);
    const [noOfCohorts, setNoOfCohorts] = useState<number>(0);
    const [cohortPresent, setCohortPresent] = useState<boolean>(false);
    const [treatementArmValues, setTreatmentArmValues] = useState<lookupItemT[]>([]);
    const selectedLookupGlobe = useAppSelector((state) => state.multiselect.selectedLookupGlobe);
    const selectedLookupGlobeSpecial  = useAppSelector((state) => state.multiselect.selectedLookupGlobeSpecial);



    let count = 0;
    useEffect(() => {
        console.log('TreatmentArmResults values: ', values );

        if(!values["CohortsPresent"] && values['ClinicalData_Endpoints'] && values['ArmsCohorts'] && values['ArmsCohorts'].length > 0){
            setCohortPresent(false);
            setNoOfCohorts(0);
            setNoOfArms(values['ArmsCohorts'].length);
            if(!values["CohortsPresent"]){
                for(const a of values['ArmsCohorts']){
                    a.Cohorts && delete a.Cohorts;
                }
                let armsAndCohortDescription = Array.from(Array(values['ArmsCohorts'].length), () => ({Description: ''}));
                const parentObj = values['ClinicalData_Endpoints'][endpointIndex];
                if(!parentObj['Endpoint_ArmsCohorts']){
                    parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                    updateEndpoint(parentObj, endpointIndex || 0);
                }
                else if(parentObj['Endpoint_ArmsCohorts'].length > values['ArmsCohorts'].length){
                    armsAndCohortDescription = parentObj['Endpoint_ArmsCohorts'].slice(0,values['ArmsCohorts'].length-1);
                    parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                    updateEndpoint(parentObj, endpointIndex || 0);
                }
                else if(parentObj['Endpoint_ArmsCohorts'].length < values['ArmsCohorts'].length){
                    armsAndCohortDescription = parentObj['Endpoint_ArmsCohorts'];
                    for(let i = parentObj['Endpoint_ArmsCohorts'].length-1; i< values['ArmsCohorts'].length - 1; i++){
                        armsAndCohortDescription.push({Description: ''});
                    }
                    parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                    updateEndpoint(parentObj, endpointIndex || 0);
                }
                // updateEndpoint(parentObj, endpointIndex || 0);
            }
        }
        if(values["CohortsPresent"] && values['ClinicalData_Endpoints'] && values['ArmsCohorts'].length > 0){
            setCohortPresent(true);
            setNoOfArms(values['ArmsCohorts'].length);
            const cohortCount = values['ArmsCohorts'].reduce((count: number, a: any) => {
                return count + a['Cohorts'].length;
            }, 0);

            
            let armsAndCohortDescription = Array.from(Array(cohortCount), () => ({Description: ''}));
            const parentObj = values['ClinicalData_Endpoints'][endpointIndex];
            // if(!parentObj['Endpoint_ArmsCohorts']){
            if(!values['ClinicalData_Endpoints'][endpointIndex]['Endpoint_ArmsCohorts']){
                parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                updateEndpoint(parentObj, endpointIndex || 0);
            }
            // else if(parentObj['Endpoint_ArmsCohorts'].length < cohortCount){            
            else if(values['ClinicalData_Endpoints'][endpointIndex]['Endpoint_ArmsCohorts'].length < cohortCount){            
                armsAndCohortDescription = parentObj['Endpoint_ArmsCohorts'];
                for(let i = parentObj['Endpoint_ArmsCohorts'].length; i< cohortCount; i++){
                    armsAndCohortDescription.push({Description: ''});
                }
                parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                updateEndpoint(parentObj, endpointIndex || 0);
            }
            // else if(parentObj['Endpoint_ArmsCohorts'].length > cohortCount){    
            else if(values['ClinicalData_Endpoints'][endpointIndex]['Endpoint_ArmsCohorts'].length > cohortCount){
                armsAndCohortDescription = parentObj['Endpoint_ArmsCohorts'].slice(0,cohortCount-1);
                parentObj['Endpoint_ArmsCohorts'] = armsAndCohortDescription;
                updateEndpoint(parentObj, endpointIndex || 0);
            }
            setNoOfCohorts(cohortCount);
        }
    }, [values]);

    useEffect(() => {
        
            const ClinicalData_Drug: lookupItemT[] =
              (selectedLookupGlobe["ClinicalData_Drug"] &&
                selectedLookupGlobe["ClinicalData_Drug"].map((x: any) => {
                  return {
                    id: String(x.Id),
                    label: x.Value,
                  };
                })) ||
              [];
    
            const ClinicalData_Drug_Combination: lookupItemT[] =
              selectedLookupGlobeSpecial["ClinicalData_Drug_Combination"]?.map(
                (x: any) => {
                  return {
                    id: String(x.Id),
                    label: x.Value,
                  };
                }
              ) || [];
    
            const ClinicalData_Device: lookupItemT[] =
              (!!selectedLookupGlobe["ClinicalData_Device"] &&
                selectedLookupGlobe["ClinicalData_Device"].map((x: any) => {
                  return {
                    id: String(100000 + x.Id),
                    label: x.Value,
                  };
                })) ||
              [];
    
            const ClinicalData_ProcedureIntervention: lookupItemT[] =
              (!!selectedLookupGlobe["ClinicalData_ProcedureIntervention"] &&
                selectedLookupGlobe["ClinicalData_ProcedureIntervention"].map(
                  (x: any) => {
                    return {
                      id: String(200000 + x.Id),
                      label: x.Value,
                    };
                  }
                )) ||
              [];
    
            const DrugIds =
              selectedLookupGlobe["ClinicalData_Drug"]?.map((el) => el.Id) || [];
            const ClinicalData_DrugControl: lookupItemT[] =
              (selectedLookupGlobe["ClinicalData_DrugControl"] &&
                selectedLookupGlobe["ClinicalData_DrugControl"]
                  .filter((el) => !DrugIds.includes(el.Id))
                  .map((x: any) => {
                    return {
                      id: String(x.Id),
                      label: x.Value,
                    };
                  })) ||
              [];
    
            const Drug_CombinationIds =
              selectedLookupGlobeSpecial["ClinicalData_DrugControl_Combination"]?.map(
                (x) => x.Id
              ) || [];
    
            const ClinicalData_Drug_CombinationControl: lookupItemT[] =
              selectedLookupGlobeSpecial["ClinicalData_DrugControl_Combination"]
                ?.filter((el) => Drug_CombinationIds.includes(el.Id)) //Changed from !Drug_CombinationIds to Drug_CombinationIds. Because DrugControl_Combination items are not visible in Treatment Arm label in Endpoint section. 
                .map((x: any) => {
                  return {
                    id: String(x.Id),
                    label: x.Value,
                  };
                }) || [];
    
            const DeviceIds =
              selectedLookupGlobe["ClinicalData_Device"]?.map((el) => el.Id) || [];
            const ClinicalData_DeviceControl: lookupItemT[] =
              (!!selectedLookupGlobe["ClinicalData_DeviceControl"] &&
                selectedLookupGlobe["ClinicalData_DeviceControl"]
                  .filter((el) => !DeviceIds.includes(el.Id))
                  .map((x: any) => {
                    return {
                      id: String(100000 + x.Id),
                      label: x.Value,
                    };
                  })) ||
              [];
    
            const ProcedureInterventionIds =
              selectedLookupGlobe["ClinicalData_ProcedureIntervention"]?.map(
                (el) => el.Id
              ) || [];
    
            const ClinicalData_ProcedureInterventionControl: lookupItemT[] =
              (!!selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"] &&
                selectedLookupGlobe["ClinicalData_ProcedureInterventionControl"]
                  ?.filter((el) => !ProcedureInterventionIds.includes(el.Id))
                  .map((x: any) => {
                    return {
                      id: String(200000 + x.Id),
                      label: x.Value,
                    };
                  })) ||
              [];
    
            const item: lookupItemT[] = [
              ...ClinicalData_Drug,
              ...ClinicalData_Drug_Combination,
              ...ClinicalData_Device,
              ...ClinicalData_ProcedureIntervention,
              ...ClinicalData_DrugControl,
              ...ClinicalData_Drug_CombinationControl,
              ...ClinicalData_DeviceControl,
              ...ClinicalData_ProcedureInterventionControl,
            ];
            setTreatmentArmValues(item);
    

          
    },[selectedLookupGlobe?.ClinicalData_Drug,
        selectedLookupGlobeSpecial?.ClinicalData_Drug_Combination,
        selectedLookupGlobe?.ClinicalData_Device,
        selectedLookupGlobe?.ClinicalData_ProcedureIntervention,
        
        selectedLookupGlobe?.ClinicalData_DrugControl,
        selectedLookupGlobeSpecial?.ClinicalData_DrugControl_Combination,
        selectedLookupGlobe?.ClinicalData_DeviceControl,
        selectedLookupGlobe?.ClinicalData_ProcedureInterventionControl])


    const onFieldChange = (name: string, value: any, parentObject: any, parentIndex: number, index: number) => {
        if( parentObject !== undefined) {      
            let child_obj = {...parentObject}
            child_obj['Endpoint_ArmsCohorts'][index][name] = value;
            updateEndpoint(child_obj,parentIndex || 0);
        } 
    }

    const getValue = (name: string, index: number) => {
        return  values["ClinicalData_Endpoints"] && values["ClinicalData_Endpoints"][endpointIndex]['Endpoint_ArmsCohorts'] && values["ClinicalData_Endpoints"][endpointIndex]['Endpoint_ArmsCohorts'][index] 
        ? values["ClinicalData_Endpoints"][endpointIndex]['Endpoint_ArmsCohorts'][index][name]
        : '';
    };

    return (
        <>
        {!cohortPresent && 
        // noOfArms > 0 && 
        values['ArmsCohorts'] && values['ArmsCohorts'].map((arms: any, index: number) =>{    
            const cohortId = values?.ArmsCohorts[index]["ClinicalData_Arms.OutcomeResultID"];
            const cohortValue = treatementArmValues.filter((el) => el.id == cohortId).map((el) => el.label)[0];         
            return(
            <React.Fragment key={index+1}>
            <div
                className={`col-sm-12 row form-group field-type-6 field-name-Endpoint-${endpointIndex}-TreatmentArm-${index}`}
                style={{ marginBottom: "0rem" }}
                key={`Endpoint-${endpointIndex}-TreatmentArm-${index}`}
            >
                <label
                className={"col-sm-4 text-end control-label "}
                title={`Endpoint-${endpointIndex}-TreatmentArm-${index}`}
                style={{ paddingRight: "4px", paddingTop: 10 }}
                >
                    {`Treatment arm ${index+1}`}{cohortValue ? ` - ${cohortValue}` : ""} 
                  {/* {`Treatment arm ${index+1}`}  */}
                </label>
                <div className="col-sm-8">
                <textarea
                    id={`Endpoint-${endpointIndex}-TreatmentArm-${index}`}
                    name={`Endpoint_ArmsCohorts-${index}`}
                    value={getValue("Description", index)}
                    placeholder="Describe the result"
                    onChange={(e) => onFieldChange("Description", e.target.value, values['ClinicalData_Endpoints'][endpointIndex], endpointIndex, index)}// 'Endpoint_ArmsCohorts'
                    className="form-control"
                ></textarea>
                </div>
            </div>
            </React.Fragment>
        )})
        }
        {cohortPresent && 
        // noOfArms > 0 && 
        values['ArmsCohorts'] && values['ArmsCohorts'].map((arms: any, armIndex: number) => {
            return(
                arms["Cohorts"].map((ch: any, chIndex: number) => {
                    count++;
                    let tempCount = count - 1;
                    const cohortId = values?.ArmsCohorts[armIndex]["ClinicalData_Arms.OutcomeResultID"];
                    const cohortValue = treatementArmValues.filter((el) => el.id == cohortId).map((el) => el.label)[0]; 
                    return (
                    
                    <div
                        className={`col-sm-12 row form-group field-type-6 field-name-Endpoint-${endpointIndex}-TreatmentArm-${count}`}
                        style={{ marginBottom: "0rem" }}
                        key={`Endpoint-${endpointIndex}-TreatmentArm-${armIndex+1}-Cohort-${chIndex}`}
                    >
                        <label
                        className={"col-sm-4 text-end control-label "}
                        title={`Endpoint-${endpointIndex}-TreatmentArm-${chIndex}`}
                        style={{ paddingRight: "4px", paddingTop: 10 }}
                        >
                            {`Treatment arm ${armIndex+1}`}{cohortValue ? ` - ${cohortValue} - Cohort ${chIndex+1}` : ""} 
                        </label>
                        <div className="col-sm-8">
                        <textarea
                            id={`Endpoint-${endpointIndex}-TreatmentArm-${count-1}`}
                            name={`Endpoint_ArmsCohorts-${chIndex}`}
                            value={getValue("Description", tempCount)}
                            placeholder="Describe the result"
                            onChange={(e) => {
                                const id = parseInt(e.target.id.split('-')[3]);
                                tempCount = id-1;
                                console.log('count = ', id);
                                onFieldChange("Description", e.target.value, values['ClinicalData_Endpoints'][endpointIndex], endpointIndex, id)
                            }}
                            className="form-control"
                        ></textarea>
                        </div>
                    </div>
                    
                    )}
                )
            )
        })
        }  
        </>
    );
};