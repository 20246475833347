import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch, useLocation, Link, useHistory, useParams } from "react-router-dom";
import {
  useFetchSectionMutation,
  useFetchFieldMutation,
} from "../../../store/DataSourceMetaDataApiSlice";
import { Section, Field, Comment } from "../../../store/DataSourceMetaData";
import { getCommentTableName, getIdFieldName } from "../../../utils/index";
import apiCall from "../../service/apiCall";
import moment from "moment";
import {
  useFetchLookupItemsMutationMutation,
  useFetchLookupItemsQuery,
} from "../../../store/LookupApiSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Comments from "../Comments";
import { IselectedLookupGlobe, IselectedLookupGlobeSpecial } from "../../interfaces/multiselect";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { FormFieldType, LookupIds } from "../../Shared/Utility";
import { datasourceMap, hyperlinkList, LinkStyle, lookupIdWithParentValue } from "../FieldView";
import ComplexLookupSplit, { validateNumber } from "../Utility";
import { setSelectedDataState, setSelectedLookupGlobe, setSelectedLookupGlobeSpecial, setSubpageData } from "../../../store/MultiSelectSlice";
import DiseaseHierarchy from "../DiseaseHierarchy";
import "../../../app.css";
import { col_items } from "../../service/colItems";
import CloseIcon from "@mui/icons-material/Close";
import { MandatoryFieldForClinicalData, MandatoryFieldForHTA } from "../Editor";
import HTARecord_SubGroupPropCompareRecords from "./SubgroupForCompareRecords";

interface lookupItemT {
  id: string;
  label: string;
}

type ClientObj = {
  label: string;
  ID: number;
};

export type SubGroup = {
  ID: number[];
  SubGroupID: number[];
  HTARecord_version_ID: number[];
  CountryID: number[];
  AgencyID: number[];
  IsCustom: boolean[];
  SubGroupIDText: string[];
  ReimbursementRate: (number | null)[];
  SMR: (number | null)[];
  ASMR: (number | null)[];
  BenifitRating: (number | null)[];
  Recommendation: (number | null)[];
};

const CompareRecords = () => {
  const { dataSourceID } : any = useParams();
  const dataSourceIDNumber = Number(dataSourceID);
  const [srows, setSrows] = useState<Section[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentsRecordID_1, setcommentsRecordID_1] = useState<Comment[]>([]);
  const [commentsRecordID_2, setCommentsRecordID_2] = useState<Comment[]>([]);
  const [dataRecordID_1, setDataRecordID_1] = useState<any>({});
  const [dataRecordID_2, setDataRecordID_2] = useState<any>({});
  const [fieldRows, setFieldRows] = React.useState<Field[]>([]);
  const [listLookUpIDs, setListLookUpIDs] = useState([]);
  const [items, setItems] = useState<any>({});
  const [clientList, setClientList] = useState<ClientObj[]>([]);
  const myRef = useRef();
  const [url, setUrl] = useState<string>("");
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>(false);
  const [hoveredIndexSpecial, setHoveredIndexSpecial] = useState<string>("");
  const [mandatoryFields, setMandatoryFields] = useState<any>([]);

  // Router
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const recordID_1 = Number(queryParams.get('id1'));
  const recordID_2 = Number(queryParams.get('id2'));

  // Style
  const titleStyle = {
    fontSize: "15px",
    fontWeight: 700,
    color: "#00AAE7",
  };

  // Methods
  const [fetchSection, { data, error, isLoading, isSuccess }] =
    useFetchSectionMutation();
  const [fetchField, { isLoading: isLoadingFields }] = useFetchFieldMutation();
  const [fetchLookupItems] = useFetchLookupItemsMutationMutation();
  const fetchClients = useFetchLookupItemsQuery({
    lookup_id: 127,
    datasource_id: dataSourceIDNumber,
  });

  const dispatch = useAppDispatch();
  const accountType = useAppSelector((state) => state.userProfile.accountType);
  const selectedLookupGlobe = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobe
  );
  const selectedLookupGlobeSpecial = useAppSelector(
    (state) => state.multiselect.selectedLookupGlobeSpecial
  );

  const [subGroupData, setSubGroupData] = useState<SubGroup[]>([]);

  useEffect(() => {
    console.log("subGroupData", subGroupData);
  }, [subGroupData]); 

    useEffect(() => {
      if (Number(dataSourceID) == 1) {
        setMandatoryFields(MandatoryFieldForHTA);
      } else if (Number(dataSourceID) == 2) {
        setMandatoryFields(MandatoryFieldForClinicalData);
      } 
    }, [dataSourceID]);

  useEffect(() => {
    if (fetchClients.data && fetchClients.data.length > 0) {
      let arr = [];
      for (let c of fetchClients.data) {
        arr.push({
          label: c.Value,
          ID: c.ID,
        });
      }
      setClientList(arr);
    }
  }, [fetchClients.isLoading]);

  const initialize = async () => {
    setLoading(true);

    setUrl(location.pathname + location.search);

    if (accountType != null && accountType != "") {
      // Data
      fetchRecordsData(dataSourceIDNumber, Number(recordID_1), Number(recordID_2));

      // Sections
      fetchSections(dataSourceIDNumber, Number(recordID_1), Number(recordID_2));

      // Comments
      fetchComments(dataSourceIDNumber, Number(recordID_1), Number(recordID_2));

      // Fields
      fetchFields(dataSourceIDNumber);

      // LookupGlobe
       fetchLookupGlobe(dataSourceIDNumber, Number(recordID_1), Number(recordID_2));

    }
  };

  const isSectionEmpty = (section : Section) => {
    const fields = fieldRows.filter((field) => field.sectionId === section.id);
    if (fields.length === 0) return true;
    return fields.some((field) => field.masterFieldId === null && IsFieldIsEmpty(field));
  }

  useEffect(() => {
    initialize();
  }, [accountType]);

  const fetchSections = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    const result : any = await fetchSection({
      dataSourceID: sourceID,
      roleId : 0
    });
    // console.log("result++", result);
    let _rows = [];
    if (result && result["data"]) {
      if (
        Number(firstID) == 0 ||
        firstID == null ||
        isNaN(Number(firstID)) ||
        Number(secondID) == 0 ||
        secondID == null ||
        isNaN(Number(secondID))
      ) {
        _rows = result.data.filter(
          (x:any) => x.name != "Record details" && x.name != "Search results"
        );
      } else if (accountType === "EClient") {
        _rows = result.data.filter(
          (x:any) => x.name != "Record details" && x.name != "Search results"
        );

      } else {
        _rows = result.data.filter((x:any) => x.name != "Search results");
      }
      setSrows(_rows);
    }
  };

  const fetchComments = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    let tableName = getCommentTableName(Number(sourceID));
    let idColumnName = getIdFieldName(Number(sourceID));
    // console.log("tableName", tableName, "idColumnName", idColumnName);

    let commentFirstID = await apiCall(
      `/api/fetchComments/${tableName}/${firstID}/${idColumnName}`
    );
    // console.log("commentFirstID", commentFirstID);
    if (
      commentFirstID &&
      commentFirstID.data &&
      commentFirstID.data.length > 0
    ) {
      setcommentsRecordID_1(commentFirstID.data);
    }

    let commentSecondID = await apiCall(
      `/api/fetchComments/${tableName}/${secondID}/${idColumnName}`
    );
    // console.log("commentSecondID", commentSecondID);
    if (
      commentSecondID &&
      commentSecondID.data &&
      commentSecondID.data.length > 0
    ) {
      setCommentsRecordID_2(commentSecondID.data);
    }
  };

  const fetchRecordsData = async (
    sourceID: number,
    firstID: number,
    secondID: number
  ) => {
    let res1 = await apiCall(`/api/datasource/${sourceID}/${firstID}/view/undefined/compare`);
    console.log("res1",res1);
    if (recordID_1 && res1.data && res1.data.length > 0) {
      setDataRecordID_1(res1.data[0]);
    }

    let res2 = await apiCall(`/api/datasource/${sourceID}/${secondID}/view/undefined/compare`);
    if (res2 && res2.data && res2.data.length > 0) {
      setDataRecordID_2(res2.data[0]);
    }
  };

  const fetchFields = async (sourceID: number) => {
    const result : any = await fetchField({ dataSourceID: sourceID });
    if (result && result.data && result.data.length > 0) {
      setFieldRows(result.data);
      let tmp: any = [];
      let tmp2: any = {};

      await Promise.all(result.data.map(async (x:any) => {
        if (x.lookupId !== null) {
          tmp.push(x.lookupId);
          let lookup: any = await fetchLookupItems({ lookup_id: Number(x.lookupId), datasource_id: sourceID });
          if (lookup.data && lookup.data.length > 0) {
            let lookupItems: lookupItemT[] = lookup.data.map((x: any) => ({
              label: String(x.Value),
              id: String(x.ID),
            }));
            tmp2[x.name] = lookupItems;
          }
        }
      }));
      setListLookUpIDs(tmp);
      setItems(tmp2);
      // if (sections && sections.length > 0) setValue(sections[0].id.toString());
    }
  };

  const scrollToComment = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (myRef && myRef.current) myRef.current?.scrollIntoView();
  };

  const navigate = useHistory();

  const drugSearch = async (
    event: any,
    item: any,
    searchName: string,
    Country_ID:number,
    disease?: any,
     
  ) => {
    event.preventDefault();
    if (searchName === "HTA") {
      let obj = {
        DrugIDs: [item],
      };
      dispatch(setSelectedDataState(item));
      dispatch(setSelectedLookupGlobe(obj));
      navigate.push("/Search/ByDrug");
    } else if (searchName === "Pricing") {
      let country = await apiCall(`/api/getCountry/${Country_ID}`);
      if (country.data && country.data.length > 0) {
        let obj = {
          DrugIDs: [item],
          CountryIDs: [{ Id: Country_ID, Value: country.data[0].Name }],
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrugPricing");
      }
    } else if (searchName === "Clinical") {
      let obj = {
        DrugIDs: [item],
        SuperSearchDiseaseIDs: [disease[0]],
      };
      dispatch(setSelectedDataState(item));
      dispatch(setSelectedLookupGlobe(obj));
      navigate.push("/Search/ByDrugClinical");
    } else if (searchName === "Regulatory") {
      let regulator = await apiCall(`/api/getRegulator/${Country_ID}`);
      if (regulator.data && regulator.data.length > 0) {
        let obj = {
          DrugIDs: [item],
          SuperSearchDiseaseIDs: [disease[0]],
          RegulatorIDs: regulator.data,
        };
        dispatch(setSelectedDataState(item));
        dispatch(setSelectedLookupGlobe(obj));
        navigate.push("/Search/ByDrugRegulatory");
      }
    }
  };

  const displayDrugBrandName = (row: any,position:number) => {
    return (
      <span
        style={{
          width: "100%",
          display: "block",
          fontSize: "12px",
          color: "#00aae7",
        }}
      >
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Clinical",
              position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID,
              selectedLookupGlobe[`HTARecord_PrimaryDisease_ID_${position}`]
            )
          }
        >
          Clinical
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) =>
            drugSearch(
              e,
              row,
              "Regulatory",
              position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID,
              selectedLookupGlobe[`HTARecord_PrimaryDisease_ID_${position}`]
            )
          }
        >
          Regulatory
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) => drugSearch(e, row, "HTA",position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID)}
        >
          HTA
        </Link>
        <Link
          style={LinkStyle}
          to={"/"}
          onClick={(e) => drugSearch(e, row, "Pricing",position === 1 ? dataRecordID_1.Country_ID : dataRecordID_2.Country_ID)}
        >
          Pricing
        </Link>
      </span>
    );
  };

  const displayContent = (
    name: string,
    fieldTypeId: number,
    position: number,
    lookupId: number,
    sectionName: string,
    vi?: number
  ) => {
    if (sectionName !== "Disease") {
      const values = position == 1 ? dataRecordID_1 : dataRecordID_2
      switch (fieldTypeId) {
        case FormFieldType.LookupAutoComplete:
        case FormFieldType.DeviceDropdownRelatedData:
        case FormFieldType.Dropdown:
        case FormFieldType.LookupDisplay:
          if (hyperlinkList.includes(name)) {
            return (
              <a
                className="no-underline"
                style={{
                  color: "rgb(0, 170, 231)",
                  border: "none",
                  background: "none",
                  padding: "0px",
                  fontSize: "12px",
                }}
                href={`/view/${datasourceMap[ name ]}/${values[ name ]
                  }`}
                target="_blank"
                rel="noreferrer"
              >
                {getLookupLabel(name, position, fieldTypeId, lookupId)}
              </a>
            );
          } else {
            return (
              <label>
                {/* {isLoading && "loading"}{" "} */}
                {isLoading && <CircularProgress color="inherit" size={10} />}{" "}
                {!isLoading && getLookupLabel(name, position, fieldTypeId, lookupId)}
              </label>
            );
          }
        // case 4:
        //   return (
        //     <label>
        //       {!isLoading &&
        //         getLookupLabel(name, position, fieldTypeId, lookupId)}
        //     </label>
        //   );
        case FormFieldType.Checkbox:
          return (
            <input
              type="checkbox"
              readOnly
              value={getLookupLabel(name, position, fieldTypeId, lookupId)}
              style={{ color: "rgb(0, 170, 231)", marginTop: 2 }}
              checked={getLookupLabel(name, position, fieldTypeId, lookupId)}
            />
          );
        case FormFieldType.MultiSelect:
        case FormFieldType.DrugSelect:
        case FormFieldType.DeviceSelect:
        case FormFieldType.ClinicalDataSelect:
          let myKey = ""
          let msKey = "";
          if (vi != undefined) {
            msKey = name + "_" + position + "_" + String(vi);
            myKey = name + "_" + String(vi);
          } else {
            msKey = name + "_" + position;
            myKey = name
          }
          const hasKey = selectedLookupGlobe.hasOwnProperty(msKey);
          const hasKeySpecial =
            selectedLookupGlobeSpecial.hasOwnProperty(msKey);
          return (
            <>
              {hasKey &&
                hyperlinkList.includes(name) &&
                selectedLookupGlobe[ msKey ].map((row, index) => {
                  return (
                    <>
                      <a
                        className="no-underline"
                        key={index}
                        style={{
                          color: "#00aae7",
                          border: "none",
                          background: "none",
                          padding: "0px",
                          fontSize: "12px",
                          display: "block",
                        }}
                        href={`/view/${datasourceMap[ myKey ]}/${row.Id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.Value}
                      </a>
                      {name === "HTARecord_Drug" && displayDrugBrandName(row, position)}
                    </>
                  );
                })}
              {hasKey &&
                !hyperlinkList.includes(name) &&
                !lookupIdWithParentValue.includes(lookupId) &&
                selectedLookupGlobe[ msKey ].map((row) => {
                  return (
                    <span
                      key={row.Id}
                      style={{
                        width: "100%",
                        display: "block",
                        fontSize: "12px",
                      }}
                    >
                      {row.Value}
                    </span>
                  );
                })}
              {hasKey &&
                [
                  "HTARecord_CoaInstrument",
                  "HTARecord_ClinicalPositives",
                  "HTARecord_ClinicalNegatives"
                ].includes(name) && (
                  <>
                    <ComplexLookupSplit data={selectedLookupGlobe[ msKey ]} />
                  </>
                )}
              {hasKeySpecial &&
                [
                  "HTARecord_Sourceofcomparator"
                ].includes(name) && (
                  <>
                    <ComplexLookupSplit data={selectedLookupGlobeSpecial[ msKey ]} />
                  </>
                )}
              {hasKeySpecial &&
                lookupId != LookupIds.SourceOfComparator &&
                selectedLookupGlobeSpecial[ msKey ]?.map((row, index) => {
                  return (
                    <div className="custom-scroll" key={row.Id}>
                      <span

                        style={{
                          //color: "#00aae7",
                          width: "100%",
                          display: "block",
                          fontSize: "12px",
                        }}

                        onMouseEnter={() => {
                          setHoveredIndexSpecial(row.Id);
                        }}
                        onMouseLeave={() => {
                          setHoveredIndexSpecial("");
                        }}
                      >
                        {row.Id === hoveredIndexSpecial && (
                          <span>
                            <CloseIcon
                              onClick={() => console.log("testing....")}
                              style={{
                                color:
                                  row.Id === hoveredIndexSpecial
                                    ? "#ff0000"
                                    : "#00aae7",
                                transition: "color 0.3s ease",
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        )}
                        {row.Value}
                      </span>
                    </div>
                  );
                })}
            </>
          );
        case FormFieldType.NumberRange:
          let minVal = "";
          let maxVal = "";
          minVal = validateNumber(values[ name ], 'read');
          if ([ 1 ].includes(dataSourceIDNumber)) {
            maxVal = validateNumber(values[ name + "_Max" ], 'read');
          }
          return (
            <>
              <label>{minVal}</label>
              {[ 1 ].includes(dataSourceIDNumber) && (
                <>
                  <span> - </span> <label>{maxVal}</label>{" "}
                </>
              )}
              {name == "ClinicalData_Endpoints.ConfidenceInterval" && (
                <>
                  <span> - </span> <label>{validateNumber(values[ "ClinicalData_Endpoints.ConfidenceInterval_Max" ], 'read')}</label>{" "}
                </>
              )}
            </>
          );
        case 32:
          
          if (name.toLowerCase().indexOf("direct_link") >= 0) {
            return (
              <a
                className="no-underline"
                style={{
                  color: "rgb(0,170,231)",
                  border: "none",
                  background: "none",
                  padding: "0px",
                  fontSize: "12px",
                }}
                href={`/view/${dataSourceIDNumber}/${values[ col_items[ dataSourceIDNumber ] ]}`}
                target="_blank"
              >
                Link
              </a>
            );
          } else if (
            values[ name ] != null ||
            name.toLowerCase().indexOf("link") >= 0
          ) {
            return (
              <a
                className="no-underline"
                style={{
                  color: "rgb(0,170,231)",
                  border: "none",
                  background: "none",
                  padding: "0px",
                  fontSize: "12px",
                }}
                href={values[ name ]}
                target="_blank"
              >
                Link
              </a>
            );
          }
          else
            return <label></label>

        default:
          return (
            <label
              dangerouslySetInnerHTML={{
                __html: getLookupLabel(name, position, fieldTypeId, lookupId),
              }}
            ></label>
          );
      }
    }
  };

  const getLookupLabel = (
    propertyName: string,
    position: number,
    fieldTypeId: number,
    lookupId: number
  ) => {
    if (propertyName == null || propertyName == "") {
      return "";
    }

    propertyName = propertyName === "Cloned_From"  ? "Clone_Record" : propertyName;

    let _value =
      position === 1
        ? dataRecordID_1[propertyName]
        : dataRecordID_2[propertyName];

    if((!_value || _value == 0) && propertyName == "Clone_Record"){
      return 0;
    }

    if (_value == null || _value == "") return "";

    if (fieldTypeId == 15) {
      if (_value == null || _value == "") return "";
      else {
        return moment(_value).format("DD/MM/yyyy");
      }
    }
    if(fieldTypeId == FormFieldType.TextareaRichtext)
      return _value;

    if (lookupId == null) return _value;

    let lookupItem;
    if (items[propertyName]) {
      lookupItem = items[propertyName].find((opt:any) => opt.id == _value);
    }

    let label = "";

    if (lookupItem) label = lookupItem.label;
    return label;
  };

  const fetchLookupGlobe = async (
    dataSourceID: number,
    recordId_1: number,
    recordId_2: number
  ) => {
    let newObj: IselectedLookupGlobe = {};
    let newObjSpecial: IselectedLookupGlobeSpecial = {};
    let multiselect_api_record_1 =
      dataSourceID < 250
        ? `/api/multiselectvalues/${dataSourceID}/${recordId_1}/view/undefined/compare1`
        : `/api/multiselectvalues/admin/${dataSourceID}/${recordId_1}`;
    let response = await apiCall(multiselect_api_record_1);
    let selLookup = response.data.multiselectValues;
    let selLookupSpecial = response.data.multiselectValuesSpecial;
    const s_data = response.data[ "subPageData" ]?.["sub_group_details"] ||  [];
    
    for (let i = 0; i < selLookup.length; i++) {
      if(selLookup[i]){
        if (newObj.hasOwnProperty(`${selLookup[i]["FieldSource"]}_1`)) {
          newObj[`${selLookup[i]["FieldSource"]}_1`].push({
            Id: selLookup[i]["LookupId"],
            Value: selLookup[i]["Value"],
          });
        } else {
          newObj[`${selLookup[i]["FieldSource"]}_1`] = [
            { Id: selLookup[i]["LookupId"], Value: selLookup[i]["Value"] },
          ];
        }
      }
    }

    for (let i = 0; i < selLookupSpecial.length; i++) {
      if(selLookupSpecial[i]){
        if (
          newObjSpecial.hasOwnProperty(`${selLookupSpecial[i]["FieldSource"]}_1`)
        ) {
          newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_1`].push({
            Id: selLookupSpecial[i]["LookupId"],
            Value: selLookupSpecial[i]["Value"],
          });
        } else {
          newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_1`] = [
            {
              Id: selLookupSpecial[i]["LookupId"],
              Value: selLookupSpecial[i]["Value"],
            },
          ];
        }
      }
    }
    

    let multiselect_api_record_2 =
      dataSourceID < 250
        ? `/api/multiselectvalues/${dataSourceID}/${recordId_2}/view/undefined/compare2`
        : `/api/multiselectvalues/admin/${dataSourceID}/${recordId_2}`;
    response = await apiCall(multiselect_api_record_2);
    selLookup = response.data.multiselectValues;
    selLookupSpecial = response.data.multiselectValuesSpecial;
    const s_data2 = response.data[ "subPageData" ]?.["sub_group_details"] ||  [];
    mergeSubGroups(s_data, s_data2);
    
    for (let i = 0; i < selLookup.length; i++) {
      if(selLookup[i]){
        if (newObj.hasOwnProperty(`${selLookup[i]["FieldSource"]}_2`)) {
          newObj[`${selLookup[i]["FieldSource"]}_2`].push({
            Id: selLookup[i]["LookupId"],
            Value: selLookup[i]["Value"],
          });
        } else {
          newObj[`${selLookup[i]["FieldSource"]}_2`] = [
            { Id: selLookup[i]["LookupId"], Value: selLookup[i]["Value"] },
          ];
        }
      }
    }

    for (let i = 0; i < selLookupSpecial.length; i++) {
      if(selLookupSpecial[i]){
        if (
          newObjSpecial.hasOwnProperty(`${selLookupSpecial[i]["FieldSource"]}_2`)
        ) {
          newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_2`].push({
            Id: selLookupSpecial[i]["LookupId"],
            Value: selLookupSpecial[i]["Value"],
          });
        } else {
          newObjSpecial[`${selLookupSpecial[i]["FieldSource"]}_2`] = [
            {
              Id: selLookupSpecial[i]["LookupId"],
              Value: selLookupSpecial[i]["Value"],
            },
          ];
        }
      }
    }

    dispatch(setSelectedLookupGlobe({ ...selectedLookupGlobe, ...newObj }));
    dispatch(
      setSelectedLookupGlobeSpecial({
        ...selectedLookupGlobeSpecial,
        ...newObjSpecial,
      })
    );
    
    setLoading(false);
  };

  const mergeSubGroups = (data1: any[], data2: any[]) => {
    const maxLength = Math.max(data1.length, data2.length);
    const mergedData: SubGroup[] = [];

    for (let i = 0; i < maxLength; i++) {
        const item1 = data1[i] || {};
        const item2 = data2[i] || {};

        const mergedItem: SubGroup = {
            ID: [item1.ID ?? null, item2.ID ?? null],
            SubGroupID: [item1.SubGroupID ?? null, item2.SubGroupID ?? null],
            HTARecord_version_ID: [item1.HTARecord_version_ID ?? null, item2.HTARecord_version_ID ?? null],
            CountryID: [item1.CountryID ?? null, item2.CountryID ?? null],
            AgencyID: [item1.AgencyID ?? null, item2.AgencyID ?? null],
            IsCustom: [item1.IsCustom ?? null, item2.IsCustom ?? null],
            SubGroupIDText: [item1.SubGroupIDText ?? null, item2.SubGroupIDText ?? null],
            ReimbursementRate: [item1.ReimbursementRate ?? null, item2.ReimbursementRate ?? null],
            SMR: [item1.SMR ?? null, item2.SMR ?? null],
            ASMR: [item1.ASMR ?? null, item2.ASMR ?? null],
            BenifitRating: [item1.BenifitRating ?? null, item2.BenifitRating ?? null],
            Recommendation: [item1.Recommendation ?? null, item2.Recommendation ?? null],
        };

        mergedData.push(mergedItem);
    }

    setSubGroupData(mergedData);
};

  const hideMenu = (event: any) => {
    event.preventDefault();
    setIsMenuHidden(true);
  };

  const IsFieldIsEmpty = (field: Field) => {
    if (field.name == "Direct_Link" || field.name == "Cloned_From")
      return true;
    if (
      field.fieldTypeId == FormFieldType.MultiSelect ||
      field.fieldTypeId == FormFieldType.DrugSelect ||
      field.fieldTypeId == FormFieldType.DeviceSelect ||
      field.fieldTypeId == FormFieldType.ClinicalDataSelect
    ) {
      if (
        !selectedLookupGlobe[ `${field.name}_1` ] &&
        !selectedLookupGlobe[ `${field.name}_2` ] &&
        !selectedLookupGlobeSpecial[ `${field.name}_1` ] &&
        !selectedLookupGlobeSpecial[ `${field.name}_1` ]
      ) {
        return false;
      } else return true
    }
    if (!dataRecordID_1[ field.name ] && !dataRecordID_2[ field.name ])
      return false;
    return true;
  };

  return !loading ? (
    <div>
      <div style={{ paddingLeft: "5%" }}>
        <span
          style={{ color: "rgb(0, 170, 231)", cursor: "pointer" }}
          onClick={() => setIsMenuHidden(false)}
        >
          Record
        </span>
      </div>
      <div className="main-container col-sm-12 row" style={{width:"97%",marginLeft:"2em"}}> 
        <div
          className="leftactionpane col-md-2 record-view-height"
          style={
            isMenuHidden
              ? { padding: "0px", display: "none" }
              : { padding: "0px" }
          }
        >
          <ul
            className="ace-left-nav ui-sortable"
            data-spy="affix"
            data-offset-top="205"
            style={{ padding: 0 }}
          >
            <li className="ui-sortable-handle">
              <div>
                <a
                  className="pull-right"
                  href="#"
                  id="left-ace-nav-btn"
                  title="Hide this Menu"
                  onClick={(e) => {
                    e.preventDefault();
                    hideMenu(e)}}
                >
                  <i
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ fontSize: "25px", paddingRight: "7px" }}
                  ></i>
                </a>
              </div>
              <div
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  height: "66px",
                }}
              >
                <b>
                  <span style={{ paddingTop: "20px", display: "block" }}>
                    Record Layout
                  </span>
                </b>
              </div>
            </li>
            {srows.length > 0 &&
              srows
                .filter(
                  (s) => s.parent_section_id == null || s.parent_section_id == 0
                )
                .map((sectionNav) => (
                  <li
                    className="hover-action ui-sortable-handle ace-left-nav-activated"
                    key={sectionNav.id}
                  >
                    <a
                      href={url + "#section-" + sectionNav.id.toString()}
                      style={{ color: "rgb(0, 170, 231) !important" }}
                      className="sectionLink no-underline"
                    >
                      <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                        <span style={{ color: "rgb(0, 170, 231) !important" }}>
                          {sectionNav.name}
                        </span>
                      </div>
                    </a>
                  </li>
                ))}
            {(commentsRecordID_1.length > 0 ||
              commentsRecordID_2.length > 0) && (
              <li className="hover-action ui-sortable-handle ace-left-nav-activated">
                <a
                  href="#section-comment"
                  onClick={(e) => scrollToComment(e)}
                  className="sectionLink no-underline"
                >
                  <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                    Comments
                  </div>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div
          className={
            (isMenuHidden ? "col-md-12" : "col-md-10") +
            " rightactionpane record-view-height"
          }
        >
          <div id="div-fixed" style={{ paddingLeft: "0px" }}>
            <div className="pull-left" style={{ margin: "15px 0px" }}>
              <div
                className="row justify-content-end"
                style={{
                  borderBottom: "1px solid #ededed",
                  margin: "20px auto",
                  paddingBottom: "20px",
                }}
              >
                <div className="col-5">
                  <Link
                    to={`/view/${dataSourceIDNumber}/${recordID_1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-underline"
                    style={{marginLeft:"-0.8rem"}}
                  >
                    <span style={titleStyle}>{dataRecordID_1.Name}</span>
                  </Link>
                </div>
                <div className="col-5">
                  <Link
                    to={`/view/${dataSourceIDNumber}/${recordID_2}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="no-underline"
                    style={{marginLeft:"-0.8rem"}}
                  >
                    <span style={titleStyle}>{dataRecordID_2.Name}</span>
                  </Link>
                </div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <b className="record-operations-label">Details</b>
              </div>
              {srows
              .filter((x) => isSectionEmpty(x))
              .map((section) => (
                <div
                  className="panel-group"
                  id={"section-" + section.id.toString()}
                  key={section.id}
                >
                  <div className="pull-left" style={{ width: "100%" }}>
                    <h4
                      className="panel-title"
                      data-toggle="collapse"
                      data-target={
                        "[id='section-" + section.id.toString() + "-collapse']"
                      }
                    >
                      <span
                        data-toggle="tooltip"
                        title=""
                        data-original-title=""
                      >
                        {section.name}
                      </span>
                    </h4>
                  </div>
                  <div
                    id={"section-" + section.id.toString() + "-collapse"}
                    style={{ clear: "both" }}
                    className="collapse in show"
                  >
                    {fieldRows
                      .filter(
                        (item) =>
                          item.sectionId == section.id &&
                          item.masterFieldId == null && IsFieldIsEmpty(item)
                      )
                      .map((item) => (
                        <div
                          className={
                            section.id !== 49
                              ? "col-sm-12 row form-group field-type-" +
                                item.fieldTypeId.toString()
                              : ""
                          }
                          key={item.id}
                        >
                          {!item.label.includes("Assign") &&
                            section.name !== "Disease" && (
                            <>
                              <label
                                className={
                                  "col-sm-2 text-end col-form-label " + (item.required || mandatoryFields.includes(item.name) ? "required" : "")
                                }
                                title={item.description}
                              >
                                {item.label}
                              </label>
                              <div className="col-sm-10 col-form-label">
                                <div
                                  className="row"
                                  style={{
                                    color: "#333333",
                                    textDecoration: "none",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "17px",
                                    // marginTop: "1px",
                                  }}
                                >
                                  <div className="col-sm-6" style={{overflow:"auto"}}>
                                    <span>
                                      {displayContent(
                                        item.name,
                                        item.fieldTypeId,
                                        1,
                                        item.lookupId,
                                        section.name
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-sm-6" style={{overflow:"auto"}}>
                                    <span>
                                      {displayContent(
                                        item.name,
                                        item.fieldTypeId,
                                        2,
                                        item.lookupId,
                                        section.name
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {
                                ((section.name ==
                                  "Reviewed indication and manufacturer’s claims" &&
                                  item.name ==
                                  "IsSubgroupEnabled") ||
                                  (section.name ==
                                    "Final recommendation" &&
                                    item.name ==
                                    "FinalRecommendation_ID")) && (
                                  <HTARecord_SubGroupPropCompareRecords
                                    datasourceName={
                                      "HTA"
                                    }
                                    section={section.name}
                                    fieldID={item.id}
                                    subGroup_data={subGroupData}
                                  />
                                )
                              }                          
                            </>
                            )}
                        </div>
                      ))}
                    {section.name === "Disease" && (
                      <div>
                        <h5>
                          {" "}
                          {dataRecordID_1.Name}{" "}
                        </h5>
                        <div style={{ margin: "-14px 10px 18px 0px" }}>
                          <DiseaseHierarchy recordID={recordID_1} dataSourceID={dataSourceIDNumber} />
                        </div>
                        <div>
                          <h5>
                            {" "}
                            {dataRecordID_2.Name}{" "}
                          </h5>
                          <div style={{ margin: "-14px 10px 18px 0px" }}>
                            <DiseaseHierarchy recordID={recordID_2} dataSourceID={dataSourceIDNumber} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {(commentsRecordID_1.length > 0 ||
                commentsRecordID_2.length > 0) && (
                <div ref={myRef}>
                  <span>
                    {commentsRecordID_1 && commentsRecordID_1.length > 0 ? (
                      <div>
                        <Comments
                          commentList={commentsRecordID_1}
                          clientList={clientList}
                          view="compare"
                          recordTitle={dataRecordID_1.Name}
                        />
                      </div>
                    ) : (
                      <div>
                        <div> {dataRecordID_1.Name} : </div>
                        <div> No comment </div>
                      </div>
                    )}
                  </span>
                  <span>
                    {commentsRecordID_2 && commentsRecordID_2.length > 0 ? (
                      <div>
                        <Comments
                          commentList={commentsRecordID_2}
                          clientList={clientList}
                          view="compare"
                          recordTitle={dataRecordID_2.Name}
                        />
                      </div>
                    ) : (
                      <div>
                        <div> {dataRecordID_2.Name} : </div>
                        <div> No comment </div>
                      </div>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "300px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default CompareRecords;
