import axios from "axios";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

function Auth() {
  const search = useLocation().search;
  const code_ : string = new URLSearchParams(search).get("code") || "";
  const session_state_ :string= new URLSearchParams(search).get("session_state") ||"";
  const _identity_manager_id = Cookies.get("email");
  
  useEffect(() => {
    const getToken = async () => {
      const _url = process.env.REACT_APP_API_URL + "/api/auth/login";
      try {
        const response: any = await axios({
          method: "post",
          url: _url,
          data: {
            code: code_,
            session_state: session_state_,
            _identity_manager_id: _identity_manager_id,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data[ "access_token" ])
          localStorage.setItem("token", response.data[ "access_token" ]);
        localStorage.setItem("refresh_token", response.data[ "refresh_token" ]);
        window.location.href = String(
          window.location.protocol + "//" + window.location.host
        );
        if (response.status === 401) {
          window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/Login`;
        }
      } catch (error: any) {
        // console.log("error",error);
        if (error.response.status === 400) {
          console.error('Failed to refresh token', error);
          window.location.href = "/AccessDenied";
        } else
          window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/error!`;
        // window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/LoggedOut?valid=false`;
      }
    };
    if (String(_identity_manager_id).includes("@")) {
      getToken();
    } else {
      window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/Login`;
    }
  }, []);

  return (
    <div>
      <p>Authenticating...</p>
    </div>
  );
}

export default Auth;
