import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import apiCall from '../../service/apiCall';

interface IProp {
      fieldDataSourceId: number;
      setFieldDatasourceId: (id: number) => void
}

interface IDatasource {
      "id": number,
      "name": string,
      "isRemoved": boolean,
      "displayName": String,
      "sortOrder": number,
      "linked": boolean,
      "mulQAssociateId": number,
      "mulQReviewerId": number
}

const DatasourceDropDown = ({ fieldDataSourceId, setFieldDatasourceId }: IProp) => {
      const [dropdownData, setDropdownData] = useState<{ label: string, value: number }[]>([]);
      const navigate = useHistory();
      useEffect(() => {
            const getSection = async () => {
                  const res: any = await apiCall(`/api/admin/ds/field/all`);
                  const data : IDatasource[] = res.data; 
                  const _dropdownData = data.map((el) => ({ label: el.name, value: el.id }));
                  setDropdownData(_dropdownData);
            }
            getSection();
      }, [])


      const onHandleChange = (e: any) => {
            navigate.push(`/admin/list/264/${e.target.value}`);
            setFieldDatasourceId(Number(e.target.value));            
      }
      return (<>
            <div>
                  <h5>Data Source</h5>
            </div>
            <div>
                  <select style={{border:"1px solid #d5d5d5",padding:"3px 4px",height:"30px"}} value={fieldDataSourceId} onChange={onHandleChange}>
                        {
                              dropdownData.map((el) => (
                                    <option value={el.value}>{el.label}</option>
                              ))
                        }
                  </select>
            </div>
           
      </>)
}

export default DatasourceDropDown;