import React, { useEffect } from "react";
import Cookies from "js-cookie";
import logo from "../../img/hta-logo.png";
import { Link } from "react-router-dom";

const AccessDenied = () => {
      const _email = Cookies.get("email");
      useEffect(() => {
            sessionStorage.clear();
            localStorage.clear();
      }, []);
      return (
            <div style={{ paddingTop: "10vw" }}>
                  <div className="container container-global" data-v-857fd648="">
                        <div className="wrap-form" data-v-857fd648="">
                              <div className="form-signin main-overlay" data-v-857fd648="">
                                    <div className="mb-4 text-center" data-v-857fd648="">
                                          <img src={logo} alt="IQVIA | HTA Accelerator" />
                                    </div>
                                    <form method="post">
                                          <div className="form-group form-group-cs mb-3">
                                                <span>
                                                      Access denied for :{" "}
                                                      {!!_email && _email.replace(/^"|"$/g, "")}
                                                </span>
                                          </div>

                                          <div>
                                                <Link className="log-out" to="/Login">
                                                      Login again
                                                </Link>
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default AccessDenied;