import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../Editor";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
//import InfoIcon from "@mui/icons-material/Info";
import { FaInfoCircle } from "react-icons/fa";
import { useTable } from "react-table";
import "./style.css";
import { FormFieldType } from "../../../Shared/Utility";

interface ElementProp {
      label : string;
      fieldType?: any;
      value : any;
      datasourceName : string
      section : string;
      description? : string;
      showInfoIcon?: boolean,
      showEmptyFieldsFlag?: boolean
}

interface IEndPointElementProp {
  label : string;
  value : any;
  showEmptyFieldsFlag?: boolean;
}

interface IDosageTableElement {
  label : string;
  value : any;
  DaysPerCycle : number;
}

const Element = ({label, fieldType, value, datasourceName, section, description, showInfoIcon = true,showEmptyFieldsFlag} : ElementProp) => {
      
  const userContext = useContext(UserContext);
  let formattedValue = fieldType == 15 ? new Date(value).toLocaleDateString('en-GB') : value;
  return (
    <>
    {(value || value == false || showEmptyFieldsFlag) &&
      <div
        className="col-sm-12 row form-group"
        style={{ marginBottom: "0rem" }}
        key = {fieldType}
      >
        <label
          className="col-sm-4 text-right control-label "
          style={{ paddingRight: "18px" }}
        >
          {label}
          {showInfoIcon && (<FaInfoCircle
            onClick={() => {
              userContext.showAlert(
                <>
                  <span style={{ fontSize: "70%", paddingLeft: "2px" }}>
                    {datasourceName}
                    <DoubleArrowIcon
                      style={{ fontSize: "18px", paddingBottom: "2px" }}
                    />
                    {section}
                    <DoubleArrowIcon
                      style={{
                        strokeWidth: "5",
                        fontSize: "18px",
                        paddingBottom: "2px",
                      }}
                    />
                   {label}
                  </span>
                </>,
                "info",
                description? description : "",
                "https://google.com",
                true
              );
            }}
            style={{
              fontSize: "12px",
              marginLeft: "3px",
              cursor: "pointer",
            }}
          />)}
        </label>
        <div className="col-sm-8" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
          {fieldType == 10 &&
            <input
              type="checkbox"
              readOnly
              value={formattedValue}
              style={{ color: "rgb(0, 170, 231)", marginTop: 2 }}
              checked={formattedValue}
            />
          }
          {fieldType == 48 &&
            <label
              dangerouslySetInnerHTML={{ __html: formattedValue || "" }}
            ></label>
          }
          {fieldType != 10 && fieldType !== 48 &&
            <label>{formattedValue}</label>
          }
        </div>
      </div>
}
    </>
  );
};

export const EndPointElement = ({ label, value, showEmptyFieldsFlag }: IEndPointElementProp) => {
  return (
    <>
      {(value || value == false || showEmptyFieldsFlag) && (
        <div
          className="col-sm-12 row form-group"
          style={{ marginBottom: "0rem" }}
        >
          <label
            className="col-sm-4 text-right control-label "
            style={{ paddingRight: "18px" }}
          >
            {label}
          </label>
          <div className="col-sm-8" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
          <label>{value}</label>  
        </div>
        </div>
        
      )}
    </>
  );
};

export const DosageElement = ({ label, value }: IEndPointElementProp) => {
  return (
    <>
      {(value || value == false) && (
        <div
          className="col-sm-12 row form-group"
          style={{ marginBottom: "0rem" }}
        >
          <label
            className="col-sm-4 text-right control-label "
            style={{ paddingRight: "18px" }}
          >
            {label}
          </label>
          <div className="col-sm-8" style={{ paddingTop: "10px",paddingLeft:"1px" }}>
          <label>{value}</label>  
        </div>
        </div>
        
      )}
    </>
  );
};

const Table = ({columns, data }:any) => {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  return (
    <table className="dosage-table" style={{margin:"0 auto"}}   {...getTableProps()}>
      <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="dosage-head dosage-head-fields" {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td className="dosage-head-fields dosage-fields" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

}

export const DosageTableElement = ({ label, value , DaysPerCycle}: IDosageTableElement) => {
  const [data, setData] = useState({});
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {

    let vals = value.split(",");

    const xy: any[] = [];

    let count = 0;
    let week = 0;

    let data_: any = {};

    for (let index = 0; index < DaysPerCycle; index++) {
      const x = vals[ index ] ? String(vals[ index ]) + ".00" : "0.00";
      //data feed for table
      data_[ index ] = x;

      // generating headers as per react-table

      if (count == 0) xy.push({ Header: "Week " + (week + 1), columns: [] });

      xy[ week ][ "columns" ].push({
        Header: index + 1,
        accessor: String(index),
      });

      count = count + 1;

      if (count == 7) {
        week = week + 1;
        count = 0;
      }
    }

    setData([data_]);
    setColumns(xy);
  }, [value]);

  return (
    <>
      {(value || value == false) && (
        <div
          className="col-sm-12 row form-group"
          style={{ marginBottom: "0rem" }}
        >
          <label
            className="col-sm-4 text-right control-label "
            style={{ paddingRight: "18px" }}
          >
            {label}
          </label>
          <div className="col-sm-12">           
              {columns.length > 0 && <Table columns={columns} data={data} />}           
          </div>
        </div>
      )}
    </>
  );
};

export default Element;



export const changeFieldCriteriaTypeId = (id: number)=> {
  switch(id){
        case FormFieldType.Checkbox:
        
              return 3; // Bit
        case FormFieldType.LookupAutoComplete:
        case FormFieldType.Dropdown:
        case FormFieldType.ComboboxSingle:
        case FormFieldType.DropdownRelatedData:
               // lookup
        case FormFieldType.MultiSelect:
        case FormFieldType.DrugSelect:
        case FormFieldType.DeviceSelect:
        case FormFieldType.ClinicalDataSelect:
        //case FormFieldType.DatePicker:
              return 2;
        default:
              return 1;
  }
}


export const changeFieldTypeId = (id: number)=> {
  switch(id){
        case FormFieldType.Checkbox:
              return FormFieldType.Checkbox
        case FormFieldType.LookupAutoComplete:
        case FormFieldType.Dropdown:
        case FormFieldType.ComboboxSingle:
        case FormFieldType.DropdownRelatedData:
        case FormFieldType.MultiSelect:
        case FormFieldType.DrugSelect:
        case FormFieldType.DeviceSelect:
        case FormFieldType.ClinicalDataSelect:
        case FormFieldType.LookupDisplay:
        // case FormFieldType.DatePicker:
              return FormFieldType.Dropdown
        default:
              return FormFieldType.Text;
  }
}